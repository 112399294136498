import { fetch } from 'utils/api';
import services from 'utils/api/Services/services';
import { actionRequest, actionSuccess, actionFailure } from 'utils/functions';

export const ACTIONS_TYPE = Object.freeze({
  SIDEBAR_MAINTAIN_EXPANDED: 'SIDEBAR_MAINTAIN_EXPANDED',
  SIDEBAR_UPDATE_ITEM_STATE: 'SIDEBAR_UPDATE_ITEM_STATE',
  SIDEBAR_UPDATE_STATE: 'SIDEBAR_UPDATE_STATE',

  GET_NOTIFICATIONS_BEGIN: 'GET_NOTIFICATIONS_BEGIN',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILURE: 'GET_NOTIFICATIONS_FAILURE',

  GET_APP_VERSION_BEGIN: 'GET_APP_VERSION_BEGIN',
  GET_APP_VERSION_SUCCESS: 'GET_APP_VERSION_SUCCESS',
  GET_APP_VERSION_FAILURE: 'GET_APP_VERSION_FAILURE',
});

export const getAppVersion = params => (dispatch, getState) =>
  fetch({
    service: services.app.appVersion(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_APP_VERSION_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_APP_VERSION_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_APP_VERSION_FAILURE, errors),
  });

export const sidebarMaintainExpanded = value => ({
  type: ACTIONS_TYPE.SIDEBAR_MAINTAIN_EXPANDED,
  result: { value: value },
});

export const sidebarUpdateState = value => ({
  type: ACTIONS_TYPE.SIDEBAR_UPDATE_STATE,
  result: { value: value },
});

export const sidebarUpdateItemState = (key, value) => ({
  type: ACTIONS_TYPE.SIDEBAR_UPDATE_ITEM_STATE,
  result: { key: key, value: value },
});

export const getNotifications = params => (dispatch, getState) => {
  console.log('get notifications with params: ', params);
  fetch({
    service: services.notifications.get(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_NOTIFICATIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_NOTIFICATIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_NOTIFICATIONS_FAILURE, errors),
  });
};
