import styled from 'styled-components';

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

export default TabContainer;
