import { useState } from 'react';

import {
  dimensionErrorText,
  minDimensionErrorText,
  formatErrorText,
  sizeErrorText,
} from './helper';
import { IconButton } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import GetAppIcon from '@material-ui/icons/GetApp';
import Cropper from 'components/Cropper';

ImageField.defaultProps = {
  value: null,
  cropper: false,
  minWidth: 0,
  minHeight: 0,
  width: 0,
  height: 0,
};

function ImageField(props) {
  const {
    label,
    value,
    cropper,
    onChange,
    width,
    height,
    minWidth,
    minHeight,
    maxSize,
  } = props;

  const [openCropper, setOpenCropper] = useState(false);
  const [image, setImage] = useState(value);
  const [error, setError] = useState(null);

  const checkImgRequirements = (img, file) => {
    let errors = null;
    const mbSize = file.size / (1024 * 1024);
    if (maxSize && mbSize > maxSize) {
      errors = 'sizeError';
    } else if (width && height) {
      if (img.width !== width || img.height !== height) {
        errors = 'dimensionError';
      }
    } else if (minWidth && minHeight) {
      if (img.width < minWidth || img.height < minHeight) {
        errors = 'minDimensionError';
      }
    } else if (!acceptedMimeTypes.includes(file.type)) {
      errors = 'formatError';
    }
    console.log('Image size: ', mbSize, ' mb');
    console.log('Image dimensions: ', img.width, 'x', img.height);
    return errors;
  };

  const acceptedMimeTypes = ['image/png', 'image/jpeg'];

  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    let img = document.createElement('img');
    img.onload = () => {
      const errors = checkImgRequirements(img, file);
      setError(errors);
      if (errors) {
        return;
      }

      setImage(reader.result);
      onChange(reader.result);
      if (cropper) {
        setOpenCropper(true);
      }
    };

    reader.onloadend = () => {
      img.src = reader.result;
    };

    reader.readAsDataURL(file);

    // save part
    let imageFormData = new FormData();
    imageFormData.append('imageFile', file);
  };

  const onCropperChange = (cropperData = null) => {
    setImage(cropperData);
    onChange(cropperData);
    setOpenCropper(false);
  };

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      {image && (
        <DoneIcon
          style={{
            marginBottom: -4,
            marginRight: 5,
            color: 'green',
          }}
        />
      )}
      {label}
      <input
        accept="image/*"
        style={{
          display: 'none',
        }}
        id={label}
        type="file"
        onChange={e => handleImageChange(e)}
      />
      <label htmlFor={label}>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          style={{
            width: 0,
            height: 0,
            backgroundColor: 'transparent !important',
          }}
        >
          <GetAppIcon />
        </IconButton>
      </label>
      {error === 'dimensionError' && dimensionErrorText(width, height)}
      {error === 'minDimensionError' &&
        minDimensionErrorText(minWidth, minHeight)}
      {error === 'formatError' && formatErrorText()}
      {error === 'sizeError' && sizeErrorText(maxSize)}

      {cropper && (
        <Cropper open={openCropper} image={image} onChange={onCropperChange} />
      )}
    </div>
  );
}

export default ImageField;
