import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQueryState } from 'utils/hooks/queryState';
import { useDebounce } from 'utils/hooks/debounce';
import Pagination from './Pagination';

const pageSizes = [20, 50, 100];

const PaginationWithQs = ({ itemCount, onChange }) => {
  const [pageQuery, setPageQuery] = useQueryState(1, 'page');
  const [pageSizeQuery, setPageSizeQuery] = useQueryState(
    pageSizes[0],
    'page-size'
  );

  const page = pageQuery ? parseInt(pageQuery) : 1;
  const pageSize = pageSizeQuery ? parseInt(pageSizeQuery) : pageSizes[0];

  const handleChange = ({ target: { name, value } }) => {
    switch (name) {
      case 'page-size':
        const pageSize = parseInt(value);
        setPageSizeQuery(pageSize);
        break;
      case 'prev':
        setPageQuery(page - 1);
        break;
      case 'next':
        setPageQuery(page + 1);
        break;
      default:
        throw new Error(`invalid action name: ${name}`);
    }
  };

  const componentDidMount = useRef(false);
  const debouncedPage = useDebounce(pageQuery, 300);
  useEffect(
    () => {
      if (componentDidMount.current) {
        onChange({ pageSize, page: debouncedPage });
      }
      componentDidMount.current = true;
    },
    // eslint-disable-next-line
    [pageSize, debouncedPage]
  );

  return (
    <Pagination
      page={page}
      pageSize={pageSize}
      itemCount={itemCount}
      pageSizeOptions={pageSizes}
      onChange={handleChange}
    />
  );
};

PaginationWithQs.propTypes = {
  itemCount: PropTypes.number,
  onChange: PropTypes.func,
};
PaginationWithQs.defaultProps = {
  itemCount: 0,
  onChange: () => {},
};

export default PaginationWithQs;
