import qs from 'qs';

const offers = {
  get: ({
    page = null,
    size = null,
    search = null,
    admin = null,
    status = null,
    standBy = null,
    company = null,
    adminType = null,
    sortField = null,
    sortOrder = null,
    contractTypeIds = null,
    planIds = null,
  }) => ({
    url: `/offers`,
    method: 'get',
    params: {
      page,
      size,
      search,
      adminId: admin,
      status,
      standBy,
      companyId: company,
      adminType: adminType,
      sortField,
      sortOrder,
      contractTypeIds,
      planIds,
    },
  }),
  getOfferStats: ({ offerId = null }) => ({
    url: `/offers/${offerId}/stats`,
    method: 'get',
  }),
  getOne: ({ id }) => ({
    url: `/offers/${id}`,
    method: 'get',
  }),
  getStandBy: params => ({
    url: `/offers`,
    method: 'get',
    params: { ...params, standBy: 1 },
  }),
  add: data => ({
    url: '/offers',
    method: 'post',
    data: data,
  }),
  update: data => ({
    url: `/offers/${data.id}`,
    method: 'put',
    data: data,
  }),
  updateStatus: params => ({
    url: `/offers/${params.id}/status`,
    method: 'patch',
    data: qs.stringify({
      status: params.status,
      reason: params.reason,
    }),
  }),
  getApplications: ({
    offerId,
    contractTypes = null,
    minStartDate = null,
    minDuration = null,
    maxDuration = null,
    profiles = null,
    isCommented = null,
    isActiveResearch = null,
    schoolTypes = null,
    schoolLevels = null,
    status = null,
  }) => ({
    url: `/offers/${offerId}/applications`,
    params: {
      contractTypeIds: contractTypes,
      startDate: minStartDate,
      minDuration: minDuration,
      maxDuration: maxDuration,
      profiles: profiles,
      isCommented: isCommented,
      isActiveResearch: isActiveResearch,
      schoolTypes: schoolTypes,
      schoolLevels: schoolLevels,
      status: status,
    },
  }),
  getSuggestions: ({ offerId }) => ({
    url: `/offers/${offerId}/suggestions`,
    method: 'get',
  }),
  interactWithCustomer: ({ id, action }) => ({
    url: `/offers/${id}/customer-interact/${action}`,
    method: 'patch',
  }),
  generateOfferInvoice: ({
    offerId,
    amountId,
    candidateId,
    isSecondVariable,
  }) => ({
    url: `/offers/${offerId}/generateInvoice`,
    method: 'post',
    params: {
      amountId,
      candidateId,
      isSecondVariable: isSecondVariable ? 1 : 0,
    },
  }),
  sendShortListMail: ({
    id,
    mailContent,
    recipients,
    applicationIds,
    attachCoverLetters,
    locale,
  }) => ({
    url: `/offers/${id}/send-short-list`,
    data: {
      mailContent,
      recipients,
      applicationIds,
      attachCoverLetters,
      locale,
    },
    method: 'put',
  }),
  downloadReport: ({ id }) => ({
    url: `/offers/${id}/reporting.pdf`,
    method: 'get',
    responseType: 'blob',
  }),
  sendReportMail: ({ id }) => ({
    url: `/offers/${id}/sendReportMail`,
    method: 'get',
  }),
  getOverviewStats: ({ adminId, contractTypeIds, planIds, status }) => ({
    url: '/offers/overview-stats',
    method: 'get',
    params: { adminId, contractTypeIds, planIds, status },
  }),
  switchHeadline: ({ id }) => ({
    url: `/offers/${id}/switch-headline`,
    method: 'patch',
  }),
};

export default offers;
