import { TextField } from 'components/core/input';
import { useTranslation } from 'react-i18next';

export default function SecondVariablePrice({
  values,
  touched,
  errors,
  onChange,
  name,
}) {
  const { t } = useTranslation();

  return (
    <TextField
      name={name ?? 'variableCostHiring'}
      label={t('paf.field.variableCostHiring')}
      touched={name ? touched[name] : touched.variableCostHiring}
      error={name ? errors[name] : errors.variableCostHiring}
      type={'number'}
      handleChange={onChange}
      value={name ? values[name] : values.variableCostHiring}
      disabled={!values.secondVariable}
    />
  );
}
