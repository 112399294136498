import Table from 'components/core/Table';
import { lightBlueFontCode, redFontCode } from 'utils/constants';
import { ROUTES } from 'utils/routes';
import Detail from './detail';

const rowColors = {
  done: lightBlueFontCode,
  delayed: redFontCode,
};

export const legendItems = [
  { color: rowColors.done, label: 'Suivi réalisé' },
  { color: rowColors.delayed, label: 'Suivi en retard' },
];

export function FollowUpTable({ rows }) {
  return (
    <Table
      maxHeight={530}
      columns={columns}
      rows={formatRows(rows || [])}
      detail={Detail}
    />
  );
}

const formatRows = rows =>
  rows.map(row => ({
    ...row,
    company: {
      label: row.company?.label || row.otherCompany,
      img: row.company?.logo,
      url: ROUTES.PAF_EDIT.replace(':id', row.placementId),
    },
    candidate: {
      label: row.candidate?.fullName || row.otherCandidate,
      url: row.candidate
        ? ROUTES.CANDIDATE_SHOW.replace(':id', row.candidate.id)
        : '#',
    },
    offer: {
      label: row.offer?.label || row.otherOffer,
      url: row.offer ? ROUTES.OFFER_SHOW.replace(':id', row.offer.id) : '#',
    },
    backgroundColor: row.isDone
      ? rowColors.done
      : new Date(row.date).getTime() < new Date().getTime()
        ? rowColors.delayed
        : 'inherit',
  }));

const columns = [
  // {
  //   header: 'Id',
  //   accessors: ['id'],
  // },
  {
    header: 'Entreprise',
    accessors: ['company'],
    type: 'image',
  },
  {
    header: '',
    accessors: ['company', 'label'],
    headerStyle: { width: '10%' },
  },
  {
    header: 'Date',
    accessors: ['date'],
    type: 'date',
  },
  {
    header: 'Offre',
    accessors: ['offer'],
    type: 'link',
    headerStyle: { width: '40%' },
  },
  {
    header: 'Candidat',
    accessors: ['candidate'],
    type: 'link',
    headerStyle: { width: '15%' },
  },
  {
    header: 'AM',
    accessors: ['admin', 'fullName'],
    headerStyle: { width: '15%' },
  },
  {
    header: 'Cdr',
    accessors: ['secondaryAdmin', 'fullName'],
    headerStyle: { width: '15%' },
  },
  // {
  //   header: 'Is done',
  //   accessors: ['isdone'],
  // },
];
