import { Link as ReactRouterLink, matchPath } from 'react-router-dom';

// https://github.com/ReactTraining/history/issues/470
const Link = ({ to, replace, ...props }) => {
  const handleClick = e => {
    const isActive = matchPath(window.location.pathname, to);
    if (isActive) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <ReactRouterLink
        {...props}
        to={to}
        replace={replace}
        onClick={handleClick}
      />
    </div>
  );
};

Link.propTypes = ReactRouterLink.propTypes;
Link.defaultProps = ReactRouterLink.defaultProps;

export default Link;
