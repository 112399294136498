export default theme => ({
  container: {
    maxWidth: 600,
  },
  textField: {
    width: 400,
  },
  actionFields: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(2),
    display: 'flex',
  },
});
