import { Grid } from 'components/core/containers';
import {
  Autocomplete,
  AutocompleteAsync,
  Checkbox,
  FileField,
  TextField,
} from 'components/core/input';
import Link from 'components/core/Link';
import SubmitButtons from 'components/core/SubmitButtons';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import {
  useFecthStudyLevels,
  useFetchProvenances,
} from 'utils/hooks/formOptions';
import services from 'utils/api/Services/services';
import { CandidateFormSchema } from 'utils/formSchemas';
import { useDispatch } from 'react-redux';
import { updateCandidate } from 'redux/candidates/actions';

export default function CandidateForm({ candidate }) {
  const {
    studyLevels,
    provenances,
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
  } = useCandidateForm(candidate);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid md={6}>
          <TextField
            name="firstName"
            label="Prénom"
            value={values.firstName}
            handleChange={handleChange}
            touched={touched.firstName}
            error={errors.firstName}
          />
        </Grid>
        <Grid md={6}>
          <TextField
            name="lastName"
            label="Nom"
            value={values.lastName}
            handleChange={handleChange}
            touched={touched.lastName}
            error={errors.lastName}
          />
        </Grid>
        <Grid md={6}>
          <AutocompleteAsync
            name="school"
            service={search =>
              services.options.getSchools({
                search: search,
              })
            }
            label={'Établissements'}
            creatable
            value={values.school}
            onChange={handleChange}
            touched={touched.school}
            error={errors.school}
          />
        </Grid>
        <Grid md={6}>
          <Autocomplete
            name="studyLevel"
            label="Niveau d'Études"
            options={studyLevels}
            value={values.studyLevel}
            onChange={handleChange}
            touched={touched.studyLevel}
            error={errors.studyLevel}
          />
        </Grid>
        <Grid md={6}>
          <Autocomplete
            name="provenance"
            value={values.provenance}
            label="Provenance"
            options={provenances?.filter(
              provenance => provenance.label !== 'Autre'
            )}
            onChange={handleChange}
            creatable
            touched={touched.provenance}
            error={errors.provenance}
          />
        </Grid>
        <Grid md={6}>
          <TextField
            name="phoneNumber"
            label="Téléphone"
            value={values.phoneNumber}
            handleChange={handleChange}
            touched={touched.phoneNumber}
            error={errors.phoneNumber}
          />
        </Grid>
        <Grid md={6}>
          <Checkbox
            name="rqth"
            label={'RQTH'}
            value={values.rqth}
            onChange={handleChange}
          />
        </Grid>
        <Grid md={6}>
          <div
            style={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              marginTop: 5,
            }}
          >
            <FileField
              label={
                <Link to={candidate.cv} blank external>
                  CV
                </Link>
              }
              name="resume"
              onChange={handleChange}
            />
          </div>
        </Grid>
        <Grid md={12}>
          <div
            style={{
              marginTop: -30,
            }}
          >
            <SubmitButtons cancelAction={() => {}} />
          </div>
        </Grid>
      </Grid>
    </form>
  );
}

function useCandidateForm(candidate) {
  const [fetchProvenances, provenances] = useFetchProvenances();
  const [fetchStudyLevels, studyLevels] = useFecthStudyLevels();

  useEffect(
    () => {
      fetchProvenances('candidate');
      fetchStudyLevels();
    },
    [fetchProvenances, fetchStudyLevels]
  );

  const dispatch = useDispatch();

  const handleSubmit = values => {
    const school = values.school;
    const provenance = values.provenance?.label || values.provenance;
    const data = {
      id: candidate.id,
      firstName: values.firstName,
      lastName: values.lastName,
      schoolId: school?.value > 0 ? school?.value : null,
      otherSchool: school?.value <= 0 ? school?.label : null,
      studyLevelId: values.studyLevel?.value,
      rqth: values.rqth,
      provenance,
      resume: values.resume,
      phoneNumber: values.phoneNumber,
    };
    dispatch(updateCandidate(data));
  };

  const formik = useFormik({
    initialValues: { ...candidate },
    onSubmit: handleSubmit,
    validationSchema: CandidateFormSchema,
  });

  const handleChange = ({ value, name }) => {
    formik.setFieldValue(name, value, true);
    formik.setFieldTouched(name, true, false);
  };

  return {
    provenances,
    studyLevels,
    values: formik.values,
    touched: formik.touched,
    errors: formik.errors,
    handleChange,
    handleSubmit: formik.handleSubmit,
  };
}
