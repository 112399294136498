import { connect } from 'react-redux';
import { removeAlert } from 'redux/alert/actions';
import Snackbar from '@material-ui/core/Snackbar';
import AlertContent from 'components/Alert/content';

function Alert(props) {
  const { alert, removeAlert } = props;

  const handleClose = () => {
    removeAlert(alert.key);
  };

  if (alert.key === '') {
    return <div />;
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={true}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <AlertContent
          onClose={handleClose}
          variant={alert.type}
          message={alert.msg}
        />
      </Snackbar>
    </div>
  );
}

const mapStateToProps = state => ({
  alert: state.alert,
});

const mapDispatchToProps = dispatch => ({
  removeAlert: key => dispatch(removeAlert(key)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Alert);
