import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cleanCurrentOffer,
  createOffer,
  getOneOffer,
  getSampleOffer,
  updateOffer,
} from 'redux/offers/actions';

export const useIsLoading = () => useSelector(state => state.offers.loading);

export const useFetchOffer = id => {
  const dispatch = useDispatch();

  useEffect(
    () => {
      if (id) {
        dispatch(getOneOffer({ id }));
      }

      return () => dispatch(cleanCurrentOffer());
    },
    [dispatch, id]
  );

  return [useSelector(state => state.offers.current)];
};

export const useFetchSample = () => {
  const dispatch = useDispatch();
  const fetchSample = id => dispatch(getSampleOffer({ id: id }));
  const sample = useSelector(state => state.offers.sample);

  return [fetchSample, sample];
};

export const useUpdateOffer = () => {
  const dispatch = useDispatch();

  const update = data => {
    console.log('call update offer with data: ', data);
    dispatch(updateOffer(data));
  };

  return update;
};

export const useCreateOffer = () => {
  const dispatch = useDispatch();

  const create = data => {
    console.log('call create offer with data: ', data);
    dispatch(createOffer(data));
  };

  return create;
};
