import type { Theme } from 'utils/types';

const fieldsSpacing = 10;

export default (theme: Theme) => ({
  root: {
    overflowX: 'auto',
    display: 'inline-flex',
  },
  formContainer: {
    display: 'flex',
  },
  row: {
    display: 'flex',
    marginTop: 20,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,
    marginLeft: 30,
    marginRight: 30,
  },
  textField: {
    width: 180,
    marginLeft: fieldsSpacing,
    marginRight: fieldsSpacing,
  },
  smallTextField: {
    width: 120,
    marginLeft: fieldsSpacing,
    marginRight: fieldsSpacing,
  },
  commentsField: {
    width: '98%',
  },
  selectField: {
    width: 180,
    marginLeft: fieldsSpacing,
    marginRight: fieldsSpacing,
  },
  checkbox: {
    width: 180,
    marginRight: 21,
    marginLeft: 0,
    marginBottom: -10,
  },
  actionFields: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(5),
    display: 'flex',
  },
  rightFields: {
    textAlign: 'right',
    flex: 1,
  },
});
