import styled from 'styled-components';

const Box = styled.div`
  border: solid 1px darkgrey;
  border-radius: 4px;
  padding: 10px;
  height: 100%;
  display: ${({ direction }) =>
    direction === 'column' ? 'column' : 'inline-flex'};
  ${({ scrollable }) =>
    scrollable ? `height: 100%; overflow-y: scroll;` : ''};
  ${({ width }) => (width ? `width: ${width}px;` : '')};
`;

export default Box;
