import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import Tooltip from 'components/core/Tooltip';
import { EditRounded } from '@material-ui/icons';

const EditButton = ({ path, fontSize }) => {
  return (
    <Container>
      <Tooltip text="Modifer" position="bottom">
        <Link to={path}>
          <EditRounded fontSize={fontSize || 'small'} />
        </Link>
      </Tooltip>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Link = styled(RouterLink)`
  display: flex
  text-decoration: none;
  color: black;
`;

export default EditButton;
