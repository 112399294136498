import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components/core/input';

export default function OfferInDifficulty({ values, onChange }) {
  const { t } = useTranslation();

  return (
    <Checkbox
      name="offerInDifficulty"
      label={t('paf.field.offerInDifficulty')}
      value={values.offerInDifficulty}
      onChange={onChange}
    />
  );
}
