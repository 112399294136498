import ActionButton from 'components/ActionButton';
import SendIcon from '@material-ui/icons/Send';

MailButton.defaultProps = {
  disabled: false,
};

export default function MailButton({ label, onClick, disabled }) {
  return (
    <ActionButton
      label={label}
      dialog
      title="Envoi de mail"
      dialogContent="Attention, cette action déclanche l'envoi d'un mail vers le client."
      action={onClick}
      confirmText="Confirmer"
      disabled={disabled}
    >
      {label} <SendIcon style={{ marginLeft: 5 }} />
    </ActionButton>
  );
}
