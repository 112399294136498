import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'utils/routes';
import {
  getCreditNotes,
  downloadCreditNotes,
  downloadOneCreditNote,
} from 'redux/creditNotes/actions';

import { tableColumns, formatData } from './constants';

import { withStyles } from '@material-ui/core';
import Table from 'components/Table';
import Main from 'components/layout/Main';
import PageHeader from 'components/layout/PageHeader';
import EditButton from 'components/Table/Actions/EditButton';
import DownloadAllButton from 'components/Table/Actions/DownloadAllButton';
import DownloadOneButton from './DownloadOneButton';
import SendButton from './SendButton';
import S from './styles';

function CreditNotes(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const creditNotesState = useSelector(state => state.creditNotes);

  const handleRefresh = ({
    page,
    size,
    filter = null,
    orderBy = null,
    order = null,
  }) => {
    dispatch(
      getCreditNotes({
        page: page + 1,
        size: size,
        filter: filter,
        orderBy: orderBy,
        direction: order,
      })
    );
  };

  const handleDownloadAll = () => {
    dispatch(downloadCreditNotes());
  };

  const handleDownloadOne = ({ id }) => {
    dispatch(
      downloadOneCreditNote({
        id: id,
      })
    );
  };

  const generalActionComponents = [
    () => <DownloadAllButton action={handleDownloadAll} />,
  ];

  const actionComponents = [
    props => (
      <EditButton
        {...props}
        pathname={ROUTES.CREDIT_NOTE_EDIT.replace(
          ':invoiceBusinessId',
          props.row.invoiceId
        ).replace(':creditNoteBusinessId', props.row.creditNoteId)}
      />
    ),
    props => <DownloadOneButton {...props} action={handleDownloadOne} />,
    props => <SendButton row={props.row} />,
  ];

  return (
    <Main
      pageTitle={t('menu.invoicingTitle')}
      isLoading={creditNotesState.loading}
    >
      <div className={props.classes.root}>
        <PageHeader title={t('invoicing.creditNotes.title')} />
        <Table
          rows={formatData(creditNotesState.items)}
          columns={tableColumns(t)}
          handleRefresh={handleRefresh}
          isSearchable
          isPaginatable
          isSortable
          generalActionComponents={generalActionComponents}
          actionComponents={actionComponents}
          totalItemCount={creditNotesState.totalItemCount}
        />
      </div>
    </Main>
  );
}

export default withStyles(S)(CreditNotes);
