import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ROUTES } from 'utils/routes';

const PrivateRoute = ({ path, component, allowedRoles, props }) => {
  const userRoles = useSelector(state => state.auth.userRoles);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  const isUserAllowed = () => {
    if (!allowedRoles.length) {
      return true;
    }

    return userRoles.find(role => allowedRoles.includes(role)) ?? false;
  };

  const Component = component;

  return (
    <Route
      path={path}
      exact
      {...props}
      render={props =>
        isAuthenticated ? (
          <>
            {isUserAllowed() ? (
              <Component {...props} />
            ) : (
              <Redirect to={ROUTES.OFFER_LIST} />
            )}
          </>
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.LOGIN,
              state: {
                from: props.location.pathname,
              },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  path: PropTypes.string,
  component: PropTypes.elementType,
  allowedRoles: PropTypes.array,
};

PrivateRoute.defaultProps = {
  path: null,
  component: null,
  allowedRoles: [],
};

export default PrivateRoute;
