import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'utils/routes';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  withStyles,
} from '@material-ui/core';

import S from './styles';

function normalizeNumber(value) {
  return value === '' ? null : value;
}

function Form(props) {
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    classes,
    isSubmitting,
  } = props;
  const { t } = useTranslation();

  const handleChange = (fieldName, value) => {
    setFieldValue(fieldName, value, true);
    setFieldTouched(fieldName, true, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box className={classes.formContainer}>
        {/* Offer informations */}
        <Box className={classes.column}>
          <Box className={classes.row}>
            {/* Amount */}
            <TextField
              id="amount"
              label={t('invoicing.creditNotes.fields.amount')}
              autoComplete="off"
              helperText={touched.amount ? errors.amount : ''}
              error={touched.amount && Boolean(errors.amount)}
              className={classes.textField}
              onChange={event => {
                const value = normalizeNumber(event.target.value);
                handleChange('amount', value);
              }}
              value={values.amount ? values.amount : ''}
            />
          </Box>
          {/*  TVA */}
          <Box className={classes.row}>
            <FormControlLabel
              label={t('invoicing.creditNotes.fields.tva')}
              control={
                <Checkbox
                  id="tva"
                  checked={values.tva}
                  onChange={() => {
                    const value = values.tva ? false : true;
                    handleChange('tva', value);
                  }}
                  color="primary"
                />
              }
              className={classes.checkbox}
              labelPlacement="end"
            />
          </Box>
        </Box>
      </Box>
      <Box component="div">
        <Box component="div" className={classes.actionFields}>
          <Box className={classes.rightFields}>
            <Link to={ROUTES.CREDIT_NOTES_LIST}>
              <Button>Cancel</Button>
            </Link>
            <Button type="submit" color="primary" disabled={isSubmitting}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
}

export default withStyles(S)(Form);
