import BaseButton from './baseButton';

export default function ContractButton(props) {
  const msg = `Souhaites-tu refuser cette candidature pour le motif CONTRAT ?
        Un mail sera envoyé au candidat.`;

  return (
    <div>
      <BaseButton
        label="Contrat"
        message={msg}
        code="contract"
        value={props.value}
        color="secondary"
        action={props.action}
        disabled={props.disabled}
      />
    </div>
  );
}
