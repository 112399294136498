import { memo } from 'react';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import ActionButton from 'components/ActionButton';

function CloneButton(props) {
  const { action, id } = props;

  const handleOnClick = () => {
    action(parseInt(id));
  };

  return (
    <ActionButton
      action={handleOnClick}
      label="Dupliquer"
      dialog
      title="Confirmation de duplication"
      contentText="Souhaites-tu vraiment dupliquer cette ligne ?"
      confirmText="Oui"
      cancelText="Non"
      icon={<FilterNoneIcon fontSize="small" />}
    />
  );
}

export default memo(CloneButton);
