import {
  lightBlueFontCode,
  darkBlueFontCode,
  redFontCode,
  recruitmentPlanId,
} from 'utils/constants';
import { ROUTES } from 'utils/routes';

export const placementStyle = lightBlueFontCode;
export const invoicedStyle = darkBlueFontCode;
export const shouldBeInvoicedStyle = redFontCode;

export const tableColumns = [
  {
    header: '',
    accessors: ['companyLogo'],
    type: 'image',
  },
  {
    header: 'Entreprise',
    accessors: ['companyLink'],
    style: { fontWeight: 'bold' },
    type: 'link',
  },
  {
    header: 'Consultant',
    accessors: ['admin', 'label'],
    isSortable: true,
  },
  {
    header: 'Poste',
    accessors: ['offerLink'],
    isSortable: true,
    type: 'link',
  },
  {
    header: 'Lancement Offre',
    accessors: ['recruitmentStartDate'],
    isSortable: true,
    type: 'date',
  },
  {
    header: "Type d'Offre",
    accessors: ['plan', 'label'],
    isSortable: true,
  },
  {
    header: 'Type de Contrat',
    accessors: ['contractType', 'label'],
    isSortable: true,
  },
  {
    header: 'Frais Fixe',
    accessors: ['fixedCost'],
    isSortable: true,
  },
  {
    header: '1er Variable',
    accessors: ['variableCost'],
    isSortable: true,
  },
  {
    header: '2nd Variable',
    accessors: ['variableCostHiring'],
    isSortable: true,
  },
];

export const initialPaf = {
  abandonedOffer: false,
  admin: null,
  candidate: null,
  candidateStartDate: null,
  company: null,
  finalInternship: false,
  fixedCost: null,
  invoiceDate: null,
  offerInDifficulty: false,
  plan: null,
  pafDate: null,
  offer: null,
  contractType: null,
  contractDuration: null,
  recruitmentStartDate: new Date(),
  contractChangeDate: null,
  secondVariable: true,
  secondVariableDate: null,
  variableCost: null,
  variableCostHiring: null,
  completedOffer: null,
  duration: null,
  comments: '',
  vacantPositions: 1,
};

export const legend = [
  { color: lightBlueFontCode, label: 'Placement' },
  { color: darkBlueFontCode, label: 'Placement Facturé' },
  { color: redFontCode, label: 'Placement non Facturé' },
];

export const pafTableDataFormat = (pafs, checkContractChanged = false) =>
  pafs.map(paf => {
    const pafTableDataFormat = Object.assign({}, paf);

    pafTableDataFormat.companyLogo = {
      label: paf.company.label,
      img: paf.company.url,
      url: ROUTES.COMPANY_SHOW.replace(':id', paf.company.value),
    };
    pafTableDataFormat.companyLink = {
      label: paf.company.label,
      url: ROUTES.COMPANY_SHOW.replace(':id', paf.company.value),
    };

    pafTableDataFormat.offerLink = {
      label: paf.offer && paf.offer.label,
      url: ROUTES.OFFER_SHOW.replace(':id', paf.offer && paf.offer.value),
    };
    pafTableDataFormat.candidateLink = {
      label: paf.candidate && paf.candidate.label,
      url:
        paf.candidate &&
        ROUTES.CANDIDATE_SHOW.replace(':id', paf.candidate.value),
    };

    // Set styles for paf lines depending of line status
    if (paf.abandonedOffer) {
      pafTableDataFormat.isStrikeThrough = true;
      return pafTableDataFormat;
    }

    if (paf.plan && paf.plan.value !== recruitmentPlanId) {
      return pafTableDataFormat;
    }

    if (
      (checkContractChanged && paf.contractChangeDate) ||
      (!checkContractChanged && paf.invoiceDate)
    ) {
      pafTableDataFormat.backgroundColor = invoicedStyle;
      return pafTableDataFormat;
    }

    if (paf.pafDate && !checkContractChanged) {
      pafTableDataFormat.backgroundColor = placementStyle;
    }

    if (
      (!paf.invoiceDate &&
        paf.candidateStartDate &&
        new Date(paf.candidateStartDate) < new Date()) ||
      (checkContractChanged &&
        !paf.completedOffer &&
        paf.secondVariableDate &&
        new Date(paf.secondVariableDate) < new Date())
    ) {
      pafTableDataFormat.backgroundColor = shouldBeInvoicedStyle;
    }
    return pafTableDataFormat;
  });
