import DropdownButton from 'components/DropdownButton';
import Subtitle from 'components/layout/Subtitle';
import InvoiceTable from 'components/Tables/InvoiceTable';

function Invoices(props) {
  const companyId = props.companyId;

  return (
    <DropdownButton
      label={<Subtitle label="Factures" />}
      content={<InvoiceTable companyId={companyId} displayActions={false} />}
    />
  );
}

export default Invoices;
