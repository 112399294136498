import { useDispatch } from 'react-redux';

import { setInvoiceReminderProgram } from 'redux/invoices/actions';
import ActionButton from 'components/ActionButton';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

function InvoiceReminderProgramButton(props) {
  const { invoice } = props;

  const dispatch = useDispatch();

  const handleOnConfirm = () => {
    let params = {
      id: invoice.id,
      set: invoice.irpDate ? 0 : 1,
    };

    dispatch(setInvoiceReminderProgram(params));
  };

  return (
    <ActionButton
      icon={<ErrorOutlineIcon fontSize="small" />}
      dialog
      action={handleOnConfirm}
      label="Programme de relance"
      title="Programme de relance"
      contentText={
        invoice.irpDate
          ? 'Terminer le programme de relance.'
          : 'Lancer le programme de relance.'
      }
      confirmText="Valider"
      cancelText="Annuler"
      disabled={invoice.issuance === null || invoice.paymentDate !== null}
    />
  );
}

export default InvoiceReminderProgramButton;
