import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContractTypeOptions,
  getStudyLevelOptions,
  getSchoolTypeOptions,
  getProfileOptions,
  getFunctionByFamilyOptions,
} from 'redux/formOptions/actions';
import services from 'utils/api/Services/services';

import { Grid } from 'components/core/containers';
import { FilterAccordion } from 'components/core/Accordion';
import {
  MultiAutocompleteFilter,
  AutocompleteAsyncFilter,
  DateFilter,
  TextFilter,
  CheckboxFilter,
} from 'components/core/FilterInput';
import { useEffect } from 'react';

function CandidateFilters({ onChange }) {
  const options = useSelector(state => state.formOptions);

  const contractTypeOptions = options.contractTypeOptions;
  const schoolTypeOptions = formatLabelBasedOptions(options.schoolTypeOptions);
  const profileOptions = formatLabelBasedOptions(options.profileOptions);
  const functionOptions = options.functionByFamilyOptions;
  const studyLevelOptions = options.studyLevelOptions;

  const dispatch = useDispatch();
  const didMount = useRef(false);
  useEffect(
    () => {
      if (didMount.current === false) {
        dispatch(getContractTypeOptions());
        dispatch(getSchoolTypeOptions());
        dispatch(getProfileOptions());
        dispatch(getFunctionByFamilyOptions());
        dispatch(getStudyLevelOptions());

        didMount.current = true;
      }
    },
    [dispatch, contractTypeOptions, schoolTypeOptions]
  );

  const handleChange = ({ name, value }) => {
    const params = {};
    switch (name) {
      case 'contract-type-ids':
        params.contractTypeIds = value;
        break;
      case 'school-types':
        params.schoolTypes = value;
        break;
      case 'profiles':
        params.profiles = value;
        break;
      case 'min-start-date':
        params.minStartDate = value;
        break;
      case 'max-start-date':
        params.maxStartDate = value;
        break;
      case 'school-ids':
        params.schoolIds = value;
        break;
      case 'min-duration':
        params.minDuration = value;
        break;
      case 'max-duration':
        params.maxDuration = value;
        break;
      case 'function-ids':
        params.functionIds = value;
        break;
      case 'study-level-ids':
        params.studyLevelIds = value;
        break;
      case 'rqth':
        params.isRqth = value;
        break;
      case 'comment':
        params.isCommented = value;
        break;
      case 'final-year':
        params.isFinalYear = value;
        break;
      case 'gap-year':
        params.isGapYear = value;
        break;
      case 'active-research':
        params.isActiveResearch = value;
        break;
      case 'no-research':
        params.isNoResearch = value;
        break;
      case 'no-application':
        params.isNoApplication = value;
        break;
      case 'min-registration-date':
        params.minRegistrationDate = value;
        break;
      case 'max-registration-date':
        params.maxRegistrationDate = value;
        break;
      default:
        throw new Error(`invalid param name: ${name}`);
    }
    onChange(params);
  };

  return (
    <FilterAccordion>
      <Grid container>
        <Grid md={4} sm={12}>
          <MultiAutocompleteFilter
            name="contract-type-ids"
            label="Types de Contrats"
            options={contractTypeOptions}
            onChange={handleChange}
            defaultValue={null}
          />
        </Grid>
        <Grid md={4} sm={12}>
          <MultiAutocompleteFilter
            name="school-types"
            label="Types d'Établissements"
            options={schoolTypeOptions}
            onChange={handleChange}
            defaultValue={null}
          />
        </Grid>
        <Grid md={4} sm={12}>
          <MultiAutocompleteFilter
            name="profiles"
            label="Profils"
            options={profileOptions}
            onChange={handleChange}
            defaultValue={null}
          />
        </Grid>
        <Grid md={2} sm={6}>
          <DateFilter
            name="min-start-date"
            label="Date de Début Min"
            onChange={handleChange}
          />
        </Grid>
        <Grid md={2} sm={6}>
          <DateFilter
            name="max-start-date"
            label="Date de Début Max"
            onChange={handleChange}
          />
        </Grid>
        <Grid md={4} sm={12}>
          <AutocompleteAsyncFilter
            multi
            name="school-ids"
            service={fetchSchoolOptions}
            label="Établissements"
            onChange={handleChange}
          />
        </Grid>
        <Grid md={2} sm={6}>
          <TextFilter
            name="min-duration"
            label="De"
            onChange={handleChange}
            endAdornment="mois"
            type="number"
          />
        </Grid>
        <Grid md={2} sm={6}>
          <TextFilter
            name="max-duration"
            label="À"
            onChange={handleChange}
            endAdornment="mois"
            type="number"
          />
        </Grid>
        <Grid md={4} sm={12}>
          <MultiAutocompleteFilter
            name="function-ids"
            label="Fonctions"
            options={functionOptions}
            onChange={handleChange}
            defaultValue={null}
          />
        </Grid>
        <Grid md={4} sm={12}>
          <MultiAutocompleteFilter
            name="study-level-ids"
            label="Niveaux d'Études"
            options={studyLevelOptions}
            onChange={handleChange}
            defaultValue={null}
          />
        </Grid>
        <Grid md={4} sm={12} />
        <Grid md={4} sm={12}>
          <DateFilter
            name="min-registration-date"
            label="Date Inscription Min"
            onChange={handleChange}
          />
        </Grid>
        <Grid md={4} sm={12}>
          <DateFilter
            name="max-registration-date"
            label="Date Inscription Max"
            onChange={handleChange}
          />
        </Grid>
        <Grid md={4} sm={12} />
        <Grid md={2} sm={4}>
          <CheckboxFilter
            name="comment"
            label="Commentaire"
            onChange={handleChange}
          />
        </Grid>
        <Grid md={2} sm={4}>
          <CheckboxFilter
            name="final-year"
            label="Fin d'Études"
            onChange={handleChange}
          />
        </Grid>
        <Grid md={2} sm={4}>
          <CheckboxFilter
            name="gap-year"
            label="Césure"
            onChange={handleChange}
          />
        </Grid>
        <Grid md={2} sm={4}>
          <CheckboxFilter
            name="active-research"
            label="Recherche Active"
            onChange={handleChange}
          />
        </Grid>
        <Grid md={2} sm={4}>
          <CheckboxFilter
            name="no-research"
            label="Sans Démarche"
            onChange={handleChange}
          />
        </Grid>
        <Grid md={2} sm={4}>
          <CheckboxFilter
            name="no-application"
            label="Sans Candidature"
            onChange={handleChange}
          />
        </Grid>
        <Grid md={2} sm={4}>
          <CheckboxFilter name="rqth" label="RQTH" onChange={handleChange} />
        </Grid>
      </Grid>
    </FilterAccordion>
  );
}

const formatLabelBasedOptions = options => {
  return options
    ? options.map(option => ({ ...option, value: option.label }))
    : null;
};
const fetchSchoolOptions = ({ search = null, id = null }) =>
  services.options.getSchools({
    search: search,
    id: id,
  });

export default CandidateFilters;
