import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';

import {
  getOneCreditNote,
  createCreditNote,
  updateCreditNote,
  cleanCurrentCreditNote,
} from 'redux/creditNotes/actions';
import { getOneInvoice, cleanCurrentInvoice } from 'redux/invoices/actions';

import { CreditNoteFormSchema } from 'utils/formSchemas';
import { initialCreditNote } from '../constants';

import Form from './form';
import Main from 'components/layout/Main';
import Title from 'components/layout/Title';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import S from './styles.js';

function CreditNoteForm(props) {
  const { classes } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const params = useParams();

  // Create case
  const invoiceBusinessId = params.invoiceBusinessId || null;
  const invoiceId = invoiceBusinessId
    ? invoiceBusinessId.substring(invoiceBusinessId.indexOf('-') + 1)
    : null;

  // Edit case
  const creditNoteBusinessId = params.creditNoteBusinessId || null;
  const creditNoteId = creditNoteBusinessId
    ? creditNoteBusinessId.substring(creditNoteBusinessId.indexOf('-') + 1)
    : null;

  useEffect(
    () => {
      if (creditNoteId) {
        console.log('call get one creditNote with id: ', creditNoteId);
        dispatch(getOneCreditNote({ id: creditNoteId }));
      } else {
        console.log('call get one invoice with id: ', invoiceId);
        dispatch(getOneInvoice({ id: invoiceId }));
      }
      return () => {
        dispatch(cleanCurrentCreditNote());
        dispatch(cleanCurrentInvoice());
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const invoiceState = useSelector(state => state.invoices);
  const currentInvoice =
    invoiceId !== null &&
    invoiceState.current !== null &&
    invoiceState.current.id === Number(invoiceId)
      ? invoiceState.current
      : null;
  const invoiceAmount =
    currentInvoice !== null ? currentInvoice.amountValue : null;

  const creditNoteState = useSelector(state => state.creditNotes);
  let creditNote =
    creditNoteId !== null &&
    creditNoteState.current !== null &&
    creditNoteState.current.id === Number(creditNoteId)
      ? creditNoteState.current
      : null;
  creditNote =
    creditNoteId === null ? initialCreditNote(invoiceAmount) : creditNote;

  // Update current Invoice values and dispatch request
  const handleOnSubmit = values => {
    const data = {
      invoiceId: Number(invoiceId),
      amount: Number(values.amount),
      tva: values.tva,
    };
    if (creditNoteId !== null) {
      data.id = creditNoteId;
      console.log('call update creditNote with data: ', data);
      dispatch(updateCreditNote(data));
    } else {
      console.log('call create creditNote with data: ', data);
      dispatch(createCreditNote(data));
    }
  };

  return (
    <Main
      pageTitle={t('menu.invoicingTitle')}
      isLoading={creditNoteState.loading}
    >
      <>
        <Title>
          {t('invoicing.creditNotes.create')} {invoiceBusinessId}
        </Title>
        <Paper className={classes.root}>
          {creditNote &&
            (creditNoteId || invoiceAmount) && (
              <Formik
                initialValues={creditNote}
                validationSchema={CreditNoteFormSchema}
                onSubmit={handleOnSubmit}
              >
                {props => <Form {...props} />}
              </Formik>
            )}
        </Paper>
      </>
    </Main>
  );
}

export default withStyles(S)(CreditNoteForm);
