import { useTranslation } from 'react-i18next';

import { internshipTypeId } from 'utils/constants';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, TextField } from '@material-ui/core';
import { Subtitle } from '../subtitle';

export default function InternshipIndexation(props) {
  const {
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    classes,
    getFormOptions,
  } = props;

  const { t } = useTranslation();
  const { domainOptions } = getFormOptions();
  const { levelOptions } = getFormOptions();

  return (
    <Box>
      {domainOptions && (
        <Box>
          <Subtitle label="Indexation Stage" />

          <Box className={classes.row}>
            <Autocomplete
              multiple
              id="internshipDomains"
              className={classes.selectLargeField}
              options={domainOptions[internshipTypeId]}
              getOptionLabel={option => option.label}
              groupBy={option => option.groupLabel}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              renderInput={params => (
                <TextField
                  {...params}
                  id="internshipDomains"
                  variant="standard"
                  label={t('jobboards-posting.field.internshipDomains')}
                  fullWidth
                  error={
                    touched.internshipDomains &&
                    Boolean(errors.internshipDomains)
                  }
                  helperText={
                    touched.internshipDomains ? errors.internshipDomains : ''
                  }
                />
              )}
              onChange={(event, value) => {
                setFieldValue('internshipDomains', value);
                setFieldTouched('internshipDomains', true, false);
              }}
            />{' '}
          </Box>

          <Box className={classes.row}>
            <Autocomplete
              multiple
              id="internshipLevels"
              className={classes.selectLargeField}
              options={levelOptions}
              getOptionLabel={option => option.label}
              groupBy={option => option.groupLabel}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              renderInput={params => (
                <TextField
                  {...params}
                  id="internshipLevels"
                  variant="standard"
                  label={t('jobboards-posting.field.internshipLevels')}
                  fullWidth
                  error={
                    touched.internshipLevels && Boolean(errors.internshipLevels)
                  }
                  helperText={
                    touched.internshipLevels ? errors.internshipLevels : ''
                  }
                />
              )}
              onChange={(event, value) => {
                setFieldValue('internshipLevels', value);
                setFieldTouched('internshipLevels', true, false);
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
