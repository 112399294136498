import { ResizableBox } from 'react-resizable';
import styled from 'styled-components';

const Resizable = ({ height, width, children }) => (
  <StyledResizable height={height} width={width}>
    <ContentContainer>{children}</ContentContainer>
  </StyledResizable>
);
const StyledResizable = styled(ResizableBox)`
  position: relative;
  height: inherit;
  padding-right: 10px;
  .react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: 0px;
    right: 0px;
    background:
      url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
      background-position: bottom right;
    background-repeat: no-repeat;
    cursor: se-resize;
  },
`;

const ContentContainer = styled.div`
  padding-right: 5px;
  height: inherit;
  overflow-y: auto;
`;

export default Resizable;
