import { darkBlueFont, redFont } from 'utils/constants';

const irpTooltip = (
  <>
    Programme de relance => Étape actuelle (nombre de jour avant prochaine
    étape)
    <br />
    <br />
    <li>Étape 1 : Mail de relance</li>
    <li>Étape 2 : Dernière relance avant mise en demeure</li>
    <li>Étape 3 : Mise en demeure</li>
    <li>Étape 4 : Injonction de payer</li>
  </>
);

export const tableColumns = t => [
  {
    header: t('invoicing.invoices.detail.table.company'),
    accessors: ['company'],
    isSortable: true,
    type: 'image',
  },
  {
    header: t('invoicing.invoices.detail.table.id'),
    accessors: ['invoiceId'],
    isSortable: true,
  },
  {
    header: t('invoicing.invoices.detail.table.type'),
    accessors: ['type'],
  },
  {
    header: t('invoicing.invoices.detail.table.label'),
    accessors: ['offer', 'label'],
    isSortable: true,
    headerStyle: {
      minWidth: '110px',
    },
    style: {
      fontWeight: 'bold',
    },
  },
  {
    header: t('invoicing.invoices.detail.table.pris'),
    accessors: ['candidate', 'label'],
  },
  {
    header: t('invoicing.invoices.detail.table.amount'),
    suffix: '€',
    accessors: ['amount'],
    isSortable: true,
    style: {
      fontWeight: 'bold',
    },
  },
  {
    header: t('invoicing.invoices.detail.table.date'),
    accessors: ['date'],
    isSortable: true,
    type: 'date',
  },
  {
    header: t('invoicing.invoices.detail.table.daysToPay'),
    accessors: ['daysToPay'],
    suffix: 'j',
  },
  {
    header: t('invoicing.invoices.detail.table.issuance'),
    suffix: 'j',
    accessors: ['issuance'],
  },
  {
    header: t('invoicing.invoices.detail.table.invoiceReminder'),
    accessors: ['invoiceReminder'],
    isSortable: true,
    style: { minWidth: '80px' },
  },
  {
    header: t('invoicing.invoices.detail.table.irpState'),
    tooltip: irpTooltip,
    accessors: ['irpState'],
    style: { minWidth: '50px' },
  },
  {
    header: t('invoicing.invoices.detail.table.transactionSG'),
    accessors: ['transactionSG'],
  },
  {
    header: t('invoicing.invoices.detail.table.paymentDate'),
    accessors: ['paymentDate'],
    isSortable: true,
    type: 'date',
  },
  {
    header: t('invoicing.invoices.detail.table.applyTva'),
    accessors: ['tva'],
    type: 'boolean',
  },
];

export const typesOptions = [
  { label: 'Autre', value: 'autre' },
  { label: 'RPO', value: 'rpo' },
];

export const initialInvoice = {
  type: typesOptions[0],
  company: null,
  amount: null,
  comment: null,
  message: null,
  daysToPay: 15,
  tva: true,
};

/*
 * Set format for invoice Table
 */
export const invoiceTableFormat = invoices =>
  invoices.map(invoice => {
    const invoiceTableFormat = Object.assign({}, invoice);
    // Set styles for invoice lines depending of line status
    if (invoice.paymentDate) {
      invoiceTableFormat.textDecoration = darkBlueFont;
      return invoiceTableFormat;
    } else if (invoice.issuance >= invoice.daysToPay) {
      invoiceTableFormat.textDecoration = redFont;
      return invoiceTableFormat;
    }
    return invoiceTableFormat;
  });
