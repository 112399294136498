import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const panelStyle = {
  flex: 1,
  marginBottom: 0,
  boxShadow:
    'rgba(0, 0, 0, 0.12) 0px 1px 0px 0px, rgba(0, 0, 0, 0.05) 1px 0px 0px 0px',
};

function Filter(props) {
  return (
    <Accordion
      TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
      style={panelStyle}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        Filtres
      </AccordionSummary>

      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
}

export default Filter;
