import { useTranslation } from 'react-i18next';
import { initialCompany } from './helper';

import Main from 'components/layout/Main';
import Title from 'components/layout/Title';
import CompanyForm from './companyForm';
import { Paper } from '@material-ui/core';
import { useCreateCompany } from 'utils/hooks/company';

export default function CreateCompanyForm() {
  const { t } = useTranslation();

  const createCompany = useCreateCompany();

  const handleSubmit = values => createCompany(values);

  return (
    <Main pageTitle={t('company.title')}>
      <Title>{t('company.create')}</Title>
      <Paper>
        <CompanyForm company={initialCompany} onSubmit={handleSubmit} />
      </Paper>
    </Main>
  );
}
