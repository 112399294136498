import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from 'components/core/input';
import { useFetchOffers } from 'utils/hooks/formOptions';

function Offer({ values, errors, touched, onChange }) {
  const { t } = useTranslation();
  const offerOptions = useFetchOfferOptions(values.company?.value);

  return (
    <Autocomplete
      name="offer"
      value={values.offer}
      options={offerOptions}
      label={t('paf.field.offer')}
      touched={touched.offer}
      error={errors.offer}
      onChange={onChange}
      creatable
    />
  );
}

const useFetchOfferOptions = companyId => {
  const [fetch, offerOptions, cleanOptions] = useFetchOffers();

  useEffect(
    () => {
      const isKnownCompany = companyId > 0;
      if (isKnownCompany) {
        fetch({
          onlyActive: true,
          companyId,
        });
      } else {
        cleanOptions();
      }
    },
    [companyId, fetch, cleanOptions]
  );

  return offerOptions;
};

export default Offer;
