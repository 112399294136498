import { fetch } from 'utils/api';
import services from 'utils/api/Services/services';
import { actionRequest, actionSuccess, actionFailure } from 'utils/functions';
import { formGroupedOptionsValidator } from 'utils/schemas';

export const ACTIONS_TYPE = Object.freeze({
  GET_LETUDIANT_DOMAIN_OPTIONS_BEGIN: 'GET_LETUDIANT_DOMAIN_OPTIONS_BEGIN',
  GET_LETUDIANT_DOMAIN_OPTIONS_SUCCESS: 'GET_LETUDIANT_DOMAIN_OPTIONS_SUCCESS',
  GET_LETUDIANT_DOMAIN_OPTIONS_FAILURE: 'GET_LETUDIANT_DOMAIN_OPTIONS_FAILURE',

  GET_LETUDIANT_OFFER_TYPE_OPTIONS_BEGIN:
    'GET_LETUDIANT_OFFER_TYPE_OPTIONS_BEGIN',
  GET_LETUDIANT_OFFER_TYPE_OPTIONS_SUCCESS:
    'GET_LETUDIANT_OFFER_TYPE_OPTIONS_SUCCESS',
  GET_LETUDIANT_OFFER_TYPE_OPTIONS_FAILURE:
    'GET_LETUDIANT_OFFER_TYPE_OPTIONS_FAILURE',

  GET_LETUDIANT_EXPERIENCE_OPTIONS_BEGIN:
    'GET_LETUDIANT_EXPERIENCE_OPTIONS_BEGIN',
  GET_LETUDIANT_EXPERIENCE_OPTIONS_SUCCESS:
    'GET_LETUDIANT_EXPERIENCE_OPTIONS_SUCCESS',
  GET_LETUDIANT_EXPERIENCE_OPTIONS_FAILURE:
    'GET_LETUDIANT_EXPERIENCE_OPTIONS_FAILURE',

  GET_LETUDIANT_CONTRACT_OPTIONS_BEGIN: 'GET_LETUDIANT_CONTRACT_OPTIONS_BEGIN',
  GET_LETUDIANT_CONTRACT_OPTIONS_SUCCESS:
    'GET_LETUDIANT_CONTRACT_OPTIONS_SUCCESS',
  GET_LETUDIANT_CONTRACT_OPTIONS_FAILURE:
    'GET_LETUDIANT_CONTRACT_OPTIONS_FAILURE',

  GET_LETUDIANT_WORK_AND_STUDY_CONTRACT_OPTIONS_BEGIN:
    'GET_LETUDIANT_WORK_AND_STUDY_CONTRACT_OPTIONS_BEGIN',
  GET_LETUDIANT_WORK_AND_STUDY_CONTRACT_OPTIONS_SUCCESS:
    'GET_LETUDIANT_WORK_AND_STUDY_CONTRACT_OPTIONS_SUCCESS',
  GET_LETUDIANT_WORK_AND_STUDY_CONTRACT_OPTIONS_FAILURE:
    'GET_LETUDIANT_WORK_AND_STUDY_CONTRACT_OPTIONS_FAILURE',

  GET_LETUDIANT_DURATION_OPTIONS_BEGIN: 'GET_LETUDIANT_DURATION_OPTIONS_BEGIN',
  GET_LETUDIANT_DURATION_OPTIONS_SUCCESS:
    'GET_LETUDIANT_DURATION_OPTIONS_SUCCESS',
  GET_LETUDIANT_DURATION_OPTIONS_FAILURE:
    'GET_LETUDIANT_DURATION_OPTIONS_FAILURE',

  GET_LETUDIANT_LEVEL_OPTIONS_BEGIN: 'GET_LETUDIANT_LEVEL_OPTIONS_BEGIN',
  GET_LETUDIANT_LEVEL_OPTIONS_SUCCESS: 'GET_LETUDIANT_LEVEL_OPTIONS_SUCCESS',
  GET_LETUDIANT_LEVEL_OPTIONS_FAILURE: 'GET_LETUDIANT_LEVEL_OPTIONS_FAILURE',

  GET_LETUDIANT_FUNCTION_OPTIONS_BEGIN: 'GET_LETUDIANT_FUNCTION_OPTIONS_BEGIN',
  GET_LETUDIANT_FUNCTION_OPTIONS_SUCCESS:
    'GET_LETUDIANT_FUNCTION_OPTIONS_SUCCESS',
  GET_LETUDIANT_FUNCTION_OPTIONS_FAILURE:
    'GET_LETUDIANT_FUNCTION_OPTIONS_FAILURE',

  GET_OFFER_OPTIONS_BEGIN: 'GET_OFFER_OPTIONS_BEGIN',
  GET_OFFER_OPTIONS_SUCCESS: 'GET_OFFER_OPTIONS_SUCCESS',
  GET_OFFER_OPTIONS_FAILURE: 'GET_OFFER_OPTIONS_FAILURE',
  CLEAN_OFFER_OPTIONS: 'CLEAN_OFFER_OPTIONS',

  GET_ADMIN_OPTIONS_BEGIN: 'GET_ADMIN_OPTIONS_BEGIN',
  GET_ADMIN_OPTIONS_SUCCESS: 'GET_ADMIN_OPTIONS_SUCCESS',
  GET_ADMIN_OPTIONS_FAILURE: 'GET_ADMIN_OPTIONS_FAILURE',

  GET_CONTRACT_TYPE_OPTIONS_BEGIN: 'GET_CONTRACT_TYPE_OPTIONS_BEGIN',
  GET_CONTRACT_TYPE_OPTIONS_SUCCESS: 'GET_CONTRACT_TYPE_OPTIONS_SUCCESS',
  GET_CONTRACT_TYPE_OPTIONS_FAILURE: 'GET_CONTRACT_TYPE_OPTIONS_FAILURE',

  GET_COMPANY_OPTIONS_BEGIN: 'GET_COMPANY_OPTIONS_BEGIN',
  GET_COMPANY_OPTIONS_SUCCESS: 'GET_COMPANY_OPTIONS_SUCCESS',
  GET_COMPANY_OPTIONS_FAILURE: 'GET_COMPANY_OPTIONS_FAILURE',

  GET_CANDIDATE_OPTIONS_BEGIN: 'GET_CANDIDATE_OPTIONS_BEGIN',
  GET_CANDIDATE_OPTIONS_SUCCESS: 'GET_CANDIDATE_OPTIONS_SUCCESS',
  GET_CANDIDATE_OPTIONS_FAILURE: 'GET_CANDIDATE_OPTIONS_FAILURE',

  GET_INVOICE_OPTIONS_BEGIN: 'GET_INVOICE_OPTIONS_BEGIN',
  GET_INVOICE_OPTIONS_SUCCESS: 'GET_INVOICE_OPTIONS_SUCCESS',
  GET_INVOICE_OPTIONS_FAILURE: 'GET_INVOICE_OPTIONS_FAILURE',

  GET_RECRUITER_OPTIONS_BEGIN: 'GET_RECRUITER_OPTIONS_BEGIN',
  GET_RECRUITER_OPTIONS_SUCCESS: 'GET_RECRUITER_OPTIONS_SUCCESS',
  GET_RECRUITER_OPTIONS_FAILURE: 'GET_RECRUITER_OPTIONS_FAILURE',

  GET_FUNCTION_BY_FAMILY_OPTIONS_BEGIN: 'GET_FUNCTION_BY_FAMILY_OPTIONS_BEGIN',
  GET_FUNCTION_BY_FAMILY_OPTIONS_SUCCESS:
    'GET_FUNCTION_BY_FAMILY_OPTIONS_SUCCESS',
  GET_FUNCTION_BY_FAMILY_OPTIONS_FAILURE:
    'GET_FUNCTION_BY_FAMILY_OPTIONS_FAILURE',

  GET_STUDY_LEVEL_OPTIONS_BEGIN: 'GET_STUDY_LEVEL_OPTIONS_BEGIN',
  GET_STUDY_LEVEL_OPTIONS_SUCCESS: 'GET_STUDY_LEVEL_OPTIONS_SUCCESS',
  GET_STUDY_LEVEL_OPTIONS_FAILURE: 'GET_STUDY_LEVEL_OPTIONS_FAILURE',

  GET_BUSINESS_SECTOR_OPTIONS_BEGIN: 'GET_BUSINESS_SECTOR_OPTIONS_BEGIN',
  GET_BUSINESS_SECTOR_OPTIONS_SUCCESS: 'GET_BUSINESS_SECTOR_OPTIONS_SUCCESS',
  GET_BUSINESS_SECTOR_OPTIONS_FAILURE: 'GET_BUSINESS_SECTOR_OPTIONS_FAILURE',

  GET_COMPANY_SIZE_OPTIONS_BEGIN: 'GET_COMPANY_SIZE_OPTIONS_BEGIN',
  GET_COMPANY_SIZE_OPTIONS_SUCCESS: 'GET_COMPANY_SIZE_OPTIONS_SUCCESS',
  GET_COMPANY_SIZE_OPTIONS_FAILURE: 'GET_COMPANY_SIZE_OPTIONS_FAILURE',

  GET_SOURCE_OPTIONS_BEGIN: 'GET_SOURCE_OPTIONS_BEGIN',
  GET_SOURCE_OPTIONS_SUCCESS: 'GET_SOURCE_OPTIONS_SUCCESS',
  GET_SOURCE_OPTIONS_FAILURE: 'GET_SOURCE_OPTIONS_FAILURE',

  GET_PLAN_OPTIONS_BEGIN: 'GET_PLAN_OPTIONS_BEGIN',
  GET_PLAN_OPTIONS_SUCCESS: 'GET_PLAN_OPTIONS_SUCCESS',
  GET_PLAN_OPTIONS_FAILURE: 'GET_PLAN_OPTIONS_FAILURE',

  GET_SCHOOL_TYPE_OPTIONS_BEGIN: 'GET_SCHOOL_TYPE_OPTIONS_BEGIN',
  GET_SCHOOL_TYPE_OPTIONS_SUCCESS: 'GET_SCHOOL_TYPE_OPTIONS_SUCCESS',
  GET_SCHOOL_TYPE_OPTIONS_FAILURE: 'GET_SCHOOL_TYPE_OPTIONS_FAILURE',

  GET_SCHOOL_OPTIONS_BEGIN: 'GET_SCHOOL_OPTIONS_BEGIN',
  GET_SCHOOL_OPTIONS_SUCCESS: 'GET_SCHOOL_OPTIONS_SUCCESS',
  GET_SCHOOL_OPTIONS_FAILURE: 'GET_SCHOOL_OPTIONS_FAILURE',

  GET_PROFILE_OPTIONS_BEGIN: 'GET_PROFILE_OPTIONS_BEGIN',
  GET_PROFILE_OPTIONS_SUCCESS: 'GET_PROFILE_OPTIONS_SUCCESS',
  GET_PROFILE_OPTIONS_FAILURE: 'GET_PROFILE_OPTIONS_FAILURE',

  GET_AMOUNT_OPTIONS_BY_OFFER_BEGIN: 'GET_AMOUNT_OPTIONS_BY_OFFER_BEGIN',
  GET_AMOUNT_OPTIONS_BY_OFFER_SUCCESS: 'GET_AMOUNT_OPTIONS_BY_OFFER_SUCCESS',
  GET_AMOUNT_OPTIONS_BY_OFFER_FAILURE: 'GET_AMOUNT_OPTIONS_BY_OFFER_FAILURE',
  CLEAN_AMOUNT_OPTIONS: 'CLEAN_AMOUNT_OPTIONS',

  GET_HIRED_CANDIDATE_OPTIONS_BY_OFFER_BEGIN:
    'GET_HIRED_CANDIDATE_OPTIONS_BY_OFFER_BEGIN',
  GET_HIRED_CANDIDATE_OPTIONS_BY_OFFER_SUCCESS:
    'GET_HIRED_CANDIDATE_OPTIONS_BY_OFFER_SUCCESS',
  GET_HIRED_CANDIDATE_OPTIONS_BY_OFFER_FAILURE:
    'GET_HIRED_CANDIDATE_OPTIONS_BY_OFFER_FAILURE',

  GET_RESEARCH_STATUS_OPTIONS_BEGIN: 'GET_RESEARCH_STATUS_OPTIONS_BEGIN',
  GET_RESEARCH_STATUS_OPTIONS_SUCCESS: 'GET_RESEARCH_STATUS_OPTIONS_SUCCESS',
  GET_RESEARCH_STATUS_OPTIONS_FAILURE: 'GET_RESEARCH_STATUS_OPTIONS_FAILURE',

  GET_CITY_OPTIONS_BEGIN: 'GET_CITY_OPTIONS_BEGIN',
  GET_CITY_OPTIONS_SUCCESS: 'GET_CITY_OPTIONS_SUCCESS',
  GET_CITY_OPTIONS_FAILURE: 'GET_CITY_OPTIONS_FAILURE',

  GET_TELEWORKING_OPTIONS_BEGIN: 'GET_TELEWORKING_OPTIONS_BEGIN',
  GET_TELEWORKING_OPTIONS_SUCCESS: 'GET_TELEWORKING_OPTIONS_SUCCESS',
  GET_TELEWORKING_OPTIONS_FAILURE: 'GET_TELEWORKING_OPTIONS_FAILURE',

  GET_RECRUITER_ROLE_OPTIONS_BEGIN: 'GET_RECRUITER_ROLE_OPTIONS_BEGIN',
  GET_RECRUITER_ROLE_OPTIONS_SUCCESS: 'GET_RECRUITER_ROLE_OPTIONS_SUCCESS',
  GET_RECRUITER_ROLE_OPTIONS_FAILURE: 'GET_RECRUITER_ROLE_OPTIONS_FAILURE',

  GET_FUNCTION_OPTIONS_BEGIN: 'GET_FUNCTION_OPTIONS_BEGIN',
  GET_FUNCTION_OPTIONS_SUCCESS: 'GET_FUNCTION_OPTIONS_SUCCESS',
  GET_FUNCTION_OPTIONS_FAILURE: 'GET_FUNCTION_OPTIONS_FAILURE',

  GET_PROPOSAL_STATUS_OPTIONS_BEGIN: 'GET_PROPOSAL_STATUS_OPTIONS_BEGIN',
  GET_PROPOSAL_STATUS_OPTIONS_SUCCESS: 'GET_PROPOSAL_STATUS_OPTIONS_SUCCESS',
  GET_PROPOSAL_STATUS_OPTIONS_FAILURE: 'GET_PROPOSAL_STATUS_OPTIONS_FAILURE',

  GET_PROPOSAL_MODELS_BEGIN: 'GET_PROPOSAL_MODELS_BEGIN',
  GET_PROPOSAL_MODELS_SUCCESS: 'GET_PROPOSAL_MODELS_SUCCESS',
  GET_PROPOSAL_MODELS_FAILURE: 'GET_PROPOSAL_MODELS_FAILURE',
  CLEAN_PROPOSAL_MODELS: 'CLEAN_PROPOSAL_MODELS',
});

export const cleanProposalModels = () => ({
  type: ACTIONS_TYPE.CLEAN_PROPOSAL_MODELS,
});

export const getProposalModels = companyId => (dispatch, getState) =>
  fetch({
    service: services.commercialProposals.get({ companyId }),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_PROPOSAL_MODELS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_PROPOSAL_MODELS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_PROPOSAL_MODELS_FAILURE, errors),
  });

export const getCommercialProposalStatusOptions = () => (dispatch, getState) =>
  fetch({
    service: services.options.getCommercialProposalStatus(),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_PROPOSAL_STATUS_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_PROPOSAL_STATUS_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_PROPOSAL_STATUS_OPTIONS_FAILURE, errors),
  });

export const getRecruiterRoleOptions = () => (dispatch, getState) =>
  fetch({
    service: services.options.getRecruiterRoles(),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_RECRUITER_ROLE_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_RECRUITER_ROLE_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_RECRUITER_ROLE_OPTIONS_FAILURE, errors),
  });

export const getTeleworkingOptions = () => (dispatch, getState) =>
  fetch({
    service: services.options.getTeleworking(),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_TELEWORKING_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_TELEWORKING_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_TELEWORKING_OPTIONS_FAILURE, errors),
  });

export const getCityOptions = params => (dispatch, getState) =>
  fetch({
    service: services.options.getCities(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_CITY_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_CITY_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_CITY_OPTIONS_FAILURE, errors),
  });

export const getResearchStatusOptions = params => (dispatch, getState) =>
  fetch({
    service: services.options.getResearchStatusOptions(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_RESEARCH_STATUS_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_RESEARCH_STATUS_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_RESEARCH_STATUS_OPTIONS_FAILURE, errors),
  });

export const getHiredCandidateOptions = params => (dispatch, getState) =>
  fetch({
    service: services.options.getHiredCandidateOptions(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_HIRED_CANDIDATE_OPTIONS_BY_OFFER_BEGIN),
    actionSuccess: result =>
      actionSuccess(
        ACTIONS_TYPE.GET_HIRED_CANDIDATE_OPTIONS_BY_OFFER_SUCCESS,
        result
      ),
    actionFailure: errors =>
      actionFailure(
        ACTIONS_TYPE.GET_HIRED_CANDIDATE_OPTIONS_BY_OFFER_FAILURE,
        errors
      ),
  });

export const cleanAmountOptions = () => ({
  type: ACTIONS_TYPE.CLEAN_AMOUNT_OPTIONS,
});

export const getAmountOptions = params => (dispatch, getState) =>
  fetch({
    service: services.options.getAmountOptions(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_AMOUNT_OPTIONS_BY_OFFER_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_AMOUNT_OPTIONS_BY_OFFER_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_AMOUNT_OPTIONS_BY_OFFER_FAILURE, errors),
  });

export const getProfileOptions = () => (dispatch, getState) =>
  fetch({
    service: services.options.getProfiles(),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_PROFILE_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_PROFILE_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_PROFILE_OPTIONS_FAILURE, errors),
  });

export const getSchoolOptions = params => (dispatch, getState) =>
  fetch({
    service: services.options.getRecruiters(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_SCHOOL_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_SCHOOL_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_SCHOOL_OPTIONS_FAILURE, errors),
  });

export const getSchoolTypeOptions = () => (dispatch, getState) =>
  fetch({
    service: services.options.getSchoolTypes(),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_SCHOOL_TYPE_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_SCHOOL_TYPE_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_SCHOOL_TYPE_OPTIONS_FAILURE, errors),
  });

export const getPlanOptions = () => (dispatch, getState) =>
  fetch({
    service: services.options.getPlans(),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_PLAN_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_PLAN_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_PLAN_OPTIONS_FAILURE, errors),
  });

export const getSourceOptions = ({ type }) => (dispatch, getState) =>
  fetch({
    service: services.options.getSources({ type }),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_SOURCE_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_SOURCE_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_SOURCE_OPTIONS_FAILURE, errors),
  });

export const getCompanySizeOptions = () => (dispatch, getState) =>
  fetch({
    service: services.options.getCompanySizes(),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_COMPANY_SIZE_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_COMPANY_SIZE_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_COMPANY_SIZE_OPTIONS_FAILURE, errors),
  });

export const getBusinessSectorOptions = () => (dispatch, getState) =>
  fetch({
    service: services.options.getBusinessSectors(),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_BUSINESS_SECTOR_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_BUSINESS_SECTOR_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_BUSINESS_SECTOR_OPTIONS_FAILURE, errors),
  });

export const getStudyLevelOptions = () => (dispatch, getState) =>
  fetch({
    service: services.options.getStudyLevels(),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_STUDY_LEVEL_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_STUDY_LEVEL_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_STUDY_LEVEL_OPTIONS_FAILURE, errors),
  });

export const getFunctionOptions = () => (dispatch, getState) =>
  fetch({
    service: services.options.getFunctions(),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_FUNCTION_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_FUNCTION_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_FUNCTION_OPTIONS_FAILURE, errors),
  });

export const getFunctionByFamilyOptions = () => (dispatch, getState) =>
  fetch({
    service: services.options.getFunctionsGroupedByFamily(),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_FUNCTION_BY_FAMILY_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(
        ACTIONS_TYPE.GET_FUNCTION_BY_FAMILY_OPTIONS_SUCCESS,
        result
      ),
    actionFailure: errors =>
      actionFailure(
        ACTIONS_TYPE.GET_FUNCTION_BY_FAMILY_OPTIONS_FAILURE,
        errors
      ),
  });

export const getRecruiterOptions = params => (dispatch, getState) =>
  fetch({
    service: services.options.getRecruiters(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_RECRUITER_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_RECRUITER_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_RECRUITER_OPTIONS_FAILURE, errors),
  });

export const getActiveInvoiceOptions = params => (dispatch, getState) =>
  fetch({
    service: services.options.getActiveInvoices(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_INVOICE_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_INVOICE_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_INVOICE_OPTIONS_FAILURE, errors),
  });

export const getLetudiantLevels = () => (dispatch, getState) =>
  fetch({
    service: services.jobboards.letudiant.getLevels(),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_LETUDIANT_LEVEL_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_LETUDIANT_LEVEL_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_LETUDIANT_LEVEL_OPTIONS_FAILURE, errors),
    validator: formGroupedOptionsValidator,
  });

export const getLetudiantFunctions = () => (dispatch, getState) =>
  fetch({
    service: services.jobboards.letudiant.getFunctions(),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_LETUDIANT_FUNCTION_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(
        ACTIONS_TYPE.GET_LETUDIANT_FUNCTION_OPTIONS_SUCCESS,
        result
      ),
    actionFailure: errors =>
      actionFailure(
        ACTIONS_TYPE.GET_LETUDIANT_FUNCTION_OPTIONS_FAILURE,
        errors
      ),
    validator: formGroupedOptionsValidator,
  });

export const getLetudiantDurations = params => (dispatch, getState) =>
  fetch({
    service: services.jobboards.letudiant.getDurations(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_LETUDIANT_DURATION_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_LETUDIANT_DURATION_OPTIONS_SUCCESS, {
        offerTypeId: params.offerTypeId,
        result: result,
      }),
    actionFailure: errors =>
      actionFailure(
        ACTIONS_TYPE.GET_LETUDIANT_DURATION_OPTIONS_FAILURE,
        errors
      ),
    validator: formGroupedOptionsValidator,
  });

export const getLetudiantWorkAndStudyContracts = () => (dispatch, getState) =>
  fetch({
    service: services.jobboards.letudiant.getWorkAndStudyContracts(),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(
        ACTIONS_TYPE.GET_LETUDIANT_WORK_AND_STUDY_CONTRACT_OPTIONS_BEGIN
      ),
    actionSuccess: result =>
      actionSuccess(
        ACTIONS_TYPE.GET_LETUDIANT_WORK_AND_STUDY_CONTRACT_OPTIONS_SUCCESS,
        result
      ),
    actionFailure: errors =>
      actionFailure(
        ACTIONS_TYPE.GET_LETUDIANT_WORK_AND_STUDY_CONTRACT_OPTIONS_FAILURE,
        errors
      ),
    validator: formGroupedOptionsValidator,
  });

export const getLetudiantContracts = params => (dispatch, getState) =>
  fetch({
    service: services.jobboards.letudiant.getContracts(),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_LETUDIANT_CONTRACT_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_LETUDIANT_CONTRACT_OPTIONS_SUCCESS, {
        offerTypeId: params.offerTypeId,
        result: result,
      }),
    actionFailure: errors =>
      actionFailure(
        ACTIONS_TYPE.GET_LETUDIANT_CONTRACT_OPTIONS_FAILURE,
        errors
      ),
    validator: formGroupedOptionsValidator,
  });

export const getLetudiantOfferTypes = () => (dispatch, getState) =>
  fetch({
    service: services.jobboards.letudiant.getOfferTypes(),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_LETUDIANT_OFFER_TYPE_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(
        ACTIONS_TYPE.GET_LETUDIANT_OFFER_TYPE_OPTIONS_SUCCESS,
        result
      ),
    actionFailure: errors =>
      actionFailure(
        ACTIONS_TYPE.GET_LETUDIANT_OFFER_TYPE_OPTIONS_FAILURE,
        errors
      ),
    validator: formGroupedOptionsValidator,
  });

export const getLetudiantDomains = params => (dispatch, getState) =>
  fetch({
    service: services.jobboards.letudiant.getDomains(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_LETUDIANT_DOMAIN_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_LETUDIANT_DOMAIN_OPTIONS_SUCCESS, {
        offerTypeId: params.offerTypeId,
        result: result,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_LETUDIANT_DOMAIN_OPTIONS_FAILURE, errors),
    validator: formGroupedOptionsValidator,
  });

export const getLetudiantExperiences = () => (dispatch, getState) =>
  fetch({
    service: services.jobboards.letudiant.getExperiences(),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_LETUDIANT_EXPERIENCE_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(
        ACTIONS_TYPE.GET_LETUDIANT_EXPERIENCE_OPTIONS_SUCCESS,
        result
      ),
    actionFailure: errors =>
      actionFailure(
        ACTIONS_TYPE.GET_LETUDIANT_EXPERIENCE_OPTIONS_FAILURE,
        errors
      ),
    validator: formGroupedOptionsValidator,
  });

export const getOfferOptions = params => (dispatch, getState) =>
  fetch({
    service: services.options.getOffers(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_OFFER_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_OFFER_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_LETUDIANT_OFFER_TYPES_FAILURE, errors),
    validator: formGroupedOptionsValidator,
  });

export const cleanOfferOptions = () => ({
  type: ACTIONS_TYPE.CLEAN_OFFER_OPTIONS,
});

export const getAdminOptions = params => (dispatch, getState) =>
  fetch({
    service: services.options.getAdmins(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_ADMIN_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_ADMIN_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_ADMIN_OPTIONS_FAILURE, errors),
    validator: formGroupedOptionsValidator,
  });

export const getContractTypeOptions = params => (dispatch, getState) =>
  fetch({
    service: services.options.getContractTypes(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_CONTRACT_TYPE_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_CONTRACT_TYPE_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_CONTRACT_TYPE_OPTIONS_FAILURE, errors),
    validator: formGroupedOptionsValidator,
  });

export const getCompanyOptions = params => (dispatch, getState) =>
  fetch({
    service: services.options.getCompanies(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_COMPANY_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_COMPANY_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_COMPANY_OPTIONS_FAILURE, errors),
    validator: formGroupedOptionsValidator,
  });

export const getCandidateOptions = params => (dispatch, getState) =>
  fetch({
    service: services.options.getCandidates(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_CANDIDATE_OPTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_CANDIDATE_OPTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_CANDIDATE_OPTIONS_FAILURE, errors),
    validator: formGroupedOptionsValidator,
  });
