import GetAppIcon from '@material-ui/icons/GetApp';
import ActionButton from 'components/ActionButton';

function DownloadAllButton(props) {
  const { action, filter, search } = props;

  const handleOnClick = () => {
    action({
      ...filter,
      filter: search,
    });
  };

  return (
    <ActionButton
      action={handleOnClick}
      label="Télécharger"
      icon={<GetAppIcon fontSize="small" />}
      tooltipPosition="left"
    />
  );
}

export default DownloadAllButton;
