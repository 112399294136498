import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  KeyboardArrowDown as DownIcon,
  KeyboardArrowUp as UpIcon,
} from '@material-ui/icons';

Accordion.propTypes = {
  label: PropTypes.any,
  children: PropTypes.element,
  lazy: PropTypes.bool,
  defaultOpen: PropTypes.bool,
};
Accordion.defaultProps = {
  label: null,
  children: null,
  defaultOpen: false,
};

export default function Accordion({ label, children, lazy, defaultOpen }) {
  const [open, setOpen] = useState(defaultOpen);

  const handleClick = () => {
    if (children) {
      setOpen(!open);
    }
  };

  const Icon = open ? UpIcon : DownIcon;
  return (
    <Container>
      <Header open={open} onClick={handleClick}>
        {label}
        {children && <Icon />}
      </Header>
      {lazy ? (
        open && <Panel open={open}>{children}</Panel>
      ) : (
        <Panel open={open}>{children}</Panel>
      )}
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  background-color: white;
  cursor: pointer;
  padding: 15px;
  width: 100%;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: ${({ open }) =>
    open ? '0px' : '1px solid rgba(0,0,0,0.12);'};
  border-top: 0px;
  border-left: 0px;
  outline: none;
  transition: 0.4s;
`;
const Panel = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 0px;
  border-left: 0px;
  padding: 15px;
`;
