import { getJobBoard } from './functions';

export const jobBoardsList = [
  { id: 1, key: 'letudiant', label: "L'Étudiant" },
  { id: 2, key: 'linkedin', label: 'LinkedIn' },
  { id: 3, key: 'jobteaser', label: 'JobTeaser' },
  { id: 4, key: 'apec', label: 'Apec' },
];

export const jobBoardsOptions = jobBoardsList.map(jobBoard => {
  return {
    value: jobBoard.id,
    label: jobBoard.label,
  };
});

const letudiantTooltip = (
  <>
    <li>Date de dernière diffusion</li>
    <br />
    <li>Jours depuis dernière diffusion</li>
    <br />
    <li>Total de jours de diffusion</li>
    <br />
    <li>Total de diffusions</li>
    <br />
    <li>TP si top liste</li>
  </>
);

const standardTooltip = (
  <>
    <li>Date de dernière diffusion</li>
    <br />
    <li>Jours depuis dernière diffusion</li>
    <br />
    <li>Total de jours de diffusion</li>
    <br />
    <li>Total de diffusions</li>
  </>
);

export const tableColumns = () => [
  {
    header: 'Entreprise',
    accessors: ['offer', 'company'],
    type: 'image',
  },
  {
    header: 'Recruteurs',
    accessors: ['offer', 'admins'],
    headerStyle: {
      width: '10%',
    },
  },
  {
    header: 'Offre',
    accessors: ['offer', 'label'],
    headerStyle: { width: '25%' },
    style: { textAlign: 'left' },
    type: 'link',
  },
  {
    header: 'Contrat',
    accessors: ['offer', 'contractType', 'label'],
    headerStyle: {
      width: '7%',
    },
  },
  {
    header: 'En ligne depuis',
    accessors: ['offer', 'createDuration'],
    headerStyle: {
      width: '7%',
    },
  },
  {
    header: getJobBoard('letudiant').label,
    tooltip: letudiantTooltip,
    accessors: ['indicators', 'letudiant'],
    headerStyle: {
      textAlign: 'center',
      width: '10%',
      backgroundColor: '#d9ead3',
    },
    cellStyle: {
      backgroundColor: '#d9ead3',
    },
    cellStyleAccessor: 'letudiant',
    style: { textAlign: 'center' },
  },
  {
    header: getJobBoard('linkedin').label,
    tooltip: standardTooltip,
    accessors: ['indicators', 'linkedin'],
    headerStyle: {
      textAlign: 'center',
      width: '10%',
      backgroundColor: '#9fc5e8',
    },
    cellStyle: {
      backgroundColor: '#9fc5e8',
    },
    cellStyleAccessor: 'linkedin',
    style: { textAlign: 'center' },
  },
  {
    header: getJobBoard('jobteaser').label,
    tooltip: standardTooltip,
    accessors: ['indicators', 'jobteaser'],
    headerStyle: {
      backgroundColor: '#dd7e6b',
      textAlign: 'center',
      width: '10%',
    },
    cellStyle: {
      backgroundColor: '#dd7e6b',
    },
    cellStyleAccessor: 'jobteaser',
    style: { textAlign: 'center' },
  },
  {
    header: getJobBoard('apec').label,
    tooltip: standardTooltip,
    accessors: ['indicators', 'apec'],
    headerStyle: {
      backgroundColor: '#cfe2f3',
      textAlign: 'center',
      width: '10%',
    },
    cellStyle: {
      backgroundColor: '#cfe2f3',
    },
    cellStyleAccessor: 'apec',
    style: { textAlign: 'center' },
  },
];
