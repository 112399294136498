import { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useQueryState } from 'utils/hooks/queryState';
import { TabContainer, VerticalTabContainter } from './TabContainer';
import { Tab as BaseTab, VerticalTab } from './Tab';
import { useTranslation } from 'react-i18next';

function Tabs({ name, direction, tabs, defTab, onChange }) {
  const [query, setQuery] = useQueryState(null, name);
  const [active, setActive] = useState(query || defTab || tabs[0].key);

  const handleClick = ({ target: { id } }) => {
    const value = id;
    if (value !== active) {
      setActive(value);
      onChange({ value, name });
    }
  };

  useEffect(
    () => {
      setQuery(active);
    },
    [active, setQuery]
  );

  const Container =
    direction === 'vertical' ? VerticalTabContainter : TabContainer;

  const Tab = direction === 'vertical' ? VerticalTab : BaseTab;

  const { t } = useTranslation();

  return (
    <>
      <Container>
        {tabs.map(tab => (
          <Tab
            id={tab.key}
            key={tab.key}
            active={String(active) === String(tab.key)}
            onClick={handleClick}
          >
            {typeof tab.label === 'string' ? t(tab.label) : tab.label}
          </Tab>
        ))}
      </Container>
    </>
  );
}

Tabs.propTypes = {
  name: PropTypes.string,
  direction: PropTypes.string,
  tabs: PropTypes.array,
  defTab: PropTypes.string,
  onChange: PropTypes.func,
};
Tabs.defaultProps = {
  name: 'tab',
  direction: 'horizontal',
  tabs: [],
  onChange: () => {},
};

export default memo(Tabs);
