import {
  Checkbox as CheckboxMui,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';

function Checkbox(props) {
  const { label, value, handleChange } = props;

  return (
    <FormGroup>
      <FormControlLabel
        label={label}
        control={
          <CheckboxMui
            id="familiarity"
            checked={value}
            onChange={() => {
              handleChange(!value);
            }}
            color="primary"
          />
        }
        //className={classes.checkbox}
        labelPlacement="end"
      />
    </FormGroup>
  );
}

export default Checkbox;
