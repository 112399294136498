import { memo } from 'react';
import { Delete } from '@material-ui/icons';
import ActionButton from 'components/ActionButton';

function RemoveButton(props) {
  const { action, id } = props;

  const handleOnClick = () => {
    action(parseInt(id));
  };

  return (
    <ActionButton
      action={handleOnClick}
      label="Supprimer"
      dialog
      title="Confirmation de suppression"
      contentText="Souhaites-tu vraiment supprimer cette ligne ?"
      confirmText="Oui"
      cancelText="Non"
      icon={<Delete fontSize="small" />}
      tooltipPosition="bottom"
    />
  );
}

export default memo(RemoveButton);
