export default theme => ({
  root: {
    width: 400,
  },
  panel: {
    marginTop: 15,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,
    marginLeft: 30,
    marginRight: 30,
  },
  row: {
    display: 'flex',
    marginTop: 20,
  },
  actionFields: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(5),
    display: 'flex',
  },
  rightFields: {
    textAlign: 'right',
    flex: 1,
  },
});
