import moment from 'moment-timezone';
import { getRandomColor, getWeeksPerYear } from 'utils/functions';

/**
 * Option from Line component
 */
export const options = {
  legend: {
    position: 'bottom',
  },
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        ticks: {
          display: true,
          autoSkip: true,
          maxTicksLimit: 10,
          maxRotation: 0,
          minRotation: 0,
        },
      },
    ],
  },
};

/**
 * Generate DataSets for Line component
 * @param stats date from Reducer
 */
export const getDataSets = stats => {
  if (stats === null) {
    return null;
  }
  return Object.keys(stats).map((key, i) => {
    let data = [];
    let years = stats[key];
    let keysYear = Object.keys(stats[key]);
    keysYear.map(key => {
      data = data.concat(Object.values(years[key]));
      return null;
    });

    return {
      label: key,
      data: data,
      borderColor: colors[i] ? colors[i] : getRandomColor(),
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      type: 'line',
      lineTension: 0.25,
      borderWidth: 2,
      pointRadius: 2,
      fill: false,
    };
  });
};

/**
 * Generate week labels for Line component
 * @param {*} fromDate
 * @param {*} toDate
 */
export const getLabels = (fromDate, toDate) => {
  const fromYear = fromDate.year();
  const toYear = toDate.year();
  let labels = [];
  for (let y = fromYear; y <= toYear; y++) {
    const fromWeek = y === fromYear ? fromDate.isoWeek() : 1;
    const toWeek = y === toYear ? toDate.isoWeek() : getWeeksPerYear(y);
    for (let w = fromWeek; w <= toWeek; w++) {
      if (y !== 12 && w !== 1) {
        labels.push(
          moment()
            .year(y)
            .isoWeek(w)
            .day(7)
            .format('DD/MM/YYYY')
        );
      }
    }
  }
  return labels;
};

/**
 * Generate month labels for Line component
 * @param {*} fromDate
 * @param {*} toDate
 */
export const getMonthLabels = (fromDate, toDate) => {
  const fromYear = fromDate.year();
  const toYear = toDate.year();
  let labels = [];
  for (let y = fromYear; y <= toYear; y++) {
    const fromMonth = y === fromYear ? fromDate.month() : 1;
    const toMonth = y === toYear ? toDate.month() : 12;
    for (let m = fromMonth; m <= toMonth; m++) {
      labels.push(
        moment()
          .year(y)
          .month(m)
          .format('MM/YYYY')
      );
    }
  }
  return labels;
};

const colors = [
  'green',
  'blue',
  'black',
  'red',
  'purple',
  'yellow',
  'darkred',
  'coral',
  'gold',
  'darkkhaki',
  'darkgreen',
  'darkturquoise',
  'fuchsia',
  'burlywood',
  'pink',
];
