import { fetch } from 'utils/api';
import services from 'utils/api/Services/services';
import { actionRequest, actionSuccess, actionFailure } from 'utils/functions';

export const ACTIONS_TYPE = Object.freeze({
  GET_RECRUITERS_BEGIN: 'GET_RECRUITERS_BEGIN',
  GET_RECRUITERS_SUCCESS: 'GET_RECRUITERS_SUCCESS',
  GET_RECRUITERS_FAILURE: 'GET_RECRUITERS_FAILURE',

  CLEAN_RECRUITERS: 'CLEAN_RECRUITERS',

  GET_ONE_RECRUITER_BEGIN: 'GET_ONE_RECRUITER_BEGIN',
  GET_ONE_RECRUITER_SUCCESS: 'GET_ONE_RECRUITER_SUCCESS',
  GET_ONE_RECRUITER_FAILURE: 'GET_ONE_RECRUITER_FAILURE',

  UPDATE_RECRUITER_BEGIN: 'UPDATE_RECRUITER_BEGIN',
  UPDATE_RECRUITER_SUCCESS: 'UPDATE_RECRUITER_SUCCESS',
  UPDATE_RECRUITER_FAILURE: 'UPDATE_RECRUITER_FAILURE',

  CREATE_RECRUITER_BEGIN: 'CREATE_RECRUITER_BEGIN',
  CREATE_RECRUITER_SUCCESS: 'CREATE_RECRUITER_SUCCESS',
  CREATE_RECRUITER_FAILURE: 'CREATE_RECRUITER_FAILURE',
});

export const cleanRecruiters = () => ({
  type: ACTIONS_TYPE.CLEAN_RECRUITERS,
});

export const createRecruiter = params => (dispatch, getState) =>
  fetch({
    service: services.recruiters.add(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.CREATE_RECRUITER_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.CREATE_RECRUITER_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.CREATE_RECRUITER_FAILURE, errors),
  });

export const updateRecruiter = params => (dispatch, getState) =>
  fetch({
    service: services.recruiters.update(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.UPDATE_RECRUITER_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.UPDATE_RECRUITER_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_RECRUITER_FAILURE, errors),
  });

export const getOneRecruiter = params => (dispatch, getState) =>
  fetch({
    service: services.recruiters.getOne(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_ONE_RECRUITER_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_ONE_RECRUITER_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_ONE_RECRUITER_FAILURE, errors),
  });

export const getRecruiters = params => (dispatch, getState) =>
  fetch({
    service: services.recruiters.get(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_RECRUITERS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_RECRUITERS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_RECRUITERS_FAILURE, errors),
  });
