export default () => ({
  button: {
    marginBottom: '2px',
    color: 'black',
    backgroundColor: 'transparent !important',
  },
  actionFields: {
    marginTop: 15,
  },
});
