import { useDispatch } from 'react-redux';
import CommentFlow from 'components/eiquem/shared/CommentFlow';

ApplicationComment.defaultProps = {
  applicationStates: [],
};

function ApplicationComment(props) {
  const {
    applicationId,
    researchId,
    values,
    onAdd,
    onUpdate,
    onDelete,
  } = props;
  const dispatch = useDispatch();

  const handleOnChange = comment => {
    const params = {
      researchId: researchId,
      applicationId: applicationId,
      commentId: comment.id,
      message: comment.message,
    };

    switch (comment.state) {
      case 'add':
        dispatch(onAdd(params));
        break;
      case 'update':
        dispatch(onUpdate(params));
        break;
      case 'remove':
        dispatch(onDelete(params));
        break;
      default:
        console.log(comment.state);
    }
  };

  const requireSuperAdmin = false;

  return (
    <CommentFlow
      label="Commentaires"
      values={values}
      onChange={handleOnChange}
      height={250}
      requireSuperAdmin={requireSuperAdmin}
      allowOtherToUpdate
      useRichInput
      storageKey={applicationId}
    />
  );
}

export default ApplicationComment;
