import Item from 'components/core/Item';
import { Grid } from 'components/core/containers';

export default function OfferOverview({ stats }) {
  const {
    campusCount,
    campusTurnover,
    seniorCount,
    seniorTurnover,
    inProgressApplicationCount,
    lastWeekApplicationSentCount,
    currentWeekApplicationSentCount,
  } = stats;

  return (
    <>
      <Grid container>
        <Grid md={8}>
          <Grid container>
            <Grid md={2}>
              <Item label="Offres Campus" value={campusCount} />
              <Item label="CA" value={campusTurnover} type="currency" />
            </Grid>
            <Grid md={2}>
              <Item label="Offres CDI" value={seniorCount} />
              <Item label="CA" value={seniorTurnover} type="currency" />
            </Grid>
            <Grid md={2}>
              <Item label="Total" value={campusCount + seniorCount} />
              <Item
                label="Total"
                value={campusTurnover + seniorTurnover}
                type="currency"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid md={8}>
          <Grid container>
            <Grid md={2}>
              <Item
                label="Envois semaine passée"
                value={lastWeekApplicationSentCount}
              />
              <Item
                label="Envois semaine en cours"
                value={currentWeekApplicationSentCount}
              />
            </Grid>
            <Grid md={2}>
              <Item
                label="Candidatures à Traiter"
                value={inProgressApplicationCount}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
