import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAdminOptions } from 'redux/formOptions/actions';
import { persistHistoryState } from 'utils/functions';
import Filter from 'components/Filter';
import Autocomplete from 'components/core/input/Autocomplete';
import { Box, Checkbox, FormControlLabel, withStyles } from '@material-ui/core';

import S from '../styles';

function FilterFields(props) {
  const { classes } = props;

  const history = useHistory();
  const historyState = history.location.state ? history.location.state : {};
  const handleHistory = state => {
    persistHistoryState(state);
  };

  const auth = useSelector(state => state.auth);
  const [admin, setAdmin] = useState(
    historyState.filter && historyState.filter.admin !== 'undefined'
      ? historyState.filter.admin
      : {
          value: auth.userId,
          label: auth.userName,
        }
  );
  const [displayUnactive, setDisplayUnactive] = useState(
    historyState.filter ? historyState.filter.displayUnactive : 0
  );

  const dispatch = useDispatch();
  // Refresh rows when filter, pagination or order change
  const isInitialMount = useRef(true);
  useEffect(
    () => {
      if (isInitialMount.current) {
        dispatch(
          getAdminOptions({
            onlyActive: 1,
          })
        );
        isInitialMount.current = false;
        return;
      }

      const filter = {
        admin: admin,
        displayUnactive: displayUnactive,
      };
      const formated = {
        admin: admin ? admin.value : null,
        onlyActive: displayUnactive ? 0 : 1,
      };
      handleHistory({ filter: filter, filterFormated: formated });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [admin, displayUnactive]
  );

  const adminOptions = useSelector(state => state.formOptions.adminOptions);

  return (
    <Filter>
      <Box className={classes.fieldContainer}>
        <Box className={classes.column}>
          <Box className={classes.row}>
            <Autocomplete
              defaultValue={admin}
              className={classes.selectField}
              options={adminOptions}
              label="Recruteurs"
              onChange={({ value }) => {
                setAdmin(value);
              }}
            />
          </Box>
          <Box className={classes.row}>
            <FormControlLabel
              label="Offres terminées"
              control={
                <Checkbox
                  id="displayUnactive"
                  checked={displayUnactive}
                  onChange={() => {
                    setDisplayUnactive(displayUnactive ? 0 : 1);
                  }}
                  color="primary"
                />
              }
              className={classes.checkbox}
              labelPlacement="end"
            />
          </Box>
        </Box>
      </Box>
    </Filter>
  );
}

export default withStyles(S)(FilterFields);
