import { useEffect, useMemo, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sidebarUpdateItemState, getNotifications } from 'redux/app/actions';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'utils/routes';
import { ROLES } from 'utils/roles';

import { Divider, Collapse, List, withStyles } from '@material-ui/core';
import {
  Dehaze as DehazeIcon,
  StarBorder as StarBorderIcon,
  Add as AddIcon,
  Equalizer as EqualizerIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  Face as FaceIcon,
  EuroSymbol as EuroSymbolIcon,
  TrendingUp as TrendingUpIcon,
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
  ViewComfy as ViewComfyIcon,
  Business as BusinessIcon,
  Wc as WcIcon,
  AssignmentTurnedIn as CommercialProposalIcon,
  Call as CallIcon,
  Mail as MailIcon,
} from '@material-ui/icons';
import Item from './Item';
import S from './styles';

function Sidebar({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sidebarStatus = useSelector(state => state.app.sidebar.expanded);
  const pafOpen = useSelector(state => state.app.sidebar.items.paf);
  const statOpen = useSelector(state => state.app.sidebar.items.statistics);
  const invoicingOpen = useSelector(state => state.app.sidebar.items.invoicing);
  const userRoles = useSelector(state => state.auth.userRoles);

  const jobBoardNotificationCount = useSelector(
    state => state.app.notifications.posting
  );
  const userId = useSelector(state => state.auth.userId);

  useEffect(
    () => {
      const params = { user: userId };
      dispatch(getNotifications(params));
    },
    [userId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleClick = useMemo(
    () => e => {
      const key = e.currentTarget.getAttribute('name');
      const isExpended = () => {
        switch (key) {
          case 'paf':
            return pafOpen;
          case 'statistics': {
            return statOpen;
          }
          case 'invoicing': {
            return invoicingOpen;
          }
          default:
            throw new Error(`Wrong expendable key provided: ${key}`);
        }
      };

      dispatch(sidebarUpdateItemState(key, !isExpended()));
    },
    [pafOpen, statOpen, invoicingOpen] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <List component="nav" className={classes.root}>
      {/* Recruitment */}
      <Item
        name="paf"
        label={t('paf.title')}
        icon={DehazeIcon}
        onClick={handleClick}
        isMain
        isExpendable
        isExpended={pafOpen}
      />
      <Collapse in={sidebarStatus && pafOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Item
            label={t('jobboards-posting.title')}
            route={ROUTES.JOBBOARDS_SUMMARY}
            icon={PlaylistAddCheckIcon}
            badgeValue={jobBoardNotificationCount}
          />
          <Item
            label={t('paf.summary.title')}
            route={ROUTES.PAF}
            icon={StarBorderIcon}
          />
          <Item
            label={t('followUp.title')}
            route={ROUTES.FOLLOW_UP}
            icon={CallIcon}
          />
          <Item
            label={t('applicationFollowUp.title')}
            route={ROUTES.APPLICATION_FOLLOW_UPS}
            icon={MailIcon}
          />
          <Item
            label={t('paf.data-title')}
            route={ROUTES.PAF_DATA}
            icon={AddIcon}
          />
          <Item
            label={t('offer.title')}
            route={ROUTES.OFFER_LIST}
            icon={ViewComfyIcon}
          />
        </List>
      </Collapse>
      {/* Candidates */}
      <Item
        label={t('candidate.title')}
        route={ROUTES.CANDIDATES_LIST}
        icon={FaceIcon}
        isMain
      />
      {/* Invoicing */}
      <Item
        name="invoicing"
        label={t('menu.invoicingTitle')}
        icon={EuroSymbolIcon}
        onClick={handleClick}
        isMain
        isExpendable
        isExpended={invoicingOpen}
      />
      <Collapse
        in={sidebarStatus && invoicingOpen}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <Item
            label={t('invoicing.invoices.title')}
            route={ROUTES.INVOICES_LIST}
            icon={ArrowDownwardIcon}
          />
          <Item
            label={t('invoicing.creditNotes.title')}
            route={ROUTES.CREDIT_NOTES_LIST}
            icon={ArrowUpwardIcon}
          />
        </List>
      </Collapse>
      {/* Companies */}
      <Item
        label={t('company.title')}
        route={ROUTES.COMPANIES_LIST}
        icon={BusinessIcon}
        isMain
      />
      {/* Commercial Proposals */}
      <Item
        label={t('commercialProposal.title')}
        route={ROUTES.COMMERCIAL_PROPOSAL_LIST}
        icon={CommercialProposalIcon}
        isMain
      />
      <Divider />
      {/* Statistics */}
      <Item
        name="statistics"
        label={t('statistics.title')}
        icon={EqualizerIcon}
        onClick={handleClick}
        isMain
        isExpendable
        isExpended={statOpen}
      />
      <Collapse in={sidebarStatus && statOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Item
            label={t('paf.title')}
            route={ROUTES.STATISTICS_PAF}
            icon={DehazeIcon}
          />
          <Item
            label={t('candidates.title')}
            route={ROUTES.STATISTICS_CANDIDATES}
            icon={FaceIcon}
          />
          <Item
            label={t('invoices.title')}
            route={ROUTES.STATISTICS_INVOICES}
            icon={TrendingUpIcon}
          />
        </List>
      </Collapse>
      {/* Admins */}
      {userRoles.includes(ROLES.SUPER_ADMIN) && (
        <Item
          label={t('admin.title')}
          route={ROUTES.ADMIN_LIST}
          icon={WcIcon}
          isMain
        />
      )}
    </List>
  );
}

export default withStyles(S)(memo(Sidebar));
