import { FormHelperText, IconButton } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';

function FileField({ onChange, label, name, error, touched }) {
  const handleFileChange = e => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      onChange({
        name: name,
        value: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };

  return (
    <>
      <div style={touched && error && { color: 'red' }}>{label}</div>
      <input
        accept="application/pdf"
        style={{
          display: 'none',
        }}
        type="file"
        id={name}
        onChange={handleFileChange}
        name={name}
      />
      <label htmlFor={name}>
        <IconButton
          color="primary"
          component="span"
          style={{ width: 0, height: 0 }}
        >
          <GetApp />
        </IconButton>
      </label>
      <FormHelperText error>{touched && error}</FormHelperText>
    </>
  );
}

export default FileField;
