import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components/core/input';

export default function IsFinalInternship({ values, onChange, name }) {
  const { t } = useTranslation();

  return (
    <Checkbox
      name={name ?? 'finalInternship'}
      label={t('paf.field.finalInternship')}
      value={name ? values[name] : values.finalInternship}
      onChange={onChange}
    />
  );
}
