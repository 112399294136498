import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'utils/routes';

import { CompanyFormSchema } from 'utils/formSchemas';
import { handleFormValues } from './helper';

import { Formik } from 'formik';

import { Grid } from 'components/core/containers';
import {
  Autocomplete,
  TextField,
  ImageField,
  Checkbox,
} from 'components/core/input';
import { RichTextEditor } from 'components/core/input';
import SubmitButtons from 'components/core/SubmitButtons';
import Subtitle from 'components/layout/Subtitle';
import {
  useFetchAdmins,
  useFetchBusinessSectors,
  useFetchCompanySizes,
  useFetchProvenances,
} from 'utils/hooks/formOptions';

export default function CompanyForm({ company, onSubmit }) {
  const handleSubmit = values => {
    onSubmit(handleFormValues(values));
  };

  return (
    <Formik
      initialValues={company}
      validationSchema={CompanyFormSchema}
      onSubmit={handleSubmit}
    >
      {props => <Form {...props} />}
    </Formik>
  );
}

function Form({
  values,
  errors,
  touched,
  handleSubmit,
  setFieldTouched,
  setFieldValue,
  isSubmitting,
}) {
  const { t } = useTranslation();
  const [fetchAdmins, admins] = useFetchAdmins();
  const [fetchProvenances, provenances] = useFetchProvenances();
  const [fetchCompanySizes, companySizes] = useFetchCompanySizes();
  const [fetchBusinessSectors, businessSectors] = useFetchBusinessSectors();

  useEffect(
    () => {
      fetchBusinessSectors();
      fetchCompanySizes();
      fetchProvenances();
      fetchAdmins({ onlyActive: true });
    },
    [fetchBusinessSectors, fetchCompanySizes, fetchProvenances, fetchAdmins]
  );

  const handleChange = (fieldName, value) => {
    setFieldValue(fieldName, value, true);
    setFieldTouched(fieldName, true, false);
  };

  const handleChangeDifferentBillingAddress = () => {
    const value = !values.differentBillingAddress;
    if (value === false) {
      handleChange('billingName', null);
      handleChange('billingStreet', null);
      handleChange('billingPostCode', null);
      handleChange('billingCity', null);
      handleChange('billingCountry', null);
    }
    handleChange('differentBillingAddress', value);
  };

  return (
    <form onSubmit={handleSubmit} style={{ padding: 20 }}>
      <Grid container spacing={50}>
        <Grid item sm={12} md={4}>
          <Subtitle label={t('common.company')} />
          <Grid container spacing={25}>
            <Grid item md={6}>
              {/* Name */}
              <TextField
                label={t('common.fields.name')}
                touched={touched.name}
                error={errors.name}
                handleChange={({ value }) => {
                  handleChange('name', value);
                }}
                value={values.name}
              />
            </Grid>
            <Grid item md={3} style={{ alignSelf: 'end' }}>
              {/* Logo image */}
              <ImageField
                maxSize={1}
                label={t('common.fields.logo')}
                value={values.logo}
                onChange={image => {
                  values.logo = image;
                }}
              />
            </Grid>
            <Grid item md={3} style={{ alignSelf: 'end' }}>
              {/* Background image */}
              <ImageField
                cropper
                label={t('common.fields.background')}
                value={values.background}
                maxSize={2}
                onChange={image => {
                  values.background = image;
                }}
              />
            </Grid>

            <Grid item md={6}>
              {/* Business Sector */}
              <Autocomplete
                defaultValue={values.businessSector}
                options={businessSectors}
                label={t('common.fields.businessSector')}
                error={touched.businessSector && Boolean(errors.businessSector)}
                helperText={touched.businessSector ? errors.businessSector : ''}
                onChange={({ value }) => {
                  handleChange('businessSector', value);
                }}
              />
            </Grid>
            <Grid item md={6}>
              {/* Secondary Business Sector */}
              <Autocomplete
                defaultValue={values.secondaryBusinessSector}
                options={businessSectors}
                label={t('common.fields.secondaryBusinessSector')}
                error={errors.secondaryBusinessSector}
                touched={touched.secondaryBusinessSector}
                onChange={({ value }) => {
                  handleChange('secondaryBusinessSector', value);
                }}
              />
            </Grid>
            <Grid item md={6}>
              {/* Size */}
              <Autocomplete
                defaultValue={values.size}
                options={companySizes}
                label={t('common.fields.size')}
                error={errors.size}
                touched={touched.size}
                onChange={({ value }) => {
                  handleChange('size', value);
                }}
              />
            </Grid>
            <Grid item md={6}>
              {/* Number of Employees */}
              <TextField
                label={t('common.fields.employeesCount')}
                touched={touched.employeesCount}
                error={errors.employeesCount}
                handleChange={({ value }) => {
                  handleChange('employeesCount', value);
                }}
                value={values.employeesCount}
              />
            </Grid>
            <Grid item md={6}>
              {/* Provenance */}
              <Autocomplete
                defaultValue={values.provenance}
                options={provenances}
                label={t('common.fields.provenance')}
                error={errors.provenance}
                touched={touched.provenance}
                onChange={({ value }) => {
                  handleChange('provenance', value);
                }}
              />
            </Grid>

            <Grid item md={6}>
              {/* Admin */}
              <Autocomplete
                defaultValue={values.admin}
                options={admins}
                label={t('common.fields.admin')}
                error={errors.admin}
                touched={touched.admin}
                onChange={({ value }) => {
                  handleChange('admin', value);
                }}
              />
            </Grid>
            <Grid item md={6}>
              {/* Active */}
              <Checkbox
                label={t('common.fields.active')}
                value={values.active}
                onChange={() => {
                  handleChange('active', !values.active);
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12} md={4}>
          <Subtitle label={t('common.address')} />
          <Grid container spacing={25}>
            <Grid item md={12}>
              {/* Street */}
              <TextField
                label={t('common.fields.street')}
                touched={touched.street}
                error={errors.street}
                handleChange={({ value }) => {
                  handleChange('street', value);
                }}
                value={values.street}
              />
            </Grid>
            <Grid item md={6}>
              {/* Post Code */}
              <TextField
                label={t('common.fields.postCode')}
                touched={touched.postCode}
                error={errors.postCode}
                handleChange={({ value }) => {
                  handleChange('postCode', value);
                }}
                value={values.postCode}
              />
            </Grid>
            <Grid item md={6}>
              {/* City */}
              <TextField
                label={t('common.fields.city')}
                touched={touched.city}
                error={errors.city}
                handleChange={({ value }) => {
                  handleChange('city', value);
                }}
                value={values.city}
              />
            </Grid>
            <Grid item md={6}>
              {/* Country */}
              <TextField
                label={t('common.fields.country')}
                touched={touched.country}
                error={errors.country}
                handleChange={({ value }) => {
                  handleChange('country', value);
                }}
                value={values.country}
              />
            </Grid>
            <Grid item md={6}>
              {/* Website */}
              <TextField
                label={t('common.fields.websiteUrl')}
                handleChange={({ value }) => {
                  handleChange('websiteUrl', value);
                }}
                value={values.websiteUrl}
              />
            </Grid>
            <Grid item md={6}>
              {/* Email */}
              <TextField
                label={t('common.fields.email')}
                touched={touched.email}
                error={errors.email}
                handleChange={({ value }) => {
                  handleChange('email', value);
                }}
                value={values.email}
              />
            </Grid>
            <Grid item md={6}>
              {/* Company Phone Number */}
              <TextField
                label={t('common.fields.phoneNumber')}
                touched={touched.phoneNumber}
                error={errors.phoneNumber}
                handleChange={({ value }) => {
                  handleChange('phoneNumber', value);
                }}
                value={values.phoneNumber}
              />
            </Grid>
            <Grid md={6}>
              {/* Billing Email */}
              <TextField
                label={t('common.fields.billingEmail')}
                handleChange={({ value }) =>
                  handleChange('billingEmail', value)
                }
                value={values.billingEmail}
              />
            </Grid>
            <Grid md={6}>
              {/* Billing Contact */}
              <TextField
                label={t('common.fields.billingContact')}
                handleChange={({ value }) =>
                  handleChange('billingContactName', value)
                }
                value={values.billingContactName}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12} md={4}>
          <Subtitle label={t('common.billingAddress')} />
          <Grid container spacing={25}>
            <Grid item md={6}>
              {/* Street */}
              <TextField
                label={t('common.fields.name')}
                touched={touched.billingName}
                error={errors.billingName}
                handleChange={({ value }) => {
                  handleChange('billingName', value);
                }}
                value={values.billingName}
                disabled={!values.differentBillingAddress}
              />
            </Grid>
            <Grid item md={12}>
              {/* Street */}
              <TextField
                label={t('common.fields.street')}
                touched={touched.billingStreet}
                error={errors.billingStreet}
                handleChange={({ value }) => {
                  handleChange('billingStreet', value);
                }}
                value={values.billingStreet}
                disabled={!values.differentBillingAddress}
              />
            </Grid>
            <Grid item md={6}>
              {/* Post Code */}
              <TextField
                label={t('common.fields.postCode')}
                touched={touched.billingPostCode}
                error={errors.billingPostCode}
                handleChange={({ value }) => {
                  handleChange('billingPostCode', value);
                }}
                value={values.billingPostCode}
                disabled={!values.differentBillingAddress}
              />
            </Grid>
            <Grid item md={6}>
              {/* City */}
              <TextField
                label={t('common.fields.city')}
                touched={touched.billingCity}
                error={errors.billingCity}
                handleChange={({ value }) => {
                  handleChange('billingCity', value);
                }}
                value={values.billingCity}
                disabled={!values.differentBillingAddress}
              />
            </Grid>
            <Grid item md={6}>
              {/* Country */}
              <TextField
                label={t('common.fields.country')}
                touched={touched.billingCountry}
                error={errors.billingCountry}
                handleChange={({ value }) => {
                  handleChange('billingCountry', value);
                }}
                value={values.billingCountry}
                disabled={!values.differentBillingAddress}
              />
            </Grid>
            <Grid item md={6} />
            <Grid item md={6}>
              {/* Different Billing Address */}
              <Checkbox
                label="Adresse de facturation différente"
                value={values.differentBillingAddress}
                onChange={() => handleChangeDifferentBillingAddress()}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <Grid container spacing={25}>
            <Grid item sm={12} md={6}>
              {/* Description */}
              <RichTextEditor
                label={t('common.fields.description')}
                htmlValue={values.description}
                handleChange={value => {
                  handleChange('description', value);
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              {/* Recruitment Process */}
              <RichTextEditor
                label={t('common.fields.recruitmentProcess')}
                htmlValue={values.recruitmentProcess}
                handleChange={value => {
                  handleChange('recruitmentProcess', value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SubmitButtons
        cancelRoute={ROUTES.COMPANIES_LIST}
        isSubmitting={isSubmitting}
      />
    </form>
  );
}
