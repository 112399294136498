// @flow
import React from 'react';
import type { RequestAction, SuccessAction, FailureAction } from 'utils/type';
import moment from 'moment-timezone';
import FileDownload from 'js-file-download';
import history from 'utils/history';

export const getNameAndStateRequest = (
  type: string
): { requestName: ?string, requestState: ?string } => {
  const matches = /(.*)_(REQUEST|SUCCESS|FAIL)/.exec(type);
  if (!matches) return { requestName: null, requestState: null };
  const [, requestName, requestState] = matches;
  return { requestName, requestState };
};

export const serialize = (params: any) => {
  if (!params) return null;
  const parts = Object.keys(params).map(key => {
    if (Array.isArray(params[key])) {
      const mapped = params[key].map(el => `${key}=${el}`);
      return mapped.join('&');
    } else {
      return `${key}=${params[key]}`;
    }
  });
  return parts.join('&');
};

export const actionRequest = (type: string): RequestAction => ({ type });
export const actionSuccess = (type: string, result: any): SuccessAction => ({
  type,
  result,
});
export const actionFailure = (
  type: string,
  errors: ErrorType
): FailureAction => ({
  type,
  errors,
});

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay]
  );

  return debouncedValue;
}

export const formatDate = date => {
  if (date === null || typeof date === 'undefined') return null;
  return moment.tz(date, 'Europe/Paris').format('DD/MM/YYYY');
};

export const formatHours = date => {
  if (date === null || typeof date === 'undefined') return null;
  return moment.tz(date, 'Europe/Paris').format('HH:mm');
};

export const removeTimeZonePart = date => {
  if (typeof date !== 'string') {
    return date;
  }

  let finalDate = '';
  if (date.split('+').length > 1) {
    let b = date.split('+');
    finalDate = b[0];
  } else {
    let b = date.split('-');
    if (b.length > 1) {
      b.pop();
      finalDate = b.join('-');
    }
  }
  return finalDate;
};

/**
 * Sort object on key values
 */
export const keysrt = (key, desc) => {
  return function(a, b) {
    return desc ? ~~(a[key] < b[key]) : ~~(a[key] > b[key]);
  };
};

export const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

/**
 * Get ISO weeks for a year
 */
export const getWeeksPerYear = y => {
  const d = new Date(y, 0, 1);
  const isLeap = new Date(y, 1, 29).getMonth() === 1;

  //check for a Jan 1 that's a Thursday or a leap year that has a
  //Wednesday jan 1. Otherwise it's 52
  return d.getDay() === 4 || (isLeap && d.getDay() === 3) ? 53 : 52;
};

export const isValidDate = date => moment(date).isValid();

export const downloadFile = response => {
  const headerLine = response.headers['content-disposition'];
  const fileName = headerLine.split('filename=')[1].replace(/['"]+/g, '');
  FileDownload(response.data, fileName);
};

export const capitalize = string => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export function useForceUpdate() {
  // eslint-disable-next-line
  const [value, setValue] = React.useState(0);
  return () => setValue(value => ++value);
}

/**
 * Persist history state to maintain table filters
 * @param {object} state - New state to persist in history
 */
export const persistHistoryState = state => {
  console.log('update history location...');
  const historyState = history.location.state ? history.location.state : {};
  history.replace({
    state: {
      prevPath: history.location.pathname,
      ...historyState,
      ...state,
    },
  });
  console.log('history location updated: ', history.location);
};

/**
 * Push redirection considering the previous path stored in history state and excpected redirect paths
 * @param {array} expectedPaths - Expected paths for the redirection
 */
export const pushRedirection = expectedPaths => {
  const historyState = history.location.state;
  if (
    typeof historyState !== 'undefined' &&
    typeof historyState.prevPath !== 'undefined' &&
    expectedPaths.includes(historyState.prevPath)
  ) {
    console.log('redirection to: ', historyState.prevPath);
    return history.goBack();
  } else {
    console.log('redirection to: ', expectedPaths[0]);
    return history.push(expectedPaths[0]);
  }
};

export const differenceInDays = (from, to) => {
  return Math.round((to - from) / (1000 * 3600 * 24));
};

export const joinArray = (array, key = null) => {
  if (array === null || array === undefined) {
    return null;
  }
  if (!Array.isArray(array)) {
    throw new Error('parameter 1 is not an array!');
  }
  if (array.length === 0) {
    return null;
  }

  return array.map(x => (key !== null ? x[key] : x)).join();
};

export const isEmpty = str => {
  return !str || 0 === str.length;
};

export const getHistoryFilter = history => {
  return history.location.state && history.location.state.filter
    ? history.location.state.filter
    : {};
};

export const getHistoryFilterFormated = history => {
  return history.location.state && history.location.state.filterFormated
    ? history.location.state.filterFormated
    : {};
};

export const formatDateParameter = date => {
  return formatDate(date);
};

export const formatArrayParameter = (array, key) => {
  return joinArray(array, key);
};

export const formatBoolParameter = bool => {
  return bool ? 1 : null;
};

export const formatStringParameter = str => {
  return isEmpty(str) ? null : str;
};

const isObject = object => {
  return object != null && typeof object === 'object';
};

// Recursive shallow equality check
export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
};

export const emptyCache = () => {
  if ('caches' in window) {
    caches.keys().then(names => {
      // Delete all the cache files
      names.forEach(name => {
        caches.delete(name);
      });
    });

    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.location.reload(true);
  }
};

export const currencyFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
});

const now = new Date();
export const currMonth = now.getMonth() + 1;
