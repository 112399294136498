import { fetch } from 'utils/api';
import services from 'utils/api/Services/services';
import { actionRequest, actionSuccess, actionFailure } from 'utils/functions';

export const ACTIONS_TYPE = Object.freeze({
  GET_ADMINS_BEGIN: 'GET_ADMINS_BEGIN',
  GET_ADMINS_SUCCESS: 'GET_ADMINS_SUCCESS',
  GET_ADMINS_FAILURE: 'GET_ADMINS_FAILURE',

  GET_ONE_ADMIN_BEGIN: 'GET_ONE_ADMIN_BEGIN',
  GET_ONE_ADMIN_SUCCESS: 'GET_ONE_ADMIN_SUCCESS',
  GET_ONE_ADMIN_FAILURE: 'GET_ONE_ADMIN_FAILURE',

  CLEAN_CURRENT_ADMIN: 'CLEAN_CURRENT_ADMIN',

  UPDATE_ADMIN_BEGIN: 'UPDATE_ADMIN_BEGIN',
  UPDATE_ADMIN_SUCCESS: 'UPDATE_ADMIN_SUCCESS',
  UPDATE_ADMIN_FAILURE: 'UPDATE_ADMIN_FAILURE',

  CREATE_ADMIN_BEGIN: 'CREATE_ADMIN_BEGIN',
  CREATE_ADMIN_SUCCESS: 'CREATE_ADMIN_SUCCESS',
  CREATE_ADMIN_FAILURE: 'CREATE_ADMIN_FAILURE',
});

export const createAdmin = params => (dispatch, getState) =>
  fetch({
    service: services.admins.create(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.CREATE_ADMIN_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.CREATE_ADMIN_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.CREATE_ADMIN_FAILURE, errors),
  });

export const updateAdmin = params => (dispatch, getState) =>
  fetch({
    service: services.admins.update(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.UPDATE_ADMIN_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.UPDATE_ADMIN_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_ADMIN_FAILURE, errors),
  });

export const cleanCurrentAdmin = () => ({
  type: ACTIONS_TYPE.CLEAN_CURRENT_ADMIN,
});

export const getOneAdmin = params => (dispatch, getState) =>
  fetch({
    service: services.admins.getOne(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_ONE_ADMIN_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_ONE_ADMIN_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_ONE_ADMIN_FAILURE, errors),
  });

export const getAdmins = params => (dispatch, getState) =>
  fetch({
    service: services.admins.get(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_ADMINS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_ADMINS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_ADMINS_FAILURE, errors),
  });
