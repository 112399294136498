import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateInvoiceComment } from 'redux/invoices/actions';
import { Box, Button, TextField, withStyles } from '@material-ui/core';
import S from './styles';

function RowDetails(props) {
  const { row, classes } = props;

  const invoice = row;
  const [comment, setComment] = useState(invoice.comment);

  const dispatch = useDispatch();
  const handleSubmit = event => {
    event.preventDefault();
    const data = {
      id: invoice.id,
      comment,
    };

    console.log('call update invoice comment with data: ', data);
    dispatch(updateInvoiceComment(data));
  };

  const handleCancel = () => {
    setComment(invoice.comment);
  };
  return (
    <Box className={classes.container}>
      <form onSubmit={handleSubmit}>
        {/*  Comment */}
        <TextField
          id="comment"
          label="Commentaire"
          multiline
          rowsMax="4"
          className={classes.textField}
          onChange={event => {
            setComment(event.target.value);
          }}
          value={comment}
        />
        <Box>
          <Box className={classes.actionFields}>
            <Box className={classes.rightFields}>
              <Button color="primary" onClick={handleCancel}>
                Annuler
              </Button>
              <Button type="submit" color="primary">
                Enregister
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

export default withStyles(S)(RowDetails);
