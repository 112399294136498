import { memo, useState } from 'react';
import styled from 'styled-components';

Tooltip.defaultProps = {
  text: null,
  children: null,
  position: 'bottom',
  width: null,
};

function Tooltip({ text, children, position, width }) {
  const [show, setShow] = useState(false);

  const hideTooltip = () => setShow(false);
  const showTooltip = () => setShow(true);

  return (
    <Container onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      <TooltipText position={position} width={width} show={show}>
        {text}
      </TooltipText>
      {children}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;
const TooltipText = styled.span`
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  background-color: whitesmoke;
  color: black;
  font-size: 9px;
  text-align: center;
  border-radius: 6px;
  width: ${({ width }) => width ?? 100}px;
  z-index: 3;
  padding: 3px;

  position: absolute;
  ${({ position }) => handleDirection(position)};
`;
const handleDirection = direction => {
  switch (direction) {
    case 'right':
      return 'top: -5px; left: 105%;';
    case 'bottom':
      return 'top: 100%; left: 50%; margin-left: -10px;';
    case 'left':
      return 'top: 100%; right: 105%;';
    case 'top':
    default:
      return 'top: -5px; left: 105%;';
  }
};

export default memo(Tooltip);
