import options from 'utils/api/Services/options';
import invoices from 'utils/api/Services/invoices';
import creditNotes from 'utils/api/Services/creditNotes';
import offers from 'utils/api/Services/offers';
import candidates from 'utils/api/Services/candidates';
import applications from 'utils/api/Services/applications';
import jobboards from 'utils/api/Services/jobboards';
import auth from 'utils/api/Services/auth';
import companies from 'utils/api/Services/companies';
import recruiters from 'utils/api/Services/recruiters';
import admins from 'utils/api/Services/admins';
import app from 'utils/api/Services/app';
import commercialProposals from 'utils/api/Services/commercialProposals';
import pafs from 'utils/api/Services/pafs';
import followUps from 'utils/api/Services/followUps';
import applicationFollowUps from 'utils/api/Services/applicationFollowUps';

const services = {
  notifications: {
    get: params => ({
      url: '/notifications',
      params,
      method: 'get',
    }),
  },
  contractTypes: {
    get: params => ({
      url: '/contractTypes',
      method: 'get',
      params,
    }),
  },
  pafSummary: {
    get: params => ({
      url: `/pafs/summary/${params.year}/${params.month}`,
      method: 'get',
      params,
    }),
  },

  pafStatistics: {
    get: data => ({
      url: '/statistics/pafs',
      method: 'post',
      data: data,
    }),
  },
  statistics: {
    candidates: {
      getRegistrationsByProvenances: ({ fromDate, toDate }) => ({
        url: '/statistics/candidates/provenances/registrations',
        method: 'post',
        data: { fromDate, toDate },
      }),
      getHiresByProvenances: ({ fromDate, toDate }) => ({
        url: '/statistics/candidates/provenances/hires',
        method: 'post',
        data: { fromDate, toDate },
      }),
      getSLByProvenances: ({ fromDate, toDate }) => ({
        url: '/statistics/candidates/provenances/sl',
        method: 'post',
        data: { fromDate, toDate },
      }),
      getInvoicesTurnover: ({ fromDate, toDate }) => ({
        url: '/statistics/invoices/turnover',
        method: 'post',
        data: { fromDate, toDate },
      }),
      getFixedInvoices: ({ fromDate, toDate }) => ({
        url: '/statistics/invoices/fixed',
        method: 'post',
        data: { fromDate, toDate },
      }),
      getVariableInvoices: ({ fromDate, toDate }) => ({
        url: '/statistics/invoices/variable',
        method: 'post',
        data: { fromDate, toDate },
      }),
      getInvoicesCount: ({ fromDate, toDate }) => ({
        url: '/statistics/invoices/count',
        method: 'post',
        data: { fromDate, toDate },
      }),
      getInvoicesCustomerType: ({ fromDate, toDate }) => ({
        url: '/statistics/invoices/customer',
        method: 'post',
        data: { fromDate, toDate },
      }),
      getInvoicesSummary: () => ({
        url: '/statistics/invoices/summary',
        method: 'post',
      }),
      getInvoicesActiveAmount: () => ({
        url: '/statistics/invoices/active',
        method: 'post',
      }),
    },
  },
  options: options,
  invoices: invoices,
  creditNotes: creditNotes,
  offers: offers,
  candidates: candidates,
  applications: applications,
  jobboards: jobboards,
  auth: auth,
  companies: companies,
  recruiters: recruiters,
  admins: admins,
  app: app,
  commercialProposals: commercialProposals,
  pafs: pafs,
  followUps,
  applicationFollowUps,
};

export default services;
