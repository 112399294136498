const commercialProposals = {
  update: (
    id,
    {
      adminId,
      companyId,
      prospect,
      contractTypeId,
      contact,
      phone,
      email,
      label,
      fixedPrice,
      variablePrice,
      provenanceId,
      status,
      planId,
      secondVariablePrice,
      specificTerms,
      lang,
      internalFixedPrice,
      internalVariablePrice,
      internalSecondVariablePrice,
      positionCount,
      comment,
    }
  ) => ({
    url: `/commercial-proposals/${id}`,
    method: 'put',
    data: {
      adminId: adminId,
      companyId: companyId,
      prospect: prospect,
      contractTypeId: contractTypeId,
      contact: contact,
      phone: phone,
      email: email,
      label: label,
      fixedPrice: fixedPrice,
      variablePrice: variablePrice,
      provenanceId: provenanceId,
      status: status,
      planId: planId,
      secondVariablePrice: secondVariablePrice,
      specificTerms: specificTerms,
      lang: lang,
      internalFixedPrice: internalFixedPrice,
      internalVariablePrice: internalVariablePrice,
      internalSecondVariablePrice: internalSecondVariablePrice,
      positionCount: positionCount,
      comment: comment,
    },
  }),
  create: ({
    adminId,
    companyId,
    prospect,
    contractTypeId,
    contact,
    phone,
    email,
    label,
    fixedPrice,
    variablePrice,
    provenanceId,
    status,
    planId,
    secondVariablePrice,
    specificTerms,
    lang,
    internalFixedPrice,
    internalVariablePrice,
    internalSecondVariablePrice,
    positionCount,
    comment,
  }) => ({
    url: `/commercial-proposals`,
    method: 'post',
    data: {
      adminId: adminId,
      companyId: companyId,
      prospect: prospect,
      contractTypeId: contractTypeId,
      contact: contact,
      phone: phone,
      email: email,
      label: label,
      fixedPrice: fixedPrice,
      variablePrice: variablePrice,
      provenanceId: provenanceId,
      status: status,
      planId: planId,
      secondVariablePrice: secondVariablePrice,
      specificTerms: specificTerms,
      lang: lang,
      internalFixedPrice: internalFixedPrice,
      internalVariablePrice: internalVariablePrice,
      internalSecondVariablePrice: internalSecondVariablePrice,
      positionCount: positionCount,
      comment: comment,
    },
  }),
  getOne: id => ({
    url: `/commercial-proposals/${id}`,
    method: 'get',
  }),
  get: ({
    search,
    adminId,
    companyId,
    contractTypeId,
    fromDate,
    toDate,
    page,
    size,
    statusId,
  }) => ({
    url: `/commercial-proposals`,
    method: 'get',
    params: {
      search: search,
      adminId: adminId,
      companyId: companyId,
      contractTypeId: contractTypeId,
      fromDate: fromDate,
      toDate: toDate,
      page: page,
      size: size,
      statusId,
    },
  }),
  download: id => ({
    url: `/commercial-proposals/${id}/pdf`,
    method: 'get',
    responseType: 'blob',
  }),
  delete: id => ({
    url: `/commercial-proposals/${id}`,
    method: 'delete',
  }),
};

export default commercialProposals;
