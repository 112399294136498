import { useRef, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';

import {
  IconButton,
  TextField,
  InputAdornment,
  withStyles,
} from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import S from './styles';

function SearchInput(props) {
  const { filter, onChange, onClear, classes } = props;

  const handleChange = event => {
    const value = event.target.value;
    // setValue(value);
    debHandleChange.current(value);
  };
  const debHandleChange = useRef(_.debounce(value => onChange(value), 400));

  //const [value, setValue] = React.useState(filter);
  const handleClear = () => {
    setKey(key + 1);
    onClear();
  };

  const [key, setKey] = useState(0);
  return (
    <div className={classes.container}>
      <TextField
        key={key}
        id="simple-start-adornment"
        className={clsx(classes.margin, classes.textField)}
        placeholder="Search"
        defaultValue={filter}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disableRipple
                onClick={handleClear}
                className={classes.transparentBC}
              >
                <Clear />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default withStyles(S)(SearchInput);
