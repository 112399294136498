import Tabs from 'components/core/Tabs';
import { currMonth } from 'utils/functions';

export default function MonthTabs() {
  return (
    <Tabs name="month" direction="vertical" tabs={MONTHS} defTab={currMonth} />
  );
}

const MONTHS = Object.freeze([
  { key: '1', label: 'months.1' },
  { key: '2', label: 'months.2' },
  { key: '3', label: 'months.3' },
  { key: '4', label: 'months.4' },
  { key: '5', label: 'months.5' },
  { key: '6', label: 'months.6' },
  { key: '7', label: 'months.7' },
  { key: '8', label: 'months.8' },
  { key: '9', label: 'months.9' },
  { key: '10', label: 'months.10' },
  { key: '11', label: 'months.11' },
  { key: '12', label: 'months.12' },
]);
