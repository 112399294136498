import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Hook that holds a state and keeps it synchronized with URL query params
 * It removes the query param on unmount or if value is null
 * @param {*} defaultValue Default value if query param value is null, ignored otherwise
 * @param {*} paramName Name of the URL query param
 * @param {*} serialize Function to cast URL query param string to desired type
 * @returns
 */
export const useQueryState = (defaultValue, paramName) => {
  const history = useHistory();
  const qs = new URLSearchParams(history.location.search);

  const queryParam =
    qs.getAll(paramName).length > 1
      ? qs.getAll(paramName)
      : qs.get(paramName) || defaultValue;

  const [stateValue, setState] = useState(queryParam ?? defaultValue);

  useEffect(
    () => {
      const qs = getQs(history);
      if (qs.get(paramName) === null && stateValue === null) {
        return;
      }

      if (Array.isArray(stateValue)) {
        qs.delete(paramName);
        stateValue.forEach(value => {
          qs.append(paramName, value);
        });
      } else {
        stateValue !== null
          ? qs.set(paramName, stateValue)
          : qs.delete(paramName);
      }

      replaceHistory(history, qs);
    },
    [stateValue, history, paramName]
  );

  useEffect(
    () => {
      return () => {
        const qs = getQs(history);
        if (qs.get(paramName) === null) {
          return;
        }
        qs.delete(paramName);
        replaceHistory(history, qs);
      };
    },
    [history, paramName]
  );

  return [stateValue, setState];
};

const getQs = history => {
  return new URLSearchParams(history.location.search);
};

const replaceHistory = (history, qs) => {
  history.replace({
    pathname: window.location.pathname,
    search: `?${qs.toString()}`,
  });
};

export const useQuery = () => {
  const search = window.location.search;
  return useMemo(() => new URLSearchParams(search), [search]);
};
