import { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';

import _ from 'lodash';

import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'no-wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      margin="normal"
      fullWidth
      label={TextFieldProps.label}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired,
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 1000 : 200,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}
Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired,
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  isFocused: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

function Placeholder(props) {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}
Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

const IndicatorSeparator = () => null;

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}
SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.any,
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool.isRequired,
  removeProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    onMouseDown: PropTypes.func.isRequired,
    onTouchEnd: PropTypes.func.isRequired,
  }).isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}
Menu.propTypes = {
  children: PropTypes.element.isRequired,
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

const components = {
  Control,
  Menu,
  IndicatorSeparator,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

ReactSelect.defaultProps = {
  isClearable: true,
  noOptionsMessage: 'No options',
  isMulti: false,
};

export default function ReactSelect(props) {
  const {
    label,
    options,
    onChange,
    error,
    helperText,
    value,
    style,
    isAsync,
    isCreatable,
    fetchOptions,
    isClearable,
    noOptionsMessage,
    isMulti,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [single, setSingle] = useState(value);

  const handleChangeSingle = value => {
    setSingle(value);
    onChange(value);
  };

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  // Debounce used to wait that user stop typing
  const wait = 300;
  const loadOptions = (inputValue, callback) => {
    fetchOptions(inputValue, callback);
  };
  const debouncedLoadOptions = _.debounce(loadOptions, wait);

  const errorText = helperText && helperText.id ? helperText.id : '';
  return (
    <div className={style}>
      <NoSsr>
        {isAsync &&
          !isCreatable && (
            <AsyncSelect
              classes={classes}
              styles={selectStyles}
              inputId="react-select-single"
              components={components}
              TextFieldProps={{
                label: label,
                InputLabelProps: {
                  htmlFor: 'react-select-single',
                  shrink: true,
                },
                error: error,
                helperText: errorText,
              }}
              placeholder={label}
              onChange={handleChangeSingle}
              cacheOptions
              value={single}
              loadOptions={debouncedLoadOptions}
              defaultOptions={options}
              isClearable={isClearable}
              noOptionsMessage={() => noOptionsMessage}
              isMulti={isMulti}
            />
          )}

        {isAsync &&
          isCreatable && (
            <AsyncCreatableSelect
              classes={classes}
              styles={selectStyles}
              inputId="react-select-single"
              components={components}
              TextFieldProps={{
                label: label,
                InputLabelProps: {
                  htmlFor: 'react-select-single',
                  shrink: true,
                },
                error: error,
                helperText: errorText,
              }}
              placeholder={label}
              onChange={handleChangeSingle}
              cacheOptions
              value={single}
              loadOptions={debouncedLoadOptions}
              defaultOptions={options}
              isClearable={isClearable}
              noOptionsMessage={() => noOptionsMessage}
            />
          )}

        {!isAsync &&
          !isCreatable && (
            <Select
              classes={classes}
              styles={selectStyles}
              inputId="react-select-single"
              TextFieldProps={{
                label: label,
                InputLabelProps: {
                  htmlFor: 'react-select-single',
                  shrink: true,
                },
                error: error,
                helperText: errorText,
              }}
              placeholder={label}
              options={options}
              components={components}
              value={single}
              onChange={handleChangeSingle}
              isClearable={isClearable}
              noOptionsMessage={() => noOptionsMessage}
              isMulti={isMulti}
            />
          )}
        {!isAsync &&
          isCreatable && (
            <CreatableSelect
              classes={classes}
              styles={selectStyles}
              inputId="react-select-single"
              TextFieldProps={{
                label: label,
                InputLabelProps: {
                  htmlFor: 'react-select-single',
                  shrink: true,
                },
                error: error,
                helperText: errorText,
              }}
              placeholder={label}
              options={options}
              components={components}
              value={single}
              onChange={handleChangeSingle}
              isClearable={isClearable}
              noOptionsMessage={() => noOptionsMessage}
            />
          )}
      </NoSsr>
    </div>
  );
}
