import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { appReducer } from './app/reducer';
import { alertReducer } from './alert/reducer';
import { pafsReducer } from './pafs/reducer';
import { adminsReducer } from './admins/reducer';
import { candidatesReducer } from './candidates/reducer';
import { companiesReducer } from './companies/reducer';
import { pafSummaryReducer } from './pafSummary/reducer';
import { authReducer } from './auth/reducer';
import { pafStatisticsReducer } from './pafStatistics/reducer';
import { offersReducer } from './offers/reducer';
import { jobBoardsPostsReducer } from './jobBoardsPosts/reducer';
import { formOptionsReducer } from './formOptions/reducer';
import { statisticsReducer } from './statistics/reducer';
import { invoicesReducer } from './invoices/reducer';
import { creditNotesReducer } from './creditNotes/reducer';
import { recruitersReducer } from './recruiters/reducer';
import { commercialProposalsReducer } from './commercialProposals/reducer';
import { followUpsReducer } from './followUps/reducer';
import { applicationFollowUpsReducer } from './applicationFollowUps/reducer';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    alert: alertReducer,
    pafs: pafsReducer,
    admins: adminsReducer,
    candidates: candidatesReducer,
    pafSummary: pafSummaryReducer,
    companies: companiesReducer,
    auth: authReducer,
    pafStatistics: pafStatisticsReducer,
    offers: offersReducer,
    jobBoardsPosts: jobBoardsPostsReducer,
    formOptions: formOptionsReducer,
    statistics: statisticsReducer,
    invoices: invoicesReducer,
    creditNotes: creditNotesReducer,
    recruiters: recruitersReducer,
    commercialProposals: commercialProposalsReducer,
    followUps: followUpsReducer,
    applicationFollowUps: applicationFollowUpsReducer,
  });

export default createRootReducer;
