import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  persistHistoryState,
  getHistoryFilterFormated,
  deepEqual,
} from 'utils/functions';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  withStyles,
} from '@material-ui/core';
import Pagination from 'components/Pagination';
import SearchInput from 'components/SearchInput';
import TableRowEnhanced from 'components/Table/TableRowEnhanced';
import SortableCell from 'components/Table/SortableCell';
import BaseCell from 'components/Table/BaseCell';

import S from './styles';

type Props = {|
  rows: Array<Object>,
  actions: any,
  height: any,
  columns: ColumnsType,
  maxWidth: number,
  createPath: any,
  isSearchable: boolean,
  isPaginatable: boolean,
  isSortable: boolean,
  totalItemCount: number,
  actionsComponents: any,
  ExpandComponent: any,
  FilterFields: Component,
  classes: any,
|};

BaseTable.defaultProps = {
  rows: [],
  isPaginatable: false,
  isSortable: false,
  maxHeight: '100%',
  actionsComponents: null,
};

function BaseTable(props: Props) {
  const {
    columns,
    rows,

    generalActionComponents,
    actionComponents,
    filterComponent,
    expandComponent,
    handleRefresh,

    isSearchable,
    isPaginatable,
    isSortable,
    totalItemCount,

    maxWidth,
    maxHeight,
    classes,
  } = props;

  // Manage history
  const history = useHistory();
  const historyState = history.location.state ? history.location.state : {};
  const handleHistory = state => {
    persistHistoryState(state);
  };

  // Manage rows searching with debouncing
  const [search, setSearch] = React.useState(historyState.search || '');
  const handleSearchChange = value => {
    setSearch(value);
    handleHistory({ search: value });
  };
  const handleSearchClear = () => {
    setSearch(null);
    handleHistory({ search: null });
  };

  // Manage table sort
  const [order, setOrder] = React.useState(historyState.order || null);
  const [orderBy, setOrderBy] = React.useState(historyState.orderBy || null);
  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    const newOrder = isDesc ? 'asc' : 'desc';
    setOrder(newOrder);
    setOrderBy(property);
    handleHistory({
      order: newOrder,
      orderBy: property,
    });
  };

  // Manage table pagination
  const defaultPage = 0;
  const defaultRowsPerPage = 20;
  const [pagination, setPagination] = React.useState({
    page: historyState.page || defaultPage,
    rowsPerPage: historyState.rowsPerPage || defaultRowsPerPage,
  });
  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, page: newPage });
    handleHistory({ page: newPage });
  };
  const handleChangeRowsPerPage = event => {
    const pagination = {
      page: defaultPage,
      rowsPerPage: event.target.value,
    };
    setPagination(pagination);
    handleHistory({ rowsPerPage: event.target.value });
  };

  // Check filter fields
  const [filterFields, setFilterFields] = React.useState(
    getHistoryFilterFormated(history)
  );
  if (!deepEqual(filterFields, getHistoryFilterFormated(history))) {
    setFilterFields(getHistoryFilterFormated(history));
  }
  // Refresh rows when filter, pagination or order change
  React.useEffect(
    () => {
      if (!handleRefresh) {
        return;
      }
      let params = { filter: search };
      if (isPaginatable) {
        params = {
          ...params,
          page: pagination.page,
          size: pagination.rowsPerPage,
          orderBy: orderBy,
          order: order,
        };
      }
      if (filterFields) {
        params = { ...params, ...filterFields };
      }

      console.log('Refresh data with params: ', params);
      handleRefresh(params);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, pagination, orderBy, order, filterFields]
  );

  return (
    <>
      <div className={classes.root} style={{ maxWidth: maxWidth }}>
        <div className={classes.tableActions}>
          {filterComponent ? (
            React.cloneElement(filterComponent, {})
          ) : (
            <div style={{ flex: 1 }} />
          )}
          {isSearchable && (
            <SearchInput
              filter={search}
              onChange={handleSearchChange}
              onClear={handleSearchClear}
            />
          )}

          {generalActionComponents && (
            <div className={classes.headerButtons}>
              {generalActionComponents.map((Component, key) => (
                <Component key={'generalAction-' + key} />
              ))}
            </div>
          )}
        </div>
        <TableContainer
          style={{
            maxHeight: maxHeight,
            overflow: maxHeight === '100%' ? 'unset' : 'auto',
          }}
        >
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {actionComponents && <BaseCell label="Actions" />}
                {columns.map(
                  column =>
                    isSortable && column.isSortable ? (
                      <SortableCell
                        key={column.header}
                        label={column.header}
                        accessor={column.accessors[0]}
                        order={order}
                        orderBy={orderBy}
                        createSortHandler={handleRequestSort}
                        tooltip={column.tooltip}
                        style={column.headerStyle || null}
                      />
                    ) : (
                      <BaseCell
                        key={column.header}
                        label={column.header}
                        tooltip={column.tooltip}
                        style={column.headerStyle}
                      />
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, k) => (
                <TableRowEnhanced
                  key={k}
                  row={row}
                  columns={columns}
                  actionComponents={actionComponents}
                  expandComponent={expandComponent}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!props.isLoading &&
          rows.length === 0 && <span>{props.noDataMsg}</span>}
        {rows.length > 0 &&
          isPaginatable && (
            <Pagination
              totalItemCount={totalItemCount}
              refreshRows={handleRefresh}
              pagination={pagination}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              page={pagination.page}
              rowsPerPage={pagination.rowsPerPage}
            />
          )}
      </div>
    </>
  );
}

export default withStyles(S)(BaseTable);
