import PropTypes from 'prop-types';
import { formatDate, formatHours } from 'utils/functions';
import { makeStyles } from '@material-ui/core/styles';

function Label({ creator, createDate, editDate }) {
  const classes = useStyles();

  const createDateLabel = createDate && (
    <div>
      Posté le {formatDate(createDate)} à {formatHours(createDate)}
    </div>
  );
  const editDateLabel = editDate && (
    <div>
      , édité le {formatDate(editDate)} à {formatHours(editDate)}
    </div>
  );

  return (
    <div className={classes.label}>
      <span className={classes.text}>{creator}</span>
      <span className={classes.text}>
        {createDateLabel} {editDateLabel}
      </span>
    </div>
  );
}

const useStyles = makeStyles({
  label: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  text: {
    display: 'flex',
    alignItems: 'flex-end',
    fontStyle: 'oblique',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

Label.propTypes = {
  creator: PropTypes.string,
  createDate: PropTypes.string,
  editDate: PropTypes.string,
};

Label.defaultProps = {
  creator: null,
  createDate: null,
  editDate: null,
};

export default Label;
