export const ROUTES = Object.freeze({
  LOGIN: '/login',
  PAF: '/placements/summary',
  PAF_DATA: '/placements/data',
  PAF_CREATE: '/placements/create',
  PAF_EDIT: '/placement/:id/edit',
  STATISTICS_PAF: '/statistics/placements',
  STATISTICS_CANDIDATES: '/statistics/candidates',
  STATISTICS_INVOICES: '/statistics/invoices',
  JOBBOARDS_POSTING: '/jobboards/posting',
  JOBBOARDS_SUMMARY: '/jobboards',
  INVOICES_LIST: '/invoices',
  INVOICE_CREATE: '/invoices/create',
  INVOICE_EDIT: '/invoice/:id/edit',
  CREDIT_NOTES_LIST: '/credit-notes',
  CREDIT_NOTE_CREATE: '/invoice/:invoiceBusinessId/credit-notes/create',
  CREDIT_NOTE_EDIT:
    '/invoice/:invoiceBusinessId/credit-note/:creditNoteBusinessId/edit',
  OFFER_LIST: '/offers',
  OFFER_CREATE: '/offers/create',
  OFFER_EDIT: '/offer/:id/edit',
  OFFER_SHOW: '/offer/:id',
  OFFER_SHOW_V2: '/offerv2/:id',
  COMPANIES_LIST: '/companies',
  COMPANY_CREATE: '/companies/create',
  COMPANY_EDIT: '/company/:id/edit',
  COMPANY_SHOW: '/company/:id',
  CANDIDATES_LIST: '/candidates',
  CANDIDATE_SHOW: '/candidate/:id',
  CANDIDATE_CREATE: '/candidates/create',
  RECRUITER_CREATE: '/company/:companyId/recruiters/create',
  RECRUITER_EDIT: '/recruiter/:id/edit',
  ADMIN_LIST: '/admins',
  ADMIN_CREATE: '/admins/create',
  ADMIN_EDIT: '/admin/:id/edit',
  COMMERCIAL_PROPOSAL_LIST: '/commercial-proposals',
  COMMERCIAL_PROPOSAL_CREATE: '/commercial-proposals/create',
  COMMERCIAL_PROPOSAL_EDIT: '/commercial-proposal/:id/edit',
  FOLLOW_UP: '/follow-up',
  APPLICATION_FOLLOW_UPS: '/applications/follow-ups',
});
