import { ACTIONS_TYPE } from './actions';

export const initialState = {
  loading: false,
  totalItemCount: 0,
  items: [],
  current: null,
  kpi: {},
  models: [],
};

export function commercialProposalsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPE.GET_COMMERCIAL_PROPOSALS_BEGIN:
    case ACTIONS_TYPE.GET_ONE_COMMERCIAL_PROPOSAL_BEGIN:
    case ACTIONS_TYPE.UPDATE_COMMERCIAL_PROPOSAL_BEGIN:
    case ACTIONS_TYPE.CREATE_COMMERCIAL_PROPOSAL_BEGIN:
    case ACTIONS_TYPE.DOWNLOAD_COMMERCIAL_PROPOSAL_BEGIN:
    case ACTIONS_TYPE.DELETE_COMMERCIAL_PROPOSAL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_COMMERCIAL_PROPOSALS_FAILURE:
    case ACTIONS_TYPE.GET_ONE_COMMERCIAL_PROPOSAL_FAILURE:
    case ACTIONS_TYPE.UPDATE_COMMERCIAL_PROPOSAL_FAILURE:
    case ACTIONS_TYPE.CREATE_COMMERCIAL_PROPOSAL_FAILURE:
    case ACTIONS_TYPE.CREATE_COMMERCIAL_PROPOSAL_SUCCESS:
    case ACTIONS_TYPE.DOWNLOAD_COMMERCIAL_PROPOSAL_SUCCESS:
    case ACTIONS_TYPE.DOWNLOAD_COMMERCIAL_PROPOSAL_FAILURE:
    case ACTIONS_TYPE.DELETE_COMMERCIAL_PROPOSAL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_ONE_COMMERCIAL_PROPOSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        current: action.result,
      };
    case ACTIONS_TYPE.CLEAN_CURRENT_PROPOSAL:
      return {
        ...state,
        current: null,
      };
    case ACTIONS_TYPE.CLEAN_PROPOSALS:
      return {
        ...state,
        items: initialState.items,
      };
    case ACTIONS_TYPE.GET_COMMERCIAL_PROPOSALS_SUCCESS:
      const result = action.result;
      return {
        ...state,
        loading: false,
        totalItemCount: result.pagination.total,
        items: result.proposals,
        kpi: result.kpi,
      };
    case ACTIONS_TYPE.DELETE_COMMERCIAL_PROPOSAL_SUCCESS:
      const id = action.result.proposalId;
      return {
        ...state,
        loading: false,
        totalItemCount: state.totalItemCount - 1,
        items: [...state.items.filter(proposal => proposal.id !== id)],
      };
    case ACTIONS_TYPE.UPDATE_COMMERCIAL_PROPOSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.map(
          proposal =>
            proposal.id === action.result.id ? action.result : proposal
        ),
      };
    default:
      return state;
  }
}
