import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import TabPanel from 'components/core/TabPanel';
import Legend from 'components/core/Legend';

import { FollowUpTable, legendItems } from '../Table';
import { useQuery } from 'utils/hooks';
import YearInput from 'components/eiquem/shared/YearInput';
import PanelTitle from 'components/eiquem/shared/PanelTitle';
import AdminFilters from 'components/eiquem/shared/AdminFilter/adminFilter';

export default function Panel({
  index,
  currValue,
  rows,
  year,
  setYear,
  isMount,
}) {
  const { t } = useTranslation();
  const qs = useQuery();
  const month = qs.get('month');

  return (
    <TabPanel index={index} value={currValue}>
      <Container>
        <PanelTitle
          label={`${t('followUp.title')} - ${t('months.' + month)}`}
          legend={<Legend items={legendItems} />}
          yearInput={<YearInput year={year} setYear={setYear} />}
          filter={<AdminFilters keepFilters={isMount} />}
        />
        <FollowUpTable rows={rows} />
      </Container>
    </TabPanel>
  );
}

const Container = styled.div`
  padding: 30px 30px 10px 30px;
  width: 100%;
`;
