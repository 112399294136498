import history from 'utils/history';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Link from 'components/core/Link';

SubmitButtons.defaultProps = {
  cancelAction: () => {
    history.goBack();
  },
};

function SubmitButtons({ cancelRoute, cancelAction, isSubmitting }) {
  const { t } = useTranslation();

  return (
    <Container>
      {cancelRoute ? (
        <Link to={cancelRoute}>
          <>
            <SButton type="reset" color="primary">
              {t('common.cancel')}
            </SButton>
          </>
        </Link>
      ) : (
        <>
          <SButton type="reset" onClick={cancelAction}>
            {t('common.cancel')}
          </SButton>
        </>
      )}
      <SButton type="submit" disabled={isSubmitting}>
        {t('common.save')}
      </SButton>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 20px;
  text-align: right;
`;

const SButton = styled.button`
  text-transform: uppercase;
  border: 0px;
  background: transparent;
  cursor: grab;
  font-size: 0.75rem;
`;

export default SubmitButtons;
