//import fetch from 'cross-fetch';
import { useState, useEffect, Fragment } from 'react';

import { useDebounce } from 'utils/functions';

import axios from 'axios';
import { getHeaders } from 'utils/api/headers';
import { apiUrl } from 'utils/constants';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CircularProgress from '@material-ui/core/CircularProgress';

AutocompleteEnhancedAsync.defaultProps = {
  lazy: false,
  defaultOptions: [],
  defaultValues: [],
  creatable: false,
  disableClearable: false,
};

export default function AutocompleteEnhancedAsync(props) {
  const {
    name,
    value,
    defaultValues,
    defaultOptions,
    service,
    onChange,
    label,
    className,
    multi,
    lazy,
    creatable,
    disableClearable,
    touched,
    error,
  } = props;

  const [options, setOptions] = useState(defaultOptions ? defaultOptions : []);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, 300);

  useEffect(
    () => {
      setOptions(defaultOptions);
    },
    [defaultOptions]
  );

  const fetchOptions = filter => {
    setLoading(true);
    const { url, method, params } = service(filter);
    axios({
      method: method,
      url: `${apiUrl}` + url,
      params: params,
      headers: getHeaders(),
    })
      .then(response => {
        let json = response.data;
        if (json !== null && json !== []) {
          json = json.map(item => {
            if (!item.value && item.id) {
              return { value: item.id, label: item.label };
            } else {
              return item;
            }
          });
        }
        setOptions(json);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(
    () => {
      if (debouncedFilter === '' && lazy === true) {
        return;
      }
      fetchOptions(debouncedFilter);
    },
    // eslint-disable-next-line
    [debouncedFilter]
  );

  const handleOptionLabel = option => {
    if (typeof option === 'string') {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return option.label;
  };

  const handleFilterOptions = (options, params) => {
    if (creatable && params.inputValue !== '' && options.length === 0) {
      options.push({
        inputValue: params.inputValue,
        label: `Ajouter "${params.inputValue}"`,
      });
    }

    return options;
  };

  // Reformat the value, especially in case of creatable autocomplete
  const handleFormatValue = value => {
    if (value === null) {
      return null;
    }
    let newValue = {
      value: value.value ? value.value : -1,
      label: value.label ? value.label : null,
    };
    if (value && value.inputValue) {
      // When a created value is selected from the option
      newValue.label = value.inputValue;
    } else if (typeof value === 'string') {
      // When a created value is selected with enter key
      newValue.label = value;
    }
    return newValue;
  };

  const handleChange = (_, value) => {
    if (Array.isArray(value)) {
      // If multiple selections is set, then value is an array
      const values = value;
      const newValues = values.map(value => handleFormatValue(value));
      onChange({ name, value: newValues });
    } else {
      const newValue = handleFormatValue(value);
      onChange({ name, value: newValue });
    }
  };

  const handleChangeTextField = event => {
    const value = event.target.value;
    setFilter(value);
  };

  return (
    <Autocomplete
      id="autocompleAsynchronous"
      value={value}
      forcePopupIcon
      multiple={multi}
      size="small"
      className={className}
      options={options || []}
      loading={loading}
      freeSolo={creatable}
      renderOption={option => option.label}
      getOptionLabel={option => handleOptionLabel(option)}
      filterOptions={(options, params) => handleFilterOptions(options, params)}
      getOptionSelected={(option, value) => option.value === value.value}
      defaultValue={multi && defaultValues ? defaultValues : defaultValues[0]}
      onChange={(event, value) => {
        handleChange(event, value);
      }}
      disableClearable={disableClearable}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          error={touched && Boolean(error)}
          helperText={touched ? error : ''}
          fullWidth
          variant="standard"
          onChange={event => handleChangeTextField(event)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}
