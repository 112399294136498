import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'utils/routes';
import { getAdmins } from 'redux/admins/actions';
import { tableColumns } from './helper';

import { Box } from '@material-ui/core';
import Main from 'components/layout/Main';
import PageHeader from 'components/layout/PageHeader';
import EditButton from 'components/Table/Actions/EditButton';
import CreateButton from 'components/Table/Actions/CreateButton';
import Table from 'components/Table';

function Admins() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleRefresh = ({ page, size, filter = null }) => {
    dispatch(
      getAdmins({
        page: page + 1,
        size: size,
        search: filter,
      })
    );
  };

  const actionComponents = [
    props => <EditButton {...props} pathname={ROUTES.ADMIN_EDIT} />,
  ];
  const generalActionComponents = [
    () => <CreateButton pathname={ROUTES.ADMIN_CREATE} />,
  ];

  const adminState = useSelector(state => state.admins);

  return (
    <Main pageTitle={t('admin.title')} isLoading={adminState.loading}>
      <Box>
        <PageHeader title={t('admin.title')} />
        <Table
          rows={adminState.items}
          columns={tableColumns}
          handleRefresh={handleRefresh}
          isSearchable
          isPaginatable
          isSortable
          totalItemCount={adminState.totalItemCount}
          actionComponents={actionComponents}
          generalActionComponents={generalActionComponents}
        />
      </Box>
    </Main>
  );
}

export default Admins;
