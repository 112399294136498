import { useEffect, useRef } from 'react';
import Label from 'components/core/Label';
import styled from 'styled-components';
import { theme } from 'utils/theme';

function Textarea({
  name,
  label,
  rows,
  value,
  onChange,
  displayLastLinesOnInit,
}) {
  const handleChange = ({ target: { name, value } }) => {
    onChange({ name, value });
  };

  const didMount = useRef(false);
  const areaRef = useRef();
  useEffect(() => {
    if (displayLastLinesOnInit && value && !didMount.current) {
      areaRef.current.scrollTop = areaRef.current.scrollHeight;
      didMount.current = true;
    }
  });

  return (
    <Container>
      <Label>{label}</Label>
      <StyledTextarea
        name={name}
        rows={rows}
        onChange={handleChange}
        value={value || ''}
        ref={areaRef}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

const StyledTextarea = styled.textarea`
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize + 1}px;
`;

export default Textarea;
