import styled from 'styled-components';

const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background: black;
  margin: 3px 5px 10px 0px;
`;

export default Divider;
