import { TextField } from 'components/core/input';
import { useTranslation } from 'react-i18next';

export default function VacantPositions({ values, touched, errors, onChange }) {
  const { t } = useTranslation();

  return (
    <TextField
      name="vacantPositions"
      label={t('paf.field.vacantPositions')}
      touched={touched.vacantPositions}
      error={errors.vacantPositions}
      type={'number'}
      handleChange={onChange}
      value={values.vacantPositions}
    />
  );
}
