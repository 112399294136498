import { getPostings } from './actions';
import { ACTIONS_TYPE } from 'redux/jobBoardsPosts/actions';
import history from 'utils/history';
import { pushRedirection } from 'utils/functions';
import { ROUTES } from 'utils/routes';

export const postingMiddleware = ({ dispatch, getState }) => next => action => {
  if (!action) return;

  if (action.type === ACTIONS_TYPE.POST_LETUDIANT_ADVERT_SUCCESS) {
    pushRedirection([ROUTES.JOBBOARDS_SUMMARY]);
  }
  if (
    action.type === ACTIONS_TYPE.POST_POSTINGS_SUCCESS ||
    action.type === ACTIONS_TYPE.ARCHIVE_POSTINGS_SUCCESS
  ) {
    console.log('postingMiddleware called...');

    const historyState = history.location.state || null;
    const historyParams = historyState ? historyState.filterFormated : null;
    const defaultParams = { admin: getState().auth.userId };

    const params = historyParams || defaultParams;
    console.log('get postings with params: ', params);
    dispatch(getPostings(params));
  }
  next(action);
};
