import { Box } from '@material-ui/core';

export const Subtitle = props => (
  <Box
    style={{ marginTop: '20px', marginLeft: '10px' }}
    fontWeight="fontWeightBold"
    fontStyle="italic"
    color="text.secondary"
    fontSize={15}
  >
    - {props.label} -
  </Box>
);
