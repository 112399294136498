import { ACTIONS_TYPE } from './actions';
import type { Action } from 'utils/types';
import { localStorageJwtKey } from 'utils/constants';
import jwtDecode from 'jwt-decode';

export const initialState = {
  isAuthenticated: localStorage.getItem(localStorageJwtKey) ? true : false,
  userRoles: localStorage.getItem(localStorageJwtKey)
    ? jwtDecode(localStorage.getItem(localStorageJwtKey)).roles
    : [],
  userId: localStorage.getItem(localStorageJwtKey)
    ? jwtDecode(localStorage.getItem(localStorageJwtKey)).id
    : null,
  userName: localStorage.getItem(localStorageJwtKey)
    ? jwtDecode(localStorage.getItem(localStorageJwtKey)).name
    : null,
  isProcessing: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS_TYPE.PROCESS_LOGIN_BEGIN:
      return {
        ...state,
        isAuthenticated: false,
        isProcessing: true,
      };

    case ACTIONS_TYPE.PROCESS_LOGIN_SUCCESS:
    case ACTIONS_TYPE.PROCESS_REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        userRoles: jwtDecode(action.result.token).roles,
        userId: jwtDecode(action.result.token).id,
        userName: jwtDecode(action.result.token).name,
        isProcessing: false,
      };

    case ACTIONS_TYPE.PROCESS_LOGIN_FAILURE:
    case ACTIONS_TYPE.PROCESS_REFRESH_TOKEN_FAILURE:
    case ACTIONS_TYPE.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        userRoles: [],
        userId: null,
        userName: null,
        isProcessing: false,
      };

    default:
      return state;
  }
};
