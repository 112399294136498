import {
  Button,
  Dialog as DialogMui,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
} from '@material-ui/core';

import S from './styles';

Dialog.defaultProps = {
  confirmText: 'Enregistrer',
  cancelText: 'Annuler',
  disableSubmit: false,
};

function Dialog(props) {
  const {
    open,
    title,
    contentText,
    confirmText,
    cancelText,
    onConfirm,
    onCancel,
    disableSubmit,
    classes,
  } = props;

  const handleConfirm = () => {
    onConfirm();
  };

  const handleClose = () => {
    onCancel();
  };

  return (
    <DialogMui
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
        {props.children}
      </DialogContent>
      <DialogActions className={classes.actionFields}>
        <Button onClick={handleClose} color="primary">
          {cancelText}
        </Button>
        <Button
          disabled={disableSubmit}
          onClick={handleConfirm}
          color="primary"
        >
          {confirmText}
        </Button>
      </DialogActions>
    </DialogMui>
  );
}

export default withStyles(S)(Dialog);
