import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  TYPES,
  fetchApplications,
  selectApplications,
  cleanUp,
  tableColumns,
  format,
  legend,
} from './helper';
import Table from 'components/core/Table';
import { InlineContainer } from 'components/core/containers';
import Legend from 'components/core/Legend';

import Detail from './Detail';
import Filters from './Filters';
import useSelectRows from 'utils/hooks/useSelectRows';

ApplicationTable.propTypes = {
  type: PropTypes.oneOf([TYPES.ACTIVE, TYPES.COMPLETED, TYPES.SUGGESTION]),
};

ApplicationTable.defaultProps = {
  type: TYPES.ACTIVE,
};

function ApplicationTable({ type, planId }) {
  const dispatch = useDispatch();
  const params = useParams();
  const offerId = Number(params.id);
  const applications = format(useSelector(selectApplications(type)), type);
  const selectable = useSelectRows(type === TYPES.ACTIVE, applications);

  useEffect(() => {
    fetchApplications({ offerId, type }, dispatch);
    return () => {
      cleanUp(dispatch, type);
    };
  }, []);

  const RowDetail = props => (
    <Detail
      type={type}
      offerId={offerId}
      row={props.row}
      cleanSelectedRows={selectable.cleanSelectedRows}
      planId={planId}
    />
  );

  const handleFilterChange = params => {
    fetchApplications({ ...params, offerId, type }, dispatch);
  };

  return (
    <>
      <InlineContainer align="bottom">
        <Filters
          onChange={handleFilterChange}
          displayActions={type === TYPES.ACTIVE}
          cleanSelectedRows={selectable.cleanSelectedRows}
        />
        <Legend items={legend(type)} />
      </InlineContainer>
      <Table
        columns={tableColumns}
        rows={applications}
        detail={RowDetail}
        selectable={type === TYPES.ACTIVE && selectable}
      />
    </>
  );
}

export default ApplicationTable;
