import { useTranslation } from 'react-i18next';

import { Box, TextField } from '@material-ui/core';
import { Subtitle } from './subtitle';

export default function SalaryFields(props) {
  const {
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    classes,
    showInternshipPart,
    showWorkAndStudyPart,
    showStudentJobPart,
    showWorkPart,
  } = props;

  const { t } = useTranslation();

  return (
    <Box className={classes.column}>
      {(showWorkAndStudyPart ||
        showStudentJobPart ||
        showWorkPart ||
        showInternshipPart) && <Subtitle label="Rémunérations" />}
      {showStudentJobPart && (
        <Box className={classes.row}>
          <TextField
            id="studentJobSalary"
            label={t('jobboards-posting.field.studentJobSalary')}
            autoComplete="off"
            helperText={touched.studentJobSalary ? errors.studentJobSalary : ''}
            error={touched.studentJobSalary && Boolean(errors.studentJobSalary)}
            className={classes.largeTextField}
            margin="normal"
            onChange={event => {
              const value = event.target.value;
              setFieldValue('studentJobSalary', value);
              setFieldTouched('studentJobSalary', true, false);
            }}
            value={values.studentJobSalary ? values.studentJobSalary : ''}
          />
        </Box>
      )}
      {showWorkPart && (
        <Box className={classes.row}>
          <TextField
            id="workSalary"
            label={t('jobboards-posting.field.workSalary')}
            autoComplete="off"
            helperText={touched.workSalary ? errors.workSalary : ''}
            error={touched.workSalary && Boolean(errors.workSalary)}
            className={classes.largeTextField}
            margin="normal"
            onChange={event => {
              const value = event.target.value;
              setFieldValue('workSalary', value);
              setFieldTouched('workSalary', true, false);
            }}
            value={values.workSalary ? values.workSalary : ''}
          />
        </Box>
      )}
      {showInternshipPart && (
        <Box className={classes.row}>
          <TextField
            id="internshipSalary"
            label={t('jobboards-posting.field.internshipSalary')}
            autoComplete="off"
            helperText={touched.internshipSalary ? errors.internshipSalary : ''}
            error={touched.internshipSalary && Boolean(errors.internshipSalary)}
            className={classes.largeTextField}
            margin="normal"
            onChange={event => {
              const value = event.target.value;
              setFieldValue('internshipSalary', value);
              setFieldTouched('internshipSalary', true, false);
            }}
            value={values.internshipSalary ? values.internshipSalary : ''}
          />
        </Box>
      )}
      {showWorkAndStudyPart && (
        <Box className={classes.row}>
          <TextField
            id="workAndStudySalary"
            label={t('jobboards-posting.field.workAndStudySalary')}
            autoComplete="off"
            helperText={
              touched.workAndStudySalary ? errors.workAndStudySalary : ''
            }
            error={
              touched.workAndStudySalary && Boolean(errors.workAndStudySalary)
            }
            className={classes.largeTextField}
            margin="normal"
            onChange={event => {
              const value = event.target.value;
              setFieldValue('workAndStudySalary', value);
              setFieldTouched('workAndStudySalary', true, false);
            }}
            value={values.workAndStudySalary ? values.workAndStudySalary : ''}
          />
        </Box>
      )}
    </Box>
  );
}
