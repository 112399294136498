import { matchPath } from 'react-router-dom';
import {
  getOffers,
  getOverviewStats,
  getApplications,
  getSuggestions,
  getOneOffer,
} from './actions';
import { ROUTES } from 'utils/routes';
import { ACTIONS_TYPE } from 'redux/offers/actions';
import history from 'utils/history';
import { pushRedirection } from 'utils/functions';

export const offersMiddleware = ({ dispatch }) => next => action => {
  if (!action) {
    return;
  }
  if (
    action.type === ACTIONS_TYPE.SWITCH_OFFER_APPLICATION_SUCCESS ||
    action.type === ACTIONS_TYPE.SEND_SL_SUCCESS ||
    action.type === ACTIONS_TYPE.NOTIFY_OFFER_CONTACT_ATTEMPT_SUCCESS ||
    action.type === ACTIONS_TYPE.NOTIFY_OFFER_RESUME_UPDATE_SUCCESS ||
    action.type === ACTIONS_TYPE.ADD_APPLICATION_COMMENT_SUCCESS
  ) {
    console.log('offersMiddleware called...');
    const offerId = Number(history.location.pathname.split('/').pop());
    const qs = new URLSearchParams(window.location.search);
    dispatch(
      getApplications({
        offerId: offerId,
        status: qs.get('application'),
      })
    );
  }
  if (
    action.type === ACTIONS_TYPE.SUGGEST_OFFER_SUCCESS ||
    action.type === ACTIONS_TYPE.ADD_OFFER_APPLICATION_SUCCESS
  ) {
    console.log('offersMiddleware called...');
    const offerId = Number(history.location.pathname.split('/').pop());
    dispatch(
      getSuggestions({
        offerId: offerId,
      })
    );
  }

  if (action.type === ACTIONS_TYPE.UPDATE_OFFER_STATUS_SUCCESS) {
    console.log('offersMiddleware called...');

    const qs = new URLSearchParams(window.location.search);

    const status = qs.get('status');
    const params = {
      page: qs.get('page') || 1,
      size: qs.get('page-size') || 20,
      filter: qs.get('search'),
      admin: qs.get('admin'),
      status: status === 'standBy' ? 'inProgress' : status,
      standBy: status === 'standBy' ? 1 : 0,
    };

    const matchCompany = matchPath(
      history.location.pathname,
      ROUTES.COMPANY_SHOW
    );
    if (matchCompany) {
      params.company = matchCompany.params.id;
      params.standBy = null;
    }

    dispatch(getOffers(params));
    dispatch(
      getOverviewStats({
        adminId: qs.get('admin'),
      })
    );
  }

  if (
    action.type === ACTIONS_TYPE.CREATE_OFFER_SUCCESS ||
    action.type === ACTIONS_TYPE.UPDATE_OFFER_SUCCESS
  ) {
    console.log('offersMiddleware called...');

    const matchOfferForm = matchPath(history.location.pathname, [
      ROUTES.OFFER_EDIT,
      ROUTES.OFFER_CREATE,
    ]);

    if (matchOfferForm) {
      console.log('redirect to previous url...');
      const expectedPaths = [ROUTES.OFFER_LIST];
      pushRedirection(expectedPaths);
    }
  }

  if ([ACTIONS_TYPE.INTERACT_WITH_CUSTOMER_SUCCESS].includes(action.type)) {
    console.log('offersMiddleware called...');

    const offerId = action.result.offerId;
    console.log('refresh offer ', offerId);

    dispatch(getOneOffer({ id: offerId }));
  }

  next(action);
};
