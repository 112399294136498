import Accordion from 'components/core/Accordion';
import styled from 'styled-components';

const FilterAccordion = ({ children }) => {
  return (
    <Accordion label="Filtres">
      <Container>{children}</Container>
    </Accordion>
  );
};

const Container = styled.div`
  display: flex;
  column-gap: 20px;
`;

export default FilterAccordion;
