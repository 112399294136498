import { useSelector, useDispatch } from 'react-redux';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';

import { cleanCurrentPaf, getOnePaf, updatePaf } from 'redux/pafs/actions';

import Form from 'components/eiquem/placement/paf/Form';
import Main from 'components/layout/Main';
import Title from 'components/layout/Title';
import { Paper } from 'components/core/containers';
import { useParamId } from 'utils/hooks';
import { useEffect } from 'react';

function PafEditPage() {
  const { t } = useTranslation();

  const id = useParamId();

  const dispatch = useDispatch();
  useEffect(
    () => {
      dispatch(getOnePaf({ id }));
      return dispatch(cleanCurrentPaf());
    },
    [dispatch, id]
  );

  const isLaoding = useSelector(state => state.pafs.loading);
  const paf = useSelector(state => state.pafs.current);

  const handleOnSubmit = values => {
    dispatch(updatePaf(values));
  };

  return (
    <Main pageTitle={t('menu.recruitmentTitle')} isLoading={isLaoding}>
      {paf && (
        <div>
          <Title>
            {t('paf.recruitment-start-date')}
            {' : '}
            {format(new Date(paf.recruitmentStartDate), 'dd/MM/yyyy')}
          </Title>
          <Paper>
            <Form paf={paf} onSubmit={handleOnSubmit} />
          </Paper>
        </div>
      )}
    </Main>
  );
}

export default PafEditPage;
