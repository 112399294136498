export const getShortListMailFr = (offer, applications) => {
  if (!applications || !applications.length) {
    return null;
  }
  const offerLabel = offer.label;
  const useFamiliarity = offer.familiarity;
  const contact = offer.contactName;

  if (useFamiliarity) {
    return frenchMailWithFamiliarity(offerLabel, contact, applications);
  } else {
    return frenchMailWithoutFamiliarity(offerLabel, contact, applications);
  }
};

const frenchMailWithFamiliarity = (offerLabel, contact, applications) => `
<p>Bonjour ${contact},</p>

<p>J'espère que tu vas bien.</p>

<p>
  Tu trouveras ci-joint ${count(applications)}
  pour le poste de ${offerLabel}.
<p>

<p>${formatApplications(applications)}</p>

<p>Tu trouveras ${countResume(applications)}</p>

<p>Je suis disponible si tu as besoin d'informations supplémentaires.</p>

<p>
  Je te souhaite une excellente fin de journée,<br/>
  À bientôt,
</p>
`;

const frenchMailWithoutFamiliarity = (offerLabel, contact, applications) => `
<p>Bonjour ${contact},</p>

<p>J'espère que vous allez bien.</p>

<p>
  Vous trouverez ci-joint ${count(applications)} pour le poste de ${offerLabel}.
</p>

<p>${formatApplications(applications)}</p>

<p>Vous trouverez ${countResume(applications)}</p>

<p>Je suis disponible si vous avez besoin d'informations supplémentaires.</p>

<p>
  Je vous souhaite une excellente fin de journée,<br/>
  À bientôt,
</p>
`;

const count = applications =>
  `
${applications.length} ${
    applications.length > 1 ? 'nouvelles candidatures' : 'nouvelle candidature'
  }
`;

const formatApplications = applications =>
  applications
    .map(application => {
      const research = application.research;
      return `
      <p>
        <b>${research.candidate.name}</b>, disponible à partir du
        ${research.startDate} pour un 
        ${research.contractLabel}${
        research.duration ? ` d'une durée de ${research.duration} mois.` : '.'
      }
        ${
          application.comments.length
            ? `${application.comments[0].message}`
            : ''
        }
      </p>
`;
    })
    .join('');

const countResume = applications =>
  `${
    applications.length > 1
      ? 'leurs CVs en pièces jointes.'
      : 'son CV en pièce jointe.'
  }`;
