import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import Autocomplete from 'components/core/input/Autocomplete';
import S from '../styles';

import { jobBoardsOptions } from '../constants';

ArchiveButton.propTypes = {
  classes: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  action: PropTypes.func.isRequired,
};

function ArchiveButton(props) {
  const { classes, offer, action } = props;
  const [open, setOpen] = useState(false);
  const [jobBoard, setJobBoard] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    action(offer.id, jobBoard.value);
    handleClose();
  };

  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t('jobboards-posting.summary.archive-tooltip')}>
        <IconButton
          size="small"
          className={classes.button}
          onClick={handleClickOpen}
        >
          <Close fontSize="small" />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title">
          {t('jobboards-posting.summary.archive-title')}{' '}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('jobboards-posting.summary.archive-text')}
          </DialogContentText>
          <Autocomplete
            className={classes.selectField}
            options={jobBoardsOptions}
            label={t('jobboards-posting.summary.jobboard-label')}
            onChange={({ value }) => {
              setJobBoard(value);
            }}
          />
        </DialogContent>
        <DialogActions className={classes.actionFields}>
          <Button onClick={handleClose} color="primary">
            {t('jobboards-posting.summary.archive-cancel')}
          </Button>
          <Button
            disabled={jobBoard === null}
            onClick={handleConfirm}
            color="primary"
          >
            {t('jobboards-posting.summary.archive-confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withStyles(S)(ArchiveButton);
