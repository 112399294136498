import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOneCompany,
  cleanCurrentCompany,
  updateCompany,
  createCompany,
} from 'redux/companies/actions';

const useIsLoading = () => useSelector(state => state.companies.loading);

export function useFetchCompany(id) {
  const dispatch = useDispatch();

  useEffect(
    () => {
      return () => dispatch(cleanCurrentCompany());
    },
    [dispatch, id]
  );

  const fetch = useCallback(id => dispatch(getOneCompany({ id })), [dispatch]);
  const company = useSelector(state => state.companies.current);

  return [fetch, company, useIsLoading()];
}

export const useUpdateCompany = () => {
  const dispatch = useDispatch();

  const update = data => {
    console.log('call update company with data: ', data);
    dispatch(updateCompany(data));
  };

  return update;
};

export const useCreateCompany = () => {
  const dispatch = useDispatch();

  const create = data => {
    console.log('call create company with data: ', data);
    dispatch(createCompany(data));
  };

  return create;
};
