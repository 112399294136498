import DateFnsUtils from '@date-io/date-fns';
import { isValid } from 'date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

DatePicker.defaultProps = {
  format: 'dd/MM/yyyy',
};

function DatePicker(props) {
  const {
    name,
    label,
    value,
    onChange,
    disabled,
    touched,
    error,
    format,
  } = props;

  const handleChange = value => {
    const isValidValue = isValid(value);
    if (value === null || isValidValue) {
      onChange({
        name,
        value,
      });
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        id={label}
        disableToolbar
        autoComplete="off"
        format={format}
        helperText={touched ? error : ''}
        error={touched && Boolean(error)}
        label={label}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        style={{ width: '100%' }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePicker;
