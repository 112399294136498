const options = {
  getAdmins: ({ onlyActive, search = null, id = null }) => ({
    url: '/options/admins',
    method: 'get',
    params: { onlyActive, search, id },
  }),
  getContractTypes: () => ({
    url: '/options/contract_types',
    method: 'get',
  }),
  getCandidates: ({ search }) => ({
    url: '/options/candidates',
    method: 'get',
    params: { search },
  }),
  getBusinessSectors: () => ({
    url: '/options/businessSectors',
    method: 'get',
  }),
  getCompanies: ({ search = null, id = null } = {}) => ({
    url: '/options/companies',
    method: 'get',
    params: { search, id },
  }),
  getCompanySizes: () => ({
    url: '/options/company_sizes',
    method: 'get',
  }),
  getSources: ({ type = 'company' }) => ({
    url: '/options/sources',
    params: {
      type, // candidate || company
    },
  }),
  getTags: ({ search }) => ({
    url: '/options/tags',
    params: { search },
  }),
  getPlans: () => ({
    url: '/options/plans',
  }),
  getSchoolTypes: () => ({
    url: '/options/schoolTypes',
  }),
  getSchools: ({ search, id }) => ({
    url: '/options/schools',
    params: { search, id },
  }),
  getProfiles: () => ({
    url: '/options/profiles',
  }),
  getOffers: ({ company, onlyActive, offerFunction }) => ({
    url: '/options/offers',
    method: 'get',
    params: { company, onlyActive, fromDate: '01/01/2018', offerFunction },
  }),
  getRecruiters: ({ company }) => ({
    url: `/options/recruiters`,
    method: 'get',
    params: { company },
  }),
  getFunctions: () => ({
    url: '/options/functions',
    method: 'get',
  }),
  getFunctionsGroupedByFamily: () => ({
    url: '/options/functions/v2',
    method: 'get',
  }),
  getStudyLevels: () => ({
    url: '/options/study_levels',
    method: 'get',
  }),
  getActiveInvoices: ({ id }) => ({
    url: `/options/invoices/${id}`,
    method: 'get',
  }),
  getAmountOptions: ({ id }) => ({
    url: `/options/offers/${id}/amounts`,
    method: 'get',
  }),
  getHiredCandidateOptions: ({ id }) => ({
    url: `/options/offers/${id}/hiredCandidates`,
    method: 'get',
  }),
  getResearchStatusOptions: () => ({
    url: `/options/researches/status`,
    method: 'get',
  }),
  getCities: ({ name = null }) => ({
    url: `/options/cities`,
    method: 'get',
    params: { name: name },
  }),
  getTeleworking: () => ({
    url: `/options/teleworking`,
    method: 'get',
  }),
  getRecruiterRoles: () => ({
    url: '/options/roles/recruiters',
  }),
  getCommercialProposalStatus: () => ({
    url: '/options/commercial-proposal-status',
  }),
};

export default options;
