import { useSelector } from 'react-redux';
import Item from 'components/core/Item';

function Statistics() {
  const stats = useSelector(state => state.commercialProposals.kpi);

  const format = value => {
    return `${value} (${((100 * value) / (stats.total || 1)).toFixed(1)}%)`;
  };

  return (
    <>
      <Item label="En cours" value={format(stats.inProgress)} />
      <Item label="En attente" value={format(stats.pending)} />
      <Item label="Refusée" value={format(stats.refused)} />
      <Item label="Signées" value={format(stats.signed)} />
    </>
  );
}

export default Statistics;
