import { theme } from 'utils/theme';
import { TextField } from 'components/core/input';

export default function YearInput({ year, setYear }) {
  return (
    <TextField
      value={year}
      type="number"
      inputStyle={{
        width: 55,
        fontSize: theme.typography.fontSize + 4,
      }}
      handleChange={({ value }) => setYear(value)}
    />
  );
}
