import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useQueryState } from 'utils/hooks/queryState';
import { useDebounce } from 'utils/hooks/debounce';
import styled from 'styled-components';

import TextField from 'components/core/input/TextField';
import { Clear } from '@material-ui/icons';

TextFilter.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  endAdornment: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.string || PropTypes.number,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
};
TextFilter.defaultProps = {
  onChange: () => {},
  clearable: false,
};

function TextFilter({
  name,
  placeholder,
  label,
  endAdornment,
  type,
  defaultValue,
  onChange,
  clearable,
}) {
  const [query, setQuery] = useQueryState(defaultValue || null, name);
  const [value, setValue] = useState(query || null);

  const debouncedValue = useDebounce(value, 500);
  const didMount = useRef(false);
  useEffect(
    () => {
      if (didMount.current) {
        setQuery(debouncedValue);
        onChange({ name: name, value: value });
      }
      didMount.current = true;
    },
    [debouncedValue]
  );

  const handleChange = ({ value }) => {
    setValue(value);
  };

  const handleClear = () => {
    if (value === null) {
      return;
    }
    setValue(null);
  };

  return (
    <TextField
      placeholder={placeholder}
      label={label}
      value={value}
      handleChange={handleChange}
      endAdornment={
        clearable ? <ClearAdornment onClear={handleClear} /> : endAdornment
      }
      type={type}
    />
  );
}

const ClearButton = styled.button`
  background: transparent;
  border: transparent;
`;

const ClearAdornment = ({ onClear }) => (
  <ClearButton onClick={onClear}>
    <Clear />
  </ClearButton>
);

export default TextFilter;
