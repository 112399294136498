import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { partielId, proId, apprentissageId } from 'utils/constants';

import {
  getContractTypeOptions,
  getResearchStatusOptions,
  getProfileOptions,
} from 'redux/formOptions/actions';

import { Grid, withStyles } from '@material-ui/core';

import Autocomplete from 'components/core/input/Autocomplete';
import DatePicker from 'components/core/input/DatePicker';
import TextField from 'components/core/input/TextField';
import Checkbox from 'components/Checkbox';
import Slider from 'components/Slider';

import SubmitButtons from 'components/core/SubmitButtons';
import S from './styles';

function Form(props) {
  const { values, handleSubmit, setFieldValue, classes } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChange = (fieldName, value) => {
    setFieldValue(fieldName, value, true);
  };

  const options = useSelector(state => state.formOptions);
  const contractTypeOptions = options ? options.contractTypeOptions : null;
  const statusOptions = options ? options.researchStatusOptions : null;
  const profileOptions = options ? options.profileOptions : null;

  useEffect(
    () => {
      dispatch(contractTypeOptions ? null : getContractTypeOptions());
      dispatch(statusOptions ? null : getResearchStatusOptions());
      dispatch(profileOptions ? null : getProfileOptions());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const researchStatus = useSelector(
    state => state.candidates
  ).current.researches.find(research => research.id === values.id).status;
  useEffect(
    () => {
      if (researchStatus.label !== values.status.label) {
        // Research is completed if the candidate is hired
        handleChange('status', researchStatus);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [researchStatus]
  );
  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={6} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
              <Autocomplete
                defaultValue={values.status}
                value={values.status}
                options={statusOptions}
                label={t('common.fields.status')}
                onChange={({ value }) => {
                  handleChange('status', value);
                }}
                disableClearable
              />
            </Grid>
            <Grid item xs={6} md={6} />

            <Grid item xs={6} md={6}>
              <Autocomplete
                defaultValue={values.contractType}
                value={values.contractType}
                options={contractTypeOptions}
                label={t('common.fields.contractType')}
                onChange={({ value }) => {
                  handleChange('contractType', value);
                }}
                disableClearable
              />
            </Grid>
            <Grid item xs={6} md={6} />

            {values.contractType &&
              (values.contractType.value === partielId ||
                values.contractType.value === proId ||
                values.contractType.value === apprentissageId) && (
                <>
                  <Grid item xs={6} md={6}>
                    <TextField
                      label={t('common.fields.rhythm')}
                      className={classes.field}
                      placeholder={t('common.fields.rhythmPlaceholder')}
                      handleChange={({ value }) => {
                        handleChange('rhythm', value);
                      }}
                      value={values.rhythm}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      label={t('common.fields.formationCost')}
                      className={classes.field}
                      placeholder={t('common.fields.formationCostPlaceholder')}
                      handleChange={({ value }) => {
                        handleChange('cost', value);
                      }}
                      value={values.cost}
                    />
                  </Grid>
                </>
              )}
            <Grid item xs={6} md={6}>
              <DatePicker
                className={classes.field}
                label={t('common.fields.startDate')}
                value={values.startDate}
                onChange={({ value }) => {
                  handleChange('startDate', value);
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                label={t('common.fields.duration')}
                className={classes.field}
                handleChange={({ value }) => {
                  handleChange('duration', value);
                }}
                value={values.duration}
                type="number"
                endAdornment={t('common.month')}
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <Autocomplete
                defaultValue={values.profile}
                value={values.profile}
                options={profileOptions}
                label={t('common.fields.profile')}
                onChange={({ value }) => {
                  handleChange('profile', value);
                }}
              />
            </Grid>
            <Grid item xs={6} md={6} />

            <Grid item xs={6} md={6}>
              <Checkbox
                label={t('common.fields.finalYear')}
                value={values.finalYear}
                handleChange={value => handleChange('finalYear', value)}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Checkbox
                label={t('common.fields.gapYear')}
                value={values.gapYear}
                handleChange={value => handleChange('gapYear', value)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Slider
                label={t('common.fields.elocution')}
                value={values.elocution}
                handleChange={value => handleChange('elocution', Number(value))}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Slider
                label={t('common.fields.communication')}
                value={values.communication}
                handleChange={value =>
                  handleChange('communication', Number(value))
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Slider
                label={t('common.fields.motivation')}
                value={values.motivation}
                handleChange={value =>
                  handleChange('motivation', Number(value))
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Slider
                label={t('common.fields.dynamism')}
                value={values.dynamism}
                handleChange={value => handleChange('dynamism', Number(value))}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            label={t('common.fields.candidateComment')}
            className={classes.field}
            multiline
            rowMax={4}
            rows={4}
            handleChange={({ value }) => {
              handleChange('candidateComment', value);
            }}
            value={values.candidateComment}
          />
        </Grid>
      </Grid>

      <SubmitButtons cancelAction={() => {}} />
    </form>
  );
}

export default withStyles(S)(Form);
