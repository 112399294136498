import { TextField } from 'components/core/input';
import { useTranslation } from 'react-i18next';

export function ContractDuration({ values, touched, errors, onChange }) {
  const { t } = useTranslation();

  return (
    <TextField
      name={'contractDuration'}
      label={t('paf.field.contractDuration')}
      touched={touched.contractDuration}
      error={errors.contractDuration}
      type={'number'}
      handleChange={onChange}
      value={values.contractDuration}
    />
  );
}

export const computeContractDuration = contractType => {
  if (!contractType) return 0;
  else if (contractType === 'CDI') return 8;
  else if (contractType === 'CDD') return 12;
  else if (contractType.includes('Stage')) return 6;
  else if (contractType.includes('Contrat')) return 12;
  else return 0;
};
