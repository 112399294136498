import PageHeader from 'components/layout/PageHeader';
import { Grid } from 'components/core/containers';
import Comment from './Comment';
import RecruiterTable from 'components/eiquem/company/RecruiterTable';
import EditButton from 'components/core/buttons/EditButton';
import { ROUTES } from 'utils/routes';
import CollaborationReview from './CollaborationReview';
import Field from './Field';
import Title from './Title';

export default function CompanyDetail({ company }) {
  return (
    <>
      {company && (
        <PageHeader
          title={
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 10 }}> {company.name}</div>
              <EditButton
                path={ROUTES.COMPANY_EDIT.replace(':id', company.id)}
                fontSize="medium"
              />
            </div>
          }
          subtitles={
            company.logo && (
              <img
                src={company.logo}
                alt={company.name}
                width={50}
                style={{ marginRight: 10 }}
              />
            )
          }
          defaultOpen={true}
        >
          {/* page container */}
          <Grid container spacing={25}>
            <Grid md={12}>
              {/* top container */}
              <Grid container spacing={25}>
                {/* left container */}
                <Grid sm={6} md={5}>
                  <Grid container spacing={25}>
                    <Grid md={4}>
                      <Grid container spacing={0}>
                        <Title label="common.company" />
                        <Field label="name" value={company.name} />
                        <Field
                          label="businessSector"
                          value={company.businessSector?.label}
                        />
                        <Field
                          label="size"
                          value={company.size?.label}
                          type="number"
                        />
                        <Field
                          label="employeesCount"
                          value={company.employeesCount}
                        />
                        <Field
                          label="provenance"
                          value={company.provenance?.label}
                        />
                        <Field label="admin" value={company.admin?.label} />
                      </Grid>
                    </Grid>

                    <Grid md={4}>
                      <Grid container spacing={0}>
                        <Title label="Bilan Financier" />
                        <Field
                          label="invoiceCount"
                          value={company.kpi.invoiceCount}
                          type="number"
                        />
                        <Field
                          label="income"
                          value={company.kpi.paid}
                          type="currency"
                        />
                        <Field
                          label="unpaid"
                          value={company.kpi.unpaid}
                          type="currency"
                        />
                        <Field
                          label="currentTurnover"
                          value={company.kpi.currentYearTurnover}
                          type="currency"
                        />
                        <Field
                          label="lastYearTurnover"
                          value={company.kpi.lastYearTurnover}
                          type="currency"
                        />
                        <Field
                          label="collaborationStartDate"
                          value={company.kpi.collaborationStartDate}
                          type="date"
                        />
                      </Grid>
                    </Grid>

                    <Grid md={4}>
                      <Grid container spacing={0}>
                        <CollaborationReview company={company} />
                      </Grid>
                    </Grid>

                    <Grid md={4}>
                      <Grid container spacing={0}>
                        <Title label="common.address" />
                        <Field label="street" value={company.street} />
                        <Field label="postCode" value={company.postCode} />
                        <Field label="city" value={company.city} />
                        <Field label="country" value={company.country} />
                        <Field label="email" value={company.email} />
                        <Field
                          label="phoneNumber"
                          value={company.phoneNumber}
                        />
                        <Field label="websiteUrl" value={company.websiteUrl} />
                      </Grid>
                    </Grid>

                    <Grid md={4}>
                      <Grid container spacing={0}>
                        <Title label="common.billingAddress" />
                        <Field
                          label="name"
                          value={
                            company.differentBillingAddress
                              ? company.billingName
                              : company.name
                          }
                        />
                        <Field
                          label="street"
                          value={
                            company.differentBillingAddress
                              ? company.billingStreet
                              : company.street
                          }
                        />
                        <Field
                          label="postCode"
                          value={
                            company.differentBillingAddress
                              ? company.billingPostCode
                              : company.postCode
                          }
                        />
                        <Field
                          label="city"
                          value={
                            company.differentBillingAddress
                              ? company.billingCity
                              : company.city
                          }
                        />
                        <Field
                          label="country"
                          value={
                            company.differentBillingAddress
                              ? company.billingCountry
                              : company.country
                          }
                        />
                        <Field
                          label="contact"
                          value={company.billingContactName}
                        />
                        <Field
                          label="email"
                          value={company.billingEmail || company.email}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* right container */}
                <Grid sm={6} md={7}>
                  <Grid container spacing={25}>
                    {/* description */}
                    <Grid sm={12} md={12}>
                      <Title label="common.fields.description" />
                      <HtmlDiv content={company.description} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* bottom container */}
            <Grid md={12}>
              <Grid container spacing={25}>
                {/* internal comment */}
                <Grid md={5}>
                  <Title label="common.fields.internalComment" />
                  <Comment
                    initialValue={company.comment}
                    companyId={company.id}
                  />
                </Grid>
                {/* recruiters */}
                <Grid md={7}>
                  <Title label="common.fields.recruiter" />
                  <div style={{ marginTop: -32 }}>
                    <RecruiterTable companyId={company.id} />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </PageHeader>
      )}
    </>
  );
}

const HtmlDiv = ({ content }) => (
  <div
    style={{
      maxHeight: 400,
      overflowY: 'auto',
    }}
    dangerouslySetInnerHTML={{
      __html: content,
    }}
  />
);
