import { ACTIONS_TYPE } from './actions';
import history from 'utils/history';

export const recruitersMiddleware = () => next => action => {
  if (!action) {
    return;
  }
  if (
    action.type === ACTIONS_TYPE.CREATE_RECRUITER_SUCCESS ||
    action.type === ACTIONS_TYPE.UPDATE_RECRUITER_SUCCESS
  ) {
    console.log('recruitersMiddleware called...');
    console.log('redirect to previous url...');
    history.goBack();
  }

  next(action);
};
