import BaseButton from './baseButton';

export default function NotAvailableBeforeInterviewButton(props) {
  const { value } = props;

  const msg = `Souhaites-tu ABANDONNER cette candidature pour la raison PLUS DISPONIBLE AVANT ENTRETIEN ?
    Aucun mail ne sera envoyé au candidat mais le statut de sa candidature sera mis à jour.`;

  return (
    <BaseButton
      title="Le candidat n'est plus disponible avant l'entretien"
      label="Av entr"
      message={msg}
      value={value}
      color="secondary"
      action={props.action}
      disabled={props.disabled}
    />
  );
}
