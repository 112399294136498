import { fetch } from 'utils/api';
import services from 'utils/api/Services/services';
import { actionRequest, actionSuccess, actionFailure } from 'utils/functions';
import { offersArrayValidator } from 'utils/schemas';

export const ACTIONS_TYPE = Object.freeze({
  GET_OFFERS_BEGIN: 'GET_OFFERS_BEGIN',
  GET_OFFERS_SUCCESS: 'GET_OFFERS_SUCCESS',
  GET_OFFERS_FAILURE: 'GET_OFFERS_FAILURE',
  GET_STAND_BY_OFFERS_BEGIN: 'GET_STAND_BY_OFFERS_BEGIN',
  GET_STAND_BY_OFFERS_SUCCESS: 'GET_STAND_BY_OFFERS_SUCCESS',
  GET_STAND_BY_OFFERS_FAILURE: 'GET_STAND_BY_OFFERS_FAILURE',
  GET_OFFER_BEGIN: 'GET_OFFER_BEGIN',
  GET_OFFER_SUCCESS: 'GET_OFFER_SUCCESS',
  GET_OFFER_FAILURE: 'GET_OFFER_FAILURE',
  GET_SAMPLE_BEGIN: 'GET_SAMPLE_BEGIN',
  GET_SAMPLE_SUCCESS: 'GET_SAMPLE_SUCCESS',
  GET_SAMPLE_FAILURE: 'GET_SAMPLE_FAILURE',
  CREATE_OFFER_BEGIN: 'CREATE_OFFER_BEGIN',
  CREATE_OFFER_SUCCESS: 'CREATE_OFFER_SUCCESS',
  CREATE_OFFER_FAILURE: 'CREATE_OFFER_FAILURE',
  UPDATE_OFFER_BEGIN: 'UPDATE_OFFER_BEGIN',
  UPDATE_OFFER_SUCCESS: 'UPDATE_OFFER_SUCCESS',
  UPDATE_OFFER_FAILURE: 'UPDATE_OFFER_FAILURE',
  UPDATE_OFFER_STATUS_BEGIN: 'UPDATE_OFFER_STATUS_BEGIN',
  UPDATE_OFFER_STATUS_SUCCESS: 'UPDATE_OFFER_STATUS_SUCCESS',
  UPDATE_OFFER_STATUS_FAILURE: 'UPDATE_OFFER_STATUS_FAILURE',
  CLEAN_CURRENT_OFFER: 'CLEAN_CURRENT_OFFER',
  CLEAN_OFFERS: 'CLEAN_OFFERS',

  GENERATE_OFFER_INVOICE_BEGIN: 'GENERATE_OFFER_INVOICE_BEGIN',
  GENERATE_OFFER_INVOICE_SUCCESS: 'GENERATE_OFFER_INVOICE_SUCCESS',
  GENERATE_OFFER_INVOICE_FAILURE: 'GENERATE_OFFER_INVOICE_FAILURE',
  SEND_SL_BEGIN: 'SEND_SL_BEGIN',
  SEND_SL_SUCCESS: 'SEND_SL_SUCCESS',
  SEND_SL_FAILURE: 'SEND_SL_FAILURE',
  DOWNLOAD_REPORT_BEGIN: 'DOWNLOAD_REPORT_BEGIN',
  DOWNLOAD_REPORT_SUCCESS: 'DOWNLOAD_REPORT_SUCCESS',
  DOWNLOAD_REPORT_FAILURE: 'DOWNLOAD_REPORT_FAILURE',
  GET_APPLICATIONS_BEGIN: 'GET_APPLICATIONS_BEGIN',
  GET_APPLICATIONS_SUCCESS: 'GET_APPLICATIONS_SUCCESS',
  GET_APPLICATIONS_FAILURE: 'GET_APPLICATIONS_FAILURE',
  CLEAN_OFFER_APPLICATIONS: 'CLEAN_OFFER_APPLICATIONS',

  GET_SUGGESTIONS_BEGIN: 'GET_SUGGESTIONS_BEGIN',
  GET_SUGGESTIONS_SUCCESS: 'GET_SUGGESTIONS_SUCCESS',
  GET_SUGGESTIONS_FAILURE: 'GET_SUGGESTIONS_FAILURE',
  CLEAN_OFFER_SUGGESTIONS: 'CLEAN_OFFER_SUGGESTIONS',

  SWITCH_OFFER_APPLICATION_BEGIN: 'SWITCH_OFFER_APPLICATION_BEGIN',
  SWITCH_OFFER_APPLICATION_SUCCESS: 'SWITCH_OFFER_APPLICATION_SUCCESS',
  SWITCH_OFFER_APPLICATION_FAILURE: 'SWITCH_OFFER_APPLICATION_FAILURE',

  ADD_OFFER_APPLICATION_BEGIN: 'ADD_OFFER_APPLICATION_BEGIN',
  ADD_OFFER_APPLICATION_SUCCESS: 'ADD_OFFER_APPLICATION_SUCCESS',
  ADD_OFFER_APPLICATION_FAILURE: 'ADD_OFFER_APPLICATION_FAILURE',

  NOTIFY_OFFER_RESUME_UPDATE_BEGIN: 'NOTIFY_OFFER_RESUME_UPDATE_BEGIN',
  NOTIFY_OFFER_RESUME_UPDATE_SUCCESS: 'NOTIFY_OFFER_RESUME_UPDATE_SUCCESS',
  NOTIFY_OFFER_RESUME_UPDATE_FAILURE: 'NOTIFY_OFFER_RESUME_UPDATE_FAILURE',

  NOTIFY_OFFER_CONTACT_ATTEMPT_BEGIN: 'NOTIFY_OFFER_CONTACT_ATTEMPT_BEGIN',
  NOTIFY_OFFER_CONTACT_ATTEMPT_SUCCESS: 'NOTIFY_OFFER_CONTACT_ATTEMPT_SUCCESS',
  NOTIFY_OFFER_CONTACT_ATTEMPT_FAILURE: 'NOTIFY_OFFER_CONTACT_ATTEMPT_FAILURE',

  SUGGEST_OFFER_BEGIN: 'OFFER_REMIND_SUGGESTION_BEGIN',
  SUGGEST_OFFER_SUCCESS: 'OFFER_REMIND_SUGGESTION_SUCCESS',
  SUGGEST_OFFER_FAILURE: 'OFFER_REMIND_SUGGESTION_FAILURE',

  ADD_APPLICATION_COMMENT_BEGIN: 'ADD_APPLICATION_COMMENT_BEGIN',
  ADD_APPLICATION_COMMENT_SUCCESS: 'ADD_APPLICATION_COMMENT_SUCCESS',
  ADD_APPLICATION_COMMENT_FAILURE: 'ADD_APPLICATION_COMMENT_FAILURE',

  UPDATE_APPLICATION_COMMENT_BEGIN: 'UPDATE_APPLICATION_COMMENT_BEGIN',
  UPDATE_APPLICATION_COMMENT_SUCCESS: 'UPDATE_APPLICATION_COMMENT_SUCCESS',
  UPDATE_APPLICATION_COMMENT_FAILURE: 'UPDATE_APPLICATION_COMMENT_FAILURE',

  DELETE_APPLICATION_COMMENT_BEGIN: 'DELETE_APPLICATION_COMMENT_BEGIN',
  DELETE_APPLICATION_COMMENT_SUCCESS: 'DELETE_APPLICATION_COMMENT_SUCCESS',
  DELETE_APPLICATION_COMMENT_FAILURE: 'DELETE_APPLICATION_COMMENT_FAILURE',

  GET_OFFERS_OVERVIEW_STATS_BEGIN: 'GET_OFFERS_OVERVIEW_STATS_BEGIN',
  GET_OFFERS_OVERVIEW_STATS_SUCCES: 'GET_OFFERS_OVERVIEW_STATS_SUCCES',
  GET_OFFERS_OVERVIEW_STATS_FAILURE: 'GET_OFFERS_OVERVIEW_STATS_FAILURE',

  GET_OFFER_STATS_BEGIN: 'GET_OFFER_STATS_BEGIN',
  GET_OFFER_STATS_SUCCESS: 'GET_OFFER_STATS_SUCCESS',
  GET_OFFER_STATS_FAILURE: 'GET_OFFER_STATS_FAILURE',

  INTERACT_WITH_CUSTOMER_BEGIN: 'INTERACT_WITH_CUSTOMER_BEGIN',
  INTERACT_WITH_CUSTOMER_SUCCESS: 'INTERACT_WITH_CUSTOMER_SUCCESS',
  INTERACT_WITH_CUSTOMER_FAILURE: 'INTERACT_WITH_CUSTOMER_FAILURE',

  APPOINTMENT_REQUEST_BEGIN: 'APPOINTMENT_REQUEST_BEGIN',
  APPOINTMENT_REQUEST_SUCCESS: 'APPOINTMENT_REQUEST_SUCCESS',
  APPOINTMENT_REQUEST_FAILURE: 'APPOINTMENT_REQUEST_FAILURE',

  SWITCH_OFFER_HEADLINE_BEGIN: 'SWITCH_OFFER_HEADLINE_BEGIN',
  SWITCH_OFFER_HEADLINE_SUCCESS: 'SWITCH_OFFER_HEADLINE_SUCCESS',
  SWITCH_OFFER_HEADLINE_FAILURE: 'SWITCH_OFFER_HEADLINE_FAILURE',
});

export const switchOfferHeadline = ({ id }) => (dispatch, getState) =>
  fetch({
    service: services.offers.switchHeadline({
      id,
    }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.SWITCH_OFFER_HEADLINE_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.SWITCH_OFFER_HEADLINE_SUCCESS, { id }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.SWITCH_OFFER_HEADLINE_FAILURE, errors),
  });

export const appointmentRequest = ({ applicationId }) => (dispatch, getState) =>
  fetch({
    service: services.applications.appointmentRequest({
      applicationId,
    }),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.APPOINTMENT_REQUEST_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.APPOINTMENT_REQUEST_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.APPOINTMENT_REQUEST_FAILURE, errors),
  });
export const getOfferStats = ({ offerId }) => (dispatch, getState) =>
  fetch({
    service: services.offers.getOfferStats({
      offerId,
    }),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_OFFER_STATS_BEGIN),
    actionSuccess: stats =>
      actionSuccess(ACTIONS_TYPE.GET_OFFER_STATS_SUCCESS, { offerId, stats }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_OFFER_STATS_FAILURE, errors),
  });
export const getOverviewStats = ({
  adminId,
  planIds,
  contractTypeIds,
  status,
}) => (dispatch, getState) =>
  fetch({
    service: services.offers.getOverviewStats({
      adminId,
      planIds,
      contractTypeIds,
      status,
    }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_OFFERS_OVERVIEW_STATS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_OFFERS_OVERVIEW_STATS_SUCCES, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_OFFERS_OVERVIEW_STATS_FAILURE, errors),
  });

export const deleteApplicationComment = ({ applicationId, commentId }) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.applications.deleteComment({
      applicationId,
      commentId,
    }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.DELETE_APPLICATION_COMMENT_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.DELETE_APPLICATION_COMMENT_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.DELETE_APPLICATION_COMMENT_FAILURE, errors),
  });

export const updateApplicationComment = ({
  applicationId,
  commentId,
  message,
}) => (dispatch, getState) =>
  fetch({
    service: services.applications.updateComment({
      applicationId,
      commentId,
      message,
    }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.UPDATE_APPLICATION_COMMENT_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.UPDATE_APPLICATION_COMMENT_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_APPLICATION_COMMENT_FAILURE, errors),
  });

export const addApplicationComment = ({ applicationId, message }) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.applications.addComment({ applicationId, message }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.ADD_APPLICATION_COMMENT_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.ADD_APPLICATION_COMMENT_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.ADD_APPLICATION_COMMENT_FAILURE, errors),
  });

export const suggestOffer = ({ researchId, offerId }) => (dispatch, getState) =>
  fetch({
    service: services.candidates.suggestOffer(researchId, offerId),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.SUGGEST_OFFER_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.SUGGEST_OFFER_SUCCESS, {
        researchId: researchId,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.SUGGEST_OFFER_FAILURE, errors),
  });

export const notifyContactAttempt = ({ applicationId }) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.applications.notifyContactAttempt({
      applicationId: applicationId,
    }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.NOTIFY_OFFER_CONTACT_ATTEMPT_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.NOTIFY_OFFER_CONTACT_ATTEMPT_SUCCESS, {
        result,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.NOTIFY_OFFER_CONTACT_ATTEMPT_FAILURE, errors),
  });

export const notifyResumeUpdateRequired = ({ applicationId }) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.applications.notifyResumeUpdateRequired({
      applicationId: applicationId,
    }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.NOTIFY_OFFER_RESUME_UPDATE_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.NOTIFY_OFFER_RESUME_UPDATE_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.NOTIFY_OFFER_RESUME_UPDATE_FAILURE, errors),
  });

export const addApplication = (researchId, offerId, code) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.candidates.addApplication(researchId, offerId, code),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.ADD_OFFER_APPLICATION_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.ADD_OFFER_APPLICATION_SUCCESS, {
        researchId: researchId,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.ADD_OFFER_APPLICATION_FAILURE, errors),
  });

export const switchApplicationState = ({
  applicationId,
  applicationIds,
  code,
  reason,
  prefilledPlacement = null,
}) => (dispatch, getState) =>
  fetch({
    service: services.applications.switchApplicationState({
      applicationId,
      applicationIds,
      code,
      reason,
      prefilledPlacement,
    }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.SWITCH_OFFER_APPLICATION_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.SWITCH_OFFER_APPLICATION_SUCCESS, {
        result,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.SWITCH_OFFER_APPLICATION_FAILURE, errors),
  });

export const cleanApplications = () => ({
  type: ACTIONS_TYPE.CLEAN_OFFER_APPLICATIONS,
});

export const getSuggestions = ({ offerId }) => (dispatch, getState) =>
  fetch({
    service: services.offers.getSuggestions({ offerId: offerId }),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_SUGGESTIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_SUGGESTIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_SUGGESTIONS_FAILURE, errors),
  });

export const cleanSuggestions = () => ({
  type: ACTIONS_TYPE.CLEAN_OFFER_SUGGESTIONS,
});

export const downloadReport = params => (dispatch, getState) =>
  fetch({
    service: services.offers.downloadReport(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.DOWNLOAD_REPORT_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.DOWNLOAD_REPORT_SUCCESS, {
        result,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.DOWNLOAD_REPORT_FAILURE, errors),
  });

export const sendShortListMail = params => (dispatch, getState) =>
  fetch({
    service: services.offers.sendShortListMail(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.SEND_SL_BEGIN),
    actionSuccess: () => actionSuccess(ACTIONS_TYPE.SEND_SL_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.SEND_SL_FAILURE, errors),
  });

export const generateOfferInvoice = params => (dispatch, getState) =>
  fetch({
    service: services.offers.generateOfferInvoice(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GENERATE_OFFER_INVOICE_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GENERATE_OFFER_INVOICE_SUCCESS, {
        result,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GENERATE_OFFER_INVOICE_FAILURE, errors),
  });

export const updateOneApplicationStatus = ({
  applicationId,
  offerId,
  statusLabel,
}) => (dispatch, getState) =>
  fetch({
    service: services.applications.updateOneApplicationStatus({
      offerId,
      applicationId,
      statusLabel,
    }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.UPDATE_APPLICATION_STATUS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.UPDATE_APPLICATION_STATUS_SUCCESS, {
        result,
        offerId,
        applicationId,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_APPLICATION_STATUS_FAILURE, errors),
  });

export const interactWithCustomer = ({ id, action }) => (dispatch, getState) =>
  fetch({
    service: services.offers.interactWithCustomer({
      id,
      action,
    }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.INTERACT_WITH_CUSTOMER_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.INTERACT_WITH_CUSTOMER_SUCCESS, {
        offerId: id,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.INTERACT_WITH_CUSTOMER_FAILURE, errors),
  });

export const getApplications = params => (dispatch, getState) =>
  fetch({
    service: services.offers.getApplications(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_APPLICATIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_APPLICATIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_APPLICATIONS_FAILURE, errors),
  });

export const updateOfferStatus = params => (dispatch, getState) =>
  fetch({
    service: services.offers.updateStatus(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.UPDATE_OFFER_STATUS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.UPDATE_OFFER_STATUS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_OFFER_STATUS_FAILURE, errors),
  });

export const getOneOffer = params => (dispatch, getState) =>
  fetch({
    service: services.offers.getOne(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_OFFER_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_OFFER_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_OFFER_FAILURE, errors),
  });

export const getSampleOffer = params => (dispatch, getState) =>
  fetch({
    service: services.offers.getOne(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_SAMPLE_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_SAMPLE_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_SAMPLE_FAILURE, errors),
  });

export const cleanOffers = () => ({
  type: ACTIONS_TYPE.CLEAN_OFFERS,
});

export const cleanCurrentOffer = () => ({
  type: ACTIONS_TYPE.CLEAN_CURRENT_OFFER,
});

export const updateOffer = params => (dispatch, getState) =>
  fetch({
    service: services.offers.update(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.UPDATE_OFFER_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.UPDATE_OFFER_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_OFFER_FAILURE, errors),
  });

export const createOffer = data => (dispatch, getState) =>
  fetch({
    service: services.offers.add(data),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.CREATE_OFFER_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.CREATE_OFFER_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.CREATE_OFFER_FAILURE, errors),
  });

export const getOffers = params => (dispatch, getState) =>
  fetch({
    service: services.offers.get(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_OFFERS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_OFFERS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_OFFERS_FAILURE, errors),
    validator: offersArrayValidator,
  });

export const getStandByOffers = params => (dispatch, getState) =>
  fetch({
    service: services.offers.getStandBy(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_STAND_BY_OFFERS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_STAND_BY_OFFERS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_STAND_BY_OFFERS_FAILURE, errors),
    validator: offersArrayValidator,
  });
