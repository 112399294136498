export default theme => ({
  root: {
    display: 'inline-block',
    padding: 20,
  },
  bold: {
    fontWeight: 'bold',
  },
  table: {
    maxWidth: 500,
  },
  tableHead: {
    color: '#3f51b5',
    fontWeight: 'bold',
  },
  firstColumn: {
    minWidth: 220,
  },
  cell: {
    maxWidth: 70,
    textAlign: 'center',
  },
  tableWrapper: {
    margin: 20,
  },
  row: {
    display: 'flex',
  },
  center: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  divider: {
    marginTop: 40,
    marginRight: 25,
    marginLeft: 25,
  },
  detail: {
    display: 'flex',
    marginTop: 25,
    height: 450,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  mainPanel: {
    width: '100%',
  },
  subPanel: {
    marginLeft: 40,
    marginTop: 25,
  },
  yearBlock: {
    display: 'flex',
  },
  selectField: {
    width: 220,
    marginLeft: 20,
    marginRight: 20,
  },
});
