import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Main from 'components/layout/Main';
import PageHeader from 'components/layout/PageHeader';
import { useEffect, useRef } from 'react';
import { getApplicationFollowUps } from 'redux/applicationFollowUps/actions';
import Table from 'components/core/Table/table';
import { FilterAccordion } from 'components/core/Accordion';
import AdminFilters from 'components/eiquem/shared/AdminFilter/adminFilter';
import { Grid, InlineContainer } from 'components/core/containers';
import Legend from 'components/core/Legend';
import { redFontCode } from 'utils/constants';
import { useQuery } from 'utils/hooks';
import { ROUTES } from 'utils/routes';

export default function ApplicationFollowUpsPage() {
  const { t } = useTranslation();
  const loading = useSelector(state => state.applicationFollowUps.loading);
  const followUps = [...useSelector(state => state.applicationFollowUps.items)];

  const dispatch = useDispatch();
  const qs = useQuery();
  const admin = qs.get('admin');

  const isMount = useRef(false);
  useEffect(
    () => {
      if (admin && !isMount.current) {
        dispatch(getApplicationFollowUps({ adminId: admin }));
        isMount.current = true;
      }
    },
    [dispatch, admin]
  );

  const onChange = ({ admin }) => {
    dispatch(getApplicationFollowUps({ adminId: admin }));
  };

  return (
    <Main pageTitle={t('applicationFollowUp.title')} isLoading={loading}>
      <PageHeader title={t('applicationFollowUp.title')} />
      <InlineContainer align="bottom">
        <FilterAccordion>
          <Grid container>
            <Grid md={4} sm={6}>
              <AdminFilters
                keepFilters={false}
                onChange={onChange}
                useAdminType={false}
              />
            </Grid>
          </Grid>
        </FilterAccordion>
        <Legend items={legendItems} />
      </InlineContainer>

      <Table columns={columns} rows={followUps && format(followUps)} />
    </Main>
  );
}

const columns = [
  {
    header: 'Date',
    accessors: ['date'],
    type: 'date',
  },
  {
    header: 'Candidat',
    accessors: ['candidateLink'],
    type: 'link',
  },
  {
    header: 'Offres',
    accessors: ['offerLinks'],
    type: 'links',
  },
  {
    header: 'Responsable',
    accessors: ['mainAdmin'],
  },
  {
    header: 'CdR',
    accessors: ['admins'],
  },
];

const format = rows =>
  rows.map(row => ({
    ...row,
    candidateLink: {
      label: row.candidate.label,
      url: ROUTES.CANDIDATE_SHOW.replace(':id', row.candidate.value),
    },
    offerLinks: row.offers.map(offer => ({
      label: `${offer.company} - ${offer.label}`,
      url: ROUTES.OFFER_SHOW.replace(':id', offer.value),
    })),
    backgroundColor: row.isExpired ? redFontCode : null,
  }));

export const legendItems = [{ color: redFontCode, label: 'Suivi en retard' }];
