const style = () => ({
  root: {
    paddingTop: 10,
  },
  row: {
    display: 'flex',
    marginTop: 20,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,
    marginLeft: 30,
    marginRight: 30,
  },
  field: {
    width: '100%',
  },
});
export default style;
