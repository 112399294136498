import BaseButton from './baseButton';

export default function RefuseButton(props) {
  const { value } = props;

  const msg = `Souhaites-tu REFUSER cette candidature ?
    Aucun mail ne sera envoyé au candidat mais le statut de sa candidature sera mis à jour.`;

  return (
    <BaseButton
      label="Refuser"
      message={msg}
      value={value}
      color="secondary"
      action={props.action}
      disabled={props.disabled}
    />
  );
}
