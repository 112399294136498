export default (theme: Theme) => ({
  topRoot: {
    overflowX: 'auto',
    display: 'inline-flex',
    width: '1280px',
    marginBottom: 20,
    paddingBottom: 20,
  },
  root: {
    overflowX: 'auto',
    display: 'inline-flex',
    width: '1280px',
  },
  row: {
    display: 'flex',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,
    marginLeft: 30,
    marginRight: 30,
    maxWidth: '600px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,
    maxWidth: '600px',
  },
  selectLargeField: {
    width: 550,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 15,
  },
  dateField: {
    width: 150,
    marginTop: 15,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 0,
  },
  largeTextField: {
    width: 550,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 15,
  },
  smallTextField: {
    width: 150,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 15,
  },
  divider: {
    height: '70%',
    alignSelf: 'center',
  },
  textField: {
    width: 240,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 15,
  },
  freeFields: {
    width: 550,
  },
  selectField: {
    width: 300,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 15,
  },
  checkbox: {
    marginTop: 20,
    marginBottom: 0,
    marginLeft: 0,
  },
  actionFields: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(5),
    display: 'flex',
  },
  rightFields: {
    textAlign: 'right',
    flex: 1,
  },
});
