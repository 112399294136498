import { useTranslation } from 'react-i18next';

import Main from 'components/layout/Main';
import OfferPanel from 'components/eiquem/offer/Panel';
import ApplicationsAndSuggestions from 'components/eiquem/offer/Applications';
import { useParamId } from 'utils/hooks';
import { useFetchOffer, useIsLoading } from 'utils/hooks/offer';

function OfferShow() {
  const { t } = useTranslation();

  const id = useParamId();
  const [offer] = useFetchOffer(id);
  const isLoading = useIsLoading();

  return (
    <Main pageTitle={t('menu.recruitmentTitle')} isLoading={isLoading}>
      {offer && (
        <>
          <OfferPanel />
          <ApplicationsAndSuggestions />
        </>
      )}
    </Main>
  );
}

export default OfferShow;
