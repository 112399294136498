import { TextField } from 'components/core/input';
import { useTranslation } from 'react-i18next';

export default function Comments({ values, touched, errors, onChange }) {
  const { t } = useTranslation();

  return (
    <TextField
      name="comments"
      label={t('paf.field.comments')}
      touched={touched.comments}
      error={errors.comments}
      handleChange={onChange}
      value={values.comments}
      multiline
    />
  );
}
