import NumberFormat from 'react-number-format';
import { Link as RouterLink } from 'react-router-dom';
import { Check } from '@material-ui/icons';
import { Link as MuiLink, Tooltip } from '@material-ui/core';
import { formatDate } from 'utils/functions';

/**
 * Returns value formated regarding column['type'] and accessed by from column['accessors']
 * @param {*} column
 * @param {*} row
 */
export const formatValue = (column, row) => {
  const accessors = column.accessors;
  if (typeof accessors === 'undefined') {
    return null;
  }
  // Retrieve the value from accessors tree [accessor1, accessor2, ...]
  let value;
  accessors.forEach(accessor => {
    if (value === null) {
      return;
    }
    value = 'undefined' === typeof value ? row[accessor] : value[accessor];
  });

  if (value === null) {
    return null;
  }
  // Adapt display for date and boolean fields
  const type = column.type;
  if (typeof type === 'undefined') {
    return value;
  }
  switch (type) {
    case 'date':
      return formatDate(value);
    case 'boolean':
      return value === true ? <Check fontSize="small" /> : '';
    case 'currency':
      return (
        <NumberFormat
          value={value}
          thousandSeparator={' '}
          suffix={'€'}
          displayType={'text'}
        />
      );
    case 'image':
      return value.url !== null ? (
        <Tooltip title={value.label}>
          <a href={value.link} rel="noreferrer">
            <img
              src={value.url}
              alt={value.label}
              style={{
                width: '50px',
                margin: 'auto',
                display: 'block',
              }}
            />
          </a>
        </Tooltip>
      ) : (
        <div
          style={{
            width: '50px',
            margin: 'auto',
            display: 'block',
          }}
        >
          {value.label}
        </div>
      );
    case 'link':
      return (
        <>
          {value.url && value.label ? (
            <MuiLink component={RouterLink} to={value.url}>
              {value.label}
            </MuiLink>
          ) : (
            <MuiLink href={value} target="_blank" rel="noreferrer">
              {column.header}
            </MuiLink>
          )}
        </>
      );
    default:
      return value;
  }
};
