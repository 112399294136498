import * as Yup from 'yup';
import {
  studentJobTypeId,
  internshipTypeId,
  workAndStudyTypeId,
  workTypeId,
  cdiId,
  rpoPlanId,
  accordingProfileSalaryId,
} from './constants';

const optionSchema = Yup.object().shape({
  value: Yup.number(),
  label: Yup.string(),
});

const stringSchema = Yup.string();

export const CommercialProposalFormSchema = Yup.object({
  admin: optionSchema.nullable().required('Required!'),
  status: optionSchema.nullable().required('Required!'),
  plan: optionSchema.nullable().required('Required!'),
  contractType: optionSchema.nullable().required('Required!'),
  lang: optionSchema.nullable().required('Required!'),
  contact: stringSchema.nullable().required(),
  email: stringSchema.nullable().required(),
  phone: stringSchema.nullable().required(),
  label: stringSchema.nullable().required(),
  fixedPrice: stringSchema.nullable().required(),
  variablePrice: stringSchema.nullable().required(),
  internalFixedPrice: stringSchema.nullable().required(),
  internalVariablePrice: stringSchema.nullable().required(),
  prospect: stringSchema
    .nullable()
    .when(
      'isProspect',
      (isProspect, field) => (isProspect ? field.required() : field)
    ),
  provenance: optionSchema
    .nullable()
    .when(
      'isProspect',
      (isProspect, field) => (isProspect ? field.required() : field)
    ),
  company: optionSchema
    .nullable()
    .when(
      'isProspect',
      (isProspect, field) => (isProspect ? field : field.required())
    ),
});

export const LoginFormSchema = Yup.object({
  login: Yup.string(),
  password: Yup.string(),
});

export const GenderFormSchema = Yup.object({
  label: Yup.string()
    .max(5, 'Too Long!')
    .required('Required!'),
});

export const OfferFormSchema = Yup.object({
  admin: optionSchema.nullable().required('Required!'),
  status: optionSchema.nullable().required('Required!'),
  label: Yup.string()
    .max(100, 'Too Long!')
    .required('Required!'),
  company: optionSchema.nullable().required('Required!'),
  offerType: optionSchema.nullable().required('Required!'),
  fixedPrice: Yup.number()
    .nullable()
    .required('Required!')
    .typeError('Should be a number!')
    .min(0, 'Should be a positif number!'),
  contractTypes: Yup.array()
    .of(optionSchema)
    .nullable()
    .required('Required!'),
  functions: Yup.array()
    .of(optionSchema)
    .nullable()
    .required('Required!'),
  studyLevels: Yup.array()
    .of(optionSchema)
    .nullable()
    .required('Required!'),
  durationMin: Yup.number()
    .nullable()
    .when(['contractTypes', 'offerType'], {
      is: (contractTypes, offerType) =>
        (contractTypes.length > 1 ||
          (contractTypes.length === 1 && contractTypes[0].value !== cdiId)) &&
        (offerType !== null && offerType.value !== rpoPlanId),
      then: fieldSchema =>
        fieldSchema
          .required('Required!')
          .typeError('Should be a number!')
          .min(0, 'Should be a positif number!'),
    }),
  durationMax: Yup.number()
    .nullable()
    .when(['contractTypes', 'offerType'], {
      is: (contractTypes, offerType) =>
        (contractTypes.length > 1 ||
          (contractTypes.length === 1 && contractTypes[0].value !== cdiId)) &&
        (offerType !== null && offerType.value !== rpoPlanId),
      then: fieldSchema =>
        fieldSchema
          .required('Required!')
          .typeError('Should be a number!')
          .min(0, 'Should be a positif number!'),
    }),
  startDateMin: Yup.string()
    .nullable()
    .required('Required!'),
  contactName: Yup.string()
    .nullable()
    .required('Required!'),
  startDateMax: Yup.string()
    .nullable()
    .required('Required!'),
  salary: Yup.string()
    .nullable()
    .when('salaryType', {
      is: salaryType => salaryType !== accordingProfileSalaryId,
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
  cities: Yup.array()
    .of(optionSchema)
    .nullable()
    .required('Required!'),
  redirectUrl: Yup.string()
    .nullable()
    .url(),
});

export const InvoiceFormSchema = Yup.object({
  type: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .nullable()
    .required('Required!'),
  company: Yup.object()
    .shape({
      value: Yup.number(),
      label: Yup.string(),
    })
    .nullable()
    .required('Required!'),
  daysToPay: Yup.number()
    .nullable()
    .required('Required!')
    .typeError('Should be a number!')
    .min(0, 'Should be a positif number!'),
  comment: Yup.string().nullable(),
});

export const CreditNoteFormSchema = Yup.object({
  amount: Yup.number()
    .nullable()
    .required('Required!')
    .typeError('Should be a number!')
    .min(0, 'Should be a positif number!'),
});

export const LetudiantFormSchema = Yup.object().shape({
  offerType: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      })
    )
    .nullable()
    .required('Required!'),
  countries: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      })
    )
    .nullable()
    .required('Required!'),
  company: Yup.string()
    .nullable()
    .required('Required!')
    .max(200),
  labelContact: Yup.string()
    .nullable()
    .required('Required!')
    .max(100),
  position: Yup.string()
    .nullable()
    .required('Required!')
    .max(100),
  studentJobSalary: Yup.string()
    .nullable()
    .when('offerType', {
      is: items => items.some(item => item.value === studentJobTypeId),
      then: fieldSchema => fieldSchema.required('Required!').max(200),
    }),
  workSalary: Yup.string()
    .nullable()
    .when('offerType', {
      is: items => items.some(item => item.value === workTypeId),
      then: fieldSchema => fieldSchema.required('Required!').max(200),
    }),
  workAndStudySalary: Yup.string()
    .nullable()
    .when('offerType', {
      is: items => items.some(item => item.value === workAndStudyTypeId),
      then: fieldSchema => fieldSchema.required('Required!').max(200),
    }),
  internshipSalary: Yup.string()
    .nullable()
    .when('offerType', {
      is: items => items.some(item => item.value === internshipTypeId),
      then: fieldSchema => fieldSchema.required('Required!').max(200),
    }),
  internshipDuration: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      })
    )
    .nullable()
    .when('offerType', {
      is: items => items.some(item => item.value === internshipTypeId),
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
  workAndStudyDuration: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      })
    )
    .nullable()
    .when('offerType', {
      is: items => items.some(item => item.value === workAndStudyTypeId),
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
  internshipDomains: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      })
    )
    .nullable()
    .when('offerType', {
      is: items => items.some(item => item.value === internshipTypeId),
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
  workFunctions: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      })
    )
    .nullable()
    .when('offerType', {
      is: items => items.some(item => item.value === workTypeId),
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
  studentJobDomains: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      })
    )
    .nullable()
    .when('offerType', {
      is: items => items.some(item => item.value === studentJobTypeId),
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
  workAndStudyDomains: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      })
    )
    .nullable()
    .when('offerType', {
      is: items => items.some(item => item.value === workAndStudyTypeId),
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
  workDomains: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      })
    )
    .nullable()
    .when('offerType', {
      is: items => items.some(item => item.value === workTypeId),
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
  studentJobContracts: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      })
    )
    .nullable()
    .when('offerType', {
      is: items => items.some(item => item.value === studentJobTypeId),
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
  workContracts: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      })
    )
    .nullable()
    .when('offerType', {
      is: items => items.some(item => item.value === workTypeId),
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
  internshipLevels: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      })
    )
    .nullable()
    .when('offerType', {
      is: items => items.some(item => item.value === internshipTypeId),
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
  workLevels: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      })
    )
    .nullable()
    .when('offerType', {
      is: items => items.some(item => item.value === workTypeId),
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
  workAndStudyLevels: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      })
    )
    .nullable()
    .when('offerType', {
      is: items => items.some(item => item.value === workAndStudyTypeId),
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
  workExperiences: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      })
    )
    .nullable()
    .when('offerType', {
      is: items => items.some(item => item.value === workTypeId),
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
  mission: Yup.string()
    .nullable()
    .required('Required!')
    .max(3000),
  offerUrl: Yup.string()
    .nullable()
    .required('Required!')
    .max(1000),
  profile: Yup.string()
    .nullable()
    .required('Required!')
    .max(1000),
  publicationEndDate: Yup.string()
    .nullable()
    .required('Required!'),
});

export const PafFormSchema = Yup.object().shape({
  admin: Yup.object()
    .shape({
      value: Yup.number(),
      label: Yup.string(),
    })
    .nullable()
    .required('Required!'),
  company: Yup.object()
    .shape({
      value: Yup.number(),
      label: Yup.string(),
    })
    .nullable()
    .required('Required!'),
  offer: Yup.object()
    .shape({
      value: Yup.number(),
      label: Yup.string(),
    })
    .nullable()
    .required('Required!'),
  contractType: Yup.object()
    .shape({
      value: Yup.number(),
      label: Yup.string(),
    })
    .nullable()
    .required('Required!'),
  plan: Yup.object()
    .shape({
      value: Yup.number(),
      label: Yup.string(),
    })
    .nullable()
    .required('Required!'),
  recruitmentStartDate: Yup.string()
    .nullable()
    .required('Required!'),
  fixedCost: Yup.number()
    .nullable()
    .required('Required!')
    .typeError('Should be a number!')
    .min(0, 'Should be a positif number!'),
  variableCost: Yup.number()
    .nullable()
    .required('Required!')
    .typeError('Should be a number!')
    .min(0, 'Should be a positif number!'),
  variableCostHiring: Yup.number()
    .nullable()
    .required('Required!')
    .typeError('Should be a number!')
    .min(0, 'Should be a positif number!'),
  contractDuration: Yup.number()
    .nullable()
    .required('Required!')
    .typeError('Should be a number!')
    .min(0, 'Should be a positif number!'),
  vacantPositions: Yup.number()
    .required('Required!')
    .typeError('Should be a number!')
    .min(1, 'Should be a positif number!'),
});

export const CompanyFormSchema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .required('Required!'),
  street: Yup.string()
    .nullable()
    .required('Required!'),
  city: Yup.string()
    .nullable()
    .required('Required!'),
  postCode: Yup.string()
    .nullable()
    .required('Required!'),
  country: Yup.string()
    .nullable()
    .required('Required!'),
  email: Yup.string()
    .nullable()
    .required('Required!'),
  phoneNumber: Yup.string()
    .nullable()
    .required('Required!'),
  employeesCount: Yup.string()
    .nullable()
    .required('Required!'),
  businessSector: optionSchema.nullable().required('Required!'),
  secondaryBusinessSector: optionSchema.nullable(),
  size: optionSchema.nullable().required('Required!'),
  provenance: optionSchema.nullable().required('Required!'),
  admin: optionSchema.nullable().required('Required!'),
  billingName: Yup.string()
    .nullable()
    .when('differentBillingAddress', {
      is: true,
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
  billingStreet: Yup.string()
    .nullable()
    .when('differentBillingAddress', {
      is: true,
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
  billingCity: Yup.string()
    .nullable()
    .when('differentBillingAddress', {
      is: true,
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
  billingPostCode: Yup.string()
    .nullable()
    .when('differentBillingAddress', {
      is: true,
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
  billingCountry: Yup.string()
    .nullable()
    .when('differentBillingAddress', {
      is: true,
      then: fieldSchema => fieldSchema.required('Required!'),
    }),
});

export const RecruiterFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .nullable()
    .required('Required!'),
  lastName: Yup.string()
    .nullable()
    .required('Required!'),
  mail: Yup.string()
    .nullable()
    .required('Required!'),
  position: Yup.string()
    .nullable()
    .required('Required!'),
  password: Yup.string()
    .nullable()
    .required('Required!'),
  role: Yup.object()
    .shape({
      value: Yup.number(),
      label: Yup.string(),
    })
    .nullable()
    .required('Required!'),
});

export const AdminFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .nullable()
    .required('Required!'),
  lastName: Yup.string()
    .nullable()
    .required('Required!'),
  email: Yup.string()
    .nullable()
    .required('Required!'),
  phone: Yup.string()
    .nullable()
    .required('Required!'),
  position: Yup.string()
    .nullable()
    .required('Required!'),
  login: Yup.string()
    .nullable()
    .required('Required!'),
  photo: Yup.string().nullable(),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    )
    .nullable(),
});

export const CandidateFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .nullable()
    .required(),
  lastName: Yup.string()
    .nullable()
    .required(),
  school: optionSchema.nullable().required(),
  studyLevel: optionSchema.nullable().required(),
  provenance: Yup.string()
    .nullable()
    .required(),
  phoneNumber: Yup.string().nullable(),
});

export const CandidateCreateFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .nullable()
    .required(),
  lastName: Yup.string()
    .nullable()
    .required(),
  email: Yup.string()
    .nullable()
    .required(),
  resume: Yup.string()
    .nullable()
    .required(),
  phone: Yup.string().nullable(),
  school: optionSchema.nullable().required(),
  studyLevel: optionSchema.nullable().required(),
  contractType: optionSchema.nullable().required(),
  startDate: Yup.string()
    .nullable()
    .required(),
  duration: optionSchema.nullable(),
  provenance: Yup.string()
    .nullable()
    .required(),
});
