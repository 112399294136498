import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { useQueryState } from 'utils/hooks/queryState';
import DatePicker from 'components/core/input/DatePicker';

const DateFilter = ({ name, label, defaultValue, onChange }) => {
  const [value, setValue] = useQueryState(defaultValue || null, name);

  const handleChange = ({ value }) => {
    setValue(value);
    onChange({ name: name, value: value });
  };

  return <DatePicker label={label} value={value} onChange={handleChange} />;
};

DateFilter.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: momentPropTypes.momentObj,
  onChange: PropTypes.func,
};
DateFilter.defaultProps = {
  onChange: () => {},
};

export default DateFilter;
