import { ACTIONS_TYPE } from './actions';

export const initialState = {
  loading: false,
  items: [],
  current: null,
};

export function recruitersReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPE.GET_RECRUITERS_BEGIN:
    case ACTIONS_TYPE.GET_ONE_RECRUITER_BEGIN:
    case ACTIONS_TYPE.UPDATE_RECRUITER_BEGIN:
    case ACTIONS_TYPE.CREATE_RECRUITER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_RECRUITERS_FAILURE:
    case ACTIONS_TYPE.GET_ONE_RECRUITER_FAILURE:
    case ACTIONS_TYPE.UPDATE_RECRUITER_FAILURE:
    case ACTIONS_TYPE.UPDATE_RECRUITER_SUCCESS:
    case ACTIONS_TYPE.CREATE_RECRUITER_FAILURE:
    case ACTIONS_TYPE.CREATE_RECRUITER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_RECRUITERS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.result,
      };
    case ACTIONS_TYPE.GET_ONE_RECRUITER_SUCCESS:
      return {
        ...state,
        loading: false,
        current: action.result,
      };
    case ACTIONS_TYPE.CLEAN_RECRUITERS:
      return {
        ...state,
        items: [],
      };
    case ACTIONS_TYPE.CLEAN_ONE_RECRUITER:
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
}
