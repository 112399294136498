import { logout } from './actions';
import { ACTIONS_TYPE } from 'redux/auth/actions';

export const checkAuthMiddleware = ({ dispatch }) => next => action => {
  if (!action) {
    return;
  }

  // Disconnect if cannot refresh token
  if (action.type === ACTIONS_TYPE.PROCESS_REFRESH_TOKEN_FAILURE) {
    dispatch(logout());
  }

  next(action);
};
