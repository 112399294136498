import { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from 'utils/api/headers';
import { apiUrl } from 'utils/constants';
import { useQuery, useQueryState } from 'utils/hooks/queryState';
import AutocompleteAsync from 'components/core/input/AutocompleteAsync';

AutocompleteAsyncFilter.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.array,
  service: PropTypes.func,
};
AutocompleteAsyncFilter.defaultProps = {
  service: () => {},
};

function AutocompleteAsyncFilter({
  name,
  label,
  defaultValue,
  service,
  onChange,
  multi,
}) {
  const qs = useQuery();
  const [query, setQuery] = useQueryState(
    multi ? qs.getAll(name) : qs.get(name),
    name
  );
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);

  const didFetchDefaultOptions = useRef(false);
  const didSetAutocomplete = useRef(false);

  useEffect(
    () => {
      fetchDefaultOptions();
      setDefaultOptions();
    },
    [query, defaultValue, options]
  );

  const fetchDefaultOptions = () => {
    if (!didFetchDefaultOptions.current) {
      Promise.all(
        getRequests(service, query).map(request =>
          fetch({
            url: request.url,
            params: request.params,
          })
        )
      ).then(function(responses) {
        const defaultOptions = [];
        responses.forEach(response => defaultOptions.push(...response.data));
        setOptions(defaultOptions);
      });

      didFetchDefaultOptions.current = true;
    }
  };

  const setDefaultOptions = () => {
    if (!didSetAutocomplete.current && options.length > 0) {
      if (query) {
        let defaultOptions = null;
        if (multi) {
          defaultOptions = options.filter(option =>
            query.includes(option.value.toString())
          );
        } else {
          defaultOptions = options.find(
            option => query === option.value.toString()
          );
        }
        setValue(defaultOptions);
      }
      didSetAutocomplete.current = true;
    }
  };

  const handleChange = ({ value }) => {
    let queryValues = null;
    if (Array.isArray(value)) {
      queryValues = value ? value.map(val => val.value) : null;
    } else {
      queryValues = value ? value.value : null;
    }
    setQuery(queryValues);
    setValue(options);
    onChange({ value: queryValues, name: name });
  };

  const autocompleteService = search => service({ search: search });

  return (
    <div style={{ width: '100%' }}>
      <AutocompleteAsync
        multi={multi}
        label={label}
        value={value || []}
        onChange={handleChange}
        service={autocompleteService}
        defaultOptions={options}
      />
    </div>
  );
}

const getRequests = (service, query) =>
  (Array.isArray(query) ? query : [query]).map(id => {
    const { url, params } = service({ id: id });
    return { url: url, params: params };
  });

const fetch = ({ url, params }) =>
  axios({
    method: 'GET',
    url: `${apiUrl}` + url,
    params: params,
    headers: getHeaders(),
  });

export default memo(AutocompleteAsyncFilter);
