import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleFormData } from 'pages/Offers/Form/helper';
import { updateOffer } from 'redux/offers/actions';

import PageHeader from 'components/layout/PageHeader';
import Textarea from 'components/core/input/Textarea';
import { Grid } from 'components/core/containers';
import OfferHeaderItems from './OfferHeaderItems';
import OfferSubtitles from './OfferSubtitles';
import OfferKpi from './OfferKpi';
import OfferActions from './OfferActions';
import OfferContacts from './OfferContacts';
import { useDebounce } from 'utils/hooks/debounce';
import { useRef } from 'react';

function OfferPanel() {
  const dispatch = useDispatch();
  const offer = useSelector(state => state.offers.current);

  const [state, setState] = useState({
    ...offer,
    customerBrief: offer.customerBrief,
    comment: offer.comment,
    recruitmentComment: offer.recruitmentComment,
    externalWeeklyApplicationCount: offer.kpi.externalWeeklyApplicationCount,
    externalWeeklyApproachCount: offer.kpi.externalWeeklyApproachCount,
    externalWeeklyAcceptedApproachCount:
      offer.kpi.externalWeeklyAcceptedApproachCount,
    externalWeeklyDeclinedApproachCount:
      offer.kpi.externalWeeklyDeclinedApproachCount,
    externalWeeklyCallCount: offer.kpi.externalWeeklyCallCount,
  });

  const handleChange = ({ name, value }) => {
    let nextState = { ...state };
    switch (name) {
      case 'internal-comment':
        nextState.comment = value;
        break;
      case 'customer-brief':
        nextState.customerBrief = value;
        break;
      case 'recruitment-comment':
        nextState.recruitmentComment = value;
        break;
      case 'external-applications':
        nextState.externalWeeklyApplicationCount = value;
        break;
      case 'external-approaches':
        nextState.externalWeeklyApproachCount = value;
        break;
      case 'external-accepted-approaches':
        nextState.externalWeeklyAcceptedApproachCount = value;
        break;
      case 'external-declined-approaches':
        nextState.externalWeeklyDeclinedApproachCount = value;
        break;
      case 'external-call':
        nextState.externalWeeklyCallCount = value;
        break;
      default:
        throw new Error(`invalid action name: ${name}`);
    }
    setState(nextState);
  };

  const debouncedState = useDebounce(state, 700);
  const isMounted = useRef(false);
  useEffect(
    () => {
      if (isMounted.current) {
        setState(prev => ({ ...prev, comment: offer.comment }));
        // Prevent to dispatch updateOffer when comment has been changed because of side effect
        isMounted.current = false;
      }
    },
    [offer.comment]
  );

  useEffect(
    () => {
      if (isMounted.current) {
        dispatch(updateOffer(handleFormData(debouncedState)));
      } else {
        isMounted.current = true;
      }
    },
    [dispatch, debouncedState]
  );

  return (
    <PageHeader
      title={`${offer.label} - ${offer.company.label}`}
      subtitles={<OfferSubtitles offer={offer} />}
      bottomItems={<OfferHeaderItems offer={offer} />}
      open={false}
      lazy
    >
      <div style={{ marginTop: -15 }}>
        <Grid container>
          <Grid item md={9} sm={12}>
            <OfferKpi
              offer={offer}
              externalWeeklyApplication={state.externalWeeklyApplicationCount}
              externalWeeklyApproach={state.externalWeeklyApproachCount}
              externalWeeklyAcceptedApproach={
                state.externalWeeklyAcceptedApproachCount
              }
              externalWeeklyDeclinedApproach={
                state.externalWeeklyDeclinedApproachCount
              }
              externalWeeklyCall={state.externalWeeklyCallCount}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3} sm={12}>
            <Textarea
              label="Brief Client"
              name="customer-brief"
              rows={27}
              value={state.customerBrief}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={9} sm={12}>
            <OfferActions offer={offer} />
          </Grid>
          <Grid item md={3} sm={12}>
            <OfferContacts contacts={offer.contacts} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid md={6} sm={12}>
            <Textarea
              label="Commentaire AM"
              name="internal-comment"
              rows={15}
              value={state.comment}
              onChange={handleChange}
              displayLastLinesOnInit
            />
          </Grid>
          <Grid md={6} sm={12}>
            <Textarea
              label="Commentaire Recruteur"
              name="recruitment-comment"
              rows={15}
              value={state.recruitmentComment}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </div>
    </PageHeader>
  );
}

export default OfferPanel;
