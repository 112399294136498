import PropTypes from 'prop-types';
import Tooltip from 'components/core/Tooltip';
import { Checkbox } from 'components/core/input';
import { OrderTh, Th, SORT_ORDER } from './Th';
import { useQueryState } from 'utils/hooks';

Header.defaultProps = {
  columns: [],
  hasActions: false,
  selectable: false,
  onChange: () => {},
};

Header.propTypes = {
  columns: PropTypes.array.isRequired,
  hasActions: PropTypes.bool,
  selectable: PropTypes.bool,
  onSelectAllRows: PropTypes.func,
  onChange: PropTypes.func,
};

function Header({
  columns,
  hasActions,
  selectable,
  onSelectAllRows,
  allRowsSelected,
  onChange,
}) {
  const [sortField, setSortField] = useQueryState(null, 'sortField');
  const [sortOrder, setSortOrder] = useQueryState(SORT_ORDER.NULL, 'sortOrder');

  const nextOrder = ({ name }) => {
    let nextField = sortField;
    let nextOrder = sortOrder;

    if (sortField === null || name !== sortField) {
      nextField = name;
      nextOrder = SORT_ORDER.ASC;
    } else {
      switch (sortOrder) {
        case SORT_ORDER.NULL:
          nextOrder = SORT_ORDER.ASC;
          break;
        case SORT_ORDER.ASC:
          nextOrder = SORT_ORDER.DESC;
          break;
        default:
          nextOrder = SORT_ORDER.NULL;
          nextField = null;
      }
    }

    setSortOrder(nextOrder);
    setSortField(nextField);
    onChange({ sortField: nextField, sortOrder: nextOrder });
  };

  return (
    <thead>
      <tr style={{ height: 40 }}>
        {hasActions && <Th>Actions</Th>}
        {columns.map(
          (column, index) =>
            column.orderLabel ? (
              <OrderTh
                name={column.orderLabel}
                sortOrder={
                  column.orderLabel === sortField ? sortOrder : SORT_ORDER.NULL
                }
                onClick={nextOrder}
              >
                {value(column)}
              </OrderTh>
            ) : (
              <Th key={column.header ?? index} hStyle={column.headerStyle}>
                {value(column)}
              </Th>
            )
        )}
        {selectable && (
          <Th>
            <Checkbox value={allRowsSelected} onChange={onSelectAllRows} />
          </Th>
        )}
      </tr>
    </thead>
  );
}

const value = column => {
  if (column.tooltip) {
    return (
      <Tooltip position="left" text={column.tooltip}>
        {column.header}
      </Tooltip>
    );
  } else {
    return <div>{column.header}</div>;
  }
};

export default Header;
