import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import ActionButton from 'components/ActionButton';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { switchOfferHeadline } from 'redux/offers/actions';

export default function OfferHeadlineButton({ row: { id, headline } }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const handle = () => {
    dispatch(switchOfferHeadline({ id }));
  };

  return (
    <ActionButton
      icon={headline ? <StarIcon /> : <StarOutlineIcon />}
      action={handle}
      label={t('offer.headline')}
    />
  );
}
