import { useRef, useEffect, memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from 'utils/hooks/debounce';
import Pagination from './Pagination';

const pageSizes = [20, 50, 100];

const PaginationWithState = ({ itemCount, onChange }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizes[0]);

  const handleChange = ({ target: { name, value } }) => {
    switch (name) {
      case 'page-size':
        const pageSize = parseInt(value);
        setPageSize(pageSize);
        break;
      case 'prev':
        setPage(page - 1);
        break;
      case 'next':
        setPage(page + 1);
        break;
      default:
        throw new Error(`invalid action name: ${name}`);
    }
  };

  const componentDidMount = useRef(false);
  const debouncedPage = useDebounce(page, 300);
  useEffect(
    () => {
      if (componentDidMount.current) {
        onChange({ pageSize, page: debouncedPage });
      }
      componentDidMount.current = true;
    },
    // eslint-disable-next-line
    [pageSize, debouncedPage]
  );

  return (
    <Pagination
      page={page}
      pageSize={pageSize}
      itemCount={itemCount}
      pageSizeOptions={pageSizes}
      onChange={handleChange}
    />
  );
};

PaginationWithState.propTypes = {
  itemCount: PropTypes.number,
  onChange: PropTypes.func,
};
PaginationWithState.defaultProps = {
  itemCount: 0,
  onChange: () => {},
};

export default memo(PaginationWithState);
