import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';

import services from 'utils/api/Services/services';
import { cdiId } from 'utils/constants';
import { getSalaryValue, getSalaryType } from './helper';
import {
  Box,
  IconButton,
  Checkbox,
  Divider,
  FormControlLabel,
  withStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
  Autocomplete,
  AutocompleteAsync,
  RichTextEditor,
  TextField,
} from 'components/core/input';
import SubmitButtons from 'components/core/SubmitButtons';
import S from './styles';
import {
  useFecthStudyLevels,
  useFetchAdmins,
  useFetchContractTypes,
  useFetchFunctions,
  useFetchOffers,
  useFetchPlans,
  useFetchRecruiters,
  useFetchTeleworkings,
} from 'utils/hooks/formOptions';
import { useFetchCompany } from 'utils/hooks/company';
import { useFetchSample } from 'utils/hooks/offer';

const fetchCompanies = searchTerm =>
  services.options.getCompanies({
    search: searchTerm,
  });

const fetchTags = searchTerm =>
  services.options.getTags({
    search: searchTerm,
  });

const fetchCities = name =>
  services.options.getCities({
    name: name,
  });

function Form({
  values,
  errors,
  touched,
  handleSubmit,
  setFieldTouched,
  setFieldValue,
  setValues,
  isSubmitting,
  classes,
}) {
  const { t } = useTranslation();

  const [fetchAdmins, admins] = useFetchAdmins();
  const [fetchPlans, plans] = useFetchPlans();
  const [fetchContractTypes, contractTypes] = useFetchContractTypes();
  const [fetchFunctions, functions] = useFetchFunctions();
  const [fetchStudyLevels, studyLevels] = useFecthStudyLevels();
  const [fetchTeleworkings, teleworkings] = useFetchTeleworkings();
  const [fetchRecruiters, recruiters] = useFetchRecruiters();
  const [fetchCompany, company] = useFetchCompany();
  const [fetchOffers, offers] = useFetchOffers();
  const [fetchOfferSample, sample] = useFetchSample();

  useEffect(
    () => {
      fetchAdmins({ onlyActive: 1 });
      fetchPlans();
      fetchContractTypes();
      fetchFunctions();
      fetchStudyLevels();
      fetchTeleworkings();
    },
    [
      fetchAdmins,
      fetchPlans,
      fetchContractTypes,
      fetchFunctions,
      fetchStudyLevels,
      fetchTeleworkings,
    ]
  );

  const handleChange = (fieldName, value) => {
    setFieldValue(fieldName, value, true);
  };

  const handleChangeSalaryType = type => {
    const newSalaryType = values.salaryType === type ? null : type;
    handleChange('salaryType', newSalaryType);
    setFieldTouched('salary');
  };

  const durationDisabled = values.contractTypes?.[0]?.value === cdiId;

  const handleChangeOfferType = offerType => {
    handleChange('offerType', offerType);
    // Disable second variable for RPO
    if (offerType?.value === 5) {
      handleChange('isSecondVariable', false);
      handleChange('secondVariable', 0);
    }
  };

  const [variablePrices, setVariablePrices] = useState(
    values.variablePrices || []
  );
  const addVariablePrice = () => {
    const variablePrice = {
      id: null,
      price: 0,
    };
    setVariablePrices([...variablePrices, variablePrice]);
    handleChange('variablePrices', variablePrices);
  };

  const handleChangeVariablePrice = (index, value) => {
    setVariablePrices(
      variablePrices.map((variable, key) => {
        if (index === key) variable.price = value;
        return variable;
      })
    );
    handleChange('variablePrices', variablePrices);
  };

  const removeVariablePrice = index => {
    const newVariables = variablePrices.filter(
      (variable, key) => key !== index
    );
    setVariablePrices(newVariables);
    handleChange('variablePrices', newVariables);
  };

  const [sampleKey, setSampleKey] = useState(0);
  useEffect(
    () => {
      if (!values.sample || !sample) return;

      setValues({
        ...sample,
        id: null,
        salary: getSalaryValue(sample.salary),
        salaryType: getSalaryType(sample.salary),
      });

      const variablePrices = sample.variablePrices ?? [];
      setVariablePrices(
        variablePrices.map(price => {
          return {
            ...price,
            invoiced: false,
          };
        })
      );

      // Refresh RichTextEditor components
      setSampleKey(sampleKey + 1);
    },
    // eslint-disable-next-line
    [sample]
  );

  const handleCompanyChange = value => {
    if (value) {
      const companyId = value.value;
      fetchOffers({ companyId });
      fetchRecruiters({ company: companyId });
      fetchCompany(companyId);
    }
    setValues({
      ...values,
      recruiter: null,
      recruitmentProcess: null,
      sample: null,
      company: value,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box className={classes.formContainer}>
        {/* Upper Part */}
        <Box className={classes.row}>
          {/* Column 1 */}
          <Box className={classes.column}>
            <Box className={classes.row}>
              {/* Admin */}
              <Autocomplete
                disableClearable
                defaultValue={values.admin}
                value={values.admin}
                options={admins}
                className={classes.field}
                label={t('offer.fields.admin')}
                error={errors.admin}
                touched={touched.admin}
                onChange={({ value }) => {
                  handleChange('admin', value);
                  if (value && value.email) {
                    handleChange('emails', [
                      ...values.emails,
                      { value: value.email, label: value.email },
                    ]);
                  }
                }}
              />
            </Box>
            <Box className={classes.row}>
              {/* Secondary Admins */}
              <Autocomplete
                multi
                defaultValue={values.secondaryAdmins}
                value={values.secondaryAdmins}
                options={admins}
                className={classes.largeField}
                label={t('offer.fields.secondaryAdmins')}
                onChange={({ value }) => handleChange('secondaryAdmins', value)}
              />
            </Box>
            <Box className={classes.row}>
              {/* Label */}
              <TextField
                label={t('offer.fields.label')}
                touched={touched.label}
                error={errors.label}
                className={classes.largeField}
                handleChange={({ value }) => handleChange('label', value)}
                value={values.label}
                endAdornment={t('offer.fields.gender')}
              />
            </Box>
            <Box className={classes.row}>
              {/* Company */}
              <AutocompleteAsync
                disableClearable
                defaultValues={[values.company]}
                service={fetchCompanies}
                className={classes.field}
                label={t('offer.fields.company')}
                error={errors.company}
                touched={touched.company}
                onChange={({ value }) => handleCompanyChange(value)}
              />
              {/* Sample */}
              <Autocomplete
                disabled={values.id}
                options={values.company ? offers : []}
                value={values.sample}
                className={classes.field}
                label={t('offer.fields.sample')}
                error={errors.sample}
                touched={touched.sample}
                onChange={({ value }) => {
                  if (value != null) {
                    fetchOfferSample(value.value);
                  }
                  handleChange('sample', value);
                }}
              />
            </Box>
            <Box className={classes.row}>
              {/* Invoicing Company */}
              <TextField
                label={t('offer.fields.invoicingCompany')}
                touched={touched.invoicingCompany}
                error={errors.invoicingCompany}
                className={classes.largeField}
                handleChange={({ value }) =>
                  handleChange('invoicingCompany', value)
                }
                value={values.invoicingCompany}
              />
            </Box>
            <Box className={classes.row}>
              {/* Recruiter */}
              <Autocomplete
                options={values.company ? recruiters : []}
                value={values.recruiter}
                className={classes.field}
                label={t('offer.fields.recruiter')}
                error={errors.recruiter}
                touched={touched.recruiter}
                onChange={({ value }) => {
                  handleChange('recruiter', value);
                  if (value && value.email) {
                    handleChange('emails', [
                      ...values.emails,
                      { value: value.email, label: value.email },
                    ]);
                  }
                }}
              />
            </Box>
            {/* Contact Name */}
            <Box className={classes.row}>
              <TextField
                label={t('offer.fields.contactName')}
                touched={touched.contactName}
                error={errors.contactName}
                className={classes.field}
                handleChange={({ value }) => handleChange('contactName', value)}
                value={values.contactName}
              />
              {/* Familiarity */}
              <FormControlLabel
                label={t('offer.fields.familiarity')}
                control={
                  <Checkbox
                    id="familiarity"
                    checked={values.familiarity}
                    onChange={() =>
                      handleChange('familiarity', !values.familiarity)
                    }
                    color="primary"
                  />
                }
                className={classes.checkbox}
                labelPlacement="end"
              />
            </Box>
            {/* Emails */}
            <Box className={classes.row}>
              <Autocomplete
                value={values.emails}
                multi
                creatable
                className={classes.largeField}
                label={t('offer.fields.emails')}
                error={errors.emails}
                touched={touched.emails}
                onChange={({ value }) => {
                  const newValues = value.map(item => ({
                    value: item.inputValue
                      ? item.inputValue
                      : item.value
                        ? item.value
                        : item,
                    label: item.inputValue
                      ? item.inputValue
                      : item.label
                        ? item.label
                        : item,
                  }));
                  handleChange('emails', newValues);
                }}
              />
            </Box>
            <Box className={classes.row}>
              {/* Offer Type */}
              <Autocomplete
                disableClearable
                value={values.offerType}
                options={plans}
                className={classes.field}
                label={t('offer.fields.offerType')}
                error={errors.offerType}
                touched={touched.offerType}
                onChange={({ value }) => handleChangeOfferType(value)}
              />
            </Box>
            <Box className={classes.row}>
              {/* Redirect URL */}
              <TextField
                label={t('offer.fields.redirectUrl')}
                touched={touched.redirectUrl}
                error={errors.redirectUrl}
                className={classes.field}
                handleChange={({ value }) => handleChange('redirectUrl', value)}
                value={values.redirectUrl}
              />
            </Box>
          </Box>

          <Box>
            <Divider className={classes.divider} orientation="vertical" />
          </Box>
          {/* Column 2 */}
          <Box className={classes.column}>
            <Box className={classes.row}>
              {/* Contract Type */}
              <Autocomplete
                disableClearable
                value={values.contractTypes}
                multi
                options={contractTypes}
                className={classes.largeField}
                label={t('offer.fields.contractTypes')}
                error={errors.contractTypes}
                touched={touched.contractTypes}
                onChange={({ value }) => handleChange('contractTypes', value)}
              />
            </Box>
            {/*  Durations */}
            <Box className={classes.row}>
              {/*  Duration Min */}
              <TextField
                label={t('offer.fields.durationMin')}
                touched={touched.durationMin}
                error={errors.durationMin}
                className={classes.field}
                handleChange={({ value }) => {
                  if (!values.durationMax) {
                    handleChange('durationMax', value);
                  }
                  handleChange('durationMin', value);
                }}
                endAdornment={t('offer.fields.months')}
                value={values.durationMin}
                disabled={durationDisabled}
                type="number"
              />
              {/*  Duration Max */}
              <TextField
                label={t('offer.fields.durationMax')}
                touched={touched.durationMax}
                error={errors.durationMax}
                className={classes.field}
                handleChange={({ value }) => handleChange('durationMax', value)}
                endAdornment={t('offer.fields.months')}
                value={values.durationMax}
                disabled={durationDisabled}
                type="number"
              />
            </Box>
            {/*  Start Period */}
            <Box className={classes.row}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.field}
                  disableToolbar
                  autoComplete="off"
                  variant="inline"
                  views={['year', 'month']}
                  error={touched.startDateMin && Boolean(errors.startDateMin)}
                  helperText={touched.startDateMin ? errors.startDateMin : ''}
                  id="date-picker-inline"
                  label={t('offer.fields.startDateMin')}
                  value={values.startDateMin}
                  onChange={value => {
                    handleChange('startDateMax', value);
                    handleChange('startDateMin', value);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardDatePicker
                  className={classes.field}
                  disableToolbar
                  autoComplete="off"
                  variant="inline"
                  views={['year', 'month']}
                  error={touched.startDateMax && Boolean(errors.startDateMax)}
                  helperText={touched.startDateMax ? errors.startDateMax : ''}
                  id="date-picker-inline"
                  label={t('offer.fields.startDateMax')}
                  value={values.startDateMax}
                  onChange={value => handleChange('startDateMax', value)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box className={classes.row}>
              {/* Cities */}
              <AutocompleteAsync
                disableClearable
                multi
                value={values.cities}
                service={fetchCities}
                className={classes.largeField}
                label="Villes"
                error={errors.cities}
                touched={touched.cities}
                onChange={({ value }) => handleChange('cities', value)}
              />
            </Box>
            <Box className={classes.row}>
              {/* Functions */}
              <Autocomplete
                multi
                disableCloseOnSelect
                value={values.functions}
                options={functions}
                className={classes.largeField}
                label={t('offer.fields.functions')}
                error={errors.functions}
                touched={touched.functions}
                onChange={({ value }) => handleChange('functions', value)}
              />
            </Box>
            <Box className={classes.row}>
              {/* Tags */}
              <AutocompleteAsync
                value={values.tags}
                multi
                creatable
                disableCloseOnSelect
                service={fetchTags}
                className={classes.largeField}
                label={t('offer.fields.tags')}
                error={errors.tags}
                touched={touched.tags}
                onChange={({ value }) => handleChange('tags', value)}
              />
            </Box>
            <Box className={classes.row}>
              {/* Salary */}
              <TextField
                label={t('offer.fields.salary')}
                disabled={values.salaryType === 2}
                touched={touched.salary}
                error={errors.salary}
                className={classes.field}
                handleChange={({ value }) => handleChange('salary', value)}
                value={values.salary}
              />
              {/* Is Salary Per Month */}
              <FormControlLabel
                label={t('offer.fields.isSalaryPerMonth')}
                control={
                  <Checkbox
                    id="isSalaryPerMonth"
                    checked={values.salaryType === 0}
                    onChange={() => handleChangeSalaryType(0)}
                    color="primary"
                  />
                }
                className={classes.checkbox}
                labelPlacement="end"
              />
              {/* Is Salary Per Year */}
              <FormControlLabel
                label={t('offer.fields.isSalaryPerYear')}
                control={
                  <Checkbox
                    id="isSalaryPerYear"
                    checked={values.salaryType === 1}
                    onChange={() => handleChangeSalaryType(1)}
                    color="primary"
                  />
                }
                className={classes.checkbox}
                labelPlacement="end"
              />
              {/* Is According Profile Salary */}
              <FormControlLabel
                label={t('offer.fields.isAccordingProfileSalary')}
                control={
                  <Checkbox
                    id="isAccordingProfileSalary"
                    checked={values.salaryType === 2}
                    onChange={() => handleChangeSalaryType(2)}
                    color="primary"
                  />
                }
                className={classes.checkbox}
                labelPlacement="end"
              />
            </Box>
            <Box className={classes.row}>
              {/* Study Level */}
              <Autocomplete
                disableClearable
                multi
                value={values.studyLevels}
                options={studyLevels}
                className={classes.largeField}
                label={t('offer.fields.studyLevels')}
                error={errors.studyLevels}
                touched={touched.studyLevels}
                onChange={({ value }) => handleChange('studyLevels', value)}
              />
            </Box>
            <Box className={classes.row}>
              <Autocomplete
                value={values.teleworking}
                options={teleworkings}
                className={classes.largeField}
                label="Télétravail"
                error={errors.teleworking}
                touched={touched.teleworking}
                onChange={({ value }) => handleChange('teleworking', value)}
              />
            </Box>
          </Box>
          <Box>
            <Divider className={classes.divider} orientation="vertical" />
          </Box>
          {/* Column 3 */}
          <Box className={classes.column}>
            <Box className={classes.row}>
              {/* Fixed Price */}
              <TextField
                label={t('offer.fields.fixedPrice')}
                touched={touched.fixedPrice}
                error={errors.fixedPrice}
                className={classes.field}
                handleChange={({ value }) => handleChange('fixedPrice', value)}
                value={values.fixedPrice}
                endAdornment={t('offer.fields.device')}
                type="number"
              />
            </Box>
            <Box className={classes.row}>
              <span>
                Ajouter un variable &nbsp;
                <IconButton onClick={() => addVariablePrice()}>
                  <AddIcon />
                </IconButton>
              </span>
            </Box>
            {variablePrices &&
              variablePrices.map((variablePrice, key) => (
                <Box className={classes.row} key={'box-' + key}>
                  <span className={classes.itemsWrapper}>
                    <TextField
                      key={'input-' + key}
                      label={`${t('offer.fields.variablePrice')} ${key + 1}`}
                      disabled={variablePrice.invoiced}
                      touched={touched.variablePrice}
                      error={errors.variablePrice}
                      handleChange={({ value }) =>
                        handleChangeVariablePrice(key, value)
                      }
                      value={variablePrice.price}
                      endAdornment={t('offer.fields.device')}
                      type="number"
                    />
                    <IconButton
                      className={classes.removeButton}
                      size="small"
                      disabled={variablePrice.invoiced}
                      onClick={() => removeVariablePrice(key)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </span>
                </Box>
              ))}

            <Box className={classes.row}>
              {/* Vacant positions */}
              <TextField
                label={t('offer.fields.vacantPositions')}
                touched={touched.vacantPositions}
                error={errors.vacantPositions}
                className={classes.field}
                handleChange={({ value }) =>
                  handleChange('vacantPositions', value)
                }
                value={values.vacantPositions}
                disabled={
                  typeof values.id !== 'undefined' && values.id !== null
                }
                type="number"
              />
            </Box>
          </Box>
        </Box>
        {/* Lower Part */}
        <Box className={classes.row}>
          <Box className={classes.richText} key={sampleKey}>
            <RichTextEditor
              label={t('offer.fields.offer')}
              htmlValue={values.description}
              handleChange={value => handleChange('description', value)}
            />
          </Box>
        </Box>
        <Box className={classes.row}>
          <TextField
            label={t('offer.fields.customerBrief')}
            multiline
            rowsMax="10"
            className={clsx(classes.fullField, classes.field)}
            handleChange={({ value }) => handleChange('customerBrief', value)}
            value={values.customerBrief ? values.customerBrief : ''}
          />
        </Box>
        <Box className={classes.row}>
          <Box className={classes.richText} key={sampleKey}>
            {/* Recruitment Process */}
            <RichTextEditor
              label="Processus de Recrutement"
              htmlValue={
                values.recruitmentProcess === null && company
                  ? company.recruitmentProcess
                  : values.recruitmentProcess
              }
              handleChange={value => handleChange('recruitmentProcess', value)}
            />
          </Box>
        </Box>
      </Box>
      <SubmitButtons isSubmitting={isSubmitting} />
    </form>
  );
}

export default withStyles(S)(Form);
