import moment from 'moment-timezone';
import { Box, withStyles } from '@material-ui/core';
import S from './styles';

function RowDetails(props) {
  const { row, classes } = props;

  const posts = row.posts ? row.posts : null;

  const sortedPosts = posts => (posts ? posts.sort((a, b) => b.id - a.id) : []);

  const Title = props => (
    <Box className={classes.title}>
      <>{props.children}</>
    </Box>
  );
  const postDates = (postDate, archiveDate) => (
    <>
      {moment(postDate).format('DD/MM/YYYY')}
      {' -> '} {archiveDate ? moment(archiveDate).format('DD/MM/YYYY') : '...'}
    </>
  );

  const splitUrls = urls => {
    if (urls === null) {
      return [];
    }
    return urls.split(', ');
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.block}>
        <Title>L'Étudiant</Title>
        {posts &&
          sortedPosts(posts.letudiant).map((post, k) => {
            return (
              <Box key={'expanded-' + k} className={classes.content}>
                <li>{postDates(post.postDate, post.archiveDate)}</li>
                {splitUrls(post.urls).map(url => (
                  <Box key={url}>
                    {url} <br />
                  </Box>
                ))}
              </Box>
            );
          })}
      </Box>
      <Box className={classes.block}>
        <Title>LinkedIn</Title>
        {posts &&
          posts.linkedin &&
          sortedPosts(posts.linkedin).map((post, k) => {
            return (
              <Box key={'expanded-' + k} className={classes.content}>
                <li>{postDates(post.postDate, post.archiveDate)}</li>
              </Box>
            );
          })}
      </Box>
      <Box className={classes.block}>
        <Title>JobTeaser</Title>
        {posts &&
          posts.jobteaser &&
          sortedPosts(posts.jobteaser).map((post, k) => {
            return (
              <Box key={'expanded-' + k} className={classes.content}>
                <li>{postDates(post.postDate, post.archiveDate)}</li>
              </Box>
            );
          })}
      </Box>
      <Box className={classes.block}>
        <Title>Apec</Title>
        {posts &&
          posts.apec &&
          sortedPosts(posts.apec).map((post, k) => {
            return (
              <Box key={'expanded-' + k} className={classes.content}>
                <li>{postDates(post.postDate, post.archiveDate)}</li>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}

export default withStyles(S)(RowDetails);
