import { useParamId } from 'utils/hooks';

import EditCompanyForm from './editCompanyForm';
import CreateCompanyForm from './createCompanyForm';

export default function CompanyForms() {
  const id = useParamId();

  return id ? <EditCompanyForm id={id} /> : <CreateCompanyForm />;
}
