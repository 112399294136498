import styled from 'styled-components';

function Grid({ container, md = 1, sm, children, spacing }) {
  return container ? (
    <GridContainer spacing={spacing}>{children}</GridContainer>
  ) : (
    <GridItem md={md} sm={sm}>
      {children}
    </GridItem>
  );
}

const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: ${({ spacing }) => (spacing >= 0 ? spacing : '20')}px;
  height: inherit;
  //background-color: #0c8e8f;
  //padding: 10px;
`;

const GridItem = styled.div`
  grid-column: span ${({ md }) => md};
  @media only screen and (max-width: 1000px) {
    grid-column: span ${({ sm, md }) => sm || md};
  }
  height: inherit;
  //background-color: rgba(188, 210, 211, 0.9);
  //padding: 20px;
`;

export default Grid;
