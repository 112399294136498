import { Grid, Paper } from 'components/core/containers';
import {
  Autocomplete,
  AutocompleteAsync,
  Checkbox,
  DatePicker,
  FileField,
  TextField,
} from 'components/core/input';
import SubmitButtons from 'components/core/SubmitButtons';
import Main from 'components/layout/Main';
import Title from 'components/layout/Title';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addCandidate } from 'redux/candidates/actions';
import services from 'utils/api/Services/services';
import { CandidateCreateFormSchema } from 'utils/formSchemas';
import {
  useFecthStudyLevels,
  useFetchContractTypes,
  useFetchFunctions,
  useFetchProvenances,
} from 'utils/hooks/formOptions';
import { ROUTES } from 'utils/routes';

export default function CandidateCreatePage() {
  const {
    studyLevels,
    provenances,
    contractTypes,
    functions,
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    isLoading,
    t,
  } = useCandidateForm();

  return (
    <Main pageTitle={t('candidate.title')} isLoading={isLoading}>
      <Title>{t('candidate.create')}</Title>
      <Paper>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={50}>
            <Grid md={6}>
              <Grid container>
                <Grid md={6}>
                  <TextField
                    name="firstName"
                    label={t('common.fields.firstName')}
                    value={values.firstName}
                    handleChange={handleChange}
                    touched={touched.firstName}
                    error={errors.firstName}
                  />
                </Grid>
                <Grid md={6}>
                  <TextField
                    name="lastName"
                    label={t('common.fields.lastName')}
                    value={values.lastName}
                    handleChange={handleChange}
                    touched={touched.lastName}
                    error={errors.lastName}
                  />
                </Grid>
                <Grid md={6}>
                  <TextField
                    name="email"
                    label={t('common.fields.email')}
                    value={values.email}
                    handleChange={handleChange}
                    touched={touched.email}
                    error={errors.email}
                  />
                </Grid>
                <Grid md={6}>
                  <TextField
                    name="phone"
                    label={t('common.fields.phone')}
                    value={values.phone}
                    handleChange={handleChange}
                    touched={touched.phone}
                    error={errors.phone}
                  />
                </Grid>
                <Grid md={12}>
                  <div
                    style={{
                      display: 'flex',
                      height: '100%',
                      alignItems: 'center',
                      marginTop: 5,
                    }}
                  >
                    <FileField
                      label={t('common.fields.resume')}
                      name="resume"
                      value={values.resume}
                      onChange={handleChange}
                      touched={touched.resume}
                      error={errors.resume}
                    />
                  </div>
                </Grid>
                <Grid md={6}>
                  <Checkbox
                    name="finalYear"
                    label={t('common.fields.finalYear')}
                    value={values.finalYear}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid md={6}>
                  <Checkbox
                    name="gapYear"
                    label={t('common.fields.gapYear')}
                    value={values.gapYear}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid md={6}>
              <Grid container>
                <Grid md={6}>
                  <AutocompleteAsync
                    name="school"
                    service={search =>
                      services.options.getSchools({
                        search: search,
                      })
                    }
                    label={t('common.fields.school')}
                    creatable
                    value={values.school}
                    onChange={handleChange}
                    touched={touched.school}
                    error={errors.school}
                  />
                </Grid>
                <Grid md={6}>
                  <Autocomplete
                    name="studyLevel"
                    label={t('common.fields.studyLevel')}
                    options={studyLevels}
                    value={values.studyLevel}
                    onChange={handleChange}
                    touched={touched.studyLevel}
                    error={errors.studyLevel}
                  />
                </Grid>
                <Grid md={6}>
                  <Autocomplete
                    name="contractType"
                    label={t('common.fields.contractType')}
                    options={contractTypes}
                    value={values.contractType}
                    onChange={handleChange}
                    touched={touched.contractType}
                    error={errors.contractType}
                  />
                </Grid>
                <Grid md={6}>
                  <DatePicker
                    name="startDate"
                    label={t('common.fields.startDate')}
                    value={values.startDate}
                    onChange={handleChange}
                    touched={touched.startDate}
                    error={errors.startDate}
                  />
                </Grid>
                <Grid md={6}>
                  <TextField
                    name="duration"
                    label={t('common.fields.duration')}
                    value={values.duration}
                    handleChange={handleChange}
                    touched={touched.duration}
                    error={errors.duration}
                    type="number"
                  />
                </Grid>
                <Grid md={6}>
                  <Autocomplete
                    name="provenance"
                    value={values.provenance}
                    label={t('common.fields.provenance')}
                    options={provenances?.filter(
                      provenance => provenance.label !== 'Autre'
                    )}
                    onChange={handleChange}
                    creatable
                    touched={touched.provenance}
                    error={errors.provenance}
                  />
                </Grid>
                <Grid md={12}>
                  <Autocomplete
                    name="functions"
                    multi
                    disableCloseOnSelect
                    value={values.functions}
                    options={functions}
                    label={t('common.fields.functions')}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid md={12}>
              <div
                style={{
                  marginTop: -30,
                }}
              >
                <SubmitButtons
                  isSubmitting={isSubmitting}
                  cancelRoute={ROUTES.CANDIDATES_LIST}
                />
              </div>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Main>
  );
}

function useCandidateForm() {
  const dispatch = useDispatch();

  const [fetchProvenances, provenances] = useFetchProvenances();
  const [fetchStudyLevels, studyLevels] = useFecthStudyLevels();
  const [fetchContractTypes, contractTypes] = useFetchContractTypes();
  const [fetchFunctions, functions] = useFetchFunctions();

  useEffect(
    () => {
      fetchProvenances('candidate');
      fetchStudyLevels();
      fetchContractTypes();
      fetchFunctions();
    },
    [fetchProvenances, fetchStudyLevels, fetchContractTypes, fetchFunctions]
  );

  const handleSubmit = values => {
    const data = {
      ...values,
      provenance: values.provenance.label,
      schoolId: values.school.value > 0 ? values.school.value : null,
      otherSchool: values.school.value <= 0 ? values.school.label : null,
      studyLevelId: values.studyLevel.value,
      contractTypeId: values.contractType.value,
      startDate: values.startDate,
      functionIds: values.functions?.map(f => f.value),
      finalYear: values.finalYear,
      gapYear: values.gapYear,
    };
    dispatch(addCandidate(data));
  };

  const handleChange = ({ value, name }) => {
    formik.setFieldValue(name, value, true);
    formik.setFieldTouched(name, true, false);
  };

  const formik = useFormik({
    initialValues: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      provenance: null,
      school: null,
      studyLevel: null,
      resume: null,
      contractType: null,
      startDate: null,
      duration: null,
      functions: [],
      finalYear: false,
      gapYear: false,
    },
    onSubmit: handleSubmit,
    validationSchema: CandidateCreateFormSchema,
  });

  const { t } = useTranslation();

  return {
    provenances,
    studyLevels,
    contractTypes,
    functions,
    values: formik.values,
    touched: formik.touched,
    errors: formik.errors,
    handleChange,
    handleSubmit: formik.handleSubmit,
    t,
    isSubmitting: formik.isSubmitting,
    isLoading: useSelector(state => state.candidates.loading),
  };
}
