import styled from 'styled-components';
import { Box, ColumnContainer } from 'components/core/containers';
import Label from 'components/core/Label';

function OfferContacts({ contacts }) {
  const orderedContacts = [...contacts].reverse();

  return (
    <ContactsContainer>
      <Label>Contacts</Label>
      <Box direction="column" scrollable>
        {orderedContacts.map(contact => (
          <Contact key={contact.email} contact={contact} />
        ))}
      </Box>
    </ContactsContainer>
  );
}

const Contact = ({ contact }) => (
  <ContactContainer>
    <Label isTitle>{contact.label}</Label>
    <span>
      <b>Téléphone :</b> {contact.phone || '-'}
    </span>
    <span>
      <b>Email :</b> {contact.email || '-'}
    </span>
  </ContactContainer>
);

const ContactsContainer = styled(ColumnContainer)`
  max-height: 110px;
`;
const ContactContainer = styled(ColumnContainer)`
  padding-bottom: 7px;
`;

export default OfferContacts;
