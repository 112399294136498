const jobboards = {
  posting: {
    getAll: params => ({
      url: `/postings`,
      method: 'get',
      params: params,
    }),
    post: params => ({
      url: `/postings`,
      method: 'post',
      data: params,
    }),
    archive: params => ({
      url: `/postings/archive`,
      method: 'put',
      data: params,
    }),
  },
  letudiant: {
    get: ({ offerId }) => ({
      url: `/jobboards/letudiant/v6/posts/${offerId}`,
      method: 'get',
    }),
    post: ({ offerId, data }) => ({
      url: `/jobboards/letudiant/v6/posts/${offerId}`,
      method: 'post',
      data: data,
    }),
    getCities: params => ({
      url: `/jobboards/letudiant/v6/geo/referentials/cities`,
      method: 'get',
      params: params,
    }),
    getCountries: params => ({
      url: `/jobboards/letudiant/v6/geo/referentials/countries`,
      method: 'get',
      params: params,
    }),
    getDomains: params => ({
      url: `/jobboards/letudiant/v6/referentials/domains`,
      method: 'get',
      params: params,
    }),
    getLevels: params => ({
      url: `/jobboards/letudiant/v6/referentials/levels`,
      method: 'get',
      params: params,
    }),
    getFunctions: () => ({
      url: `/jobboards/letudiant/v6/referentials/functions`,
      method: 'get',
    }),
    getDurations: params => ({
      url: `/jobboards/letudiant/v6/referentials/durations`,
      method: 'get',
      params: params,
    }),
    getWorkAndStudyContracts: () => ({
      url: `/jobboards/letudiant/v6/referentials/work-and-study-contracts`,
      method: 'get',
    }),
    getContracts: () => ({
      url: `/jobboards/letudiant/v6/referentials/contracts`,
      method: 'get',
    }),
    getOfferTypes: () => ({
      url: `/jobboards/letudiant/v6/referentials/types`,
      method: 'get',
    }),
    getExperiences: () => ({
      url: `/jobboards/letudiant/v6/referentials/experiences`,
      method: 'get',
    }),
  },
};

export default jobboards;
