// @flow
import valid from './valid';

// Login
export const confirmLoginSchema = {
  token: valid.string,
  refresh_token: valid.string,
};
export const confirmLoginValidator = valid.validate(confirmLoginSchema);

// Position
export const positionSchema = {
  id: valid.positiveInteger,
  label: valid.string,
};
export const positionValidator = valid.validate(positionSchema);
export const positionsArrayValidator = valid.arrayOfSchema(positionSchema);

// Company
export const companySchema = {
  id: valid.positiveInteger,
  name: valid.string,
};
export const companyValidator = valid.validate(companySchema);
export const companiesArrayValidator = valid.arrayOfSchema(companySchema);

// Candidate
export const candidateSchema = {
  id: valid.positiveInteger,
  firstName: valid.string,
  lastName: valid.string,
};
export const candidateValidator = valid.validate(candidateSchema);
export const candidatesArrayValidator = valid.arrayOfSchema(candidateSchema);

// OfferType
export const offerTypeSchema = {
  id: valid.positiveInteger,
  label: valid.string,
};
export const offerTypeValidator = valid.validate(offerTypeSchema);
export const offerTypesArrayValidator = valid.arrayOfSchema(offerTypeSchema);

// ContractType
export const contractTypeSchema = {
  id: valid.positiveInteger,
  label: valid.string,
};
export const contractTypeValidator = valid.validate(contractTypeSchema);
export const contractTypesArrayValidator = valid.arrayOfSchema(
  contractTypeSchema
);

// Admin
export const adminSchema = {
  id: valid.positiveInteger,
  firstName: valid.string,
  lastName: valid.string,
  email: valid.email,
  position: valid.string,
  gender: valid.string,
};
export const adminValidator = valid.validate(adminSchema);
export const adminsArrayValidator = valid.arrayOfSchema(adminSchema);

// Paf
export const pafSchema = {
  id: valid.positiveInteger,
  admin: adminValidator,
  company: companyValidator,
  position: valid.string,
  recruitmentStartDate: valid.utcDate,
  offerType: offerTypeValidator,
  contractType: contractTypeValidator,
  secondVariable: valid.optionalBoolean,
  fixedCost: valid.optionalPositiveInteger,
  variableCost: valid.optionalPositiveInteger,
  variableCostHiring: valid.optio,
  pafDate: valid.optionalUtcDate,
  candidateStartDate: valid.optionalUtcDate,
  candidate: candidateValidator,
  finalInternship: valid.optionalBoolean,
  invoiceDate: valid.optionalUtcDate,
  offerInDifficulty: valid.optionalBoolean,
  abandonedOffer: valid.optionalBoolean,
  completedOffer: valid.optionalBoolean,
  contractDuration: valid.optionalPositiveInteger,
  comments: valid.string,
  vacantPositions: valid.positiveInteger,
};
export const pafValidator = valid.validate(pafSchema);
export const pafsSchema = {
  totalItemCount: valid.positiveInteger,
  pafs: valid.arrayOfSchema(pafSchema),
};
export const pafsArrayValidator = valid.validate(pafsSchema);

// PafSummary
export const pafSummarySchema = {
  summary: valid.validate({
    curr_sales_pot: valid.number,
    curr_sales_pot_sv: valid.number,
    sales_pot: valid.number,
    sales_pot_sv: valid.number,
    count_paf: valid.number,
    count_invoiced: valid.number,
    count_not_invoiced: valid.number,
    count_total_not_invoiced: valid.number,
    count_starting: valid.number,
  }),
  paf: valid.validate({
    id: valid.number,
    type: valid.string,
    paf_date: valid.number,
    company: valid.validate({
      id: valid.number,
      name: valid.string,
    }),
    candidate: valid.validate({
      id: valid.number,
      name: valid.string,
    }),
  }),
};
export const pafSummaryValidator = valid.validate(pafSummarySchema);

// BusinessSector
export const businessSectorSchema = {
  id: valid.positiveInteger,
  label: valid.string,
};
export const businessSectorValidator = valid.validate(businessSectorSchema);
export const businessSectorsArrayValidator = valid.arrayOfSchema(
  businessSectorSchema
);

// CompanyType
export const companyTypeSchema = {
  id: valid.positiveInteger,
  label: valid.string,
};
export const companyTypeValidator = valid.validate(companyTypeSchema);
export const companyTypesArrayValidator = valid.arrayOfSchema(
  companyTypeSchema
);

// PafStatistics
export const pafStatisticsSchema = {
  averageTimeToPaf: valid.positiveInteger,
  averageTimeToStarting: valid.positiveInteger,
  abandonedCount: valid.positiveInteger,
  successCount: valid.positiveInteger,
  contractChangeCount: valid.positiveInteger,
  pafLineCount: valid.positiveInteger,
};
export const pafStatisticsValidator = valid.validate(pafStatisticsSchema);

// Offer
export const offerSchema = {
  id: valid.positiveInteger,
  label: valid.string,
};
export const offerValidator = valid.validate(offerSchema);
export const offersArrayValidator = valid.arrayOfSchema(offerSchema);

const letudiantAdvertSchema = {
  refPartenaire: valid.string,
  employeur: valid.string,
  statut: valid.number,
  libelle: valid.string,
  nombrePostes: valid.number,
  mission: valid.string,
  profil: valid.string,
  dateFinPublication: valid.string,
  pays: valid.arrayOf(valid.number),
  domaines: valid.arrayOf(valid.number),
  dureeMission: valid.arrayOf(valid.number),
  niveaux: valid.arrayOf(valid.number),
  libelleContact: valid.string,
  postulationDirecteInternet: valid.string,
};
export const letudiantAdvertValidator = valid.validate(letudiantAdvertSchema);

const formOptionsSchema = {
  label: valid.string,
  value: valid.string,
};
export const formOptionsValidator = valid.arrayOfSchema(formOptionsSchema);

const formGroupedOptionsSchema = {
  label: valid.string,
  options: valid.arrayOfSchema(formOptionsSchema),
};
export const formGroupedOptionsValidator = valid.arrayOfSchema(
  formGroupedOptionsSchema
);
