import type { Action } from 'utils/types';
import { ACTIONS_TYPE } from './actions';
import { ACTIONS_TYPE as AUTH_ACTIONS_TYPE } from 'redux/auth/actions';
import { ACTIONS_TYPE as APP_ACTIONS_TYPE } from 'redux/app/actions';

export type AlertStateType = {
  key: string,
  type: string,
  msg: string,
};

export const initialState: AlertStateType = {
  key: '',
  type: '',
  msg: '',
};

export function alertReducer(
  state: AlertStateType = initialState,
  action: Action
): AlertStateType {
  if (
    action.type.endsWith('_FAILURE') &&
    action.type !== AUTH_ACTIONS_TYPE.PROCESS_REFRESH_TOKEN_FAILURE
  ) {
    const newErrors = {
      key: `${new Date().getTime()}-${action.errors}`,
      type: 'error',
      msg: action.errors.message,
    };
    return newErrors;
  }
  if (action.type.endsWith('_SUCCESS') && !action.type.startsWith('GET_')) {
    const newSuccess = {
      key: `${new Date().getTime()}`,
      type: 'success',
      msg: 'Success!',
    };
    return newSuccess;
  }
  if (action.type === APP_ACTIONS_TYPE.GET_APP_VERSION_SUCCESS) {
    //if (action.result === localStorage.getItem('appVersion')) {
    return { ...state };
    /*}
    const newSuccess = {
      key: `${new Date().getTime()}`,
      type: 'warning',
      msg: 'New Update, please refresh the page to get the last NBO version!',
    };
    return newSuccess;
    */
  }

  if (action.type === ACTIONS_TYPE.REMOVE_ALERT) {
    return {
      key: '',
      type: '',
      msg: '',
    };
  }

  return state;
}
