const style = {
  root: {
    display: 'inline-block',
    padding: 20,
    width: 1300,
  },
  row: {
    display: 'flex',
    marginBottom: 20,
  },
  dateField: {
    width: 150,
    marginTop: 15,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 0,
  },
};

export default style;
