import PropTypes from 'prop-types';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import S from '../styles';
import { withStyles } from '@material-ui/core';
import BaseCell from 'components/Table/BaseCell';

SortableCell.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.object,
  accessor: PropTypes.string.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  createSortHandler: PropTypes.func.isRequired,
  style: PropTypes.object,
};

function SortableCell(props) {
  const {
    label,
    accessor,
    tooltip,
    order,
    orderBy,
    createSortHandler,
    style,
  } = props;

  const onClickHandler = property => event => {
    createSortHandler(event, property);
  };

  return (
    <BaseCell label={label} tooltip={tooltip} style={style}>
      <TableSortLabel
        active={orderBy === accessor}
        direction={orderBy === accessor ? order : 'desc'}
        onClick={onClickHandler(accessor)}
      >
        {label}
      </TableSortLabel>
    </BaseCell>
  );
}

export default withStyles(S)(SortableCell);
