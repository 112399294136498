import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Main from 'components/layout/Main';
import Summary from 'pages/Invoicing/Invoices/Summary';
import Detail from 'pages/Invoicing/Invoices/Detail';
import PageHeader from 'components/layout/PageHeader';

function Invoices() {
  const { t } = useTranslation();
  const isLoading = useSelector(state => state.invoices).loading;

  return (
    <Main pageTitle={t('menu.invoicingTitle')} isLoading={isLoading}>
      <PageHeader title={t('invoicing.invoices.title')}>
        <Summary />
      </PageHeader>
      <Detail />
    </Main>
  );
}

export default Invoices;
