import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { remindInvoice } from 'redux/invoices/actions';
import ActionButton from 'components/ActionButton';
import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import S from '../styles';

function RemindButton(props) {
  const { classes, row } = props;

  const dispatch = useDispatch();
  const [sent, setSent] = useState(true);
  const handleOnConfirm = () => {
    dispatch(
      remindInvoice({
        id: row.id,
        sent: sent ? 1 : 0,
      })
    );
  };

  const dialogContent = (
    <FormControlLabel
      label="Marquer comme relancé"
      control={
        <Checkbox
          id="sent"
          checked={sent}
          onChange={event => {
            setSent(event.target.checked ? true : false);
          }}
          color="primary"
        />
      }
      className={classes.checkbox}
      labelPlacement="end"
    />
  );

  return (
    <ActionButton
      icon={<ReplayIcon fontSize="small" />}
      dialog
      action={handleOnConfirm}
      label="Générer mail de relance"
      title="Génération du mail de relance"
      contentText="Indiquer ou non que le client a été relancé."
      confirmText="Envoyer"
      cancelText="Annuler"
      dialogContent={dialogContent}
      disabled={row.issuance === null || row.paymentDate !== null}
    />
  );
}

export default withStyles(S)(RemindButton);
