import { ACTIONS_TYPE } from './actions';
import type { Action } from 'utils/types';

export type AppStateType = {
  sidebar: {
    expanded: Boolean,
    forceExpanded: Boolean,
    items: {
      paf: Boolean,
      jobBoard: Boolean,
      statistics: Boolean,
      invoicing: Boolean,
    },
  },
  notifications: {
    posting: number,
  },
  version: string,
};

export const initialState: AppStateType = {
  sidebar: {
    expanded: false,
    forceExpanded: false,
    items: {
      paf: true,
      jobBoard: true,
      statistics: false,
      invoicing: true,
    },
  },
  notifications: {
    posting: 0,
  },
  version: process.env.REACT_APP_VERSION,
};

export const appReducer = (
  state: AppStateType = initialState,
  action: Action
) => {
  switch (action.type) {
    case ACTIONS_TYPE.GET_APP_VERSION_SUCCESS:
      return {
        ...state,
        version: action.result,
      };

    case ACTIONS_TYPE.SIDEBAR_MAINTAIN_EXPANDED:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          maintainExpanded: action.result.value,
        },
      };

    case ACTIONS_TYPE.SIDEBAR_UPDATE_STATE:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          expanded: action.result.value,
        },
      };

    case ACTIONS_TYPE.SIDEBAR_UPDATE_ITEM_STATE:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          items: {
            ...state.sidebar.items,
            [action.result.key]: action.result.value,
          },
        },
      };

    case ACTIONS_TYPE.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.result,
      };

    default:
      return state;
  }
};
