import Field from '../Field';
import Title from '../Title';

export default function CollaborationReview({ company }) {
  return (
    <>
      <Title label="common.fields.collaborationReview" />
      <Field
        label="proposalSent"
        value={company.kpi?.commercialProposalSentCount}
      />
      <Field
        label="proposalAccepted"
        value={company.kpi?.commercialProposalAcceptedCount}
      />
      <Field
        label="seniorRecruited"
        value={company.kpi?.seniorRecruitedCount}
      />
      <Field
        label="juniorRecruited"
        value={company.kpi?.juniorRecruitedCount}
      />
    </>
  );
}
