import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ROUTES } from 'utils/routes';
import { formatData, tableColumns } from 'components/eiquem/offer/Table/helper';

import Table from 'components/core/Table';
import EditButton from 'components/core/buttons/EditButton';
import OfferStatusButton from 'components/eiquem/offer/OfferStatusButton';
import RowDetails from 'components/eiquem/offer/Table/RowDetails';
import OfferHeadlineButton from '../OfferHeadlineButton';

function OfferTable({ onChange }) {
  const offers = useSelector(state => state.offers.items);

  const offerRows = useMemo(
    () => {
      return formatData(offers);
    },
    [offers]
  );
  return (
    <Table
      rows={offerRows}
      columns={tableColumns}
      actions={actions}
      detail={RowDetails}
      onChange={onChange}
    />
  );
}

const actions = props => (
  <>
    <EditButton path={ROUTES.OFFER_EDIT.replace(':id', props.id)} />
    <OfferStatusButton row={props} />
    <OfferHeadlineButton row={props} />
  </>
);

export default OfferTable;
