import { formatDate } from 'utils/functions';
import { Subtitles, Subtitle } from 'components/layout/PageHeader/Subtitle';

export default function OfferSubtitles({ offer }) {
  return (
    <Subtitles>
      <Subtitle>{formatDate(offer.startDateMin)}</Subtitle>
      <Subtitle>
        {offer.durationMin && offer.durationMin > 0
          ? offer.durationMin + ' mois'
          : null}
      </Subtitle>
      <Subtitle>{offer.cities.map(city => city.label).join(', ')}</Subtitle>
      <Subtitle>
        {offer.contractTypes.map(contractType => contractType.label).join(', ')}
      </Subtitle>
    </Subtitles>
  );
}
