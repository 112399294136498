import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { addApplication, suggestOffer } from 'redux/candidates/actions';
import {
  getFunctionByFamilyOptions,
  getOfferOptions,
} from 'redux/formOptions/actions';

import { Grid } from '@material-ui/core';
import ActionButton from 'components/ActionButton';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from 'components/core/input/Autocomplete';
import Checkbox from 'components/Checkbox';

CreateButton.defaultProps = {
  redirectAfter: false,
};

function CreateButton(props) {
  const researchId = parseInt(props.researchId);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    offerFunction: null,
    offer: null,
    suggestion: false,
  });

  const handleChange = (field, value) => {
    const newValues = { ...values };
    newValues[field] = value;
    setValues(newValues);
  };

  const handleOnConfirm = () => {
    const offerId = parseInt(values.offer.value);

    const isSuggestion = values.suggestion;
    if (isSuggestion) {
      dispatch(
        suggestOffer({
          researchId: researchId,
          offerId: offerId,
        })
      );
    } else {
      dispatch(addApplication(researchId, offerId));
    }
  };

  const functionOptions = useSelector(
    state => state.formOptions.functionByFamilyOptions
  );
  const offerOptions = useSelector(state => state.formOptions.offerOptions);

  useEffect(() => {
    dispatch(functionOptions ? null : getFunctionByFamilyOptions());
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      if (!values.offerFunction) {
        return;
      }
      const params = {
        onlyActive: 1,
        offerFunction: values.offerFunction.value,
      };
      dispatch(getOfferOptions(params));
    },
    // eslint-disable-next-line
    [values.offerFunction]
  );
  const dialogContent = (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <Autocomplete
          value={values.offerFunction}
          options={functionOptions}
          label={t('common.fields.offerFuctions')}
          onChange={({ value }) => {
            handleChange('offerFunction', value);
          }}
        />
      </Grid>
      <Grid item sm={12}>
        <Autocomplete
          value={values.offer}
          options={offerOptions}
          label={t('common.fields.offer')}
          onChange={({ value }) => {
            handleChange('offer', value);
          }}
        />
      </Grid>
      <Grid item sm={12}>
        <Checkbox
          label={t('common.fields.suggestion')}
          value={values.suggestion}
          handleChange={value => handleChange('suggestion', value)}
        />
      </Grid>
    </Grid>
  );
  return (
    <ActionButton
      icon={<AddIcon />}
      dialog
      action={handleOnConfirm}
      label={t('candidate.dialog.application.title')}
      title={t('candidate.dialog.application.title')}
      contentText={t('candidate.dialog.application.text')}
      dialogContent={dialogContent}
      disableSubmit={!values.offer}
      tooltipPosition="left"
    />
  );
}

export default CreateButton;
