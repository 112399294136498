import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Main from 'components/layout/Main';
import CandidateTable from 'components/eiquem/candidate/Table';
import PageHeader from 'components/layout/PageHeader';

function CandidateListPage() {
  const { t } = useTranslation();
  const loading = useSelector(state => state.candidates.loading);

  return (
    <Main pageTitle={t('candidate.title')} isLoading={loading}>
      <PageHeader title={t('candidate.title')} />
      <CandidateTable />
    </Main>
  );
}

export default CandidateListPage;
