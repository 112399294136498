import { fetch } from 'utils/api';
import services from 'utils/api/Services/services';
import { actionRequest, actionSuccess, actionFailure } from 'utils/functions';
import { letudiantAdvertValidator } from 'utils/schemas';

import type { GetLetudiantAdvertAPIType } from 'utils/api/types';

export const ACTIONS_TYPE = Object.freeze({
  GET_POSTINGS_BEGIN: 'GET_POSTINGS_BEGIN',
  GET_POSTINGS_SUCCESS: 'GET_POSTINGS_SUCCESS',
  GET_POSTINGS_FAILURE: 'GET_POSTINGS_FAILURE',

  POST_POSTINGS_BEGIN: 'POST_POSTINGS_BEGIN',
  POST_POSTINGS_SUCCESS: 'POST_POSTINGS_SUCCESS',
  POST_POSTINGS_FAILURE: 'POST_POSTINGS_FAILURE',

  ARCHIVE_POSTINGS_BEGIN: 'ARCHIVE_POSTINGS_BEGIN',
  ARCHIVE_POSTINGS_SUCCESS: 'ARCHIVE_POSTINGS_SUCCESS',
  ARCHIVE_POSTINGS_FAILURE: 'ARCHIVE_POSTINGS_FAILURE',

  GET_LETUDIANT_ADVERT_BEGIN: 'GET_LETUDIANT_ADVERT_BEGIN',
  GET_LETUDIANT_ADVERT_SUCCESS: 'GET_LETUDIANT_ADVERT_SUCCESS',
  GET_LETUDIANT_ADVERT_FAILURE: 'GET_LETUDIANT_ADVERT_FAILURE',

  POST_LETUDIANT_ADVERT_BEGIN: 'POST_LETUDIANT_ADVERT_BEGIN',
  POST_LETUDIANT_ADVERT_SUCCESS: 'POST_LETUDIANT_ADVERT_SUCCESS',
  POST_LETUDIANT_ADVERT_FAILURE: 'POST_LETUDIANT_ADVERT_FAILURE',
});

export const getPostings = (params: GetPostingsAPIType): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
) =>
  fetch({
    service: services.jobboards.posting.getAll(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_POSTINGS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_POSTINGS_SUCCESS, {
        result: result,
        admin: params ? params.admin : null,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_POSTINGS_FAILURE, errors),
    validator: letudiantAdvertValidator,
  });

export const postPosting = (params: PostPostingAPIType): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
) =>
  fetch({
    service: services.jobboards.posting.post(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.POST_POSTINGS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.POST_POSTINGS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.POST_POSTINGS_FAILURE, errors),
  });

export const archivePosting = (params: ArchivePostingAPIType): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
) =>
  fetch({
    service: services.jobboards.posting.archive(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.ARCHIVE_POSTINGS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.ARCHIVE_POSTINGS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.ARCHIVE_POSTINGS_FAILURE, errors),
  });

export const getLetudiantAdvert = (
  params: GetLetudiantAdvertAPIType
): ThunkAction => (dispatch: Dispatch, getState: GetState) =>
  fetch({
    service: services.jobboards.letudiant.get(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_LETUDIANT_ADVERT_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_LETUDIANT_ADVERT_SUCCESS, {
        offerId: params.offerId,
        result: result,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_LETUDIANT_ADVERT_FAILURE, errors),
    validator: letudiantAdvertValidator,
  });

export const postLetudiantAdvert = (
  params: PostLetudiantAdvertAPIType
): ThunkAction => (dispatch: Dispatch, getState: GetState) =>
  fetch({
    service: services.jobboards.letudiant.post(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.POST_LETUDIANT_ADVERT_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.POST_LETUDIANT_ADVERT_SUCCESS, {
        offerId: params.offerId,
        result: result,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.POST_LETUDIANT_ADVERT_FAILURE, errors),
    validator: letudiantAdvertValidator,
  });
