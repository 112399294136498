import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment-timezone';

import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { Replay } from '@material-ui/icons';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Autocomplete from 'components/core/input/Autocomplete';
import S from '../styles';

import { jobBoardsOptions } from '../constants';
import { getJobBoard } from '../functions';

PublishButton.propTypes = {
  classes: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  action: PropTypes.func.isRequired,
};

function PublishButton(props) {
  const { classes, offer, action } = props;
  const [open, setOpen] = useState(false);
  const [jobBoard, setJobBoard] = useState(null);
  const [archiveDate, setArchiveDate] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    action(offer.id, jobBoard.value, archiveDate);
    handleClose();
  };

  const letudiantId = getJobBoard('letudiant').id;
  const handleChangeJobBoard = jobBoard => {
    if (jobBoard !== null && jobBoard.value === letudiantId) {
      setArchiveDate(moment().add(15, 'days'));
    }
    setJobBoard(jobBoard);
  };

  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t('jobboards-posting.summary.publish-tooltip')}>
        <IconButton
          size="small"
          className={classes.button}
          onClick={handleClickOpen}
        >
          <Replay fontSize="small" />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title">
          {t('jobboards-posting.summary.publish-title')}{' '}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('jobboards-posting.summary.publish-text')}
          </DialogContentText>
          <Autocomplete
            className={classes.selectField}
            options={jobBoardsOptions}
            label={t('jobboards-posting.summary.jobboard-label')}
            onChange={({ value }) => {
              handleChangeJobBoard(value);
            }}
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className={classes.dateField}
              disableToolbar
              autoComplete="off"
              variant="inline"
              format="dd/MM/yyyy"
              id="date-picker-inline"
              label={t('jobboards-posting.summary.publish-archive-date-label')}
              disabled={jobBoard && jobBoard.value === letudiantId}
              value={archiveDate}
              onChange={value => {
                setArchiveDate(value);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions className={classes.actionFields}>
          <Button onClick={handleClose} color="primary">
            {t('jobboards-posting.summary.publish-cancel')}
          </Button>
          <Button
            disabled={jobBoard === null}
            onClick={handleConfirm}
            color="primary"
          >
            {t('jobboards-posting.summary.publish-confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withStyles(S)(PublishButton);
