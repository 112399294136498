import { formatDate } from 'utils/functions';
import { Subtitles, Subtitle } from 'components/layout/PageHeader/Subtitle';

export default function CandidateSubtitles({ candidate }) {
  return (
    <Subtitles>
      <Subtitle>{formatDate(candidate.registerDate)}</Subtitle>
      <Subtitle>{candidate.mail}</Subtitle>
      <Subtitle>{candidate.phoneNumber}</Subtitle>
    </Subtitles>
  );
}
