import {
  lightBlueFontCode,
  darkBlueFontCode,
  yellowFontCode,
  greenFontCode,
  redFontCode,
  greyFontCode,
  orangeFontCode,
  purpleFontCode,
} from 'utils/constants';
import { ROUTES } from 'utils/routes';
import { formatDate, isValidDate } from 'utils/functions';

export const tableColumns = [
  {
    header: 'Entreprise',
    accessors: ['companyLink'],
    headerStyle: { width: '10%' },
    type: 'image',
  },
  {
    header: '',
    accessors: ['companyLink', 'label'],
    headerStyle: { width: '10%' },
  },
  {
    header: 'Offre',
    accessors: ['offerLink'],
    headerStyle: {
      width: '40%',
    },
    type: 'link',
  },
  {
    header: 'Suggestion',
    accessors: ['suggestionDate'],
    width: '10%',
  },
  {
    header: 'Candidature',
    accessors: ['applicationDate'],
    width: '10%',
    type: 'date',
  },
  {
    header: "Date d'envoi",
    accessors: ['sentDate'],
    width: '10%',
    type: 'date',
  },
  {
    header: 'CV',
    accessors: ['resumeLink'],
    headerStyle: { width: '5%' },
    type: 'link',
  },
  {
    header: 'LM',
    accessors: ['coverLetterLink'],
    headerStyle: { width: '5%' },
    type: 'link',
  },
];

export const tableLegend = [
  { color: lightBlueFontCode, label: 'Candidat Envoyé' },
  { color: darkBlueFontCode, label: 'Candidat en Entretien' },
  { color: orangeFontCode, label: 'Suggestion' },
  { color: greenFontCode, label: 'Suggestion Acceptée' },
  { color: greyFontCode, label: 'Candidat en Short List' },
  { color: redFontCode, label: 'Candidat Refusé' },
  { color: yellowFontCode, label: 'Candidat Pris' },
  { color: purpleFontCode, label: 'Candidat non Joignable (message)' },
];

export const formatRows = applications => {
  if (!applications) {
    return [];
  }
  return applications.map(application => {
    const row = {
      ...application,
      id: application.id || application.suggestionId,
      companyLink: {
        label: application.company.name,
        img: application.company.logo,
        url: ROUTES.COMPANY_SHOW.replace(':id', application.company.id),
      },
      offerLink: {
        label: application.offer.label,
        url: ROUTES.OFFER_SHOW.replace(':id', application.offer.id),
      },
      resumeLink: {
        label: 'CV',
        url: application.resumeLink,
        target: 'blank',
      },
      coverLetterLink: {
        label: 'LM',
        url: application.coverLetterLink,
        target: 'blank',
      },
      suggestionDate: validateAndFormatDate(application.suggestionDate),
    };

    if (application.lastRemindDate) {
      row.suggestionDate += ` (${validateAndFormatDate(
        application.lastRemindDate
      )})`;
    }

    const states = application.states || [];
    if (states.some(state => state.code === 'hired')) {
      row.backgroundColor = yellowFontCode;
    } else if (states.some(state => state.code === 'refused')) {
      row.backgroundColor = redFontCode;
    } else if (states.some(state => state.code === 'abandoned')) {
      row.backgroundColor = redFontCode;
    } else if (application.hasContactAttempt) {
      row.backgroundColor = purpleFontCode;
    } else if (states.some(state => state.code === 'inInterview')) {
      row.backgroundColor = darkBlueFontCode;
    } else if (states.some(state => state.code === 'sent')) {
      row.backgroundColor = lightBlueFontCode;
    } else if (states.some(state => state.code === 'shortList')) {
      row.backgroundColor = greyFontCode;
    } else if (application.suggestionDate) {
      if (application.suggestionAccepted) {
        row.backgroundColor = greenFontCode;
      } else {
        row.backgroundColor = orangeFontCode;
      }
    }
    if (application.declinedDate) {
      row.backgroundColor = redFontCode;
    }

    return row;
  });
};

const validateAndFormatDate = date =>
  isValidDate(date) ? formatDate(date) : '-';
