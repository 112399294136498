import {
  lightBlueFontCode,
  darkBlueFontCode,
  redFontCode,
} from 'utils/constants';

export const months = [
  'Janvier',
  'Fevrier',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Decembre',
];
export const tableColumns = dateAccessor => [
  {
    header: 'Entreprise',
    accessors: ['companyLogo'],
    type: 'image',
  },
  {
    header: '',
    accessors: ['companyLink'],
    headerStyle: { width: '10%' },
    type: 'link',
  },
  {
    header: 'Date',
    type: 'date',
    accessors: [dateAccessor],
    headerStyle: { width: '10%' },
  },
  {
    header: 'Offre',
    accessors: ['offerLink'],
    headerStyle: { width: '40%' },
    type: 'link',
  },
  {
    header: 'Candidat',
    accessors: ['candidateLink'],
    headerStyle: { width: '20%' },
    type: 'link',
  },
  {
    header: 'AM',
    accessors: ['admin', 'label'],
    headerStyle: { width: '20%' },
  },
];

const now = new Date();
export const currYear = now.getFullYear();
export const currMonth = now.getMonth() + 1;

export const placementTabs = [
  {
    key: 'placement',
    label: 'Placements',
  },
  {
    key: 'starting',
    label: 'Démarrages',
  },
  {
    key: 'invoiced',
    label: 'Facturés',
  },
  {
    key: 'renewed',
    label: 'Seconds Variables',
  },
];

export const monthTabs = months.map((month, index) => ({
  key: index + 1,
  label: month,
}));

export const legend = type => {
  switch (type) {
    default:
      return defaultLegend;
    case 'renewed':
      return renewLegend;
  }
};

const defaultLegend = [
  { color: lightBlueFontCode, label: 'Placement' },
  { color: darkBlueFontCode, label: 'Placement Facturé' },
  { color: redFontCode, label: 'Placement non Facturé' },
];
const renewLegend = [
  { color: darkBlueFontCode, label: 'Second Variable Facturé' },
  { color: redFontCode, label: 'Second Variable non Facturé' },
];
