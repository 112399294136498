import { ACTIONS_TYPE, getPafs } from './actions';
import { ROUTES } from 'utils/routes';
import { pushRedirection } from 'utils/functions';
import history from 'utils/history';

export const pafsMiddleware = ({ dispatch }) => next => action => {
  if (!action) {
    return;
  }
  if (
    action.type === ACTIONS_TYPE.CREATE_PAF_SUCCESS ||
    action.type === ACTIONS_TYPE.UPDATE_PAF_SUCCESS
  ) {
    console.log('pafsMiddleware called...');
    console.log('redirect to previous url...');
    const expectedPaths = [ROUTES.PAF_DATA, ROUTES.PAF];
    pushRedirection(expectedPaths);
  } else if (
    action.type === ACTIONS_TYPE.CLONE_PAF_SUCCESS ||
    action.type === ACTIONS_TYPE.DELETE_PAF_SUCCESS
  ) {
    console.log('pafsMiddleware called...');
    console.log('refresh data...');
    const historyState = history.location.state || null;
    const historyParams =
      historyState && historyState.search
        ? { filter: historyState.search }
        : {};

    dispatch(getPafs(historyParams));
  }

  next(action);
};
