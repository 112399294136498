import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { sendInvoices } from 'redux/invoices/actions';
import { getActiveInvoiceOptions } from 'redux/formOptions/actions';
import ActionButton from 'components/ActionButton';

import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import Autocomplete from 'components/core/input/Autocomplete';

import S from '../styles';

function SendMailButton(props) {
  const { classes, row } = props;

  const dispatch = useDispatch();
  const [sent, setSent] = useState(true);
  const [grouped, setGrouped] = useState(false);
  const [groupedInvoices, setGroupedInvoices] = useState([]);
  const [summary, setSummary] = useState(false);

  const onOpenDialog = () => dispatch(getActiveInvoiceOptions({ id: row.id }));

  const activeInvoiceOptions = useSelector(
    state => state.formOptions.activeInvoiceOptions
  );
  const invoiceOptions = activeInvoiceOptions
    ? activeInvoiceOptions.filter(option => option.value !== row.id)
    : [];

  const handleOnConfirm = () => {
    let params = {
      id: row.id,
      sent: sent ? 1 : 0,
      summary: summary ? 1 : 0,
    };
    if (grouped) {
      params.groupedInvoices = groupedInvoices
        .map(invoice => invoice.value)
        .join(',');
    }

    dispatch(sendInvoices(params));
  };

  const dialogContent = (
    <>
      <div className={classes.row}>
        <FormControlLabel
          label="Marquer comme envoyée"
          control={
            <Checkbox
              id="sent"
              checked={sent}
              onChange={event => {
                setSent(event.target.checked);
              }}
              color="primary"
              disabled={row.issuance !== null}
            />
          }
          className={classes.checkbox}
          labelPlacement="end"
        />
      </div>
      <div className={classes.row}>
        <FormControlLabel
          label="Grouper d'autres factures"
          control={
            <Checkbox
              disabled={invoiceOptions.length === 0}
              id="grouped"
              checked={grouped}
              onChange={event => {
                setGrouped(event.target.checked);
              }}
              color="primary"
            />
          }
          className={classes.checkbox}
          labelPlacement="end"
        />
        {grouped && (
          <Autocomplete
            multi
            disableCloseOnSelect
            className={classes.selectField}
            options={invoiceOptions}
            label="Factures"
            onChange={({ value }) => {
              setGroupedInvoices(value);
            }}
          />
        )}
      </div>
      <div className={classes.row}>
        <FormControlLabel
          label="Récapitulatif"
          control={
            <Checkbox
              id="remind"
              checked={summary}
              onChange={event => {
                setSummary(event.target.checked);
              }}
              color="primary"
            />
          }
          className={classes.checkbox}
          labelPlacement="end"
        />
      </div>
    </>
  );

  return (
    <ActionButton
      icon={<MailIcon fontSize="small" />}
      dialog
      action={handleOnConfirm}
      label="Générer mail de facturation"
      title="Génération du mail de facturation"
      contentText="Personnalisation du mail de facturation à destination du client."
      confirmText="Envoyer"
      cancelText="Annuler"
      dialogContent={dialogContent}
      onOpenDialog={onOpenDialog}
      disabled={row.paymentDate !== null}
    />
  );
}

export default withStyles(S)(SendMailButton);
