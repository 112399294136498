import BaseButton from './baseButton';

export default function NonPropositionButton(props) {
  const { value } = props;

  const msg = `Souhaites-tu REFUSER cette candidature pour la raison NON PROPOSISITON ?
    Aucun mail ne sera envoyé au candidat mais le statut de sa candidature sera mis à jour.`;

  return (
    <BaseButton
      title="Le candidat n'a pas reçu de proposition de la part de l'entreprise"
      label="Non prop"
      message={msg}
      value={value}
      color="secondary"
      action={props.action}
      disabled={props.disabled}
    />
  );
}
