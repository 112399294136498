// @flow
import type { Schema, ValidatorReturn, Validator } from './types';
import {
  isNumber,
  isString,
  isInteger,
  isBoolean,
  toBoolean,
  isEmail,
  isUtcDate,
} from './helpers';
import {
  requiredError,
  stringError,
  numberError,
  positiveIntegerError,
  booleanError,
  arrayError,
  emailError,
  utcDateError,
} from './error-generators';

export const required = (value: any, key?: string = ''): ValidatorReturn => ({
  result: value,
  errors:
    value === undefined || value === null ? [requiredError(key, value)] : [],
});

export const string = (value: any, key?: string = ''): ValidatorReturn => {
  return isString(value)
    ? { result: value, errors: [] }
    : { result: '', errors: [stringError(key, value)] };
};

export const optionalString = (value: any): ValidatorReturn => {
  return isString(value)
    ? { result: value, errors: [] }
    : { result: '', errors: [] };
};

export const number = (value: any, key?: string = ''): ValidatorReturn => {
  return isNumber(value)
    ? { result: value, errors: [] }
    : { result: 0, errors: [numberError(key, value)] };
};

export const optionalNumber = (value: any): ValidatorReturn => {
  return isNumber(value)
    ? { result: value, errors: [] }
    : { result: 0, errors: [] };
};

export const positiveInteger = (
  value: any,
  key?: string = ''
): ValidatorReturn => {
  return isNumber(value) && isInteger(value) && value >= 0
    ? { result: value, errors: [] }
    : { result: 0, errors: [positiveIntegerError(key, value)] };
};

export const optionalPositiveInteger = (
  value: any,
  key?: string = ''
): ValidatorReturn => {
  return (isNumber(value) && isInteger(value) && value >= 0) || value === null
    ? { result: value, errors: [] }
    : { result: 0, errors: [positiveIntegerError(key, value)] };
};

export const boolean = (value: any, key?: string = ''): ValidatorReturn => {
  return isBoolean(value)
    ? { result: value, errors: [] }
    : { result: toBoolean(value), errors: [booleanError(key, value)] };
};

export const optionalBoolean = (value: any): ValidatorReturn => {
  return isBoolean(value)
    ? { result: value, errors: [] }
    : { result: toBoolean(value), errors: [] };
};

export const email = (value: any, key?: string = ''): ValidatorReturn => {
  return isEmail(value)
    ? { result: value, errors: [] }
    : {
        result: isString(value) ? value : '',
        errors: [emailError(key, value)],
      };
};

export const utcDate = (value: any, key?: string = ''): ValidatorReturn => {
  return isUtcDate(value)
    ? { result: new Date(value), errors: [] }
    : { result: undefined, errors: [utcDateError(key, value)] };
};

export const optionalUtcDate = (value: any): ValidatorReturn => {
  return isUtcDate(value)
    ? { result: new Date(value), errors: [] }
    : { result: null, errors: [] };
};

export const validate = (schema: Schema, optionnal?: boolean = false) => (
  data: any
): ValidatorReturn => {
  let result = {};
  let errors = [];

  if (optionnal && (data === undefined || data === null)) {
    return { result: undefined, errors };
  }

  Object.keys(schema).forEach(key => {
    const validator = schema[key];

    if (!validator) return;

    const validation = validator((data || {})[key], key);

    if (validation.errors.length) errors.push(validation.errors.join());

    result[key] = validation.result;
  });

  return { result, errors };
};

export const arrayOf = (validator: Validator) => (
  data: any
): ValidatorReturn => {
  let arrayErrors = [];
  if (!Array.isArray(data)) {
    return {
      result: [],
      errors: [arrayError(data)],
    };
  }

  const arrayResult = data.map((value, index) => {
    const { result, errors } = validator(value);
    if (errors.length) {
      arrayErrors.push([`At index ${index}`].concat(errors).join(', '));
    }
    return result;
  });

  return { result: arrayResult, errors: arrayErrors };
};

export const arrayOfSchema = (schema: Schema) => (data: any): ValidatorReturn =>
  arrayOf(validate(schema))(data);

const valid = {
  required,
  string,
  optionalString,
  number,
  optionalNumber,
  positiveInteger,
  optionalPositiveInteger,
  boolean,
  optionalBoolean,
  email,
  utcDate,
  optionalUtcDate,
  arrayOfSchema,
  arrayOf,
  validate,
};

export default valid;
