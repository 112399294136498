import { ACTIONS_TYPE, cleanCurrentCompany } from './actions';
import { ROUTES } from 'utils/routes';
import { pushRedirection } from 'utils/functions';

export const companiesMiddleware = ({ dispatch }) => next => action => {
  if (!action) {
    return;
  }
  if (
    action.type === ACTIONS_TYPE.CREATE_COMPANY_SUCCESS ||
    action.type === ACTIONS_TYPE.UPDATE_COMPANY_SUCCESS
  ) {
    console.log('companiesMiddleware called...');
    console.log('redirect to previous url...');
    const expectedPaths = [ROUTES.COMPANIES_LIST];
    pushRedirection(expectedPaths);
    dispatch(cleanCurrentCompany());
  }

  next(action);
};
