import { ACTIONS_TYPE } from './actions';

export const initialState = {
  loading: false,
  items: null,
  totalItemCount: null,
  adverts: [],
};

export function jobBoardsPostsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPE.GET_POSTINGS_BEGIN:
    case ACTIONS_TYPE.ARCHIVE_POSTINGS_BEGIN:
    case ACTIONS_TYPE.POST_POSTINGS_BEGIN:
    case ACTIONS_TYPE.POST_LETUDIANT_ADVERT_BEGIN:
    case ACTIONS_TYPE.GET_LETUDIANT_ADVERT_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ACTIONS_TYPE.GET_POSTINGS_FAILURE:
    case ACTIONS_TYPE.ARCHIVE_POSTINGS_FAILURE:
    case ACTIONS_TYPE.ARCHIVE_POSTINGS_SUCCESS:
    case ACTIONS_TYPE.POST_POSTINGS_FAILURE:
    case ACTIONS_TYPE.POST_POSTINGS_SUCCESS:
    case ACTIONS_TYPE.POST_LETUDIANT_ADVERT_FAILURE:
    case ACTIONS_TYPE.POST_LETUDIANT_ADVERT_SUCCESS:
    case ACTIONS_TYPE.GET_LETUDIANT_ADVERT_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case ACTIONS_TYPE.GET_POSTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.result.result.postings
          ? action.result.result.postings
          : action.result.result,
        totalItemCount: action.result.result.totalItemCount
          ? action.result.result.totalItemCount
          : null,
      };

    case ACTIONS_TYPE.GET_LETUDIANT_ADVERT_SUCCESS:
      let index = state.adverts.findIndex(
        advert => advert.offerId === action.result.offerId
      );
      if (index === -1) {
        return Object.assign({}, state, {
          ...state,
          loading: false,
          adverts: [
            ...state.adverts,
            {
              offerId: action.result.offerId,
              letudiant: action.result.result,
            },
          ],
        });
      } else {
        return Object.assign({}, state, {
          ...state,
          loading: false,
          adverts: [
            ...state.adverts.map(
              advert =>
                advert.offerId === action.result.offerId
                  ? { ...advert, letudiant: action.result.result }
                  : advert
            ),
          ],
        });
      }

    default:
      return state;
  }
}
