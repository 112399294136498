function ResearchInfo(props) {
  const { research } = props;

  return (
    <>
      <Item label="Contrat" value={research.contractLabel} />
      <Item
        label="Disponible"
        value={`${research.startDate}
         ${research.duration ? ' - ' + `${research.duration} mois` : ''}`}
      />
      <Item
        label="Nombre d'offres candidatées"
        value={research.applicationCount}
      />
      <Item
        label="Nombre d'offres suggérées"
        value={research.suggestionCount}
      />
      <Item
        label="Envoyée/Refusées"
        value={`${research.sentApplicationCount}/${
          research.refusedApplicationCount
        }`}
      />
      <Item
        label="Commentaire"
        value={research.hasBeenCommented ? 'oui' : 'non'}
      />
      <Item
        label="Date du dernier contact"
        value={research.lastContactDate ?? 'jamais'}
      />
    </>
  );
}

const Item = ({ label, value }) => (
  <span style={{ display: 'block' }}>
    <b>{label} :</b> {value}
  </span>
);

export default ResearchInfo;
