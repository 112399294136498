import { fetch } from 'utils/api';
import services from 'utils/api/Services/services';
import { actionRequest, actionSuccess, actionFailure } from 'utils/functions';
import { pafValidator, pafsArrayValidator } from 'utils/schemas';

export const ACTIONS_TYPE = Object.freeze({
  GET_PAF_BEGIN: 'GET_PAF_BEGIN',
  GET_PAF_SUCCESS: 'GET_PAF_SUCCESS',
  GET_PAF_FAILURE: 'GET_PAF_FAILURE',
  GET_PAFS_BEGIN: 'GET_PAFS_BEGIN',
  GET_PAFS_SUCCESS: 'GET_PAFS_SUCCESS',
  GET_PAFS_FAILURE: 'GET_PAFS_FAILURE',
  CREATE_PAF_BEGIN: 'CREATE_PAF_BEGIN',
  CREATE_PAF_SUCCESS: 'CREATE_PAF_SUCCESS',
  CREATE_PAF_FAILURE: 'CREATE_PAF_FAILURE',
  UPDATE_PAF_BEGIN: 'UPDATE_PAF_BEGIN',
  UPDATE_PAF_SUCCESS: 'UPDATE_PAF_SUCCESS',
  UPDATE_PAF_FAILURE: 'UPDATE_PAF_FAILURE',
  DELETE_PAF_BEGIN: 'DELETE_PAF_BEGIN',
  DELETE_PAF_SUCCESS: 'DELETE_PAF_SUCCESS',
  DELETE_PAF_FAILURE: 'DELETE_PAF_FAILURE',
  CLONE_PAF_BEGIN: 'CLONE_PAF_BEGIN',
  CLONE_PAF_SUCCESS: 'CLONE_PAF_SUCCESS',
  CLONE_PAF_FAILURE: 'CLONE_PAF_FAILURE',
  CLEAN_CURRENT_PAF: 'CLEAN_CURRENT_PAF',
  CLEAN_PAFS: 'CLEAN_PAFS',
  GET_PAF_STATS_BEGIN: 'GET_PAF_STATS_BEGIN',
  GET_PAF_STATS_SUCCESS: 'GET_PAF_STATS_SUCCESS',
  GET_PAF_STATS_FAILURE: 'GET_PAF_STATS_FAILURE',
  GET_PREFILLED_PAF_BEGIN: 'GET_PREFILLED_PAF_BEGIN',
  GET_PREFILLED_PAF_SUCCESS: 'GET_PREFILLED_PAF_SUCCESS',
  GET_PREFILLED_PAF_FAILURE: 'GET_PREFILLED_PAF_FAILURE',
});

export const getPrefilledPlacementByOffer = ({ offerId }) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.pafs.getPrefilledByOffer({ offerId }),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_PREFILLED_PAF_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_PREFILLED_PAF_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_PREFILLED_PAF_FAILURE, errors),
  });

export const getPafStats = ({ fromDate = null, toDate = null }) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.pafs.getStats({ fromDate, toDate }),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_PAF_STATS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_PAF_STATS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_PAF_STATS_FAILURE, errors),
  });

export const cleanPafs = () => ({
  type: ACTIONS_TYPE.CLEAN_PAFS,
});

export const cleanCurrentPaf = () => ({
  type: ACTIONS_TYPE.CLEAN_CURRENT_PAF,
});

export const clonePaf = params => (dispatch, getState) =>
  fetch({
    service: services.pafs.clone(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.CLONE_PAF_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.CLONE_PAF_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.CLONE_PAF_FAILURE, errors),
  });

export const getOnePaf = params => (dispatch, getState) =>
  fetch({
    service: services.pafs.getOne(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_PAF_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_PAF_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_PAF_FAILURE, errors),
    validator: pafsArrayValidator,
  });

export const getPafs = params => (dispatch, getState) =>
  fetch({
    service: services.pafs.get(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_PAFS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_PAFS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_PAFS_FAILURE, errors),
    validator: pafsArrayValidator,
  });

export const createPaf = params => (dispatch, getState) =>
  fetch({
    service: services.pafs.create(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.CREATE_PAF_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.CREATE_PAF_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.CREATE_PAF_FAILURE, errors),
    validator: pafValidator,
  });

export const updatePaf = params => (dispatch, getState) =>
  fetch({
    service: services.pafs.update(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.UPDATE_PAF_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.UPDATE_PAF_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_PAF_FAILURE, errors),
    validator: pafValidator,
  });

export const deletePaf = params => (dispatch, getState) =>
  fetch({
    service: services.pafs.delete(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.DELETE_PAF_BEGIN),
    actionSuccess: () => actionSuccess(ACTIONS_TYPE.DELETE_PAF_SUCCESS, params),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.DELETE_PAF_FAILURE, errors),
    validator: pafValidator,
  });
