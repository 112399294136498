import styled from 'styled-components';
import TabContainer from './tabContainer';

const VerticalTabContainer = styled(TabContainer)`
  flex-direction: column;
  min-height: 400px;
  max-width: 160px;
  border-top: 0px;
`;
export default VerticalTabContainer;
