// @flow
import { ROUTES } from './routes';
import { FaUserLock, FaDesktop } from 'react-icons/fa';

export const navigationList = {
  backOffice: [
    { title: 'dashboard', to: ROUTES.HOME, icon: FaDesktop, path: ROUTES.HOME },
    {
      title: 'profile.main-title',
      to: ROUTES.HOME,
      icon: FaUserLock,
      path: ROUTES.HOME,
    },
  ],
};

export const localStorageJwtKey = 'JWT';
export const localStorageRefreshJwtKey = 'REFRESH_JWT';

export const apiUrl = process.env.REACT_APP_API;

//export const greenFont = 'greenFont';
//export const greenFontCode = '#39CCCC';

export const redFont = 'redFont';
export const redFontCode = 'lightCoral';

export const lightBlueFont = 'lightBlueFont';
export const lightBlueFontCode = 'lightBlue';

export const darkBlueFont = 'darkBlueFont';
export const darkBlueFontCode = '#73adc0';

export const greyFont = 'greyFont';
export const greyFontCode = '#ededed';

export const yellowFont = 'yellowFont';
export const yellowFontCode = 'yellow';

export const greenFont = 'greenFont';
export const greenFontCode = 'lightGreen';

export const orangeFont = 'orangeFont';
export const orangeFontCode = '#e4a000';

export const purpleFont = 'purpleFont';
export const purpleFontCode = '#7c177c';

export const strikeThrough = 'strikeThrough';

export const months = [
  'months.1',
  'months.2',
  'months.3',
  'months.4',
  'months.5',
  'months.6',
  'months.7',
  'months.8',
  'months.9',
  'months.10',
  'months.11',
  'months.12',
];

// L'Etudiant constants
export const studentJobTypeId = 0;
export const internshipTypeId = 1;
export const workAndStudyTypeId = 2;
export const workTypeId = 8;

// Plan constants
export const targetCVPlanId = 2;
export const recruitmentPlanId = 3;
export const rpoPlanId = 5;

// Contract type
export const partielId = 2;
export const proId = 3;
export const apprentissageId = 4;
export const cdiId = 5;
export const cddId = 6;

// Offer constants
export const recruitmentOfferTypeId = 1;
export const accordingProfileSalaryId = 2;
