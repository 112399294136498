import Tabs from 'components/core/Tabs';

function PeriodTabs() {
  return <Tabs name="period" direction="horizontal" tabs={PERIODS} />;
}

const PERIOD_KEYS = Object.freeze({
  w1: 'w1',
  w4: 'w4',
  q: 'q',
  m1: 'm1',
  m: 'm',
});

const PERIODS = Object.freeze([
  { key: PERIOD_KEYS.w1, label: 'followUp.periods.week1' },
  { key: PERIOD_KEYS.w4, label: 'followUp.periods.week4' },
  { key: PERIOD_KEYS.q, label: 'followUp.periods.quarter' },
  { key: PERIOD_KEYS.m1, label: 'followUp.periods.month-1' },
  { key: PERIOD_KEYS.m, label: 'followUp.periods.month' },
]);

export { PeriodTabs, PERIOD_KEYS };
