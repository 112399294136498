import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from 'components/core/input';
import { useFetchContractTypes } from 'utils/hooks/formOptions';

function ContractType({ values, errors, touched, onChange }) {
  const { t } = useTranslation();
  const options = useContractTypeOptions();

  return (
    <Autocomplete
      name={'contractType'}
      defaultValue={values.contractType}
      value={values.contractType}
      options={options}
      label={t('paf.field.contractType')}
      touched={touched.contractType}
      error={errors.contractType}
      onChange={onChange}
    />
  );
}

const useContractTypeOptions = () => {
  const [fetch, options] = useFetchContractTypes();

  useEffect(
    () => {
      if (!options) {
        fetch();
      }
    },
    [options, fetch]
  );

  return options;
};

export default ContractType;
