import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';

import services from 'utils/api/Services/services';
import {
  cleanCurrentInvoice,
  createInvoice,
  updateInvoice,
  getOneInvoice,
} from 'redux/invoices/actions';

import { InvoiceFormSchema } from 'utils/formSchemas';
import { capitalize } from 'utils/functions';
import { initialInvoice, typesOptions } from '../constants';

import Form from './form';
import Main from 'components/layout/Main';
import Title from 'components/layout/Title';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import S from './styles.js';

function InvoiceForm(props) {
  const { classes } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Edit case
  const params = useParams();
  const id = params.id ? parseInt(params.id) : null;
  React.useEffect(
    () => {
      if (id) {
        dispatch(getOneInvoice({ id: id }));
      }

      return dispatch(cleanCurrentInvoice());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const invoiceState = useSelector(state => state.invoices);
  const currentInvoice = invoiceState.current;
  let invoice = null;
  if (id === null) {
    invoice = initialInvoice;
  } else if (currentInvoice !== null && currentInvoice.id === id) {
    invoice = currentInvoice;
    const typeValue = invoice.type;

    const typeFromOptions = typesOptions.find(
      option => option.value === typeValue
    );
    if (typeFromOptions) {
      invoice.type = typeFromOptions;
    } else {
      invoice.type = typeValue;
    }
    if (typeof invoice.type === 'string') {
      invoice.type = {
        label: capitalize(typeValue),
        value: typeValue,
      };
    }
  }

  // Update current Invoice values and dispatch request
  const handleOnSubmit = values => {
    const data = {
      ...values,
      message: values.otherLabel,
      type: values.type.value,
    };
    if (id !== null) {
      data.id = id;
      console.log('call update invoice with data: ', data);
      dispatch(updateInvoice(data));
    } else {
      console.log('call create invoice with data: ', data);
      dispatch(createInvoice(data));
    }
  };

  return (
    <Main pageTitle={t('menu.invoicingTitle')} isLoading={invoiceState.loading}>
      <>
        {id === null && <Title>{t('invoicing.invoices.create')}</Title>}
        {id !== null && (
          <Title>
            {t('invoicing.invoices.edit')} - {id}
          </Title>
        )}

        <Paper className={classes.root}>
          {invoice && (
            <Formik
              initialValues={invoice}
              validationSchema={InvoiceFormSchema}
              onSubmit={handleOnSubmit}
            >
              {props => (
                <Form
                  {...props}
                  fetchAsyncFormOptions={fetchAsyncFormOptions}
                />
              )}
            </Formik>
          )}
        </Paper>
      </>
    </Main>
  );
}

// Manage the fetching of async options with callback
const fetchAsyncFormOptions = () => {
  const fetchCompaniesOptions = searchTerm =>
    services.options.getCompanies({
      search: searchTerm,
    });

  return {
    fetchCompaniesOptions: fetchCompaniesOptions,
  };
};

export default withStyles(S)(InvoiceForm);
