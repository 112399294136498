import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { updateResearch } from 'redux/candidates/actions';

import { Grid } from 'components/core/containers';
import ResearchComments from 'components/eiquem/candidate/ResearchComments';
import ApplicationTable from 'components/eiquem/candidate/ApplicationTable';
import Form from './form';

function Research(props) {
  const { research } = props;

  const dispatch = useDispatch();

  const handleOnSubmit = values => {
    const data = {
      id: values.id,
      status: values.status,
      profile: values.profile,
      gapYear: values.gapYear,
      finalYear: values.finalYear,
      startDate: values.startDate,
      duration: values.duration,
      contractType: values.contractType,
      rhythm: values.rhythm,
      cost: values.cost,
      customerComment: values.customerComment,
      candidateComment: values.candidateComment,
      elocution: values.elocution,
      communication: values.communication,
      motivation: values.motivation,
      dynamism: values.dynamism,
    };
    console.log('call update research with data: ', data);
    dispatch(updateResearch(data));
  };

  return (
    <div style={{ marginTop: 10 }}>
      <Grid container>
        <Grid md={6} sm={12}>
          <Formik initialValues={research} onSubmit={handleOnSubmit}>
            {props => <Form {...props} />}
          </Formik>
        </Grid>
        <Grid sm={12} md={6}>
          <ResearchComments
            researchId={research.id}
            values={research.comments}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <ApplicationTable researchId={research.id} />
        </Grid>
      </Grid>
    </div>
  );
}

export default Research;
