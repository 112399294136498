import { PropTypes } from 'prop-types';
import TextFilter from './textFilter';

SearchField.propTypes = {
  onChange: PropTypes.func,
};

SearchField.defaultProps = {
  onChange: () => {},
};

function SearchField({ onChange }) {
  const handleChange = ({ value }) => {
    onChange({ search: value });
  };

  return (
    <div>
      <TextFilter
        name="search"
        placeholder="Rechercher"
        clearable
        onChange={handleChange}
      />
    </div>
  );
}

export default SearchField;
