import { fetch } from 'utils/api';
import services from 'utils/api/Services/services';
import { actionRequest, actionSuccess, actionFailure } from 'utils/functions';
import { companiesArrayValidator } from 'utils/schemas';

export const ACTIONS_TYPE = Object.freeze({
  GET_COMPANIES_BEGIN: 'GET_COMPANIES_BEGIN',
  GET_COMPANIES_SUCCESS: 'GET_COMPANIES_SUCCESS',
  GET_COMPANIES_FAILURE: 'GET_COMPANIES_FAILURE',

  CREATE_COMPANY_BEGIN: 'CREATE_COMPANY_BEGIN',
  CREATE_COMPANY_SUCCESS: 'CREATE_COMPANY_SUCCESS',
  CREATE_COMPANY_FAILURE: 'CREATE_COMPANY_FAILURE',

  GET_COMPANY_BEGIN: 'GET_COMPANY_BEGIN',
  GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
  GET_COMPANY_FAILURE: 'GET_COMPANY_FAILURE',

  UPDATE_COMPANY_BEGIN: 'UPDATE_COMPANY_BEGIN',
  UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_FAILURE: 'UPDATE_COMPANY_FAILURE',

  CLEAN_CURRENT_COMPANY: 'CLEAN_CURRENT_COMPANY',

  UPDATE_COMPANY_COMMENT_BEGIN: 'UPDATE_COMPANY_COMMENT_BEGIN',
  UPDATE_COMPANY_COMMENT_SUCCESS: 'UPDATE_COMPANY_COMMENT_SUCCESS',
  UPDATE_COMPANY_COMMENT_FAILURE: 'UPDATE_COMPANY_COMMENT_FAILURE',

  DOWNLOAD_COMPANIES_BEGIN: 'DOWNLOAD_COMPANIES_BEGIN',
  DOWNLOAD_COMPANIES_SUCCESS: 'DOWNLOAD_COMPANIES_SUCCESS',
  DOWNLOAD_COMPANIES_FAILURE: 'DOWNLOAD_COMPANIES_FAILURE',
});

export const downloadCompanies = params => (dispatch, getState) =>
  fetch({
    service: services.companies.downloadAll(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.DOWNLOAD_COMPANIES_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.DOWNLOAD_COMPANIES_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.DOWNLOAD_COMPANIES_FAILURE, errors),
  });

export const updateComment = (id, comment) => (dispatch, getState) =>
  fetch({
    service: services.companies.updateComment(id, comment),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.UPDATE_COMPANY_COMMENT_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.UPDATE_COMPANY_COMMENT_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_COMPANY_COMMENT_FAILURE, errors),
  });

export const cleanCurrentCompany = () => ({
  type: ACTIONS_TYPE.CLEAN_CURRENT_COMPANY,
});

export const updateCompany = params => (dispatch, getState) =>
  fetch({
    service: services.companies.update(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.UPDATE_COMPANY_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.UPDATE_COMPANY_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_COMPANY_FAILURE, errors),
  });

export const getOneCompany = params => (dispatch, getState) =>
  fetch({
    service: services.companies.getOne(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_COMPANY_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_COMPANY_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_COMPANY_FAILURE, errors),
  });

export const createCompany = params => (dispatch, getState) =>
  fetch({
    service: services.companies.create(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.CREATE_COMPANY_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.CREATE_COMPANY_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.CREATE_COMPANY_FAILURE, errors),
  });

export const getCompanies = params => (dispatch, getState) =>
  fetch({
    service: services.companies.get(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_COMPANIES_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_COMPANIES_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_COMPANIES_FAILURE, errors),
    validator: companiesArrayValidator,
  });
