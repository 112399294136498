const admins = {
  get: ({ onlyActive, search, page, size }) => ({
    url: '/admins',
    method: 'get',
    params: { onlyActive: onlyActive, search: search, page: page, size: size },
  }),
  getOne: id => ({
    url: `/admins/${id}`,
    method: 'get',
  }),
  update: ({
    id,
    firstName,
    lastName,
    email,
    phone,
    position,
    calendly,
    photo = null,
    login,
    password = null,
    superAdmin,
    active,
  }) => ({
    url: `/admins/${id}`,
    method: 'put',
    data: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      position: position,
      calendly: calendly,
      photo: photo,
      login: login,
      password: password,
      superAdmin: superAdmin,
      active: active,
    },
  }),
  create: ({
    firstName,
    lastName,
    email,
    phone,
    position,
    calendly,
    photo = null,
    login,
    password = null,
    superAdmin,
    active,
  }) => ({
    url: `/admins`,
    method: 'post',
    data: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      position: position,
      calendly: calendly,
      photo: photo,
      login: login,
      password: password,
      superAdmin: superAdmin,
      active: active,
    },
  }),
};

export default admins;
