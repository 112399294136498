import { useState, useEffect } from 'react';

import {
  studentJobTypeId,
  internshipTypeId,
  workTypeId,
  workAndStudyTypeId,
} from 'utils/constants';

import { Button, Box, withStyles, Divider } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import Dialog from 'components/Dialog';

import OfferMainFields from 'pages/JobBoardsPosting/Letudiant/Form/offerMain';
import OfferFields from 'pages/JobBoardsPosting/Letudiant/Form/offer';
import LocalizationFields from 'pages/JobBoardsPosting/Letudiant/Form/localization';
import DurationFields from 'pages/JobBoardsPosting/Letudiant/Form/duration';
import SalaryFields from 'pages/JobBoardsPosting/Letudiant/Form/salary';
import IndexationFields from 'pages/JobBoardsPosting/Letudiant/Form/IndexationFields';

import S from '../styles';

function Form(props) {
  const { values, handleSubmit, submitForm, classes, isSubmitting } = props;

  const [open, setOpen] = useState(false);

  const [showStudentJobPart, setShowStudentJobPart] = useState(false);
  const [showInternshipPart, setShowInternshipPart] = useState(false);
  const [showWorkPart, setShowWorkPart] = useState(false);
  const [showWorkAndStudyPart, setShowWorkAndStudyPart] = useState(false);

  const offerTypes = values.offerType;
  useEffect(
    () => {
      if (offerTypes.length > 0) {
        setShowStudentJobPart(
          offerTypes.find(type => type.value === studentJobTypeId)
            ? true
            : false
        );
        setShowInternshipPart(
          offerTypes.find(type => type.value === internshipTypeId)
            ? true
            : false
        );
        setShowWorkPart(
          offerTypes.find(type => type.value === workTypeId) ? true : false
        );
        setShowWorkAndStudyPart(
          offerTypes.find(type => type.value === workAndStudyTypeId)
            ? true
            : false
        );
      }
    },
    [offerTypes]
  );

  /*
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }
*/
  return (
    <form onSubmit={handleSubmit} /* onKeyDown={onKeyDown}*/>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <OfferMainFields {...props} />

        <Box className={classes.row} style={{ marginTop: '20px' }}>
          <Box className={classes.column}>
            <LocalizationFields {...props} />
          </Box>
          <Box className={classes.row}>
            <Divider className={classes.divider} orientation="vertical" />
          </Box>
          <Box className={classes.column}>
            <DurationFields
              {...props}
              showInternshipPart={showInternshipPart}
              showWorkAndStudyPart={showWorkAndStudyPart}
            />
          </Box>
        </Box>

        <Box className={classes.row} style={{ marginTop: '20px' }}>
          <SalaryFields
            {...props}
            showStudentJobPart={showStudentJobPart}
            showInternshipPart={showInternshipPart}
            showWorkPart={showWorkPart}
            showWorkAndStudyPart={showWorkAndStudyPart}
          />
        </Box>

        <Box style={{ marginTop: '20px' }}>
          <IndexationFields
            {...props}
            showStudentJobPart={showStudentJobPart}
            showInternshipPart={showInternshipPart}
            showWorkPart={showWorkPart}
            showWorkAndStudyPart={showWorkAndStudyPart}
          />
        </Box>

        <Box style={{ marginTop: '20px' }}>
          <OfferFields {...props} />
        </Box>

        <Box component="div" className={classes.actionFields}>
          <Box className={classes.rightFields}>
            <Button color="primary" onClick={() => setOpen(true)}>
              Publier
            </Button>
          </Box>
        </Box>
      </MuiPickersUtilsProvider>
      <Dialog
        open={open}
        title="Confirmation de publication"
        contentText="Souhaites-tu vraiment publier cette offre ?"
        confirmText="Oui"
        cancelText="Non"
        onConfirm={() => {
          setOpen(false);
          if (!isSubmitting) {
            submitForm();
          }
        }}
        onCancel={() => setOpen(false)}
      />
    </form>
  );
}

export default withStyles(S)(Form);
