import PropTypes from 'prop-types';
import { useQueryState } from 'utils/hooks/queryState';

import Checkbox from 'components/core/input/Checkbox';

CheckboxFilter.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.bool,
  onChange: PropTypes.func,
};
CheckboxFilter.defaultProps = {
  onChange: () => {},
};

function CheckboxFilter({ name, label, defaultValue, onChange }) {
  const [value, setValue] = useQueryState(defaultValue || null, name);

  const handleChange = ({ value }) => {
    value = value ? 1 : null;
    setValue(value);
    onChange({ name: name, value: value });
  };

  return <Checkbox label={label} value={value} onChange={handleChange} />;
}

export default CheckboxFilter;
