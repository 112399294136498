import { Fragment, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'utils/i18n';
import initStore from 'redux/store';

import App from './App';

//ReactDOM.render(<App />, document.getElementById('root'));

const store = initStore();
const root = document.getElementById('root');
const renderApp = () => {
  if (root)
    ReactDOM.render(
      <Fragment>
        <Provider store={store}>
          <Suspense fallback="loading">
            <App />
          </Suspense>
        </Provider>
      </Fragment>,
      root
    );
};

renderApp(App);

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept('./App.js', () => {
    const NextApp = require('./App').default;
    renderApp(NextApp);
  });
}
