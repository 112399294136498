import { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useQueryState } from 'utils/hooks/queryState';
import Autocomplete from 'components/core/input/Autocomplete';

const AutocompleteFilter = ({
  name,
  label,
  defaultValue,
  options,
  onChange,
}) => {
  const qs = useQuery();
  const [query, setQuery] = useQueryState(qs.get(name) || defaultValue, name);
  const [value, setValue] = useState(null);

  const didMount = useRef(false);
  useEffect(
    () => {
      if (options) {
        if (didMount.current) {
          return;
        }

        const defaultOptionValue = query || defaultValue || null;
        const defaultOptions = defaultOptionValue
          ? options.filter(
              option =>
                option.value.toString() === defaultOptionValue.toString()
            )
          : null;

        setValue(defaultOptions && defaultOptions[0]);
        setQuery(defaultOptionValue);

        didMount.current = true;
      }
    },
    [options, query, defaultValue, setQuery]
  );

  const handleChange = ({ value }) => {
    const queryValue = value ? value.value : null;
    setQuery(queryValue);
    setValue(value);
    onChange({ value: queryValue, name: name });
  };

  return (
    <div style={{ width: '100%' }}>
      <Autocomplete
        label={label}
        value={value}
        onChange={handleChange}
        options={options}
      />
    </div>
  );
};

AutocompleteFilter.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.number || PropTypes.string,
  options: PropTypes.array,
};
AutocompleteFilter.defaultProps = {
  options: [],
};

export default memo(AutocompleteFilter);
