import { useEffect, useRef, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { columns, format, fetchCandidates } from './helper';
import { useQuery } from 'utils/hooks/queryState';

import { InlineContainer } from 'components/core/containers';
import { SearchFilter } from 'components/core/FilterInput';
import Table from 'components/core/Table';
import Pagination from 'components/core/Pagination';
import CandidateDetail from './Detail';
import AnonymizeButton from './AnonymizeButton';
import Filters from './Filters';
import { ROUTES } from 'utils/routes';
import CreateButton from 'components/Table/Actions/CreateButton';

function CandidateTable() {
  const dispatch = useDispatch();
  const qs = useQuery();
  const didMount = useRef(false);

  const refreshCandidates = useCallback(
    (params = {}) => {
      fetchCandidates(params, dispatch, qs);
    },
    [dispatch, qs]
  );

  useEffect(
    () => {
      if (didMount.current === false) {
        refreshCandidates();
        didMount.current = true;
      }
    },
    [refreshCandidates]
  );

  const candidates = useSelector(state => state.candidates.items);
  const formattedCandidates = useMemo(
    () => {
      return format(candidates);
    },
    [candidates]
  );

  const candidateCount = useSelector(state => state.candidates.totalItemCount);

  return (
    <>
      <InlineContainer align="bottom">
        <Filters onChange={refreshCandidates} />
        <SearchFilter onChange={refreshCandidates} />
        <CreateButton pathname={ROUTES.CANDIDATE_CREATE} />
      </InlineContainer>
      <Table
        columns={columns}
        rows={formattedCandidates}
        detail={CandidateDetail}
        actions={actions}
      />
      <Pagination itemCount={candidateCount} onChange={refreshCandidates} />
    </>
  );
}

const actions = props => (
  <>
    <AnonymizeButton id={props.id} />
  </>
);

export default CandidateTable;
