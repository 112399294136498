import PropTypes from 'prop-types';
import { Checkbox } from 'components/core/input';
import Td from '../Td';

SelectCell.propTypes = {
  id: PropTypes.number,
  selectedRows: PropTypes.array,
  onChange: PropTypes.func,
};

SelectCell.defaultProps = {
  id: null,
  selectedRows: [],
  onChange: null,
};

function SelectCell({ id, selectedRows, onChange }) {
  const handleSelectRow = ({ name, value }) => {
    onChange({ id: name, value });
  };

  const checked = selectedRows.map(row => parseInt(row)).includes(id);

  return (
    <Td>
      <Checkbox name={id} value={checked} onChange={handleSelectRow} />
    </Td>
  );
}

export default SelectCell;
