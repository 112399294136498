export const statusOptions = [
  { value: 'active', label: 'active' },
  { value: 'terminee', label: 'terminée' },
  { value: 'stand-by', label: 'stand-by' },
];

export const reasonOptions = [
  { value: 'placement', label: 'Candidat placé' },
  { value: 'ailleurs', label: 'Candidat pris ailleurs' },
  { value: 'abandon', label: 'Offre abandonnée' },
];
