import { cddId, cdiId } from 'utils/constants';

export const langOptions = [
  { value: 1, label: 'Français' },
  { value: 2, label: 'Anglais' },
];

export const initialProposal = {
  admin: null,
  offerType: null,
  contractType: null,
  company: null,
  provenance: null,
  contact: null,
  email: null,
  phone: null,
  label: null,
  fixedPrice: null,
  variablePrice: null,
  secondVariablePrice: null,
  status: null,
  plan: null,
  lang: langOptions[0],
  internalFixedPrice: null,
  internalVariablePrice: null,
  internalSecondVariablePrice: null,
  positionCount: null,
  isProspect: true,
  prospect: null,
  comment: null,
  specificTerms: null,
};
export const handleValues = values => {
  return {
    adminId: values.admin.value,
    planId: values.plan.value,
    companyId: values.company ? values.company.value : null,
    prospect: values.prospect,
    provenanceId: values.provenance ? values.provenance.value : null,
    contact: values.contact,
    email: values.email,
    phone: values.phone,
    label: values.label,
    fixedPrice: values.fixedPrice,
    variablePrice: values.variablePrice,
    contractTypeId: values.contractType.value,
    status: values.status.label,
    secondVariablePrice: values.secondVariablePrice,
    specificTerms: values.specificTerms,
    lang: values.lang.value === 1 ? 'fr' : 'en',
    internalFixedPrice: values.internalFixedPrice,
    internalVariablePrice: values.internalVariablePrice,
    internalSecondVariablePrice: values.internalSecondVariablePrice,
    positionCount: values.positionCount,
    comment: values.comment,
  };
};

export const getDefaultPrices = contractTypeId => {
  if (contractTypeId === cddId || contractTypeId === cdiId) {
    return {
      fixedPrice: "2% du package brut annuel à l'embauche",
      variablePrice: "18% du package brut annuel à l'embauche",
      secondVariablePrice: null,
      internalFixedPrice: 700,
      internalVariablePrice: 6300,
      internalSecondVariablePrice: null,
    };
  } else {
    return {
      fixedPrice: '350€ HT',
      variablePrice: '1350€ HT',
      secondVariablePrice: "10% du package brut annuel à l'embauche",
      internalFixedPrice: 350,
      internalVariablePrice: 1350,
      internalSecondVariablePrice: 3000,
    };
  }
};
