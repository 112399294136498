// @flow
export const isNumber = (value: any): boolean =>
  typeof value === 'number' && !isNaN(value);

export const isString = (value: any): boolean =>
  typeof value === 'string' || value instanceof String;

export const toString = (value: any): string =>
  value === undefined || value === null ? '' : String(value);

export const isInteger = (value: any): boolean => value === parseInt(value, 10);

export const isBoolean = (value: any): boolean =>
  value === true || value === false;

export const toBoolean = (value: any): boolean => {
  if (value === true || value === false) return value;
  if (value === 1 || value === 'true') return true;
  if (value === 0 || value === 'false') return false;
  return false;
};

const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const isEmail = (value: any): boolean => {
  return isString(value) && emailRegex.test(value);
};

const dateRegex = /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d?(?:\.?\d?\d?\d?)[+-](?:2[0-3]|[01][0-9]):[0-5][0-9]/i;

export const isUtcDate = (value: any): boolean => {
  return isString(value) && dateRegex.test(value);
};
