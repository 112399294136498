import styled from 'styled-components';
import { theme } from 'utils/theme';

const Td = styled.td`
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding: 10px;
  font-size: ${theme.typography.fontSize + 1}px;
  font-family: ${theme.typography.fontFamily};
  position: relative;
  ${props =>
    props.isStrikeThrough
      ? `&:before {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 0;
      border-bottom: 1px solid black;
      width: 100%;
    }`
      : ''};
`;

export default Td;
