import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

import { Box, Typography, Tooltip, withStyles } from '@material-ui/core';

import S from './styles';

function Summary(props) {
  const { summary, classes } = props;
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="subtitle1" className={classes.row}>
        <Tooltip title="Somme des 1ers variables des offres qui ne possèdent pas de date de placement. Le 2nd variable est ajouté à la somme pour les offres de type CDI. Depuis le début.">
          <Box>{t('paf.summary.backlog')} </Box>
        </Tooltip>

        <b>
          <NumberFormat
            value={summary ? summary.backlog : 0}
            thousandSeparator={' '}
            suffix={'€'}
            displayType={'text'}
          />
        </b>
      </Typography>
      <Typography variant="subtitle1" className={classes.row}>
        <Tooltip title="Somme des 1ers variables qui ne sont pas encore facturés pour les offres qui possèdent une date de placement. Depuis le début.">
          <Box>{t('paf.summary.paf')} </Box>
        </Tooltip>
        <b>
          <NumberFormat
            value={summary ? summary.paf : 0}
            thousandSeparator={' '}
            suffix={'€'}
            displayType={'text'}
          />
        </b>
      </Typography>
      <Typography variant="subtitle1" className={classes.row}>
        <Tooltip title="Somme des 2nds variables qui n'ont pas encore été facturés pour les offres non terminées. Depuis le début.">
          <Box>{t('paf.summary.renewed')} </Box>
        </Tooltip>
        <b>
          <NumberFormat
            value={summary ? summary.renewed : 0}
            thousandSeparator={' '}
            suffix={'€'}
            displayType={'text'}
          />
        </b>
      </Typography>
      <Typography variant="subtitle1" className={classes.row}>
        <Tooltip title="Somme des frais de lancement, des 1ers et 2nds variables facturés. Pour l'année en cours.">
          <Box>{t('paf.summary.invoiced')} </Box>
        </Tooltip>
        <b>
          <NumberFormat
            value={
              summary
                ? summary.invoicedFixedCosts +
                  summary.invoicedFirstVariable +
                  summary.invoicedSecondVariable
                : 0
            }
            thousandSeparator={' '}
            suffix={'€'}
            displayType={'text'}
          />
        </b>
      </Typography>

      <Typography variant="subtitle1">
        {t('paf.summary.nb-pafs')} <b>{summary ? summary.count_paf : ''}</b>
      </Typography>
      <Typography variant="subtitle1">
        {t('paf.summary.nb-startings')}{' '}
        <b>{summary ? summary.count_starting : ''}</b>
      </Typography>
      <Typography variant="subtitle1">
        {t('paf.summary.nb-pafs-no-invoiced')}{' '}
        <b>{summary ? summary.count_not_invoiced : ''}</b>
      </Typography>
      <Typography variant="subtitle1">
        {t('paf.summary.nb-total-pafs-no-invoiced')}{' '}
        <b>{summary ? summary.count_total_not_invoiced : ''}</b>
      </Typography>
    </Box>
  );
}

export default withStyles(S)(Summary);
