import styled from 'styled-components';

export default function IconButton({ onClick, icon: Icon }) {
  return (
    <Button onClick={onClick}>
      <Container>
        <Icon />
      </Container>
    </Button>
  );
}

const Button = styled.button`
  background: transparent;
  border: 0px;
  cursor: pointer;
`;

const Container = styled.div`
  > * {
    vertical-align: middle;
  }
`;
