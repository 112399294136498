import BaseButton from './baseButton';

export default function MessageButton(props) {
  const { value } = props;

  const msg = `Souhaites-tu indiquer au candidat que tu as essayé de le contacter ?
        Un mail sera envoyé au candidat.`;

  return (
    <BaseButton
      title="Tentative de contact"
      label="Message"
      message={msg}
      value={value}
      color="primary"
      action={props.action}
      disabled={props.disabled}
    />
  );
}
