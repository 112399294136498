import { Link as BaseLink } from 'react-router-dom';
import styled from 'styled-components';

const style = `
  color: black;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }`;

const RelativeLink = styled(BaseLink)`
  ${style};
`;
const ExternalLink = styled.a`
  ${style};
`;

const Link = ({ to, children, blank, external }) => {
  const target = blank ? '_blank' : '';

  if (external) {
    return (
      <ExternalLink href={to} target={target}>
        {children}
      </ExternalLink>
    );
  } else {
    return (
      <RelativeLink to={to} target={target}>
        {children}
      </RelativeLink>
    );
  }
};

export default Link;
