const creditNotes = {
  get: ({
    page = null,
    size = null,
    filter = null,
    orderBy = null,
    direction = null,
  }) => ({
    url: '/creditNotes',
    method: 'get',
    params: {
      page,
      size,
      filter,
      orderBy,
      direction,
    },
  }),
  getOne: ({ id }) => ({
    url: `/creditNotes/${id}`,
    method: 'get',
  }),
  add: ({ invoiceId, amount, tva }) => ({
    url: '/creditNotes',
    method: 'post',
    data: {
      invoiceId,
      amount,
      tva,
    },
  }),
  update: ({ id, invoiceId, amount, tva }) => ({
    url: `/creditNotes/${id}`,
    method: 'put',
    data: {
      invoiceId,
      amount,
      tva,
    },
  }),
  delete: ({ id }) => ({
    url: `/creditNotes/${id}`,
    method: 'delete',
  }),
  downloadOne: ({ id }) => ({
    url: `/download/creditNotes/${id}`,
    method: 'get',
    responseType: 'blob',
  }),
  send: ({ id, sent }) => ({
    url: `/send/creditNotes/${id}`,
    method: 'put',
    params: {
      sent,
    },
  }),
  download: () => ({
    url: '/download/creditNotes',
    method: 'get',
    responseType: 'blob',
  }),
};

export default creditNotes;
