import moment from 'moment-timezone';
import {
  lightBlueFontCode,
  darkBlueFontCode,
  strikeThrough,
  redFontCode,
  recruitmentPlanId,
} from 'utils/constants';
import { ROUTES } from 'utils/routes';

export const placementStyle = lightBlueFontCode;
export const invoicedStyle = darkBlueFontCode;
export const shouldBeInvoicedStyle = redFontCode;
export const abandonedOfferStyle = strikeThrough;

/*
 * Format paf table
 * If checkContractChanged is true we check if contractChangeDate is set to add invoicedStyle as backgroundColor 
 */
export const pafTableDataFormat = (
  pafs,
  checkContractChanged = false,
  month = null,
  year = null
) =>
  pafs.map(paf => {
    const pafTableDataFormat = Object.assign({}, paf);

    pafTableDataFormat.companyLogo = {
      label: paf.company.label,
      img: paf.company.url,
      url: ROUTES.COMPANY_SHOW.replace(':id', paf.company.value),
    };
    pafTableDataFormat.companyLink = {
      label: paf.company.label,
      url: ROUTES.COMPANY_SHOW.replace(':id', paf.company.value),
    };
    pafTableDataFormat.offerLink = {
      label: paf.offer && paf.offer.label,
      url: ROUTES.OFFER_SHOW.replace(':id', paf.offer && paf.offer.value),
    };
    pafTableDataFormat.candidateLink = {
      label: paf.candidate && paf.candidate.label,
      url:
        paf.candidate &&
        ROUTES.CANDIDATE_SHOW.replace(':id', paf.candidate.value),
    };

    if (month && year && paf.contractChangeDate) {
      const secondInvoiceDate = moment(paf.contractChangeDate);
      if (
        parseInt(month) === parseInt(secondInvoiceDate.format('M')) &&
        parseInt(year) === parseInt(secondInvoiceDate.format('Y'))
      ) {
        pafTableDataFormat.invoiceDate = paf.contractChangeDate;
      }
    }

    // Set styles for paf lines depending of line status
    if (paf.abandonedOffer) {
      pafTableDataFormat.isStrikeThrough = true;
      return pafTableDataFormat;
    }

    if (paf.plan && paf.plan.value !== recruitmentPlanId) {
      return pafTableDataFormat;
    }

    if (
      (checkContractChanged && paf.contractChangeDate) ||
      (!checkContractChanged && paf.invoiceDate)
    ) {
      pafTableDataFormat.backgroundColor = invoicedStyle;
      return pafTableDataFormat;
    }

    if (paf.pafDate && !checkContractChanged) {
      pafTableDataFormat.backgroundColor = placementStyle;
    }

    if (
      (!paf.invoiceDate &&
        paf.candidateStartDate &&
        new Date(paf.candidateStartDate) < new Date()) ||
      (checkContractChanged &&
        !paf.completedOffer &&
        paf.secondVariableDate &&
        new Date(paf.secondVariableDate) < new Date())
    ) {
      pafTableDataFormat.backgroundColor = shouldBeInvoicedStyle;
    }

    return pafTableDataFormat;
  });
