import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  addApplication,
  appointmentRequest,
  notifyContactAttempt,
  notifyResumeUpdateRequired,
  suggestOffer,
  switchApplicationState,
  addApplicationComment,
  updateApplicationComment,
  deleteApplicationComment,
} from 'redux/offers/actions';
import ResearchInfo from './ResearchInfo';
import ApplicationState from 'components/eiquem/application/ApplicationState';
import ApplicationComment from 'components/eiquem/application/ApplicationComment';
import { Grid } from '@material-ui/core';

Detail.propTypes = {
  type: PropTypes.oneOf(['active', 'completed', 'suggestion']),
};
Detail.defaultProps = {
  type: 'application',
};

function Detail({ row, type, offerId, cleanSelectedRows, planId }) {
  const dispatch = useDispatch();

  const application = (type !== 'suggestion' && row) ?? null;
  const suggestion = (type === 'suggestion' && row) ?? null;

  const handleStateChange = (state, reason, prefilledPlacement) => {
    if (application) {
      dispatch(
        switchApplicationState({
          applicationId: application.id,
          code: state,
          reason: reason,
          prefilledPlacement,
        })
      );
    } else if (suggestion) {
      dispatch(
        addApplication(
          suggestion.research.id,
          offerId,
          state,
          suggestion && suggestion.preSuggestion
        )
      );
    }
    cleanSelectedRows && cleanSelectedRows();
  };
  const handleContactAttempt = () => {
    if (application) {
      dispatch(notifyContactAttempt({ applicationId: application.id }));
    }
  };
  const handleResumeUpdateRequest = () => {
    if (application) {
      dispatch(notifyResumeUpdateRequired({ applicationId: application.id }));
    }
  };
  const handleAppointmentRequest = () => {
    if (application) {
      dispatch(appointmentRequest({ applicationId: application.id }));
    }
  };
  const handleSuggestOffer = () => {
    if (suggestion) {
      dispatch(
        suggestOffer({
          researchId: suggestion.research.id,
          offerId: offerId,
        })
      );
    }
  };

  return (
    <Grid container spacing={2} style={{ overflow: 'hidden' }}>
      <Grid item md={12} xs={12}>
        <ApplicationState
          application={application || null}
          suggestion={suggestion || null}
          preSuggestion={(suggestion && suggestion.preSuggestion) || null}
          onStateChange={handleStateChange}
          onContactAttempt={handleContactAttempt}
          onResumeUpdateRequest={handleResumeUpdateRequest}
          onSuggestOffer={handleSuggestOffer}
          onAppointmentRequest={handleAppointmentRequest}
          offerId={offerId}
          planId={planId}
        />
      </Grid>

      <Grid item md={2} xs={12}>
        <ResearchInfo
          research={application ? application.research : suggestion.research}
        />
      </Grid>
      <Grid item md={10} xs={12}>
        {application && (
          <ApplicationComment
            applicationId={application.id}
            values={application.comments}
            onAdd={addApplicationComment}
            onUpdate={updateApplicationComment}
            onDelete={deleteApplicationComment}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default Detail;
