import { ACTIONS_TYPE, getFollowUps } from './actions';

export const followUpsMiddleware = ({ dispatch }) => next => action => {
  if (!action) {
    return;
  }
  if (
    action.type === ACTIONS_TYPE.UPDATE_FOLLOW_UP_SUCCESS ||
    action.type === ACTIONS_TYPE.REMIND_AND_POSTPONE_FOLLOW_UP_SUCCESS
  ) {
    console.log('followUpsMiddleware called...');
    console.log('get followUps...');

    const qs = new URLSearchParams(window.location.search);
    const params = {
      month: Number.parseInt(qs.get('month')),
      year: Number.parseInt(qs.get('year')),
      adminId: Number.parseInt(qs.get('admin')) || null,
      adminType: qs.get('adminType') || null,
    };
    console.log('params', params);
    dispatch(getFollowUps(params));
  }

  next(action);
};
