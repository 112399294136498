import { ACTIONS_TYPE } from './actions';

export const initialState = {
  loading: false,
  totalItemCount: 0,
  items: [],
  current: null,
  followUps: null,
};

export function candidatesReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPE.GET_CANDIDATE_BEGIN:
    case ACTIONS_TYPE.GET_CANDIDATES_BEGIN:
    case ACTIONS_TYPE.UPDATE_CANDIDATE_PROFILE_BEGIN:
    case ACTIONS_TYPE.GET_RESEARCHES_BEGIN:
    case ACTIONS_TYPE.UPDATE_RESEARCH_BEGIN:
    case ACTIONS_TYPE.UPDATE_RESEARCH_COMMENTS_BEGIN:
    case ACTIONS_TYPE.UPDATE_RESEARCH_APPLICATION_BEGIN:
    case ACTIONS_TYPE.ACTIVE_RESEARCH_CONFIRMATION_BEGIN:
    case ACTIONS_TYPE.ANONYMIZE_CANDIDATE_BEGIN:
    case ACTIONS_TYPE.GET_RESEARCH_APPLICATIONS_BEGIN:
    case ACTIONS_TYPE.SUGGEST_OFFER_BEGIN:
    case ACTIONS_TYPE.ADD_CANDIDATE_APPLICATION_BEGIN:
    case ACTIONS_TYPE.SWITCH_CANDIDATE_APPLICATION_BEGIN:
    case ACTIONS_TYPE.APPLICATION_CONTACT_ATTEMPT_BEGIN:
    case ACTIONS_TYPE.CANDIDATE_CONTACT_ATTEMPT_BEGIN:
    case ACTIONS_TYPE.CANDIDATE_RESUME_UPDATE_BEGIN:
    case ACTIONS_TYPE.ADD_APPLICATION_COMMENT_BEGIN:
    case ACTIONS_TYPE.UPDATE_APPLICATION_COMMENT_BEGIN:
    case ACTIONS_TYPE.DELETE_APPLICATION_COMMENT_BEGIN:
    case ACTIONS_TYPE.PLACEMENT_CONTACT_ATTEMPT_BEGIN:
    case ACTIONS_TYPE.UPDATE_RESUME_BEGIN:
    case ACTIONS_TYPE.UPDATE_COVER_LETTER_BEGIN:
    case ACTIONS_TYPE.APPOINTMENT_REQUEST_BEGIN:
    case ACTIONS_TYPE.ADD_CANDIDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_CANDIDATE_FAILURE:
    case ACTIONS_TYPE.GET_CANDIDATES_FAILURE:
    case ACTIONS_TYPE.UPDATE_CANDIDATE_PROFILE_FAILURE:
    case ACTIONS_TYPE.GET_RESEARCHES_FAILURE:
    case ACTIONS_TYPE.UPDATE_RESEARCH_FAILURE:
    case ACTIONS_TYPE.UPDATE_RESEARCH_COMMENTS_FAILURE:
    case ACTIONS_TYPE.UPDATE_RESEARCH_APPLICATION_FAILURE:
    case ACTIONS_TYPE.CONTACT_ATTEMPT_FAILURE:
    case ACTIONS_TYPE.ACTIVE_RESEARCH_CONFIRMATION_FAILURE:
    case ACTIONS_TYPE.ACTIVE_RESEARCH_CONFIRMATION_SUCCESS:
    case ACTIONS_TYPE.ANONYMIZE_CANDIDATE_FAILURE:
    case ACTIONS_TYPE.UPDATE_RESEARCH_APPLICATION_SUCCESS:
    case ACTIONS_TYPE.GET_RESEARCH_APPLICATIONS_FAILURE:
    case ACTIONS_TYPE.SUGGEST_OFFER_SUCCESS:
    case ACTIONS_TYPE.SUGGEST_OFFER_FAILURE:
    case ACTIONS_TYPE.ADD_CANDIDATE_APPLICATION_SUCCESS:
    case ACTIONS_TYPE.ADD_CANDIDATE_APPLICATION_FAILURE:
    case ACTIONS_TYPE.SWITCH_CANDIDATE_APPLICATION_FAILURE:
    case ACTIONS_TYPE.APPLICATION_CONTACT_ATTEMPT_SUCCESS:
    case ACTIONS_TYPE.APPLICATION_CONTACT_ATTEMPT_FAILURE:
    case ACTIONS_TYPE.CANDIDATE_CONTACT_ATTEMPT_SUCCESS:
    case ACTIONS_TYPE.CANDIDATE_CONTACT_ATTEMPT_FAILURE:
    case ACTIONS_TYPE.CANDIDATE_RESUME_UPDATE_SUCCESS:
    case ACTIONS_TYPE.CANDIDATE_RESUME_UPDATE_FAILURE:
    case ACTIONS_TYPE.ADD_APPLICATION_COMMENT_FAILURE:
    case ACTIONS_TYPE.UPDATE_APPLICATION_COMMENT_SUCCESS:
    case ACTIONS_TYPE.UPDATE_APPLICATION_COMMENT_FAILURE:
    case ACTIONS_TYPE.DELETE_APPLICATION_COMMENT_SUCCESS:
    case ACTIONS_TYPE.DELETE_APPLICATION_COMMENT_FAILURE:
    case ACTIONS_TYPE.SWITCH_CANDIDATE_APPLICATION_SUCCESS:
    case ACTIONS_TYPE.PLACEMENT_CONTACT_ATTEMPT_SUCCESS:
    case ACTIONS_TYPE.PLACEMENT_CONTACT_ATTEMPT_FAILURE:
    case ACTIONS_TYPE.UPDATE_RESUME_SUCCESS:
    case ACTIONS_TYPE.UPDATE_RESUME_FAILURE:
    case ACTIONS_TYPE.UPDATE_COVER_LETTER_SUCCESS:
    case ACTIONS_TYPE.UPDATE_COVER_LETTER_FAILURE:
    case ACTIONS_TYPE.APPOINTMENT_REQUEST_SUCCESS:
    case ACTIONS_TYPE.APPOINTMENT_REQUEST_FAILURE:
    case ACTIONS_TYPE.ADD_CANDIDATE_SUCCESS:
    case ACTIONS_TYPE.ADD_CANDIDATE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case ACTIONS_TYPE.UPDATE_CANDIDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.map(
          candidate =>
            candidate.id === action.result.id
              ? { ...candidate, profile: action.result.profile }
              : { ...candidate }
        ),
      };
    case ACTIONS_TYPE.GET_CANDIDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        totalItemCount: action.result.totalItemCount,
        items: action.result.candidates || [],
      };
    case ACTIONS_TYPE.ANONYMIZE_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.map(
          item => (action.result.id === item.id ? action.result : item)
        ),
      };
    case ACTIONS_TYPE.GET_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        current: { ...state.current, ...action.result },
      };
    case ACTIONS_TYPE.GET_RESEARCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        current: {
          ...state.current,
          researches: action.result.researches,
        },
      };
    case ACTIONS_TYPE.CLEAN_CURRENT_CANDIDATE:
      return {
        ...state,
        current: null,
      };
    case ACTIONS_TYPE.GET_RESEARCH_APPLICATIONS_SUCCESS:
      return {
        ...state,
        applications: action.result,
        loading: false,
      };
    case ACTIONS_TYPE.CLEAN_APPLICATIONS:
      return {
        ...state,
        applications: null,
      };
    case ACTIONS_TYPE.GET_CANDIDATE_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        followUps: action.result.followUps,
      };
    default:
      return state;
  }
}
