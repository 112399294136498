import { useTranslation } from 'react-i18next';
import { useFetchPlans } from 'utils/hooks/formOptions';
import { Autocomplete } from 'components/core/input';
import { useEffect } from 'react';

function Plan({ values, errors, touched, onChange }) {
  const { t } = useTranslation();
  const planOptions = usePlanOptions();

  return (
    <Autocomplete
      name="plan"
      options={planOptions}
      defaultValue={values.plan}
      label={t('paf.field.plan')}
      error={errors.plan}
      touched={touched.plan}
      onChange={onChange}
    />
  );
}

const usePlanOptions = () => {
  const [fetch, planOptions] = useFetchPlans();
  useEffect(
    () => {
      if (!planOptions) {
        fetch();
      }
    },
    [fetch, planOptions]
  );
  return planOptions;
};

export default Plan;
