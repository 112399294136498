import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, IconButton, withStyles } from '@material-ui/core';
import Dialog from 'components/Dialog';
import S from './styles';

import Tooltip from 'components/core/Tooltip';

ActionButton.defaultProps = {
  dialog: false,
  disabled: false,
  action: null,
  link: null,
  disableSubmit: false,
  interactive: false,
  tooltip: true,
  colorButton: 'primary',
  variantButton: 'outlined',
};

function ActionButton(props) {
  const {
    icon,
    action,
    link,
    label,
    dialog,
    title,
    contentText,
    confirmText,
    cancelText,
    dialogContent,
    onOpenDialog,
    disabled,
    disableSubmit,
    tooltipPosition,
    tooltipWidth,
    classes,
    colorButton,
    variantButton,
  } = props;

  const handleOnClick = () => {
    if (action === null) {
      return;
    }
    if (dialog) {
      setIsOpenDialog(true);
      if (typeof onOpenDialog === 'function') {
        onOpenDialog();
      }
    } else {
      action();
    }
  };

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const onConfirmDialog = () => {
    action();
    setIsOpenDialog(false);
  };

  const onCancelDialog = () => {
    setIsOpenDialog(false);
  };

  const actionButton = (
    <div className={classes.wrapper}>
      {icon ? (
        <IconButton
          component={link ? Link : IconButton}
          to={link}
          disableRipple
          size="small"
          className={classes.iconButton}
          onClick={handleOnClick}
          disabled={Boolean(disabled)}
        >
          {icon}
        </IconButton>
      ) : (
        <Button
          className={classes.button}
          variant={variantButton}
          color={colorButton}
          onClick={handleOnClick}
          disabled={disabled}
        >
          {props.children}
        </Button>
      )}
    </div>
  );

  return (
    <>
      <Tooltip text={label} position={tooltipPosition} width={tooltipWidth}>
        {actionButton}
      </Tooltip>
      {dialog && (
        <Dialog
          open={isOpenDialog}
          title={title}
          contentText={contentText}
          confirmText={confirmText}
          cancelText={cancelText}
          onConfirm={onConfirmDialog}
          onCancel={onCancelDialog}
          disableSubmit={disableSubmit}
        >
          {dialogContent}
        </Dialog>
      )}
    </>
  );
}

export default withStyles(S)(ActionButton);
