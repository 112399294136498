import styled from 'styled-components';
import { theme } from 'utils/theme';

const Label = styled.label`
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize + 1}px;
  ${({ isTitle }) =>
    isTitle
      ? `font-size: ${theme.typography.fontSize + 1}px; font-weight: 800;`
      : null};
  white-space: nowrap;
`;

export default Label;
