import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { persistHistoryState } from 'utils/functions';

import { Tabs, Tab } from '@material-ui/core';
import TabPanel from 'components/TabPanel';
import InvoiceTable from 'components/Tables/InvoiceTable';

function Detail() {
  const { t } = useTranslation();

  const history = useHistory();
  const historyState = history.location.state ? history.location.state : {};
  const handleHistory = state => {
    persistHistoryState(state);
  };

  // Value: all, paid or unpaid
  const [tabValue, setTabValue] = useState(
    historyState.tabValue ? historyState.tabValue : 'all'
  );

  const handleTabChange = (event, newValue) => {
    handleHistory({ tabValue: newValue });
    setTabValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label={t('invoicing.invoices.detail.all')} value="all" />
        <Tab label={t('invoicing.invoices.detail.paid')} value="paid" />
        <Tab label={t('invoicing.invoices.detail.unpaid')} value="unpaid" />
      </Tabs>
      <TabPanel value={tabValue} index="all">
        <InvoiceTable status="all" />
      </TabPanel>
      <TabPanel value={tabValue} index="paid">
        <InvoiceTable status="paid" />
      </TabPanel>
      <TabPanel value={tabValue} index="unpaid">
        <InvoiceTable status="unpaid" />
      </TabPanel>
    </div>
  );
}

export default Detail;
