export const initialOffer = {
  admin: null,
  secondaryAdmins: [],
  label: '',
  company: null,
  invoicingCompany: null,
  contactName: null,
  familiarity: false,
  sample: null,
  recruiter: null,
  offerType: null,
  fixedPrice: null,
  firstVariable: null,
  isSecondVariable: true,
  secondVariable: null,
  contractTypes: [],
  durationMin: null,
  durationMax: null,
  startDateMin: null,
  startDateMax: null,
  region: null,
  studyLevels: [],
  functions: [],
  functionsV2: [],
  location: null,
  regions: null,
  salaryType: 0,
  salary: null,
  description: '',
  profiles: null,
  comment: null,
  emails: [],
  vacantPositions: 1,
  tags: [],
  cities: [],
  teleworking: null,
  recruitmentProcess: null,
  redirectUrl: null,
};

export const handleFormData = data => ({
  id: data.id || null,
  adminId: data.admin.value,
  secondaryAdminIds: data.secondaryAdmins.map(admin => admin.value),
  label: data.label,
  companyId: data.company.value,
  invoicingCompany: data.invoicingCompany,
  recruiterId: data.recruiter ? data.recruiter.value : null,
  contactName: data.contactName,
  familiarity: data.familiarity,
  emails: data.emails.map(email => email.label).join(','),
  offerTypeId: data.offerType.value,
  contractTypeIds: data.contractTypes.map(type => type.value),
  durationMin: data.durationMin,
  durationMax: data.durationMax,
  fixedPrice: data.fixedPrice,
  variablePrices: data.variablePrices,
  startDateMin: data.startDateMin,
  startDateMax: data.startDateMax,
  studyLevelIds: data.studyLevels.map(studyLevel => studyLevel.value),
  salary:
    data.salaryType === 0
      ? data.salary + ' par mois'
      : data.salaryType === 1
        ? data.salary + ' par an'
        : data.salaryType === 2
          ? 'Selon profil'
          : data.salary,
  description: data.description,
  customerBrief: data.customerBrief,
  functionIds: data.functions ? data.functions.map(func => func.value) : null,
  vacantPositions: data.vacantPositions,
  tagIds: getTagIds(data.tags),
  otherTags: getOtherTags(data.tags),
  recruitmentProcess: data.recruitmentProcess,
  cityIds: data.cities ? data.cities.map(city => city.value) : null,
  teleworkingId: data.teleworking ? data.teleworking.value : null,
  comment: data.comment,
  recruitmentComment: data.recruitmentComment,
  externalWeeklyApplicationCount:
    parseInt(data.externalWeeklyApplicationCount) || 0,
  externalWeeklyCallCount: parseInt(data.externalWeeklyCallCount) || 0,
  externalWeeklyApproachCount: parseInt(data.externalWeeklyApproachCount) || 0,
  externalWeeklyAcceptedApproachCount:
    parseInt(data.externalWeeklyAcceptedApproachCount) || 0,
  externalWeeklyDeclinedApproachCount:
    parseInt(data.externalWeeklyDeclinedApproachCount) || 0,
  redirectUrl: data.redirectUrl,
});

const getTagIds = tags => {
  if (!tags) {
    return null;
  }
  const existingTags = tags.filter(tag => tag.value > 0);
  if (existingTags.length === 0) {
    return null;
  }
  return existingTags.map(tag => tag.value);
};

const getOtherTags = tags => {
  if (!tags) {
    return null;
  }
  const newTags = tags.filter(tag => tag.value < 0);
  if (newTags.length === 0) {
    return null;
  }
  return newTags.map(tag => tag.label);
};

export const getSalaryType = salaryBrut => {
  if (salaryBrut.search(' par mois') >= 0) {
    return 0;
  } else if (salaryBrut.search('par an') >= 0) {
    return 1;
  }
  return 2;
};

export const getSalaryValue = salaryBrut => {
  if (salaryBrut.search(' par mois') >= 0) {
    return salaryBrut.substring(0, salaryBrut.search(' par mois'));
  } else if (salaryBrut.search(' par an') >= 0) {
    return salaryBrut.substring(0, salaryBrut.search(' par an'));
  }
  return salaryBrut;
};
