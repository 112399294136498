import { useDispatch, useSelector } from 'react-redux';
import { updateApplicationFollowUp } from 'redux/applicationFollowUps/actions';
import ActionButton from 'components/ActionButton';
import { Checkbox } from 'components/core/input';
import { useState } from 'react';

export default function ApplicationFollowUpContactButton() {
  const dispatch = useDispatch();
  const applicationFollowUp = useSelector(
    state => state.applicationFollowUps.current
  );

  const [useFamiliarity, setFamiliarity] = useState(false);
  const handleFamiliarityChange = () => setFamiliarity(!useFamiliarity);

  const sendApplicationContactAttempt = () =>
    dispatch(
      updateApplicationFollowUp({
        id: applicationFollowUp.id,
        notifyAndPostpone: true,
        useFamiliarity,
      })
    );

  return (
    <>
      {applicationFollowUp && (
        <ActionButton
          label="Mail de tentative de contact pour suivi envoyé"
          dialog
          title="Suivi Envoyé"
          dialogContent={
            <>
              Un mail sera envoyé au candidat l'avertissant que tu as essayé de
              le contacter pour parler de sa candidature sans succès.
              <Checkbox label={'Tutoyer'} onChange={handleFamiliarityChange} />
            </>
          }
          action={sendApplicationContactAttempt}
          confirmText="Confirmer"
        >
          Suivi Envoyé
        </ActionButton>
      )}
    </>
  );
}
