const recruiters = {
  get: ({ companyId }) => ({
    url: `/companies/${companyId}/recruiters`,
  }),
  getOne: ({ recruiterId }) => ({
    url: `/recruiters/${recruiterId}`,
  }),
  update: ({
    id,
    firstName,
    lastName,
    mail,
    phone,
    position,
    role,
    password,
    active,
    companyId,
  }) => ({
    url: `/recruiters/${id}`,
    method: 'put',
    data: {
      id: id,
      firstName: firstName,
      lastName: lastName,
      mail: mail,
      phone: phone,
      position: position,
      role: role,
      password: password,
      active: active,
      companyId: companyId,
    },
  }),
  add: ({
    firstName,
    lastName,
    mail,
    phone,
    position,
    role,
    password,
    active,
    companyId,
  }) => ({
    url: `/recruiters`,
    method: 'post',
    data: {
      firstName: firstName,
      lastName: lastName,
      mail: mail,
      phone: phone,
      position: position,
      role: role,
      password: password,
      active: active,
      companyId: companyId,
    },
  }),
};

export default recruiters;
