import styled from 'styled-components';
import { theme } from 'utils/theme';

const Label = ({ title, subtitles, bottomItems }) => {
  return (
    <Container>
      <div style={{ flexDirection: 'column', flex: 1 }}>
        <div style={{ display: 'flex' }}>
          <Title>{title}</Title>
          <div style={{ flex: 1 }} />
          <div onClick={stopPropagation}>{subtitles}</div>
        </div>
        {bottomItems && (
          <BottomContainer onClick={stopPropagation}>
            {bottomItems}
          </BottomContainer>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Title = styled.span`
  font-size: ${theme.typography.fontSize + 7}px;
  align-self: center;
  user-select: text;
`;

const BottomContainer = styled.div`
  width: fit-content;
`;

const stopPropagation = e => {
  e.stopPropagation();
};

export default Label;
