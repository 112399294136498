import { lazy, Suspense, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import {
  getRegistrationsByProvenances,
  getHiresByProvenances,
  getSLByProvenances,
} from 'redux/statistics/actions';

import { getLabels, options, getDataSets } from 'pages/Statistics/constants';
import { isValidDate } from 'utils/functions';
import S from './styles';

import Main from 'components/layout/Main';
import Title from 'components/layout/Title';
import Subtitle from 'components/layout/Subtitle';
import { DatePicker } from 'components/core/input';

const Line = lazy(() => import('pages/Statistics/Line.js'));

function CandidatesStatistics() {
  const { t } = useTranslation();
  const [registrationsToDate, setRegistrationsToDate] = useState(moment());
  const [registrationsFromDate, setRegistrationsFromDate] = useState(
    registrationsToDate.clone().subtract(1, 'year')
  );
  const [hiresToDate, setHiresToDate] = useState(moment());
  const [hiresFromDate, setHiresFromDate] = useState(
    hiresToDate.clone().subtract(1, 'year')
  );

  const [slToDate, setSlToDate] = useState(moment());
  const [slFromDate, setSlFromDate] = useState(
    slToDate.clone().subtract(1, 'year')
  );
  const dispatch = useDispatch();
  useEffect(
    () => {
      if (
        isValidDate(registrationsFromDate) &&
        isValidDate(registrationsToDate)
      ) {
        dispatch(
          getRegistrationsByProvenances({
            fromDate: registrationsFromDate,
            toDate: registrationsToDate,
          })
        );
      }
    },
    [registrationsFromDate, registrationsToDate, dispatch]
  );

  useEffect(
    () => {
      if (isValidDate(hiresFromDate) && isValidDate(hiresToDate)) {
        dispatch(
          getHiresByProvenances({
            fromDate: hiresFromDate,
            toDate: hiresToDate,
          })
        );
      }
    },
    [hiresFromDate, hiresToDate, dispatch]
  );

  useEffect(
    () => {
      if (isValidDate(slFromDate) && isValidDate(slToDate)) {
        dispatch(
          getSLByProvenances({
            fromDate: slFromDate,
            toDate: slToDate,
          })
        );
      }
    },
    [slFromDate, slToDate, dispatch]
  );

  const statisticsState = useSelector(state => state.statistics);

  const registersData = {
    labels: getLabels(registrationsFromDate, registrationsToDate),
    datasets: getDataSets(statisticsState.candidates.registersByProvenances),
  };
  const hiresData = {
    labels: getLabels(hiresFromDate, hiresToDate),
    datasets: getDataSets(statisticsState.candidates.hiresByProvenances),
  };
  const slData = {
    labels: getLabels(slFromDate, slToDate),
    datasets: getDataSets(statisticsState.candidates.slByProvenances),
  };

  return (
    <Main pageTitle={t('statistics.title')} isLoading={statisticsState.loading}>
      <div>
        <Title variant="h6" color="primary" gutterBottom>
          Candidats
        </Title>
        <div>
          <Subtitle label="Répartition des Inscriptions par Semaine" />
          <div style={S.row}>
            <div style={S.dateField}>
              <DatePicker
                label="Depuis le"
                value={registrationsFromDate}
                onChange={value => {
                  if (value) {
                    setRegistrationsFromDate(moment(value));
                  }
                }}
              />
            </div>
            <div style={S.dateField}>
              <DatePicker
                label="Jusqu'au"
                value={registrationsToDate}
                onChange={value => {
                  if (value) {
                    setRegistrationsToDate(moment(value));
                  }
                }}
              />
            </div>
          </div>
          <div style={{ height: 450 }}>
            <Line data={registersData} options={options} />
          </div>
        </div>

        <div>
          <Subtitle label="Répartition des Placements par Semaine" />
          <div style={S.row}>
            <div style={S.dateField}>
              <DatePicker
                label="Depuis le"
                value={hiresFromDate}
                onChange={value => {
                  if (value) {
                    setHiresFromDate(moment(value));
                  }
                }}
              />
            </div>
            <div style={S.dateField}>
              <DatePicker
                label="Jusqu'au"
                value={hiresToDate}
                onChange={value => {
                  if (value) {
                    setHiresToDate(moment(value));
                  }
                }}
              />
            </div>
          </div>
          <div style={{ height: 450 }}>
            <Suspense>
              <Line data={hiresData} options={options} />
            </Suspense>
          </div>
        </div>

        <div>
          <Subtitle label="Répartition des SL par Semaine" />
          <div style={S.row}>
            <div style={S.dateField}>
              <DatePicker
                label="Depuis le"
                value={slFromDate}
                onChange={value => {
                  if (value) {
                    setSlFromDate(moment(value));
                  }
                }}
              />
            </div>
            <div style={S.dateField}>
              <DatePicker
                label="Jusqu'au"
                value={slToDate}
                onChange={value => {
                  if (value) {
                    setSlToDate(moment(value));
                  }
                }}
              />
            </div>
          </div>
          <div style={{ height: 450 }}>
            <Line data={slData} options={options} />
          </div>
        </div>
      </div>
    </Main>
  );
}

export default CandidatesStatistics;
