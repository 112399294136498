import PropTypes from 'prop-types';

export default function Label({ value }) {
  return (
    <div
      style={{
        transformOrigin: 'top left',
        color: 'rgba(0, 0, 0, 0.54)',
      }}
    >
      {value}
    </div>
  );
}

Label.propTypes = {
  value: PropTypes.string,
};

Label.defaultProps = {
  value: null,
};
