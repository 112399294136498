import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { OfferFormSchema } from 'utils/formSchemas';
import { handleFormData } from './helper';
import Form from './form';
import Title from 'components/layout/Title';
import { useFetchOffer, useUpdateOffer } from 'utils/hooks/offer';

export default function EditOfferForm({ id }) {
  const { t } = useTranslation();
  const [offer] = useFetchOffer(id);
  const updateOffer = useUpdateOffer();

  if (offer) {
    offer.isSecondVariable = offer.secondVariable ? true : false;
    if (offer.salary?.includes('par mois')) {
      offer.salary = offer.salary.substr(0, offer.salary.indexOf(' par mois'));
      offer.salaryType = 0;
    } else if (offer.salary?.includes('par an')) {
      offer.salary = offer.salary.substr(0, offer.salary.indexOf(' par an'));
      offer.salaryType = 1;
    } else if (offer.salary?.includes('Selon profil')) {
      offer.salary = offer.salary.substr(
        0,
        offer.salary.indexOf('Selon profil')
      );
      offer.salaryType = 2;
    }

    if (offer.profiles && Array.isArray(offer.profiles)) {
      offer.profiles = '\u2022 '.concat(offer.profiles.join('\n\u2022 '));
    }
  }

  const handleOnSubmit = values => {
    const data = handleFormData({ ...values, ...values.kpi });
    updateOffer(data);
  };

  return (
    <>
      <Title>{t('offer.edit')}</Title>
      {offer && (
        <Formik
          initialValues={offer}
          validationSchema={OfferFormSchema}
          onSubmit={handleOnSubmit}
        >
          {props => <Form {...props} />}
        </Formik>
      )}
    </>
  );
}
