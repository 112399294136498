import { useDispatch } from 'react-redux';
import ActionButton from 'components/ActionButton';
import { Grid } from 'components/core/containers';
import {
  notifyCandidateContactAttempt,
  activeResearchConfirmation,
  placementContactAttempt,
} from 'redux/candidates/actions';

import CandidateForm from './CandidateForm';
import { PlacementFollowUp } from 'components/eiquem/candidate/PlacementFollowUp';
import ApplicationFollowUp from 'components/eiquem/candidate/ApplicationFollowUp';
import ApplicationFollowUpContactButton from 'components/eiquem/candidate/ApplicationFollowUpContactButton';

function Sheet({ candidate }) {
  const dispatch = useDispatch();

  const candidateId = candidate.id;

  const sendContactAttempt = () =>
    dispatch(notifyCandidateContactAttempt({ candidateId }));

  const sendActiveResearchConfirmation = () =>
    dispatch(activeResearchConfirmation(candidateId));

  const sendPlacementContactAttempt = period => () =>
    dispatch(placementContactAttempt(candidateId, period));

  return (
    <Grid container>
      <Grid md={5} sm={12}>
        <CandidateForm candidate={candidate} />
      </Grid>
      <Grid md={3} sm={12}>
        <div style={{ marginBottom: 10 }}>Actions</div>

        <Grid container>
          <Grid md={12}>
            <ActionButton
              label="Mail de tentative de contact"
              dialog
              title="Tentative de contact"
              dialogContent="Un mail sera envoyé au candidat l'avertissant que tu as essayé de le contacter sans succès."
              action={sendContactAttempt}
              confirmText="Confirmer"
            >
              Contact
            </ActionButton>
          </Grid>
          <Grid md={12}>
            <ActionButton
              label="Mail de confirmation de recherche en cours"
              dialog
              title="Toujours en recherche ?"
              dialogContent="Un mail sera envoyé au candidat lui demandant s'il est toujours en recherche d'un contrat."
              action={sendActiveResearchConfirmation}
              confirmText="Confirmer"
            >
              Toujours en recherche ?
            </ActionButton>
          </Grid>
          <Grid md={12}>
            <ApplicationFollowUpContactButton />
          </Grid>

          {candidate.alreadyPlaced && (
            <>
              <Grid md={12}>
                <ActionButton
                  label="Mail de tentative de contact pour suivi de placement W4"
                  dialog
                  title="Suivi W4"
                  dialogContent="Un mail sera envoyé au candidat l'avertissant que tu as essayé de le contacter pour parler de son expérience sans succès."
                  action={sendPlacementContactAttempt('W4')}
                  confirmText="Confirmer"
                  disabled={!candidate.alreadyPlaced}
                >
                  Suivi W4
                </ActionButton>
              </Grid>
              <Grid md={12}>
                <ActionButton
                  label="Mail de tentative de contact pour suivi de placement M-1"
                  dialog
                  title="Suivi M-1"
                  dialogContent="Un mail sera envoyé au candidat l'avertissant que tu as essayé de le contacter pour parler de son expérience sans succès."
                  action={sendPlacementContactAttempt('M1')}
                  confirmText="Confirmer"
                  disabled={!candidate.alreadyPlaced}
                >
                  Suivi M-1
                </ActionButton>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <Grid md={4} sm={12}>
        <ApplicationFollowUp candidateId={candidate.id} />
        <PlacementFollowUp candidateId={candidate.id} />
      </Grid>
    </Grid>
  );
}

export default Sheet;
