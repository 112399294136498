export const initialRecruiter = {
  firstName: null,
  lastName: null,
  mail: null,
  phone: null,
  position: null,
  role: null,
  password: null,
  active: true,
  companyId: null,
};
