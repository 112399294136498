const companies = {
  get: ({
    page,
    size,
    search,
    orderBy,
    direction,
    turnoverMin,
    turnoverMax,
    businessSectorId,
    secondaryBusinessSectorId,
    sizeId,
    planId,
    hasActiveOffers,
    hasOffers,
  }) => ({
    url: '/companies',
    params: {
      page,
      size,
      search,
      orderBy,
      direction,
      turnoverMin,
      turnoverMax,
      businessSectorId,
      secondaryBusinessSectorId,
      sizeId,
      planId,
      hasActiveOffers,
      hasOffers,
    },
  }),
  getOne: ({ id }) => ({
    url: `/companies/${id}`,
  }),
  create: ({
    name = null,
    adminId = null,
    businessSectorId = null,
    secondaryBusinessSectorId = null,
    sizeId = null,
    provenanceId = null,
    street = null,
    city = null,
    postCode = null,
    country = null,
    billingName = null,
    billingStreet = null,
    billingCity = null,
    billingPostCode = null,
    billingCountry = null,
    billingEmail = null,
    billingContact = null,
    email = null,
    phoneNumber = null,
    employeesCount = null,
    description = null,
    recruitmentProcess = null,
    active = null,
    logo = null,
    background = null,
    websiteUrl = null,
  }) => ({
    url: '/companies',
    method: 'post',
    data: {
      name: name,
      adminId: adminId,
      businessSectorId: businessSectorId,
      secondaryBusinessSectorId: secondaryBusinessSectorId,
      sizeId: sizeId,
      provenanceId: provenanceId,
      street: street,
      city: city,
      postCode: postCode,
      country: country,
      billingName: billingName,
      billingStreet: billingStreet,
      billingCity: billingCity,
      billingPostCode: billingPostCode,
      billingCountry: billingCountry,
      billingEmail: billingEmail,
      billingContact,
      email: email,
      phoneNumber: phoneNumber,
      employeesCount: employeesCount,
      description: description,
      recruitmentProcess: recruitmentProcess,
      active: active,
      logo: logo,
      background: background,
      websiteUrl,
    },
  }),
  update: ({
    id = null,
    name = null,
    adminId = null,
    businessSectorId = null,
    secondaryBusinessSectorId = null,
    sizeId = null,
    provenanceId = null,
    street = null,
    city = null,
    postCode = null,
    country = null,
    billingName = null,
    billingStreet = null,
    billingCity = null,
    billingPostCode = null,
    billingCountry = null,
    billingEmail = null,
    billingContact = null,
    email = null,
    phoneNumber = null,
    employeesCount = null,
    description = null,
    recruitmentProcess = null,
    active = null,
    logo = null,
    background = null,
    websiteUrl = null,
  }) => ({
    url: `/companies/${id}`,
    method: 'put',
    data: {
      name: name,
      adminId: adminId,
      businessSectorId: businessSectorId,
      secondaryBusinessSectorId: secondaryBusinessSectorId,
      sizeId: sizeId,
      provenanceId: provenanceId,
      street: street,
      city: city,
      postCode: postCode,
      country: country,
      billingName: billingName,
      billingStreet: billingStreet,
      billingCity: billingCity,
      billingPostCode: billingPostCode,
      billingCountry: billingCountry,
      billingEmail: billingEmail,
      billingContact,
      email: email,
      phoneNumber: phoneNumber,
      employeesCount: employeesCount,
      description: description,
      recruitmentProcess: recruitmentProcess,
      active: active,
      logo: logo,
      background: background,
      websiteUrl,
    },
  }),
  updateComment: (id, comment) => ({
    url: `/companies/${id}/comment`,
    method: 'patch',
    data: {
      comment: comment,
    },
  }),
  downloadAll: ({
    search,
    orderBy,
    direction,
    turnoverMin,
    turnoverMax,
    businessSectorId,
    secondaryBusinessSectorId,
    sizeId,
    planId,
    hasActiveOffers,
    hasOffers,
  }) => ({
    url: '/companies/sheet',
    method: 'get',
    responseType: 'blob',
    params: {
      search,
      orderBy,
      direction,
      turnoverMin,
      turnoverMax,
      businessSectorId,
      secondaryBusinessSectorId,
      sizeId,
      planId,
      hasActiveOffers,
      hasOffers,
    },
  }),
};

export default companies;
