import { fetch } from 'utils/api';
import services from 'utils/api/Services/services';
import { actionRequest, actionSuccess, actionFailure } from 'utils/functions';

export const ACTIONS_TYPE = Object.freeze({
  GET_CREDIT_NOTES_BEGIN: 'GET_CREDIT_NOTES_BEGIN',
  GET_CREDIT_NOTES_SUCCESS: 'GET_CREDIT_NOTES_SUCCESS',
  GET_CREDIT_NOTES_FAILURE: 'GET_CREDIT_NOTES_FAILURE',

  GET_CREDIT_NOTE_BEGIN: 'GET_CREDIT_NOTE_BEGIN',
  GET_CREDIT_NOTE_SUCCESS: 'GET_CREDIT_NOTE_SUCCESS',
  GET_CREDIT_NOTE_FAILURE: 'GET_CREDIT_NOTE_FAILURE',

  CLEAN_CURRENT_CREDIT_NOTE: 'CLEAN_CURRENT_CREDIT_NOTE',

  CREATE_CREDIT_NOTE_BEGIN: 'CREATE_CREDIT_NOTE_BEGIN',
  CREATE_CREDIT_NOTE_SUCCESS: 'CREATE_CREDIT_NOTE_SUCCESS',
  CREATE_CREDIT_NOTE_FAILURE: 'CREATE_CREDIT_NOTE_FAILURE',

  UPDATE_CREDIT_NOTE_BEGIN: 'UPDATE_CREDIT_NOTE_BEGIN',
  UPDATE_CREDIT_NOTE_SUCCESS: 'UPDATE_CREDIT_NOTE_SUCCESS',
  UPDATE_CREDIT_NOTE_FAILURE: 'UPDATE_CREDIT_NOTE_FAILURE',

  DELETE_CREDIT_NOTE_BEGIN: 'DELETE_CREDIT_NOTE_BEGIN',
  DELETE_CREDIT_NOTE_SUCCESS: 'DELETE_CREDIT_NOTE_SUCCESS',
  DELETE_CREDIT_NOTE_FAILURE: 'DELETE_CREDIT_NOTE_FAILURE',

  DOWNLOAD_CREDIT_NOTES_BEGIN: 'DOWNLOAD_CREDIT_NOTES_BEGIN',
  DOWNLOAD_CREDIT_NOTES_SUCCESS: 'DOWNLOAD_CREDIT_NOTES_SUCCESS',
  DOWNLOAD_CREDIT_NOTES_FAILURE: 'DOWNLOAD_CREDIT_NOTES_FAILURE',

  DOWNLOAD_ONE_CREDIT_NOTE_BEGIN: 'DOWNLOAD_ONE_CREDIT_NOTE_BEGIN',
  DOWNLOAD_ONE_CREDIT_NOTE_SUCCESS: 'DOWNLOAD_ONE_CREDIT_NOTE_SUCCESS',
  DOWNLOAD_ONE_CREDIT_NOTE_FAILURE: 'DOWNLOAD_ONE_CREDIT_NOTE_FAILURE',

  SEND_CREDIT_NOTE_BEGIN: 'SEND_CREDIT_NOTE_BEGIN',
  SEND_CREDIT_NOTE_SUCCESS: 'SEND_CREDIT_NOTE_SUCCESS',
  SEND_CREDIT_NOTE_FAILURE: 'SEND_CREDIT_NOTE_FAILURE',
});

export const sendCreditNote = params => (dispatch, getState) =>
  fetch({
    service: services.creditNotes.send(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.SEND_CREDIT_NOTE_BEGIN),
    actionSuccess: () => actionSuccess(ACTIONS_TYPE.SEND_CREDIT_NOTE_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.SEND_CREDIT_NOTE_FAILURE, errors),
  });

export const downloadCreditNotes = () => (dispatch, getState) =>
  fetch({
    service: services.creditNotes.download(),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.DOWNLOAD_CREDIT_NOTES_SUCCESS),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.DOWNLOAD_CREDIT_NOTES_SUCCESS, null),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.DOWNLOAD_CREDIT_NOTES_FAILURE, errors),
  });

export const downloadOneCreditNote = params => (dispatch, getState) =>
  fetch({
    service: services.creditNotes.downloadOne(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.DOWNLOAD_ONE_CREDIT_NOTE_SUCCESS),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.DOWNLOAD_ONE_CREDIT_NOTE_SUCCESS, null),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.DOWNLOAD_ONE_CREDIT_NOTE_FAILURE, errors),
  });

export const deleteCreditNote = params => (dispatch, getState) =>
  fetch({
    service: services.creditNotes.delete(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.DELETE_CREDIT_NOTE_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.DELETE_CREDIT_NOTE_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.DELETE_CREDIT_NOTE_FAILURE, errors),
  });

export const cleanCurrentCreditNote = (): Action => ({
  type: ACTIONS_TYPE.CLEAN_CURRENT_CREDIT_NOTE,
});

export const getOneCreditNote = params => (dispatch, getState) =>
  fetch({
    service: services.creditNotes.getOne(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_CREDIT_NOTE_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_CREDIT_NOTE_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_CREDIT_NOTE_FAILURE, errors),
  });

export const createCreditNote = params => (dispatch, getState) =>
  fetch({
    service: services.creditNotes.add(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.CREATE_CREDIT_NOTE_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.CREATE_CREDIT_NOTE_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.CREATE_CREDIT_NOTE_FAILURE, errors),
  });

export const updateCreditNote = params => (dispatch, getState) =>
  fetch({
    service: services.creditNotes.update(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.UPDATE_CREDIT_NOTE_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.UPDATE_CREDIT_NOTE_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_CREDIT_NOTE_FAILURE, errors),
  });

export const getCreditNotes = params => (dispatch, getState) =>
  fetch({
    service: services.creditNotes.get(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_CREDIT_NOTES_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_CREDIT_NOTES_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_CREDIT_NOTES_FAILURE, errors),
  });
