import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { AdminFormSchema } from 'utils/formSchemas';
import {
  getOneAdmin,
  cleanCurrentAdmin,
  updateAdmin,
  createAdmin,
} from 'redux/admins/actions';
import { initialAdmin } from '../helper';

import { Paper } from '@material-ui/core';
import Title from 'components/layout/Title';
import Main from 'components/layout/Main';
import Form from './form';

function Admin() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id ? parseInt(params.id) : null;

  useEffect(
    () => {
      if (id === null) {
        return;
      }
      dispatch(getOneAdmin(id));
      return function() {
        dispatch(cleanCurrentAdmin());
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOnSubmit = values => {
    if (id !== null) {
      values.id = id;
      console.log('call update admin with data: ', values);
      dispatch(updateAdmin(values));
    } else {
      console.log('call create admin with data: ', values);
      dispatch(createAdmin(values));
    }
  };

  const adminState = useSelector(state => state.admins);
  const admin = id ? adminState.current : initialAdmin;

  return (
    <Main isLoading={adminState.loading}>
      {id === null && <Title>{t('admin.create')}</Title>}
      {id !== null && (
        <Title>
          {t('admin.edit')} - {id}
        </Title>
      )}

      <Paper>
        {admin && (
          <Formik
            initialValues={admin}
            validationSchema={AdminFormSchema}
            onSubmit={handleOnSubmit}
          >
            {props => <Form {...props} />}
          </Formik>
        )}
      </Paper>
    </Main>
  );
}

export default Admin;
