const style = () => ({
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    paddingTop: 5,
    paddingBottom: 10,
    textAlign: 'center',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: 5,
  },
  icon: {
    color: 'black',
  },
});

export default style;
