const options = {
  get: ({
    page = null,
    size = null,
    filter = null,
    orderBy = null,
    direction = null,
    fromDate = null,
    toDate = null,
    fromPaymentDate = null,
    toPaymentDate = null,
    types = null,
    admin = null,
    invoiceStatus = null,
    companyId = null,
  }) => ({
    url: '/invoices',
    method: 'get',
    params: {
      page,
      size,
      filter,
      orderBy,
      direction,
      fromDate,
      toDate,
      fromPaymentDate,
      toPaymentDate,
      types,
      admin,
      invoiceStatus,
      companyId,
    },
  }),
  getOne: ({ id }) => ({
    url: `/invoices/${id}`,
    method: 'get',
  }),
  update: ({
    id,
    type,
    company,
    amountId,
    amountValue,
    comment = null,
    daysToPay,
    tva = null,
    message,
  }) => ({
    url: `/invoices/${id}`,
    method: 'put',
    data: {
      type,
      company,
      amountId,
      amountValue,
      comment,
      daysToPay,
      tva,
      message,
    },
  }),
  updateComment: ({ id, comment }) => ({
    url: `/invoices/${id}/comment`,
    method: 'patch',
    data: {
      comment,
    },
  }),
  delete: ({ id }) => ({
    url: `/invoices/${id}`,
    method: 'delete',
  }),
  sendInvoices: ({ id, sent, summary, groupedInvoices = null }) => ({
    url: `/send/invoices/${id}`,
    method: 'put',
    params: {
      sent,
      summary,
      groupedInvoices,
    },
  }),
  remindInvoice: ({ id, sent }) => ({
    url: `/send/invoices/${id}/remind`,
    method: 'put',
    params: {
      sent,
    },
  }),
  download: ({
    filter = null,
    orderBy = null,
    direction = null,
    fromDate = null,
    toDate = null,
    fromPaymentDate = null,
    toPaymentDate = null,
    types = null,
    admin = null,
    state = null,
    companyId = null,
  }) => ({
    url: '/download/invoices',
    method: 'get',
    responseType: 'blob',
    params: {
      filter,
      orderBy,
      direction,
      fromDate,
      toDate,
      fromPaymentDate,
      toPaymentDate,
      types,
      admin,
      state,
      companyId,
    },
  }),
  downloadOne: ({ id }) => ({
    url: `/download/invoices/${id}`,
    method: 'get',
    responseType: 'blob',
  }),
  getKpi: () => ({
    url: '/statistics/invoices',
    method: 'get',
  }),
  add: ({
    type,
    company,
    amountValue,
    comment = null,
    message = null,
    daysToPay,
    tva = null,
  }) => ({
    url: '/invoices',
    method: 'post',
    data: {
      type,
      company,
      amountId: null,
      amountValue,
      comment,
      message,
      daysToPay,
      tva,
    },
  }),
  setInvoiceReminderProgram: ({ id, set, groupedInvoices = null }) => ({
    url: `/invoices/${id}/irp`,
    method: 'put',
    params: {
      set,
      groupedInvoices,
    },
  }),
  setInvoiceAsPaid: ({ id, set, paymentDate }) => ({
    url: `/invoices/${id}/setAsPaid`,
    method: 'put',
    params: {
      set,
      paymentDate,
    },
  }),
};

export default options;
