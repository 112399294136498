import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { OfferFormSchema } from 'utils/formSchemas';
import { useCreateOffer } from 'utils/hooks/offer';
import { initialOffer, handleFormData } from './helper';
import Form from './form';
import Title from 'components/layout/Title';

export default function CreateOfferForm() {
  const { t } = useTranslation();
  const createOffer = useCreateOffer();

  const handleOnSubmit = values => {
    const data = handleFormData(values);
    console.log('call create offer with data: ', data);
    createOffer(data);
  };

  return (
    <>
      <Title>{t('offer.create')}</Title>
      <Formik
        initialValues={initialOffer}
        validationSchema={OfferFormSchema}
        onSubmit={handleOnSubmit}
      >
        {props => <Form {...props} />}
      </Formik>
    </>
  );
}
