import { useSelector } from 'react-redux';
import { useQuery } from 'utils/hooks/queryState';
import Tabs from 'components/core/Tabs';
import TabPanel from 'components/core/TabPanel';
import ApplicationTable from './Table';

function ApplicationsAndSuggestions() {
  const qs = useQuery();
  const tab = qs.get('application');
  const offer = useSelector(state => state.offers.current);
  const kpi = offer.kpi;
  const planId = offer.offerType.value;

  return (
    <>
      <Tabs name="application" direction="horizontal" tabs={tabs(kpi)} />
      <TabPanel index="active" value={tab}>
        <ApplicationTable type="active" planId={planId} />
      </TabPanel>
      <TabPanel index="suggestion" value={tab}>
        <ApplicationTable type="suggestion" planId={planId} />
      </TabPanel>
      <TabPanel index="completed" value={tab}>
        <ApplicationTable type="completed" planId={planId} />
      </TabPanel>
    </>
  );
}

export default ApplicationsAndSuggestions;

const tabs = kpi => [
  {
    key: 'active',
    label: `candidatures à traiter/suivre (${
      kpi.applicationToBeProcessedCount
    })`,
  },
  {
    key: 'suggestion',
    label: (
      <>
        suggestions ({kpi.pendingSuggestionCount}/
        {kpi.suggestionCount -
          kpi.acceptedSuggestionCount -
          kpi.pendingSuggestionCount}
        )
      </>
    ),
  },
  {
    key: 'completed',
    label: `candidatures traitées (${kpi.applicationCompletedCount})`,
  },
];
