import { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import history, { initHistoryState } from 'utils/history';
import _ from 'lodash';
import {
  getBusinessSectorOptions,
  getCompanySizeOptions,
  getPlanOptions,
} from 'redux/formOptions/actions';
import { persistHistoryState } from 'utils/functions';

import { Grid, withStyles } from '@material-ui/core';
import Autocomplete from 'components/core/input/Autocomplete';
import TextField from 'components/core/input/TextField';
import Checkbox from 'components/Checkbox';

import S from './styles';

function FilterFields(props) {
  const dispatch = useDispatch();

  const debHandleChange = useRef(
    _.debounce(
      (value, formatted, filedName) => setFilter(value, formatted, filedName),
      700
    )
  );
  const handleTurnoverMinChange = ({ value }) => {
    debHandleChange.current(value, value, 'turnoverMin');
  };
  const handleTurnoverMaxChange = ({ value }) => {
    debHandleChange.current(value, value, 'turnoverMax');
  };
  const handleBusinessSectorChange = ({ value }) => {
    setFilter(value, value && value.value, 'businessSectorId');
  };
  const handleSecondaryBusinessSectorChange = ({ value }) => {
    setFilter(value, value && value.value, 'secondaryBusinessSectorId');
  };
  const handleSizeChange = ({ value }) => {
    setFilter(value, value && value.value, 'sizeId');
  };
  const handlePlanChange = ({ value }) => {
    setFilter(value, value && value.value, 'planId');
  };
  const handHasActiveOffersChange = value => {
    setFilter(value, value ? 1 : 0, 'hasActiveOffers');
  };
  const handHasOffersChange = value => {
    setFilter(value, value ? 1 : 0, 'hasOffers');
  };

  const setFilter = (value, formattedValue, fieldName) => {
    persistHistoryState({
      ...historyState,
      filter: {
        ...historyState.filter,
        [fieldName]: value,
      },
      filterFormated: {
        ...historyState.filterFormated,
        [fieldName]: formattedValue,
      },
    });
  };

  const options = useSelector(state => state.formOptions);
  useEffect(
    () => {
      if (options.businessSectorOptions === null) {
        dispatch(getBusinessSectorOptions());
      }
      if (options.companySizeOptions === null) {
        dispatch(getCompanySizeOptions());
      }
      if (options.planOptions === null) {
        dispatch(getPlanOptions());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const historyState = history.location.state || initHistoryState;
  const filters = historyState.filter || {};
  return (
    <div className={props.classes.container}>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Autocomplete
            defaultValue={filters.businessSectorId}
            options={options.businessSectorOptions}
            label="Secteur d'Activité"
            onChange={handleBusinessSectorChange}
          />
        </Grid>
        <Grid item md={6}>
          <Autocomplete
            defaultValue={filters.secondaryBusinessSectorId}
            options={options.businessSectorOptions}
            label="Secteur d'Activité Secondaire"
            onChange={handleSecondaryBusinessSectorChange}
          />
        </Grid>
        <Grid item md={6}>
          <Autocomplete
            defaultValue={filters.sizeId}
            options={options.companySizeOptions}
            label="Taille"
            onChange={handleSizeChange}
          />
        </Grid>
        <Grid item md={6}>
          <Autocomplete
            defaultValue={filters.planId}
            options={options.planOptions}
            label="Plan"
            onChange={handlePlanChange}
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            label="CA Minimal"
            type="number"
            defaultValue={filters.turnoverMin}
            handleChange={handleTurnoverMinChange}
            className={props.classes.field}
            endAdornment="€"
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            label="CA Maximal"
            type="number"
            defaultValue={filters.turnoverMax}
            handleChange={handleTurnoverMaxChange}
            className={props.classes.field}
            endAdornment="€"
          />
        </Grid>
        <Grid item md={6} />
        <Grid item md={3}>
          <Checkbox
            label="Avec Offres Actives"
            value={filters.hasActiveOffers || false}
            handleChange={handHasActiveOffersChange}
          />
        </Grid>
        <Grid item md={3}>
          <Checkbox
            label="Avec Offres"
            value={filters.hasOffers || false}
            handleChange={handHasOffersChange}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(S)(FilterFields);
