import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Main from 'components/layout/Main';
import PageHeader from 'components/layout/PageHeader';
import CommercialProposalTable from 'components/eiquem/commercialProposal/Table';
import CommercialProposalStatistics from 'components/eiquem/commercialProposal/Statistics';

function CommercialProposals() {
  const { t } = useTranslation();
  const isLoading = useSelector(state => state.commercialProposals).loading;

  return (
    <Main pageTitle={t('commercialProposal.title')} isLoading={isLoading}>
      <PageHeader title={t('commercialProposal.title')}>
        <CommercialProposalStatistics />
      </PageHeader>
      <CommercialProposalTable displayActions />
    </Main>
  );
}

export default CommercialProposals;
