import { ROUTES } from 'utils/routes';

const candTooltip = (
  <>
    <div>Cand. à traiter /</div>
    <div> Sugg. sans réponse</div>
  </>
);

export const tableColumns = [
  {
    header: 'Entreprise',
    accessors: ['companyLogo'],
    type: 'image',
    orderLabel: 'company',
  },
  {
    header: '',
    accessors: [' offer', 'company', 'label'],
    style: { fontWeight: 'bold' },
  },
  {
    header: 'Offre',
    accessors: ['labelLink'],
    headerStyle: {
      minWidth: '110px',
    },
    type: 'link',
    orderLabel: 'offer',
  },
  {
    header: 'Plan',
    accessors: ['plan', 'label'],
    headerStyle: {
      minWidth: '100px',
    },
    orderLabel: 'plan',
  },
  {
    header: 'Début',
    accessors: ['startDate'],
    headerStyle: {
      minWidth: '50px',
    },
    type: 'date',
    orderLabel: 'startDate',
  },
  {
    header: 'Durée',
    accessors: ['duration'],
    headerStyle: {
      minWidth: '50px',
    },
    style: { textAlign: 'center' },
    suffix: 'mois',
    orderLabel: 'duration',
  },
  {
    header: 'Lieu',
    accessors: ['location'],
    headerStyle: {
      minWidth: '50px',
    },
  },
  {
    header: 'Contrat',
    accessors: ['contractTypes'],
    headerStyle: {
      minWidth: '50px',
    },
  },
  {
    header: 'SL',
    accessors: ['shortListReady'],
    headerStyle: {
      minWidth: '50px',
    },
    type: 'boolean',
  },
  {
    header: 'Dernière SL',
    accessors: ['lastShortListInDays'],
    headerStyle: {
      minWidth: '50px',
    },
    suffix: 'j',
    style: {
      fontWeight: 'bold',
    },
    cellStyleAccessor: 'lastShortListInDays',
    orderLabel: 'shortList',
  },
  {
    header: 'RC',
    accessors: ['lastCustomerFeedbackInDays'],
    headerStyle: {
      minWidth: '50px',
    },
    suffix: 'j',
    cellStyleAccessor: 'lastCustomerFeedbackInDays',
    orderLabel: 'customerFeedback',
  },
  {
    header: 'CC',
    accessors: ['lastCustomerContactInDays'],
    headerStyle: {
      minWidth: '50px',
    },
    suffix: 'j',
    orderLabel: 'customerContact',
  },
  {
    header: 'Diffusée',
    accessors: ['lastPostingInDays'],
    headerStyle: {
      minWidth: '50px',
    },
    suffix: 'j',
    cellStyleAccessor: 'lastPostingInDays',
    orderLabel: 'posting',
  },
  {
    header: 'Cand',
    tooltip: candTooltip,
    accessors: ['appAndSugg'],
    headerStyle: {
      minWidth: '50px',
    },
  },
];

export const formatData = offers => {
  if (offers === null) {
    return;
  }
  const rowsFormated = [];

  offers.forEach(offer => {
    const rowFormated = Object.assign({}, offer);
    // Data
    rowFormated.appAndSugg = `${offer.applicationToManageCount}/${
      offer.pendingSuggestionCount
    }`;

    // Style
    rowFormated.cellStyle = {};
    if (offer.lastShortListInDays > 10) {
      rowFormated.cellStyle['lastShortListInDays'] = {
        ...rowFormated.cellStyle['lastShortListInDays'],
        color: 'red',
        fontWeight: 'bold',
      };
    }
    if (offer.lastCustomerFeedbackInDays > 10) {
      rowFormated.cellStyle['lastCustomerFeedbackInDays'] = {
        ...rowFormated.cellStyle['lastCustomerFeedbackInDays'],
        color: 'red',
        fontWeight: 'bold',
      };
    }
    if (offer.lastPostingInDays > 10) {
      rowFormated.cellStyle['lastPostingInDays'] = {
        ...rowFormated.cellStyle['lastPostingInDays'],
        color: 'red',
        fontWeight: 'bold',
      };
    }

    // Set label as link
    rowFormated.labelLink = {
      label: offer.label,
      url: ROUTES.OFFER_SHOW.replace(':id', offer.id),
    };

    const companyLabel = offer.company.label;
    const companyUrl = ROUTES.COMPANY_SHOW.replace(':id', offer.company.id);
    rowFormated.companyLogo = {
      label: companyLabel,
      img: offer.company.logo,
      url: companyUrl,
    };

    if (offer.applicationToManageCount) {
      rowFormated.backgroundColor = 'lightgrey';
    }

    rowsFormated.push(rowFormated);
    return;
  });

  return rowsFormated;
};
