import { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'utils/hooks/queryState';
import {
  downloadCommercialProposal,
  deleteCommercialProposal,
  cleanProposals,
} from 'redux/commercialProposals/actions';
import { columns, fetchProposals, format, legend } from './helper';
import { ROUTES } from 'utils/routes';

import { InlineContainer } from 'components/core/containers';
import Legend from 'components/core/Legend';
import Table from 'components/core/Table';
import CreateButton from 'components/Table/Actions/CreateButton';
import EditButton from 'components/Table/Actions/EditButton';
import RemoveButton from 'components/Table/Actions/RemoveButton';
import DownloadOneButton from './DownloadOneButton';
import { SearchFilter } from 'components/core/FilterInput';
import { Pagination, PaginationWithState } from 'components/core/Pagination';
import RowDetail from './RowDetail';
import Filters from './Filters';

CommercialProposalTable.defaultProps = {
  companyId: null,
  displayActions: true,
};

function CommercialProposalTable({ companyId, displayActions }) {
  const dispatch = useDispatch();
  const proposals = useSelector(state => state.commercialProposals.items);
  const proposalCount = useSelector(
    state => state.commercialProposals.totalItemCount
  );
  const qs = useQuery();

  const refreshProposals = useCallback(
    (params = {}) => {
      if (companyId) {
        params.company = companyId;
      }
      fetchProposals(params, dispatch, qs);
    },
    [dispatch, qs, companyId]
  );

  useEffect(
    () => {
      refreshProposals({});
      return function cleanup() {
        dispatch(cleanProposals());
      };
    },
    [dispatch]
  );

  const handleDownload = useCallback(
    id => dispatch(downloadCommercialProposal(id)),
    [dispatch]
  );
  const handleRemove = useCallback(
    id => dispatch(deleteCommercialProposal(id)),
    [dispatch]
  );

  const Actions = useCallback(
    props => (
      <>
        <EditButton {...props} pathname={ROUTES.COMMERCIAL_PROPOSAL_EDIT} />
        <RemoveButton {...props} action={handleRemove} />
        <DownloadOneButton {...props} action={handleDownload} />
      </>
    ),
    [handleRemove, handleDownload]
  );

  const rows = useMemo(() => format(proposals), [proposals]);

  return (
    <>
      {displayActions && (
        <InlineContainer align="bottom">
          <Filters onChange={refreshProposals} />
          <SearchFilter onChange={refreshProposals} />
          <CreateButton pathname={ROUTES.COMMERCIAL_PROPOSAL_CREATE} />
          <Legend items={legend} />
        </InlineContainer>
      )}
      <Table
        columns={columns}
        rows={rows}
        detail={RowDetail}
        actions={Actions}
      />
      {companyId ? (
        <PaginationWithState
          itemCount={proposalCount}
          onChange={refreshProposals}
        />
      ) : (
        <Pagination itemCount={proposalCount} onChange={refreshProposals} />
      )}
    </>
  );
}

export default CommercialProposalTable;
