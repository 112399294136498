import { InputAdornment, TextField as TextFieldMui } from '@material-ui/core';

function TextField(props) {
  const {
    name,
    className,
    disabled,
    endAdornment,
    multiline,
    error,
    handleChange,
    label,
    placeholder,
    rows,
    rowMax,
    touched,
    value,
    type,
    defaultValue,
    inputStyle,
  } = props;

  const onChange = event => {
    let value = event.target.value;
    if (type === 'number') {
      value = normalizeNumber(value);
    }
    handleChange({ value, name });
  };

  const format = () => {
    let formattedValue = value;
    if (typeof formattedValue === 'string') {
      formattedValue = value.trim();
    }
    handleChange({ value, formattedValue });
  };

  return (
    <TextFieldMui
      style={{ width: '100%' }}
      defaultValue={defaultValue}
      id={label}
      label={label}
      placeholder={placeholder}
      autoComplete="off"
      multiline={multiline}
      rows={rows}
      rowsMax={rowMax}
      helperText={touched ? error : ''}
      error={touched && Boolean(error)}
      className={className}
      onChange={event => {
        onChange(event);
      }}
      InputProps={{
        endAdornment: endAdornment && adornment('end', endAdornment),
        inputProps: { min: 0 },
        style: inputStyle,
      }}
      value={value !== null ? value : ''}
      disabled={disabled}
      type={type}
      onBlur={format}
    />
  );
}

const normalizeNumber = value => {
  if (value === null || value === '') {
    return null;
  } else {
    return Number(value);
  }
};

const adornment = (position, value) => (
  <InputAdornment position={position}>{value}</InputAdornment>
);

export default TextField;
