import { Grid } from 'components/core/containers';
import {
  FollowUpField,
  PERIODS,
} from 'components/eiquem/placement/followUp/Field';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  getPlacementFollowUp,
  updateFollowUp,
  remindAndPostponeFollowUp,
} from 'redux/candidates/actions';
import { formatDateParameter } from 'utils/functions';

export function PlacementFollowUp({ candidateId }) {
  const { t } = useTranslation();
  const [followUps, setFollowUps] = useState(null);

  const dispatch = useDispatch();
  useEffect(
    () => {
      dispatch(getPlacementFollowUp({ candidateId }));
    },
    [dispatch, candidateId]
  );

  const followUpState = useSelector(state => state.candidates.followUps);
  useEffect(
    () => {
      setFollowUps(followUpState);
    },
    [followUpState]
  );

  const handleChange = value => {
    dispatch(
      updateFollowUp({
        id: value.id,
        followUpDate: formatDateParameter(value.date),
        isDone: value.isDone,
      })
    );

    setFollowUps(prevState =>
      prevState.map(followUp => (followUp.id === value.id ? value : followUp))
    );
  };

  const handleRemindAndPostpone = ({ id }) => {
    dispatch(remindAndPostponeFollowUp({ id, candidateId }));
  };

  if (!followUps) {
    return <></>;
  }

  return (
    <Grid md={12}>
      <div>Suivi Placement</div>

      <div style={{ maxHeight: 250, height: '100%', overflowY: 'auto' }}>
        <FollowUpField
          label={t('followUp.periods.week1')}
          period={PERIODS.W1}
          followUps={followUps}
          onChange={handleChange}
          onRemindAndPostpone={handleRemindAndPostpone}
        />
        <FollowUpField
          label={t('followUp.periods.week4')}
          period={PERIODS.W4}
          followUps={followUps}
          onChange={handleChange}
          onRemindAndPostpone={handleRemindAndPostpone}
        />
        <FollowUpField
          label={t('followUp.periods.quarter')}
          period={PERIODS.Q}
          followUps={followUps}
          onChange={handleChange}
          onRemindAndPostpone={handleRemindAndPostpone}
        />
        <FollowUpField
          label={t('followUp.periods.month-1')}
          period={PERIODS.M1}
          followUps={followUps}
          onChange={handleChange}
          onRemindAndPostpone={handleRemindAndPostpone}
        />
        <FollowUpField
          label={t('followUp.periods.month')}
          period={PERIODS.M}
          followUps={followUps}
          onChange={handleChange}
          onRemindAndPostpone={handleRemindAndPostpone}
        />
      </div>
    </Grid>
  );
}
