import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { getKpi } from 'redux/invoices/actions';
import NumberFormat from 'react-number-format';
import { Box, Typography, withStyles } from '@material-ui/core';

import S from './styles.js';

function Summary(props) {
  const { classes } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(getKpi());
    },
    [dispatch]
  );

  const kpi = useSelector(state => state.invoices.kpi);

  const SummaryItem = props => (
    <Typography variant="subtitle1" className={classes.row}>
      <Box>{t('invoicing.invoices.summary.'.concat(props.label))} </Box>
      <b>
        <NumberFormat
          value={props.value ? props.value : 0}
          thousandSeparator={' '}
          suffix={'€'}
          displayType={'text'}
          decimalScale={2}
          fixedDecimalScale={
            props.value ? !Number.isInteger(props.value) : false
          }
        />
      </b>
    </Typography>
  );

  return (
    <div className={classes.summaryContainer}>
      {Object.entries(kpi).map(([key, value]) => (
        <SummaryItem key={key} label={key} value={value} />
      ))}
    </div>
  );
}

export default withStyles(S)(Summary);
