import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPafStats } from 'redux/pafs/actions';
import { useQuery } from 'utils/hooks/queryState';
import { formatDate } from 'utils/functions';
import moment from 'moment-timezone';
import {
  Grid,
  InlineContainer,
  ItemsContainer,
} from 'components/core/containers';
import Item from 'components/core/Item';

const Statistics = () => {
  const dispatch = useDispatch();
  const qs = useQuery();

  const startDate = qs.get('start');
  const toDate = qs.get('end');

  useEffect(
    () => {
      dispatch(
        getPafStats({
          fromDate: formatDate(startDate ?? defaultStartDate),
          toDate: formatDate(toDate ?? defaultEndDate),
        })
      );
    },
    [dispatch, getPafStats, startDate, toDate]
  );

  const stats = useSelector(state => state.pafs).stats;

  return (
    <InlineContainer>
      <ItemsContainer>
        <Grid container>
          <Grid md={12}>
            <Item
              label={`CA Placement (${stats.placements})`}
              value={stats.turnover}
              type="currency"
            />
          </Grid>
          <Grid md={6}>
            <Item
              label={`Campus (${stats.studentPlacements})`}
              value={stats.studentTurnover}
              type="currency"
            />
          </Grid>
          <Grid md={6}>
            <Item
              label={`CDI (${stats.regularPlacements})`}
              value={stats.regularTurnover}
              type="currency"
            />
          </Grid>
        </Grid>
      </ItemsContainer>
      <div />
      <ItemsContainer>
        <Grid container>
          <Grid md={12}>
            <Item
              label={`CA Sécurisé (${stats.placementsToInvoice})`}
              value={stats.turnoverToInvoice}
              type="currency"
            />
          </Grid>
          <Grid md={6}>
            <Item
              label={`Campus (${stats.studentPlacementsToInvoice})`}
              value={stats.studentTurnoverToInvoice}
              type="currency"
            />
          </Grid>
          <Grid md={6}>
            <Item
              label={`CDI (${stats.regularPlacementsToInvoice})`}
              value={stats.regularTurnoverToInvoice}
              type="currency"
            />
          </Grid>
        </Grid>
      </ItemsContainer>
      <div />
      <ItemsContainer>
        <Grid container>
          <Grid md={12}>
            <Item
              label={`CA Facturé (${stats.placementsInvoiced})`}
              value={stats.turnoverInvoiced}
              type="currency"
            />
          </Grid>
          <Grid md={6}>
            <Item
              label={`Campus (${stats.studentPlacementsInvoiced})`}
              value={stats.studentTurnoverInvoiced}
              type="currency"
            />
          </Grid>
          <Grid md={6}>
            <Item
              label={`CDI (${stats.regularPlacementsInvoiced})`}
              value={stats.regularTurnoverInvoiced}
              type="currency"
            />
          </Grid>
        </Grid>
      </ItemsContainer>
    </InlineContainer>
  );
};

export default Statistics;

const defaultStartDate = moment().startOf('month');
const defaultEndDate = moment().endOf('month');
