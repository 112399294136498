import { fetch } from 'utils/api';
import services from 'utils/api/Services/services';
import { actionRequest, actionSuccess, actionFailure } from 'utils/functions';

export const ACTIONS_TYPE = Object.freeze({
  GET_FOLLOW_UPS_BEGIN: 'GET_FOLLOW_UPS_BEGIN',
  GET_FOLLOW_UPS_SUCCESS: 'GET_FOLLOW_UPS_SUCCESS',
  GET_FOLLOW_UPS_FAILURE: 'GET_FOLLOW_UPS_FAILURE',

  UPDATE_FOLLOW_UP_BEGIN: 'UPDATE_FOLLOW_UP_BEGIN',
  UPDATE_FOLLOW_UP_SUCCESS: 'UPDATE_FOLLOW_UP_SUCCESS',
  UPDATE_FOLLOW_UP_FAILURE: 'UPDATE_FOLLOW_UP_FAILURE',

  REMIND_AND_POSTPONE_FOLLOW_UP_BEGIN: 'REMIND_AND_POSTPONE_FOLLOW_UP_BEGIN',
  REMIND_AND_POSTPONE_FOLLOW_UP_SUCCESS:
    'REMIND_AND_POSTPONE_FOLLOW_UP_SUCCESS',
  REMIND_AND_POSTPONE_FOLLOW_UP_FAILURE:
    'REMIND_AND_POSTPONE_FOLLOW_UP_FAILURE',
});

export const remindAndPostponeFollowUp = ({ id }) => (dispatch, getState) =>
  fetch({
    service: services.followUps.remindAndPostpone({ id }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.REMIND_AND_POSTPONE_FOLLOW_UP_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.REMIND_AND_POSTPONE_FOLLOW_UP_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.REMIND_AND_POSTPONE_FOLLOW_UP_FAILURE, errors),
  });

export const updateFollowUp = ({ id, followUpDate, isDone }) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.followUps.update({ id, followUpDate, isDone }),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.UPDATE_FOLLOW_UP_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.UPDATE_FOLLOW_UP_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_FOLLOW_UP_FAILURE, errors),
  });

export const getFollowUps = ({ year, month, adminId, adminType }) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.followUps.get({ year, month, adminId, adminType }),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_FOLLOW_UPS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_FOLLOW_UPS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_FOLLOW_UPS_FAILURE, errors),
  });
