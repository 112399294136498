import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { forecastsTableColumns } from './constants';
import S from './styles';
import {
  Table,
  TableRow,
  TableCell,
  withStyles,
  TableHead,
  TableBody,
  Tooltip,
  Box,
} from '@material-ui/core';

function ForecastsTable(props) {
  const { classes, values } = props;
  const { t } = useTranslation();

  return (
    <Table className={classes.table} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell className={clsx(classes.tableHead, classes.firstColumn)}>
            <Tooltip
              title={t('statistics.paf.monthly-success-forecasts-tooltip')}
            >
              <Box>{t('statistics.paf.monthly-success-forecasts-title')}</Box>
            </Tooltip>
          </TableCell>
          {forecastsTableColumns.map(column => (
            <TableCell
              key={column.header}
              className={clsx(classes.tableHead, classes.cell)}
            >
              {column.header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className={classes.bold}>
            {t('statistics.paf.monthly-success-forecasts-rate')}
          </TableCell>
          {forecastsTableColumns.map(column => (
            <TableCell key={column.accessor[1]}>
              {values.pafLinesCount > 0
                ? Math.round(
                    (values[column.accessor[1]] / values.pafLinesCount) * 1000
                  ) / 10
                : 0}
              %
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell className={classes.bold}>
            {t('statistics.paf.monthly-success-forecasts-ongoing')}
          </TableCell>
          {forecastsTableColumns.map(column => (
            <TableCell key={column.accessor[2]}>
              {values[column.accessor[2]]}
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default withStyles(S)(ForecastsTable);
