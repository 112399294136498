const applications = {
  switchApplicationState: ({
    applicationId,
    applicationIds,
    code,
    reason = null,
    prefilledPlacement = null,
  }) => ({
    url: `/applications/${applicationId}/switch-state`,
    method: 'patch',
    data: {
      applicationId,
      applicationIds,
      code,
      reason,
      prefilledPlacement,
    },
  }),
  notifyResumeUpdateRequired: ({ applicationId }) => ({
    url: `/applications/${applicationId}/notify-resume-update-required`,
    method: 'patch',
  }),
  notifyContactAttempt: ({ applicationId }) => ({
    url: `/applications/${applicationId}/notify-contact-attempt`,
    method: 'patch',
  }),
  addComment: ({ applicationId, message }) => ({
    url: `/applications/${applicationId}/comments`,
    method: 'post',
    data: {
      applicationId: applicationId,
      message: message,
    },
  }),
  updateComment: ({ applicationId, commentId, message }) => ({
    url: `/applications/${applicationId}/comments`,
    method: 'put',
    data: {
      applicationId: applicationId,
      commentId: commentId,
      message: message,
    },
  }),
  deleteComment: ({ applicationId, commentId }) => ({
    url: `/applications/${applicationId}/comments`,
    method: 'delete',
    data: {
      applicationId: applicationId,
      commentId: commentId,
    },
  }),
  updateResume: ({ applicationId, file }) => ({
    url: `/applications/${applicationId}/resume`,
    method: 'patch',
    data: {
      file,
      applicationId,
    },
  }),
  updateCoverLetter: ({ applicationId, file }) => ({
    url: `/applications/${applicationId}/cover-letter`,
    method: 'patch',
    data: {
      file,
      applicationId,
    },
  }),
  appointmentRequest: ({ applicationId }) => ({
    url: `/applications/${applicationId}/notify-request-appointment`,
    method: 'patch',
    data: {
      applicationId,
    },
  }),
};

export default applications;
