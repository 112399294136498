import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components/core/input';

export default function IsCompletedOffer({ values, onChange }) {
  const { t } = useTranslation();

  return (
    <Checkbox
      name="completedOffer"
      label={t('paf.field.completedOffer')}
      value={values.completedOffer}
      onChange={onChange}
    />
  );
}
