import { ACTIONS_TYPE } from './actions';
import type { Action, PafStatisticsType } from 'utils/types';

export type PafStatisticsStateType = {
  loading: Boolean,
  statistics: Array<PafStatisticsType>,
};

export const initialState: PafStatisticsStateType = {};

export function pafStatisticsReducer(
  state: PafStatisticsStateType = initialState,
  action: Action
) {
  switch (action.type) {
    case ACTIONS_TYPE.GET_PAF_STATISTICS_BEGIN:
      return { ...state, loading: true };
    case ACTIONS_TYPE.GET_PAF_STATISTICS_FAILURE:
      return { ...state, loading: false };
    case ACTIONS_TYPE.GET_PAF_STATISTICS_SUCCESS:
      return { ...state, statistics: action.result, loading: false };
    default:
      return state;
  }
}
