import BaseButton from './baseButton';

export default function DateButton(props) {
  const { value } = props;

  const msg = `Souhaites-tu refuser cette candidature pour le motif DATE ?
         Un mail sera envoyé au candidat.`;

  return (
    <div>
      <BaseButton
        label="Date"
        message={msg}
        value={value}
        color="secondary"
        action={props.action}
        disabled={props.disabled}
      />
    </div>
  );
}
