import { useDispatch } from 'react-redux';
import { ROUTES } from 'utils/routes';
import { downloadReport } from 'redux/offers/actions';

import {
  Equalizer as EqualizerIcon,
  GetApp as GetAppIcon,
  EditRounded as EditRoundedIcon,
  Language as LanguageIcon,
} from '@material-ui/icons';
import ActionButton from 'components/ActionButton';
import OfferStatusButton from 'components/eiquem/offer/OfferStatusButton';
import InvoiceButton from './InvoiceButton';
import ShortListButton from './ShortListButton';

export default function OfferButtons({ offer }) {
  const dispatch = useDispatch();

  const handleDownloadReport = () => {
    dispatch(downloadReport({ id: offer.id }));
  };

  const openFrontInNewTab = () => {
    const newWindow = window.open(offer.uri, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div style={{ display: 'inline-flex', marginTop: -5, gap: 2 }}>
      <OfferStatusButton row={offer} redirectAfter />
      <ShortListButton />
      <ActionButton label="Télécharger" disabled icon={<GetAppIcon />} />
      <ActionButton
        action={handleDownloadReport}
        label="Rapport"
        icon={<EqualizerIcon />}
      />
      <InvoiceButton offer={offer} />
      <ActionButton
        link={ROUTES.OFFER_EDIT.replace(':id', offer.id)}
        label="Modifier"
        icon={<EditRoundedIcon />}
      />
      <ActionButton
        action={openFrontInNewTab}
        label="Lien Eiquem"
        icon={<LanguageIcon />}
      />
    </div>
  );
}
