import { FormHelperText } from '@material-ui/core';

export const dimensionErrorText = (width, height) => (
  <FormHelperText error>
    Image dimensions must be {width}x{height}
    px
  </FormHelperText>
);

export const minDimensionErrorText = (minWidth, minHeight) => (
  <FormHelperText error>
    Image dimensions must be bigger than {minWidth}x{minHeight}
    px
  </FormHelperText>
);

export const formatErrorText = () => (
  <FormHelperText error>Image format not accepted</FormHelperText>
);

export const sizeErrorText = maxSize => (
  <FormHelperText error>Image must be smaller than {maxSize} mb</FormHelperText>
);
