import { memo } from 'react';
import { formatDate, isValidDate } from 'utils/functions';
import { Check } from '@material-ui/icons';
import styled from 'styled-components';
import Td from '../Td';

function CustomCell({ onClick, column, row }) {
  const handleClick = e => {
    const isLinkHasBeenClicked = typeof e.target.href !== 'undefined';
    if (isLinkHasBeenClicked) {
      return;
    }
    onClick();
  };

  const rowStyle =
    (row.cellStyle && row.cellStyle[column.cellStyleAccessor]) || column.style;

  const formatValue = (row, accessors, type = null, suffix = null) => {
    const value = getValue(row, accessors, suffix);
    switch (type) {
      case 'link':
        return value.url ? (
          <Link href={value.url} target={value.target}>
            {value.label}
          </Link>
        ) : (
          '-'
        );
      case 'links':
        return (
          Array.isArray(value) &&
          value.map(val => (
            <Link href={val.url} target={val.target}>
              {val.label}
            </Link>
          ))
        );
      case 'date':
        return isValidDate(value) ? formatDate(value) : '-';
      case 'image':
        if (value.url) {
          return (
            <Link href={value.url}>
              <Img src={value.img} alt={value.label} />
            </Link>
          );
        } else {
          return <Img src={value.img} alt={value.label} />;
        }
      case 'boolean':
        return value === true ? <Check fontSize="small" /> : '';
      default:
        return value;
    }
  };

  const getValue = (row, accessors, suffix) => {
    let value;
    accessors.forEach(accessor => {
      if (value === null) {
        return;
      }
      value = 'undefined' === typeof value ? row[accessor] : value[accessor];
    });

    if (value === null) {
      return '-';
    } else {
      return suffix ? `${value} ${suffix}` : value;
    }
  };

  return (
    <Td
      onClick={handleClick}
      style={rowStyle}
      isStrikeThrough={row.isStrikeThrough}
    >
      {formatValue(row, column.accessors, column.type, column.suffix)}
    </Td>
  );
}

const Img = styled.img`
  height: 50px;
  margin: auto;
  display: block;
`;

const Link = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export default memo(CustomCell);
