import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminOptions } from 'redux/formOptions/actions';
import { AutocompleteFilter } from 'components/core/FilterInput';

AdminFilters.propTypes = {
  name: PropTypes.func,
  keepFilters: PropTypes.bool,
  useAdminType: PropTypes.bool,
};
AdminFilters.defaultProps = {
  keepFilters: true,
  onChange: () => {},
  useAdminType: true,
};

const AdminTypeOptions = Object.freeze([
  { value: 'mainAdmin', label: 'Account Manager' },
  { value: 'secondaryAdmin', label: 'Chargé de Recrutement' },
]);

export default function AdminFilters({ onChange, keepFilters, useAdminType }) {
  const dispatch = useDispatch();
  const adminOptions = useSelector(state => state.formOptions.adminOptions);
  const authenticatedAdmin = useSelector(state => state.auth.userId);

  useEffect(
    () => {
      if (!adminOptions) {
        dispatch(getAdminOptions({ onlyActive: 1 }));
      }
    },
    [adminOptions, dispatch]
  );

  const handleChange = ({ name, value }) => {
    const params = {};
    switch (name) {
      case 'admin':
        params.admin = value;
        break;
      case 'adminType':
        params.adminType = value;
        break;
      default:
        throw new Error(`invalid param name: ${name}`);
    }
    onChange(params);
  };

  return (
    <>
      <AutocompleteFilter
        name="admin"
        label="Administrateur"
        options={adminOptions}
        defaultValue={keepFilters ? null : authenticatedAdmin}
        onChange={handleChange}
      />
      {useAdminType && (
        <AutocompleteFilter
          name="adminType"
          label="Type Administrateur"
          options={AdminTypeOptions}
          onChange={handleChange}
          defaultValue={null}
        />
      )}
    </>
  );
}
