import { Grid } from '@material-ui/core';
import * as StateButtons from './Buttons';

export default function ApplicationState(props) {
  const { application, suggestion, preSuggestion, offerId, planId } = props;

  const state = application
    ? getStateFromApplication(application)
    : getStateFromSuggestion(suggestion);

  const handleStateChange = (
    state,
    reason = null,
    prefiledPlacement = null
  ) => {
    props.onStateChange(state, reason, prefiledPlacement);
  };
  const handleContactAttempt = () => {
    props.onContactAttempt();
  };
  const handleResumeUpdateRequest = () => {
    props.onResumeUpdateRequest();
  };
  const handleSuggestion = () => {
    props.onSuggestOffer();
  };
  const handleAppointmentRequest = () => {
    props.onAppointmentRequest();
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={4} xs={6}>
        <Grid container spacing={1}>
          <Grid item md={6} xs={6}>
            <StateButtons.Contract
              action={() => handleStateChange('refused', 'contract')}
              value={state.contract}
              disabled={suggestion}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.Competition
              action={() => handleStateChange('refused', 'competition')}
              value={state.competition}
              disabled={props.suggestion}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.Date
              action={() => handleStateChange('refused', 'date')}
              value={state.date}
              disabled={props.suggestion}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.Duration
              action={() => handleStateChange('refused', 'duration')}
              value={state.duration}
              disabled={props.suggestion}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.Profile
              action={() => handleStateChange('refused', 'profile')}
              value={state.profile}
              disabled={props.suggestion}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.Level
              action={() => handleStateChange('refused', 'level')}
              value={state.level}
              disabled={props.suggestion}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.Rhythm
              value={state.rhythm}
              action={() => handleStateChange('refused', 'rhythm')}
              disabled={props.suggestion}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.Experience
              value={state.experience}
              action={() => handleStateChange('refused', 'experience')}
              disabled={props.suggestion}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={4} xs={6}>
        <Grid container spacing={1}>
          <Grid item md={6} xs={6}>
            <StateButtons.Suggestion
              value={state.suggestion && !preSuggestion}
              action={handleSuggestion}
              disabled={
                props.application || (props.suggestion && state.abandoned)
              }
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.SuggestionReminder
              value={state.hasBeenReminded}
              action={handleSuggestion}
              disabled={
                props.application || (props.suggestion && state.abandoned)
              }
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.Message
              value={state.message}
              action={handleContactAttempt}
              disabled={props.suggestion}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.Appointment
              action={handleAppointmentRequest}
              disabled={props.suggestion}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.ShortList
              value={state.shortList}
              action={() => handleStateChange('shortList')}
              disabled={props.suggestion && state.abandoned}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.ResumeUpdateRequest
              value={state.resumeToUpdate}
              action={handleResumeUpdateRequest}
              disabled={props.suggestion}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.Hire
              value={state.hired}
              action={prefilledPlacement =>
                handleStateChange('hired', null, prefilledPlacement)
              }
              disabled={props.suggestion && state.abandoned}
              offerId={offerId}
              planId={planId}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.Send
              value={state.sent}
              action={() => handleStateChange('sent')}
              disabled={props.suggestion && state.abandoned}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={4} xs={6}>
        <Grid container spacing={1}>
          <Grid item md={6} xs={6}>
            <StateButtons.Refuse
              value={state.refused}
              action={() => handleStateChange('refused')}
              disabled={props.suggestion}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.Abandon
              value={state.abandoned}
              action={() => handleStateChange('abandoned')}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.Interview
              value={state.inInterview}
              action={() => handleStateChange('inInterview')}
              disabled={props.suggestion && state.abandoned}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.NotAvailableBeforeInterview
              value={state.notAvailableBeforeInterview}
              action={() =>
                handleStateChange('abandoned', 'notAvailableBeforeInterview')
              }
              disabled={props.suggestion}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.NotAvailableAfterInterview
              value={state.notAvailableAfterInterview}
              action={() =>
                handleStateChange('abandoned', 'notAvailableAfterInterview')
              }
              disabled={props.suggestion}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.Proposition
              value={state.proposition}
              action={() => handleStateChange('proposition')}
              disabled={props.suggestion && state.abandoned}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.NonProposition
              value={state.noProposition}
              action={() => handleStateChange('refused', 'noProposition')}
              disabled={props.suggestion}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <StateButtons.Resume
              value={state.resume}
              action={() => handleStateChange('refused', 'resume')}
              disabled={props.suggestion}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const getStateFromApplication = application => {
  const state = {};
  application.states.forEach(appState => {
    state[appState.code] = true;
    if (appState.reason) {
      state[appState.reason.code] = true;
    }
  });

  if (application.hasSuggestion) {
    state.suggestion = true;
    if (application.suggestion.hasBeenReminded) {
      state.suggestionReminded = true;
    }
  }
  if (application.hasContactAttempt) {
    state.message = true;
  }
  if (application.resumeToUpdate) {
    state.resumeToUpdate = true;
  }
  return state;
};

const getStateFromSuggestion = suggestion => {
  const state = {};
  state.suggestion = true;
  if (suggestion.hasBeenReminded) {
    state.hasBeenReminded = true;
  }
  if (suggestion.hasContactAttempt) {
    state.message = true;
  }
  if (suggestion.declinedDate) {
    state.abandoned = true;
  }
  return state;
};
