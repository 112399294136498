const pafs = {
  getOne: ({ id }) => ({
    url: `/placements/${id}`,
    method: 'get',
  }),
  get: ({ page, size, filter, orderBy, direction }) => ({
    url: '/pafs',
    method: 'get',
    params: { page, size, filter, orderBy, direction },
  }),
  create: params => ({
    url: '/placements',
    method: 'post',
    data: params.paf,
  }),
  clone: params => ({
    url: `/pafs/${params.id}/clone`,
    method: 'post',
  }),
  update: params => ({
    url: `/placements/${params.id}`,
    method: 'put',
    data: params,
  }),
  delete: params => ({
    url: `/pafs/${params.id}`,
    method: 'delete',
  }),
  getStats: ({ fromDate = null, toDate = null }) => ({
    url: '/placements/statistics',
    method: 'get',
    params: {
      'from-date': fromDate,
      'to-date': toDate,
    },
  }),
  getPrefilledByOffer: ({ offerId }) => ({
    url: `/offers/${offerId}/placements/prefilled`,
    method: 'get',
  }),
};

export default pafs;
