import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import axios from 'axios';
import { getHeaders } from 'utils/api/headers';
import { apiUrl, recruitmentPlanId } from 'utils/constants';
import services from 'utils/api/Services/services';

import {
  getCompanySizeOptions,
  getCompanyOptions,
  getContractTypeOptions,
  getPlanOptions,
  getBusinessSectorOptions,
} from 'redux/formOptions/actions';
import { getPafStatistics } from 'redux/pafStatistics/actions';

import { Paper, Box, Typography, withStyles, Tooltip } from '@material-ui/core';
import Main from 'components/layout/Main';
import Select from 'components/Select';
import StatTable from './statTable';
import ForcastsTable from './forecastsTable';

//import { months } from 'utils/constants';
import S from './styles';

function PafStatistics(props: Props) {
  const { classes } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  React.useEffect(
    () => {
      dispatch(getBusinessSectorOptions());
      dispatch(getContractTypeOptions());
      dispatch(getCompanySizeOptions());
      dispatch(getPlanOptions());
      dispatch(getCompanyOptions({}));
    },
    [dispatch]
  );

  const {
    businessSectorOptions,
    contractTypeOptions,
    companySizeOptions,
    offerTypeOptions,
    yearOptions,
    companyOptions,
  } = GetFormOptions(t);

  const [pafFilters, setPafFilters] = React.useState({
    offerType: recruitmentPlanId, // Set offerType default to "Recrutement"
  });
  React.useEffect(
    () => {
      const params = pafFilters;
      dispatch(getPafStatistics(params));
    },
    [pafFilters, dispatch]
  );
  const pafStatistics = useSelector(state => state.pafStatistics);

  const { fetchCompanyOptions } = fetchAsyncFormOptions();

  return (
    <Main pageTitle={t('statistics.title')} isLoading={pafStatistics.loading}>
      <Paper className={classes.root}>
        <Box>
          <Typography variant="h6" color="primary" gutterBottom>
            Mise en valeur des Placements
          </Typography>
          <Box className={classes.tableWrapper}>
            {pafStatistics &&
              pafStatistics.statistics && (
                <StatTable
                  statName="Mois de Lancemencent"
                  values={pafStatistics.statistics.recruitmentStartStats}
                />
              )}
          </Box>
          <Box className={classes.tableWrapper}>
            {pafStatistics &&
              pafStatistics.statistics && (
                <StatTable
                  statName={
                    <Tooltip title="Le mois potentiel d'intégration fait référence à la date de début d'une offre. Cette information est communiquée dans le back office lors de la création d'une offre">
                      <Box>Mois Potentiel d'Intégration</Box>
                    </Tooltip>
                  }
                  values={pafStatistics.statistics.candidateStartStats}
                />
              )}
          </Box>
          <Box className={classes.tableWrapper}>
            {pafStatistics &&
              pafStatistics.statistics && (
                <ForcastsTable
                  values={pafStatistics.statistics.monthlySuccessForecasts}
                />
              )}
          </Box>
          <Box className={classes.center}>
            <Typography
              style={{ marginTop: '30px' }}
              color="primary"
              gutterBottom
            >
              Affiner les valeurs
            </Typography>
            <Box className={clsx(classes.row, classes.center)}>
              <Select
                id="contractType"
                label="Type de contrat"
                name="contractType"
                style={classes.selectField}
                options={contractTypeOptions}
                onChange={value => {
                  setPafFilters({
                    ...pafFilters,
                    contractType: value ? value.value : null,
                  });
                }}
                value={pafFilters.contractType}
              />
              <Select
                id="companyType"
                label="Typologie des entreprises"
                name="companyType"
                style={classes.selectField}
                options={companySizeOptions}
                onChange={value => {
                  setPafFilters({
                    ...pafFilters,
                    companyType: value ? value.value : null,
                  });
                }}
                value={pafFilters.companyType}
              />
              <Select
                id="offerType"
                label="Type d'offre"
                name="offerType"
                style={classes.selectField}
                options={offerTypeOptions}
                onChange={value => {
                  setPafFilters({
                    ...pafFilters,
                    offerType: value ? value.value : null,
                  });
                }}
                value={offerTypeOptions ? offerTypeOptions[1] : null}
              />
              <Select
                id="businessSector"
                label="Secteur d'activité"
                name="businessSector"
                style={classes.selectField}
                options={businessSectorOptions}
                onChange={value => {
                  setPafFilters({
                    ...pafFilters,
                    businessSector: value ? value.value : null,
                  });
                }}
                value={pafFilters.businessSector}
              />
            </Box>
            <Box className={clsx(classes.row, classes.center)}>
              <Select
                id="company"
                label="Entreprise"
                name="company"
                style={classes.selectField}
                options={companyOptions}
                onChange={value => {
                  setPafFilters({
                    ...pafFilters,
                    company: value ? value.value : null,
                  });
                }}
                value={pafFilters.company}
                isAsync
                fetchOptions={fetchCompanyOptions}
              />
              <Select
                id="year"
                label="Année"
                name="year"
                style={classes.selectField}
                options={yearOptions}
                onChange={value => {
                  setPafFilters({
                    ...pafFilters,
                    year: value ? value.value : null,
                  });
                }}
                value={pafFilters.year}
              />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Main>
  );
}

export default withStyles(S)(PafStatistics);

const GetFormOptions = () => {
  const formOptionsState = useSelector(state => state.formOptions);

  const now = new Date();
  const currYear = now.getFullYear();
  const pafStartYear = 2017;
  let yearOptions = [];
  for (let i = 0; i <= currYear - pafStartYear; i++) {
    yearOptions.push({
      value: pafStartYear + i,
      label: pafStartYear + i,
    });
  }
  yearOptions = yearOptions.reverse();

  return {
    businessSectorOptions: formOptionsState.businessSectorOptions,
    contractTypeOptions: formOptionsState.contractTypeOptions,
    companySizeOptions: formOptionsState.companySizeOptions,
    offerTypeOptions: formOptionsState.planOptions,
    yearOptions: yearOptions,
    companyOptions: formOptionsState.companyOptions,
  };
};

const fetchAsyncFormOptions = () => {
  const fetchCompanyOptions = (inputValue, callback) => {
    const { url, method, params } = services.options.getCompanies({
      search: inputValue,
    });
    axios({
      method: method,
      url: `${apiUrl}` + url,
      params: params,
      headers: getHeaders(),
    })
      .then(response => {
        const json = response.data;
        callback(json);
        /*  callback(json.map(company => ({
            value: company.id,
            label: company.name,
          }))) */
      })
      .catch(error => {
        console.log(error);
      });
  };

  return {
    fetchCompanyOptions: fetchCompanyOptions,
  };
};
