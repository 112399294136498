import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getRecruiterRoleOptions } from 'redux/formOptions/actions';

import {
  Grid,
  FormControlLabel,
  Checkbox,
  withStyles,
} from '@material-ui/core';

import Autocomplete from 'components/core/input/Autocomplete';
import TextField from 'components/core/input/TextField';
import SubmitButtons from 'components/core/SubmitButtons';
import S from './styles';

function Form(props) {
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    classes,
    isSubmitting,
  } = props;

  const dispatch = useDispatch();
  const options = useSelector(state => state.formOptions);
  useEffect(
    () => {
      if (!options.recruiterRoleOptions) {
        dispatch(getRecruiterRoleOptions());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleChange = (fieldName, value) => {
    setFieldValue(fieldName, value, true);
    setFieldTouched(fieldName, true, false);
  };

  return (
    <form onSubmit={handleSubmit} style={{ padding: 20 }}>
      <Grid container spacing={8}>
        <Grid item xs={6} md={4}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
              {/* FirstName */}
              <TextField
                label="Prénom"
                touched={touched.firstName}
                error={errors.firstName}
                className={classes.field}
                handleChange={({ value }) => {
                  handleChange('firstName', value);
                }}
                value={values.firstName}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              {/* LastName */}
              <TextField
                label="Nom"
                touched={touched.lastName}
                error={errors.lastName}
                className={classes.field}
                handleChange={({ value }) => {
                  handleChange('lastName', value);
                }}
                value={values.lastName}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              {/* Mail */}
              <TextField
                label="Mail"
                touched={touched.mail}
                error={errors.mail}
                className={classes.field}
                handleChange={({ value }) => {
                  handleChange('mail', value);
                }}
                value={values.mail}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              {/* Phone */}
              <TextField
                label="Téléphone"
                touched={touched.phone}
                error={errors.phone}
                className={classes.field}
                handleChange={({ value }) => {
                  handleChange('phone', value);
                }}
                value={values.phone}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              {/* Role */}
              <Autocomplete
                defaultValue={values.role}
                options={options.recruiterRoleOptions}
                label="Role"
                error={errors.role}
                touched={touched.role}
                onChange={({ value }) => {
                  handleChange('role', value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {/* Position */}
              <TextField
                label="Poste"
                touched={touched.position}
                error={errors.position}
                className={classes.field}
                handleChange={({ value }) => {
                  handleChange('position', value);
                }}
                value={values.position}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={4}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
              {/* Password */}
              <TextField
                type="password"
                label="Mot de Passe"
                touched={touched.password}
                error={errors.password}
                className={classes.field}
                handleChange={({ value }) => {
                  handleChange('password', value);
                }}
                value={values.password}
              />
            </Grid>
            <Grid item xs={6} md={6} />
            <Grid item xs={6} md={6}>
              {/* Disable */}
              <FormControlLabel
                className={classes.checkbox}
                label="Actif"
                control={
                  <Checkbox
                    id="active"
                    checked={values.active}
                    onChange={() => {
                      const value = values.active ? false : true;
                      handleChange('active', value);
                    }}
                    color="primary"
                  />
                }
                labelPlacement="end"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SubmitButtons isSubmitting={isSubmitting} />
    </form>
  );
}

export default withStyles(S)(Form);
