import DropdownButton from 'components/DropdownButton';
import Subtitle from 'components/layout/Subtitle';
import CommercialProposalTable from 'components/eiquem/commercialProposal/Table';

function CommercialProposals(props) {
  const companyId = props.companyId;

  return (
    <DropdownButton
      label={<Subtitle label="Propales" />}
      content={
        <CommercialProposalTable companyId={companyId} displayActions={false} />
      }
    />
  );
}

export default CommercialProposals;
