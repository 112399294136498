import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deletePaf } from 'redux/pafs/actions';
import { ROUTES } from 'utils/routes';

import { pafTableDataFormat } from 'pages/Placement/helper';
import { months, tableColumns, legend } from 'pages/Placement/Summary/helper';

import Legend from 'components/core/Legend';
import Table from 'components/core/Table';
import EditButton from 'components/Table/Actions/EditButton';
import RemoveButton from 'components/Table/Actions/RemoveButton';
import RowDetails from 'components/eiquem/placement/paf/Table/RowDetail/rowDetail';
import styled from 'styled-components';
import PanelTitle from 'components/eiquem/shared/PanelTitle';
import YearInput from 'components/eiquem/shared/YearInput';

const SummaryDetail = memo(props => {
  const { month, year, setYear, rows, title, type } = props;
  const dispatch = useDispatch();

  const checkContractChanged = type === 'renewed' ? true : false;

  const tableData = rows
    ? pafTableDataFormat(rows.pafs, checkContractChanged, month, year)
    : null;

  const handleDelete = paf => {
    dispatch(deletePaf({ paf: paf }));
  };

  const actionComponents = props => (
    <>
      <EditButton {...props} pathname={ROUTES.PAF_EDIT} />
      <RemoveButton {...props} action={handleDelete} />
    </>
  );

  const dateAccessor = type => {
    switch (type) {
      case 'paf':
        return 'pafDate';
      case 'starting':
        return 'candidateStartDate';
      case 'invoiced':
        return 'invoiceDate';
      case 'renewed':
        return 'secondVariableDate';
      default:
        return 'pafDate';
    }
  };

  const pafSummaryState = useSelector(state => state.pafSummary);

  return (
    <Container>
      <PanelTitle
        label={`${title} - ${months[month - 1]}`}
        legend={<Legend items={legend(type)} />}
        yearInput={<YearInput year={year} setYear={setYear} />}
      />
      <Table
        maxHeight={530}
        loading={pafSummaryState.loading}
        columns={tableColumns(dateAccessor(type))}
        rows={tableData}
        actions={actionComponents}
        detail={RowDetails}
      />
    </Container>
  );
});

export default SummaryDetail;

const Container = styled.div`
  padding: 30px 30px 10px 30px;
`;
