import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { RichTextEditor } from 'components/core/input';
import DOMPurify from 'dompurify';

export default function RichInput({ message, onChange, editMode }) {
  const classes = useStyles();

  return (
    <>
      {editMode ? (
        <RichTextEditor htmlValue={message} handleChange={onChange} />
      ) : (
        <div
          className={classes.field}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}
        />
      )}
    </>
  );
}

const useStyles = makeStyles({
  field: {
    width: '100%',
  },
});

RichInput.propTypes = {
  message: PropTypes.string,
  onChange: PropTypes.func,
  editMode: PropTypes.bool,
};

RichInput.defaultProps = {
  message: null,
  onChange: () => {},
  editMode: false,
};
