import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { CommercialProposalFormSchema } from 'utils/formSchemas';

import { handleValues } from 'components/eiquem/commercialProposal/Form/helper';
import {
  useFetchProposal,
  useUpdateProposal,
} from 'utils/hooks/commercialProposal';

import { Paper } from '@material-ui/core';
import Title from 'components/layout/Title';
import Main from 'components/layout/Main';
import Form from 'components/eiquem/commercialProposal/Form/form';

export default function EditProposal(props) {
  const { t } = useTranslation();

  const id = props.id;

  const [proposal, isLoading] = useFetchProposal(id);
  const [updateProposal] = useUpdateProposal(id);

  const handleOnSubmit = values => {
    updateProposal(handleValues(values));
  };

  return (
    <Main pageTitle={t('commercialProposal.title')} isLoading={isLoading}>
      <Title>
        {t('commercialProposal.edit')} - {id}
      </Title>

      <Paper>
        {proposal && (
          <Formik
            initialValues={{
              ...proposal,
              isProspect: proposal.company ? false : true,
            }}
            validationSchema={CommercialProposalFormSchema}
            onSubmit={handleOnSubmit}
          >
            {props => <Form {...props} />}
          </Formik>
        )}
      </Paper>
    </Main>
  );
}
