import PropTypes from 'prop-types';
import styled from 'styled-components';

Pagination.propTypes = {
  page: PropTypes.number,
  pageSize: PropTypes.number,
  itemCount: PropTypes.number,
  pageSizeOptions: PropTypes.array,
  onChange: PropTypes.func,
};
Pagination.defaultProps = {
  page: 0,
  pageSize: 0,
  itemCount: 0,
  onChange: () => {},
};

function Pagination({ page, pageSize, itemCount, pageSizeOptions, onChange }) {
  const pageCount = Math.floor(itemCount / pageSize) + 1;

  return (
    <Container>
      <span>Lignes par page : </span>
      <PageSizeSelect name="page-size" value={pageSize} onChange={onChange}>
        {pageSizeOptions.map(pageSize => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </PageSizeSelect>
      <span>
        {' '}
        {page}/{pageCount} - {itemCount} résultats{' '}
      </span>
      <PageButton name="prev" onClick={onChange} disabled={page <= 1}>
        {'<'}
      </PageButton>
      <PageButton name="next" onClick={onChange} disabled={page >= pageCount}>
        {'>'}
      </PageButton>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  text-align: right;
  padding: 5px;
`;

const PageButton = styled.button`
  margin: 5px;
  background: transparent;
  border: none;
  &:hover {
    cursor: pointer;
  }
  font-weight: bold;
`;

const PageSizeSelect = styled.select`
  border: none;
  background: transparent;
  margin: 5px;
`;

export default Pagination;
