import { useCallback, useEffect, useState } from 'react';
import { useQueryState } from 'utils/hooks/queryState';

const castArray = value => (Array.isArray(value) ? value : [value]);

const useSelectRows = (selectable, rows) => {
  const [selectedRows, setSelectedRows] = useQueryState([], 'rows');
  const [allRowsSelected, setAllRowsSelected] = useState(false);

  useEffect(() => {
    if (!selectable && selectedRows.length) {
      cleanSelectedRows();
    }
  }, []);

  const handleSelectRow = useCallback(
    ({ id, value }) => {
      if (!selectable) return null;
      const ids = value
        ? [...castArray(selectedRows), id]
        : castArray(selectedRows).filter(row => parseInt(row) !== id);

      setSelectedRows(ids);
      setAllRowsSelected(false);
    },
    [selectedRows]
  );

  const handleSelectAllRows = ({ value }) => {
    setSelectedRows(value ? rows.map(row => parseInt(row.id)) : []);
    setAllRowsSelected(!allRowsSelected);
  };

  const cleanSelectedRows = () => {
    setSelectedRows([]);
    setAllRowsSelected(false);
  };

  return {
    selectedRows: castArray(selectedRows),
    allRowsSelected,
    handleSelectRow,
    handleSelectAllRows,
    cleanSelectedRows,
  };
};

export default useSelectRows;
