import { recruitmentPlanId } from 'utils/constants';

import {
  AbandonedOffer,
  Admin,
  Candidate,
  CandidateStartDate,
  Comments,
  Company,
  ContractDuration,
  computeContractDuration,
  ContractType,
  FirstVariableInvoicingDate,
  FixedPrice,
  FollowUps,
  computeFollowUps,
  IsCompletedOffer,
  IsFinalInternship,
  IsSecondVariable,
  Offer,
  OfferInDifficulty,
  PlacementDate,
  Plan,
  RecruitmentStartDate,
  SecondVariableInvoicingDate,
  computeSecondVariablePotentialDate,
  SecondVariablePotentialDate,
  SecondVariablePrice,
  VacantPositions,
  VariablePrice,
} from './fields';
import { Grid } from 'components/core/containers';
import SubmitButtons from 'components/core/SubmitButtons';
import { completeFollowUps } from './fields/FollowUps';

export default function Form(props) {
  const { values, handleSubmit, setValues, isSubmitting } = props;

  const handleChange = ({ name, value }) => {
    computeFormValues({ name, value }, values, setValues);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={50}>
        <Grid md={4} sm={12}>
          <Grid container>
            <Grid md={6}>
              <Admin onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6} />
            <Grid md={6}>
              <RecruitmentStartDate onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6}>
              <Plan onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6}>
              <Company onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6}>
              <Offer onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6}>
              <ContractType onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6}>
              <ContractDuration onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6}>
              <VacantPositions onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6} />
            <Grid md={6}>
              <OfferInDifficulty onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6}>
              <AbandonedOffer onChange={handleChange} {...props} />
            </Grid>
            <Grid md={12}>
              <Comments onChange={handleChange} {...props} />
            </Grid>
          </Grid>
        </Grid>

        <Grid md={4} sm={12}>
          <Grid container>
            <Grid md={6}>
              <FixedPrice onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6}>
              <VariablePrice onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6}>
              <IsSecondVariable onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6}>
              <SecondVariablePrice onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6}>
              <PlacementDate onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6} />
            <Grid md={6}>
              <Candidate onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6}>
              <CandidateStartDate onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6}>
              <IsFinalInternship onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6} />
            <Grid md={6}>
              <FirstVariableInvoicingDate onChange={handleChange} {...props} />
            </Grid>
          </Grid>
        </Grid>

        <Grid md={4} sm={12}>
          <Grid container>
            <FollowUps onChange={handleChange} {...props} />
            <Grid md={6}>
              <SecondVariablePotentialDate onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6}>
              <SecondVariableInvoicingDate onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6}>
              <IsCompletedOffer onChange={handleChange} {...props} />
            </Grid>
            <Grid md={6} />
          </Grid>
        </Grid>
      </Grid>

      <SubmitButtons isSubmitting={isSubmitting} />
    </form>
  );
}

const computeFormValues = ({ name, value }, values, setValues) => {
  switch (name) {
    case 'plan': {
      const plan = value;
      const planId = plan?.value;

      let newValues = {
        plan: value,
        followUps: computeFollowUps(
          values.candidateStartDate,
          values.contractDuration,
          plan
        ),
      };

      if (planId !== recruitmentPlanId) {
        newValues = {
          ...newValues,
          variableCost: 0,
          variableCostHiring: 0,
          secondVariable: false,
          secondVariableDate: null,
        };
      }
      setValues({ ...values, ...newValues });
      break;
    }
    case 'company': {
      setValues({
        ...values,
        company: value,
        offer: null,
      });
      break;
    }
    case 'contractType': {
      setValues({
        ...values,
        contractType: value,
        contractDuration: computeContractDuration(value?.label),
      });
      break;
    }
    case 'contractDuration': {
      const duration = value;
      const startDate = new Date(values.candidateStartDate);
      setValues({
        ...values,
        contractDuration: duration,
        secondVariableDate: computeSecondVariablePotentialDate(
          duration,
          startDate,
          values.plan
        ),
        followUps:
          startDate < new Date()
            ? values.followUps
            : computeFollowUps(startDate, duration, values.plan),
      });
      break;
    }
    case 'candidateStartDate': {
      const startDate = value ? new Date(value) : null;
      setValues({
        ...values,
        candidateStartDate: startDate,
        secondVariableDate: startDate
          ? computeSecondVariablePotentialDate(
              values.contractDuration,
              startDate,
              values.plan
            )
          : null,
        followUps: startDate
          ? startDate < new Date()
            ? values.followUps
            : computeFollowUps(startDate, values.contractDuration, values.plan)
          : [],
      });
      break;
    }
    case 'secondVariable': {
      setValues({
        ...values,
        secondVariable: value,
        variableCostHiring: value ? values.variableCostHiring : 0,
      });
      break;
    }
    case 'completedOffer': {
      const isCompleted = value;
      const followUps = values.followUps;
      setValues({
        ...values,
        completedOffer: isCompleted,
        followUps: isCompleted ? completeFollowUps(followUps) : followUps,
      });
      break;
    }
    case 'abandonedOffer': {
      const isAbandoned = value;
      const followUps = values.followUps;
      setValues({
        ...values,
        abandonedOffer: isAbandoned,
        followUps: isAbandoned ? null : followUps,
      });
      break;
    }

    default: {
      setValues({ ...values, [name]: value });
    }
  }
};
