import styled from 'styled-components';
import { theme } from 'utils/theme';

const Subtitle = ({ label }) => <StyledSubtitle>{label}</StyledSubtitle>;

const StyledSubtitle = styled.span`
  font-weight: 700;
  font-style: italic;
  color: grey;
  font-size: ${theme.typography.fontSize + 3}px;
`;

export default Subtitle;
