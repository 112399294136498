import { useEffect } from 'react';
import PropTypes from 'prop-types';
import MultiAutocompleteFilter from 'components/core/FilterInput/multiAutocompleteFilter';
import { useFetchPlans } from 'utils/hooks/formOptions';

PlanFilter.propTypes = {
  onChange: PropTypes.func,
};
PlanFilter.defaultProps = {
  onChange: () => {},
};

export default function PlanFilter({ onChange }) {
  const { plans, handleChange } = usePlanFilter({ onChange });

  return (
    <>
      <MultiAutocompleteFilter
        name="plans"
        label="Plans"
        options={plans}
        onChange={handleChange}
        defaultValue={null}
      />
    </>
  );
}

function usePlanFilter({ onChange }) {
  const [fetchPlans, plans] = useFetchPlans();
  useEffect(
    () => {
      if (!plans) {
        fetchPlans();
      }
    },
    [plans, fetchPlans]
  );

  const handleChange = ({ name, value }) => {
    const params = {};
    switch (name) {
      case 'plans':
        params.planIds = value;
        break;
      default:
        throw new Error(`invalid param name: ${name}`);
    }
    onChange(params);
  };

  return {
    plans,
    handleChange,
  };
}
