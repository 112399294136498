import { useHistory } from 'react-router-dom';
import { Add } from '@material-ui/icons';
import ActionButton from 'components/ActionButton';

const icon = <Add />;

function CreateButton(props) {
  const { pathname, action, id } = props;

  const history = useHistory();
  const prevPath = history.location.pathname;

  const handleOnClick = () => {
    if (pathname) {
      history.push(pathname.replace(':id', id), {
        id: id,
        prevPath: prevPath,
      });
    } else if (action) {
      action();
    }
  };

  return (
    <ActionButton
      action={handleOnClick}
      label="Ajouter"
      icon={icon}
      tooltipPosition="left"
    />
  );
}

export default CreateButton;
