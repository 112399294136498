import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatDate } from 'utils/functions';
import { Check } from '@material-ui/icons';

Item.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  type: PropTypes.string,
};

function Item({ label, value, type }) {
  const formatValue = value => {
    if (value === null || typeof value === 'undefined' || value === false) {
      return '-';
    }

    switch (type) {
      case 'date':
        return formatDate(value);
      case 'bool':
        return <Check fontSize="small" />;
      case 'currency':
        return formatter.format(value ?? 0);
      case 'days':
        return `${value} ${value === 1 ? 'jour' : 'jours'}`;
      case 'component':
        return value;
      default:
        return <div>{value}</div>;
    }
  };

  return (
    <Container>
      <Label>{label}</Label>
      <Value>{formatValue(value)}</Value>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

const Label = styled.span`
  font-weight: bold;
`;

const Value = styled.div`
  padding-left: 2px;
`;

const formatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
});

export default memo(Item);
