import { ACTIONS_TYPE } from './actions';
import type { Action } from 'utils/types';

export type StatisticsStateType = {
  loading: Boolean,
  candidates: {
    registersByProvenances: any,
    hiresByProvenances: any,
    slByProvenances: any,
  },
  invoices: {
    turnover: any,
    fixed: any,
    variable: any,
    count: any,
    customer: any,
    summary: any,
    activeAmount: any,
  },
};

export const initialState: statisticsStateType = {
  loading: false,
  candidates: {
    registersByProvenances: null,
    hiresByProvenances: null,
    slByProvenances: null,
  },
  invoices: {
    turnover: null,
    fixed: null,
    variable: null,
    count: null,
    customer: null,
    summary: null,
    activeAmount: null,
  },
};

export function statisticsReducer(
  state: CandidatesStatisticsStateType = initialState,
  action: Action
) {
  switch (action.type) {
    case ACTIONS_TYPE.GET_REGISTRATIONS_PROVENANCES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_REGISTRATIONS_PROVENANCES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_REGISTRATIONS_PROVENANCES_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        candidates: {
          ...state.candidates,
          registersByProvenances: action.result.statistics,
        },
      });

    case ACTIONS_TYPE.GET_HIRES_PROVENANCES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_HIRES_PROVENANCES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_HIRES_PROVENANCES_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        candidates: {
          ...state.candidates,
          hiresByProvenances: action.result.statistics,
        },
      });

    case ACTIONS_TYPE.GET_SL_PROVENANCES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_SL_PROVENANCES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_SL_PROVENANCES_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        candidates: {
          ...state.candidates,
          slByProvenances: action.result.statistics,
        },
      });

    case ACTIONS_TYPE.GET_INVOICES_TURNOVER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_INVOICES_TURNOVER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_INVOICES_TURNOVER_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        invoices: {
          ...state.invoices,
          turnover: action.result.statistics,
        },
      });

    case ACTIONS_TYPE.GET_INVOICES_FIXED_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_INVOICES_FIXED_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_INVOICES_FIXED_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        invoices: {
          ...state.invoices,
          fixed: action.result.statistics,
        },
      });

    case ACTIONS_TYPE.GET_INVOICES_VARIABLE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_INVOICES_VARIABLE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_INVOICES_VARIABLE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        invoices: {
          ...state.invoices,
          variable: action.result.statistics,
        },
      });

    case ACTIONS_TYPE.GET_INVOICES_COUNT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_INVOICES_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_INVOICES_COUNT_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        invoices: {
          ...state.invoices,
          count: action.result.statistics,
        },
      });

    case ACTIONS_TYPE.GET_INVOICES_CUSTOMER_TYPE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_INVOICES_CUSTOMER_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_INVOICES_CUSTOMER_TYPE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        invoices: {
          ...state.invoices,
          customer: action.result.statistics,
        },
      });

    case ACTIONS_TYPE.GET_INVOICES_SUMMARY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_INVOICES_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_INVOICES_SUMMARY_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        invoices: {
          ...state.invoices,
          summary: action.result.statistics,
        },
      });

    case ACTIONS_TYPE.GET_INVOICES_ACTIVE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_INVOICES_ACTIVE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_INVOICES_ACTIVE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        invoices: {
          ...state.invoices,
          activeAmount: action.result.statistics.totalActive,
        },
      });
    default:
      return state;
  }
}
