const followUps = {
  get: ({ year = null, month = null, adminId = null, adminType = null }) => ({
    url: '/follow-ups',
    method: 'get',
    params: {
      year,
      month,
      adminId,
      adminType,
    },
  }),
  update: ({ id = null, followUpDate = null, isDone = null }) => ({
    url: `/follow-ups/${id}`,
    method: 'put',
    data: {
      followUpDate,
      isDone,
    },
  }),
  remindAndPostpone: ({ id = null }) => ({
    url: `/follow-ups/${id}/remind-and-postpone`,
    method: 'patch',
  }),
};

export default followUps;
