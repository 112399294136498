import { getInvoices } from './actions';
import { ACTIONS_TYPE } from 'redux/invoices/actions';
import { ROUTES } from 'utils/routes';
import { pushRedirection } from 'utils/functions';
import history from 'utils/history';

export const invoicesMiddleware = ({ dispatch }) => next => action => {
  if (!action) return;
  if (
    action.type === ACTIONS_TYPE.SEND_INVOICES_SUCCESS ||
    action.type === ACTIONS_TYPE.REMIND_INVOICE_SUCCESS ||
    action.type === ACTIONS_TYPE.SET_INVOICE_REMINDER_PROGRAM_SUCCESS ||
    action.type === ACTIONS_TYPE.SET_INVOICE_AS_PAID_SUCCESS ||
    action.type === ACTIONS_TYPE.REMIND_INVOICE_SUCCESS
  ) {
    console.log('invoicesMiddleware called...');
    const historyState = history.location.state || null;
    const historyParams = historyState
      ? {
          ...historyState.filterFormated,
          filter: historyState.search,
          page: historyState.page ? historyState.page + 1 : 1,
          size: historyState.rowsPerPage,
        }
      : null;

    const params = historyParams || {};

    console.log('get invoices with params', params);
    dispatch(getInvoices(params));
  } else if (
    action.type === ACTIONS_TYPE.CREATE_INVOICE_SUCCESS ||
    action.type === ACTIONS_TYPE.UPDATE_INVOICE_SUCCESS
  ) {
    console.log('invoicesMiddleware called...');
    console.log('redirect to previous url...');
    const expectedPaths = [ROUTES.INVOICES_LIST];
    pushRedirection(expectedPaths);
  }
  next(action);
};
