import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { anonymizeCandidate } from 'redux/candidates/actions';
import { ROLES } from 'utils/roles';

import DeleteIcon from '@material-ui/icons/Delete';
import ActionButton from 'components/ActionButton';

function AnonymizeButton({ id }) {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    if (id === null) {
      throw new Error('ID required to anonymize a candidate');
    }
    dispatch(anonymizeCandidate(id));
  };

  const userRoles = useSelector(state => state.auth.userRoles);
  const disabled = userRoles.includes(ROLES.SUPER_ADMIN) === false;

  return (
    <ActionButton
      icon={<DeleteIcon fontSize="small" />}
      action={handleOnClick}
      label="Anonymiser"
      dialog
      title="Confirmation d'anonymisation"
      contentText="Souhaites-tu vraiment anonymiser ce candidat ?"
      confirmText="Oui"
      cancelText="Non"
      disabled={disabled}
    />
  );
}

export default memo(AnonymizeButton);
