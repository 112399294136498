import {
  lightBlueFontCode,
  redFontCode,
  greenFontCode,
  orangeFontCode,
} from 'utils/constants';
import { getCommercialProposals } from 'redux/commercialProposals/actions';
import { formatDate } from 'utils/functions';

export const columns = [
  {
    header: 'Admin',
    accessors: ['admin', 'label'],
    style: {
      fontWeight: 'bold',
      width: '10%',
    },
  },
  {
    header: 'Modèle',
    accessors: ['model'],
    style: {
      width: '10%',
    },
  },
  {
    header: 'Entreprise',
    accessors: ['companyLabel'],
    headerStyle: {
      width: '15%',
    },
    style: {
      fontWeight: 'bold',
    },
  },
  {
    header: 'Nom',
    accessors: ['label'],
    style: {
      fontWeight: 'bold',
      width: '15%',
    },
  },
  {
    header: 'Téléphone',
    accessors: ['phone'],
    style: {
      width: '10%',
    },
  },
  {
    header: 'Responsable',
    accessors: ['contact'],
    style: {
      width: '10%',
    },
  },
  {
    header: 'Frais fixes',
    accessors: ['fixedPrice'],
    style: {
      width: '10%',
    },
  },
  {
    header: 'Frais variables',
    accessors: ['variablePrice'],
    style: {
      width: '10%',
    },
  },
  {
    header: 'Création',
    accessors: ['createdAt'],
    type: 'date',
  },
  {
    header: 'Statut',
    accessors: ['status', 'label'],
    headerStyle: {
      width: '10%',
    },
  },
];

export const format = proposals =>
  proposals.map(proposal => {
    const row = {
      ...proposal,
      companyLabel: proposal.company
        ? proposal.company.label
        : proposal.prospect,
    };

    const status = proposal.status.label;
    switch (status) {
      case 'En attente': {
        row.backgroundColor = orangeFontCode;
        break;
      }
      case 'Signée': {
        row.backgroundColor = greenFontCode;
        break;
      }
      case 'Refusée': {
        row.backgroundColor = redFontCode;
        break;
      }
      default:
        row.backgroundColor = lightBlueFontCode;
    }

    return row;
  });

export const legend = [
  { color: lightBlueFontCode, label: 'En cours' },
  { color: orangeFontCode, label: 'En attente' },
  { color: redFontCode, label: 'Refusée' },
  { color: greenFontCode, label: 'Signée' },
];

export const fetchProposals = (
  {
    search,
    admin,
    company,
    contract,
    fromDate,
    toDate,
    page,
    pageSize,
    status,
  },
  dispatch,
  qs
) => {
  dispatch(
    getCommercialProposals({
      search: isDef(search) ? search : qs.get('search'),
      adminId: isDef(admin) ? admin : qs.get('admin'),
      companyId: isDef(company) ? company : qs.get('company'),
      contractTypeId: isDef(contract) ? contract : qs.get('contract'),
      fromDate: formatDate(isDef(fromDate) ? fromDate : qs.get('from-date')),
      toDate: formatDate(isDef(toDate) ? toDate : qs.get('to-date')),
      page: page || qs.get('page') || 1,
      size: pageSize || qs.get('page-size'),
      statusId: isDef(status) ? status : qs.get('status'),
    })
  );
};

const isDef = value => typeof value !== 'undefined';
