import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFollowUps } from 'redux/followUps/actions';
import { MonthTabs, PeriodTabs, PERIOD_KEYS } from './Tabs';
import { Panel } from './Panel';
import { useQuery, useQueryState } from 'utils/hooks';
import { currYear } from 'pages/Placement/Summary/helper';

export default function FollowUpMultiTable() {
  const {
    selectedPeriod,
    followUps,
    year,
    setYear,
    isMount,
  } = useFollowUpManager();

  return (
    <>
      <PeriodTabs />
      <div style={{ display: 'flex' }}>
        <MonthTabs />
        {[
          PERIOD_KEYS.w1,
          PERIOD_KEYS.w4,
          PERIOD_KEYS.q,
          PERIOD_KEYS.m1,
          PERIOD_KEYS.m,
        ].map(
          periodKey =>
            selectedPeriod === periodKey ? (
              <Panel
                key={periodKey}
                index={periodKey}
                currValue={selectedPeriod}
                rows={followUps[periodKey]}
                year={year}
                setYear={setYear}
                isMount={isMount}
              />
            ) : (
              <div key={periodKey} />
            )
        )}
      </div>
    </>
  );
}

function useFollowUpManager() {
  const dispatch = useDispatch();
  const qs = useQuery();
  const month = qs.get('month');
  const [year, setYear] = useQueryState(currYear, 'year');
  const isMount = useRef(false);
  const admin = qs.get('admin');
  const adminId = admin ? Number.parseInt(admin) : null;
  const adminType = qs.get('adminType');

  useEffect(
    () => {
      if (month && year && (adminId || (!adminId && isMount.current))) {
        const params = {
          month,
          year,
          adminId: adminId,
          adminType,
        };
        dispatch(getFollowUps(params));
        isMount.current = true;
      }
    },
    [dispatch, month, year, adminId, adminType]
  );

  const followUps = useSelector(state => state.followUps.items);
  const selectedPeriod = qs.get('period');

  return { selectedPeriod, followUps, year, setYear, isMount: isMount.current };
}
