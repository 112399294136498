import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import services from 'utils/api/Services/services';

import AutocompleteAsync from 'components/core/input/AutocompleteAsync';
import { Box, TextField } from '@material-ui/core';
import { Subtitle } from './subtitle';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

export default function LocalizationFields(props) {
  const {
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    classes,
  } = props;

  const { t } = useTranslation();
  const [showFranceCities, setShowFranceCities] = useState(true);
  const [showWorldCities, setShowWorldCities] = useState(false);

  const handleCountryChange = values => {
    if (values.length < 1) {
      setShowFranceCities(false);
      setShowWorldCities(false);
      return;
    }

    setShowFranceCities(
      values.find(country => country.label === 'France') ? true : false
    );
    setShowWorldCities(
      values.find(country => country.label !== 'France') ? true : false
    );
  };

  const fetchCountriesService = inputValue =>
    services.jobboards.letudiant.getCountries({
      filter: inputValue,
    });
  const fetchFranceCitiesService = inputValue =>
    services.jobboards.letudiant.getCities({
      filter: inputValue,
    });

  return (
    <Box>
      <Subtitle label="Localisations" />
      <AutocompleteAsync
        multi
        service={fetchCountriesService}
        defaultValue={values.countries}
        className={classes.selectLargeField}
        label={t('jobboards-posting.field.countries')}
        error={errors.countries}
        touched={touched.countries}
        onChange={({ value }) => {
          setFieldValue('countries', value);
          setFieldTouched('countries', true, false);

          handleCountryChange(value);
        }}
      />
      {showFranceCities && (
        <AutocompleteAsync
          multi
          service={fetchFranceCitiesService}
          className={classes.selectLargeField}
          label={t('jobboards-posting.field.franceCities')}
          error={errors.franceCities}
          touched={touched.franceCities}
          onChange={({ value }) => {
            setFieldValue('franceCities', value);
            setFieldTouched('franceCities', true, false);
          }}
        />
      )}
      {showWorldCities && (
        <Autocomplete
          multiple
          onChange={(_, values) => {
            const newValues = values.map(value => ({
              value: value.inputValue ? value.inputValue : value,
              label: value.inputValue ? value.inputValue : value,
            }));

            setFieldValue('worldCities', newValues);
            setFieldTouched('worldCities', true, false);
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`,
              });
            }
            return filtered;
          }}
          id="worldCities"
          getOptionLabel={option => {
            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.title;
          }}
          renderOption={option => option.title}
          className={classes.selectLargeField}
          freeSolo
          renderInput={params => (
            <TextField
              {...params}
              id="worldCities"
              variant="standard"
              label={t('jobboards-posting.field.worldCities')}
              fullWidth
              error={touched.worldCities && Boolean(errors.worldCities)}
              helperText={touched.worldCities ? errors.worldCities : ''}
            />
          )}
        />
      )}
    </Box>
  );
}
