import { useTranslation } from 'react-i18next';
import addMonths from 'date-fns/addMonths';
import { recruitmentPlanId } from 'utils/constants';

import { DatePicker } from 'components/core/input';

export function SecondVariablePotentialDate({ values, onChange, name }) {
  const { t } = useTranslation();

  return (
    <DatePicker
      name={name ?? 'secondVariableDate'}
      label={t('paf.field.secondVariableDate')}
      value={name ? values[name] : values.secondVariableDate}
      onChange={onChange}
    />
  );
}

export const computeSecondVariablePotentialDate = (
  duration,
  candidateStartDate,
  plan
) => {
  if (candidateStartDate === null || duration === null || plan === null) {
    return null;
  }
  if (plan?.value !== recruitmentPlanId) {
    return null;
  }
  const secondVariableDate = addMonths(new Date(candidateStartDate), duration);

  return secondVariableDate;
};
