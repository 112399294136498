import { useTranslation } from 'react-i18next';
import { DatePicker } from 'components/core/input';

export default function CandidateStartDate(props) {
  const { values, onChange } = props;
  const { t } = useTranslation();

  return (
    <DatePicker
      name={'candidateStartDate'}
      label={t('paf.field.candidateStartDate')}
      value={values.candidateStartDate}
      onChange={onChange}
    />
  );
}
