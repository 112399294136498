import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanApplications, getApplications } from 'redux/candidates/actions';
import { formatRows, tableColumns, tableLegend } from './helper.js';

import { InlineContainer } from 'components/core/containers';
import Legend from 'components/core/Legend';
import CreateButton from './CreateButton';
import RowDetails from './RowDetails';
import Table from 'components/core/Table';

function ApplicationTable(props) {
  const { researchId } = props;

  const dispatch = useDispatch();
  useEffect(
    () => {
      dispatch(getApplications(researchId));
      return () => dispatch(cleanApplications());
    },
    [dispatch, researchId]
  );

  const applicationsAndSuggestions = useSelector(
    state => state.candidates.applications
  );

  const Detail = props => (
    <RowDetails row={props.row} researchId={researchId} />
  );

  return (
    <>
      <InlineContainer align="bottom">
        <div style={{ flex: 1 }} />
        <CreateButton researchId={researchId} />
        <Legend items={tableLegend} />
      </InlineContainer>
      <Table
        columns={tableColumns}
        rows={formatRows(applicationsAndSuggestions)}
        detail={Detail}
      />
    </>
  );
}

export default ApplicationTable;
