import styled from 'styled-components';
import { Checkbox as CheckboxMui } from '@material-ui/core';
import Label from 'components/core/Label';

function Checkbox({ name, label, value, onChange }) {
  const handleChange = e => {
    onChange({ name, value: e.target.checked });
  };
  return (
    <Container>
      <CheckboxMui checked={value} onChange={handleChange} color="primary" />
      <Label>{label}</Label>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

export default Checkbox;
