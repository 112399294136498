import ActionButton from 'components/ActionButton';

export default function BaseButton(props) {
  const { label, message, value, color, title, action } = props;

  const onConfirm = () => {
    action();
  };

  return (
    <ActionButton
      title={title}
      label={label}
      dialog
      dialogContent={message}
      action={onConfirm}
      confirmText="Confirmer"
      colorButton={color}
      variantButton={value ? 'contained' : 'outlined'}
      disabled={props.disabled}
    >
      {label}
    </ActionButton>
  );
}
