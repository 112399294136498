import BaseButton from './baseButton';

export default function RhythmButton(props) {
  const { value } = props;

  const msg = `Souhaites-tu refuser cette candidature pour le motif EXPÉRIENCE ?
         Un mail sera envoyé au candidat.`;

  return (
    <BaseButton
      label="Expérience"
      message={msg}
      value={value}
      color="secondary"
      action={props.action}
      disabled={props.disabled}
    />
  );
}
