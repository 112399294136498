import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'utils/hooks/queryState';
import { getOverviewStats } from 'redux/offers/actions';

import Main from 'components/layout/Main';
import PageHeader from 'components/layout/PageHeader';
import OfferTableWithFilter from 'components/eiquem/offer/TableWithFilter';
import Tabs from 'components/core/Tabs';
import TabPanel from 'components/core/TabPanel';
import { useDebounce } from 'utils/hooks/debounce';
import OfferOverview from 'components/eiquem/offer/Overview';

function Offers() {
  const { offers, kpi, status, isMount, setStatus } = useOffers();
  const { t } = useTranslation();

  return (
    <Main pageTitle={t('menu.recruitmentTitle')} isLoading={offers.loading}>
      <div>
        <PageHeader title={t('offer.title')}>
          {[STATUSES.inProgress, STATUSES.standBy].includes(status) && (
            <OfferOverview stats={kpi} />
          )}
        </PageHeader>
        <Tabs
          name={'status'}
          direction="horizontal"
          tabs={offerTabs(kpi)}
          current={status}
          onChange={setStatus}
        />

        <TabPanel value={status} index={STATUSES.inProgress}>
          <OfferTableWithFilter
            status={STATUSES.inProgress}
            keepFilters={isMount}
          />
        </TabPanel>
        <TabPanel value={status} index={STATUSES.standBy}>
          <OfferTableWithFilter
            status={STATUSES.standBy}
            keepFilters={isMount}
          />
        </TabPanel>
        <TabPanel value={status} index={STATUSES.pending}>
          <OfferTableWithFilter
            status={STATUSES.pending}
            keepFilters={isMount}
          />
        </TabPanel>
        <TabPanel value={status} index={STATUSES.completed}>
          <OfferTableWithFilter
            status={STATUSES.completed}
            keepFilters={isMount}
          />
        </TabPanel>
      </div>
    </Main>
  );
}

export default Offers;

export const STATUSES = Object.freeze({
  inProgress: 'inProgress',
  standBy: 'standBy',
  pending: 'pending',
  completed: 'completed',
});

function useOffers() {
  const qs = useQuery();
  const didMount = useRef(false);

  const [status, setStatus] = useState(qs.get('status') || STATUSES.inProgress);

  useEffect(() => {
    didMount.current = true;
  }, []);

  const handleSetStatus = useCallback(({ value }) => {
    setStatus(value);
  }, []);

  return {
    offers: useSelector(state => state.offers),
    kpi: useFetchOverview(didMount),
    status,
    isMount: didMount.current,
    setStatus: handleSetStatus,
  };
}

function useFetchOverview(didMount) {
  const qs = useQuery();
  const dispatch = useDispatch();
  const adminQs = qs.get('admin');
  const authenticatedAdmin = useSelector(state => state.auth.userId);
  const adminId = adminQs
    ? parseInt(adminQs)
    : didMount.current
      ? null
      : authenticatedAdmin;

  const [params, setParams] = useState({
    admin: adminId,
    planIds: qs.getAll('plans'),
    contractTypeIds: qs.getAll('contractTypes'),
    status: qs.get('status'),
  });
  const debouncedParams = useDebounce(params, 50);

  useEffect(
    () =>
      setParams({
        adminId,
        planIds: qs.getAll('plans'),
        contractTypeIds: qs.getAll('contractTypes'),
        status: qs.get('status'),
      }),
    [qs, adminId]
  );

  useEffect(
    () => {
      debouncedParams.status && dispatch(getOverviewStats(debouncedParams));
    },
    [debouncedParams, dispatch]
  );

  return useSelector(state => state.offers.kpi);
}

const offerTabs = kpi => [
  {
    key: STATUSES.inProgress,
    label: `Actives (${kpi.activeCount})`,
  },
  {
    key: STATUSES.standBy,
    label: `Stand-by (${kpi.standByCount})`,
  },
  {
    key: STATUSES.pending,
    label: `À valider (${kpi.pendingCount})`,
  },
  {
    key: STATUSES.completed,
    label: 'Terminées',
  },
];
