import { fetch } from 'utils/api';
import services from 'utils/api/Services/services';
import { actionRequest, actionSuccess, actionFailure } from 'utils/functions';
import { pafSummaryValidator } from 'utils/schemas';

export const ACTIONS_TYPE = Object.freeze({
  GET_PAF_SUMMARY_BEGIN: 'GET_PAF_SUMMARY_BEGIN',
  GET_PAF_SUMMARY_SUCCESS: 'GET_PAF_SUMMARY_SUCCESS',
  GET_PAF_SUMMARY_FAILURE: 'GET_PAF_SUMMARY_FAILURE',
});

export const getPafSummary = params => (dispatch, getState) =>
  fetch({
    service: services.pafSummary.get(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_PAF_SUMMARY_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_PAF_SUMMARY_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_PAF_SUMMARY_FAILURE, errors),
    validator: pafSummaryValidator,
  });
