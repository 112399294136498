import { ROUTES } from 'utils/routes';

export const tableColumns = t => [
  {
    header: t('invoicing.creditNotes.table.company'),
    accessors: ['company'],
    type: 'image',
    isSortable: true,
    headerStyle: {},
    style: {
      fontWeight: 'bold',
    },
  },
  {
    header: t('invoicing.creditNotes.table.id'),
    accessors: ['creditNoteId'],
    isSortable: true,
    headerStyle: {
      textAlign: 'left',
    },
    style: {
      fontWeight: 'bold',
    },
  },
  {
    header: t('invoicing.creditNotes.table.invoice'),
    accessors: ['invoiceId'],
    isSortable: true,
  },
  {
    header: t('invoicing.creditNotes.table.label'),
    accessors: ['invoiceLabel'],
    isSortable: true,
    headerStyle: { width: '50%' },
    style: {
      textAlign: 'left',
      fontWeight: 'bold',
    },
  },
  {
    header: t('invoicing.creditNotes.table.amount'),
    accessors: ['amount'],
    headerStyle: { width: '10%' },
  },
  {
    header: t('invoicing.creditNotes.table.date'),
    accessors: ['creditNoteDate'],
    type: 'date',
    isSortable: true,
    headerStyle: { width: '10%' },
  },
  {
    header: t('invoicing.creditNotes.table.sendingDate'),
    accessors: ['sendingDate'],
    type: 'date',
    headerStyle: { width: '10%' },
    isSortable: true,
  },
  {
    header: t('invoicing.creditNotes.table.tva'),
    accessors: ['tva'],
    type: 'boolean',
    headerStyle: { width: '10%' },
  },
];

export const initialCreditNote = (invoiceAmount = 0) => ({
  amount: invoiceAmount,
  tva: true,
});

export const formatData = creditNotes => {
  if (creditNotes === null) {
    return;
  }
  const rowsFormated = [];
  creditNotes.forEach(creditNote => {
    const rowFormated = Object.assign({}, creditNote);

    rowFormated.company = {
      label: creditNote.company.label,
      url: creditNote.company.url,
      link: ROUTES.COMPANY_SHOW.replace(':id', creditNote.company.value),
    };

    rowsFormated.push(rowFormated);
    return;
  });

  return rowsFormated;
};
