import { useState } from 'react';

import { formatDate } from 'utils/functions';

import TabPanel from 'components/TabPanel';

import Research from './Research';
import { Tabs, Tab } from '@material-ui/core';

function Researches(props) {
  const { researches } = props;

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (_, value) => {
    setTabValue(value);
  };

  return (
    <>
      <Tabs
        value={tabValue}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        centered
      >
        {researches.map((research, key) => {
          const label = 'Démarche - ' + formatDate(research.createDate);
          return <Tab key={label + key} label={label} />;
        })}
      </Tabs>
      {researches.map((research, key) => {
        return (
          <TabPanel key={'tabPanel' + key} value={tabValue} index={key}>
            <Research research={research} />
          </TabPanel>
        );
      })}
    </>
  );
}

export default Researches;
