import { useState } from 'react';
import { Cropper as ReactCropper } from 'react-cropper';
import { withStyles } from '@material-ui/core';
import Dialog from 'components/Dialog';
import 'cropperjs/dist/cropper.css';
import S from './styles';

function Cropper(props) {
  const { open, image, onChange, minWidth, minHeight } = props;

  const [cropper, setCropper] = useState();

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      const cropData = cropper.getCroppedCanvas().toDataURL();
      onChange(cropData);
    }
  };

  return (
    <>
      {image && (
        <Dialog
          open={open}
          title="Define Image"
          contentText="Define the area to display"
          onConfirm={getCropData}
          onCancel={() => onChange()}
        >
          <ReactCropper
            style={{ height: 400, width: '100%' }}
            //initialAspectRatio={1}
            preview=".img-preview"
            src={image}
            viewMode={1}
            //guides={true}
            minCropBoxWidth={minWidth}
            minCropBoxHeight={minHeight}
            initialCropBoxData={{ width: 1 }}
            initialAspectRatio={16 / 9}
            //cropBoxResizable={false}
            background={false}
            dragMode="move"
            //responsive={true}
            //autoCropArea={0.8}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={instance => {
              setCropper(instance);
            }}
            modal={false}
            //cropBoxResizable={false}
            //cropBoxMovable={false}
          />
        </Dialog>
      )}
    </>
  );
}

export default withStyles(S)(Cropper);
