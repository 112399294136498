import { memo } from 'react';

import { withStyles } from '@material-ui/core';
import { HelpOutline, Stop } from '@material-ui/icons';
import ActionButton from 'components/ActionButton';
import S from './styles';

function Legend(props) {
  const { items, classes } = props;

  const legend = (
    <>
      <div className={classes.title}>- Légende -</div>
      {items.map(item => (
        <div key={item.label} className={classes.item}>
          <Stop style={{ color: item.color, backgroundColor: item.color }} />
          <span>&nbsp;&nbsp;</span>
          {item.label}
        </div>
      ))}
    </>
  );

  return (
    <ActionButton
      label={legend}
      icon={<HelpOutline className={classes.icon} />}
      disabled
      tooltipPosition="left"
      tooltipWidth={150}
    />
  );
}

export default memo(withStyles(S)(Legend));
