import GetAppIcon from '@material-ui/icons/GetApp';
import ActionButton from 'components/ActionButton';

function DownloadOneButton(props) {
  const { action, id } = props;

  const handleOnClick = () => {
    action({ id: id });
  };

  return (
    <ActionButton
      action={handleOnClick}
      label="Télécharger avoir"
      icon={<GetAppIcon fontSize="small" />}
    />
  );
}

export default DownloadOneButton;
