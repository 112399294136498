import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getOfferStats } from 'redux/offers/actions';
import { ROUTES } from 'utils/routes';
import Item from 'components/core/Item';
import Link from 'components/core/Link';

function RowDetails({ row, isShown }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isShown) {
      dispatch(getOfferStats({ offerId: row.id }));
    }
  }, []);

  const stats = row.kpi || {};

  const applicationCount = stats.applicationCount || '0';
  const suggestionCount = stats.suggestionCount || '0';

  const slCandidates = stats.shortListedCandidates || [];
  const sentCandidates = stats.sentCandidates || [];
  const inInterviewCandidates = stats.inInterviewCandidates || [];

  return (
    <>
      <Item
        label="Total Candidatures et Suggestions"
        value={`${applicationCount}/${suggestionCount}`}
      />
      <Item
        label={`Candidats en SL (${slCandidates.length})`}
        value={candidateLinks(slCandidates)}
      />
      <Item
        label={`Candidats Envoyés (${sentCandidates.length})`}
        value={candidateLinks(sentCandidates)}
      />
      <Item
        label={`Candidats en Entretien (${inInterviewCandidates.length})`}
        value={candidateLinks(inInterviewCandidates)}
      />
    </>
  );
}

const candidateLinks = candidates =>
  candidates && candidates.length > 0
    ? candidates
        .map(candidate => <CandidateLink candidate={candidate} />)
        .reduce((prev, curr) => [prev, ', ', curr])
    : null;

const CandidateLink = ({ candidate }) => (
  <Link to={ROUTES.CANDIDATE_SHOW.replace(':id', candidate.id)}>
    {candidate.label}
  </Link>
);

export default RowDetails;
