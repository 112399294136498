import { IconButton as MuiIconButton, withStyles } from '@material-ui/core';
import S from './styles';

IconButton.defaultProps = {
  onClick: () => {},
  children: null,
};

function IconButton(props) {
  return (
    <MuiIconButton
      className={props.classes.root}
      disableRipple
      size="small"
      onClick={props.onClick}
    >
      {props.children}
    </MuiIconButton>
  );
}

export default withStyles(S)(IconButton);
