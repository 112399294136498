import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { typesOptions } from 'pages/Invoicing/Invoices/constants';

import AutocompleteAsync from 'components/core/input/AutocompleteAsync';
import Autocomplete from 'components/core/input/Autocomplete';
import TextField from 'components/core/input/TextField';
import SubmitButtons from 'components/core/SubmitButtons';
import { Box, Checkbox, FormControlLabel, withStyles } from '@material-ui/core';

import S from './styles';
import { useFetchAmounts } from 'utils/hooks/formOptions';
import { useEffect } from 'react';
import { useRef } from 'react';

function Form(props) {
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    classes,
    fetchAsyncFormOptions,
    isSubmitting,
  } = props;
  const { t } = useTranslation();

  const { fetchCompaniesOptions } = fetchAsyncFormOptions();

  const handleChange = (fieldName, value) => {
    setFieldValue(fieldName, value, true);
    setFieldTouched(fieldName, true, false);
  };

  // Disable type field if the value doesn't belong to typesOptions array
  const isTypeDisable =
    values.type !== null &&
    !typesOptions.some(option => option.value === values.type?.value);

  const offerId = values.offerId;
  const [fetchAmounts, amounts, cleanAmounts] = useFetchAmounts();

  const isMount = useRef(false);
  useEffect(
    () => {
      if (!isMount.current && offerId) {
        fetchAmounts({ offerId: offerId || null });
        isMount.current = true;
      }
      return () => cleanAmounts();
    },
    [fetchAmounts, cleanAmounts, offerId]
  );

  return (
    <form onSubmit={handleSubmit}>
      <Box className={classes.formContainer}>
        <Box className={classes.column}>
          <Box className={classes.row}>
            {/* Invoice Type */}
            <Autocomplete
              disabled={isTypeDisable}
              label={t('invoicing.invoices.fields.type')}
              options={typesOptions}
              defaultValue={values.type}
              error={errors.type}
              touched={touched.type}
              onChange={({ value }) => {
                handleChange('type', value);
              }}
              className={classes.selectField}
            />
          </Box>
          <Box className={classes.row}>
            {/* Company */}
            <AutocompleteAsync
              defaultValues={[values.company]}
              creatable
              service={fetchCompaniesOptions}
              className={classes.selectField}
              label={t('invoicing.invoices.fields.company')}
              error={errors.company}
              touched={touched.company}
              onChange={({ value }) => {
                handleChange('company', value);
              }}
            />
          </Box>

          {/* Amounts */}
          {amounts &&
            amounts.length > 0 && (
              <Box className={classes.row}>
                <Autocomplete
                  id="amount"
                  label={t('invoicing.invoices.fields.amount')}
                  options={amounts}
                  defaultValue={amounts?.find(
                    amount => amount.value === values.amountId
                  )}
                  onChange={({ value }) => {
                    handleChange('amountId', value.value);
                    handleChange('amountValue', value.amountValue);
                  }}
                  className={classes.selectField}
                />
              </Box>
            )}
          <Box className={classes.row}>
            {/* Amount Value*/}
            <TextField
              id="amountValue"
              label={t('invoicing.invoices.fields.amount')}
              touched={touched.amountValue}
              error={errors.amountValue}
              className={classes.textField}
              handleChange={({ value }) => {
                handleChange('amountValue', value);
              }}
              value={values.amountValue}
              type="number"
            />
          </Box>
          <Box className={classes.row}>
            {/* Days to pay */}
            <TextField
              id="daysToPay"
              label={t('invoicing.invoices.fields.daysToPay')}
              touched={touched.daysToPay}
              error={errors.daysToPay}
              className={classes.smallTextField}
              handleChange={({ value }) => {
                handleChange('daysToPay', value);
              }}
              value={values.daysToPay}
              endAdornment="Jours"
              type="number"
            />
          </Box>
          {/*  Label */}
          {!isTypeDisable && (
            <Box className={classes.row}>
              <TextField
                id="otherLabel"
                label={t('invoicing.invoices.fields.label')}
                touched={touched.otherLabel}
                error={errors.otherLabel}
                className={classes.textField}
                handleChange={({ value }) => {
                  handleChange('otherLabel', value);
                }}
                value={values.otherLabel}
              />
            </Box>
          )}

          {/*  Comment */}
          <Box className={classes.row}>
            <TextField
              id="comment"
              label={t('invoicing.invoices.fields.comment')}
              multiline
              rowMax="4"
              touched={touched.comment}
              error={errors.comment}
              className={clsx(classes.commentsField, classes.textField)}
              handleChange={({ value }) => {
                handleChange('comment', value);
              }}
              value={values.comment}
            />
          </Box>
          {/*  TVA */}
          <Box className={classes.row}>
            <FormControlLabel
              label={t('invoicing.invoices.fields.tva')}
              control={
                <Checkbox
                  id="tva"
                  checked={values.tva}
                  onChange={() => {
                    const value = values.tva ? false : true;
                    handleChange('tva', value);
                  }}
                  color="primary"
                />
              }
              className={classes.checkbox}
              labelPlacement="end"
            />
          </Box>
        </Box>
      </Box>

      <SubmitButtons isSubmitting={isSubmitting} />
    </form>
  );
}

export default withStyles(S)(Form);
