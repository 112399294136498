export default () => ({
  container: {
    alignSelf: 'flex-end',
    paddingLeft: 10,
  },
  textField: {
    width: '180px',
  },
  transparentBC: {
    backgroundColor: 'transparent !important',
  },
});
