import BaseButton from './baseButton';

export default function DurationButton(props) {
  const { value } = props;

  const msg = `Souhaites-tu refuser cette candidature pour le motif DURÉE ?
        Un mail sera envoyé au candidat.`;

  return (
    <div>
      <BaseButton
        label="Durée"
        message={msg}
        value={value}
        color="secondary"
        action={props.action}
        disabled={props.disabled}
      />
    </div>
  );
}
