import { useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { sidebarMaintainExpanded, sidebarUpdateState } from 'redux/app/actions';
import { logout } from 'redux/auth/actions';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
  Box,
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  IconButton,
  Container,
  CircularProgress,
  withStyles,
} from '@material-ui/core';

import MainListItems from '../Sidebar';

import S from './styles';
import PropTypes from 'prop-types';

import logo from './eiquem.svg';

function Main(props) {
  const { classes, children, pageTitle, isLoading } = props;

  const sidebarStatus = useSelector(state => state.app.sidebar.expanded);

  const [open, setOpen] = useState(sidebarStatus);

  const handleDrawerOpenOnClick = () => {
    setOpen(true);
    dispatch(sidebarUpdateState(true));
    dispatch(sidebarMaintainExpanded(true));
  };
  const handleDrawerCloseOnClick = () => {
    setOpen(false);
    dispatch(sidebarUpdateState(false));
    dispatch(sidebarMaintainExpanded(false));
  };

  const isMaintainExpanded = useSelector(
    state => state.app.sidebar.maintainExpanded
  );
  const handleDrawerOpenOnHover = () => {
    setOpen(true);
    dispatch(sidebarUpdateState(true));
  };
  const handleDrawerCloseOnHover = () => {
    if (isMaintainExpanded) {
      return;
    }
    setOpen(false);
    dispatch(sidebarUpdateState(false));
  };

  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <Box className={classes.root}>
      <CssBaseline />
      <div className={clsx(classes.logo, open && classes.logoDrawerOpen)}>
        <img src={logo} alt="logo" style={{ height: 75 }} />
      </div>
      <AppBar className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpenOnClick}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h5"
            color="primary"
            noWrap
            className={classes.title}
          >
            {pageTitle}
          </Typography>
          {isLoading && <CircularProgress />}
          <IconButton color="primary" onClick={logoutHandler}>
            <ExitToAppIcon />
          </IconButton>
          <Typography color="primary" noWrap>
            Log out
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '30px',
          }}
        >
          <Typography variant="h5" color="primary" style={{ flex: 1 }}>
            NBO
          </Typography>
          <div className={classes.toolbarIcon}>
            <IconButton
              className={classes.toolbarIconButton}
              onClick={handleDrawerCloseOnClick}
            >
              <ChevronLeftIcon />
            </IconButton>
          </div>
        </div>
        <Divider />
        <div
          onMouseEnter={handleDrawerOpenOnHover}
          onMouseLeave={handleDrawerCloseOnHover}
        >
          <MainListItems sidebarOpened={open} />
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {children && (
          <Container className={classes.container} children={children} />
        )}
      </main>
    </Box>
  );
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(S)(Main);
