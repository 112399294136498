const fieldsSpacing = 10;

export default theme => ({
  root: {
    width: '100%',
  },
  tab: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  fieldContainer: {
    marginTop: '-40px',
    marginLeft: '-30px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,
    marginLeft: 30,
    marginRight: 30,
  },
  row: {
    display: 'flex',
    marginTop: 20,
  },
  selectField: {
    width: 380,
    marginLeft: fieldsSpacing,
    marginRight: fieldsSpacing,
  },
  dateField: {
    width: 180,
    marginLeft: fieldsSpacing,
    marginRight: fieldsSpacing,
  },
  button: {
    color: 'black',
    backgroundColor: 'transparent !important',
  },
  link: {
    marginTop: 3,
  },
});
