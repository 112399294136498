import { useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { TableRow, TableCell, Box, withStyles } from '@material-ui/core';

import { formatValue } from './helper';
import S from 'components/Table/styles';

TableRowEnhanced.propTypes = {
  row: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  actionsComponents: PropTypes.array,
  ExpandComponent: PropTypes.any,
};

function TableRowEnhanced(props) {
  const { row, columns, actionComponents, expandComponent, classes } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClickOnRow = event => {
    const isLinkHasBeenClicked = typeof event.target.href !== 'undefined';
    if (!isLinkHasBeenClicked) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <>
      <TableRow
        className={clsx(
          row.backgroundColor ? classes[row.backgroundColor] : '',
          row.textDecoration ? classes[row.textDecoration] : ''
        )}
      >
        {actionComponents && (
          <TableCell className={clsx(classes.tableCell, classes.actionsCell)}>
            <Box className={classes.actionWrapper}>
              {actionComponents.map((ActionComponent, k) => (
                <ActionComponent
                  key={'action-button-' + k}
                  id={row.id}
                  {...props}
                />
              ))}
            </Box>
          </TableCell>
        )}
        {columns.map((column, k) => (
          <TableCell
            onClick={event => handleClickOnRow(event)}
            style={{
              ...column.cellStyle,
              textDecoration: row.textDecoration ? row.textDecoration : '',
              ...(row.cellStyle && row.cellStyle[column.cellStyleAccessor]
                ? row.cellStyle[column.cellStyleAccessor]
                : null),
            }}
            className={classes.tableCell}
            key={'row-column' + k}
            align="left"
          >
            <Box style={column.style}>
              {formatValue(column, row)}{' '}
              {formatValue(column, row) !== null ? column.suffix : ''}
            </Box>
          </TableCell>
        ))}
      </TableRow>
      {expandComponent &&
        isExpanded && (
          <TableRow>
            <TableCell
              colSpan={
                columns.length +
                (actionComponents ? actionComponents.length : 0)
              }
              className={classes.expandedCell}
            >
              {cloneElement(expandComponent, {
                row: row,
                handleIsExpended: value => setIsExpanded(value),
              })}
            </TableCell>
          </TableRow>
        )}
    </>
  );
}

export default withStyles(S)(TableRowEnhanced);
