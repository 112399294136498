import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Label from './Label';
import Buttons from './Buttons';
import Input from './Input';
import RichInput from './RichInput';

function Comment(props) {
  const {
    comment,
    onEdit,
    onCancel,
    onConfirm,
    onRemove,
    editMode,
    isEditable,
    useRichInput,
    onChange,
  } = props;
  const classes = useStyles();

  const id = comment.id;
  const [message, setMessage] = useState(comment.message);

  useEffect(
    () => {
      setMessage(comment.message);
    },
    [comment]
  );

  const handleEdit = () => onEdit(id);
  const handleConfirm = () => onConfirm(id, message);
  const handleRemove = () => onRemove(id);
  const handleCancel = () => {
    setMessage(comment.message);
    onCancel(id);
  };
  const handleChange = value => {
    setMessage(value);
    onChange({ id, value });
  };

  return (
    <div>
      <div className={classes.header}>
        <Label
          creator={comment.creator.label}
          createDate={comment.date}
          editDate={comment.editDate}
        />
        {isEditable && (
          <Buttons
            onEdit={handleEdit}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            onRemove={handleRemove}
            editMode={editMode}
          />
        )}
      </div>
      {useRichInput ? (
        <RichInput
          message={message}
          onChange={handleChange}
          editMode={editMode}
        />
      ) : (
        <Input message={message} onChange={handleChange} editMode={editMode} />
      )}
    </div>
  );
}

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

Comment.propTypes = {
  comment: PropTypes.object,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onRemove: PropTypes.func,
  editMode: PropTypes.bool,
  isEditable: PropTypes.bool,
  useRichInput: PropTypes.bool,
  onChange: PropTypes.func,
};

Comment.defaultProps = {
  comment: {},
  onEdit: () => {},
  onCancel: () => {},
  onConfirm: () => {},
  onRemove: () => {},
  editMode: false,
  useRichInput: false,
  onChange: () => {},
};

export default Comment;
