export const tableColumns = [
  {
    header: 'Année',
    accessors: ['year'],
    style: { textAlign: 'left' },
    headerStyle: { textAlign: 'left' },
  },
  {
    header: 'Offres lancées',
    accessors: ['offersCount'],
    style: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
  },
  {
    header: 'Candidats pris',
    accessors: ['candidateHiredCount'],
    headerStyle: { textAlign: 'center' },
    style: { textAlign: 'center' },
  },
  {
    header: 'Total facturé',
    accessors: ['invoicedAmount'],
    headerStyle: { textAlign: 'right' },
    style: { textAlign: 'right' },
    type: 'currency',
  },
];
