import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { months } from 'utils/constants';
import S from './styles';
import {
  Table,
  TableRow,
  TableCell,
  withStyles,
  TableHead,
  TableBody,
  Tooltip,
  Box,
} from '@material-ui/core';

function StatTable(props) {
  const { classes, statName, values } = props;
  const { t } = useTranslation();

  return (
    <Table className={classes.table} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell
            className={clsx(
              classes.bold,
              classes.tableHead,
              classes.firstColumn
            )}
          >
            {statName}
          </TableCell>
          {months.map((month, i) => (
            <TableCell
              className={clsx(classes.bold, classes.tableHead, classes.cell)}
              key={i}
            >
              {t(month)}
            </TableCell>
          ))}
          <TableCell
            className={clsx(classes.bold, classes.tableHead, classes.cell)}
          >
            Total
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className={classes.bold}>
            <Tooltip title="Temps moyen (en mois) entre la date de création de l'offre dans le PAF et la date d'annonce du placement">
              <Box>Tps moy. de placement</Box>
            </Tooltip>
          </TableCell>
          {values.map((value, i) => (
            <TableCell key={i} className={classes.cell}>
              {value.averageTimeToPaf}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell className={classes.bold}>
            <Tooltip title="Temps moyen (en mois) entre la date de création de l'offre dans le PAF et la date d'intégration du candidat">
              <Box>Tps moy. de recrutement</Box>
            </Tooltip>
          </TableCell>
          {values.map((value, i) => (
            <TableCell key={i} className={classes.cell}>
              {value.averageTimeToStart}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell className={classes.bold}>Taux de placement</TableCell>
          {values.map((value, i) => (
            <TableCell key={i} className={classes.cell}>
              {value.pafLineCounts > 0
                ? Math.round((value.successCount / value.pafLineCounts) * 100)
                : 0}
              %
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell className={classes.bold}>Taux de 2nd Variable</TableCell>
          {values.map((value, i) => (
            <TableCell key={i} className={classes.cell}>
              {value.pafLineCounts > 0
                ? Math.round(
                    (value.contractChangeCount / value.pafLineCounts) * 100
                  )
                : 0}
              %
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell className={classes.bold}>Taux d'abandon</TableCell>
          {values.map((value, i) => (
            <TableCell key={i} className={classes.cell}>
              {value.pafLineCounts > 0
                ? Math.round((value.abandonedCount / value.pafLineCounts) * 100)
                : 0}
              %
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell className={classes.bold}>Nb. d'offres</TableCell>
          {values.map((value, i) => (
            <TableCell key={i} className={classes.cell}>
              {value.pafLineCounts}
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default withStyles(S)(StatTable);
