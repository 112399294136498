import { useTranslation } from 'react-i18next';

import { AutocompleteAsync } from 'components/core/input';
import { useSelector } from 'react-redux';
import services from 'utils/api/Services/services';

export default function Candidate({ values, errors, touched, onChange }) {
  const { t } = useTranslation();
  const options = useSelectOptions();

  return (
    <AutocompleteAsync
      name="candidate"
      defaultValues={[values.candidate]}
      defaultOptions={options}
      lazy={true}
      service={fetchOptions}
      label={t('paf.field.candidate')}
      touched={touched.candidate}
      error={errors.candidate}
      onChange={onChange}
      creatable
    />
  );
}

const useSelectOptions = () => {
  return useSelector(state => state.formOptions.candidateOptions);
};

const fetchOptions = searchTerm =>
  services.options.getCandidates({
    search: searchTerm,
  });
