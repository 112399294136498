import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { TextField, Box, Divider } from '@material-ui/core';
import { Subtitle } from './subtitle';

export default function OfferFields(props) {
  const {
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    classes,
  } = props;

  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <Box className={classes.column}>
        <Subtitle label="Détail de l'Annonce" />
      </Box>
      <Box className={classes.row}>
        <Box className={classes.column}>
          {/* Mission */}
          <TextField
            id="mission"
            label={t('jobboards-posting.field.mission')}
            autoComplete="off"
            multiline
            helperText={touched.mission ? errors.mission : ''}
            error={touched.mission && Boolean(errors.mission)}
            className={clsx(classes.freeFields, classes.textField)}
            margin="normal"
            onChange={event => {
              const value = event.target.value;
              setFieldValue('mission', value);
              setFieldTouched('mission', true, false);
            }}
            value={values.mission ? values.mission : ''}
          />
        </Box>

        <Box className={classes.row}>
          <Divider className={classes.divider} orientation="vertical" />
        </Box>
        {/* Profile */}
        <Box className={classes.column}>
          <TextField
            id="profile"
            label={t('jobboards-posting.field.profile')}
            autoComplete="off"
            multiline
            helperText={touched.profile ? errors.profile : ''}
            error={touched.profile && Boolean(errors.profile)}
            className={clsx(classes.freeFields, classes.textField)}
            margin="normal"
            onChange={event => {
              const value = event.target.value;
              setFieldValue('profile', value);
              setFieldTouched('profile', true, false);
            }}
            value={values.profile ? values.profile : ''}
          />
        </Box>
      </Box>
    </Box>
  );
}
