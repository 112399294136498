import { fetch } from 'utils/api';
import services from 'utils/api/Services/services';
import { actionRequest, actionSuccess, actionFailure } from 'utils/functions';

export const ACTIONS_TYPE = Object.freeze({
  GET_COMMERCIAL_PROPOSALS_BEGIN: 'GET_COMMERCIAL_PROPOSALS_BEGIN',
  GET_COMMERCIAL_PROPOSALS_SUCCESS: 'GET_COMMERCIAL_PROPOSALS_SUCCESS',
  GET_COMMERCIAL_PROPOSALS_FAILURE: 'GET_COMMERCIAL_PROPOSALS_FAILURE',

  GET_ONE_COMMERCIAL_PROPOSAL_BEGIN: 'GET_ONE_COMMERCIAL_PROPOSAL_BEGIN',
  GET_ONE_COMMERCIAL_PROPOSAL_SUCCESS: 'GET_ONE_COMMERCIAL_PROPOSAL_SUCCESS',
  GET_ONE_COMMERCIAL_PROPOSAL_FAILURE: 'GET_ONE_COMMERCIAL_PROPOSAL_FAILURE',

  CLEAN_CURRENT_PROPOSAL: 'CLEAN_CURRENT_PROPOSAL',
  CLEAN_PROPOSALS: 'CLEAN_PROPOSALS',

  UPDATE_COMMERCIAL_PROPOSAL_BEGIN: 'UPDATE_COMMERCIAL_PROPOSAL_BEGIN',
  UPDATE_COMMERCIAL_PROPOSAL_SUCCESS: 'UPDATE_COMMERCIAL_PROPOSAL_SUCCESS',
  UPDATE_COMMERCIAL_PROPOSAL_FAILURE: 'UPDATE_COMMERCIAL_PROPOSAL_FAILURE',

  CREATE_COMMERCIAL_PROPOSAL_BEGIN: 'CREATE_COMMERCIAL_PROPOSAL_BEGIN',
  CREATE_COMMERCIAL_PROPOSAL_SUCCESS: 'CREATE_COMMERCIAL_PROPOSAL_SUCCESS',
  CREATE_COMMERCIAL_PROPOSAL_FAILURE: 'CREATE_COMMERCIAL_PROPOSAL_FAILURE',

  DOWNLOAD_COMMERCIAL_PROPOSAL_BEGIN: 'DOWNLOAD_COMMERCIAL_PROPOSAL_BEGIN',
  DOWNLOAD_COMMERCIAL_PROPOSAL_SUCCESS: 'DOWNLOAD_COMMERCIAL_PROPOSAL_SUCCESS',
  DOWNLOAD_COMMERCIAL_PROPOSAL_FAILURE: 'DOWNLOAD_COMMERCIAL_PROPOSAL_FAILURE',

  DELETE_COMMERCIAL_PROPOSAL_BEGIN: 'DELETE_COMMERCIAL_PROPOSAL_BEGIN',
  DELETE_COMMERCIAL_PROPOSAL_SUCCESS: 'DELETE_COMMERCIAL_PROPOSAL_SUCCESS',
  DELETE_COMMERCIAL_PROPOSAL_FAILURE: 'DELETE_COMMERCIAL_PROPOSAL_FAILURE',
});

export const deleteCommercialProposal = id => (dispatch, getState) =>
  fetch({
    service: services.commercialProposals.delete(id),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.DELETE_COMMERCIAL_PROPOSAL_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.DELETE_COMMERCIAL_PROPOSAL_SUCCESS, {
        proposalId: id,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.DELETE_COMMERCIAL_PROPOSAL_FAILURE, errors),
  });

export const downloadCommercialProposal = id => (dispatch, getState) =>
  fetch({
    service: services.commercialProposals.download(id),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.DOWNLOAD_COMMERCIAL_PROPOSAL_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.DOWNLOAD_COMMERCIAL_PROPOSAL_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.DOWNLOAD_COMMERCIAL_PROPOSAL_FAILURE, errors),
  });

export const getOneCommercialProposal = id => (dispatch, getState) =>
  fetch({
    service: services.commercialProposals.getOne(id),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_ONE_COMMERCIAL_PROPOSAL_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_ONE_COMMERCIAL_PROPOSAL_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_ONE_COMMERCIAL_PROPOSAL_FAILURE, errors),
  });

export const cleanCurrentProposal = () => ({
  type: ACTIONS_TYPE.CLEAN_CURRENT_PROPOSAL,
});

export const cleanProposals = () => ({
  type: ACTIONS_TYPE.CLEAN_PROPOSALS,
});

export const createCommercialProposal = data => (dispatch, getState) =>
  fetch({
    service: services.commercialProposals.create(data),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.CREATE_COMMERCIAL_PROPOSAL_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.CREATE_COMMERCIAL_PROPOSAL_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.CREATE_COMMERCIAL_PROPOSAL_FAILURE, errors),
  });

export const updateCommercialProposal = (id, data) => (dispatch, getState) =>
  fetch({
    service: services.commercialProposals.update(id, data),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.UPDATE_COMMERCIAL_PROPOSAL_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.UPDATE_COMMERCIAL_PROPOSAL_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_COMMERCIAL_PROPOSAL_FAILURE, errors),
  });

export const getCommercialProposals = params => (dispatch, getState) =>
  fetch({
    service: services.commercialProposals.get(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_COMMERCIAL_PROPOSALS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_COMMERCIAL_PROPOSALS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_COMMERCIAL_PROPOSALS_FAILURE, errors),
  });
