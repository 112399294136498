import styled from 'styled-components';
import { theme } from 'utils/theme';

const Subtitle = ({ children }) =>
  children ? (
    <li>
      <Item>{children}</Item>
    </li>
  ) : null;

const Item = styled.span`
  font-size: ${theme.typography.fontSize}px;
  font-weight: bold;
  cursor: default;
  user-select: text;
  margin-right: 10px;
`;

export default Subtitle;
