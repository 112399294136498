import { ACTIONS_TYPE } from './actions';

export const initialState = {
  loading: false,
  items: [],
  current: null,
};

export function applicationFollowUpsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPE.GET_APPLICATION_FOLLOW_UPS_BEGIN:
    case ACTIONS_TYPE.GET_APPLICATION_FOLLOW_UP_BEGIN:
    case ACTIONS_TYPE.UPDATE_APPLICATION_FOLLOW_UP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_APPLICATION_FOLLOW_UPS_FAILURE:
    case ACTIONS_TYPE.GET_APPLICATION_FOLLOW_UP_FAILURE:
    case ACTIONS_TYPE.UPDATE_APPLICATION_FOLLOW_UP_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_APPLICATION_FOLLOW_UPS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.result,
      };
    case ACTIONS_TYPE.GET_APPLICATION_FOLLOW_UP_SUCCESS:
    case ACTIONS_TYPE.UPDATE_APPLICATION_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        loading: false,
        current: action.result,
      };
    case ACTIONS_TYPE.CLEAN_APPLICATION_FOLLOW_UP:
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
}
