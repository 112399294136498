import { ACTIONS_TYPE } from './actions';

export const initialState = {
  loading: false,
  summary: [],
};

export function pafSummaryReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPE.GET_PAF_SUMMARY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_PAF_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_PAF_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: action.result,
        loading: false,
      };

    default:
      return state;
  }
}
