import {
  getApplications,
  getSuggestions,
  cleanApplications,
  cleanSuggestions,
} from 'redux/offers/actions';
import {
  lightBlueFontCode,
  darkBlueFontCode,
  yellowFontCode,
  greenFontCode,
  redFontCode,
  greyFontCode,
  purpleFontCode,
} from 'utils/constants';
import { ROUTES } from 'utils/routes';
import { formatDate, isValidDate } from 'utils/functions';

const TYPES = Object.freeze({
  ACTIVE: 'active',
  COMPLETED: 'completed',
  SUGGESTION: 'suggestion',
});

const fetchApplications = (
  {
    offerId,
    type,
    contractTypes = null,
    minStartDate = null,
    minDuration = null,
    maxDuration = null,
    profiles = null,
    isCommented = null,
    isActiveResearch = null,
    schoolTypes = null,
    schoolLevels = null,
  },
  dispatch
) => {
  dispatch(
    type === TYPES.SUGGESTION
      ? getSuggestions({
          offerId,
        })
      : getApplications({
          offerId,
          contractTypes,
          minStartDate,
          minDuration,
          maxDuration,
          profiles,
          isCommented,
          isActiveResearch,
          schoolTypes,
          schoolLevels,
          status: type,
        })
  );
};

const cleanUp = (dispatch, type) => {
  dispatch(
    type === TYPES.SUGGESTION ? cleanSuggestions() : cleanApplications()
  );
};

const selectApplications = type => state =>
  type === TYPES.SUGGESTION
    ? state.offers.suggestions
    : state.offers.applications;

const tableColumns = [
  {
    header: 'Candidat',
    accessors: ['candidate'],
    style: {
      fontWeight: 'bold',
    },
    type: 'link',
  },
  {
    header: 'Établissement',
    accessors: ['research', 'school'],
    style: {
      minWidth: '50px',
    },
  },
  {
    header: 'Suggestion',
    accessors: ['suggestionDate'],
  },
  {
    header: 'Candidature',
    accessors: ['applicationDate'],
    type: 'date',
  },
  {
    header: 'Envoyé',
    accessors: ['sentDate'],
    type: 'date',
  },
  {
    header: 'Provenance',
    accessors: ['research', 'provenance'],
  },
  {
    header: 'Profil',
    accessors: ['research', 'profile'],
    style: {
      fontWeight: 'bold',
    },
  },
  {
    header: 'CV',
    accessors: ['resumeLink'],
    type: 'link',
  },
  {
    header: 'LM',
    accessors: ['coverLetterLink'],
    type: 'link',
  },
];

const format = (applications, type) => {
  switch (type) {
    case TYPES.ACTIVE:
      return formatActiveApplications(applications);
    case TYPES.COMPLETED:
      return formatCompletedApplications(applications);
    case TYPES.SUGGESTION:
      return formatSuggestion(applications);
    default:
  }
};

const formatActiveApplications = applications => {
  const rows = applications.map(application => {
    const row = {
      ...application,
      candidate: formatCandidate(application.research.candidate),
      resumeLink: formatResume(application.resumeLink),
      coverLetterLink: formatCoverLetter(application.coverLetterLink),
      suggestionDate:
        application.suggestion &&
        validateAndFormatDate(application.suggestion.suggestionDate),
    };

    const states = application.states;
    switch (true) {
      case application.hasContactAttempt: {
        row.backgroundColor = purpleFontCode;
        break;
      }
      case hasState(states, 'inInterview'): {
        row.backgroundColor = darkBlueFontCode;
        break;
      }
      case hasState(states, 'sent'): {
        row.backgroundColor = lightBlueFontCode;
        break;
      }
      case hasState(states, 'shortList'): {
        row.backgroundColor = greyFontCode;
        break;
      }
      case application.hasSuggestion: {
        row.backgroundColor = greenFontCode;
        break;
      }
      default:
    }

    return row;
  });
  return rows;
};

const formatCompletedApplications = applications => {
  const rows = applications.map(application => {
    const row = {
      ...application,
      candidate: formatCandidate(application.research.candidate),
      resumeLink: formatResume(application.resumeLink),
      coverLetterLink: formatCoverLetter(application.coverLetterLink),
      suggestionDate:
        application.suggestion &&
        validateAndFormatDate(application.suggestion.suggestionDate),
    };

    const states = application.states;
    switch (true) {
      case hasState(states, 'hired'): {
        row.backgroundColor = yellowFontCode;
        break;
      }
      case hasState(states, 'inInterview'): {
        row.backgroundColor = darkBlueFontCode;
        break;
      }
      case hasState(states, 'sent'): {
        row.backgroundColor = lightBlueFontCode;
        break;
      }
      case application.hasSuggestion: {
        row.backgroundColor = greenFontCode;
        break;
      }
      case hasState(states, 'refused'): {
        row.backgroundColor = redFontCode;
        break;
      }
      default:
    }

    return row;
  });

  return rows;
};

const formatSuggestion = suggestions => {
  if (!suggestions) {
    return [];
  }
  const rows = suggestions.map(suggestion => {
    const row = {
      ...suggestion,
      candidate: formatCandidate(suggestion.research.candidate),
      resumeLink: formatResume(suggestion.resumeLink),
      coverLetterLink: null,
      suggestionDate: validateAndFormatDate(suggestion.createDate),
    };
    if (suggestion.declinedDate) {
      row.backgroundColor = redFontCode;
    } else if (suggestion.contactAttemptDate) {
      row.backgroundColor = purpleFontCode;
    } else if (suggestion.preSuggestion) {
      row.backgroundColor = greyFontCode;
    }
    if (suggestion.lastRemindDate) {
      row.suggestionDate += ` (${validateAndFormatDate(
        suggestion.lastRemindDate
      )})`;
    }

    return row;
  });

  rows.sort(function(left, right) {
    if (left.declinedDate !== null && right.declinedDate === null) {
      return 1;
    } else {
      return -1;
    }
  });

  return rows;
};

const hasState = (states, code) => states.some(state => state.code === code);

const formatCandidate = candidate => ({
  label: candidate.name,
  url: ROUTES.CANDIDATE_SHOW.replace(':id', candidate.id),
});

const formatResume = url => ({
  label: 'CV',
  url: url,
  target: 'blank',
});
const formatCoverLetter = url => ({
  label: 'LM',
  url: url,
  target: 'blank',
});

const legend = type => {
  switch (type) {
    case TYPES.ACTIVE:
      return activeApplicationLegend;
    case TYPES.SUGGESTION:
      return suggestionLegend;
    case TYPES.COMPLETED:
      return completedApplicationLegend;
    default:
  }
};

const activeApplicationLegend = [
  { color: lightBlueFontCode, label: 'Candidat Envoyé' },
  { color: darkBlueFontCode, label: 'Candidat en Entretien' },
  { color: greenFontCode, label: 'Suggestion Acceptée' },
  { color: greyFontCode, label: 'Candidat en Short List' },
  { color: purpleFontCode, label: 'Candidat non Joignable (message)' },
];

const suggestionLegend = [
  { color: purpleFontCode, label: 'Candidat non Joignable (message)' },
  { color: redFontCode, label: 'Suggestion Refusé' },
  { color: greyFontCode, label: 'Pré-suggestion' },
];

const completedApplicationLegend = [
  { color: yellowFontCode, label: 'Candidat Pris' },
  { color: lightBlueFontCode, label: 'Candidat Envoyé' },
  { color: darkBlueFontCode, label: 'Candidat en Entretien' },
  { color: greenFontCode, label: 'Suggestion Acceptée' },
  { color: redFontCode, label: 'Candidat Refusé' },
];

export {
  TYPES,
  fetchApplications,
  cleanUp,
  selectApplications,
  tableColumns,
  format,
  legend,
};

const validateAndFormatDate = date =>
  isValidDate(date) ? formatDate(date) : '-';
