export default () => ({
  row: {
    display: 'flex',
    whiteSpace: 'break-spaces',
  },
  selectField: {
    maxWidth: 90,
    marginTop: -18,
    marginLeft: 20,
  },
});
