export default function ImgInput() {
  return (
    <input
      id="img-input"
      type="file"
      name="img-input"
      style={{ display: 'none' }}
    />
  );
}
