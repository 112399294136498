import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { RecruiterFormSchema } from 'utils/formSchemas';
import { initialRecruiter } from './helper';
import {
  getOneRecruiter,
  updateRecruiter,
  createRecruiter,
} from 'redux/recruiters/actions';

import Main from 'components/layout/Main';
import Title from 'components/layout/Title';
import Form from './form';
import { Paper } from '@material-ui/core';

function RecruiterForm() {
  const dispatch = useDispatch();

  // Edit case
  const params = useParams();
  const id = params.id ? parseInt(params.id) : null;
  const companyId = params.companyId ? parseInt(params.companyId) : null;

  useEffect(
    () => {
      if (id) {
        dispatch(getOneRecruiter({ recruiterId: id }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const recruiterState = useSelector(state => state.recruiters);

  let recruiter = null;
  if (
    id !== null &&
    recruiterState.current !== null &&
    recruiterState.current.id === id
  ) {
    recruiter = recruiterState.current;
  } else if (id === null) {
    recruiter = initialRecruiter;
  }
  const handleOnSubmit = values => {
    const data = {
      ...values,
      companyId: values.companyId ? values.companyId : companyId,
    };
    if (id !== null) {
      values.id = id;
      console.log('call update recruiter with data: ', data);
      dispatch(updateRecruiter(data));
    } else {
      console.log('call create recruiter with data: ', data);
      dispatch(createRecruiter(data));
    }
  };

  return (
    <Main isLoading={recruiterState.loading}>
      <>
        {id === null && <Title>Création Recruteur</Title>}
        {id !== null && <Title>Édition Recruteur - {id}</Title>}

        <Paper>
          {recruiter && (
            <Formik
              initialValues={recruiter}
              validationSchema={RecruiterFormSchema}
              onSubmit={handleOnSubmit}
            >
              {props => <Form {...props} />}
            </Formik>
          )}
        </Paper>
      </>
    </Main>
  );
}

export default RecruiterForm;
