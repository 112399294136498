import BaseButton from './baseButton';

export default function ShortListButton(props) {
  const msg = `Souhaites-tu placer cette candidature en SHORT LIST ?
    Le statut de sa candidature sera mis à jour.`;

  return (
    <BaseButton
      title="Short list"
      label="sl"
      message={msg}
      value={props.value}
      color="primary"
      action={props.action}
      disabled={props.disabled}
    />
  );
}
