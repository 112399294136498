import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from 'components/core/input/TextField';

function Input({ message, onChange, editMode }) {
  const classes = useStyles();

  return (
    <>
      {editMode ? (
        <TextField
          className={classes.field}
          value={message}
          handleChange={({ value }) => onChange(value)}
          maxRows={10}
          multiline
        />
      ) : (
        <div className={classes.field}>{message}</div>
      )}
    </>
  );
}

const useStyles = makeStyles({
  field: {
    display: 'flex',
    width: '100%',
    whiteSpace: 'pre-wrap',
  },
});

Input.propTypes = {
  message: PropTypes.string,
  onChange: PropTypes.func,
  editMode: PropTypes.bool,
};

Input.defaultProps = {
  message: null,
  onChange: () => {},
  editMode: false,
};

export default Input;
