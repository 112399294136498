export const getShortListMailEn = (offer, applications) => {
  if (!applications || !applications.length) {
    return null;
  }
  const offerLabel = offer.label;
  const contact = offer.contactName;

  return mail(offerLabel, contact, applications);
};

const mail = (offerLabel, contact, applications) => `
<p>Hello ${contact},</p>

<p>I hope everything is going well.</p>

<p>
  You'll find in attachment ${count(
    applications
  )} for the ${offerLabel} position.
</p>

${formatApplications(applications)}

<p>You will find ${countResume(applications)} in the attachment,</p>

<p>
  Best regards,<br/>
  Have a good day!
</p>
`;

const count = applications =>
  `
${applications.length} ${
    applications.length > 1 ? 'new applications' : 'new application'
  }
`;

const formatApplications = applications =>
  applications
    .map(application => {
      const research = application.research;
      return `
      <p>
        <b>${research.candidate.name}</b>, available from ${
        research.startDate
      }${research.duration ? ` for ${research.duration} months ` : ' '}for ${
        research.contractLabel
      }.
        ${
          application.comments.length
            ? `${application.comments[0].message}`
            : ''
        }
      </p>
`;
    })
    .join('');

const countResume = applications =>
  `${applications.length > 1 ? 'their resumes' : 'his resume'}`;
