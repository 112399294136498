import BaseButton from './baseButton';

export default function AbandonButton(props) {
  const msg = `Souhaites-tu ABANDONNER cette candidature ?
    Aucun mail ne sera envoyé au candidat mais le statut de sa candidature sera mis à jour.`;

  return (
    <BaseButton
      label="Abandon"
      message={msg}
      value={props.value}
      color="secondary"
      action={props.action}
      disabled={props.disabled}
    />
  );
}
