import { ROUTES } from 'utils/routes';

export const recruiterTableColumns = [
  {
    header: 'Nom',
    accessors: ['fullName'],
    headerStyle: { width: '15%' },
    type: 'link',
  },
  {
    header: 'Mail',
    accessors: ['mail'],
    headerStyle: { width: '10%' },
  },
  {
    header: 'Téléphone',
    accessors: ['phone'],
    headerStyle: { width: '10%' },
  },
  {
    header: 'Poste',
    accessors: ['position'],
    headerStyle: { width: '35%' },
  },
  {
    header: 'Role',
    accessors: ['role'],
    headerStyle: { width: '10%' },
  },
  {
    header: 'Actif',
    accessors: ['active'],
    type: 'boolean',
    headerStyle: { width: '10%' },
  },
];

export const getRecruiterRowsFormatted = rows => {
  const rowsFormatted = rows.map(row => ({
    ...row,
    role: row.role?.label,
    fullName: {
      label: `${row.firstName} ${row.lastName?.toUpperCase()}`,
      url: ROUTES.RECRUITER_EDIT.replace(':id', row.id),
    },
  }));
  return rowsFormatted;
};
