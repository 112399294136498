import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { cloneCandidateState } from './helper.js';

import {
  getOneCandidate,
  getResearches,
  cleanCurrentCandidate,
} from 'redux/candidates/actions';

import PageHeader from 'components/layout/PageHeader';
import Main from 'components/layout/Main';

import Sheet from './Sheet';
import Researches from './Researches';
import CandidateSubtitles from './CandidateSubtitles/index.js';

function CandidateShowPage() {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const candidateState = useSelector(state => state.candidates);

  const candidateId = parseInt(params.id);
  const candidate = cloneCandidateState(candidateState, candidateId);

  useEffect(() => {
    dispatch(getResearches({ id: parseInt(candidateId) }));
    dispatch(getOneCandidate({ id: parseInt(candidateId) }));
    return function() {
      dispatch(cleanCurrentCandidate());
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Main
      pageTitle={t('menu.candidateTitle')}
      isLoading={candidateState.loading}
    >
      {candidate && (
        <>
          <PageHeader
            title={candidate.firstName + ' ' + candidate.lastName}
            subtitles={<CandidateSubtitles candidate={candidate} />}
          >
            <Sheet candidate={candidate} />
          </PageHeader>
          {candidate.researches && (
            <Researches researches={candidate.researches} />
          )}
        </>
      )}
    </Main>
  );
}

export default CandidateShowPage;
