import { ACTIONS_TYPE } from './actions';

export const initialState = {
  loading: false,
  kpi: {
    totalPending: null,
    totalFix: null,
    totalVariable: null,
    totalRpo: null,
    //totalRisky: null,
    totalOther: null,
    totalOlder: null,
    totalOlderThan3Months: null,
  },
  items: {
    all: { totalItemCount: 0, items: [] },
    paid: { totalItemCount: 0, items: [] },
    unpaid: { totalItemCount: 0, items: [] },
  },
  current: null,
};

export function invoicesReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPE.GT_KPI_BEGIN:
    case ACTIONS_TYPE.GET_INVOICES_BEGIN:
    case ACTIONS_TYPE.GET_INVOICE_BEGIN:
    case ACTIONS_TYPE.CREATE_INVOICE_BEGIN:
    case ACTIONS_TYPE.UPDATE_INVOICE_BEGIN:
    case ACTIONS_TYPE.DOWNLOAD_INVOICES_BEGIN:
    case ACTIONS_TYPE.DELETE_INVOICE_BEGIN:
    case ACTIONS_TYPE.SEND_INVOICES_BEGIN:
    case ACTIONS_TYPE.REMIND_INVOICE_BEGIN:
    case ACTIONS_TYPE.SET_INVOICE_REMINDER_PROGRAM_BEGIN:
    case ACTIONS_TYPE.SET_INVOICE_AS_PAID_BEGIN:
    case ACTIONS_TYPE.UPDATE_MSG_INVOICE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GT_KPI_FAILURE:
    case ACTIONS_TYPE.GET_INVOICES_FAILURE:
    case ACTIONS_TYPE.GET_INVOICE_FAILURE:
    case ACTIONS_TYPE.CREATE_INVOICE_FAILURE:
    case ACTIONS_TYPE.CREATE_INVOICE_SUCCESS:
    case ACTIONS_TYPE.UPDATE_INVOICE_FAILURE:
    case ACTIONS_TYPE.DOWNLOAD_INVOICES_SUCCESS:
    case ACTIONS_TYPE.DOWNLOAD_INVOICES_FAILURE:
    case ACTIONS_TYPE.DELETE_INVOICE_FAILURE:
    case ACTIONS_TYPE.DELETE_INVOICE_SUCCESS:
    case ACTIONS_TYPE.SEND_INVOICES_SUCCESS:
    case ACTIONS_TYPE.SEND_INVOICES_FAILURE:
    case ACTIONS_TYPE.REMIND_INVOICE_SUCCESS:
    case ACTIONS_TYPE.REMIND_INVOICE_FAILURE:
    case ACTIONS_TYPE.SET_INVOICE_REMINDER_PROGRAM_SUCCESS:
    case ACTIONS_TYPE.SET_INVOICE_REMINDER_PROGRAM_FAILURE:
    case ACTIONS_TYPE.SET_INVOICE_AS_PAID_SUCCESS:
    case ACTIONS_TYPE.SET_INVOICE_AS_PAID_FAILURE:
    case ACTIONS_TYPE.UPDATE_MSG_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_INVOICES_SUCCESS: {
      return {
        ...state,
        loading: false,
        items: {
          all: action.result.all
            ? {
                totalItemCount: action.result.all.totalItemCount,
                items: action.result.all.invoices,
              }
            : state.items.all,
          paid: action.result.paid
            ? {
                totalItemCount: action.result.paid.totalItemCount,
                items: action.result.paid.invoices,
              }
            : state.items.paid,
          unpaid: action.result.unpaid
            ? {
                totalItemCount: action.result.unpaid.totalItemCount,
                items: action.result.unpaid.invoices,
              }
            : state.items.unpaid,
        },
      };
    }
    case ACTIONS_TYPE.UPDATE_MSG_INVOICE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        items: {
          ...state.items,
          all: {
            ...state.items.all,
            items: state.items.all.items.map(
              item => (action.result.id === item.id ? action.result : item)
            ),
          },
          paid: {
            ...state.items.paid,
            items: state.items.paid.items.map(
              item => (action.result.id === item.id ? action.result : item)
            ),
          },
          unpaid: {
            ...state.items.unpaid,
            items: state.items.unpaid.items.map(
              item => (action.result.id === item.id ? action.result : item)
            ),
          },
        },
      });
    case ACTIONS_TYPE.UPDATE_INVOICE_SUCCESS:
    case ACTIONS_TYPE.GET_INVOICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        current: action.result,
      };
    }
    case ACTIONS_TYPE.GET_KPI_SUCCESS:
      return {
        ...state,
        //loading: false,
        kpi: {
          totalPending: action.result.totalUnpaid,
          totalFix: action.result.fixUnpaid,
          totalVariable: action.result.variableUnpaid,
          totalRpo: action.result.rpoUnpaid,
          //totalRisky: action.result.riskyUnpaid,
          totalOther: action.result.otherUnpaid,
          totalOlder: action.result.olderUnpaid,
          totalOlderThan3months: action.result.olderThan3MonthsUnpaid,
        },
      };

    case ACTIONS_TYPE.CLEAN_CURRENT_INVOICE: {
      return {
        ...state,
        current: null,
      };
    }
    default:
      return state;
  }
}
