import { Grid } from 'components/core/containers';
import { useTranslation } from 'react-i18next';
import {
  FollowUpField,
  PERIODS,
} from 'components/eiquem/placement/followUp/Field';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  remindAndPostponeFollowUp,
  updateFollowUp,
} from 'redux/followUps/actions';
import { formatDateParameter } from 'utils/functions';

export default function Detail(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [followUps, setFollowUps] = useState([...props.row.followUps]);

  useEffect(
    () => {
      setFollowUps([...props.row.followUps]);
    },
    [props.row.followUps]
  );

  const handleChange = value => {
    if (!value.date) {
      return;
    }

    setFollowUps(
      followUps.map(followUp => (followUp.id === value.id ? value : followUp))
    );
    dispatch(
      updateFollowUp({
        id: value.id,
        followUpDate: formatDateParameter(value.date),
        isDone: value.isDone,
      })
    );
  };

  const handleRemindAndPostpone = ({ id }) =>
    dispatch(remindAndPostponeFollowUp({ id }));

  return (
    <Grid container>
      <Grid md={4}>
        <FollowUpField
          label={t('followUp.periods.week1')}
          period={PERIODS.W1}
          followUps={followUps}
          onChange={handleChange}
          onRemindAndPostpone={handleRemindAndPostpone}
        />
        <FollowUpField
          label={t('followUp.periods.week4')}
          period={PERIODS.W4}
          followUps={followUps}
          onChange={handleChange}
          onRemindAndPostpone={handleRemindAndPostpone}
        />
      </Grid>
      <Grid md={4}>
        <FollowUpField
          label={t('followUp.periods.quarter')}
          period={PERIODS.Q}
          followUps={followUps}
          onChange={handleChange}
          onRemindAndPostpone={handleRemindAndPostpone}
        />
      </Grid>
      <Grid md={4}>
        <FollowUpField
          label={t('followUp.periods.month-1')}
          period={PERIODS.M1}
          followUps={followUps}
          onChange={handleChange}
          onRemindAndPostpone={handleRemindAndPostpone}
        />
        <FollowUpField
          label={t('followUp.periods.month')}
          period={PERIODS.M}
          followUps={followUps}
          onChange={handleChange}
          onRemindAndPostpone={handleRemindAndPostpone}
        />
      </Grid>
    </Grid>
  );
}
