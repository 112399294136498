export const tableColumns = [
  {
    header: 'Prénom',
    accessors: ['firstName'],
    style: { fontWeight: 'bold' },
  },
  {
    header: 'Nom',
    accessors: ['lastName'],
    style: { fontWeight: 'bold' },
  },
  {
    header: 'Email',
    accessors: ['email'],
    style: { fontWeight: 'bold', width: '70%' },
  },
  {
    header: 'Téléphone',
    accessors: ['phone'],
    style: { fontWeight: 'bold' },
  },
  {
    header: 'Titre',
    accessors: ['position'],
    headerStyle: { width: '30%' },
  },
  {
    header: 'Actif',
    accessors: ['active'],
    type: 'boolean',
  },
  {
    header: 'Super Admin',
    accessors: ['superAdmin'],
    type: 'boolean',
  },
];

export const initialAdmin = {
  firstName: null,
  lastName: null,
  email: null,
  login: null,
  password: null,
  phone: null,
  position: null,
  photo: null,
  superAdmin: false,
  calendly: null,
  active: true,
};
