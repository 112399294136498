import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAdminOptions,
  getCommercialProposalStatusOptions,
  getContractTypeOptions,
  getPlanOptions,
  getSourceOptions,
  getProposalModels,
  cleanProposalModels,
  getCompanySizeOptions,
  getBusinessSectorOptions,
  getFunctionByFamilyOptions,
  getTeleworkingOptions,
  getStudyLevelOptions,
  getRecruiterOptions,
  getOfferOptions,
  getCompanyOptions,
  cleanOfferOptions,
  getAmountOptions,
  cleanAmountOptions,
} from 'redux/formOptions/actions';

const useOptionSelector = option => {
  return useSelector(state => state.formOptions[option]);
};

const useFetchOptions = (selector, action, cleanUp) => {
  const dispatch = useDispatch();
  const options = useOptionSelector(selector);

  const clean = useCallback(
    () => {
      if (cleanUp) {
        dispatch(cleanUp());
      }
    },
    [dispatch, cleanUp]
  );

  const fetch = useCallback(
    params => {
      dispatch(action(params));
      return clean;
    },
    [dispatch, action, clean]
  );

  return [fetch, options, clean];
};

export const useFetchAdmins = () => {
  return useFetchOptions('adminOptions', getAdminOptions);
};

export const useFetchPlans = () => {
  return useFetchOptions('planOptions', getPlanOptions);
};

export const useFetchProvenances = () => {
  const action = useCallback(type => {
    return getSourceOptions({ type });
  }, []);

  return useFetchOptions('sourceOptions', action);
};

export const useFetchContractTypes = () => {
  return useFetchOptions('contractTypeOptions', getContractTypeOptions);
};

export const useFetchProposalStatuses = () => {
  return useFetchOptions(
    'commercialProposalStatusOptions',
    getCommercialProposalStatusOptions
  );
};

export const useFetchProposalModels = () => {
  return useFetchOptions(
    'proposalModels',
    getProposalModels,
    cleanProposalModels
  );
};

export const useFetchCompanySizes = () => {
  return useFetchOptions('companySizeOptions', getCompanySizeOptions);
};

export const useFetchBusinessSectors = () => {
  return useFetchOptions('businessSectorOptions', getBusinessSectorOptions);
};

export const useFetchFunctions = () => {
  return useFetchOptions('functionByFamilyOptions', getFunctionByFamilyOptions);
};

export const useFecthStudyLevels = () => {
  return useFetchOptions('studyLevelOptions', getStudyLevelOptions);
};

export const useFetchTeleworkings = () => {
  return useFetchOptions('teleworkingOptions', getTeleworkingOptions);
};

export const useFetchCompanies = () => {
  return useFetchOptions('companyOptions', getCompanyOptions);
};

export const useFetchRecruiters = () => {
  return useFetchOptions('recruiterOptions', getRecruiterOptions);
};

export const useFetchOffers = () => {
  const action = useCallback(({ companyId, onlyActive = false }) => {
    return getOfferOptions({
      company: companyId,
      onlyActive: onlyActive ? 1 : 0,
    });
  }, []);

  return useFetchOptions('offerOptions', action, cleanOfferOptions);
};

export const useFetchAmounts = () => {
  const action = useCallback(({ offerId }) => {
    return getAmountOptions({
      id: offerId,
    });
  }, []);

  return useFetchOptions('amountOptions', action, cleanAmountOptions);
};
