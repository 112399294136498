import { ACTIONS_TYPE } from './actions';

export const initialState = {
  loading: false,
  totalItemCount: 0,
  items: [],
  current: null,
};

export function adminsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPE.GET_ADMINS_BEGIN:
    case ACTIONS_TYPE.GET_ONE_ADMIN_BEGIN:
    case ACTIONS_TYPE.UPDATE_ADMIN_BEGIN:
    case ACTIONS_TYPE.CREATE_ADMIN_BEGIN:
      return { ...state, loading: true };
    case ACTIONS_TYPE.GET_ADMINS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.result.admins,
        totalItemCount: action.result.totalItemCount,
      };
    case ACTIONS_TYPE.GET_ADMINS_FAILURE:
    case ACTIONS_TYPE.UPDATE_ADMIN_SUCCESS:
    case ACTIONS_TYPE.UPDATE_ADMIN_FAILURE:
    case ACTIONS_TYPE.CREATE_ADMIN_SUCCESS:
    case ACTIONS_TYPE.CREATE_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        items: [],
      };
    case ACTIONS_TYPE.GET_ONE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        current: action.result,
      };
    case ACTIONS_TYPE.GET_ONE_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        current: null,
      };
    case ACTIONS_TYPE.CLEAN_CURRENT_ADMIN:
      return Object.assign({}, state, {
        ...state,
        current: null,
      });
    default:
      return state;
  }
}
