import PropTypes from 'prop-types';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import styled from 'styled-components';
import { Th } from './th';

OrderTh.propTypes = {
  name: PropTypes.string,
  sortOrder: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.element,
};

export const SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
  NULL: null,
};

export function OrderTh({ name, sortOrder, onClick, children }) {
  const handleClick = () => onClick({ name });
  return (
    <Th>
      <GrabDiv onClick={handleClick}>
        {children}
        <div>
          {sortOrder === SORT_ORDER.ASC ? (
            <ArrowUpwardIcon fontSize="small" />
          ) : (
            <HiddenDiv isHidden={sortOrder === SORT_ORDER.NULL}>
              <ArrowDownwardIcon fontSize="small" />
            </HiddenDiv>
          )}
        </div>
      </GrabDiv>
    </Th>
  );
}

const GrabDiv = styled.div`
  display: flex;
  cursor: grab;
  align-items: center;
  user-select: none;
`;

const HiddenDiv = styled.div`
  color: ${({ isHidden }) => (isHidden ? 'transparent' : 'inherit')};
`;
