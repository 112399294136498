import DropdownButton from 'components/DropdownButton';
import Subtitle from 'components/layout/Subtitle';
import OfferTable from 'components/eiquem/company/OfferTable';

function Offers(props) {
  const companyId = props.companyId;

  return (
    <DropdownButton
      label={<Subtitle label="Offres" />}
      content={<OfferTable companyId={companyId} />}
    />
  );
}

export default Offers;
