import { useEffect } from 'react';
import services from 'utils/api/Services/services';

import { FilterAccordion } from 'components/core/Accordion';
import { Grid } from 'components/core/containers';
import {
  AutocompleteAsyncFilter,
  AutocompleteFilter,
  DateFilter,
} from 'components/core/FilterInput';
import {
  useFetchProposalStatuses,
  useFetchContractTypes,
} from 'utils/hooks/formOptions';

const Filter = ({ onChange }) => {
  const [fetchStatuses, statuses] = useFetchProposalStatuses();
  const [fetchContractTypes, contractTypes] = useFetchContractTypes();

  useEffect(
    () => {
      if (!contractTypes) {
        fetchContractTypes();
      }
      if (!statuses) {
        fetchStatuses();
      }
    },

    [contractTypes, fetchContractTypes, statuses, fetchStatuses]
  );

  const handleChange = ({ name, value }) => {
    switch (name) {
      case 'admin':
        onChange({ admin: value });
        break;
      case 'company':
        onChange({ company: value });
        break;
      case 'contract':
        onChange({ contract: value });
        break;
      case 'from-date':
        onChange({ fromDate: value });
        break;
      case 'to-date':
        onChange({ toDate: value });
        break;
      case 'status':
        onChange({ status: value });
        break;
      default:
        throw new Error('invalid name');
    }
  };

  return (
    <FilterAccordion>
      <Grid container>
        <Grid md={8} sm={12}>
          <Grid container>
            <Grid md={6} sm={12}>
              <AutocompleteAsyncFilter
                name="admin"
                service={fetchAdmins}
                label="Administrateur"
                onChange={handleChange}
              />
            </Grid>
            <Grid md={6} sm={12}>
              <AutocompleteAsyncFilter
                name="company"
                service={fetchCompanies}
                label="Entreprise"
                onChange={handleChange}
              />
            </Grid>
            <Grid md={6} sm={12}>
              <AutocompleteFilter
                name="contract"
                options={contractTypes}
                label="Contrat"
                onChange={handleChange}
              />
            </Grid>
            <Grid md={3} sm={6}>
              <DateFilter name="from-date" label="De" onChange={handleChange} />
            </Grid>
            <Grid md={3} sm={6}>
              <DateFilter name="to-date" label="À" onChange={handleChange} />
            </Grid>

            <Grid md={6} sm={12}>
              <AutocompleteFilter
                name="status"
                options={statuses}
                label="Statut"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FilterAccordion>
  );
};

export default Filter;

const fetchAdmins = ({ search = null, id = null }) =>
  services.options.getAdmins({ search: search, id: id });
const fetchCompanies = ({ search = null, id = null }) =>
  services.options.getCompanies({ search: search, id: id });
