import { useTranslation } from 'react-i18next';

import Main from 'components/layout/Main';
import Title from 'components/layout/Title';
import CompanyForm from './companyForm';
import { Paper } from '@material-ui/core';
import { useFetchCompany, useUpdateCompany } from 'utils/hooks/company';
import { useEffect } from 'react';

export default function CreateCompanyForm({ id }) {
  const [fetchCompany, company, isLoading] = useFetchCompany(id);
  useEffect(
    () => {
      fetchCompany(id);
    },
    [fetchCompany, id]
  );

  const updateCompany = useUpdateCompany();
  const handleSubmit = values => updateCompany(values);

  const { t } = useTranslation();
  return (
    <Main pageTitle={t('company.title')} isLoading={isLoading}>
      <Title>
        {t('company.edit')} - {id}
      </Title>
      <Paper>
        {company && <CompanyForm company={company} onSubmit={handleSubmit} />}
      </Paper>
    </Main>
  );
}
