import GetAppIcon from '@material-ui/icons/GetApp';
import ActionButton from 'components/ActionButton';

function DownloadOneButton(props) {
  const { action, id } = props;
  const handleOnClick = () => {
    action(id);
  };

  return (
    <ActionButton
      action={handleOnClick}
      label="Télécharger propale"
      icon={<GetAppIcon fontSize="small" />}
    />
  );
}

export default DownloadOneButton;
