import axios from 'axios';

import { localStorageJwtKey, localStorageRefreshJwtKey } from 'utils/constants';
import jwtDecode from 'jwt-decode';
import { refreshToken } from 'redux/auth/actions';
import { ACTIONS_TYPE } from 'redux/auth/actions';

import { getHeaders } from './headers';
import type { FetchAPIType } from 'utils/api/types';

import { apiUrl } from 'utils/constants';
import { downloadFile } from 'utils/functions';

export const fetch = async ({
  service,
  dispatch,
  //getState,
  actionRequest,
  actionSuccess,
  actionFailure,
  validator,
}: FetchAPIType) => {
  await refreshJwtTokenIfExpired(dispatch, actionRequest());

  const { url, method, data, params, responseType } = service;
  dispatch(actionRequest());
  return axios({
    method: method,
    url: `${apiUrl}` + url,
    data: data,
    params: params,
    headers: getHeaders(),
    responseType: responseType,
    withCredentials: true,
  })
    .then(response => {
      if (responseType === 'blob') {
        downloadFile(response);
        dispatch(actionSuccess());
      } else {
        const json = response.data;
        if (validator) {
          const { errors } = validator(json);
          if (errors.length) {
          }
        }
        dispatch(actionSuccess(json));
      }
    })
    .catch(error => {
      const errorMsg = error.response ? error.response.data : error;
      dispatch(actionFailure(errorMsg));
    });
};

const refreshJwtTokenIfExpired = async (dispatch, action) => {
  if (!action || isAuthAction(action)) return;

  const jwtEncoded = localStorage.getItem(localStorageJwtKey);
  if (jwtEncoded === null) return;

  const jwt = jwtDecode(jwtEncoded) || null;
  if (jwt === null) return;

  if (Date.now() <= jwt.exp * 1000) return;

  const refresh = localStorage.getItem(localStorageRefreshJwtKey);
  if (refresh === null) return;

  await dispatch(refreshToken({ refresh_token: refresh }));
};

const isAuthAction = action => {
  switch (action.type) {
    case ACTIONS_TYPE.PROCESS_LOGIN_BEGIN:
    case ACTIONS_TYPE.PROCESS_REFRESH_TOKEN_BEGIN:
    case ACTIONS_TYPE.LOGOUT:
      return true;
    default:
      return false;
  }
};
