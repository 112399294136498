import { useTranslation } from 'react-i18next';

import { ROUTES } from 'utils/routes';

import {
  Grid,
  FormControlLabel,
  Checkbox,
  withStyles,
} from '@material-ui/core';

import TextField from 'components/core/input/TextField';
import ImageField from 'components/core/input/ImageField';
import SubmitButtons from 'components/core/SubmitButtons';
import S from './styles';

function Form(props) {
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    classes,
    isSubmitting,
  } = props;

  const { t } = useTranslation();

  const handleChange = (fieldName, value) => {
    setFieldValue(fieldName, value, true);
    setFieldTouched(fieldName, true, false);
  };

  return (
    <form onSubmit={handleSubmit} style={{ padding: 20 }}>
      <Grid container spacing={8}>
        <Grid item xs={6} md={4}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
              {/* FirstName */}
              <TextField
                label={t('admin.fields.firstName')}
                touched={touched.firstName}
                error={errors.firstName}
                className={classes.field}
                handleChange={({ value }) => {
                  handleChange('firstName', value);
                }}
                value={values.firstName}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              {/* LastName */}
              <TextField
                label={t('admin.fields.lastName')}
                touched={touched.lastName}
                error={errors.lastName}
                className={classes.field}
                handleChange={({ value }) => {
                  handleChange('lastName', value);
                }}
                value={values.lastName}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              {/* Email */}
              <TextField
                label={t('admin.fields.email')}
                touched={touched.email}
                error={errors.email}
                className={classes.field}
                handleChange={({ value }) => {
                  handleChange('email', value);
                }}
                value={values.email}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              {/* Phone */}
              <TextField
                label={t('admin.fields.phone')}
                touched={touched.phone}
                error={errors.phone}
                className={classes.field}
                handleChange={({ value }) => {
                  handleChange('phone', value);
                }}
                value={values.phone}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Column 2 */}
        <Grid item xs={6} md={4}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
              {/* Position */}
              <TextField
                label={t('admin.fields.position')}
                touched={touched.position}
                error={errors.position}
                className={classes.field}
                handleChange={({ value }) => {
                  handleChange('position', value);
                }}
                value={values.position}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              {/* Calendly */}
              <TextField
                type="calendly"
                label={t('admin.fields.calendly')}
                touched={touched.calendly}
                error={errors.calendly}
                className={classes.field}
                handleChange={({ value }) => {
                  handleChange('calendly', value);
                }}
                value={values.calendly}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              {/* Photo */}
              <ImageField
                label={t('admin.fields.photo')}
                value={values.photo}
                onChange={image => {
                  values.photo = image;
                }}
                maxSize={1}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
              {/* Login */}
              <TextField
                label={t('admin.fields.login')}
                touched={touched.login}
                error={errors.login}
                className={classes.field}
                handleChange={({ value }) => {
                  handleChange('login', value);
                }}
                value={values.login}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              {/* Password */}
              <TextField
                type="password"
                label={t('admin.fields.password')}
                touched={touched.password}
                error={errors.password}
                className={classes.field}
                handleChange={({ value }) => {
                  handleChange('password', value);
                }}
                value={values.password}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              {/* Super Admin */}
              <FormControlLabel
                label={t('admin.fields.superAdmin')}
                control={
                  <Checkbox
                    id="superAdmin"
                    checked={values.superAdmin}
                    onChange={() => {
                      const value = values.superAdmin ? false : true;
                      handleChange('superAdmin', value);
                    }}
                    color="primary"
                  />
                }
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={6} md={6}>
              {/* Active */}
              <FormControlLabel
                label={t('admin.fields.active')}
                control={
                  <Checkbox
                    id="active"
                    checked={values.active}
                    onChange={() => {
                      const value = values.active ? false : true;
                      handleChange('active', value);
                    }}
                    color="primary"
                  />
                }
                labelPlacement="end"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SubmitButtons
        cancelRoute={ROUTES.ADMIN_LIST}
        isSubmitting={isSubmitting}
      />
    </form>
  );
}

export default withStyles(S)(Form);
