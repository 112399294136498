import { Slider as SliderMui } from '@material-ui/core';

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 9,
    label: '9',
  },
];

/* function valueLabelFormat(value) {
  return marks.findIndex(mark => mark.value === value) + 1;
} */

export default function Slider(props) {
  const { label, value, handleChange } = props;
  //const classes = useStyles();

  return (
    <div /*className={classes.root}*/>
      <div
        id={label}
        //gutterBottom
        style={{
          transform: 'scale(0.75)',
          transformOrigin: 'top left',
          color: 'rgba(0, 0, 0, 0.54)',
        }}
      >
        {label}
      </div>
      <SliderMui
        value={value}
        step={1}
        max={9}
        aria-labelledby={label}
        valueLabelDisplay="auto"
        marks={marks}
        onChange={(event, value) => {
          handleChange(Number(value));
        }}
      />
    </div>
  );
}
