import { fetch } from 'utils/api';
import services from 'utils/api/Services/services';
import { actionRequest, actionSuccess, actionFailure } from 'utils/functions';

export const ACTIONS_TYPE = Object.freeze({
  GET_APPLICATION_FOLLOW_UP_BEGIN: 'GET_APPLICATION_FOLLOW_UP_BEGIN',
  GET_APPLICATION_FOLLOW_UP_SUCCESS: 'GET_APPLICATION_FOLLOW_UP_SUCCESS',
  GET_APPLICATION_FOLLOW_UP_FAILURE: 'GET_APPLICATION_FOLLOW_UP_FAILURE',
  CLEAN_APPLICATION_FOLLOW_UP: 'CLEAN_APPLICATION_FOLLOW_UP',

  GET_APPLICATION_FOLLOW_UPS_BEGIN: 'GET_APPLICATION_FOLLOW_UPS_BEGIN',
  GET_APPLICATION_FOLLOW_UPS_SUCCESS: 'GET_APPLICATION_FOLLOW_UPS_SUCCESS',
  GET_APPLICATION_FOLLOW_UPS_FAILURE: 'GET_APPLICATION_FOLLOW_UPS_FAILURE',

  UPDATE_APPLICATION_FOLLOW_UP_BEGIN: 'UPDATE_APPLICATION_FOLLOW_UP_BEGIN',
  UPDATE_APPLICATION_FOLLOW_UP_SUCCESS: 'UPDATE_APPLICATION_FOLLOW_UP_SUCCESS',
  UPDATE_APPLICATION_FOLLOW_UP_FAILURE: 'UPDATE_APPLICATION_FOLLOW_UP_FAILURE',
});

export const updateApplicationFollowUp = ({
  id,
  date,
  notifyAndPostpone,
  useFamiliarity,
}) => (dispatch, getState) =>
  fetch({
    service: services.applicationFollowUps.update({
      id,
      date,
      notifyAndPostpone,
      useFamiliarity,
    }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.UPDATE_APPLICATION_FOLLOW_UP_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.UPDATE_APPLICATION_FOLLOW_UP_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_APPLICATION_FOLLOW_UP_FAILURE, errors),
  });

export const getApplicationFollowUp = ({ candidateId }) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.applicationFollowUps.getOne({ candidateId }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_APPLICATION_FOLLOW_UP_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_APPLICATION_FOLLOW_UP_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_APPLICATION_FOLLOW_UP_FAILURE, errors),
  });

export const cleanApplicationFollowUp = () => ({
  type: ACTIONS_TYPE.CLEAN_APPLICATION_FOLLOW_UP,
});

export const getApplicationFollowUps = ({ adminId }) => (dispatch, getState) =>
  fetch({
    service: services.applicationFollowUps.get({ adminId }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_APPLICATION_FOLLOW_UPS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_APPLICATION_FOLLOW_UPS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_APPLICATION_FOLLOW_UPS_FAILURE, errors),
  });
