export default () => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  block: {
    flexDirection: 'column',
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
  },
});
