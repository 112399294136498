import { Checkbox, DatePicker } from 'components/core/input';
import ReplayIcon from '@material-ui/icons/Replay';
import ActionButton from 'components/ActionButton';
import { useTranslation } from 'react-i18next';

export const PERIODS = Object.freeze({
  W1: 'w1',
  W4: 'w4',
  Q: 'q',
  M1: 'm1',
  M: 'm',
});

export function FollowUpField({
  label,
  period,
  followUps,
  onChange,
  onRemindAndPostpone,
}) {
  const filteredFollowUps = followUps?.filter(
    followUp => followUp.period === period
  );

  return (
    <>
      {filteredFollowUps &&
        filteredFollowUps.map(followUp => (
          <Field
            key={followUp.id}
            id={followUp.id}
            label={label}
            line={followUp}
            onChange={onChange}
            onRemindAndPostpone={onRemindAndPostpone}
          />
        ))}
    </>
  );
}

function Field({ id, label, line, onChange, onRemindAndPostpone }) {
  const { t } = useTranslation();

  const handleChange = ({ name, value }) => {
    onChange({ ...line, [name]: value });
  };

  const handleRemindAndPostpone = () => {
    onRemindAndPostpone && onRemindAndPostpone({ id });
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          width: 100,
          display: 'flex',
          alignItems: 'baseline',
          marginRight: 15,
        }}
      >
        <Checkbox
          name="isDone"
          value={line.isDone}
          onChange={handleChange}
          label={label}
        />
      </div>
      <DatePicker name="date" value={line.date} onChange={handleChange} />
      {onRemindAndPostpone && (
        <div style={{ display: 'flex', alignItems: 'end' }}>
          <ActionButton
            action={handleRemindAndPostpone}
            title={t('followUp.remindAndPostpone.title')}
            label={t('followUp.remindAndPostpone.title')}
            dialogContent={t('followUp.remindAndPostpone.content')}
            icon={<ReplayIcon />}
            tooltipPosition="left"
            dialog
            confirmText={t('common.validate')}
            disabled={line.isDone}
          />
          ({line.remindCount})
        </div>
      )}
    </div>
  );
}
