import { TextField } from 'components/core/input';
import { useTranslation } from 'react-i18next';

export default function VariablePrice({
  values,
  touched,
  errors,
  onChange,
  name,
}) {
  const { t } = useTranslation();

  return (
    <TextField
      name={name ?? 'variableCost'}
      label={t('paf.field.variableCost')}
      touched={name ? touched[name] : touched.variableCost}
      error={name ? errors[name] : errors.variableCost}
      type={'number'}
      handleChange={onChange}
      value={name ? values[name] : values.variableCost}
    />
  );
}
