import { Grid } from 'components/core/containers';
import Subtitle from 'components/layout/Subtitle';
import { useTranslation } from 'react-i18next';

export default function Title({ label }) {
  const { t } = useTranslation();

  return (
    <Grid md={12}>
      <Subtitle label={t(label)} />
      <div style={{ marginBottom: 10 }} />
    </Grid>
  );
}
