import { useTranslation } from 'react-i18next';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Box } from '@material-ui/core';
import { Subtitle } from './subtitle';
import { internshipTypeId, workAndStudyTypeId } from 'utils/constants';

export default function DurationFields(props) {
  const {
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    classes,
    getFormOptions,
    showInternshipPart,
    showWorkAndStudyPart,
  } = props;

  const { t } = useTranslation();
  const { durationOptions } = getFormOptions();
  const internshipDurationOptions = durationOptions
    ? durationOptions[internshipTypeId]
    : null;
  const workAndStudyDurationOptions = durationOptions
    ? durationOptions[workAndStudyTypeId]
    : null;

  return (
    <Box>
      {(showInternshipPart || showWorkAndStudyPart) && (
        <Subtitle label="Durées" />
      )}
      {showInternshipPart &&
        internshipDurationOptions && (
          <Autocomplete
            multiple
            id="internshipDuration"
            className={classes.selectLargeField}
            options={internshipDurationOptions}
            getOptionLabel={internshipDurationOptions =>
              internshipDurationOptions.label
            }
            defaultValue={values.internshipDuration}
            getOptionSelected={(option, value) => option.value === value.value}
            renderInput={params => (
              <TextField
                {...params}
                id="internshipDuration"
                variant="standard"
                label={t('jobboards-posting.field.internshipDuration')}
                fullWidth
                error={
                  touched.internshipDuration &&
                  Boolean(errors.internshipDuration)
                }
                helperText={
                  touched.internshipDuration ? errors.internshipDuration : ''
                }
              />
            )}
            onChange={(event, value) => {
              setFieldValue('internshipDuration', value);
              setFieldTouched('internshipDuration', true, false);
            }}
          />
        )}
      {showWorkAndStudyPart &&
        workAndStudyDurationOptions && (
          <Autocomplete
            multiple
            id="workAndStudyDuration"
            className={classes.selectLargeField}
            options={workAndStudyDurationOptions}
            getOptionLabel={option => option.label}
            defaultValue={values.workAndStudyDuration}
            renderInput={params => (
              <TextField
                {...params}
                id="workAndStudyDuration"
                variant="standard"
                label={t('jobboards-posting.field.workAndStudyDuration')}
                fullWidth
                error={
                  touched.workAndStudyDuration &&
                  Boolean(errors.workAndStudyDuration)
                }
                helperText={
                  touched.workAndStudyDuration
                    ? errors.workAndStudyDuration
                    : ''
                }
              />
            )}
            onChange={(event, value) => {
              setFieldValue('workAndStudyDuration', value);
              setFieldTouched('workAndStudyDuration', true, false);
            }}
          />
        )}
    </Box>
  );
}
