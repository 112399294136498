import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';

AutocompleteEnhanced.defaultProps = {
  creatable: false,
  multi: false,
  options: [],
  disableCloseOnSelect: false,
  disabled: false,
  disableClearable: false,
};

export default function AutocompleteEnhanced(props) {
  const {
    name,
    className,
    options,
    defaultValue,
    value,
    label,
    multi,
    creatable,
    onChange,
    disableCloseOnSelect,
    disabled,
    disableClearable,
    touched,
    error,
  } = props;

  const manageOptionLabel = option => {
    if (typeof option === 'string') {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return option.label;
  };
  const filterOptions = createFilterOptions();
  const manageFilterOptions = (options, params) => {
    const filtered = filterOptions(options, params);
    if (creatable && params.inputValue !== '' && filtered.length === 0) {
      filtered.push({
        inputValue: params.inputValue,
        label: `Ajouter "${params.inputValue}"`,
      });
    }
    return filtered;
  };

  const handleChange = (_, value) => {
    if (Array.isArray(value)) {
      const newValues = value.map(value => handleFormatValue(value));
      onChange({ name, value: newValues });
    } else {
      const newValue = handleFormatValue(value);
      onChange({ name, value: newValue });
    }
  };

  const handleFormatValue = value => {
    if (value === null) {
      return null;
    }
    let newValue = {
      ...value,
      value: value.value ? value.value : -1,
      label: value.label ? value.label : null,
    };
    if (value && value.inputValue) {
      newValue.label = value.inputValue;
    } else if (typeof value === 'string') {
      newValue.label = value;
    }

    return newValue;
  };

  return (
    <Autocomplete
      id={'autocomple-'.concat(label)}
      multiple={multi}
      size="small"
      defaultValue={defaultValue}
      value={value}
      disableCloseOnSelect={disableCloseOnSelect}
      className={className}
      options={options || []}
      freeSolo={creatable}
      renderOption={option => option.label}
      getOptionLabel={option => manageOptionLabel(option)}
      filterOptions={(options, params) => manageFilterOptions(options, params)}
      getOptionSelected={(option, value) =>
        creatable ? null : option.value === value.value
      }
      onChange={(event, value) => {
        handleChange(event, value);
      }}
      forcePopupIcon
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          label={label}
          error={touched && Boolean(error)}
          helperText={touched ? error : ''}
        />
      )}
      groupBy={option => option.groupLabel}
      disableClearable={disableClearable}
    />
  );
}
