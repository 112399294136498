import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ActionsCell, CustomCell, SelectCell } from './Cell';

Row.propTypes = {
  id: PropTypes.number,
  columns: PropTypes.array,
  rows: PropTypes.array,
  actions: PropTypes.func,
  showDetail: PropTypes.bool,
  onShowDetail: PropTypes.func,
  selectedRows: PropTypes.array,
  onSelectRow: PropTypes.func,
  selectable: PropTypes.bool,
};

Row.defaultProps = {
  columns: [],
  rows: [],
  actions: null,
  detail: null,
  showDetail: false,
  onShowDetail: () => {},
  selectedRows: [],
  onSelectRow: null,
  selectable: false,
};

function Row({
  id,
  columns,
  row,
  actions,
  detail,
  showDetail,
  onShowDetail,
  selectedRows,
  onSelectRow,
  selectable,
}) {
  const Detail = detail;

  const handleClick = useCallback(
    () => {
      onShowDetail(id, !showDetail);
    },
    [showDetail]
  );

  return (
    <>
      <Tr backgroundColor={row.backgroundColor}>
        <ActionsCell actions={actions} row={row} />
        {columns.map((column, index) => (
          <CustomCell
            key={index}
            onClick={handleClick}
            column={column}
            row={row}
          />
        ))}

        {selectable && (
          <SelectCell
            id={id}
            onChange={onSelectRow}
            selectedRows={selectedRows}
          />
        )}
      </Tr>
      {showDetail && (
        <tr>
          <td colSpan={columns.length + (actions !== null ? 1 : 0)}>
            <Detail row={row} isShown={showDetail} />
          </td>
        </tr>
      )}
    </>
  );
}

const Tr = styled.tr`
  background-color: ${props => props.backgroundColor};
  height: 50px;
`;

export default memo(Row);
