import BaseButton from './baseButton';

export default function ResumeUpdateRequestButton(props) {
  const { value } = props;

  const msg = `Souhaites-tu suggérer au candidat d'améliorer son CV ?
        Un mail sera envoyé au candidat.`;

  return (
    <BaseButton
      title="Amélioration du CV requis"
      label="CV"
      message={msg}
      value={value}
      color="primary"
      action={props.action}
      disabled={props.disabled}
    />
  );
}
