import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { checkAuthMiddleware } from './auth/middleware';
import { postingMiddleware } from './jobBoardsPosts/middleware';
import { invoicesMiddleware } from './invoices/middleware';
import { creditNotesMiddleware } from './creditNotes/middleware';
import { offersMiddleware } from './offers/middleware';
import { pafsMiddleware } from './pafs/middleware';
import { companiesMiddleware } from './companies/middleware';
import { recruitersMiddleware } from './recruiters/middleware';
import { adminsMiddleware } from './admins/middleware';
import { commercialProposalMiddleware } from './commercialProposals/middleware';
import { candidatesMiddleware } from './candidates/middleware';
import { followUpsMiddleware } from './followUps/middleware';

import history from 'utils/history';
import createRootReducer from './reducers';

const composeEnhancers =
  process.env.NODE_ENV === 'development' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: 'BO-AJStage',
        maxAge: 20,
        features: {
          pause: true, // start/pause recording of dispatched actions
          lock: true, // lock/unlock dispatching actions and side effects
          persist: true, // persist states on page reloading
          export: true, // export history of actions in a file
          jump: true, // jump back and forth (time travelling)
          skip: true, // skip (cancel) actions
          reorder: false, // drag and drop actions in the history list
          dispatch: true, // dispatch custom actions or action creators
          test: false, // generate tests for the selected actions
        },
      })
    : compose;

export default (initialState: any) => {
  const middlewares = [
    thunk,
    checkAuthMiddleware,
    postingMiddleware,
    invoicesMiddleware,
    creditNotesMiddleware,
    offersMiddleware,
    pafsMiddleware,
    companiesMiddleware,
    recruitersMiddleware,
    adminsMiddleware,
    commercialProposalMiddleware,
    candidatesMiddleware,
    followUpsMiddleware,
  ];
  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  if (module.hot && process.env.NODE_ENV !== 'production') {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};
