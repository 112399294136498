import {
  ACTIONS_TYPE,
  getApplications,
  getOneCandidate,
  getPlacementFollowUp,
  getResearches,
} from './actions';
import { ROUTES } from 'utils/routes';
import { pushRedirection } from 'utils/functions';
import { getApplicationFollowUp } from 'redux/applicationFollowUps/actions';

export const candidatesMiddleware = ({
  getState,
  dispatch,
}) => next => action => {
  if (!action) {
    return;
  }

  if (
    [
      ACTIONS_TYPE.UPDATE_RESEARCH_COMMENTS_SUCCESS,
      ACTIONS_TYPE.UPDATE_RESEARCH_APPLICATION_SUCCESS,
      ACTIONS_TYPE.CANDIDATE_CONTACT_ATTEMPT_SUCCESS,
      ACTIONS_TYPE.PLACEMENT_CONTACT_ATTEMPT_SUCCESS,
      ACTIONS_TYPE.REMIND_CANDIDATE_FOLLOW_UP_SUCCESS,
      ACTIONS_TYPE.APPOINTMENT_REQUEST_SUCCESS,
    ].includes(action.type)
  ) {
    console.log('candidatesMiddleware called...');
    const candidateId = getState().candidates?.current?.id;
    if (candidateId) {
      console.log('refresh candidate data...');
      dispatch(getResearches({ id: candidateId }));
    }
  }
  if (
    [
      ACTIONS_TYPE.SUGGEST_OFFER_SUCCESS,
      ACTIONS_TYPE.ADD_CANDIDATE_APPLICATION_SUCCESS,
      ACTIONS_TYPE.SWITCH_CANDIDATE_APPLICATION_SUCCESS,
      ACTIONS_TYPE.CANDIDATE_RESUME_UPDATE_SUCCESS,
      ACTIONS_TYPE.APPLICATION_CONTACT_ATTEMPT_SUCCESS,
      ACTIONS_TYPE.ADD_APPLICATION_COMMENT_SUCCESS,
      ACTIONS_TYPE.UPDATE_RESEARCH_SUCCESS,
      ACTIONS_TYPE.UPDATE_RESUME_SUCCESS,
      ACTIONS_TYPE.UPDATE_COVER_LETTER_SUCCESS,
    ].includes(action.type)
  ) {
    console.log('candidatesMiddleware called...');

    const researchId = action.result && action.result.researchId;
    if (researchId) {
      console.log('refresh candidate applications and suggestions...');
      dispatch(getApplications(researchId));
    }
    const candidateId = getState().candidates?.current?.id;
    if (candidateId) {
      dispatch(getApplicationFollowUp({ candidateId }));
    }
  }
  if ([ACTIONS_TYPE.UPDATE_CANDIDATE_SUCCESS].includes(action.type)) {
    console.log('candidatesMiddleware called...');

    const candidateId = getState().candidates.current.id;
    if (candidateId) {
      console.log('refresh candidate profile...');
      dispatch(getOneCandidate({ id: candidateId }));
    }
  }
  if (action.type === ACTIONS_TYPE.ADD_CANDIDATE_SUCCESS) {
    console.log('redirect to previous candidate list...');
    const expectedPaths = [ROUTES.CANDIDATES_LIST];
    pushRedirection(expectedPaths);
  }

  if (action.type === ACTIONS_TYPE.REMIND_CANDIDATE_FOLLOW_UP_SUCCESS) {
    console.log('refresh candidate followUps...');
    dispatch(getPlacementFollowUp({ candidateId: action.result.candidateId }));
  }

  next(action);
};
