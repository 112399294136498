import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchAdmins } from 'utils/hooks/formOptions';
import services from 'utils/api/Services/services';

import { AutocompleteAsync } from 'components/core/input';

export default function Admin({ values, errors, touched, onChange }) {
  const { t } = useTranslation();
  const adminsOption = useFetchActiveAdmins();

  return (
    <AutocompleteAsync
      name="admin"
      defaultValues={[values.admin]}
      defaultOptions={adminsOption}
      lazy={true}
      service={fetchAdminOptions}
      label={t('paf.field.admin')}
      touched={touched.admin}
      error={errors.admin}
      onChange={onChange}
    />
  );
}

const fetchAdminOptions = searchTerm =>
  services.options.getAdmins({
    onlyActive: 0,
    search: searchTerm,
  });

const useFetchActiveAdmins = () => {
  const [fetch, options] = useFetchAdmins();

  useEffect(
    () => {
      fetch({ onlyActive: true });
    },
    [fetch]
  );

  return options;
};
