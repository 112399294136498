import { matchPath } from 'react-router-dom';
import { ACTIONS_TYPE } from 'redux/commercialProposals/actions';
import { ROUTES } from 'utils/routes';
import { pushRedirection } from 'utils/functions';

export const commercialProposalMiddleware = () => next => action => {
  if (!action) return;
  if (
    [
      ACTIONS_TYPE.CREATE_COMMERCIAL_PROPOSAL_SUCCESS,
      ACTIONS_TYPE.UPDATE_COMMERCIAL_PROPOSAL_SUCCESS,
      ACTIONS_TYPE.DELETE_COMMERCIAL_PROPOSAL_SUCCESS,
    ].includes(action.type)
  ) {
    console.log('commercialProposalMiddleware called...');
    if (!matchPath(window.location.pathname, ROUTES.COMPANY_SHOW)) {
      console.log('redirect to previous url...');
      pushRedirection([ROUTES.COMMERCIAL_PROPOSAL_LIST]);
    }
  }

  next(action);
};
