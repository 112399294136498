export default () => ({
  wrapper: {
    display: 'flex',
  },
  iconButton: {
    color: 'black',
    backgroundColor: 'transparent !important',
    marginTop: '2px',
  },
  button: {
    flex: 1,
  },
});
