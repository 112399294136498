import { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useQueryState } from 'utils/hooks/queryState';
import Autocomplete from 'components/core/input/Autocomplete';

MultiAutocompleteFilter.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.array,
  options: PropTypes.array,
};
MultiAutocompleteFilter.defaultProps = {
  options: [],
};

function MultiAutocompleteFilter({
  name,
  label,
  defaultValue,
  options,
  onChange,
}) {
  const qs = useQuery();
  const [query, setQuery] = useQueryState(qs.getAll(name), name);
  const [value, setValue] = useState(null);

  const didMount = useRef(false);
  useEffect(
    () => {
      if (options) {
        if (didMount.current) {
          return;
        }
        const defaultOptionValue = query || null;
        const defaultOptions = options.filter(option =>
          defaultOptionValue.includes(option.value.toString())
        );
        setValue(defaultOptions);

        didMount.current = true;
      }
    },
    [options, query, defaultValue, setQuery]
  );

  const handleChange = ({ value }) => {
    const queryValue = value ? value.map(val => val.value) : null;

    setQuery(queryValue);
    setValue(value);
    onChange({ value: queryValue, name: name });
  };

  return (
    <div style={{ width: '100%' }}>
      <Autocomplete
        multi
        label={label}
        value={value || []}
        onChange={handleChange}
        options={options}
      />
    </div>
  );
}

export default memo(MultiAutocompleteFilter);
