export const forecastsTableColumns = [
  { header: 'M+0', accessor: { 1: 'm0', 2: 'm0Ongoing' } },
  { header: 'M+1', accessor: { 1: 'm1', 2: 'm1Ongoing' } },
  { header: 'M+2', accessor: { 1: 'm2', 2: 'm2Ongoing' } },
  { header: 'M+3', accessor: { 1: 'm3', 2: 'm3Ongoing' } },
  { header: 'M+4', accessor: { 1: 'm4', 2: 'm4Ongoing' } },
  { header: 'M+5', accessor: { 1: 'm5', 2: 'm5Ongoing' } },
  { header: 'M+6', accessor: { 1: 'm6', 2: 'm6Ongoing' } },
  { header: 'M+7', accessor: { 1: 'm7', 2: 'm7Ongoing' } },
  { header: 'M+8', accessor: { 1: 'm8', 2: 'm8Ongoing' } },
  { header: 'M+9', accessor: { 1: 'm9', 2: 'm9Ongoing' } },
  {
    header: '>M+9',
    accessor: {
      1: 'mOthers',
      2: 'mOthersOngoing',
    },
  },
];
