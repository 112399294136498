import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'utils/routes';
import { getCompanies, downloadCompanies } from 'redux/companies/actions';

import { tableColumns, formatCompanies } from './helper';

import Table from 'components/Table';
import CreateButton from 'components/Table/Actions/CreateButton';
import DownloadAllButton from 'components/Table/Actions/DownloadAllButton';
import Filter from 'components/Filter';
import FilterFields from './FilterFields';
import EditButton from 'components/Table/Actions/EditButton';

function CompanyTable() {
  const dispatch = useDispatch();
  const history = useHistory();
  const historyState = history.location.state ? history.location.state : {};

  const companyState = useSelector(state => state.companies);
  const companies = companyState.items;
  const companyCount = companyState.totalItemCount;
  const isLoading = companyState.loading;

  const handleRefresh = ({
    page,
    size,
    filter = null,
    orderBy = null,
    order = null,
    turnoverMin = null,
    turnoverMax = null,
    businessSectorId = null,
    secondaryBusinessSectorId = null,
    sizeId = null,
    planId = null,
    hasActiveOffers = null,
    hasOffers = null,
  }) => {
    dispatch(
      getCompanies({
        page: page + 1,
        size: size,
        search: filter,
        orderBy: orderBy,
        direction: order,
        turnoverMin: turnoverMin,
        turnoverMax: turnoverMax,
        businessSectorId: businessSectorId,
        secondaryBusinessSectorId: secondaryBusinessSectorId,
        sizeId: sizeId,
        planId: planId,
        hasActiveOffers: hasActiveOffers,
        hasOffers: hasOffers,
      })
    );
  };

  const handleDownloadAll = ({
    filter = null,
    orderBy = null,
    order = null,
    turnoverMin = null,
    turnoverMax = null,
    businessSectorId = null,
    secondaryBusinessSectorId = null,
    sizeId = null,
    planId = null,
    hasActiveOffers = null,
    hasOffers = null,
  }) => {
    dispatch(
      downloadCompanies({
        filter: filter,
        orderBy: orderBy,
        direction: order,
        turnoverMin: turnoverMin,
        turnoverMax: turnoverMax,
        businessSectorId: businessSectorId,
        secondaryBusinessSectorId: secondaryBusinessSectorId,
        sizeId: sizeId,
        planId: planId,
        hasActiveOffers: hasActiveOffers,
        hasOffers: hasOffers,
      })
    );
  };

  const generalActionComponents = [
    () => <CreateButton pathname={ROUTES.COMPANY_CREATE} />,
    () => (
      <DownloadAllButton
        action={handleDownloadAll}
        filter={historyState.filterFormated}
      />
    ),
  ];

  const actionComponents = [
    props => <EditButton {...props} pathname={ROUTES.COMPANY_EDIT} />,
  ];

  return (
    <Table
      rows={formatCompanies(companies)}
      columns={tableColumns}
      handleRefresh={handleRefresh}
      isSearchable
      isPaginatable
      isSortable
      generalActionComponents={generalActionComponents}
      actionComponents={actionComponents}
      totalItemCount={companyCount}
      filterComponent={
        <Filter>
          <FilterFields />
        </Filter>
      }
      noDataMsg="Aucune entreprise trouvée"
      isLoading={isLoading}
    />
  );
}

export default CompanyTable;
