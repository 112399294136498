import { useTranslation } from 'react-i18next';

import { AutocompleteAsync } from 'components/core/input';
import services from 'utils/api/Services/services';
import { useFetchCompanies } from 'utils/hooks/formOptions';
import { useEffect } from 'react';

export default function Company({ values, errors, touched, onChange }) {
  const { t } = useTranslation();
  const companyOptions = useFetchOptions();

  return (
    <AutocompleteAsync
      name="company"
      defaultValues={[values.company]}
      defaultOptions={companyOptions}
      lazy={true}
      service={fetchOptions}
      label={t('paf.field.company')}
      touched={touched.company}
      error={errors.company}
      onChange={onChange}
      creatable
    />
  );
}

const useFetchOptions = () => {
  const [fetch, options] = useFetchCompanies();

  useEffect(
    () => {
      fetch();
    },
    [fetch]
  );

  return options;
};

const fetchOptions = searchTerm =>
  services.options.getCompanies({
    search: searchTerm,
  });
