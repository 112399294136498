import { useMemo, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'utils/hooks/queryState';
import SendIcon from '@material-ui/icons/Send';
import ActionButton from 'components/ActionButton';
import { Autocomplete, Checkbox, RichTextEditor } from 'components/core/input';
import { sendShortListMail } from 'redux/offers/actions';
import { Grid } from 'components/core/containers';
import { getShortListMailFr } from './mail/shortListMail_Fr';
import { getShortListMailEn } from './mail/shortListMail_En';

function ShortListButton() {
  const qs = useQuery();
  const dispatch = useDispatch();
  const offer = useSelector(state => state.offers.current);
  const applications = useSelector(state => state.offers.applications);
  const selectedIds = qs.getAll('rows');

  const contactOptions = offer.emails;
  const [recipients, setRecipients] = useState(contactOptions);
  const [enLocale, setEnLocale] = useState(false);
  const [mailContent, setMailContent] = useState();
  const [attachCoverLetters, setAttachCoverLetters] = useState(false);

  const applicationsInSL = useMemo(
    () => {
      return (
        applications.filter(app => {
          return selectedIds && selectedIds.length
            ? selectedIds.includes(app.id.toString())
            : app.states.find(
                state => state.code === 'shortList' && state.isActive
              ) !== undefined;
        }) || []
      );
    },
    [selectedIds, applications]
  );

  const updateMailWithLocaleAndApplications = () =>
    setMailContent(
      enLocale
        ? getShortListMailEn(offer, applicationsInSL)
        : getShortListMailFr(offer, applicationsInSL)
    );

  const editorRef = useRef(0);
  useEffect(
    () => {
      updateMailWithLocaleAndApplications();
      editorRef.current = editorRef.current += 1;
    },
    [enLocale]
  );

  const handleRecipientsChange = ({ value }) => setRecipients(value);
  const handleLocaleChange = ({ value }) => setEnLocale(value);
  const handleMailChange = value => setMailContent(value);
  const handleCoverLetterChange = () =>
    setAttachCoverLetters(!attachCoverLetters);

  const handleSendShortListMail = () => {
    const params = {
      id: offer.id,
      mailContent: mailContent,
      recipients: recipients.map(recipient => recipient.label),
      applicationIds: applicationsInSL.map(application => application.id),
      attachCoverLetters: attachCoverLetters,
      locale: enLocale ? 'en' : 'fr',
    };
    dispatch(sendShortListMail(params));
  };

  return (
    <ActionButton
      onOpenDialog={updateMailWithLocaleAndApplications}
      action={handleSendShortListMail}
      label="Envoyer Short List"
      icon={<SendIcon />}
      disabled={applicationsInSL.length === 0}
      title="Envoi des mails de short list"
      dialogContent={
        <Grid container>
          <Grid md={12}>
            <Autocomplete
              label="Destinataires"
              options={contactOptions}
              value={recipients}
              onChange={handleRecipientsChange}
              multi
              creatable
            />
          </Grid>
          <Grid md={2}>
            <Checkbox
              label="Anglais"
              value={enLocale}
              onChange={handleLocaleChange}
            />
          </Grid>
          <Grid md={2}>
            <Checkbox
              label="LMs"
              value={attachCoverLetters}
              onChange={handleCoverLetterChange}
            />
          </Grid>
          <Grid md={12}>
            <RichTextEditor
              key={editorRef.current}
              htmlValue={mailContent}
              handleChange={handleMailChange}
            />
          </Grid>
        </Grid>
      }
      confirmText="Confirmer"
      dialog
    />
  );
}

export default ShortListButton;
