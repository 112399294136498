import styled from 'styled-components';
import { theme } from 'utils/theme';

export default function Title({ children, variant }) {
  switch (variant) {
    case 'h6':
      return <STitle size={6}>{children}</STitle>;
    default:
      return <STitle size={7}>{children}</STitle>;
  }
}

const STitle = styled.div`
  font-size: ${props => theme.typography.fontSize + props.size}px;
  font: ${theme.typography.fontFamily};
`;
