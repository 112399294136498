import { ACTIONS_TYPE } from './actions';

export const initialState = {
  loading: 0,
  totalItemCount: 0,
  items: [],
  applications: [],
  applicationComments: [],
  current: null,
  sample: null,
  suggestions: [],
  kpi: {
    activeCount: 0,
    standByCount: 0,
    pendingCount: 0,
    campusCount: 0,
    campusTurnover: 0,
    seniorCount: 0,
    seniorTurnover: 0,
    inProgressApplicationCount: 0,
  },
};

export function offersReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPE.CREATE_OFFER_BEGIN:
    case ACTIONS_TYPE.GET_OFFERS_BEGIN:
    case ACTIONS_TYPE.GET_STAND_BY_OFFERS_BEGIN:
    case ACTIONS_TYPE.GET_OFFER_BEGIN:
    case ACTIONS_TYPE.GET_SAMPLE_BEGIN:
    case ACTIONS_TYPE.UPDATE_OFFER_BEGIN:
    case ACTIONS_TYPE.UPDATE_OFFER_STATUS_BEGIN:
    case ACTIONS_TYPE.GET_APPLICATIONS_BEGIN:
    case ACTIONS_TYPE.SWITCH_OFFER_APPLICATION_BEGIN:
    case ACTIONS_TYPE.GENERATE_OFFER_INVOICE_BEGIN:
    case ACTIONS_TYPE.SEND_SL_BEGIN:
    case ACTIONS_TYPE.DOWNLOAD_REPORT_BEGIN:
    case ACTIONS_TYPE.UPDATE_APPLICATIONS_STATUS_BEGIN:
    case ACTIONS_TYPE.GET_SUGGESTIONS_BEGIN:
    case ACTIONS_TYPE.ADD_OFFER_APPLICATION_BEGIN:
    case ACTIONS_TYPE.NOTIFY_OFFER_CONTACT_ATTEMPT_BEGIN:
    case ACTIONS_TYPE.SUGGEST_OFFER_BEGIN:
    case ACTIONS_TYPE.ADD_APPLICATION_COMMENT_BEGIN:
    case ACTIONS_TYPE.GET_APPLICATION_COMMENTS_BEGIN:
    case ACTIONS_TYPE.UPDATE_APPLICATION_COMMENT_BEGIN:
    case ACTIONS_TYPE.DELETE_APPLICATION_COMMENT_BEGIN:
    case ACTIONS_TYPE.GET_OFFERS_OVERVIEW_STATS_BEGIN:
    case ACTIONS_TYPE.GET_OFFER_STATS_BEGIN:
    case ACTIONS_TYPE.INTERACT_WITH_CUSTOMER_BEGIN:
    case ACTIONS_TYPE.APPOINTMENT_REQUEST_BEGIN:
    case ACTIONS_TYPE.SWITCH_OFFER_HEADLINE_BEGIN:
      return {
        ...state,
        loading: state.loading + 1,
      };
    case ACTIONS_TYPE.CREATE_OFFER_FAILURE:
    case ACTIONS_TYPE.CREATE_OFFER_SUCCESS:
    case ACTIONS_TYPE.GET_OFFERS_FAILURE:
    case ACTIONS_TYPE.GET_STAND_BY_OFFERS_FAILURE:
    case ACTIONS_TYPE.UPDATE_OFFER_FAILURE:
    case ACTIONS_TYPE.GET_OFFER_FAILURE:
    case ACTIONS_TYPE.GET_SAMPLE_FAILURE:
    case ACTIONS_TYPE.UPDATE_OFFER_STATUS_FAILURE:
    case ACTIONS_TYPE.UPDATE_OFFER_STATUS_SUCCESS:
    case ACTIONS_TYPE.GET_APPLICATIONS_FAILURE:
    case ACTIONS_TYPE.GENERATE_OFFER_INVOICE_SUCCESS:
    case ACTIONS_TYPE.GENERATE_OFFER_INVOICE_FAILURE:
    case ACTIONS_TYPE.SEND_SL_FAILURE:
    case ACTIONS_TYPE.SEND_SL_SUCCESS:
    case ACTIONS_TYPE.DOWNLOAD_REPORT_SUCCESS:
    case ACTIONS_TYPE.DOWNLOAD_REPORT_FAILURE:
    case ACTIONS_TYPE.SWITCH_OFFER_APPLICATION_SUCCESS:
    case ACTIONS_TYPE.SWITCH_OFFER_APPLICATION_FAILURE:
    case ACTIONS_TYPE.GET_SUGGESTIONS_FAILURE:
    case ACTIONS_TYPE.ADD_OFFER_APPLICATION_SUCCESS:
    case ACTIONS_TYPE.ADD_OFFER_APPLICATION_FAILURE:
    case ACTIONS_TYPE.NOTIFY_OFFER_CONTACT_ATTEMPT_SUCCESS:
    case ACTIONS_TYPE.NOTIFY_OFFER_CONTACT_ATTEMPT_FAILURE:
    case ACTIONS_TYPE.SUGGEST_OFFER_SUCCESS:
    case ACTIONS_TYPE.SUGGEST_OFFER_FAILURE:
    case ACTIONS_TYPE.ADD_APPLICATION_COMMENT_SUCCESS:
    case ACTIONS_TYPE.ADD_APPLICATION_COMMENT_FAILURE:
    case ACTIONS_TYPE.GET_APPLICATION_COMMENTS_FAILURE:
    case ACTIONS_TYPE.UPDATE_APPLICATION_COMMENT_SUCCESS:
    case ACTIONS_TYPE.UPDATE_APPLICATION_COMMENT_FAILURE:
    case ACTIONS_TYPE.DELETE_APPLICATION_COMMENT_SUCCESS:
    case ACTIONS_TYPE.DELETE_APPLICATION_COMMENT_FAILURE:
    case ACTIONS_TYPE.GET_OFFERS_OVERVIEW_STATS_FAILURE:
    case ACTIONS_TYPE.UPDATE_OFFER_SUCCESS:
    case ACTIONS_TYPE.GET_OFFER_STATS_FAILURE:
    case ACTIONS_TYPE.INTERACT_WITH_CUSTOMER_SUCCESS:
    case ACTIONS_TYPE.INTERACT_WITH_CUSTOMER_FAILURE:
    case ACTIONS_TYPE.APPOINTMENT_REQUEST_SUCCESS:
    case ACTIONS_TYPE.APPOINTMENT_REQUEST_FAILURE:
    case ACTIONS_TYPE.SWITCH_OFFER_HEADLINE_FAILURE:
      return {
        ...state,
        loading: state.loading - 1,
      };

    case ACTIONS_TYPE.SWITCH_OFFER_HEADLINE_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        items: state.items.map(
          item =>
            item.id === action.result.id
              ? { ...item, headline: !item.headline }
              : item
        ),
      };
    case ACTIONS_TYPE.GET_OFFER_SUCCESS: {
      return {
        ...state,
        loading: state.loading - 1,
        current: action.result,
      };
    }

    case ACTIONS_TYPE.GET_SAMPLE_SUCCESS: {
      return {
        ...state,
        loading: state.loading - 1,
        sample: action.result,
      };
    }

    case ACTIONS_TYPE.CLEAN_CURRENT_OFFER: {
      return {
        ...state,
        current: null,
      };
    }
    case ACTIONS_TYPE.CLEAN_OFFERS: {
      return {
        ...state,
        items: initialState.items,
      };
    }

    case ACTIONS_TYPE.GET_OFFERS_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        items: action.result.offers,
        totalItemCount: action.result.totalItemCount,
        //kpi: { ...state.kpi, ...action.result.kpi },
      };
    case ACTIONS_TYPE.GET_OFFER_STATS_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        items: state.items.map(item => {
          if (item.id === action.result.offerId) {
            return { ...item, kpi: { ...item.kpi, ...action.result.stats } };
          } else return { ...item };
        }),
      };
    case ACTIONS_TYPE.GET_STAND_BY_OFFERS_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        items: action.result.offers,
        totalItemCount: action.result.totalItemCount,
      };

    case ACTIONS_TYPE.GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        applications: action.result,
        items: state.items
          ? state.items.map(item => {
              if (action.result.offerId === item.id) {
                if (!item.applications) {
                  item.applications = {};
                }
                item.applications[action.result.state] =
                  action.result.applications;
              }
              return item;
            })
          : null,
      };
    case ACTIONS_TYPE.CLEAN_OFFER_APPLICATIONS:
      return {
        ...state,
        applications: [],
      };
    case ACTIONS_TYPE.GET_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        suggestions: [
          ...action.result.preSuggestions.map(preSuggestion => {
            return { ...preSuggestion, preSuggestion: true };
          }),
          ...action.result.suggestions,
        ],
      };
    case ACTIONS_TYPE.CLEAN_OFFER_SUGGESTIONS:
      return {
        ...state,
        suggestions: [],
      };
    case ACTIONS_TYPE.GET_APPLICATION_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        applicationComments: action.result,
      };
    case ACTIONS_TYPE.GET_OFFERS_OVERVIEW_STATS_SUCCES:
      return {
        ...state,
        loading: state.loading - 1,
        kpi: {
          ...action.result,
        },
      };
    default:
      return state;
  }
}
