import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAmountOptions,
  getHiredCandidateOptions,
} from 'redux/formOptions/actions';
import { generateOfferInvoice } from 'redux/offers/actions';
import ActionButton from 'components/ActionButton';
import { Autocomplete, Checkbox } from 'components/core/input';
import { Grid } from 'components/core/containers';
import { EuroSymbol as EuroSymbolIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';
import S from './styles';

function InvoiceButton(props) {
  const { offer, classes } = props;

  const dispatch = useDispatch();
  useEffect(
    () => {
      dispatch(getAmountOptions({ id: offer.id }));
      dispatch(getHiredCandidateOptions({ id: offer.id }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [amount, setAmount] = useState(null);
  const handleChangeAmount = value => {
    setAmount(value);
  };
  const [candidate, setCandidate] = useState(null);
  const handleChangeCandidate = value => {
    setCandidate(value);
  };
  const [isSecondVariable, setSecondVariable] = useState(false);
  const handleChangeSecondVariable = () => setSecondVariable(!isSecondVariable);

  const options = useSelector(state => state.formOptions);
  const amountOptions = options ? options.amountOptions : null;
  const candidatesOptions = options ? options.hiredCandidateOptions : null;

  const onSubmit = () => {
    const data = {
      offerId: offer.id,
      amountId: amount.value,
      candidateId: candidate ? candidate.value : null,
      isSecondVariable,
    };
    dispatch(generateOfferInvoice(data));
  };

  const disableSubmit = !amount || (amount.type !== 'fixe' && !candidate);

  const dialogContent = (
    <Grid container>
      <Grid md={6}>
        <Autocomplete
          id="amount"
          className={classes.field}
          options={amountOptions}
          label="Montant"
          value={amount}
          onChange={({ value }) => {
            handleChangeAmount(value);
          }}
        />
      </Grid>
      <Grid md={6}>
        <Checkbox
          label="Second Variable"
          value={isSecondVariable}
          onChange={handleChangeSecondVariable}
        />
      </Grid>
      <Grid md={6}>
        <Autocomplete
          id="candidate"
          className={classes.field}
          options={candidatesOptions}
          label="Candidat"
          value={candidate}
          onChange={({ value }) => {
            handleChangeCandidate(value);
          }}
        />
      </Grid>
    </Grid>
  );
  return (
    <ActionButton
      label="Facturer"
      dialog
      title={"Facturation de l'offre " + offer.label}
      icon={<EuroSymbolIcon />}
      dialogContent={dialogContent}
      action={onSubmit}
      disableSubmit={disableSubmit}
    />
  );
}

export default withStyles(S)(InvoiceButton);
