import PropTypes from 'prop-types';
import { Clear, Done, EditRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import ActionButton from 'components/ActionButton';

function Buttons({ onEdit, onCancel, onConfirm, onRemove, editMode }) {
  const classes = useStyles();

  const editButtons = (
    <>
      <ActionButton
        key="confirmButton"
        label="Valider"
        icon={<Done className={classes.icon} />}
        action={onConfirm}
        tooltipPosition="left"
      />
      <ActionButton
        key="cancelButton"
        label="Annuler"
        icon={<Clear className={classes.icon} />}
        action={onCancel}
        tooltipPosition="left"
      />
    </>
  );

  const createButtons = (
    <>
      <ActionButton
        key="editButton"
        label="Modifier"
        icon={<EditRounded className={classes.icon} />}
        action={onEdit}
        tooltipPosition="left"
      />
      <ActionButton
        key="removeButton"
        label="Supprimer"
        icon={<Clear className={classes.icon} />}
        action={onRemove}
        dialog
        title="Confirmation de suppression du commentaire"
        dialogContent="Souhaites-tu vraiment supprimer ce commentaire ?"
        confirmText="Supprimer"
        tooltipPosition="left"
      />
    </>
  );

  return (
    <div className={classes.actions}>
      {editMode ? editButtons : createButtons}
    </div>
  );
}

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    padding: 1,
    marginLeft: 2,
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

Buttons.propTypes = {
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onRemove: PropTypes.func,
  editMode: PropTypes.bool,
};

Buttons.defaultProps = {
  onEdit: () => {},
  onCancel: () => {},
  onConfirm: () => {},
  onRemove: () => {},
  editMode: false,
};

export default Buttons;
