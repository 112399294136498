import styled from 'styled-components';
import { theme } from 'utils/theme';

const Tab = styled.button`
  background-color: transparent;
  text-transform: uppercase;
  min-width: 160px;
  @media only screen and (max-width: 600px) {
    min-width: 0px;
  }
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-size: 11px;
  border: 0px;
  border-bottom: 2px solid
    ${props => (props.active ? theme.palette.primary.variant : 'transparent')};
`;

export default Tab;
