import { Grid } from 'components/core/containers';
import BaseButton from './baseButton';
import { useFetchAvailablePlacement } from 'utils/hooks/placement';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { recruitmentPlanId } from 'utils/constants';
import {
  CandidateStartDate,
  ContractDuration,
  ContractType,
  FollowUps,
  IsFinalInternship,
  SecondVariablePotentialDate,
  SecondVariablePrice,
  VariablePrice,
  computeFollowUps,
} from 'components/eiquem/placement/paf/Form/fields';
import moment from 'moment-timezone';

export default function HireButton(props) {
  const { value, offerId, planId } = props;

  const {
    prefiledPlacement,
    formatedPrefiledPlacement,
    errors,
    touched,
    handleChange,
  } = usePrefilledPlacementForm({
    offerId,
  });

  const handleAction = () => {
    props.action(formatedPrefiledPlacement);
  };

  return (
    <>
      <BaseButton
        title="Confirmation de Placement"
        label="Pris"
        message={
          <PrefiledPlacementForm
            values={prefiledPlacement}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            planId={planId}
          />
        }
        value={value}
        color="primary"
        action={handleAction}
        disabled={props.disabled}
      />
    </>
  );
}

const msg = `Souhaites-tu placer cette candidature en PRIS ?
Un mail sera envoyé au candidat et le statut de sa candidature sera mis à jour.
Ses autres candidatures seront abandonnées et sa démarche sera cloturée.`;

function PrefiledPlacementForm({ values, errors, touched, handleChange }) {
  return (
    <>
      {values && (
        <Grid container>
          <Grid item md={6}>
            <ContractType
              values={values}
              errors={errors}
              touched={touched}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6}>
            <ContractDuration
              values={values}
              errors={errors}
              touched={touched}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6}>
            <VariablePrice
              name="firstVariableFee"
              values={values}
              errors={errors}
              touched={touched}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6}>
            <SecondVariablePrice
              name="secondVariableFee"
              values={values}
              errors={errors}
              touched={touched}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6}>
            <CandidateStartDate
              values={values}
              errors={errors}
              touched={touched}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6}>
            <IsFinalInternship
              name="finalYear"
              values={values}
              errors={errors}
              touched={touched}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6}>
            <SecondVariablePotentialDate
              name="potentialSecondVariableFeeDate"
              values={values}
              errors={errors}
              touched={touched}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={12}>
            {values.followUps && (
              <FollowUps
                values={values}
                onChange={handleChange}
                disableRefresh
              />
            )}
          </Grid>
          <Grid item md={12}>
            {msg}
          </Grid>
        </Grid>
      )}
    </>
  );
}

function usePrefilledPlacementForm({ offerId, planId }) {
  const { placement } = useFetchAvailablePlacement({ offerId });

  const handleChange = ({ value, name }) => {
    formik.setFieldValue(name, value, true);
    formik.setFieldTouched(name, true, false);

    if (name === 'contractDuration' || name === 'candidateStartDate') {
      const contractDuration =
        name === 'contractDuration' ? value : formik.values.contractDuration;
      const candidateStartDate =
        name === 'candidateStartDate'
          ? value
          : formik.values.candidateStartDate;

      let followUps = [];
      if (contractDuration && candidateStartDate) {
        followUps = computeFollowUps(
          new Date(candidateStartDate),
          contractDuration,
          { value: recruitmentPlanId }
        );

        formik.setFieldValue(
          'potentialSecondVariableFeeDate',
          moment(candidateStartDate).add(contractDuration, 'M'),
          true
        );
      }

      formik.setFieldValue('followUps', followUps, true);
    }
  };

  const formik = useFormik({
    initialValues: {
      contractType: null,
      contractDuration: null,
      firstVariableFee: null,
      secondVariableFee: null,
      candidateStartDate: null,
      finalYear: null,
      potentialSecondVariableFeeDate: null,
      secondVariable: true,
    },
    onSubmit: () => {},
  });

  const formIntialized = useRef(false);
  useEffect(
    () => {
      if (placement && !formIntialized.current) {
        formik.setValues({
          ...formik.values,
          ...placement,
          followUps:
            planId === recruitmentPlanId
              ? computeFollowUps(
                  new Date(placement.candidateStartDate),
                  placement.contractDuration,
                  { value: recruitmentPlanId }
                )
              : [],
        });

        formIntialized.current = true;
      }
    },
    [placement, planId, formik]
  );

  const formatedPrefiledPlacement = {
    ...formik.values,
    contractTypeId: formik.values.contractType?.value,
    duration: formik.values.contractDuration,
    firstVariableFee: formik.values.firstVariableFee,
    secondVariableFee: formik.values.secondVariableFee,
    candidateStartDate: formik.values.candidateStartDate,
    finalYear: formik.values.finalYear,
    potentialSecondVariableDate: formik.values.potentialSecondVariableFeeDate,
    followUps: formik.values.followUps,
  };

  return {
    prefiledPlacement: formik.values,
    touched: formik.touched,
    errors: formik.errors,
    handleChange,
    formatedPrefiledPlacement: formatedPrefiledPlacement,
    isSubmitting: formik.isSubmitting,
    isLoading: useSelector(state => state.pafs.loading),
  };
}
