import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Main from 'components/layout/Main';
import PafTable from 'components/eiquem/placement/paf/Table';
import PageHeader from 'components/layout/PageHeader';
import PafStatistics from 'components/eiquem/placement/paf/Statistics';

function PafListPage() {
  const { t } = useTranslation();
  const isLoading = useSelector(state => state.pafs.loading);

  return (
    <Main pageTitle={t('menu.recruitmentTitle')} isLoading={isLoading}>
      <PageHeader title={t('paf.data-title')}>
        <PafStatistics />
      </PageHeader>
      <PafTable />
    </Main>
  );
}

export default PafListPage;
