import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOneCommercialProposal,
  cleanCurrentProposal,
  updateCommercialProposal,
  createCommercialProposal,
} from 'redux/commercialProposals/actions';

const useIsLoading = () =>
  useSelector(state => state.commercialProposals.loading);

export const useFetchProposal = id => {
  const dispatch = useDispatch();

  useEffect(
    () => {
      if (id) {
        dispatch(getOneCommercialProposal(id));
      }

      return () => dispatch(cleanCurrentProposal());
    },
    [dispatch, id]
  );

  return [
    useSelector(state => state.commercialProposals.current),
    useIsLoading(),
  ];
};

export const useUpdateProposal = id => {
  const dispatch = useDispatch();

  const update = data => {
    console.log('call update commercial proposal with data: ', data);
    dispatch(updateCommercialProposal(id, data));
  };

  return [update, useIsLoading()];
};

export const useCreateProposal = () => {
  const dispatch = useDispatch();

  const create = data => {
    console.log('call create commercial proposal with data: ', data);
    dispatch(createCommercialProposal(data));
  };

  return [create, useIsLoading()];
};
