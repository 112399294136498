import PropTypes from 'prop-types';
import styled from 'styled-components';
import Accordion from 'components/core/Accordion';
import Label from 'components/layout/PageHeader/Label';

PageHeader.propTypes = {
  title: PropTypes.any,
  subtitles: PropTypes.element,
  bottomItems: PropTypes.element,
  children: PropTypes.element,
  lazy: PropTypes.bool,
};
PageHeader.defaultProps = {
  label: null,
  children: null,
};

export default function PageHeader({
  title,
  subtitles,
  bottomItems,
  children,
  lazy,
  defaultOpen,
}) {
  return (
    <Container>
      <Accordion
        label={
          <Label
            title={title}
            subtitles={subtitles}
            bottomItems={bottomItems}
          />
        }
        lazy={lazy}
        defaultOpen={defaultOpen}
      >
        {children}
      </Accordion>
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 20px;
`;
