import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCommercialProposal } from 'redux/commercialProposals/actions';
import { handleValues } from 'components/eiquem/commercialProposal/Form/helper';

import { Grid } from 'components/core/containers';
import { Autocomplete, TextField } from 'components/core/input';
import SubmitButtons from 'components/core/SubmitButtons';
import { getCommercialProposalStatusOptions } from 'redux/formOptions/actions';

function RowDetail({ row }) {
  const dispatch = useDispatch();
  const statusOptions = useSelector(
    state => state.formOptions.commercialProposalStatusOptions
  );
  const [status, setStatus] = useState(row.status);
  const [comment, setComment] = useState(row.comment);

  useEffect(
    () => {
      dispatch(getCommercialProposalStatusOptions());
    },
    [dispatch]
  );

  const handleChange = ({ name, value }) => {
    switch (name) {
      case 'status':
        setStatus(value);
        break;
      case 'comment':
        setComment(value);
        break;
      default:
        throw new Error(`invalid action name: ${name}`);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    const data = handleValues({ ...row, comment: comment, status: status });
    dispatch(updateCommercialProposal(row.id, data));
  };

  return (
    <form onSubmit={handleSubmit} style={{ padding: 20 }}>
      <Grid container>
        <Grid md={8} sm={10}>
          <Grid container>
            <Grid md={4} sm={6}>
              <Autocomplete
                name="status"
                value={status}
                options={statusOptions}
                label="Statut"
                onChange={({ value }) =>
                  handleChange({ name: 'status', value: value })
                }
              />
            </Grid>
            <Grid md={8} sm={12}>
              <TextField
                name="comment"
                label="Commentaire"
                handleChange={({ value }) =>
                  handleChange({ name: 'comment', value: value })
                }
                value={comment}
                rowMax={10}
                multiline
              />
            </Grid>
          </Grid>
          <SubmitButtons />
        </Grid>
      </Grid>
    </form>
  );
}

export default RowDetail;
