import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import history from 'utils/history';

import { ROUTES } from 'utils/routes';

import { updateOfferStatus } from 'redux/offers/actions';

import { Grid, withStyles } from '@material-ui/core';
import ActionButton from 'components/ActionButton';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import Autocomplete from 'components/core/input/Autocomplete';
import S from './styles';

import { reasonOptions, statusOptions } from './helper';

StatusButton.defaultProps = {
  redirectAfter: false,
};

function StatusButton(props) {
  const { row, redirectAfter, classes } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    status: null,
    reason: null,
  });

  const handleChange = (field, value) => {
    const newValues = { ...values };
    newValues[field] = value;
    setValues(newValues);
  };

  const handleOnConfirm = () => {
    const params = {
      id: row.id,
      status: values.status.value,
    };
    if (values.reason && values.reason.value) {
      params.reason = values.reason.value;
    }
    console.log('call update offer status with params: ', params);
    dispatch(updateOfferStatus(params));

    if (redirectAfter) {
      history.push(ROUTES.OFFER_LIST);
    }
  };

  const dialogContent = (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <Autocomplete
          options={statusOptions}
          value={values.status}
          label={t('offer.dialog.status.statusLabel')}
          onChange={({ value }) => {
            handleChange('status', value);
          }}
        />
      </Grid>

      {values.status &&
        values.status.value === 'terminee' && (
          <Grid item sm={12}>
            <Autocomplete
              value={values.reason}
              options={reasonOptions}
              label={t('offer.dialog.status.reasonLabel')}
              onChange={({ value }) => {
                handleChange('reason', value);
              }}
            />
          </Grid>
        )}
    </Grid>
  );

  const statusCode =
    row.status.code === 'inProgress' && row.standBy
      ? 'standBy'
      : row.status.code;

  return (
    <ActionButton
      icon={<FiberManualRecordIcon className={classes[statusCode]} />}
      dialog
      action={handleOnConfirm}
      label={`${t('offer.status')} : ${
        row.standBy ? 'Stand-by' : row.status.label.fr || row.status.label
      }`}
      title={t('offer.dialog.status.title')}
      contentText={t('offer.dialog.status.text')}
      dialogContent={dialogContent}
      disableSubmit={
        !values.status || (values.status.value === 'terminee' && !values.reason)
      }
    />
  );
}

export default withStyles(S)(StatusButton);
