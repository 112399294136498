import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const Form = props => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldTouched,
  } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const isProcessing = useSelector(state => state.auth.isProcessing);

  const classes = useStyles();
  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <TextField
        id="login"
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Login"
        name="login"
        autoComplete="login"
        autoFocus
        helperText={touched.login ? errors.login : ''}
        error={touched.login && Boolean(errors.login)}
        onChange={change.bind(null, 'login')}
        value={values.login}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        helperText={touched.password ? errors.password : ''}
        error={touched.password && Boolean(errors.password)}
        onChange={change.bind(null, 'password')}
        value={values.password}
      />
      <Box component="div">
        <Box component="div" className={classes.actionFields}>
          <Box className={classes.rightFields}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isProcessing}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
};
