const candidates = {
  add: ({
    firstName,
    lastName,
    email,
    phone,
    provenance,
    schoolId,
    otherSchool,
    studyLevelId,
    resume,
    contractTypeId,
    startDate,
    duration,
    functionIds,
    finalYear,
    gapYear,
  }) => ({
    url: '/candidates',
    method: 'post',
    data: {
      firstName,
      lastName,
      email,
      phone,
      provenance,
      schoolId,
      otherSchool,
      studyLevelId,
      resume,
      contractTypeId,
      startDate,
      duration,
      functionIds,
      finalYear,
      gapYear,
    },
  }),
  update: ({
    id,
    firstName,
    lastName,
    schoolId,
    otherSchool,
    studyLevelId,
    rqth,
    resume,
    provenance,
    phoneNumber,
  }) => ({
    url: `/candidates/${id}`,
    method: 'put',
    data: {
      firstName,
      lastName,
      schoolId,
      otherSchool,
      studyLevelId,
      rqth,
      resume,
      provenance,
      phoneNumber,
    },
  }),
  updateProfile: (candidateId, newProfile) => ({
    url: `/candidates/${candidateId}/profile${
      newProfile ? '/' + newProfile : ''
    }`,
    method: 'put',
  }),
  get: ({
    page,
    size,
    search,
    contractTypeIds,
    minStartDate,
    maxStartDate,
    minDuration,
    maxDuration,
    studyLevelIds,
    isRqth,
    schoolTypes,
    isCommented,
    isFinalYear,
    isGapYear,
    isActiveResearch,
    isNoResearch,
    schoolIds,
    profiles,
    functionIds,
    isNoApplication,
    minRegistrationDate,
    maxRegistrationDate,
  }) => ({
    url: '/candidates',
    method: 'get',
    params: {
      page,
      size,
      search,
      contractTypeIds,
      minStartDate,
      maxStartDate,
      minDuration,
      maxDuration,
      studyLevelIds,
      isRqth,
      schoolTypes,
      isCommented,
      isFinalYear,
      isGapYear,
      isActiveResearch,
      isNoResearch,
      schoolIds,
      profiles,
      functionIds,
      isNoApplication,
      minRegistrationDate,
      maxRegistrationDate,
    },
  }),
  getOne: ({ id }) => ({
    url: `/candidates/${id}`,
    method: 'get',
  }),
  getResearches: ({ id }) => ({
    url: `/candidates/${id}/researches`,
    method: 'get',
  }),
  updateResearch: ({
    id,
    status,
    profile,
    gapYear,
    finalYear,
    startDate,
    duration,
    contractType,
    rhythm,
    cost,
    customerComment,
    candidateComment,
    elocution,
    communication,
    motivation,
    dynamism,
  }) => ({
    url: `/researches/${id}`,
    method: 'put',
    data: {
      id: id,
      status: status,
      profile: profile,
      gapYear: gapYear,
      finalYear: finalYear,
      startDate: startDate,
      duration: duration,
      contractType: contractType,
      rhythm: rhythm,
      cost: cost,
      customerComment: customerComment,
      candidateComment: candidateComment,
      elocution: elocution,
      communication: communication,
      motivation: motivation,
      dynamism: dynamism,
    },
  }),
  updateResearchComments: ({ researchId, commentId, adminId, message }) => ({
    url: `/researches/${researchId}/comments`,
    method: 'put',
    data: {
      researchId: researchId,
      id: commentId,
      adminId: adminId,
      message: message,
    },
  }),
  deleteResearchComment: ({ researchId, commentId }) => ({
    url: `/researches/${researchId}/comments/${commentId}`,
    method: 'delete',
  }),
  addApplication: (researchId, offerId, stateCode = null) => ({
    url: `/applications`,
    method: 'post',
    data: {
      researchId: researchId,
      offerId: offerId,
      stateCode: stateCode,
    },
  }),
  notifyContactAttempt: ({ candidateId = null }) => ({
    url: `/candidates/${candidateId}/notify-contact-attempt`,
    method: 'patch',
  }),
  activeResearchConfirmation: candidateId => ({
    url: `/candidates/${candidateId}/active-research-confirmation`,
    method: 'patch',
  }),
  anonymize: candidateId => ({
    url: `/candidates/${candidateId}/anonymize`,
    method: 'patch',
  }),
  getApplications: researchId => ({
    url: `/researches/${researchId}/applications-and-suggestions`,
    method: 'get',
  }),
  suggestOffer: (researchId, offerId) => ({
    url: `/researches/${researchId}/suggestions`,
    method: 'post',
    data: {
      researchId: researchId,
      offerId: offerId,
    },
  }),
  placementContactAttempt: (candidateId, period) => ({
    url: `/candidates/${candidateId}/notify-placement-contact-attempt/${period}`,
    method: 'patch',
  }),
  getPlacementFollowUp: ({ candidateId }) => ({
    url: `/candidates/${candidateId}/placement-follow-up`,
    method: 'get',
  }),
};

export default candidates;
