import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import services from 'utils/api/Services/services';

import { useDispatch, useSelector } from 'react-redux';
import {
  getLetudiantAdvert,
  postLetudiantAdvert,
} from 'redux/jobBoardsPosts/actions';
import {
  getLetudiantDomains,
  getLetudiantLevels,
  getLetudiantDurations,
  getLetudiantContracts,
  getLetudiantOfferTypes,
  getLetudiantExperiences,
  getLetudiantFunctions,
  getOfferOptions,
} from 'redux/formOptions/actions';
import { LetudiantFormSchema } from 'utils/formSchemas';
import Main from 'components/layout/Main';
import Form from '../Letudiant/Form/index';
import { Paper, withStyles, Typography, Box } from '@material-ui/core';

import Autocomplete from 'components/core/input/Autocomplete';
import AutocompleteAsync from 'components/core/input/AutocompleteAsync';
import S from './styles';
import { Subtitle } from 'pages/JobBoardsPosting/Letudiant/Form/subtitle';

function JobBoardsPosting(props) {
  const { classes } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [offer, setOffer] = useState(null);

  useEffect(
    () => {
      fetchOptions(dispatch);
    },
    [dispatch]
  );

  useEffect(
    () => {
      if (offer) {
        dispatch(getLetudiantAdvert({ offerId: offer.value }));
      }
    },
    [offer, dispatch]
  );

  const offerOptions = useSelector(state => state.formOptions).offerOptions;
  const jobBoardsState = useSelector(state => state.jobBoardsPosts);

  // Find advert from jobBoards
  const advert = jobBoardsState.adverts.find(
    advert => advert.offerId === (offer ? offer.value : null)
  );
  const letudiantAdvert = advert ? advert.letudiant : null;

  const handleOnSubmit = values => {
    dispatch(postLetudiantAdvert({ offerId: offer.value, data: values }));
  };

  const fetchOffersService = companyId => {
    dispatch(getOfferOptions({ company: companyId }));
  };

  return (
    <Main
      pageTitle={t('jobboards-posting.letudiantTitle')}
      isLoading={jobBoardsState.loading}
    >
      <Typography variant="h6" color="primary" gutterBottom>
        {t('jobboards-posting.postingTitle')}
      </Typography>
      <Paper className={classes.topRoot}>
        <Box className={classes.column}>
          <Subtitle label="Offre à Diffuser" />
          <Box className={classes.row}>
            <AutocompleteAsync
              service={fetchCompaniesService}
              className={classes.selectField}
              label={t('jobboards-posting.field.company')}
              onChange={({ value }) => {
                const companyId = value ? value.value : null;
                setOffer(null);
                fetchOffersService(companyId);
              }}
            />
          </Box>
          <Box className={classes.row}>
            <Autocomplete
              id="offer"
              defaultValue={offer}
              className={classes.selectField}
              options={offerOptions}
              label={t('jobboards-posting.field.position')}
              onChange={({ value }) => {
                setOffer(value);
              }}
            />
          </Box>
        </Box>
      </Paper>
      <Paper className={classes.root}>
        {letudiantAdvert && (
          <Formik
            initialValues={letudiantAdvert}
            validationSchema={LetudiantFormSchema}
            onSubmit={handleOnSubmit}
          >
            {props => (
              <Form
                {...props}
                {...{
                  getFormOptions: GetFormOptionsState,
                }}
              />
            )}
          </Formik>
        )}
      </Paper>
    </Main>
  );
}

export default withStyles(S)(JobBoardsPosting);

const fetchCompaniesService = inputValue =>
  services.options.getCompanies({
    search: inputValue,
  });

const fetchOptions = dispatch => {
  dispatch(getLetudiantOfferTypes());
  dispatch(getLetudiantDomains({ offerTypeId: 0 }));
  dispatch(getLetudiantDomains({ offerTypeId: 1 }));
  dispatch(getLetudiantDomains({ offerTypeId: 2 }));
  dispatch(getLetudiantDomains({ offerTypeId: 8 }));
  dispatch(getLetudiantLevels());
  dispatch(getLetudiantFunctions());
  dispatch(getLetudiantDurations({ offerTypeId: 1 }));
  dispatch(getLetudiantDurations({ offerTypeId: 2 }));
  dispatch(getLetudiantExperiences());
  dispatch(getLetudiantContracts({ offerTypeId: 0 }));
  dispatch(getLetudiantContracts({ offerTypeId: 8 }));
};

const GetFormOptionsState = () => {
  const state = useSelector(state => state.formOptions).letudiant;

  const domainOptions = state ? state.domainOptions : null;
  const levelOptions = state ? state.levelOptions : null;
  const offerTypeOptions = state ? state.offerTypeOptions : null;
  const durationOptions = state ? state.durationOptions : null;
  const contractOptions = state ? state.contractOptions : null;
  const experienceOptions = state ? state.experienceOptions : null;
  const functionOptions = state ? state.functionOptions : null;

  return {
    domainOptions: domainOptions,
    levelOptions: levelOptions,
    offerTypeOptions: offerTypeOptions,
    durationOptions: durationOptions,
    contractOptions: contractOptions,
    experienceOptions: experienceOptions,
    functionOptions: functionOptions,
  };
};
