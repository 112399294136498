import type { Dispatch, ThunkAction, GetState } from 'utils/types';
import type { GetProvenancesStatisticsAPIType } from 'utils/api/types';

import { fetch } from 'utils/api';
import services from 'utils/api/Services/services';
import { actionRequest, actionSuccess, actionFailure } from 'utils/functions';
import { companiesArrayValidator } from 'utils/schemas';

export const ACTIONS_TYPE = Object.freeze({
  GET_REGISTRATIONS_PROVENANCES_BEGIN: 'GET_REGISTRATIONS_PROVENANCES_BEGIN',
  GET_REGISTRATIONS_PROVENANCES_SUCCESS:
    'GET_REGISTRATIONS_PROVENANCES_SUCCESS',
  GET_REGISTRATIONS_PROVENANCES_FAILURE:
    'GET_REGISTRATIONS_PROVENANCES_FAILURE',

  GET_HIRES_PROVENANCES_BEGIN: 'GET_HIRES_PROVENANCES_BEGIN',
  GET_HIRES_PROVENANCES_SUCCESS: 'GET_HIRES_PROVENANCES_SUCCESS',
  GET_HIRES_PROVENANCES_FAILURE: 'GET_HIRES_PROVENANCES_FAILURE',

  GET_SL_PROVENANCES_BEGIN: 'GET_SL_PROVENANCES_BEGIN',
  GET_SL_PROVENANCES_SUCCESS: 'GET_SL_PROVENANCES_SUCCESS',
  GET_SL_PROVENANCES_FAILURE: 'GET_SL_PROVENANCES_FAILURE',

  GET_INVOICES_TURNOVER_BEGIN: 'GET_INVOICES_TURNOVER_BEGIN',
  GET_INVOICES_TURNOVER_SUCCESS: 'GET_INVOICES_TURNOVER_SUCCESS',
  GET_INVOICES_TURNOVER_FAILURE: 'GET_INVOICES_TURNOVER_FAILURE',

  GET_INVOICES_FIXED_BEGIN: 'GET_INVOICES_FIXED_BEGIN',
  GET_INVOICES_FIXED_SUCCESS: 'GET_INVOICES_FIXED_SUCCESS',
  GET_INVOICES_FIXED_FAILURE: 'GET_INVOICES_FIXED_FAILURE',

  GET_INVOICES_VARIABLE_BEGIN: 'GET_INVOICES_VARIABLE_BEGIN',
  GET_INVOICES_VARIABLE_SUCCESS: 'GET_INVOICES_VARIABLE_SUCCESS',
  GET_INVOICES_VARIABLE_FAILURE: 'GET_INVOICES_VARIABLE_FAILURE',

  GET_INVOICES_COUNT_BEGIN: 'GET_INVOICES_COUNT_BEGIN',
  GET_INVOICES_COUNT_SUCCESS: 'GET_INVOICES_COUNT_SUCCESS',
  GET_INVOICES_COUNT_FAILURE: 'GET_INVOICES_COUNT_FAILURE',

  GET_INVOICES_CUSTOMER_TYPE_BEGIN: 'GET_INVOICES_CUSTOMER_TYPE_BEGIN',
  GET_INVOICES_CUSTOMER_TYPE_SUCCESS: 'GET_INVOICES_CUSTOMER_TYPE_SUCCESS',
  GET_INVOICES_CUSTOMER_TYPE_FAILURE: 'GET_INVOICES_CUSTOMER_TYPE_FAILURE',

  GET_INVOICES_SUMMARY_BEGIN: 'GET_INVOICES_SUMMARY_BEGIN',
  GET_INVOICES_SUMMARY_SUCCESS: 'GET_INVOICES_SUMMARY_SUCCESS',
  GET_INVOICES_SUMMARY_FAILURE: 'GET_INVOICES_SUMMARY_FAILURE',

  GET_INVOICES_ACTIVE_BEGIN: 'GET_INVOICES_ACTIVE_BEGIN',
  GET_INVOICES_ACTIVE_SUCCESS: 'GET_INVOICES_ACTIVE_SUCCESS',
  GET_INVOICES_ACTIVE_FAILURE: 'GET_INVOICES_ACTIVE_FAILURE',
});

// Invoices statistics
export const getInvoicesActiveAmount = (): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
) =>
  fetch({
    service: services.statistics.candidates.getInvoicesActiveAmount(),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_INVOICES_ACTIVE_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_INVOICES_ACTIVE_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_INVOICES_ACTIVE_FAILURE, errors),
  });

export const getInvoicesSummary = (): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
) =>
  fetch({
    service: services.statistics.candidates.getInvoicesSummary(),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_INVOICES_SUMMARY_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_INVOICES_SUMMARY_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_INVOICES_SUMMARY_FAILURE, errors),
  });

export const getInvoicesCustomerType = (
  params: GetStatisticsPerMonthAPIType
): ThunkAction => (dispatch: Dispatch, getState: GetState) =>
  fetch({
    service: services.statistics.candidates.getInvoicesCustomerType(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_INVOICES_CUSTOMER_TYPE_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_INVOICES_CUSTOMER_TYPE_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_INVOICES_CUSTOMER_TYPE_FAILURE, errors),
  });

export const getInvoicesCount = (
  params: GetStatisticsPerMonthAPIType
): ThunkAction => (dispatch: Dispatch, getState: GetState) =>
  fetch({
    service: services.statistics.candidates.getInvoicesCount(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_INVOICES_COUNT_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_INVOICES_COUNT_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_INVOICES_COUNT_FAILURE, errors),
  });

export const getVariableInvoices = (
  params: GetStatisticsPerMonthAPIType
): ThunkAction => (dispatch: Dispatch, getState: GetState) =>
  fetch({
    service: services.statistics.candidates.getVariableInvoices(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_INVOICES_VARIABLE_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_INVOICES_VARIABLE_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_INVOICES_VARIABLE_FAILURE, errors),
  });

export const getFixedInvoices = (
  params: GetStatisticsPerMonthAPIType
): ThunkAction => (dispatch: Dispatch, getState: GetState) =>
  fetch({
    service: services.statistics.candidates.getFixedInvoices(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_INVOICES_FIXED_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_INVOICES_FIXED_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_INVOICES_FIXED_FAILURE, errors),
  });

export const getInvoicesTurnover = (
  params: GetStatisticsPerMonthAPIType
): ThunkAction => (dispatch: Dispatch, getState: GetState) =>
  fetch({
    service: services.statistics.candidates.getInvoicesTurnover(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_INVOICES_TURNOVER_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_INVOICES_TURNOVER_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_INVOICES_TURNOVER_FAILURE, errors),
  });

// Candidate statistics
export const getRegistrationsByProvenances = (
  params: GetProvenancesStatisticsAPIType
): ThunkAction => (dispatch: Dispatch, getState: GetState) =>
  fetch({
    service: services.statistics.candidates.getRegistrationsByProvenances(
      params
    ),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_REGISTRATIONS_PROVENANCES_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_REGISTRATIONS_PROVENANCES_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_REGISTRATIONS_PROVENANCES_FAILURE, errors),
  });

export const getHiresByProvenances = (
  params: GetProvenancesStatisticsAPIType
): ThunkAction => (dispatch: Dispatch, getState: GetState) =>
  fetch({
    service: services.statistics.candidates.getHiresByProvenances(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_HIRES_PROVENANCES_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_HIRES_PROVENANCES_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_HIRES_PROVENANCES_FAILURE, errors),
    validator: companiesArrayValidator,
  });

export const getSLByProvenances = (
  params: GetProvenancesStatisticsAPIType
): ThunkAction => (dispatch: Dispatch, getState: GetState) =>
  fetch({
    service: services.statistics.candidates.getSLByProvenances(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_SL_PROVENANCES_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_SL_PROVENANCES_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_SL_PROVENANCES_FAILURE, errors),
    validator: companiesArrayValidator,
  });
