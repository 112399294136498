import { Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ConnectedRouter } from 'connected-react-router';

import history from 'utils/history';
import { ROUTES } from 'utils/routes';
import { ROLES } from 'utils/roles';
import { muiTheme } from 'utils/theme';

import { ThemeProvider } from '@material-ui/core/styles';

import PrivateRoute from 'components/PrivateRoute';
import Alert from 'components/Alert';
import AppVersion from 'components/AppVersion';

import Login from 'pages/Login';
import Paf from 'pages/Placement/Summary';
import { PafListPage, PafCreatePage, PafEditPage } from 'pages/Placement/Paf';
import PafStatistics from 'pages/Statistics/Paf';
import CandidatesStatistics from 'pages/Statistics/Candidates';
import InvoicesStatistics from 'pages/Statistics/Invoices';
import JobBoardsPosting from 'pages/JobBoardsPosting/Letudiant';
import JobBoardsSummary from 'pages/JobBoardsPosting/Summary';
import Invoices from 'pages/Invoicing/Invoices';
import InvoiceForm from 'pages/Invoicing/Invoices/Form';
import CreditNotes from 'pages/Invoicing/CreditNotes';
import CreditNoteForm from 'pages/Invoicing/CreditNotes/Form';
import Offers from 'pages/Offers/List';
import OfferForm from 'pages/Offers/Form';
import OfferShow from 'pages/Offers/Show';
import Companies from 'pages/Companies';
import CompanyShow from 'pages/Companies/Show';
import CompanyForms from 'pages/Companies/Form';
import {
  CandidateListPage,
  CandidateShowPage,
  CandidateCreatePage,
} from 'pages/Candidates';
import RecruiterForm from 'components/eiquem/company/RecruiterForm';
import Admins from 'pages/Admins';
import AdminForm from 'pages/Admins/Form';
import CommercialProposals from 'pages/CommercialProposals';
import CommercialProposalForm from 'pages/CommercialProposals/Form';
import FollowUpPage from 'pages/FollowUp';
import ApplicationFollowUpsPage from 'pages/ApplicationFollowUp';

const App = () => (
  <Fragment>
    <ConnectedRouter history={history}>
      <Switch>
        <Redirect from="/" to={ROUTES.PAF_DATA} exact />
        <ThemeProvider theme={muiTheme}>
          <Route path={ROUTES.LOGIN} component={Login} />
          <PrivateRoute path={ROUTES.PAF} component={Paf} />
          <PrivateRoute path={ROUTES.PAF_DATA} component={PafListPage} />
          <PrivateRoute path={ROUTES.PAF_CREATE} component={PafCreatePage} />
          <PrivateRoute path={ROUTES.PAF_EDIT} component={PafEditPage} />
          <PrivateRoute
            path={ROUTES.STATISTICS_PAF}
            component={PafStatistics}
          />
          <PrivateRoute
            path={ROUTES.STATISTICS_CANDIDATES}
            component={CandidatesStatistics}
          />
          <PrivateRoute
            path={ROUTES.STATISTICS_INVOICES}
            component={InvoicesStatistics}
          />
          <PrivateRoute
            path={ROUTES.JOBBOARDS_POSTING}
            component={JobBoardsPosting}
          />
          <PrivateRoute
            path={ROUTES.JOBBOARDS_SUMMARY}
            component={JobBoardsSummary}
          />
          <PrivateRoute path={ROUTES.INVOICES_LIST} component={Invoices} />
          <PrivateRoute path={ROUTES.INVOICE_CREATE} component={InvoiceForm} />
          <PrivateRoute path={ROUTES.INVOICE_EDIT} component={InvoiceForm} />
          <PrivateRoute
            path={ROUTES.CREDIT_NOTES_LIST}
            component={CreditNotes}
          />
          <PrivateRoute
            path={ROUTES.CREDIT_NOTE_CREATE}
            component={CreditNoteForm}
          />
          <PrivateRoute
            path={ROUTES.CREDIT_NOTE_EDIT}
            component={CreditNoteForm}
          />
          <PrivateRoute path={ROUTES.OFFER_LIST} component={Offers} />
          <PrivateRoute path={ROUTES.OFFER_CREATE} component={OfferForm} />
          <PrivateRoute path={ROUTES.OFFER_EDIT} component={OfferForm} />
          <PrivateRoute path={ROUTES.OFFER_SHOW} component={OfferShow} />
          <PrivateRoute path={ROUTES.COMPANIES_LIST} component={Companies} />
          <PrivateRoute path={ROUTES.COMPANY_CREATE} component={CompanyForms} />
          <PrivateRoute path={ROUTES.COMPANY_SHOW} component={CompanyShow} />
          <PrivateRoute path={ROUTES.COMPANY_EDIT} component={CompanyForms} />
          <PrivateRoute
            path={ROUTES.CANDIDATES_LIST}
            component={CandidateListPage}
          />
          <PrivateRoute
            path={ROUTES.CANDIDATE_SHOW}
            component={CandidateShowPage}
          />
          <PrivateRoute
            path={ROUTES.CANDIDATE_CREATE}
            component={CandidateCreatePage}
          />
          <PrivateRoute
            path={ROUTES.RECRUITER_CREATE}
            component={RecruiterForm}
          />
          <PrivateRoute
            path={ROUTES.RECRUITER_EDIT}
            component={RecruiterForm}
          />
          <PrivateRoute
            path={ROUTES.ADMIN_LIST}
            allowedRoles={[ROLES.SUPER_ADMIN]}
            component={Admins}
          />
          <PrivateRoute
            path={ROUTES.ADMIN_EDIT}
            allowedRoles={[ROLES.SUPER_ADMIN]}
            component={AdminForm}
          />
          <PrivateRoute
            path={ROUTES.ADMIN_CREATE}
            allowedRoles={[ROLES.SUPER_ADMIN]}
            component={AdminForm}
          />
          <PrivateRoute
            path={ROUTES.COMMERCIAL_PROPOSAL_LIST}
            component={CommercialProposals}
          />
          <PrivateRoute
            path={ROUTES.COMMERCIAL_PROPOSAL_CREATE}
            component={CommercialProposalForm}
          />
          <PrivateRoute
            path={ROUTES.COMMERCIAL_PROPOSAL_EDIT}
            component={CommercialProposalForm}
          />
          <PrivateRoute path={ROUTES.FOLLOW_UP} component={FollowUpPage} />
          <PrivateRoute
            path={ROUTES.APPLICATION_FOLLOW_UPS}
            component={ApplicationFollowUpsPage}
          />
          <Alert />
          <AppVersion />
        </ThemeProvider>
      </Switch>
    </ConnectedRouter>
  </Fragment>
);

export default App;
