export const initialCompany = {
  logo: null,
  background: null,
  name: null,
  street: null,
  city: null,
  postCode: null,
  country: 'France',
  email: null,
  phoneNumber: null,
  businessSector: null,
  secondaryBusinessSector: null,
  size: null,
  provenance: null,
  employeesCount: null,
  description: null,
  password: null,
  active: true,
  admin: null,
  recruitmentProcess: null,
  differentBillingAddress: false,
  billingName: null,
  billingStreet: null,
  billingPostCode: null,
  billingCity: null,
  billingCountry: null,
  billingEmail: null,
  billingContactName: null,
  websiteUrl: null,
};

export const handleFormValues = values => ({
  id: values.id,
  name: values.name,
  adminId: values.admin.value,
  businessSectorId: values.businessSector.value,
  secondaryBusinessSectorId:
    values.secondaryBusinessSector && values.secondaryBusinessSector.value,
  sizeId: values.size.value,
  provenanceId: values.provenance.value,
  street: values.street,
  city: values.city,
  postCode: values.postCode,
  country: values.country,
  billingName: values.billingName,
  billingStreet: values.billingStreet,
  billingCity: values.billingCity,
  billingPostCode: values.billingPostCode,
  billingCountry: values.billingCountry,
  billingEmail: values.billingEmail,
  billingContact: values.billingContactName,
  email: values.email,
  phoneNumber: values.phoneNumber,
  employeesCount: values.employeesCount,
  description: values.description,
  recruitmentProcess: values.recruitmentProcess,
  active: values.active,
  logo:
    values.logo && values.logo.startsWith('data:image') ? values.logo : null,
  background:
    values.background && values.background.startsWith('data:image')
      ? values.background
      : null,
  websiteUrl: values.websiteUrl,
});
