import { useTranslation } from 'react-i18next';
import { DatePicker } from 'components/core/input';

export default function PlacementDate(props) {
  const { values, onChange } = props;
  const { t } = useTranslation();

  return (
    <DatePicker
      name="pafDate"
      label={t('paf.field.pafDate')}
      value={values.pafDate}
      onChange={onChange}
    />
  );
}
