import BaseButton from './baseButton';

export default function SendButton(props) {
  const { value } = props;

  const msg = `Souhaites-tu placer cette candidature en ENVOYÉ ?
  Un mail sera envoyé au candidat et le statut de sa candidature sera mis à jour.`;

  return (
    <BaseButton
      label="Envoyé"
      message={msg}
      value={value}
      color="primary"
      action={props.action}
      disabled={props.disabled}
    />
  );
}
