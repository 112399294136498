import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Header from './Header';
import Body from './Body';

const Table = ({
  maxHeight,
  columns,
  rows,
  actions,
  detail,
  selectable,
  onChange,
}) => {
  return (
    <>
      <Container maxHeight={maxHeight}>
        <table width="100%" cellSpacing="0" cellPadding="10">
          <Header
            columns={columns}
            hasActions={actions !== null}
            selectable={Boolean(selectable)}
            onSelectAllRows={selectable && selectable.handleSelectAllRows}
            allRowsSelected={selectable && selectable.allRowsSelected}
            onChange={onChange}
          />
          <Body
            columns={columns}
            rows={rows}
            actions={actions}
            detail={detail}
            selectable={Boolean(selectable)}
            selectedRows={selectable && selectable.selectedRows}
            onSelectRow={selectable && selectable.handleSelectRow}
          />
        </table>
      </Container>
    </>
  );
};

const Container = styled.div`
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight + 5 + 'px' : '100%')};
  overflow-y: ${({ maxHeight }) => (maxHeight ? 'auto' : 'hidden')};
`;

Table.propTypes = {
  maxHeight: PropTypes.number,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array,
  actions: PropTypes.func,
  selectable: PropTypes.exact({
    selectedRows: PropTypes.array.isRequired,
    allRowsSelected: PropTypes.bool.isRequired,
    handleSelectRow: PropTypes.func.isRequired,
    handleSelectAllRows: PropTypes.func.isRequired,
    cleanSelectedRows: PropTypes.func,
  }),
};

Table.defaultProps = {
  maxHeight: null,
  columns: [],
  rows: [],
  actions: null,
  detail: null,
  selectable: null,
};

export default memo(Table);
