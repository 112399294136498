import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'utils/routes';

import {
  getPostings,
  archivePosting,
  postPosting,
} from 'redux/jobBoardsPosts/actions';

import { tableColumns } from './constants';
import { postingDataFormat, getJobBoard } from './functions';

import { Paper, Box, Typography, withStyles } from '@material-ui/core';

import Main from 'components/layout/Main';
import Table from 'components/Table';
import CreateButton from 'components/Table/Actions/CreateButton';

import FilterFields from 'pages/JobBoardsPosting/Summary/FilterFields';
import PublishButton from 'pages/JobBoardsPosting/Summary/PublishButton';
import ArchiveButton from 'pages/JobBoardsPosting/Summary/ArchiveButton';
import RowDetails from 'pages/JobBoardsPosting/Summary/RowDetails';
import S from './styles';

function JobBoardsSummary(props) {
  const { classes } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const republish = (offerId, jobBoardId, archiveDate) => {
    const currPosting = postings.find(posting => posting.offer.id === offerId);
    const letudiantId = getJobBoard('letudiant').id;
    if (
      jobBoardId === letudiantId &&
      (!currPosting.posts || !currPosting.posts.letudiant)
    ) {
      // Redirect to letudiant form creation if no post exist
      history.push(ROUTES.JOBBOARDS_POSTING);
    } else {
      const params = {
        offerId: offerId,
        jobBoardId: jobBoardId,
        archiveDate: archiveDate,
      };
      console.log('post posting with params: ', params);
      dispatch(postPosting(params));
    }
  };

  const archive = (offerId, jobBoardId) => {
    const params = { offerId: offerId, jobBoardId: jobBoardId };

    console.log('archive posting with params: ', params);
    dispatch(archivePosting(params));
  };

  const currAdmin = useSelector(state => state.auth).userId;
  const handleRefresh = ({
    admin = currAdmin,
    onlyActive = 1,
    page = null,
    size = null,
  }) => {
    dispatch(
      getPostings({
        admin: admin,
        onlyActive: onlyActive,
        page: page + 1,
        size: size,
      })
    );
  };

  const generalActionComponents = [
    () => <CreateButton pathname={ROUTES.JOBBOARDS_POSTING} />,
  ];
  const actionComponents = [
    props => <PublishButton {...props.row} action={republish} />,
    props => <ArchiveButton {...props.row} action={archive} />,
  ];

  const jobBoardState = useSelector(state => state.jobBoardsPosts);

  const postings = jobBoardState.items;
  const postingDataFormated = postingDataFormat(postings);

  return (
    <Main
      pageTitle={t('menu.recruitmentTitle')}
      isLoading={jobBoardState.loading}
    >
      <Paper className={classes.root}>
        <Box>
          <Typography variant="h6" color="primary" gutterBottom>
            {t('jobboards-posting.summarySubtitle')}
          </Typography>
          <Table
            maxWidth="1400px"
            rows={postingDataFormated}
            columns={tableColumns()}
            handleRefresh={handleRefresh}
            generalActionComponents={generalActionComponents}
            actionComponents={actionComponents}
            isPaginatable={jobBoardState.totalItemCount !== null}
            totalItemCount={jobBoardState.totalItemCount}
            expandComponent={<RowDetails />}
            filterComponent={<FilterFields />}
          />
        </Box>
      </Paper>
    </Main>
  );
}

export default withStyles(S)(JobBoardsSummary);
