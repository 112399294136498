import AddIcon from '@material-ui/icons/Add';

import ActionButton from 'components/ActionButton';

AddButton.defaultProps = {
  requireSuperAdmin: false,
  isAllowToAdd: true,
};

function AddButton(props) {
  return (
    <ActionButton
      key="addButton"
      label="Ajouter"
      icon={<AddIcon />}
      action={props.onAddLine}
      disabled={!props.isAllowToAdd}
      tooltipPosition="left"
    />
  );
}

export default AddButton;
