import { ROUTES } from 'utils/routes';

export const tableColumns = [
  {
    header: 'Entreprise',
    accessors: ['label'],
    type: 'image',
    style: {
      fontWeight: 'bold',
    },
    headerStyle: { width: '5%' },
  },
  {
    header: 'Nom',
    accessors: ['nameLink'],
    type: 'link',
    isSortable: true,
    style: {
      fontWeight: 'bold',
    },
    headerStyle: { width: '10%' },
  },
  {
    header: 'Secteur',
    accessors: ['businessSector', 'label'],
    headerStyle: { width: '50%' },
  },
  {
    header: 'Taille',
    accessors: ['size', 'label'],
    headerStyle: { width: '10%' },
  },
  {
    header: "Date d'ajout",
    accessors: ['createDate'],
    headerStyle: { width: '10%' },
    type: 'date',
    isSortable: true,
  },
  {
    header: 'Pays',
    accessors: ['country'],
    headerStyle: { width: '10%' },
  },
  {
    header: 'Responsable',
    accessors: ['admin', 'label'],
    headerStyle: { width: '10%' },
  },
  {
    header: 'Active',
    accessors: ['active'],
    headerStyle: { width: '10%' },
    type: 'boolean',
  },
];

export const formatCompanies = companies => {
  return companies.map(company => {
    company.label = {
      label: company.name,
      url: company.logo,
    };
    company.nameLink = {
      label: company.name,
      url: ROUTES.COMPANY_SHOW.replace(':id', company.id),
    };
    return company;
  });
};
