import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CompanyTable from 'components/Tables/CompanyTable';
import { Box } from '@material-ui/core';
import Main from 'components/layout/Main';
import PageHeader from 'components/layout/PageHeader';

function Companies() {
  const { t } = useTranslation();
  const isCompanyLoading = useSelector(state => state.companies).loading;
  return (
    <Main pageTitle={t('company.title')} isLoading={isCompanyLoading}>
      <Box>
        <PageHeader title={t('company.title')} />
        <CompanyTable />
      </Box>
    </Main>
  );
}

export default Companies;
