import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOffers, cleanOffers } from 'redux/offers/actions';

import { default as Table } from 'components/eiquem/offer/Table';
import { PaginationWithState } from 'components/core/Pagination';

function OfferTable({ companyId }) {
  const dispatch = useDispatch();
  const offerState = useSelector(state => state.offers);
  const offers = offerState.items;
  const offerCount = offerState.totalItemCount;

  const handleRefresh = (page, size) => {
    dispatch(
      getOffers({
        page: page,
        size: size,
        company: companyId,
      })
    );
  };

  useEffect(() => {
    handleRefresh(1, 20);
    return () => dispatch(cleanOffers());
  }, []);

  const handlePaginationChange = ({ page, pageSize }) => {
    handleRefresh(page, pageSize);
  };
  return (
    <>
      <Table offers={offers} />
      <PaginationWithState
        itemCount={offerCount}
        onChange={handlePaginationChange}
      />
    </>
  );
}

export default OfferTable;
