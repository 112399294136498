import { Button } from '@material-ui/core';

export default function ActionButton({ label, onClick }) {
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      color="primary"
      style={{ width: '100%' }}
    >
      {label}
    </Button>
  );
}
