import { ROUTES } from 'utils/routes';

import { darkBlueFont, redFont } from 'utils/constants';

const irpTooltip = (
  <>
    Programme de relance
    <br />
    <li>J-2 : Rappel</li>
    <li>J 0 : Relance 1</li>
    <li>J+5 : Relance 2</li>
    <li>J+20 : Relance 3</li>
    <li>J+35 : Relance 4 et sommation mise en demeure</li>
    <li>J+45 : Mise en demeure</li>
  </>
);

export const tableColumns = t => [
  {
    header: '',
    accessors: ['company'],
    type: 'image',
  },
  {
    header: t('invoicing.invoices.detail.table.company'),
    accessors: ['company', 'label'],
    style: { fontWeight: 'bold' },
  },
  {
    header: t('invoicing.invoices.detail.table.id'),
    accessors: ['invoiceId'],
    isSortable: true,
  },
  {
    header: t('invoicing.invoices.detail.table.type'),
    accessors: ['type'],
  },
  {
    header: t('invoicing.invoices.detail.table.label'),
    accessors: ['offerLink'],
    isSortable: true,
    headerStyle: {
      minWidth: '10%',
    },
    style: {
      fontWeight: 'bold',
    },
    type: 'link',
  },
  {
    header: t('invoicing.invoices.detail.table.pris'),
    accessors: ['candidateLink'],
    type: 'link',
  },
  {
    header: t('invoicing.invoices.detail.table.amount'),
    suffix: '€',
    accessors: ['amount'],
    isSortable: true,
    style: {
      fontWeight: 'bold',
    },
  },
  {
    header: t('invoicing.invoices.detail.table.date'),
    accessors: ['date'],
    isSortable: true,
    type: 'date',
  },
  {
    header: t('invoicing.invoices.detail.table.daysToPay'),
    accessors: ['daysToPay'],
    suffix: 'j',
  },
  {
    header: t('invoicing.invoices.detail.table.issuance'),
    suffix: 'j',
    accessors: ['issuance'],
  },
  {
    header: t('invoicing.invoices.detail.table.invoiceReminder'),
    accessors: ['invoiceReminder'],
    isSortable: true,
  },
  {
    header: t('invoicing.invoices.detail.table.irpState'),
    tooltip: irpTooltip,
    accessors: ['irpState'],
  },
  {
    header: t('invoicing.invoices.detail.table.paymentDate'),
    accessors: ['paymentDate'],
    isSortable: true,
    type: 'date',
  },
  {
    header: t('invoicing.invoices.detail.table.applyTva'),
    accessors: ['tva'],
    type: 'boolean',
  },
  {
    header: t('invoicing.invoices.detail.table.creditNote'),
    accessors: ['creditNote'],
    type: 'boolean',
  },
];

export const typesOptions = [
  { label: 'Autre', value: 'autre' },
  { label: 'RPO', value: 'rpo' },
];

export const initialInvoice = {
  type: typesOptions[0],
  company: null,
  amount: null,
  comment: null,
  message: null,
  daysToPay: 15,
  tva: true,
};

/*
 * Set format for invoice Table
 */
export const invoiceTableFormat = invoices =>
  invoices.map(invoice => {
    const invoiceTableFormat = Object.assign({}, invoice);

    invoiceTableFormat.company = {
      ...invoiceTableFormat.company,
      label: invoice.company.label,
      url: invoice.company.url,
      link: ROUTES.COMPANY_SHOW.replace(':id', invoice.company.value),
    };

    invoiceTableFormat.offerLink = {
      label: invoice.offer.label,
      url: ROUTES.OFFER_SHOW.replace(':id', invoice.offer.value),
    };

    invoiceTableFormat.candidateLink = invoice.candidate
      ? {
          label: invoice.candidate.label,
          url: ROUTES.CANDIDATE_SHOW.replace(':id', invoice.candidate.value),
        }
      : null;

    // Set styles for invoice lines depending of line status
    if (invoice.paymentDate) {
      invoiceTableFormat.textDecoration = darkBlueFont;
      return invoiceTableFormat;
    } else if (invoice.issuance >= invoice.daysToPay) {
      invoiceTableFormat.textDecoration = redFont;
      return invoiceTableFormat;
    }
    return invoiceTableFormat;
  });
