import { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileOptions } from 'redux/formOptions/actions';
import { updateCandidateProfile } from 'redux/candidates/actions';
import Grid from 'components/core/containers/Grid';
import Item from 'components/core/Item';
import Autocomplete from 'components/core/input/Autocomplete';
import ApplicationTable from 'components/eiquem/candidate/ApplicationTable';

function CandidateRowDetail(props) {
  const dispatch = useDispatch();
  const profileOptions = useSelector(state => state.formOptions.profileOptions);

  useEffect(
    () => {
      dispatch(getProfileOptions());
    },
    [dispatch]
  );

  const candidate = props.row;
  const candidateId = candidate.id;
  const researchId = parseInt(candidate.researchId);
  const profile = candidate.profile;

  const handleChange = ({ value }) => {
    dispatch(updateCandidateProfile(candidateId, value ? value.label : null));
  };

  return (
    <div>
      <Grid container>
        <Grid md={7}>
          <Grid container>
            <Grid md={4}>
              <Item label="Niveau d'Études" value={candidate.studyLevel} />
            </Grid>

            <Grid md={4}>
              <Item
                label="Déjà Contacté"
                value={candidate.alreadyContacted}
                type="bool"
              />
            </Grid>
            <Grid md={4}>
              <Item
                label="Déjà Placé"
                value={candidate.alreadyPlaced}
                type="bool"
              />
            </Grid>
            <Grid md={4}>
              <Item label="Email" value={candidate.email} />
            </Grid>
            <Grid md={4}>
              <Item label="Téléphone" value={candidate.phoneNumber} />
            </Grid>

            <Grid md={12}>
              <Item label="Fonctions Recherchées" value={candidate.functions} />
            </Grid>
          </Grid>
        </Grid>
        <Grid md={4} />
        <Grid md={1}>
          <Autocomplete
            defaultValue={profile}
            options={profileOptions}
            label="Profile"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <ApplicationTable researchId={researchId} />
    </div>
  );
}

export default memo(CandidateRowDetail);
