import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components/core/input';

export default function IsSecondVariable({ values, onChange }) {
  const { t } = useTranslation();

  return (
    <Checkbox
      name="secondVariable"
      label={t('paf.field.secondVariable')}
      value={values.secondVariable}
      onChange={onChange}
    />
  );
}
