import type { Action } from 'utils/types';

export const ACTIONS_TYPE = Object.freeze({
  REMOVE_ALERT: 'REMOVE_ALERT',
});

export const removeAlert = (key: string): Action => ({
  type: ACTIONS_TYPE.REMOVE_ALERT,
  key,
});
