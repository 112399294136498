import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { CommercialProposalFormSchema } from 'utils/formSchemas';

import {
  initialProposal,
  handleValues,
} from 'components/eiquem/commercialProposal/Form/helper';

import { Paper } from '@material-ui/core';
import Title from 'components/layout/Title';
import Main from 'components/layout/Main';
import Form from 'components/eiquem/commercialProposal/Form/form';
import { useCreateProposal } from 'utils/hooks/commercialProposal';

export default function CreateProposal() {
  const { t } = useTranslation();
  const [createProposal, isLoading] = useCreateProposal();

  const handleOnSubmit = values => {
    createProposal(handleValues(values));
  };

  return (
    <Main pageTitle={t('commercialProposal.title')} isLoading={isLoading}>
      <Title>{t('commercialProposal.create')}</Title>

      <Paper>
        <Formik
          initialValues={initialProposal}
          validationSchema={CommercialProposalFormSchema}
          onSubmit={handleOnSubmit}
        >
          {props => <Form {...props} createMode />}
        </Formik>
      </Paper>
    </Main>
  );
}
