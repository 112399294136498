import { useTranslation } from 'react-i18next';

import { studentJobTypeId } from 'utils/constants';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, TextField } from '@material-ui/core';
import { Subtitle } from '../subtitle';

export default function SalaryFields(props) {
  const {
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    classes,
    getFormOptions,
  } = props;

  const { t } = useTranslation();
  const { domainOptions } = getFormOptions();
  const { contractOptions } = getFormOptions();

  return (
    <Box>
      {/* Student Job Part */}
      {domainOptions &&
        contractOptions && (
          <Box>
            <Subtitle label="Indexation Job" />
            <Box className={classes.row}>
              <Autocomplete
                multiple
                id="studentJobDomains"
                className={classes.selectLargeField}
                options={domainOptions[studentJobTypeId]}
                getOptionLabel={option => option.label}
                groupBy={option => option.groupLabel}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    id="studentJobDomains"
                    variant="standard"
                    label={t('jobboards-posting.field.studentJobDomains')}
                    fullWidth
                    error={
                      touched.studentJobDomains &&
                      Boolean(errors.studentJobDomains)
                    }
                    helperText={
                      touched.studentJobDomains ? errors.studentJobDomains : ''
                    }
                  />
                )}
                onChange={(event, value) => {
                  setFieldValue('studentJobDomains', value);
                  setFieldTouched('studentJobDomains', true, false);
                }}
              />
            </Box>
            <Box className={classes.row}>
              <Autocomplete
                multiple
                id="studentJobContracts"
                className={classes.selectLargeField}
                options={contractOptions[studentJobTypeId]}
                getOptionLabel={option => option.label}
                groupBy={option => option.groupLabel}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    id="studentJobContracts"
                    variant="standard"
                    label={t('jobboards-posting.field.studentJobContracts')}
                    fullWidth
                    error={
                      touched.studentJobContracts &&
                      Boolean(errors.studentJobContracts)
                    }
                    helperText={
                      touched.studentJobContracts
                        ? errors.studentJobContracts
                        : ''
                    }
                  />
                )}
                onChange={(event, value) => {
                  setFieldValue('studentJobContracts', value);
                  setFieldTouched('studentJobContracts', true, false);
                }}
              />
            </Box>
          </Box>
        )}
    </Box>
  );
}
