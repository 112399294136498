import { useTranslation } from 'react-i18next';
import TablePagination from '@material-ui/core/TablePagination';

Pagination.defaultProps = {
  totalItemCount: 0,
  page: 1,
  rowsPerPage: 20,
};
const defaultRowsPerPageOption = [20, 50, 100];

export default function Pagination(props) {
  const {
    totalItemCount,
    pagination,
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
  } = props;

  const { t } = useTranslation();
  return (
    <TablePagination
      {...pagination}
      rowsPerPageOptions={defaultRowsPerPageOption}
      labelRowsPerPage={t('label-rows-per-page')}
      component="div"
      count={totalItemCount}
      backIconButtonProps={{ 'aria-label': 'previous page' }}
      nextIconButtonProps={{ 'aria-label': 'next page' }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      labelDisplayedRows={() => {
        return `${page + 1}-${Math.floor(
          totalItemCount / rowsPerPage + 1
        )} (${totalItemCount} ${totalItemCount === 1 ? 'ligne' : 'lignes'})`;
      }}
    />
  );
}
