import { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import Row from './Row';

const Body = ({
  columns,
  rows,
  actions,
  detail,
  selectable,
  selectedRows,
  onSelectRow,
}) => {
  const [activeDetail, setActiveDetail] = useState(null);

  const handleShowDetail = useCallback(
    (rowId, isActive) => {
      if (detail) {
        setActiveDetail(isActive ? rowId : null);
      }
    },
    [detail]
  );

  return (
    <tbody>
      {rows &&
        rows.map((row, index) => {
          const key = row.id || index;
          return (
            <Row
              key={key}
              id={key}
              columns={columns}
              row={row}
              actions={actions}
              detail={detail}
              showDetail={activeDetail === key}
              onShowDetail={handleShowDetail}
              selectable={onSelectRow && selectable}
              selectedRows={selectedRows}
              onSelectRow={onSelectRow}
            />
          );
        })}
    </tbody>
  );
};

Body.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array,
  actions: PropTypes.func,
  selectable: PropTypes.bool,
  selectedRows: PropTypes.array,
  onSelectRow: PropTypes.func,
};

Body.defaultProps = {
  columns: [],
  rows: [],
  actions: null,
  detail: null,
  selectable: false,
  selectedRows: [],
  onSelectRow: null,
};

export default memo(Body);
