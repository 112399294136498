import { ACTIONS_TYPE } from './actions';

export const initialState = {
  loading: false,
  items: {
    w1: [],
    w4: [],
    q: [],
    m1: [],
    m: [],
  },
};

export function followUpsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPE.GET_FOLLOW_UPS_BEGIN:
    case ACTIONS_TYPE.REMIND_AND_POSTPONE_FOLLOW_UP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_FOLLOW_UPS_SUCCESS: {
      const followUps = action.result.followUps;
      return {
        ...state,
        loading: false,
        items: {
          w1: followUps.w1 || [],
          w4: followUps.w4 || [],
          q: followUps.q || [],
          m1: followUps.m1 || [],
          m: followUps.m || [],
        },
      };
    }
    case ACTIONS_TYPE.GET_FOLLOW_UPS_FAILURE:
      return {
        ...state,
        loading: false,
        items: { ...initialState.items },
      };
    case ACTIONS_TYPE.REMIND_AND_POSTPONE_FOLLOW_UP_SUCCESS:
    case ACTIONS_TYPE.REMIND_AND_POSTPONE_FOLLOW_UP_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
