import { useTranslation } from 'react-i18next';
import { addMonths, subMonths } from 'date-fns';
import { recruitmentPlanId } from 'utils/constants';

import { Grid } from 'components/core/containers';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from 'components/core/buttons/IconButton';
import {
  FollowUpField,
  PERIODS,
} from 'components/eiquem/placement/followUp/Field';

export function FollowUps({ values, onChange, disableRefresh }) {
  const { t } = useTranslation();

  if (!values.followUps) return <></>;

  const followUps = values.followUps.map(followUp => ({ ...followUp }));

  const handleChange = newFollowUp => {
    const newFollowUps = followUps.map(
      followUp => (newFollowUp.id === followUp.id ? newFollowUp : followUp)
    );

    onChange({ name: 'followUps', value: newFollowUps });
  };

  const forceRefresh = e => {
    e.preventDefault();
    onChange({
      name: 'followUps',
      value: computeFollowUps(
        values.candidateStartDate,
        values.contractDuration,
        values.plan
      ),
    });
  };

  return (
    <>
      <Grid md={12}>
        <div>
          Suivi Placement
          {!disableRefresh && (
            <IconButton icon={RefreshIcon} onClick={forceRefresh} />
          )}
        </div>

        <FollowUpField
          label={t('followUp.periods.week1')}
          period={PERIODS.W1}
          followUps={followUps}
          onChange={handleChange}
        />
        <FollowUpField
          label={t('followUp.periods.week4')}
          period={PERIODS.W4}
          followUps={followUps}
          onChange={handleChange}
        />
        <FollowUpField
          label={t('followUp.periods.quarter')}
          period={PERIODS.Q}
          followUps={followUps}
          onChange={handleChange}
        />
        <FollowUpField
          label={t('followUp.periods.month-1')}
          period={PERIODS.M1}
          followUps={followUps}
          onChange={handleChange}
        />
        <FollowUpField
          label={t('followUp.periods.month')}
          period={PERIODS.M}
          followUps={followUps}
          onChange={handleChange}
        />
      </Grid>
    </>
  );
}

export const computeFollowUps = (startDate, duration, plan) => {
  if (!startDate || !duration || plan?.value !== recruitmentPlanId) {
    return [];
  }

  switch (true) {
    case duration === 1: {
      return computePeriods([PERIODS.W1, PERIODS.M], startDate, duration);
    }
    case duration <= 4: {
      return computePeriods(
        [PERIODS.W1, PERIODS.W4, PERIODS.M],
        startDate,
        duration
      );
    }
    default: {
      return computePeriods(
        [PERIODS.W1, PERIODS.W4, PERIODS.Q, PERIODS.M1, PERIODS.M],
        startDate,
        duration
      );
    }
  }
};

const computePeriods = (periods, startDate, duration) => {
  startDate = new Date(startDate);
  const endDate = addMonths(startDate, duration);
  let followUps = [];

  periods.forEach(period => {
    switch (period) {
      case PERIODS.W1: {
        followUps.push(createFollowUp(period, startDate));
        break;
      }
      case PERIODS.W4: {
        followUps.push(createFollowUp(period, addMonths(startDate, 1)));
        break;
      }
      case PERIODS.Q: {
        for (let i = 1; i < duration - 1; i++) {
          if (i % 3 === 0) {
            followUps.push(createFollowUp(period, addMonths(startDate, i)));
          }
        }
        break;
      }
      case PERIODS.M1: {
        followUps.push(createFollowUp(period, subMonths(endDate, 1)));
        break;
      }
      case PERIODS.M: {
        followUps.push(createFollowUp(period, endDate));
        break;
      }
      default: {
        throw new Error('invalid period');
      }
    }
  });

  return followUps;
};

const createFollowUp = (period, date) => ({
  id: Math.random(),
  period: period,
  date: date,
  isDone: date > new Date() ? false : true,
});

export const completeFollowUps = followUps => {
  return followUps
    ? followUps.map(followUp => ({ ...followUp, isDone: true }))
    : null;
};
