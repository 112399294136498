import { Grid } from 'components/core/containers';
import { DatePicker } from 'components/core/input';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cleanApplicationFollowUp,
  getApplicationFollowUp,
  updateApplicationFollowUp,
} from 'redux/applicationFollowUps/actions';

export default function ApplicationFollowUp({ candidateId }) {
  const dispatch = useDispatch();
  useEffect(
    () => {
      dispatch(getApplicationFollowUp({ candidateId }));

      return () => dispatch(cleanApplicationFollowUp());
    },
    [dispatch, candidateId]
  );

  const applicationFollowUp = useSelector(
    state => state.applicationFollowUps.current
  );

  const updateApplicationFollowUpDate = ({ value }) =>
    value &&
    dispatch(
      updateApplicationFollowUp({
        id: applicationFollowUp.id,
        date: value,
      })
    );

  return (
    <>
      {applicationFollowUp && (
        <Grid md={12}>
          <div>Suivi Envoyé</div>
          <DatePicker
            name="date"
            value={applicationFollowUp.date}
            onChange={updateApplicationFollowUpDate}
          />
        </Grid>
      )}
    </>
  );
}
