const fieldsSpacing = 10;

export default () => ({
  root: {
    overflowX: 'auto',
    display: 'inline-flex',
  },
  formContainer: {
    width: '1250px',
  },
  row: {
    display: 'flex',
    marginTop: 20,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,
    marginLeft: 30,
    marginRight: 30,
  },
  field: {
    width: 180,
    marginLeft: fieldsSpacing,
    marginRight: fieldsSpacing,
  },
  fullField: {
    width: '100%',
  },
  largeField: {
    width: 380,
    marginLeft: fieldsSpacing,
    marginRight: fieldsSpacing,
  },
  checkbox: {
    marginRight: 20,
    marginLeft: 0,
    marginBottom: -5,
  },
  richText: {
    width: '100%',
    marginBottom: 30,
    marginTop: 30,
    marginLeft: 10,
    marginRight: 10,
  },
  divider: {
    marginTop: 100,
    height: '70%',
  },
  itemsWrapper: {
    display: 'flex',
  },
  removeButton: {
    margin: 10,
  },
});
