import { Grid } from 'components/core/containers';
import { useTranslation } from 'react-i18next';
import { currencyFormatter, formatDate } from 'utils/functions';

export default function Field({ label, value, type }) {
  const { t } = useTranslation();

  return (
    <Grid md={12}>
      <span>
        {t(`common.fields.${label}`)} : <b>{formatValue(value, type)}</b>
      </span>
    </Grid>
  );
}

function formatValue(value, type) {
  switch (type) {
    case 'date':
      return value ? formatDate(value) : '-';
    case 'currency':
      return currencyFormatter.format(value || 0);
    case 'number':
      return value || 0;
    default:
      return value || '-';
  }
}
