const drawerWidth = 125;

const style = theme => ({
  root: {
    display: 'flex',
    minWidth: 500,
  },
  toolbar: {
    paddingLeft: 8,
    height: 90,
  },
  logo: {
    width: '10%',
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 10,
    left: 50,
    right: 50,
    textAlign: 'center',
    zIndex: theme.zIndex.drawer + 2,
  },
  toolbarIcon: {
    height: 90,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 2px',
    ...theme.mixins.toolbar,
  },
  toolbarIconButton: {
    padding: '8px',
  },
  appBar: {
    left: 0,
    minWidth: 500,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'white',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 4,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    marginLeft: 10,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(4),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flex: 1,
    alignItems: 'left',
    height: '100vh',
    overflow: 'auto',
    backgroundColor: 'white',
  },
  container: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(4),
    flex: 1,
    maxWidth: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
});

export default style;
