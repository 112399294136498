const applicationFollowUps = {
  update: ({ id, date, notifyAndPostpone, useFamiliarity }) => ({
    url: `/applications/follow-ups/${id}`,
    method: 'put',
    data: {
      id,
      date,
      notifyAndPostpone,
      useFamiliarity,
    },
  }),
  getOne: ({ candidateId }) => ({
    url: `/candidates/${candidateId}/application-follow-up`,
    method: 'get',
  }),
  get: ({ adminId }) => ({
    url: '/applications/follow-ups',
    method: 'get',
    params: { adminId },
  }),
};

export default applicationFollowUps;
