import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getOneCompany, cleanCurrentCompany } from 'redux/companies/actions';
import Main from 'components/layout/Main';
import CompanyDetail from './Company';
import Offers from './Offers';
import Invoices from './Invoices';
import CommercialProposals from './CommercialProposals';

import { Grid } from 'components/core/containers';
import { useParamId } from 'utils/hooks';

function CompanyShow() {
  const dispatch = useDispatch();
  const companyId = useParamId();
  const { t } = useTranslation();

  useEffect(
    () => {
      if (companyId) {
        dispatch(getOneCompany({ id: companyId }));
      }

      return function cleanup() {
        dispatch(cleanCurrentCompany());
      };
    },
    [dispatch, companyId]
  );

  const companyState = useSelector(state => state.companies);
  const company = companyState.current || null;

  const isFetchingCompany = companyState.loading;
  const isFetchingRecruiter = useSelector(state => state.recruiters).loading;
  const isFetchingOffer = useSelector(state => state.offers).loading;
  const isFetchingProposal = useSelector(state => state.commercialProposals)
    .loading;
  const isLoading =
    isFetchingCompany ||
    isFetchingRecruiter ||
    isFetchingOffer ||
    isFetchingProposal;

  return (
    <Main pageTitle={t('common.company')} isLoading={isLoading}>
      <CompanyDetail company={company} />
      {company && (
        <>
          <Grid container spacing={4}>
            <Grid md={12}>
              <Offers companyId={companyId} />
            </Grid>
            <Grid md={12}>
              <Invoices companyId={companyId} />
            </Grid>
            <Grid md={12}>
              <CommercialProposals companyId={companyId} />
            </Grid>
          </Grid>
        </>
      )}
    </Main>
  );
}

export default CompanyShow;
