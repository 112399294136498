import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { format, isValid } from 'date-fns';

import { getAdminOptions } from 'redux/formOptions/actions';

import { persistHistoryState } from 'utils/functions';
import { typeOptions } from './constants';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Filter from 'components/Filter';
import Autocomplete from 'components/core/input/Autocomplete';
import { Box, withStyles } from '@material-ui/core';
import S from '../styles';

function FilterFields(props) {
  const { index, classes } = props;

  const history = useHistory();
  const historyState = history.location.state ? history.location.state : {};
  const handleHistory = state => {
    persistHistoryState(state);
  };

  const formatDate = date => {
    return date ? format(date, 'dd/MM/yyyy') : null;
  };

  const [fromDate, setFromDate] = useState(
    index !== 'paid' && historyState.filter
      ? historyState.filter.fromDate
      : null
  );
  const [toDate, setToDate] = useState(
    index !== 'paid' && historyState.filter ? historyState.filter.toDate : null
  );

  const [fromPaymentDate, setFromPaymentDate] = useState(
    index === 'paid' && historyState.filter
      ? historyState.filter.fromPaymentDate
      : null
  );
  const [toPaymentDate, setToPaymentDate] = useState(
    index === 'paid' && historyState.filter
      ? historyState.filter.toPaymentDate
      : null
  );

  const [types, setTypes] = useState(
    historyState.filter ? historyState.filter.types : null
  );
  const [admin, setAdmin] = useState(
    historyState.filter ? historyState.filter.admin : null
  );

  const adminOptions = useSelector(state => state.formOptions.adminOptions);

  // Refresh rows when filter, pagination or order change
  const dispatch = useDispatch();
  const isInitialMount = useRef(true);
  useEffect(
    () => {
      if (isInitialMount.current) {
        dispatch(
          adminOptions
            ? null
            : getAdminOptions({
                onlyActive: 1,
              })
        );
        isInitialMount.current = false;
        return;
      }
      const filter = {
        fromDate: fromDate,
        toDate: toDate,
        fromPaymentDate: fromPaymentDate,
        toPaymentDate: toPaymentDate,
        types: types,
        admin: admin,
      };
      const formated = {
        fromDate: formatDate(fromDate),
        toDate: formatDate(toDate),
        fromPaymentDate: formatDate(fromPaymentDate),
        toPaymentDate: formatDate(toPaymentDate),
        types: types ? types.map(value => value.value).toString() : null,
        admin: admin ? admin.value : null,
      };

      handleHistory({ filter: filter, filterFormated: formated });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fromDate, toDate, fromPaymentDate, toPaymentDate, types, admin]
  );

  return (
    <Filter>
      <Box className={classes.fieldContainer}>
        <Box className={classes.column}>
          <Box className={classes.row}>
            <Autocomplete
              className={classes.selectField}
              defaultValue={admin}
              options={adminOptions}
              label="Administrateur"
              onChange={({ value }) => {
                setAdmin(value);
              }}
            />
          </Box>
          <Box className={classes.row}>
            <Autocomplete
              multi
              disableCloseOnSelect
              options={typeOptions}
              defaultValue={types}
              className={classes.selectField}
              label="Type"
              onChange={({ value }) => {
                setTypes(value);
              }}
            />
          </Box>
          <Box className={classes.row}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {index !== 'paid' && (
                <>
                  <KeyboardDatePicker
                    className={classes.dateField}
                    disableToolbar
                    autoComplete="off"
                    variant="inline"
                    format="dd/MM/yyyy"
                    id="fromDate"
                    label="Depuis le"
                    value={fromDate}
                    onChange={value => {
                      if (value !== null && !isValid(value)) {
                        return;
                      }
                      setFromDate(value);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <KeyboardDatePicker
                    className={classes.dateField}
                    disableToolbar
                    autoComplete="off"
                    variant="inline"
                    format="dd/MM/yyyy"
                    id="toDate"
                    label="Jusqu'au"
                    value={toDate}
                    onChange={value => {
                      if (value !== null && !isValid(value)) {
                        return;
                      }
                      setToDate(value);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </>
              )}
              {index === 'paid' && (
                <>
                  <KeyboardDatePicker
                    className={classes.dateField}
                    disableToolbar
                    autoComplete="off"
                    variant="inline"
                    format="dd/MM/yyyy"
                    id="fromPaymentDate"
                    label="Depuis le"
                    value={fromPaymentDate}
                    onChange={value => {
                      if (value !== null && !isValid(value)) {
                        return;
                      }
                      setFromPaymentDate(value);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <KeyboardDatePicker
                    className={classes.dateField}
                    disableToolbar
                    autoComplete="off"
                    variant="inline"
                    format="dd/MM/yyyy"
                    id="toPaymentDate"
                    label="Jusqu'au"
                    value={toPaymentDate}
                    onChange={value => {
                      if (value !== null && !isValid(value)) {
                        return;
                      }
                      setToPaymentDate(value);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </>
              )}
            </MuiPickersUtilsProvider>
          </Box>
        </Box>
      </Box>
    </Filter>
  );
}

export default withStyles(S)(FilterFields);
