import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cleanCurrentPaf,
  getPrefilledPlacementByOffer,
} from 'redux/pafs/actions';

export const useFetchAvailablePlacement = ({ offerId }) => {
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(getPrefilledPlacementByOffer({ offerId }));
      return () => dispatch(cleanCurrentPaf());
    },
    [dispatch, offerId]
  );

  const placement = useSelector(state => state.pafs.current);

  return { placement };
};
