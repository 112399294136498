import { memo } from 'react';
import moment from 'moment-timezone';

import { Grid } from 'components/core/containers';
import { DateFilter } from 'components/core/FilterInput';
import { FilterAccordion } from 'components/core/Accordion';

const Filters = () => {
  return (
    <FilterAccordion>
      <Grid container>
        <Grid md={3} sm={6}>
          <DateFilter
            label="Début"
            name="start"
            defaultValue={defaultStartDate}
          />
        </Grid>
        <Grid md={3} sm={6}>
          <DateFilter label="Fin" name="end" defaultValue={defaultEndDate} />
        </Grid>
      </Grid>
    </FilterAccordion>
  );
};

export default memo(Filters);

const defaultStartDate = moment().startOf('month');
const defaultEndDate = moment().endOf('month');
