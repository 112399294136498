import { useDispatch } from 'react-redux';
import {
  appointmentRequest,
  switchApplicationState,
  notifyResumeUpdateRequired,
  notifyApplicationContactAttempt,
  addApplication,
  suggestOffer,
  addApplicationComment,
  updateApplicationComment,
  deleteApplicationComment,
  updateResume,
  updateCoverLetter,
} from 'redux/candidates/actions';
import { FileField } from 'components/core/input';
import { Grid } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import ActionButton from 'components/ActionButton';
import ApplicationComment from 'components/eiquem/application/ApplicationComment';
import ApplicationState from 'components/eiquem/application/ApplicationState';

function RowDetails(props) {
  const dispatch = useDispatch();

  const researchId = props.researchId;

  const applicationId = props.row.applicationId;
  const application = applicationId ? props.row : null;

  const suggestionId = props.row.suggestionId;
  const suggestion = suggestionId ? props.row : null;

  const offer = application?.offer ?? suggestion?.offer;
  const offerId = offer?.id;
  const planId = offer?.planId;

  const handleStateChange = (state, reason, prefilledPlacement) => {
    if (application) {
      dispatch(
        switchApplicationState({
          researchId,
          applicationId,
          code: state,
          reason: reason,
          prefilledPlacement,
        })
      );
    } else if (suggestion) {
      dispatch(addApplication(researchId, suggestion.offer.id, state));
    }
  };
  const handleContactAttempt = () => {
    if (application) {
      dispatch(
        notifyApplicationContactAttempt({
          researchId,
          applicationId: applicationId,
        })
      );
    }
  };
  const handleResumeUpdateRequest = () => {
    if (application) {
      dispatch(
        notifyResumeUpdateRequired({
          researchId,
          applicationId: applicationId,
        })
      );
    }
  };
  const handleSuggestOffer = () => {
    if (suggestion) {
      dispatch(
        suggestOffer({
          researchId,
          offerId: suggestion.offer.id,
        })
      );
    }
  };

  const handleAppointmentRequest = () => {
    if (application) {
      dispatch(appointmentRequest({ applicationId: applicationId }));
    }
  };

  const handleFileChange = ({ name, value }) => {
    const params = {
      applicationId,
      file: value,
      researchId,
    };
    switch (name) {
      case 'resume-input':
        dispatch(updateResume(params));
        break;
      case 'cover-letter-input':
        dispatch(updateCoverLetter(params));
        break;
      default:
    }
  };

  const handleRemoveCoverLetter = () => {
    const params = {
      applicationId,
      researchId,
      file: null,
    };
    dispatch(updateCoverLetter(params));
  };

  return (
    <Grid container spacing={4}>
      <Grid item md={12}>
        <ApplicationState
          application={application || null}
          suggestion={suggestion || null}
          onStateChange={handleStateChange}
          onContactAttempt={handleContactAttempt}
          onResumeUpdateRequest={handleResumeUpdateRequest}
          onSuggestOffer={handleSuggestOffer}
          onAppointmentRequest={handleAppointmentRequest}
          offerId={offerId}
          planId={planId}
        />
      </Grid>
      {application && (
        <>
          <Grid item md={10} xs={12}>
            <ApplicationComment
              researchId={props.researchId}
              applicationId={application.applicationId}
              values={application.comments}
              onAdd={addApplicationComment}
              onUpdate={updateApplicationComment}
              onDelete={deleteApplicationComment}
            />
          </Grid>
          <Grid md={2}>
            <FileField
              label="Mise à jour CV"
              name="resume-input"
              onChange={handleFileChange}
            />
            <div style={{ display: 'flex' }}>
              <div>
                <FileField
                  label="Mise à jour LM"
                  name="cover-letter-input"
                  onChange={handleFileChange}
                />
              </div>
              <div
                style={{
                  alignSelf: 'end',
                  marginBottom: 2,
                  marginLeft: -40,
                }}
              >
                <ActionButton
                  action={handleRemoveCoverLetter}
                  label="Supprimer LM"
                  dialog
                  title="Suppression de la lettre de motivation"
                  contentText="Souhaites-tu vraiment effectuer cette action ?"
                  confirmText="Oui"
                  cancelText="Non"
                  icon={<Clear />}
                />
              </div>
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default RowDetails;
