import { ACTIONS_TYPE, getCreditNotes } from './actions';
import { ROUTES } from 'utils/routes';
import { pushRedirection } from 'utils/functions';

export const creditNotesMiddleware = ({ dispatch }) => next => action => {
  if (!action) {
    return;
  }
  if (action.type === ACTIONS_TYPE.SEND_CREDIT_NOTE_SUCCESS) {
    console.log('creditNotesMiddleware called...');
    console.log('get creditNotes...');
    dispatch(getCreditNotes({}));
  } else if (
    action.type === ACTIONS_TYPE.CREATE_CREDIT_NOTE_SUCCESS ||
    action.type === ACTIONS_TYPE.UPDATE_CREDIT_NOTE_SUCCESS
  ) {
    console.log('creditNotesMiddleware called...');
    console.log('redirect to previous url...');

    const expectedPaths = [ROUTES.CREDIT_NOTES_LIST, ROUTES.INVOICES_LIST];
    pushRedirection(expectedPaths);
  }

  next(action);
};
