import styled from 'styled-components';
import Tab from './tab';
import { theme } from 'utils/theme';

const VerticalTab = styled(Tab)`
  min-height: 50px;
  border: 0px;
  border-right: 2px solid
    ${props => (props.active ? theme.palette.primary.variant : 'transparent')};
`;

export default VerticalTab;
