import { useTranslation } from 'react-i18next';

import { workTypeId } from 'utils/constants';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, TextField } from '@material-ui/core';
import { Subtitle } from '../subtitle';

export default function WorkIndexation(props) {
  const {
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    classes,
    getFormOptions,
  } = props;

  const { t } = useTranslation();
  const {
    domainOptions,
    contractOptions,
    levelOptions,
    experienceOptions,
    functionOptions,
  } = getFormOptions();

  return (
    <Box>
      {domainOptions &&
        contractOptions &&
        levelOptions &&
        experienceOptions && (
          <Box>
            <Subtitle label="Indexation Emploi" />
            <Box className={classes.row}>
              <Autocomplete
                multiple
                id="workDomains"
                className={classes.selectLargeField}
                options={domainOptions[workTypeId]}
                getOptionLabel={option => option.label}
                groupBy={option => option.groupLabel}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    id="workDomains"
                    variant="standard"
                    label={t('jobboards-posting.field.workDomains')}
                    fullWidth
                    error={touched.workDomains && Boolean(errors.workDomains)}
                    helperText={touched.workDomains ? errors.workDomains : ''}
                  />
                )}
                onChange={(event, value) => {
                  setFieldValue('workDomains', value);
                  setFieldTouched('workDomains', true, false);
                }}
              />
            </Box>
            <Box className={classes.row}>
              <Autocomplete
                multiple
                id="workLevels"
                className={classes.selectLargeField}
                options={levelOptions}
                getOptionLabel={option => option.label}
                groupBy={option => option.groupLabel}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    id="workLevels"
                    variant="standard"
                    label={t('jobboards-posting.field.workLevels')}
                    fullWidth
                    error={touched.workLevels && Boolean(errors.workLevels)}
                    helperText={touched.workLevels ? errors.workLevels : ''}
                  />
                )}
                onChange={(event, value) => {
                  setFieldValue('workLevels', value);
                  setFieldTouched('workLevels', true, false);
                }}
              />
            </Box>
            <Box className={classes.row}>
              <Autocomplete
                multiple
                id="workFunctions"
                className={classes.selectLargeField}
                options={functionOptions}
                getOptionLabel={option => option.label}
                groupBy={option => option.groupLabel}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    id="workFunctions"
                    variant="standard"
                    label={t('jobboards-posting.field.workFunctions')}
                    fullWidth
                    error={
                      touched.workFunctions && Boolean(errors.workFunctions)
                    }
                    helperText={
                      touched.workFunctions ? errors.workFunctions : ''
                    }
                  />
                )}
                onChange={(event, value) => {
                  setFieldValue('workFunctions', value);
                  setFieldTouched('workFunctions', true, false);
                }}
              />
            </Box>
            <Box className={classes.row}>
              <Autocomplete
                multiple
                id="workContracts"
                className={classes.selectLargeField}
                options={contractOptions[workTypeId]}
                getOptionLabel={option => option.label}
                groupBy={option => option.groupLabel}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    id="workContracts"
                    variant="standard"
                    label={t('jobboards-posting.field.workContracts')}
                    fullWidth
                    error={
                      touched.workContracts && Boolean(errors.workContracts)
                    }
                    helperText={
                      touched.workContracts ? errors.workContracts : ''
                    }
                  />
                )}
                onChange={(event, value) => {
                  setFieldValue('workContracts', value);
                  setFieldTouched('workContracts', true, false);
                }}
              />
            </Box>
            <Box className={classes.row}>
              <Autocomplete
                multiple
                id="workExperiences"
                className={classes.selectLargeField}
                options={experienceOptions}
                getOptionLabel={option => option.label}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    id="workExperiences"
                    variant="standard"
                    label={t('jobboards-posting.field.workExperiences')}
                    fullWidth
                    error={
                      touched.workExperiences && Boolean(errors.workExperiences)
                    }
                    helperText={
                      touched.workExperiences ? errors.workExperiences : ''
                    }
                  />
                )}
                onChange={(event, value) => {
                  setFieldValue('workExperiences', value);
                  setFieldTouched('workExperiences', true, false);
                }}
              />
            </Box>
          </Box>
        )}
    </Box>
  );
}
