import type { Theme } from 'utils/types';
import {
  lightBlueFontCode,
  redFontCode,
  darkBlueFontCode,
  greyFontCode,
  greenFontCode,
  yellowFontCode,
  orangeFontCode,
  purpleFontCode,
} from 'utils/constants';

export default (theme: Theme) => ({
  root: {
    width: '100%',
  },
  textField: {
    flexBasis: 200,
    width: '180px',
  },
  tableActions: {
    display: 'flex',
    textAlign: 'right',
    marginBottom: '10px',
  },
  margin: {
    margin: theme.spacing(1),
  },
  headerButtons: {
    marginTop: '10px',
    marginRight: '10px',
    marginLeft: '10px',
    alignSelf: 'flex-end',
    display: 'flex',
  },
  headerButton: {
    color: 'black',
    backgroundColor: 'transparent !important',
    marginTop: '2px',
  },
  createButton: {
    marginTop: '3px',
  },
  transparentBC: {
    backgroundColor: 'transparent !important',
  },
  tableCell: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingRight: 5,
    paddingLeft: 5,
    height: 35,
  },
  actionsCell: {
    borderRight: '1px solid darkGray',
  },
  actionWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lightBlueFont: {
    backgroundColor: lightBlueFontCode,
  },
  redFont: {
    backgroundColor: redFontCode,
  },
  darkBlueFont: {
    backgroundColor: darkBlueFontCode,
  },
  greyFont: {
    backgroundColor: greyFontCode,
  },
  greenFont: {
    backgroundColor: greenFontCode,
  },
  yellowFont: {
    backgroundColor: yellowFontCode,
  },
  orangeFont: {
    backgroundColor: orangeFontCode,
  },
  purpleFont: {
    backgroundColor: purpleFontCode,
  },
  strikeThrough: {
    textDecoration: 'line-through',
  },
  expandedCell: {
    border: 'whitesmoke',
    borderWidth: '1px',
    borderStyle: 'solid',
  },
});
