import { fetch } from 'utils/api';
import { localStorageJwtKey, localStorageRefreshJwtKey } from 'utils/constants';
import { confirmLoginValidator } from 'utils/schemas';
import { actionRequest, actionSuccess, actionFailure } from 'utils/functions';
import services from 'utils/api/Services/services';

export const ACTIONS_TYPE = Object.freeze({
  PROCESS_LOGIN_BEGIN: 'PROCESS_LOGIN_BEGIN',
  PROCESS_LOGIN_SUCCESS: 'PROCESS_LOGIN_SUCCESS',
  PROCESS_LOGIN_FAILURE: 'PROCESS_LOGIN_FAILURE',
  LOGOUT: 'LOGOUT',
  PROCESS_REFRESH_TOKEN_BEGIN: 'PROCESS_REFRESH_TOKEN_BEGIN',
  PROCESS_REFRESH_TOKEN_SUCCESS: 'PROCESS_REFRESH_TOKEN_SUCCESS',
  PROCESS_REFRESH_TOKEN_FAILURE: 'PROCESS_REFRESH_TOKEN_FAILURE',
});

export const login = params => (dispatch, getState) =>
  fetch({
    service: services.auth.processLogin(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.PROCESS_LOGIN_BEGIN),
    actionSuccess: (result: any) => {
      localStorage.setItem(localStorageJwtKey, result.token);
      localStorage.setItem(localStorageRefreshJwtKey, result.refresh_token);
      return actionSuccess(ACTIONS_TYPE.PROCESS_LOGIN_SUCCESS, result);
    },
    actionFailure: errors => {
      localStorage.removeItem(localStorageJwtKey);
      localStorage.removeItem(localStorageRefreshJwtKey);
      return actionFailure(ACTIONS_TYPE.PROCESS_LOGIN_FAILURE, errors);
    },
    validator: confirmLoginValidator,
  });

export const refreshToken = params => (dispatch, getState) =>
  fetch({
    service: services.auth.processRefreshToken(params),
    dispatch,
    getState,
    actionRequest: () => {
      console.log('Refresh token begin');
      actionRequest(ACTIONS_TYPE.PROCESS_REFRESH_TOKEN_BEGIN);
    },
    actionSuccess: result => {
      console.log('Refresh token success');
      localStorage.setItem(localStorageJwtKey, result.token);
      localStorage.setItem(localStorageRefreshJwtKey, result.refresh_token);
      return actionSuccess(ACTIONS_TYPE.PROCESS_REFRESH_TOKEN_SUCCESS, result);
    },
    actionFailure: errors => {
      console.log('Refresh token failure');
      localStorage.removeItem(localStorageJwtKey);
      localStorage.removeItem(localStorageRefreshJwtKey);
      return actionFailure(ACTIONS_TYPE.PROCESS_REFRESH_TOKEN_FAILURE, errors);
    },
    validator: confirmLoginValidator,
  });

export type LogoutActionType = {|
  type: typeof ACTIONS_TYPE.LOGOUT,
|};

export const logout = () => dispatch => {
  localStorage.removeItem(localStorageJwtKey);
  localStorage.removeItem(localStorageRefreshJwtKey);
  return dispatch({ type: ACTIONS_TYPE.LOGOUT });
};
