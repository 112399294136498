import { greyFontCode } from 'utils/constants';
import { formatDate } from 'utils/functions';
import { ROUTES } from 'utils/routes';
import { getCandidates } from 'redux/candidates/actions';

export const columns = [
  {
    header: 'Candidat',
    accessors: ['candidateLink'],
    style: { fontWeight: 'bold', width: '15%' },
    type: 'link',
  },
  {
    header: 'École',
    accessors: ['school'],
    style: { width: '20%' },
  },
  {
    header: 'Type de Contrat',
    accessors: ['contractType'],
    style: { fontWeight: 'bold', width: '30%' },
  },
  {
    header: 'Début',
    accessors: ['startDate'],
    type: 'date',
    style: { width: '10%' },
  },
  {
    header: 'Durée',
    accessors: ['duration'],
    suffix: 'mois',
    style: { width: '5%' },
  },
  {
    header: 'Inscription',
    accessors: ['createDate'],
    type: 'date',
  },
  {
    header: 'Provenance',
    accessors: ['provenance'],
    style: { fontWeight: 'bold', width: '10%' },
  },
  {
    header: 'Profil',
    accessors: ['profile'],
    style: { textAlign: 'center' },
  },
  {
    header: 'CV',
    accessors: ['resumeLink'],
    type: 'link',
  },
];

export const format = candidates => {
  return candidates.map(candidate => ({
    ...candidate,
    resumeLink: {
      label: 'CV',
      url: candidate.resume,
      target: '_blank',
    },
    candidateLink: {
      label: candidate.name,
      url: ROUTES.CANDIDATE_SHOW.replace(':id', candidate.id),
    },
    backgroundColor: candidate.hasApplications ? null : greyFontCode,
  }));
};

export const fetchCandidates = (
  {
    page,
    pageSize,
    search,
    contractTypeIds,
    minStartDate,
    maxStartDate,
    minDuration,
    maxDuration,
    studyLevelIds,
    isRqth,
    schoolTypes,
    isCommented,
    isFinalYear,
    isGapYear,
    isActiveResearch,
    isNoResearch,
    schoolIds,
    profiles,
    functionIds,
    isNoApplication,
    minRegistrationDate,
    maxRegistrationDate,
  },
  dispatch,
  qs
) => {
  const minStart = isDef(minStartDate)
    ? minStartDate
    : qs.get('min-start-date');

  const maxStart = isDef(maxStartDate)
    ? maxStartDate
    : qs.get('max-start-date');

  const minRegistration = isDef(minRegistrationDate)
    ? minRegistrationDate
    : qs.get('min-registration-date');
  const maxRegistration = isDef(maxRegistrationDate)
    ? maxRegistrationDate
    : qs.get('max-registration-date');

  dispatch(
    getCandidates({
      page: page || qs.get('page'),
      size: pageSize || qs.get('page-size'),
      search: isDef(search) ? search : qs.get('search'),
      contractTypeIds: isDef(contractTypeIds)
        ? contractTypeIds
        : qs.getAll('contract-type-ids'),
      schoolTypes: isDef(schoolTypes) ? schoolTypes : qs.getAll('school-types'),
      minStartDate: minStart ? formatDate(minStart) : null,
      maxStartDate: maxStart ? formatDate(maxStart) : null,
      minDuration: isDef(minDuration) ? minDuration : qs.get('min-duration'),
      maxDuration: isDef(maxDuration) ? maxDuration : qs.get('max-duration'),
      studyLevelIds: isDef(studyLevelIds)
        ? studyLevelIds
        : qs.getAll('study-level-ids'),
      isRqth: isDef(isRqth) ? isRqth : qs.get('rqth'),
      isCommented: isDef(isCommented) ? isCommented : qs.get('comment'),
      isFinalYear: isDef(isFinalYear) ? isFinalYear : qs.get('final-year'),
      isGapYear: isDef(isGapYear) ? isGapYear : qs.get('gap-year'),
      isActiveResearch: isDef(isActiveResearch)
        ? isActiveResearch
        : qs.get('active-research'),
      isNoResearch: isDef(isNoResearch) ? isNoResearch : qs.get('no-research'),
      schoolIds: schoolIds || qs.getAll('school-ids'),
      profiles: profiles || qs.getAll('profiles'),
      functionIds: isDef(functionIds) ? functionIds : qs.getAll('function-ids'),
      isNoApplication: isDef(isNoApplication)
        ? isNoApplication
        : qs.get('no-application'),
      minRegistrationDate: minRegistration ? formatDate(minRegistration) : null,
      maxRegistrationDate: maxRegistration ? formatDate(maxRegistration) : null,
    })
  );
};

const isDef = value => typeof value !== 'undefined';
