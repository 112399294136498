// @flow
export const requiredError = (key: string, received: any): string =>
  `${key} is required but received ${String(received)}`;

export const stringError = (key: string, received: any): string =>
  `${key} should be a string but received ${String(received)}`;

export const numberError = (key: string, received: any): string =>
  `${key} should be a number but received ${String(received)}`;

export const positiveIntegerError = (key: string, received: any): string =>
  `${key} should be a positive integer but received ${String(received)}`;

export const booleanError = (key: string, received: any): string =>
  `${key} should be a boolean but received ${String(received)}`;

export const arrayError = (received: any): string =>
  `data should be an array but received ${String(received)}`;

export const emailError = (key: string, received: any): string =>
  `${key} should be a valid email but received ${String(received)}`;

export const utcDateError = (key: string, received: any): string =>
  `${key} should be a valid UTC date (YYYY-MM-DDThh:mm:ssX) but received ${String(
    received
  )}`;
