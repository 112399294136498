import styled from 'styled-components';
import { theme } from 'utils/theme';

export const Th = styled.th`
  height: 35px;
  text-align: left;
  background-color: #fafafa;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize + 1}px;
  ${props => props.hStyle};
  position: sticky;
  top: 0px;
  z-index: 1;
  white-space: nowrap;
`;
