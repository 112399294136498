import PropTypes from 'prop-types';
import { FilterAccordion } from 'components/core/Accordion';
import AdminFilters from 'components/eiquem/shared/AdminFilter/adminFilter';
import ContractTypeFilter from 'components/eiquem/shared/ContractTypeFilter';
import PlanFilter from 'components/eiquem/shared/PlanFilter';

Filter.propTypes = {
  name: PropTypes.func,
  keepFilters: PropTypes.bool,
};

export default function Filter({ onChange, keepFilters }) {
  return (
    <FilterAccordion>
      <ContractTypeFilter onChange={onChange} />
      <PlanFilter onChange={onChange} />
      <AdminFilters keepFilters={keepFilters} onChange={onChange} />
    </FilterAccordion>
  );
}
