import { useTranslation } from 'react-i18next';

import { workAndStudyTypeId } from 'utils/constants';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, TextField } from '@material-ui/core';

import { Subtitle } from '../subtitle';

export default function WorkAndStudyIndexation(props) {
  const {
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    classes,
    getFormOptions,
  } = props;

  const { t } = useTranslation();
  const { domainOptions } = getFormOptions();
  const { levelOptions } = getFormOptions();

  return (
    <Box>
      {domainOptions && (
        <Box>
          <Subtitle label="Indexation Alternance" />
          <Box className={classes.row}>
            <Autocomplete
              multiple
              id="workAndStudyDomains"
              className={classes.selectLargeField}
              options={domainOptions[workAndStudyTypeId]}
              getOptionLabel={option => option.label}
              groupBy={option => option.groupLabel}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              renderInput={params => (
                <TextField
                  {...params}
                  id="workAndStudyDomains"
                  variant="standard"
                  label={t('jobboards-posting.field.workAndStudyDomains')}
                  fullWidth
                  error={
                    touched.workAndStudyDomains &&
                    Boolean(errors.workAndStudyDomains)
                  }
                  helperText={
                    touched.workAndStudyDomains
                      ? errors.workAndStudyDomains
                      : ''
                  }
                />
              )}
              onChange={(event, value) => {
                setFieldValue('workAndStudyDomains', value);
                setFieldTouched('workAndStudyDomains', true, false);
              }}
            />
          </Box>
          <Box className={classes.row}>
            <Autocomplete
              multiple
              id="workAndStudyLevels"
              className={classes.selectLargeField}
              options={levelOptions}
              getOptionLabel={option => option.label}
              groupBy={option => option.groupLabel}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              renderInput={params => (
                <TextField
                  {...params}
                  id="workAndStudyLevels"
                  variant="standard"
                  label={t('jobboards-posting.field.workAndStudyLevels')}
                  fullWidth
                  error={
                    touched.workAndStudyLevels &&
                    Boolean(errors.workAndStudyLevels)
                  }
                  helperText={
                    touched.workAndStudyLevels ? errors.workAndStudyLevels : ''
                  }
                />
              )}
              onChange={(event, value) => {
                setFieldValue('workAndStudyLevels', value);
                setFieldTouched('workAndStudyLevels', true, false);
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
