import { useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/code';
import 'tinymce/plugins/help';
import 'tinymce/plugins/wordcount';

import { Editor } from '@tinymce/tinymce-react';
import Label from './Label';
import ImgInput from './ImgInput';

export default function RichTextEditor({
  label,
  htmlValue,
  handleChange,
  height,
}) {
  const editorRef = useRef(null);
  const initialValue = useRef(htmlValue);
  const debHandleChange = useRef(
    _.debounce(content => handleChange(content), 300)
  );

  const handleEditorChange = () => {
    if (editorRef.current) {
      const value = editorRef.current.getContent();
      debHandleChange.current(value);
    }
  };

  const handleInit = (evt, editor) => (editorRef.current = editor);

  return (
    <>
      <Label value={label} />
      <ImgInput />
      <Editor
        init={editorConfig(height)}
        onInit={handleInit}
        initialValue={initialValue.current}
        onEditorChange={handleEditorChange}
      />
    </>
  );
}

RichTextEditor.propTypes = {
  label: PropTypes.string,
  htmlValue: PropTypes.string,
  handleChange: PropTypes.func,
  height: PropTypes.number,
};

RichTextEditor.defaultProps = {
  label: null,
  htmlValue: null,
  handleChange: () => {},
  height: 500,
};

const editorConfig = height => ({
  height: height,
  menubar: false,
  plugins: plugins,
  toolbar: toolbar,
  content_style: contentStyle,
  file_picker_types: 'image',
  automatic_uploads: true,
  paste_data_images: true,
  block_formats: { blockFormats },
  file_picker_callback: function(callback, value, meta) {
    if (meta.filetype === 'image') {
      var input = document.getElementById('img-input');
      input.click();
      input.onchange = function() {
        var file = input.files[0];
        var reader = new FileReader();
        reader.onload = function(e) {
          callback(e.target.result, {
            alt: file.name,
          });
        };
        reader.readAsDataURL(file);
      };
    }
  },
});

const plugins = [
  'advlist autolink lists link image charmap print preview anchor',
  'searchreplace visualblocks code fullscreen',
  'insertdatetime media table paste code help wordcount',
];

const toolbar = `undo redo | formatselect | bold italic backcolor emoticons link image 
  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help`;

const contentStyle =
  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }';

const blockFormats = 'Paragraph=p;';
