import { useDispatch } from 'react-redux';

import {
  deleteResearchComment,
  updateResearchComments,
} from 'redux/candidates/actions';

import CommentFlow from 'components/eiquem/shared/CommentFlow';

function ResearchComments(props) {
  const { researchId, values } = props;
  const dispatch = useDispatch();

  const handleCommentChange = comment => {
    const params = {
      researchId: researchId,
      commentId: comment.id > 0 ? comment.id : null,
      adminId: comment.creator.value,
      message: comment.message,
    };

    switch (comment.state) {
      case 'remove':
        dispatch(deleteResearchComment(params));
        break;
      case 'add':
      case 'update':
        dispatch(updateResearchComments(params));
        break;
      default:
        console.log('default');
    }
  };

  return (
    <CommentFlow
      values={values}
      onChange={handleCommentChange}
      height={410}
      storageKey={`research${researchId}`}
      useRichInput
    />
  );
}

export default ResearchComments;
