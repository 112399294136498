import { useEffect } from 'react';
import { connect } from 'react-redux';

import { getAppVersion } from 'redux/app/actions';
import { emptyCache } from 'utils/functions';

const storeAppVersionWhenPageIsRealoaded = appVersion => {
  window.onbeforeunload = () => {
    localStorage.setItem('appVersion', appVersion);
  };
};

function AppVersion(props) {
  const { appVersion, getAppVersion, isAuthenticated } = props;

  const getAppVersionIfUserAuthenticated = () => {
    if (isAuthenticated === false) {
      return;
    }
    getAppVersion();
  };

  getAppVersionIfUserAuthenticated();
  useEffect(() => {
    const TEN_MINUTES_MS = 600000;
    const interval = setInterval(() => {
      getAppVersionIfUserAuthenticated();
    }, TEN_MINUTES_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  //storeAppVersionWhenPageIsRealoaded(appVersion);
  console.log(appVersion);
  console.log(process.env.REACT_APP_VERSION);
  if (appVersion !== process.env.REACT_APP_VERSION) {
    storeAppVersionWhenPageIsRealoaded(appVersion);
    emptyCache();
  }
  return null;
}

const mapStateToProps = state => ({
  appVersion: state.app.version,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = dispatch => ({
  getAppVersion: () => dispatch(getAppVersion()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppVersion);
