import { memo } from 'react';
import history from 'utils/history';
import { EditRounded } from '@material-ui/icons';
import ActionButton from 'components/ActionButton';

const icon = <EditRounded fontSize="small" />;

function EditButton(props) {
  const { pathname, id } = props;
  const historyState = history.location.state ? history.location.state : {};
  const link = {
    pathname: pathname.replace(':id', id),
    state: { ...historyState },
  };

  return <ActionButton label="Modifier" icon={icon} link={link} />;
}

export default memo(EditButton);
