import { useSelector, useDispatch } from 'react-redux';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';

import { createPaf } from 'redux/pafs/actions';

import Form from 'components/eiquem/placement/paf/Form';
import Main from 'components/layout/Main';
import Title from 'components/layout/Title';
import { Paper } from 'components/core/containers';

function PafCreatePage() {
  const { t } = useTranslation();

  const isLoading = useSelector(state => state.pafs.loading);
  const dispatch = useDispatch();

  const handleOnSubmit = values => {
    dispatch(createPaf(values));
  };

  return (
    <Main pageTitle={t('menu.recruitmentTitle')} isLoading={isLoading}>
      <Title>
        {t('paf.recruitment-start-date')}
        {' : '}
        {format(new Date(initialPaf.recruitmentStartDate), 'dd/MM/yyyy')}
      </Title>
      <Paper>
        <Form paf={initialPaf} onSubmit={handleOnSubmit} />
      </Paper>
    </Main>
  );
}

export const initialPaf = {
  abandonedOffer: false,
  admin: null,
  candidate: null,
  candidateStartDate: null,
  company: null,
  finalInternship: false,
  fixedCost: null,
  invoiceDate: null,
  offerInDifficulty: false,
  plan: null,
  pafDate: null,
  offer: null,
  contractType: null,
  contractDuration: null,
  recruitmentStartDate: new Date(),
  contractChangeDate: null,
  secondVariable: true,
  secondVariableDate: null,
  variableCost: null,
  variableCostHiring: null,
  completedOffer: null,
  duration: null,
  comments: '',
  vacantPositions: 1,
};

export default PafCreatePage;
