import { memo } from 'react';
import styled from 'styled-components';
import Item from 'components/core/Item';

export const Grid = styled.div`
  display: grid
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px
`;

function RowDetails(props) {
  const { row } = props;

  const placement = row;
  const candidate = placement.candidate ? placement.candidate.label : null;
  return (
    <Grid>
      <div>
        <Item label="Annonce Placement" value={placement.pafDate} type="date" />
        <Item label="Facturation" value={placement.invoiceDate} type="date" />
      </div>
      <div>
        <Item label="Candidat" value={candidate} />
        <Item
          label="Arrivée"
          value={placement.candidateStartDate}
          type="date"
        />
        <Item
          label="Fin d'Étude"
          value={placement.finalInternship}
          type="bool"
        />
      </div>
      <div>
        <Item
          label="Potentiel 2nd Variable"
          value={placement.secondVariableDate}
          type="date"
        />
        <Item
          label="Facturation"
          value={placement.contractChangeDate}
          type="date"
        />
      </div>
      <div>
        <Item
          label="En difficulté"
          value={placement.offerInDifficulty}
          type="bool"
        />
        <Item
          label="Suivi terminé"
          value={placement.completedOffer}
          type="bool"
        />
      </div>
    </Grid>
  );
}

export default memo(RowDetails);
