import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { interactWithCustomer } from 'redux/offers/actions';

import Label from 'components/core/Label';
import MailButton from './MailButton';
import ActionButton from './ActionButton';
import { InlineContainer } from 'components/core/containers';
import { Grid } from 'components/core/containers';

function OfferActions({ offer }) {
  const dispatch = useDispatch();

  const handleCustomerInteraction = action => {
    dispatch(interactWithCustomer({ id: offer.id, action: action }));
  };

  return (
    <Container>
      <Grid container>
        <Grid md={8} sm={12}>
          <Grid container>
            <Grid md={3}>
              <Label>AM</Label>
              <Grid container spacing={5}>
                <Grid md={12}>
                  <ActionButton
                    label="MV"
                    onClick={() => handleCustomerInteraction('outboundCall')}
                  />
                </Grid>
                <Grid md={12}>
                  <ActionButton
                    label="SMS"
                    onClick={() => handleCustomerInteraction('outboundSMS')}
                    variant="outlined"
                    color="primary"
                    style={{ width: '100%' }}
                  />
                </Grid>
                <Grid md={12}>
                  <ActionButton
                    label="Mail"
                    onClick={() => handleCustomerInteraction('outboundMail')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid md={3}>
              <Label>Client</Label>
              <Grid container spacing={5}>
                <Grid md={12}>
                  <ActionButton
                    label="Appel"
                    onClick={() => handleCustomerInteraction('inboundCall')}
                  />
                </Grid>
                <Grid md={12}>
                  <ActionButton
                    label="SMS"
                    onClick={() => handleCustomerInteraction('inboundSMS')}
                  />
                </Grid>
                <Grid md={12}>
                  <ActionButton
                    label="Mail"
                    onClick={() => handleCustomerInteraction('inboundMail')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid md={6}>
              <Label>Mails</Label>
              <Grid container spacing={5}>
                <Grid md={6}>
                  <MailButton
                    label="Bienvenue"
                    onClick={() => handleCustomerInteraction('welcome')}
                  />
                </Grid>
                <Grid md={6}>
                  <MailButton
                    label="Retour"
                    onClick={() => handleCustomerInteraction('feedbackRequest')}
                  />
                </Grid>
                <Grid md={6}>
                  <MailButton
                    label="Rapport"
                    onClick={() => handleCustomerInteraction('activeReport')}
                  />
                </Grid>
                <Grid md={6}>
                  <MailButton
                    label="Relance Retour"
                    onClick={() =>
                      handleCustomerInteraction('feedbackRequestRemind')
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

const Container = styled(InlineContainer)`
  margin-bottom: 15px;
`;

export default OfferActions;
