import { ACTIONS_TYPE } from './actions';

export const initialState = {
  loading: false,
  totalItemCount: 0,
  items: [],
  current: null,
  stats: {},
};

export function pafsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPE.GET_PAFS_BEGIN:
    case ACTIONS_TYPE.GET_PAF_BEGIN:
    case ACTIONS_TYPE.UPDATE_PAF_BEGIN:
    case ACTIONS_TYPE.DELETE_PAF_BEGIN:
    case ACTIONS_TYPE.CREATE_PAF_BEGIN:
    case ACTIONS_TYPE.CLONE_PAF_BEGIN:
    case ACTIONS_TYPE.GET_PAF_STATS_BEGIN:
    case ACTIONS_TYPE.GET_PREFILLED_PAF_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_PAFS_FAILURE:
    case ACTIONS_TYPE.GET_PAF_FAILURE:
    case ACTIONS_TYPE.UPDATE_PAF_FAILURE:
    case ACTIONS_TYPE.DELETE_PAF_FAILURE:
    case ACTIONS_TYPE.CREATE_PAF_FAILURE:
    case ACTIONS_TYPE.CLONE_PAF_FAILURE:
    case ACTIONS_TYPE.UPDATE_PAF_SUCCESS:
    case ACTIONS_TYPE.CREATE_PAF_SUCCESS:
    case ACTIONS_TYPE.CLONE_PAF_SUCCESS:
    case ACTIONS_TYPE.DELETE_PAF_SUCCESS:
    case ACTIONS_TYPE.GET_PAF_STATS_FAILURE:
    case ACTIONS_TYPE.GET_PREFILLED_PAF_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_PAFS_SUCCESS:
      return {
        ...state,
        loading: false,
        totalItemCount: action.result.totalItemCount,
        items: action.result.pafs,
      };
    case ACTIONS_TYPE.GET_PREFILLED_PAF_SUCCESS:
    case ACTIONS_TYPE.GET_PAF_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        loading: false,
        current: action.result,
      });
    }
    case ACTIONS_TYPE.CLEAN_CURRENT_PAF: {
      return Object.assign({}, state, {
        ...state,
        current: false,
      });
    }
    case ACTIONS_TYPE.CLEAN_PAFS: {
      return Object.assign({}, state, {
        ...state,
        items: [],
        stats: {},
      });
    }
    case ACTIONS_TYPE.GET_PAF_STATS_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        stats: action.result,
        loading: false,
      });
    }
    default:
      return state;
  }
}
