import { Box, Divider } from '@material-ui/core';
import JobStudentIndexation from 'pages/JobBoardsPosting/Letudiant/Form/IndexationFields/jobStudent';
import WorkIndexation from 'pages/JobBoardsPosting/Letudiant/Form/IndexationFields/work';
import InternshipIndexation from 'pages/JobBoardsPosting/Letudiant/Form/IndexationFields/internship';
import WorkAndStudyIndexation from 'pages/JobBoardsPosting/Letudiant/Form/IndexationFields/workAndStudy';

export default function IndexationFields(props) {
  const {
    classes,
    showStudentJobPart,
    showInternshipPart,
    showWorkPart,
    showWorkAndStudyPart,
  } = props;

  return (
    <Box>
      <Box className={classes.row}>
        {showInternshipPart && (
          <Box className={classes.column}>
            <InternshipIndexation {...props} />
          </Box>
        )}

        {showInternshipPart &&
          showWorkAndStudyPart && (
            <Box className={classes.row}>
              <Divider className={classes.divider} orientation="vertical" />
            </Box>
          )}
        {showWorkAndStudyPart && (
          <Box className={classes.column}>
            <WorkAndStudyIndexation {...props} />
          </Box>
        )}
      </Box>

      <Box className={classes.row} style={{ marginTop: '20px' }}>
        {showWorkPart && (
          <Box className={classes.column}>
            <WorkIndexation {...props} />
          </Box>
        )}
        {showWorkPart &&
          showStudentJobPart && (
            <Box className={classes.row}>
              <Divider className={classes.divider} orientation="vertical" />
            </Box>
          )}
        {showStudentJobPart && (
          <Box className={classes.column}>
            <JobStudentIndexation {...props} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
