import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ROLES } from 'utils/roles';
import moment from 'moment-timezone';
import Label from 'components/core/Label';
import Resizable from 'components/core/Resizable';
import Divider from 'components/core/Divider';
import AddButton from './AddButton';
import Comment from './Comment';
import { useRef } from 'react';
import useLocalStorage from 'utils/hooks/localStorage';

CommentFlow.defaultProps = {
  height: '100%',
  requireSuperAdmin: false,
  allowOtherToUpdate: false,
  useRichInput: false,
  label: 'Commentaires internes',
};

function CommentFlow(props) {
  const {
    values,
    onChange,
    height,
    requireSuperAdmin,
    allowOtherToUpdate,
    useRichInput,
    label,
    storageKey,
  } = props;

  const authState = useSelector(state => state.auth);

  const [storedComments, setStoredComments] = useLocalStorageComments(
    storageKey
  );

  const [initialValues, setInitialValues] = useState(values);
  const [comments, setComments] = useState(storedComments ?? values);
  const [itemEdited, setItemEdited] = useState(null);

  useEffect(
    () => {
      values.forEach(value => {
        if (!initialValues.find(initialValue => initialValue.id === value.id)) {
          setComments(values);
          setInitialValues(values);
        }
      });
    },
    // eslint-disable-next-line
    [values]
  );

  const handleChange = ({ id, value }) => {
    setStoredComments(
      comments.map(comment => {
        if (comment.id === id) {
          comment.message = value;
        }
        return comment;
      })
    );
  };

  const handleEdit = id => {
    setItemEdited(id);
  };

  const handleCancel = id => {
    setItemEdited(null);
    if (Math.sign(id) === -1) {
      comments.shift();
    }
    setStoredComments(null);
  };

  const handleConfirm = (id, value) => {
    setItemEdited(null);
    setComments([
      ...comments.map(comment => {
        if (comment.id === id) {
          comment.message = value;
          comment.state = comment.state === 'add' ? comment.state : 'update';
          onChange(comment);
        }
        return comment;
      }),
    ]);
    setStoredComments(null);
  };

  const handleRemove = id => {
    comments.forEach(comment => {
      if (comment.id === id) {
        comment.state = 'remove';
        onChange(comment);
      }
    });
    setComments(comments.filter(comment => comment.id !== id));
    setStoredComments(null);
  };

  const handleAddLine = () => {
    const newComment = {
      id: -Math.round(Math.random() * 100000),
      date: moment().format(),
      creator: {
        value: authState.userId,
        label: authState.userName,
        isAdmin: true,
      },
      message: null,
      state: 'add',
    };

    setComments([...comments, newComment]);
    handleEdit(newComment.id);
  };

  const isAllowedToModify = () => {
    if (requireSuperAdmin) {
      return authState.userRoles.includes(ROLES.SUPER_ADMIN);
    }
    return true;
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Label>{label}</Label>
        {itemEdited === null && (
          <AddButton
            isAllowToAdd={isAllowedToModify()}
            onAddLine={handleAddLine}
          />
        )}
      </div>
      <Resizable height={height} width={Infinity}>
        {comments
          .slice(0)
          .reverse()
          .map((comment, idx) => {
            return comment.state === 'remove' ? (
              <div key={`wrapper-${comment.id}`} />
            ) : (
              <div key={`wrapper-${comment.id}`}>
                <Comment
                  comment={comment}
                  onEdit={handleEdit}
                  onCancel={handleCancel}
                  onConfirm={handleConfirm}
                  onRemove={handleRemove}
                  onChange={handleChange}
                  editMode={comment.id === itemEdited}
                  isEditable={
                    isAllowedToModify() &&
                    comment.creator &&
                    comment.creator.isAdmin &&
                    (comment.creator.value === authState.userId ||
                      allowOtherToUpdate)
                  }
                  useRichInput={useRichInput}
                />
                {idx !== comments.length - 1 &&
                  comment.id !== itemEdited && <Divider />}
              </div>
            );
          })}
      </Resizable>
    </div>
  );
}

export default CommentFlow;

function useLocalStorageComments(defaultKey) {
  const [key] = useState(defaultKey ?? new Date().getTime());
  const [localData, setLocalData] = useLocalStorage('comments', null);

  const mount = useRef(false);
  useEffect(
    () => {
      // keep comments younger than 30 sec
      const cleanComments = () => {
        if (localData) {
          const cleanData = {};
          Object.entries(localData).forEach(([key, meta]) => {
            if (new Date() - new Date(meta.date) < 30 * 1000) {
              cleanData[key] = meta;
            }
          });
          setLocalData(cleanData);
        }
      };

      if (mount.current === false) {
        cleanComments();
      }
      mount.current = true;
    },
    [localData, setLocalData]
  );

  // get comments from local storage
  const getComments = () => {
    const commentsWitMeta = localData && localData[key];
    return commentsWitMeta ? commentsWitMeta.comments : null;
  };

  // persist comments in local storage
  const setComments = useCallback(comments => {
    const newCommentWithMeta = {
      date: new Date(),
      comments,
    };
    const newData = { ...localData };
    newData[key] = newCommentWithMeta;

    setLocalData(newData);
  }, []);

  return [getComments(), setComments];
}
