import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getRecruiters, cleanRecruiters } from 'redux/recruiters/actions';
import { ROUTES } from 'utils/routes';
import { recruiterTableColumns, getRecruiterRowsFormatted } from './helper';

import Table from 'components/core/Table';
import { InlineContainer } from 'components/core/containers';
import CreateButton from 'components/Table/Actions/CreateButton';

function RecruiterTable({ companyId }) {
  const dispatch = useDispatch();
  const recruiters = useSelector(state => state.recruiters.items);

  useEffect(
    () => {
      if (companyId) {
        dispatch(getRecruiters({ companyId: companyId }));
      }
      return () => dispatch(cleanRecruiters());
    },
    [dispatch, companyId]
  );

  const formatedRows = useMemo(() => getRecruiterRowsFormatted(recruiters), [
    recruiters,
  ]);

  return (
    <>
      <InlineContainer align="bottom">
        <div style={{ flex: 1 }} />
        <CreateButton
          pathname={ROUTES.RECRUITER_CREATE.replace(':companyId', companyId)}
        />
      </InlineContainer>
      <Table columns={recruiterTableColumns} rows={formatedRows} />
    </>
  );
}

export default RecruiterTable;
