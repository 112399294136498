import { createTheme } from '@material-ui/core/styles';

const theme = {
  primary: '#0c8e8f',
  palette: {
    primary: {
      main: '#000000',
      variant: '#0c8e8f',
    },
  },
  typography: {
    fontSize: 11,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '9px',
        border: '1px solid #dadde9',
        lineHeight: '10px',
      },
    },
    MuiBadge: {
      badge: {
        height: '12px',
        minWidth: '12px',
        padding: '0px',
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '15px',
      },
    },
    MuiIconButton: {
      sizeSmall: {
        padding: '0.01em',
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        paddingRight: 40,
        paddingTop: 3,
      },
    },
    MuiSlider: {
      marked: {
        marginBottom: -5,
      },
    },
    MuiTabs: {
      flexContainerVertical: {
        height: '100%',
        justifyContent: 'center',
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow:
          '0px 1px 3px -1px rgb(0 0 0 / 15%), 0px 0px 5px 0px rgb(0 0 0 / 25%), 10px 1px 10px 0px rgb(0 0 0 / 10%)',
      },
    },
  },
};

const muiTheme = createTheme(theme);

export { theme, muiTheme };
