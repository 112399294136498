import * as React from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

type Props = {
  +children?: React.Node,
  +value: number,
  +index: number,
};

export default function TabPanel(props: Props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box>{value === index && children}</Box>
    </Typography>
  );
}
