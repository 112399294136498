import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Td from '../Td';

ActionsCell.propTypes = {
  actions: PropTypes.func,
  row: PropTypes.object,
};

ActionsCell.defaultProps = {
  actions: null,
  row: null,
};

function ActionsCell({ actions, row }) {
  const Actions = actions;
  if (!actions) {
    return null;
  }
  return (
    <Td>
      <ActionWrapper>
        <Actions {...row} />
      </ActionWrapper>
    </Td>
  );
}

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default memo(ActionsCell);
