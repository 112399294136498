import { Redirect } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { login } from 'redux/auth/actions';

import { Formik } from 'formik';
import { LoginFormSchema } from 'utils/formSchemas';

import { ROUTES } from 'utils/routes';
import history from 'utils/history';

import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { Form } from './form';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const initialValues = { login: '', password: '' };

export default function SignIn() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  if (isAuthenticated) {
    const redirectPath = history.location.state
      ? history.location.state.from
      : ROUTES.OFFER_LIST;
    return <Redirect to={redirectPath} />;
  }

  const handleOnSubmit = values => {
    const params = { login: values.login, password: values.password };
    dispatch(login(params));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Formik
          validationSchema={LoginFormSchema}
          onSubmit={handleOnSubmit}
          initialValues={initialValues}
        >
          {props => <Form {...props} />}
        </Formik>
      </div>
    </Container>
  );
}
