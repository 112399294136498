import { ACTIONS_TYPE } from './actions';

export const initialState = {
  loading: false,
  totalItemCount: 0,
  items: [],
  current: null,
};

export function companiesReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPE.GET_COMPANIES_BEGIN:
    case ACTIONS_TYPE.CREATE_COMPANY_BEGIN:
    case ACTIONS_TYPE.GET_COMPANY_BEGIN:
    case ACTIONS_TYPE.UPDATE_COMPANY_BEGIN:
    case ACTIONS_TYPE.UPDATE_COMPANY_COMMENT_BEGIN:
    case ACTIONS_TYPE.DOWNLOAD_COMPANIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_COMPANIES_FAILURE:
    case ACTIONS_TYPE.CREATE_COMPANY_FAILURE:
    case ACTIONS_TYPE.CREATE_COMPANY_SUCCESS:
    case ACTIONS_TYPE.GET_COMPANY_FAILURE:
    case ACTIONS_TYPE.UPDATE_COMPANY_SUCCESS:
    case ACTIONS_TYPE.UPDATE_COMPANY_FAILURE:
    case ACTIONS_TYPE.UPDATE_COMPANY_COMMENT_SUCCESS:
    case ACTIONS_TYPE.UPDATE_COMPANY_COMMENT_FAILURE:
    case ACTIONS_TYPE.DOWNLOAD_COMPANIES_SUCCESS:
    case ACTIONS_TYPE.DOWNLOAD_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_COMPANIES_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        totalItemCount: action.result.totalItemCount,
        items: action.result.companies,
      });
    case ACTIONS_TYPE.GET_COMPANY_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        current: action.result,
      });
    case ACTIONS_TYPE.CLEAN_CURRENT_COMPANY:
      return Object.assign({}, state, {
        ...state,
        current: null,
      });
    default:
      return state;
  }
}
