import { TextField } from 'components/core/input';
import { useTranslation } from 'react-i18next';

export default function FixedPrice({ values, touched, errors, onChange }) {
  const { t } = useTranslation();

  return (
    <TextField
      name="fixedCost"
      label={t('paf.field.fixedCost')}
      touched={touched.fixedCost}
      error={errors.fixedCost}
      type={'number'}
      handleChange={onChange}
      value={values.fixedCost}
    />
  );
}
