import BaseButton from './baseButton';

export default function SuggestionButton(props) {
  const { value } = props;

  const msg = `Souhaites-tu SUGGÉRER cette offre au candidat ?
        Un mail sera envoyé au candidat et le statut de sa candidature sera mis à jour.`;

  return (
    <BaseButton
      label="Suggérer"
      message={msg}
      value={value}
      color="primary"
      action={props.action}
      disabled={props.disabled}
    />
  );
}
