import BaseButton from './baseButton';

export default function ResumeButton(props) {
  const { value } = props;

  const msg = `Souhaites-tu REFUSER cette candidature pour la raison CV REFUSÉ PAR L'ENTREPRISE ?
    Aucun mail ne sera envoyé au candidat mais le statut de sa candidature sera mis à jour.`;

  return (
    <BaseButton
      title="CV refusé par le client"
      label="CV"
      message={msg}
      value={value}
      color="secondary"
      action={props.action}
      disabled={props.disabled}
    />
  );
}
