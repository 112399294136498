import { useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { withStyles } from '@material-ui/core';
import IconButton from 'components/IconButton';

import S from './styles';

DropdownButton.defaultProps = {
  label: null,
  content: null,
  open: true,
};

function DropdownButton(props) {
  const [show, setShow] = useState(props.open);
  return (
    <>
      <div style={{ display: 'flex' }}>
        {props.label}
        <IconButton onClick={() => setShow(!show)}>
          {show ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </IconButton>
      </div>
      <div style={{ display: show ? '' : 'none' }}>{props.content}</div>
    </>
  );
}

export default withStyles(S)(DropdownButton);
