import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'utils/routes';
import {
  downloadInvoices,
  downloadOneInvoice,
  getInvoices,
} from 'redux/invoices/actions';
import { persistHistoryState } from 'utils/functions';

import { tableColumns, invoiceTableFormat } from './helper';

import Table from 'components/Table';
import CreateButton from 'components/Table/Actions/CreateButton';
import DownloadAllButton from 'components/Table/Actions/DownloadAllButton';
import FilterFields from './FilterFields';
import EditButton from 'components/Table/Actions/EditButton';
import DownloadOneButton from './DownloadOneButton';
import SendButton from './SendButton';
import RemindButton from './RemindButton';
import CreateCreditNoteButton from './CreateCreditNoteButton';
import IRPButton from './IRPButton';
import SetAsPaidButton from './SetAsPaidButton';
import RowDetails from './RowDetails';

InvoiceTable.defaultProps = {
  status: 'all',
  displayActions: true,
  companyId: null,
};

function InvoiceTable(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const historyState = history.location.state ? history.location.state : {};

  const invoiceState = useSelector(state => state.invoices);
  const invoices = invoiceState.items[props.status].items;
  const invoiceCount = invoiceState.items[props.status].totalItemCount;
  const isLoading = invoiceState.loading;

  useEffect(() => {
    persistHistoryState({ filterFormated: { companyId: props.companyId } });
    // eslint-disable-next-line
  }, []);

  const handleRefresh = ({
    page,
    size,
    filter = null,
    orderBy = null,
    order = null,
    fromDate = null,
    toDate = null,
    fromPaymentDate = null,
    toPaymentDate = null,
    types = null,
    admin = null,
  }) => {
    dispatch(
      getInvoices({
        page: page + 1,
        size: size,
        filter: filter,
        orderBy: orderBy,
        direction: order,
        fromDate: props.status !== 'paid' ? fromDate : null,
        toDate: props.status !== 'paid' ? toDate : null,
        fromPaymentDate: props.status === 'paid' ? fromPaymentDate : null,
        toPaymentDate: props.status === 'paid' ? toPaymentDate : null,
        types: types,
        admin: admin,
        invoiceStatus: props.status,
        companyId: props.companyId,
      })
    );
  };

  const handleDownloadAll = ({
    filter = null,
    orderBy = null,
    order = null,
    fromDate = null,
    toDate = null,
    fromPaymentDate = null,
    toPaymentDate = null,
    types = null,
    admin = null,
  }) => {
    dispatch(
      downloadInvoices({
        filter: filter,
        orderBy: orderBy,
        direction: order,
        fromDate: props.status !== 'paid' ? fromDate : null,
        toDate: props.status !== 'paid' ? toDate : null,
        fromPaymentDate: props.status === 'paid' ? fromPaymentDate : null,
        toPaymentDate: props.status === 'paid' ? toPaymentDate : null,
        types: types,
        admin: admin,
        state: props.status,
        companyId: props.companyId,
      })
    );
  };

  const handleDownloadOne = ({ id }) => {
    dispatch(
      downloadOneInvoice({
        id: id,
      })
    );
  };

  const generalActionComponents = [
    () => <CreateButton pathname={ROUTES.INVOICE_CREATE} />,
    () => (
      <DownloadAllButton
        action={handleDownloadAll}
        filter={historyState.filterFormated}
        search={historyState.search}
      />
    ),
  ];

  const actionComponents = [
    props => <EditButton {...props} pathname={ROUTES.INVOICE_EDIT} />,
    props => <DownloadOneButton {...props} action={handleDownloadOne} />,
    props => <SendButton row={props.row} />,
    props => <RemindButton row={props.row} />,
    props => (
      <CreateCreditNoteButton
        row={props.row}
        invoiceBusinessId={props.row.invoiceId}
      />
    ),
    props => <SetAsPaidButton invoice={props.row} />,
    props => <IRPButton invoice={props.row} />,
  ];

  return (
    <Table
      rows={invoiceTableFormat(invoices)}
      columns={tableColumns(t)}
      handleRefresh={handleRefresh}
      isSearchable={props.displayActions}
      isPaginatable
      isSortable
      generalActionComponents={props.displayActions && generalActionComponents}
      actionComponents={actionComponents}
      totalItemCount={invoiceCount}
      filterComponent={
        props.displayActions && <FilterFields index={props.status} />
      }
      expandComponent={<RowDetails />}
      noDataMsg="Aucune facture trouvée"
      isLoading={isLoading}
    />
  );
}

export default InvoiceTable;
