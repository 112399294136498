import cloneDeep from 'lodash/cloneDeep';

/**
 * Returns a copy of redux state.
 * If current candidate doesn't exist, then return temporary candidate from item list, to increase app dynamism
 * @param  {object} state
 * @param  {number} candidateId
 * @return {object}
 */
export const cloneCandidateState = (state, candidateId) => {
  if (state.current && state.current.id === candidateId) {
    return cloneDeep(state.current);
  }
  if (state.items) {
    return cloneDeep(state.items.find(item => item.id === candidateId));
  }

  return null;
};
