import { useTranslation } from 'react-i18next';
import { DatePicker } from 'components/core/input';

function RecruitmentStartDate(props) {
  const { values, onChange } = props;
  const { t } = useTranslation();

  return (
    <DatePicker
      name="recruitmentStartDate"
      label={t('paf.recruitment-start-date')}
      value={values.recruitmentStartDate}
      onChange={onChange}
    />
  );
}

export default RecruitmentStartDate;
