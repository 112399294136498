import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'utils/hooks/queryState';
import { switchApplicationState } from 'redux/offers/actions';
import {
  formatStringParameter,
  formatDateParameter,
  formatBoolParameter,
} from 'utils/functions';
import {
  getContractTypeOptions,
  getProfileOptions,
  getSchoolTypeOptions,
} from 'redux/formOptions/actions';
import { Grid } from 'components/core/containers';
import { FilterAccordion } from 'components/core/Accordion';
import {
  Autocomplete,
  Checkbox,
  DatePicker,
  TextField,
} from 'components/core/input';
import {
  Abandon,
  Refuse,
  Date,
  Contract,
  Duration,
  Competition,
  ShortList,
} from 'components/eiquem/application/ApplicationState/Buttons';

const Filters = ({ onChange, displayActions, cleanSelectedRows }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const qs = useQuery();
  const selectedIds = qs.getAll('rows');
  const contractTypes = useSelector(s => s.formOptions.contractTypeOptions);
  const profiles = useSelector(s => s.formOptions.profileOptions);
  const schoolTypes = useSelector(s => s.formOptions.schoolTypeOptions);
  const schoolLevels = useSelector(s => s.formOptions.schoolLevelOptions);

  useEffect(() => {
    dispatch(!contractTypes && getContractTypeOptions());
    dispatch(!profiles && getProfileOptions());
    dispatch(!schoolTypes && getSchoolTypeOptions());
  }, []);

  const didMount = useRef(false);
  useEffect(
    () => {
      if (didMount.current) {
        onChange(format(state));
        cleanSelectedRows();
      }
      didMount.current = true;
    },
    [state]
  );

  const handleChange = (field, value) => {
    const nextState = { ...state };
    nextState[field] = value;
    setState(nextState);
  };

  const switchState = (code, reason) => {
    dispatch(
      switchApplicationState({
        applicationIds: selectedIds,
        code: code,
        reason: reason,
      })
    );

    cleanSelectedRows();
  };

  const actionDisabled = selectedIds.length === 0;

  return (
    <FilterAccordion>
      <Grid container>
        <Grid md={3} sm={6}>
          <Autocomplete
            defaultValue={state.contractTypes}
            multi
            disableCloseOnSelect
            options={contractTypes}
            label="Types de Contrat"
            onChange={({ value }) => {
              handleChange('contractTypes', value);
            }}
          />
        </Grid>
        <Grid md={3} sm={6}>
          <Autocomplete
            defaultValue={state.profiles}
            multi
            disableCloseOnSelect
            options={profiles}
            label="Profils"
            onChange={({ value }) => {
              handleChange('profiles', value);
            }}
          />
        </Grid>

        <Grid md={3} sm={6}>
          <Autocomplete
            defaultValue={state.schoolTypes}
            multi
            disableCloseOnSelect
            options={schoolTypes}
            label="Types d'Établissement"
            onChange={({ value }) => {
              handleChange('schoolTypes', value);
            }}
          />
        </Grid>
        <Grid md={3} sm={6}>
          <Autocomplete
            defaultValue={state.schoolLevels}
            multi
            disableCloseOnSelect
            options={schoolLevels}
            label="Niveaux École"
            onChange={({ value }) => {
              handleChange('schoolLevels', value);
            }}
          />
        </Grid>
        <Grid md={3} sm={6}>
          <DatePicker
            label="Date de Début"
            value={state.minStartDate || null}
            onChange={({ value }) => handleChange('minStartDate', value)}
          />
        </Grid>
        <Grid md={3} sm={6}>
          <Checkbox
            label="Commentaire"
            checked={state.isCommented}
            onChange={({ value }) => {
              handleChange('isCommented', value);
            }}
          />
        </Grid>
        <Grid md={3} sm={6}>
          <Checkbox
            label="Recherche Active"
            checked={state.isActiveResearch}
            onChange={({ value }) => {
              handleChange('isActiveResearch', value);
            }}
          />
        </Grid>
        <Grid md={3} sm={6} />
        <Grid md={3} sm={6}>
          <TextField
            label="Durée Min"
            handleChange={({ value }) => {
              handleChange('minDuration', value);
            }}
            value={state.minDuration}
            endAdornment="mois"
            type="number"
          />
        </Grid>
        <Grid md={3} sm={6}>
          <TextField
            label="Durée Max"
            handleChange={({ value }) => {
              handleChange('maxDuration', value);
            }}
            value={state.maxDuration || 0}
            endAdornment="mois"
            type="number"
          />
        </Grid>
        {displayActions && (
          <>
            <Grid md={12} />
            <Grid md={6} sm={12}>
              <Grid container>
                <Grid md={6} sm={12}>
                  <Grid container>
                    <Grid md={6}>
                      <Abandon
                        disabled={actionDisabled}
                        action={() => switchState('abandoned')}
                      />
                    </Grid>
                    <Grid md={6}>
                      <Refuse
                        disabled={actionDisabled}
                        action={() => switchState('refused')}
                      />
                    </Grid>
                    <Grid md={6}>
                      <Date
                        disabled={actionDisabled}
                        action={() => switchState('refused', 'date')}
                      />
                    </Grid>
                    <Grid md={6}>
                      <Contract
                        disabled={actionDisabled}
                        action={() => switchState('refused', 'contract')}
                      />
                    </Grid>
                    <Grid md={6}>
                      <Duration
                        disabled={actionDisabled}
                        action={() => switchState('refused', 'duration')}
                      />
                    </Grid>
                    <Grid md={6}>
                      <Competition
                        disabled={actionDisabled}
                        action={() => switchState('refused', 'competition')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid md={6}>
                  <Grid container>
                    <Grid md={6}>
                      <ShortList
                        disabled={actionDisabled}
                        action={() => switchState('shortList')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </FilterAccordion>
  );
};

const format = state => ({
  contractTypes:
    state.contractTypes && state.contractTypes.map(type => type.value),
  minStartDate: formatDateParameter(state.minStartDate),
  minDuration: formatStringParameter(state.minDuration),
  maxDuration: formatStringParameter(state.maxDuration),
  isCommented: formatBoolParameter(state.isCommented),
  isActiveResearch: formatBoolParameter(state.isActiveResearch),
  schoolTypes: state.schoolTypes && state.schoolTypes.map(type => type.label),
  schoolLevels:
    state.schoolLevels && state.schoolLevels.map(level => level.label),
  profiles: state.profiles && state.profiles.map(profile => profile.label),
});

export default Filters;
