import { lazy, Suspense, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import DateFnsUtils from '@date-io/date-fns';
import NumberFormat from 'react-number-format';

import {
  getInvoicesTurnover,
  getFixedInvoices,
  getVariableInvoices,
  getInvoicesCount,
  getInvoicesCustomerType,
  getInvoicesSummary,
  getInvoicesActiveAmount,
} from 'redux/statistics/actions';

import {
  getMonthLabels,
  options,
  getDataSets,
} from 'pages/Statistics/constants';
import { tableColumns } from 'pages/Statistics/Invoices/constants';
import { isValidDate } from 'utils/functions';
import S from './styles';

import Main from 'components/layout/Main';
import Subtitle from 'components/layout/Subtitle';
import Table from 'components/Table';

import { Paper, Box, Typography, withStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePicker } from '@material-ui/pickers';

const Line = lazy(() => import('pages/Statistics/Line.js'));

function InvoicesStatistics(props) {
  const { classes } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(getInvoicesSummary());
      dispatch(getInvoicesActiveAmount());
    },
    [dispatch]
  );

  const [turnoverToDate, setTurnoverToDate] = useState(moment());
  const [turnoverFromDate, setTurnoverFromDate] = useState(
    turnoverToDate.clone().subtract(2, 'year')
  );
  const [turnoverLabels, setTurnoverLabels] = useState(
    getMonthLabels(turnoverFromDate, turnoverToDate)
  );
  useEffect(
    () => {
      if (isValidDate(turnoverFromDate) && isValidDate(turnoverToDate)) {
        dispatch(
          getInvoicesTurnover({
            fromDate: turnoverFromDate,
            toDate: turnoverToDate,
          })
        );
        setTurnoverLabels(getMonthLabels(turnoverFromDate, turnoverToDate));
      }
    },
    [turnoverFromDate, turnoverToDate, dispatch]
  );

  const [fixedToDate, setFixedToDate] = useState(moment());
  const [fixedFromDate, setFixedFromDate] = useState(
    fixedToDate.clone().subtract(2, 'year')
  );
  const [fixedLabels, setFixedLabels] = useState(
    getMonthLabels(fixedFromDate, fixedToDate)
  );
  useEffect(
    () => {
      if (isValidDate(fixedFromDate) && isValidDate(fixedToDate)) {
        dispatch(
          getFixedInvoices({
            fromDate: fixedFromDate,
            toDate: fixedToDate,
          })
        );
        setFixedLabels(getMonthLabels(fixedFromDate, fixedToDate));
      }
    },
    [fixedFromDate, fixedToDate, dispatch]
  );

  const [variableToDate, setVariableToDate] = useState(moment());
  const [variableFromDate, setVariableFromDate] = useState(
    variableToDate.clone().subtract(2, 'year')
  );
  const [variableLabels, setVariableLabels] = useState(
    getMonthLabels(variableFromDate, variableToDate)
  );
  useEffect(
    () => {
      if (isValidDate(variableFromDate) && isValidDate(variableToDate)) {
        dispatch(
          getVariableInvoices({
            fromDate: variableFromDate,
            toDate: variableToDate,
          })
        );
        setVariableLabels(getMonthLabels(variableFromDate, variableToDate));
      }
    },
    [variableFromDate, variableToDate, dispatch]
  );

  const [countToDate, setCountToDate] = useState(moment());
  const [countFromDate, setCountFromDate] = useState(
    countToDate.clone().subtract(2, 'year')
  );
  const [countLabels, setCountLabels] = useState(
    getMonthLabels(countFromDate, countToDate)
  );
  useEffect(
    () => {
      if (isValidDate(countFromDate) && isValidDate(countToDate)) {
        dispatch(
          getInvoicesCount({
            fromDate: countFromDate,
            toDate: countToDate,
          })
        );
        setCountLabels(getMonthLabels(countFromDate, countToDate));
      }
    },
    [countFromDate, countToDate, dispatch]
  );

  const [customerToDate, setCustomerToDate] = useState(moment());
  const [customerFromDate, setCustomerFromDate] = useState(
    customerToDate.clone().subtract(2, 'year')
  );
  const [customerLabels, setCustomerLabels] = useState(
    getMonthLabels(customerFromDate, customerToDate)
  );
  useEffect(
    () => {
      if (isValidDate(customerFromDate) && isValidDate(customerToDate)) {
        dispatch(
          getInvoicesCustomerType({
            fromDate: customerFromDate,
            toDate: customerToDate,
          })
        );
        setCustomerLabels(getMonthLabels(customerFromDate, customerToDate));
      }
    },
    [customerFromDate, customerToDate, dispatch]
  );

  const statisticsState = useSelector(state => state.statistics);

  const turnoverData = {
    labels: turnoverLabels,
    datasets: getDataSets(statisticsState.invoices.turnover),
  };
  const fixedData = {
    labels: fixedLabels,
    datasets: getDataSets(statisticsState.invoices.fixed),
  };
  const variableData = {
    labels: variableLabels,
    datasets: getDataSets(statisticsState.invoices.variable),
  };
  const countData = {
    labels: countLabels,
    datasets: getDataSets(statisticsState.invoices.count),
  };
  const customerData = {
    labels: customerLabels,
    datasets: getDataSets(statisticsState.invoices.customer),
  };

  const summaryData = statisticsState.invoices.summary;

  const tableData = summaryData
    ? Object.keys(summaryData).map(year => {
        return {
          year: year,
          offersCount: summaryData[year].offersCount || 0,
          candidateHiredCount: summaryData[year].candidateHiredCount || 0,
          invoicedAmount: summaryData[year].invoicedAmount || 0,
        };
      })
    : null;

  const activeAmount = statisticsState.invoices.activeAmount;
  return (
    <Main pageTitle={t('statistics.title')} isLoading={statisticsState.loading}>
      <Paper className={classes.root}>
        <Box>
          <Typography variant="h6" color="primary" gutterBottom>
            Factures
          </Typography>
          <Box>
            <Subtitle label="Sommaire par année" />
            <Box style={{ height: '100%', overflow: 'auto' }}>
              {tableData && (
                <Table size="500px" rows={tableData} columns={tableColumns} />
              )}
              {activeAmount && (
                <Box style={{ marginTop: '20px' }}>
                  Somme des offres en cours:{' '}
                  <b>
                    <NumberFormat
                      value={activeAmount}
                      thousandSeparator={' '}
                      suffix={'€'}
                      displayType={'text'}
                    />
                  </b>
                </Box>
              )}
            </Box>
          </Box>
          <Box>
            <Subtitle label="Évolution du CA par mois" />
            <Box className={classes.row}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="turnoverFromDate"
                  className={classes.dateField}
                  disableToolbar
                  autoComplete="off"
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Depuis le"
                  value={turnoverFromDate}
                  onChange={value => {
                    if (value) {
                      setTurnoverFromDate(moment(value));
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardDatePicker
                  id="turnoverToDate"
                  className={classes.dateField}
                  disableToolbar
                  autoComplete="off"
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Jusqu'au"
                  value={turnoverToDate}
                  onChange={value => {
                    if (value) {
                      setTurnoverToDate(moment(value));
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box style={{ height: 450 }}>
              <Line data={turnoverData} options={options} />
            </Box>

            <Subtitle label="Évolution des Facturations Fixes par mois" />
            <Box className={classes.row}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="fixedFromDate"
                  className={classes.dateField}
                  disableToolbar
                  autoComplete="off"
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Depuis le"
                  value={fixedFromDate}
                  onChange={value => {
                    if (value) {
                      setFixedFromDate(moment(value));
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardDatePicker
                  id="fixedToDate"
                  className={classes.dateField}
                  disableToolbar
                  autoComplete="off"
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Jusqu'au"
                  value={fixedToDate}
                  onChange={value => {
                    if (value) {
                      setFixedToDate(moment(value));
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box style={{ height: 450 }}>
              <Line data={fixedData} options={options} />
            </Box>
          </Box>

          <Subtitle label="Évolution des Facturations Variables par mois" />
          <Box className={classes.row}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="variableFromDate"
                className={classes.dateField}
                disableToolbar
                autoComplete="off"
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Depuis le"
                value={variableFromDate}
                onChange={value => {
                  if (value) {
                    setVariableFromDate(moment(value));
                  }
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                id="variableToDate"
                className={classes.dateField}
                disableToolbar
                autoComplete="off"
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Jusqu'au"
                value={variableToDate}
                onChange={value => {
                  if (value) {
                    setVariableToDate(moment(value));
                  }
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>
          <Box style={{ height: 450 }}>
            <Suspense>
              <Line data={variableData} options={options} />
            </Suspense>
          </Box>

          <Subtitle label="Évolution du Nombre de Facturations par mois" />
          <Box className={classes.row}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="countFromDate"
                className={classes.dateField}
                disableToolbar
                autoComplete="off"
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Depuis le"
                value={countFromDate}
                onChange={value => {
                  if (value) {
                    setCountFromDate(moment(value));
                  }
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                id="countToDate"
                className={classes.dateField}
                disableToolbar
                autoComplete="off"
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Jusqu'au"
                value={countToDate}
                onChange={value => {
                  if (value) {
                    setCountToDate(moment(value));
                  }
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>
          <Box style={{ height: 450 }}>
            <Line data={countData} options={options} />
          </Box>

          <Subtitle label="Évolution des Nouveaux Clients et Renouvellements par mois" />
          <Box className={classes.row}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="customerFromDate"
                className={classes.dateField}
                disableToolbar
                autoComplete="off"
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Depuis le"
                value={customerFromDate}
                onChange={value => {
                  if (value) {
                    setCustomerFromDate(moment(value));
                  }
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                id="customerToDate"
                className={classes.dateField}
                disableToolbar
                autoComplete="off"
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Jusqu'au"
                value={customerToDate}
                onChange={value => {
                  if (value) {
                    setCustomerToDate(moment(value));
                  }
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>
          <Box style={{ height: 450 }}>
            <Line data={customerData} options={options} />
          </Box>
        </Box>
      </Paper>
    </Main>
  );
}

export default withStyles(S)(InvoicesStatistics);
