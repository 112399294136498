import { useEffect } from 'react';
import PropTypes from 'prop-types';
import MultiAutocompleteFilter from 'components/core/FilterInput/multiAutocompleteFilter';
import { useFetchContractTypes } from 'utils/hooks/formOptions';

ContractTypeFilter.propTypes = {
  onChange: PropTypes.func,
};
ContractTypeFilter.defaultProps = {
  onChange: () => {},
};

export default function ContractTypeFilter({ onChange }) {
  const { contractTypes, handleChange } = useContractTypeFilter({ onChange });

  return (
    <>
      <MultiAutocompleteFilter
        name="contractTypes"
        label="Types de Contrats"
        options={contractTypes}
        onChange={handleChange}
        defaultValue={null}
      />
    </>
  );
}

function useContractTypeFilter({ onChange }) {
  const [fetchContractTypes, contractTypes] = useFetchContractTypes();
  useEffect(
    () => {
      if (!contractTypes) {
        fetchContractTypes();
      }
    },
    [contractTypes, fetchContractTypes]
  );

  const handleChange = ({ name, value }) => {
    const params = {};
    switch (name) {
      case 'contractTypes':
        params.contractTypeIds = value;
        break;
      default:
        throw new Error(`invalid param name: ${name}`);
    }
    onChange(params);
  };

  return {
    contractTypes,
    handleChange,
  };
}
