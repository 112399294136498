import styled from 'styled-components';
import { ColumnContainer, Grid, Box } from 'components/core/containers';
import Item from 'components/core/Item';
import Label from 'components/core/Label';

function OfferKpi({
  offer,
  externalWeeklyApplication,
  externalWeeklyApproach,
  externalWeeklyAcceptedApproach,
  externalWeeklyDeclinedApproach,
  externalWeeklyCall,
  onChange,
}) {
  const handleChange = ({ target: { name, value } }) => {
    onChange({ name, value });
  };

  const kpi = offer.kpi;

  return (
    <ColumnContainer>
      <Label>Statistiques</Label>
      <Box>
        <Grid container>
          <Grid md={2} sm={3}>
            <Label>
              <u>Dates</u>
            </Label>
            <Item label="Lancement" value={kpi.lifeSpanInDays} type="days" />
            <Item
              label="Dernier CC"
              value={kpi.lastCustomerContactDateInDays}
              type="days"
            />
            <Item
              label="Dernière SL"
              value={kpi.lastShortListDateInDays}
              type="days"
            />
            <Item
              label="Dernière diff"
              value={kpi.lastPostingDateInDays}
              type="days"
            />
            <Item
              label="1ère SL"
              value={kpi.firstShortListInDays}
              type="days"
            />
            <Item
              label="1er placement"
              value={kpi.firstPlacementInDays}
              type="days"
            />
          </Grid>

          <Grid md={2} sm={3}>
            <Label>
              <u>Candidatures</u>
            </Label>
            <Item
              label="Total"
              value={kpi.applicationCount + kpi.externalApplicationCount}
            />
            <Item label="NBO" value={kpi.applicationCount} />
            <Item label="Ext." value={kpi.externalApplicationCount} />
            <Item label="NBO sem." value={kpi.weeklyApplicationCount} />
            <Item
              label="Ext. sem."
              type="component"
              value={
                <Input
                  name="external-applications"
                  type="number"
                  value={externalWeeklyApplication}
                  onChange={handleChange}
                />
              }
            />
          </Grid>

          <Grid md={2} sm={3}>
            <Label>
              <u>Suggestions</u>
            </Label>
            <Item label="Total" value={kpi.suggestionCount} />
            <Item label="Acceptées" value={kpi.acceptedSuggestionCount} />
            <Item label="Déclinées" value={kpi.declinedSuggestionCount} />
            <Item
              label="Sans réponse"
              value={
                kpi.suggestionCount -
                kpi.declinedSuggestionCount -
                kpi.acceptedSuggestionCount
              }
            />
            <Item label="Total sem." value={kpi.weeklySuggestionCount} />
            <Item
              label="Acceptées sem."
              value={kpi.weeklyAcceptedSuggestionCount}
            />
            <Item
              label="Déclinées sem."
              value={kpi.weeklyDeclinedSuggestionCount}
            />
            <Item
              label="Sans réponse sem."
              value={
                kpi.weeklySuggestionCount -
                kpi.weeklyAcceptedSuggestionCount -
                kpi.weeklyDeclinedSuggestionCount
              }
            />
          </Grid>

          <Grid md={2} sm={3}>
            <Label>
              <u>Approches</u>
            </Label>
            <Item label="Total" value={kpi.externalApproachCount} />
            <Item label="Acceptées" value={kpi.externalAcceptedApproachCount} />
            <Item label="Déclinées" value={kpi.externalDeclinedApproachCount} />
            <Item
              label="Sans réponse"
              value={
                kpi.externalApproachCount -
                kpi.externalAcceptedApproachCount -
                kpi.externalDeclinedApproachCount
              }
            />

            <Item
              label="Total sem."
              type="component"
              value={
                <Input
                  type="number"
                  name="external-approaches"
                  value={externalWeeklyApproach}
                  onChange={handleChange}
                />
              }
            />
            <Item
              label="Acceptées sem."
              type="component"
              value={
                <Input
                  type="number"
                  name="external-accepted-approaches"
                  value={externalWeeklyAcceptedApproach}
                  onChange={handleChange}
                />
              }
            />
            <Item
              label="Déclinées sem."
              type="component"
              value={
                <Input
                  type="number"
                  name="external-declined-approaches"
                  value={externalWeeklyDeclinedApproach}
                  onChange={handleChange}
                />
              }
            />
          </Grid>

          <Grid md={2} sm={3}>
            <Label>
              <u>Appels</u>
            </Label>
            <Item label="Total" value={kpi.callCount + kpi.externalCallCount} />
            <Item label="NBO" value={kpi.callCount} />
            <Item label="Ext." value={kpi.externalCallCount} />
            <Item label="NBO sem." value={kpi.weeklyCallCount} />
            <Item
              label="Ext. sem."
              type="component"
              value={
                <Input
                  name="external-call"
                  type="number"
                  value={externalWeeklyCall}
                  onChange={handleChange}
                />
              }
            />
          </Grid>

          <Grid md={2} sm={3}>
            <Label>
              <u>Statuts candidatures</u>
            </Label>
            <Item label="Refusées" value={kpi.applicationRefusedCount} />
            <Item label="Abandonnées" value={kpi.applicationAbandonedCount} />
            <Item label="Envoyées" value={kpi.applicationSentCount} />

            <Item label="Entretiens" value={kpi.applicationInterviewCount} />
            <Item
              label="Propositions"
              value={kpi.applicationPropositionCount}
            />
            <Item
              label="Propositions refusées"
              value={kpi.applicationPropositionDeclinedCount}
            />
            <Item
              label="Refusées par l'entr."
              value={kpi.applicationDeclinedByCustomerCount}
            />
            <Item label="Pris" value={kpi.applicationHiredCount} />
          </Grid>
        </Grid>
      </Box>
    </ColumnContainer>
  );
}

const Input = styled.input`
  width: 100px;
`;

export default OfferKpi;
