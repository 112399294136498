import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQueryState, useQuery } from 'utils/hooks/queryState';
import { getPafSummary } from 'redux/pafSummary/actions';
import { monthTabs, currYear, currMonth, placementTabs } from './helper';

import Main from 'components/layout/Main';
import PageHeader from 'components/layout/PageHeader';
import HeaderDetail from './HeaderDetail';
import Tabs from 'components/core/Tabs';
import TabPanel from 'components/core/TabPanel';
import SummaryDetail from './Detail';

function Summary() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const qs = useQuery();
  const [placement, setPlacement] = useState(
    qs.get('placement') || placementTabs[0].key
  );

  const [month, setMonth] = useState(qs.get('month') || currMonth);

  const [year, setYear] = useQueryState(currYear, 'year');

  const pafSummaryState = useSelector(state => state.pafSummary);
  const {
    pafs,
    startings,
    contractUpdates,
    invoices,
    summary,
  } = pafSummaryState.summary;

  const pafState = useSelector(state => state.pafs);

  const handleTabChange = useCallback(
    ({ name, value }) => {
      switch (name) {
        case 'month':
          setMonth(value);
          break;
        case 'placement':
          setPlacement(value);
          break;
        default:
          throw new Error(`invalid tab name: ${name}`);
      }
    },
    [setMonth, setPlacement]
  );

  useEffect(
    () => {
      dispatch(
        getPafSummary({
          year: year,
          month: month,
        })
      );
    },
    [dispatch, pafState, year, month]
  );

  const PlacementPanel = () => (
    <TabPanel index="placement" value={placement}>
      <SummaryDetail
        month={month}
        setYear={setYear}
        year={year}
        rows={pafs}
        title={t('paf.summary.paf-title')}
        type="paf"
      />
    </TabPanel>
  );
  const StartingPanel = () => (
    <TabPanel index="starting" value={placement}>
      <SummaryDetail
        month={month}
        year={year}
        setYear={setYear}
        rows={startings}
        title={t('paf.summary.starting-title')}
        type="starting"
      />
    </TabPanel>
  );
  const InvoicingPanel = () => (
    <TabPanel index="invoiced" value={placement}>
      <SummaryDetail
        month={month}
        year={year}
        setYear={setYear}
        rows={invoices}
        title={t('paf.summary.invoiced-title')}
        type="invoiced"
      />
    </TabPanel>
  );

  const RenewedPanel = () => (
    <TabPanel index="renewed" value={placement}>
      <SummaryDetail
        month={month}
        year={year}
        setYear={setYear}
        rows={contractUpdates}
        title={t('paf.summary.renewed-title')}
        type="renewed"
      />
    </TabPanel>
  );

  return (
    <Main
      pageTitle={t('menu.recruitmentTitle')}
      isLoading={pafSummaryState.loading}
    >
      <div>
        <PageHeader title={t('paf.summary.title')}>
          <HeaderDetail summary={summary} />
        </PageHeader>

        <Tabs
          name="placement"
          direction="horizontal"
          tabs={placementTabs}
          onChange={handleTabChange}
        />
        <div style={{ display: 'flex' }}>
          <Tabs
            name="month"
            direction="vertical"
            tabs={monthTabs}
            defTab={month}
            onChange={handleTabChange}
          />
          <PlacementPanel />
          <StartingPanel />
          <InvoicingPanel />
          <RenewedPanel />
        </div>
      </div>
    </Main>
  );
}

export default Summary;
