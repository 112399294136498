import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { sendCreditNote } from 'redux/creditNotes/actions';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';

import S from '../styles';

function SendMailButton(props) {
  const { classes, row } = props;
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [sent, setSent] = useState(false);

  const dispatch = useDispatch();

  const handleConfirm = () => {
    let params = {
      id: row.id,
      sent: sent ? 1 : 0,
    };

    dispatch(sendCreditNote(params));
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Générer mail d'avoir">
        <IconButton
          size="small"
          className={classes.button}
          onClick={handleClickOpen}
        >
          <MailIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title">Personnalisation</DialogTitle>
        <DialogContent>
          <Box className={classes.row}>
            <FormControlLabel
              label="Marquer comme envoyé"
              control={
                <Checkbox
                  id="sent"
                  checked={sent}
                  onChange={event => {
                    setSent(event.target.checked);
                  }}
                  color="primary"
                />
              }
              className={classes.checkbox}
              labelPlacement="end"
            />
          </Box>
        </DialogContent>

        <DialogActions className={classes.actionFields}>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button
            //disabled={jobBoard === null}
            onClick={handleConfirm}
            color="primary"
          >
            Envoyer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withStyles(S)(SendMailButton);
