import styled from 'styled-components';

const InlineContainer = styled.div`
  display: flex;
  align-items:${props => (props.align === 'bottom' ? 'end' : 'baseline')}
  padding-top: 5px;
  padding-bottom: 5px;
  grid-column-gap: 5px;
  ${props => (props.maxHeight ? `max-height: ${props.maxHeight}px` : 'inherit')}
  align-items: stretch;
`;

export default InlineContainer;
