import { ACTIONS_TYPE } from './actions';
import { ROUTES } from 'utils/routes';
import { pushRedirection } from 'utils/functions';
import { cleanCurrentAdmin } from 'redux/admins/actions';

export const adminsMiddleware = ({ dispatch }) => next => action => {
  if (!action) {
    return;
  }
  if (
    action.type === ACTIONS_TYPE.UPDATE_ADMIN_SUCCESS ||
    action.type === ACTIONS_TYPE.CREATE_ADMIN_SUCCESS
  ) {
    const expectedPaths = [ROUTES.ADMIN_LIST];
    pushRedirection(expectedPaths);
    dispatch(cleanCurrentAdmin());
  }

  next(action);
};
