import { ACTIONS_TYPE } from './actions';

export const initialState = {
  loading: false,
  items: [],
  totalItemCount: 0,
  current: null,
};

export function creditNotesReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPE.GET_CREDIT_NOTES_BEGIN:
    case ACTIONS_TYPE.GET_CREDIT_NOTE_BEGIN:
    case ACTIONS_TYPE.CREATE_CREDIT_NOTE_BEGIN:
    case ACTIONS_TYPE.UPDATE_CREDIT_NOTE_BEGIN:
    case ACTIONS_TYPE.DELETE_CREDIT_NOTE_BEGIN:
    case ACTIONS_TYPE.DOWNLOAD_ONE_CREDIT_NOTE_BEGIN:
    case ACTIONS_TYPE.DOWNLOAD_CREDIT_NOTES_BEGIN:
    case ACTIONS_TYPE.SEND_CREDIT_NOTE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_CREDIT_NOTES_FAILURE:
    case ACTIONS_TYPE.GET_CREDIT_NOTE_FAILURE:
    case ACTIONS_TYPE.CREATE_CREDIT_NOTE_SUCCESS:
    case ACTIONS_TYPE.CREATE_CREDIT_NOTE_FAILURE:
    case ACTIONS_TYPE.UPDATE_CREDIT_NOTE_SUCCESS:
    case ACTIONS_TYPE.UPDATE_CREDIT_NOTE_FAILURE:
    case ACTIONS_TYPE.DELETE_CREDIT_NOTE_SUCCESS:
    case ACTIONS_TYPE.DELETE_CREDIT_NOTE_FAILURE:
    case ACTIONS_TYPE.DOWNLOAD_ONE_CREDIT_NOTE_SUCCESS:
    case ACTIONS_TYPE.DOWNLOAD_ONE_CREDIT_NOTE_FAILURE:
    case ACTIONS_TYPE.DOWNLOAD_CREDIT_NOTES_SUCCESS:
    case ACTIONS_TYPE.DOWNLOAD_CREDIT_NOTES_FAILURE:
    case ACTIONS_TYPE.SEND_CREDIT_NOTE_SUCCESS:
    case ACTIONS_TYPE.SEND_CREDIT_NOTE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_CREDIT_NOTES_SUCCESS: {
      return {
        ...state,
        loading: false,
        items: action.result.creditNotes,
        totalItemCount: action.result.totalItemCount,
      };
    }

    case ACTIONS_TYPE.GET_CREDIT_NOTE_SUCCESS: {
      return {
        ...state,
        loading: false,
        current: action.result,
      };
    }

    case ACTIONS_TYPE.CLEAN_CURRENT_CREDIT_NOTE: {
      return {
        ...state,
        current: null,
      };
    }

    default:
      return state;
  }
}
