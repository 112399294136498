export default () => ({
  inProgress: {
    color: 'green',
  },
  completed: {
    color: 'red',
  },
  standBy: {
    color: 'orange',
  },
  pending: {
    color: 'blue',
  },
});
