import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';

import { ROUTES } from 'utils/routes';

import { Tooltip, withStyles } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import S from '../styles';

function CreateCreditNoteButton(props) {
  const { classes, invoiceBusinessId } = props;
  const history = useHistory();
  const prevPath = history.location.pathname;

  return (
    <Tooltip title="Créer un avoir">
      <Link
        className={clsx(classes.link, classes.button)}
        to={{
          pathname: ROUTES.CREDIT_NOTE_CREATE.replace(
            ':invoiceBusinessId',
            invoiceBusinessId
          ),
          state: { prevPath: prevPath },
        }}
      >
        <ArrowUpwardIcon fontSize="small" />
      </Link>
    </Tooltip>
  );
}

export default withStyles(S)(CreateCreditNoteButton);
