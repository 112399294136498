import { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'utils/hooks/queryState';
import { deletePaf, getPafs, clonePaf, cleanPafs } from 'redux/pafs/actions';
import { ROUTES } from 'utils/routes';
import { tableColumns, legend, pafTableDataFormat } from './helper';

import { InlineContainer } from 'components/core/containers';
import { SearchFilter } from 'components/core/FilterInput';
import Legend from 'components/core/Legend';
import Table from 'components/core/Table';
import Pagination from 'components/core/Pagination';
import CreateButton from 'components/Table/Actions/CreateButton';
import EditButton from 'components/Table/Actions/EditButton';
import RemoveButton from 'components/Table/Actions/RemoveButton';
import CloneButton from './CloneButton/cloneButton';
import RowDetails from 'components/eiquem/placement/paf/Table/RowDetail/rowDetail';
import Filters from './Filters/filters';

const PafTable = () => {
  const dispatch = useDispatch();
  const qs = useQuery();

  const fetchPafs = ({
    page = parseInt(qs.get('page')),
    pageSize = parseInt(qs.get('page-size')),
    search = qs.get('search'),
    orderBy = null,
    order = null,
  }) => {
    dispatch(
      getPafs({
        page: page || 1,
        size: pageSize || 20,
        filter: search,
        orderBy: orderBy,
        direction: order,
      })
    );
  };

  const memoizedFetch = useCallback(
    params => fetchPafs(params),
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      fetchPafs({});
      return () => {
        dispatch(cleanPafs());
      };
    },
    // eslint-disable-next-line
    []
  );

  const handleDelete = id => {
    dispatch(deletePaf({ id: id }));
  };
  const handleClone = id => {
    dispatch(clonePaf({ id: id }));
  };
  const actionComponents = props => (
    <>
      <EditButton {...props} pathname={ROUTES.PAF_EDIT} />
      <RemoveButton {...props} action={handleDelete} />
      <CloneButton {...props} action={handleClone} />
    </>
  );
  const memoizedActions = useCallback(
    props => actionComponents(props),
    // eslint-disable-next-line
    []
  );

  const pafs = useSelector(state => state.pafs);

  const memoizedData = useMemo(() => pafTableDataFormat(pafs.items) || [], [
    pafs.items,
  ]);

  return (
    <>
      <InlineContainer align="bottom">
        <Filters />
        <SearchFilter onChange={fetchPafs} />
        <CreateButton pathname={ROUTES.PAF_CREATE} />
        <Legend items={legend} />
      </InlineContainer>
      <Table
        columns={tableColumns}
        rows={memoizedData}
        loading={pafs.loading}
        actions={memoizedActions}
        detail={RowDetails}
      />
      <Pagination itemCount={pafs.totalItemCount} onChange={memoizedFetch} />
    </>
  );
};

export default PafTable;
