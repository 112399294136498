import { fetch } from 'utils/api';
import services from 'utils/api/Services/services';
import { actionRequest, actionSuccess, actionFailure } from 'utils/functions';
import { candidatesArrayValidator } from 'utils/schemas';

export const ACTIONS_TYPE = Object.freeze({
  GET_CANDIDATES_BEGIN: 'GET_CANDIDATES_BEGIN',
  GET_CANDIDATES_SUCCESS: 'GET_CANDIDATES_SUCCESS',
  GET_CANDIDATES_FAILURE: 'GET_CANDIDATES_FAILURE',

  GET_CANDIDATE_BEGIN: 'GET_CANDIDATE_BEGIN',
  GET_CANDIDATE_SUCCESS: 'GET_CANDIDATE_SUCCESS',
  GET_CANDIDATE_FAILURE: 'GET_CANDIDATE_FAILURE',

  CLEAN_CURRENT_CANDIDATE: 'CLEAN_CURRENT_CANDIDATE',

  UPDATE_CANDIDATE_PROFILE_BEGIN: 'UPDATE_CANDIDATE_PROFILE_BEGIN',
  UPDATE_CANDIDATE_PROFILE_SUCCESS: 'UPDATE_CANDIDATE_PROFILE_SUCCESS',
  UPDATE_CANDIDATE_PROFILE_FAILURE: 'UPDATE_CANDIDATE_PROFILE_FAILURE',

  GET_RESEARCHES_BEGIN: 'GET_RESEARCHES_BEGIN',
  GET_RESEARCHES_SUCCESS: 'GET_RESEARCHES_SUCCESS',
  GET_RESEARCHES_FAILURE: 'GET_RESEARCHES_FAILURE',

  UPDATE_RESEARCH_BEGIN: 'UPDATE_RESEARCH_BEGIN',
  UPDATE_RESEARCH_SUCCESS: 'UPDATE_RESEARCH_SUCCESS',
  UPDATE_RESEARCH_FAILURE: 'UPDATE_RESEARCH_FAILURE',

  UPDATE_RESEARCH_COMMENTS_BEGIN: 'UPDATE_RESEARCH_COMMENTS_BEGIN',
  UPDATE_RESEARCH_COMMENTS_SUCCESS: 'UPDATE_RESEARCH_COMMENTS_SUCCESS',
  UPDATE_RESEARCH_COMMENTS_FAILURE: 'UPDATE_RESEARCH_COMMENTS_FAILURE',

  UPDATE_RESEARCH_APPLICATION_BEGIN: 'UPDATE_RESEARCH_APPLICATION_BEGIN',
  UPDATE_RESEARCH_APPLICATION_SUCCESS: 'UPDATE_RESEARCH_APPLICATION_SUCCESS',
  UPDATE_RESEARCH_APPLICATION_FAILURE: 'UPDATE_RESEARCH_APPLICATION_FAILURE',

  ACTIVE_RESEARCH_CONFIRMATION_BEGIN: 'ACTIVE_RESEARCH_CONFIRMATION_BEGIN',
  ACTIVE_RESEARCH_CONFIRMATION_SUCCESS: 'ACTIVE_RESEARCH_CONFIRMATION_SUCCESS',
  ACTIVE_RESEARCH_CONFIRMATION_FAILURE: 'ACTIVE_RESEARCH_CONFIRMATION_FAILURE',

  ANONYMIZE_CANDIDATE_BEGIN: 'ANONYMIZE_CANDIDATE_BEGIN',
  ANONYMIZE_CANDIDATE_SUCCESS: 'ANONYMIZE_CANDIDATE_SUCCESS',
  ANONYMIZE_CANDIDATE_FAILURE: 'ANONYMIZE_CANDIDATE_FAILURE',

  GET_RESEARCH_APPLICATIONS_BEGIN: 'GET_RESEARCH_APPLICATIONS_BEGIN',
  GET_RESEARCH_APPLICATIONS_SUCCESS: 'GET_RESEARCH_APPLICATIONS_SUCCESS',
  GET_RESEARCH_APPLICATIONS_FAILURE: 'GET_RESEARCH_APPLICATIONS_FAILURE',

  CLEAN_APPLICATIONS: 'CLEAN_APPLICATIONS',

  SUGGEST_OFFER_BEGIN: 'SUGGEST_OFFER_BEGIN',
  SUGGEST_OFFER_SUCCESS: 'SUGGEST_OFFER_SUCCESS',
  SUGGEST_OFFER_FAILURE: 'SUGGEST_OFFER_FAILURE',

  SWITCH_CANDIDATE_APPLICATION_BEGIN: 'SWITCH_CANDIDATE_APPLICATION_BEGIN',
  SWITCH_CANDIDATE_APPLICATION_SUCCESS: 'SWITCH_CANDIDATE_APPLICATION_SUCCESS',
  SWITCH_CANDIDATE_APPLICATION_FAILURE: 'SWITCH_CANDIDATE_APPLICATION_FAILURE',

  ADD_CANDIDATE_APPLICATION_BEGIN: 'ADD_CANDIDATE_APPLICATION_BEGIN',
  ADD_CANDIDATE_APPLICATION_SUCCESS: 'ADD_CANDIDATE_APPLICATION_SUCCESS',
  ADD_CANDIDATE_APPLICATION_FAILURE: 'ADD_CANDIDATE_APPLICATION_FAILURE',

  CANDIDATE_RESUME_UPDATE_BEGIN: 'CANDIDATE_RESUME_UPDATE_BEGIN',
  CANDIDATE_RESUME_UPDATE_SUCCESS: 'CANDIDATE_RESUME_UPDATE_SUCCESS',
  CANDIDATE_RESUME_UPDATE_FAILURE: 'CANDIDATE_RESUME_UPDATE_FAILURE',

  APPLICATION_CONTACT_ATTEMPT_BEGIN: 'APPLICATION_CONTACT_ATTEMPT_BEGIN',
  APPLICATION_CONTACT_ATTEMPT_SUCCESS: 'APPLICATION_CONTACT_ATTEMPT_SUCCESS',
  APPLICATION_CONTACT_ATTEMPT_FAILURE: 'APPLICATION_CONTACT_ATTEMPT_FAILURE',

  CANDIDATE_CONTACT_ATTEMPT_BEGIN: 'CANDIDATE_CONTACT_ATTEMPT_BEGIN',
  CANDIDATE_CONTACT_ATTEMPT_SUCCESS: 'CANDIDATE_CONTACT_ATTEMPT_SUCCESS',
  CANDIDATE_CONTACT_ATTEMPT_FAILURE: 'CANDIDATE_CONTACT_ATTEMPT_FAILURE',

  ADD_APPLICATION_COMMENT_BEGIN: 'ADD_CAND_APPLICATION_COMMENT_BEGIN',
  ADD_APPLICATION_COMMENT_SUCCESS: 'ADD_CAND_APPLICATION_COMMENT_SUCCESS',
  ADD_APPLICATION_COMMENT_FAILURE: 'ADD_CAND_APPLICATION_COMMENT_FAILURE',

  UPDATE_APPLICATION_COMMENT_BEGIN: 'UPDATE_CAND_APPLICATION_COMMENT_BEGIN',
  UPDATE_APPLICATION_COMMENT_SUCCESS: 'UPDATE_CAND_APPLICATION_COMMENT_SUCCESS',
  UPDATE_APPLICATION_COMMENT_FAILURE: 'UPDATE_CAND_APPLICATION_COMMENT_FAILURE',

  DELETE_APPLICATION_COMMENT_BEGIN: 'DELETE_CAND_APPLICATION_COMMENT_BEGIN',
  DELETE_APPLICATION_COMMENT_SUCCESS: 'DELETE_CAND_APPLICATION_COMMENT_SUCCESS',
  DELETE_APPLICATION_COMMENT_FAILURE: 'DELETE_CAND_APPLICATION_COMMENT_FAILURE',

  PLACEMENT_CONTACT_ATTEMPT_BEGIN: 'PLACEMENT_CONTACT_ATTEMPT_BEGIN',
  PLACEMENT_CONTACT_ATTEMPT_SUCCESS: 'PLACEMENT_CONTACT_ATTEMPT_SUCCESS',
  PLACEMENT_CONTACT_ATTEMPT_FAILURE: 'PLACEMENT_CONTACT_ATTEMPT_FAILURE',

  UPDATE_RESUME_BEGIN: 'UPDATE_RESUME_BEGIN',
  UPDATE_RESUME_SUCCESS: 'UPDATE_RESUME_SUCCESS',
  UPDATE_RESUME_FAILURE: 'UPDATE_RESUME_FAILURE',

  UPDATE_COVER_LETTER_BEGIN: 'UPDATE_COVER_LETTER_BEGIN',
  UPDATE_COVER_LETTER_SUCCESS: 'UPDATE_COVER_LETTER_SUCCESS',
  UPDATE_COVER_LETTER_FAILURE: 'UPDATE_COVER_LETTER_FAILURE',

  UPDATE_CANDIDATE_BEGIN: 'UPDATE_COVER_LETTER_BEGIN',
  UPDATE_CANDIDATE_SUCCESS: 'UPDATE_COVER_LETTER_SUCCESS',
  UPDATE_CANDIDATE_FAILURE: 'UPDATE_COVER_LETTER_FAILURE',

  APPOINTMENT_REQUEST_BEGIN: 'APPOINTMENT_REQUEST_BEGIN',
  APPOINTMENT_REQUEST_SUCCESS: 'APPOINTMENT_REQUEST_SUCCESS',
  APPOINTMENT_REQUEST_FAILURE: 'APPOINTMENT_REQUEST_FAILURE',

  ADD_CANDIDATE_BEGIN: 'ADD_CANDIDATE_BEGIN',
  ADD_CANDIDATE_SUCCESS: 'ADD_CANDIDATE_SUCCESS',
  ADD_CANDIDATE_FAILURE: 'ADD_CANDIDATE_FAILURE',

  GET_CANDIDATE_FOLLOW_UP_BEGIN: 'GET_CANDIDATE_FOLLOW_UP_BEGIN',
  GET_CANDIDATE_FOLLOW_UP_SUCCESS: 'GET_CANDIDATE_FOLLOW_UP_SUCCESS',
  GET_CANDIDATE_FOLLOW_UP_FAILURE: 'GET_CANDIDATE_FOLLOW_UP_FAILURE',

  UPDATE_CANDIDATE_FOLLOW_UP_BEGIN: 'UPDATE_CANDIDATE_FOLLOW_UP_BEGIN',
  UPDATE_CANDIDATE_FOLLOW_UP_SUCCESS: 'UPDATE_CANDIDATE_FOLLOW_UP_SUCCESS',
  UPDATE_CANDIDATE_FOLLOW_UP_FAILURE: 'UPDATE_CANDIDATE_FOLLOW_UP_FAILURE',

  REMIND_CANDIDATE_FOLLOW_UP_BEGIN: 'REMIND_CANDIDATE_FOLLOW_UP_BEGIN',
  REMIND_CANDIDATE_FOLLOW_UP_SUCCESS: 'REMIND_CANDIDATE_FOLLOW_UP_SUCCESS',
  REMIND_CANDIDATE_FOLLOW_UP_FAILURE: 'REMIND_CANDIDATE_FOLLOW_UP_FAILURE',
});

export const remindAndPostponeFollowUp = ({ id, candidateId }) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.followUps.remindAndPostpone({ id }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.REMIND_CANDIDATE_FOLLOW_UP_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.REMIND_CANDIDATE_FOLLOW_UP_SUCCESS, {
        candidateId,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.REMIND_CANDIDATE_FOLLOW_UP_FAILURE, errors),
  });

export const updateFollowUp = ({ id, followUpDate, isDone }) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.followUps.update({ id, followUpDate, isDone }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.UPDATE_CANDIDATE_FOLLOW_UP_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.UPDATE_CANDIDATE_FOLLOW_UP_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_CANDIDATE_FOLLOW_UP_FAILURE, errors),
  });

export const getPlacementFollowUp = ({ candidateId }) => (dispatch, getState) =>
  fetch({
    service: services.candidates.getPlacementFollowUp({
      candidateId,
    }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_CANDIDATE_FOLLOW_UP_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_CANDIDATE_FOLLOW_UP_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_CANDIDATE_FOLLOW_UP_FAILURE, errors),
  });

export const addCandidate = ({
  firstName,
  lastName,
  email,
  phone,
  provenance,
  schoolId,
  otherSchool,
  studyLevelId,
  resume,
  contractTypeId,
  startDate,
  duration,
  functionIds,
  finalYear,
  gapYear,
}) => (dispatch, getState) =>
  fetch({
    service: services.candidates.add({
      firstName,
      lastName,
      email,
      phone,
      provenance,
      schoolId,
      otherSchool,
      studyLevelId,
      resume,
      contractTypeId,
      startDate,
      duration,
      functionIds,
      finalYear,
      gapYear,
    }),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.ADD_CANDIDATE_BEGIN),
    actionSuccess: () => actionSuccess(ACTIONS_TYPE.ADD_CANDIDATE_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.ADD_CANDIDATE_FAILURE, errors),
  });

export const appointmentRequest = ({ applicationId }) => (dispatch, getState) =>
  fetch({
    service: services.applications.appointmentRequest({ applicationId }),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.APPOINTMENT_REQUEST_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.APPOINTMENT_REQUEST_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.APPOINTMENT_REQUEST_FAILURE, errors),
  });

export const updateCandidate = ({
  id,
  firstName,
  lastName,
  schoolId,
  otherSchool,
  studyLevelId,
  rqth,
  resume,
  provenance,
  phoneNumber,
}) => (dispatch, getState) =>
  fetch({
    service: services.candidates.update({
      id,
      firstName,
      lastName,
      schoolId,
      otherSchool,
      studyLevelId,
      rqth,
      resume,
      provenance,
      phoneNumber,
    }),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.UPDATE_COVER_LETTER_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.UPDATE_COVER_LETTER_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_COVER_LETTER_FAILURE, errors),
  });

export const updateCoverLetter = ({ applicationId, file, researchId }) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.applications.updateCoverLetter({ applicationId, file }),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.UPDATE_COVER_LETTER_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.UPDATE_COVER_LETTER_SUCCESS, { researchId }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_COVER_LETTER_FAILURE, errors),
  });

export const updateResume = ({ applicationId, file, researchId }) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.applications.updateResume({ applicationId, file }),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.UPDATE_RESUME_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.UPDATE_RESUME_SUCCESS, { researchId }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_RESUME_FAILURE, errors),
  });

export const deleteApplicationComment = ({ applicationId, commentId }) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.applications.deleteComment({
      applicationId,
      commentId,
    }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.DELETE_APPLICATION_COMMENT_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.DELETE_APPLICATION_COMMENT_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.DELETE_APPLICATION_COMMENT_FAILURE, errors),
  });

export const updateApplicationComment = ({
  applicationId,
  commentId,
  message,
}) => (dispatch, getState) =>
  fetch({
    service: services.applications.updateComment({
      applicationId,
      commentId,
      message,
    }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.UPDATE_APPLICATION_COMMENT_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.UPDATE_APPLICATION_COMMENT_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_APPLICATION_COMMENT_FAILURE, errors),
  });

export const addApplicationComment = ({
  applicationId,
  message,
  researchId,
}) => (dispatch, getState) =>
  fetch({
    service: services.applications.addComment({ applicationId, message }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.ADD_APPLICATION_COMMENT_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.ADD_APPLICATION_COMMENT_SUCCESS, {
        researchId: researchId,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.ADD_APPLICATION_COMMENT_FAILURE, errors),
  });

export const suggestOffer = ({ researchId, offerId }) => (dispatch, getState) =>
  fetch({
    service: services.candidates.suggestOffer(researchId, offerId),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.SUGGEST_OFFER_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.SUGGEST_OFFER_SUCCESS, {
        researchId: researchId,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.SUGGEST_OFFER_FAILURE, errors),
  });

export const notifyCandidateContactAttempt = ({ candidateId }) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.candidates.notifyContactAttempt({
      candidateId: candidateId,
    }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.CANDIDATE_CONTACT_ATTEMPT_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.CANDIDATE_CONTACT_ATTEMPT_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.CANDIDATE_CONTACT_ATTEMPT_FAILURE, errors),
  });

export const notifyApplicationContactAttempt = ({
  researchId = null,
  applicationId,
}) => (dispatch, getState) =>
  fetch({
    service: services.applications.notifyContactAttempt({
      applicationId: applicationId,
    }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.APPLICATION_CONTACT_ATTEMPT_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.APPLICATION_CONTACT_ATTEMPT_SUCCESS, {
        researchId: researchId,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.APPLICATION_CONTACT_ATTEMPT_FAILURE, errors),
  });

export const notifyResumeUpdateRequired = ({ researchId, applicationId }) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.applications.notifyResumeUpdateRequired({
      applicationId: applicationId,
    }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.CANDIDATE_RESUME_UPDATE_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.CANDIDATE_RESUME_UPDATE_SUCCESS, {
        researchId: researchId,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.CANDIDATE_RESUME_UPDATE_FAILURE, errors),
  });

export const switchApplicationState = ({
  researchId,
  applicationId,
  code,
  reason = null,
  prefilledPlacement = null,
}) => (dispatch, getState) =>
  fetch({
    service: services.applications.switchApplicationState({
      applicationId: applicationId,
      code: code,
      reason: reason,
      prefilledPlacement,
    }),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.SWITCH_CANDIDATE_APPLICATION_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.SWITCH_CANDIDATE_APPLICATION_SUCCESS, {
        researchId: researchId,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.SWITCH_CANDIDATE_APPLICATION_FAILURE, errors),
  });

export const cleanApplications = () => ({
  type: ACTIONS_TYPE.CLEAN_APPLICATIONS,
});

export const getApplications = researchId => (dispatch, getState) =>
  fetch({
    service: services.candidates.getApplications(researchId),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.GET_RESEARCH_APPLICATIONS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_RESEARCH_APPLICATIONS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_RESEARCH_APPLICATIONS_FAILURE, errors),
  });

export const anonymizeCandidate = candidateId => (dispatch, getState) =>
  fetch({
    service: services.candidates.anonymize(candidateId),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.ANONYMIZE_CANDIDATE_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.ANONYMIZE_CANDIDATE_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.ANONYMIZE_CANDIDATE_FAILURE, errors),
  });

export const activeResearchConfirmation = candidateId => (dispatch, getState) =>
  fetch({
    service: services.candidates.activeResearchConfirmation(candidateId),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.ACTIVE_RESEARCH_CONFIRMATION_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.ACTIVE_RESEARCH_CONFIRMATION_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.ACTIVE_RESEARCH_CONFIRMATION_FAILURE, errors),
  });

export const placementContactAttempt = (candidateId, period) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.candidates.placementContactAttempt(candidateId, period),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.PLACEMENT_CONTACT_ATTEMPT_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.PLACEMENT_CONTACT_ATTEMPT_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.PLACEMENT_CONTACT_ATTEMPT_FAILURE, errors),
  });

export const addApplication = (researchId, offerId, state = null) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.candidates.addApplication(researchId, offerId, state),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.ADD_CANDIDATE_APPLICATION_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.ADD_CANDIDATE_APPLICATION_SUCCESS, {
        researchId: researchId,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.ADD_CANDIDATE_APPLICATION_FAILURE, errors),
    validator: candidatesArrayValidator,
  });

export const updateApplicationStatus = params => (dispatch, getState) =>
  fetch({
    service: services.applications.updateOneApplicationStatus(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.UPDATE_RESEARCH_APPLICATION_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.UPDATE_RESEARCH_APPLICATION_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_RESEARCH_APPLICATION_FAILURE, errors),
    validator: candidatesArrayValidator,
  });

export const deleteResearchComment = params => (dispatch, getState) =>
  fetch({
    service: services.candidates.deleteResearchComment(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.UPDATE_RESEARCH_COMMENTS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.UPDATE_RESEARCH_COMMENTS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_RESEARCH_COMMENTS_FAILURE, errors),
    validator: candidatesArrayValidator,
  });

export const updateResearchComments = data => (dispatch, getState) =>
  fetch({
    service: services.candidates.updateResearchComments(data),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.UPDATE_RESEARCH_COMMENTS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.UPDATE_RESEARCH_COMMENTS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_RESEARCH_COMMENTS_FAILURE, errors),
    validator: candidatesArrayValidator,
  });

export const updateResearch = data => (dispatch, getState) =>
  fetch({
    service: services.candidates.updateResearch(data),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.UPDATE_RESEARCH_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.UPDATE_RESEARCH_SUCCESS, {
        researchId: data.id,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_RESEARCH_FAILURE, errors),
    validator: candidatesArrayValidator,
  });

export const getResearches = params => (dispatch, getState) =>
  fetch({
    service: services.candidates.getResearches(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_RESEARCHES_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_RESEARCHES_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_RESEARCHES_FAILURE, errors),
    validator: candidatesArrayValidator,
  });

export const getOneCandidate = params => (dispatch, getState) =>
  fetch({
    service: services.candidates.getOne(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_CANDIDATE_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_CANDIDATE_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_CANDIDATE_FAILURE, errors),
    validator: candidatesArrayValidator,
  });

export const cleanCurrentCandidate = () => ({
  type: ACTIONS_TYPE.CLEAN_CURRENT_CANDIDATE,
});

export const updateCandidateProfile = (candidateId, newProfile) => (
  dispatch,
  getState
) =>
  fetch({
    service: services.candidates.updateProfile(candidateId, newProfile),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.UPDATE_CANDIDATE_PROFILE_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.UPDATE_CANDIDATE_PROFILE_SUCCESS, {
        id: candidateId,
        profile: newProfile,
      }),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_CANDIDATE_PROFILE_FAILURE, errors),
    validator: candidatesArrayValidator,
  });

export const getCandidates = params => (dispatch, getState) =>
  fetch({
    service: services.candidates.get(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_CANDIDATES_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_CANDIDATES_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_CANDIDATES_FAILURE, errors),
    validator: candidatesArrayValidator,
  });
