import { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link';
import { Badge, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  listItemIcon: {
    minWidth: 25,
  },
  text: {
    display: 'flex',
    alignItems: 'flex-end',
    fontStyle: 'oblique',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  badge: {
    height: 5,
  },
  left: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  right: {
    paddingLeft: theme.spacing(2),
    paddingTop: 2,
    paddingBottom: 2,
  },
}));

const Item = ({
  name,
  label,
  route,
  icon,
  badgeValue,
  isMain = false,
  isExpendable = false,
  isExpended = false,
  onClick,
}) => {
  const classes = useStyles();

  const listItemClass = isMain ? classes.left : classes.right;

  const link =
    route &&
    forwardRef((props, ref) => <Link to={route} {...props} innerRef={ref} />);

  const Icon = icon;

  const ExpendIcon = isExpended ? ExpandLessIcon : ExpandMoreIcon;

  return (
    <ListItem
      name={name}
      dense
      button
      className={listItemClass}
      component={link}
      onClick={onClick}
    >
      <ListItemIcon className={classes.listItemIcon}>
        {badgeValue > 0 && (
          <Badge
            className={classes.badge}
            badgeContent={badgeValue}
            color="secondary"
          />
        )}
        <Icon color="primary" fontSize="small" />
      </ListItemIcon>
      <ListItemText primary={label} />
      {isExpendable && <ExpendIcon />}
    </ListItem>
  );
};
export default memo(Item);

Item.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  route: PropTypes.string,
  icon: PropTypes.elementType,
  badgeValue: PropTypes.number,
  isMain: PropTypes.bool,
  isExpendable: PropTypes.bool,
  isExpended: PropTypes.bool,
  onClick: PropTypes.func,
};

Item.defaultProps = {
  name: null,
  label: null,
  route: null,
  icon: null,
  badgeValue: 0,
  isMain: false,
  isExpendable: false,
  isExpended: false,
  onClick: null,
};
