import styled from 'styled-components';
import OfferButtons from './OfferButtons';

const OfferHeaderItems = ({ offer }) => {
  const admin = offer.admin.label;
  const secondaryAdmins = offer.secondaryAdmins.length
    ? offer.secondaryAdmins.map(admin => admin.label).join(', ')
    : null;

  return (
    <Container>
      <OfferButtons offer={offer} />
      <AdminContainer>
        <b>AM :</b> {admin}
      </AdminContainer>
      {secondaryAdmins && (
        <AdminContainer>
          <b>CdR :</b> {secondaryAdmins}
        </AdminContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-top: -10px;
`;
const AdminContainer = styled.div`
  margin-left: 10px;
  margin-right: 5px;
`;

export default OfferHeaderItems;
