import Title from 'components/layout/Title';

export default function PanelTitle({ label, yearInput, filter, legend }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Title variant="h6">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 5,
          }}
        >
          <span
            style={{
              whiteSpace: 'nowrap',
              marginRight: 10,
            }}
          >
            {label}
          </span>
          {yearInput}
        </div>
      </Title>
      {filter && (
        <div
          style={{
            flex: 1,
            display: 'inline-flex',
            marginTop: '-14px',
            padding: '0px 10px 10px 20px',
            columnGap: '20px',
          }}
        >
          {filter}
        </div>
      )}
      {legend}
    </div>
  );
}
