import FollowUpMultiTable from 'components/eiquem/placement/followUp/MultiTable';
import Main from 'components/layout/Main';
import PageHeader from 'components/layout/PageHeader';
import { useTranslation } from 'react-i18next';

export default function FollowUpPage() {
  const { t } = useTranslation();

  return (
    <Main pageTitle={t('menu.recruitmentTitle')}>
      <PageHeader title={t('followUp.title')} />
      <FollowUpMultiTable />
    </Main>
  );
}
