import { ACTIONS_TYPE } from './actions';
import { keysrt } from 'utils/functions';

export const initialState = {
  loading: false,
  offerOptions: null,
  adminOptions: null,
  contractTypeOptions: null,
  companyOptions: null,
  candidateOptions: null,
  letudiant: {
    domainOptions: null,
    durationOptions: null,
    levelOptions: null,
    contractOptions: null,
    functionOptions: null,
  },
  activeInvoiceOptions: null,
  recruiterOptions: null,
  functionOptions: null,
  functionByFamilyOptions: null,
  studyLevelOptions: null,
  businessSectorOptions: null,
  companySizeOptions: null,
  sourceOptions: null,
  planOptions: null,
  schoolTypeOptions: null,
  schoolOptions: null,
  schoolLevelOptions: [
    { value: 0, label: 0 },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
  ],
  profileOptions: null,
  amountOptions: null,
  hiredCandidateOptions: null,
  researchStatusOptions: null,
  cityOptions: null,
  teleworkingOptions: null,
  recruiterRoleOptions: null,
  commercialProposalStatusOptions: null,
  proposalModels: [],
};

export function formOptionsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS_TYPE.GET_LETUDIANT_DOMAIN_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_LETUDIANT_WORK_AND_STUDY_CONTRACT_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_LETUDIANT_LEVEL_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_LETUDIANT_CONTRACT_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_LETUDIANT_OFFER_TYPE_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_LETUDIANT_EXPERIENCE_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_LETUDIANT_FUNCTION_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_OFFER_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_ADMIN_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_CONTRACT_TYPE_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_COMPANY_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_CANDIDATE_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_LETUDIANT_DURATIONS_BEGIN:
    case ACTIONS_TYPE.GET_INVOICE_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_RECRUITER_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_FUNCTION_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_FUNCTION_BY_FAMILY_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_STUDY_LEVEL_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_BUSINESS_SECTOR_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_COMPANY_SIZE_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_SOURCE_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_PLAN_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_SCHOOL_TYPE_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_SCHOOL_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_PROFILE_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_AMOUNT_OPTIONS_BY_OFFER_BEGIN:
    case ACTIONS_TYPE.GET_HIRED_CANDIDATE_OPTIONS_BY_OFFER_BEGIN:
    case ACTIONS_TYPE.GET_RESEARCH_STATUS_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_CITY_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_TELEWORKING_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_RECRUITER_ROLE_OPTIONS_BEGIN:
    case ACTIONS_TYPE.GET_PROPOSAL_STATUS_OPTIONS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS_TYPE.GET_LETUDIANT_DOMAIN_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_LETUDIANT_LEVEL_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_LETUDIANT_WORK_AND_STUDY_CONTRACTS_FAILURE:
    case ACTIONS_TYPE.GET_LETUDIANT_CONTRACT_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_LETUDIANT_OFFER_TYPE_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_LETUDIANT_EXPERIENCE_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_LETUDIANT_FUNCTION_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_OFFER_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_ADMIN_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_CONTRACT_TYPE_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_COMPANY_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_CANDIDATE_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_LETUDIANT_DURATIONS_FAILURE:
    case ACTIONS_TYPE.GET_INVOICE_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_RECRUITER_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_FUNCTION_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_FUNCTION_BY_FAMILY_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_STUDY_LEVEL_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_BUSINESS_SECTOR_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_COMPANY_SIZE_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_SOURCE_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_PLAN_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_SCHOOL_TYPE_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_SCHOOL_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_PROFILE_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_AMOUNT_OPTIONS_BY_OFFER_FAILURE:
    case ACTIONS_TYPE.GET_HIRED_CANDIDATE_OPTIONS_BY_OFFER_FAILURE:
    case ACTIONS_TYPE.GET_RESEARCH_STATUS_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_CITY_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_TELEWORKING_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_RECRUITER_ROLE_OPTIONS_FAILURE:
    case ACTIONS_TYPE.GET_PROPOSAL_STATUS_OPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTIONS_TYPE.GET_LETUDIANT_DOMAIN_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        letudiant: {
          ...state.letudiant,
          domainOptions: {
            ...state.letudiant.domainOptions,
            [action.result.offerTypeId]: action.result.result
              .sort(keysrt('label'))
              .reduce((acc, group) => {
                const groupLabel = group.label;
                const options = group.options.map(option => {
                  return { ...option, groupLabel: groupLabel };
                });
                return acc.concat(options);
              }, []),
          },
        },
      });
    case ACTIONS_TYPE.GET_LETUDIANT_LEVEL_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        letudiant: {
          ...state.letudiant,
          levelOptions: action.result,
        },
      });
    case ACTIONS_TYPE.GET_LETUDIANT_DURATION_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        letudiant: {
          ...state.letudiant,
          durationOptions: {
            ...state.letudiant.durationOptions,
            [action.result.offerTypeId]: action.result.result,
          },
        },
      });
    case ACTIONS_TYPE.GET_LETUDIANT_WORK_AND_STUDY_CONTRACT_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        letudiant: {
          ...state.letudiant,
          workAndStudyContractOptions: action.result,
        },
      });
    case ACTIONS_TYPE.GET_LETUDIANT_CONTRACT_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        letudiant: {
          ...state.letudiant,
          contractOptions: {
            ...state.letudiant.contractOptions,
            [action.result.offerTypeId]: action.result.result,
          },
        },
      });
    case ACTIONS_TYPE.GET_LETUDIANT_OFFER_TYPE_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        letudiant: {
          ...state.letudiant,
          offerTypeOptions: action.result,
        },
      });
    case ACTIONS_TYPE.GET_LETUDIANT_EXPERIENCE_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        letudiant: {
          ...state.letudiant,
          experienceOptions: action.result,
        },
      });
    case ACTIONS_TYPE.GET_LETUDIANT_FUNCTION_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        letudiant: {
          ...state.letudiant,
          functionOptions: action.result,
        },
      });
    case ACTIONS_TYPE.GET_OFFER_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        offerOptions: action.result,
      });
    case ACTIONS_TYPE.CLEAN_OFFER_OPTIONS:
      return Object.assign({}, state, {
        ...state,
        offerOptions: [],
      });
    case ACTIONS_TYPE.GET_ADMIN_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        adminOptions: action.result,
      });
    case ACTIONS_TYPE.GET_CONTRACT_TYPE_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        contractTypeOptions: action.result,
      });
    case ACTIONS_TYPE.GET_COMPANY_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        companyOptions: action.result,
      });
    case ACTIONS_TYPE.GET_CANDIDATE_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        candidateOptions: action.result,
      });
    case ACTIONS_TYPE.GET_INVOICE_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        activeInvoiceOptions: action.result,
      });
    case ACTIONS_TYPE.GET_RECRUITER_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        recruiterOptions: action.result,
      });
    case ACTIONS_TYPE.GET_FUNCTION_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        functionOptions: action.result,
      });
    case ACTIONS_TYPE.GET_FUNCTION_BY_FAMILY_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        functionByFamilyOptions: [
          ...action.result.reduce((acc, group) => {
            const groupLabel = group.label;
            const options = group.options.map(option => {
              return { ...option, groupLabel: groupLabel };
            });
            return acc.concat(options);
          }, []),
        ],
      });
    case ACTIONS_TYPE.GET_STUDY_LEVEL_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        studyLevelOptions: action.result,
      });
    case ACTIONS_TYPE.GET_BUSINESS_SECTOR_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        businessSectorOptions: action.result,
      });
    case ACTIONS_TYPE.GET_COMPANY_SIZE_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        companySizeOptions: action.result,
      });
    case ACTIONS_TYPE.GET_SOURCE_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        sourceOptions: action.result,
      });
    case ACTIONS_TYPE.GET_PLAN_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        planOptions: action.result,
      });
    case ACTIONS_TYPE.GET_SCHOOL_TYPE_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        schoolTypeOptions: action.result,
      });
    case ACTIONS_TYPE.GET_SCHOOL_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        schoolOptions: action.result,
      });
    case ACTIONS_TYPE.GET_PROFILE_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        profileOptions: action.result,
      });
    case ACTIONS_TYPE.GET_AMOUNT_OPTIONS_BY_OFFER_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        amountOptions: action.result,
      });
    case ACTIONS_TYPE.CLEAN_AMOUNT_OPTIONS:
      return Object.assign({}, state, {
        ...state,
        amountOptions: [],
      });
    case ACTIONS_TYPE.GET_HIRED_CANDIDATE_OPTIONS_BY_OFFER_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        hiredCandidateOptions: action.result,
      });
    case ACTIONS_TYPE.GET_RESEARCH_STATUS_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        researchStatusOptions: action.result,
      });
    case ACTIONS_TYPE.GET_CITY_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        cityOptions: action.result,
      });
    case ACTIONS_TYPE.GET_TELEWORKING_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        teleworkingOptions: action.result.map(option => ({
          value: option.id,
          label: option.label.fr,
        })),
      });
    case ACTIONS_TYPE.GET_RECRUITER_ROLE_OPTIONS_SUCCESS:
      return {
        ...state,
        recruiterRoleOptions: action.result,
      };
    case ACTIONS_TYPE.GET_PROPOSAL_STATUS_OPTIONS_SUCCESS:
      return {
        ...state,
        commercialProposalStatusOptions: action.result,
      };
    case ACTIONS_TYPE.GET_PROPOSAL_MODELS_SUCCESS:
      return {
        ...state,
        proposalModels: action.result.proposals
          .map(option => ({
            value: option.id,
            label: `${option.label} - ${option.contractType?.label}`,
            proposal: option,
          }))
          .slice(0, 25),
      };
    default:
      return state;
  }
}
