import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateComment } from 'redux/companies/actions';
import SubmitButtons from 'components/core/SubmitButtons';
import Textarea from 'components/core/input/Textarea';

function Comment({ companyId, initialValue }) {
  const dipatch = useDispatch();

  const [comment, setComment] = useState(initialValue);

  const handleChange = ({ value }) => {
    setComment(value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    dipatch(updateComment(companyId, comment));
  };

  const handleCancel = () => {
    setComment(initialValue);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Textarea
        name="internalComment"
        value={comment}
        onChange={handleChange}
        rows={15}
      />
      <SubmitButtons cancelAction={handleCancel} />
    </form>
  );
}

export default Comment;
