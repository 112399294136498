import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
`;

const TabPanel = memo(({ index, value, children }) => {
  if (String(index) === String(value)) {
    return <Container>{children}</Container>;
  } else return null;
});

TabPanel.propTypes = {
  index: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.element,
};

TabPanel.defaultProps = {
  children: null,
};

export default TabPanel;
