import { fetch } from 'utils/api';
import services from 'utils/api/Services/services';
import { actionRequest, actionSuccess, actionFailure } from 'utils/functions';

export const ACTIONS_TYPE = Object.freeze({
  GET_INVOICES_BEGIN: 'GET_INVOICES_BEGIN',
  GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
  GET_INVOICES_FAILURE: 'GET_INVOICES_FAILURE',

  GET_INVOICE_BEGIN: 'GET_INVOICE_BEGIN',
  GET_INVOICE_SUCCESS: 'GET_INVOICE_SUCCESS',
  GET_INVOICE_FAILURE: 'GET_INVOICE_FAILURE',

  CLEAN_CURRENT_INVOICE: 'CLEAN_CURRENT_INVOICE',

  CREATE_INVOICE_BEGIN: 'CREATE_INVOICE_BEGIN',
  CREATE_INVOICE_SUCCESS: 'CREATE_INVOICE_SUCCESS',
  CREATE_INVOICE_FAILURE: 'CREATE_INVOICE_FAILURE',

  UPDATE_INVOICE_BEGIN: 'UPDATE_INVOICE_BEGIN',
  UPDATE_INVOICE_SUCCESS: 'UPDATE_INVOICE_SUCCESS',
  UPDATE_INVOICE_FAILURE: 'UPDATE_INVOICE_FAILURE',

  DELETE_INVOICE_BEGIN: 'DELETE_INVOICE_BEGIN',
  DELETE_INVOICE_SUCCESS: 'DELETE_INVOICE_SUCCESS',
  DELETE_INVOICE_FAILURE: 'DELETE_INVOICE_FAILURE',

  GET_KPI_BEGIN: 'GET_KPI_BEGIN',
  GET_KPI_SUCCESS: 'GET_KPI_SUCCESS',
  GET_KPI_FAILURE: 'GET_KPI_FAILURE',

  DOWNLOAD_INVOICES_BEGIN: 'DOWNLOAD_INVOICES_BEGIN',
  DOWNLOAD_INVOICES_SUCCESS: 'DOWNLOAD_INVOICES_SUCCESS',
  DOWNLOAD_INVOICES_FAILURE: 'DOWNLOAD_INVOICES_FAILURE',

  DOWNLOAD_ONE_INVOICE_BEGIN: 'DOWNLOAD_ONE_INVOICE_BEGIN',
  DOWNLOAD_ONE_INVOICE_SUCCESS: 'DOWNLOAD_ONE_INVOICE_SUCCESS',
  DOWNLOAD_ONE_INVOICE_FAILURE: 'DOWNLOAD_ONE_INVOICE_FAILURE',

  SEND_INVOICES_BEGIN: 'SEND_INVOICES_BEGIN',
  SEND_INVOICES_SUCCESS: 'SEND_INVOICES_SUCCESS',
  SEND_INVOICES_FAILURE: 'SEND_INVOICES_FAILURE',

  REMIND_INVOICE_BEGIN: 'REMIND_INVOICE_BEGIN',
  REMIND_INVOICE_SUCCESS: 'REMIND_INVOICE_SUCCESS',
  REMIND_INVOICE_FAILURE: 'REMIND_INVOICE_FAILURE',

  SET_INVOICE_REMINDER_PROGRAM_BEGIN: 'SET_INVOICE_REMINDER_PROGRAM_BEGIN',
  SET_INVOICE_REMINDER_PROGRAM_SUCCESS: 'SET_INVOICE_REMINDER_PROGRAM_SUCCESS',
  SET_INVOICE_REMINDER_PROGRAM_FAILURE: 'SET_INVOICE_REMINDER_PROGRAM_FAILURE',

  SET_INVOICE_AS_PAID_BEGIN: 'SET_INVOICE_AS_PAID_BEGIN',
  SET_INVOICE_AS_PAID_SUCCESS: 'SET_INVOICE_AS_PAID_SUCCESS',
  SET_INVOICE_AS_PAID_FAILURE: 'SET_INVOICE_AS_PAID_FAILURE',

  UPDATE_MSG_INVOICE_BEGIN: 'UPDATE_MSG_INVOICE_BEGIN',
  UPDATE_MSG_INVOICE_SUCCESS: 'UPDATE_MSG_INVOICE_SUCCESS',
  UPDATE_MSG_INVOICE_FAILURE: 'UPDATE_MSG_INVOICE_FAILURE',
});

export const updateInvoiceComment = params => (dispatch, getState) =>
  fetch({
    service: services.invoices.updateComment(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.UPDATE_MSG_INVOICE_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.UPDATE_MSG_INVOICE_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_MSG_INVOICE_FAILURE, errors),
  });

export const setInvoiceAsPaid = params => (dispatch, getState) =>
  fetch({
    service: services.invoices.setInvoiceAsPaid(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.SET_INVOICE_AS_PAID_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.SET_INVOICE_AS_PAID_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.SET_INVOICE_AS_PAID_FAILURE, errors),
  });

export const setInvoiceReminderProgram = params => (dispatch, getState) =>
  fetch({
    service: services.invoices.setInvoiceReminderProgram(params),
    dispatch,
    getState,
    actionRequest: () =>
      actionRequest(ACTIONS_TYPE.SET_INVOICE_REMINDER_PROGRAM_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.SET_INVOICE_REMINDER_PROGRAM_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.SET_INVOICE_REMINDER_PROGRAM_FAILURE, errors),
  });

export const remindInvoice = params => (dispatch, getState) =>
  fetch({
    service: services.invoices.remindInvoice(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.REMIND_INVOICE_BEGIN),
    actionSuccess: () => actionSuccess(ACTIONS_TYPE.REMIND_INVOICE_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.REMIND_INVOICE_FAILURE, errors),
  });

export const sendInvoices = params => (dispatch, getState) =>
  fetch({
    service: services.invoices.sendInvoices(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.SEND_INVOICES_BEGIN),
    actionSuccess: () => actionSuccess(ACTIONS_TYPE.SEND_INVOICES_SUCCESS),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.SEND_INVOICES_FAILURE, errors),
  });

export const deleteInvoice = params => (dispatch, getState) =>
  fetch({
    service: services.invoices.delete(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.DELETE_INVOICE_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.DELETE_INVOICE_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.DELETE_INVOICE_FAILURE, errors),
  });

export const getOneInvoice = params => (dispatch, getState) =>
  fetch({
    service: services.invoices.getOne(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_INVOICE_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_INVOICE_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_INVOICE_FAILURE, errors),
  });

export const cleanCurrentInvoice = () => ({
  type: ACTIONS_TYPE.CLEAN_CURRENT_INVOICE,
});

export const downloadOneInvoice = params => (dispatch, getState) =>
  fetch({
    service: services.invoices.downloadOne(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.DOWNLOAD_ONE_INVOICE_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.DOWNLOAD_ONE_INVOICE_SUCCESS, null),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.DOWNLOAD_ONE_INVOICE_FAILURE, errors),
  });

export const downloadInvoices = params => (dispatch, getState) =>
  fetch({
    service: services.invoices.download(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.DOWNLOAD_INVOICES_BEGIN),
    actionSuccess: () =>
      actionSuccess(ACTIONS_TYPE.DOWNLOAD_INVOICES_SUCCESS, null),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.DOWNLOAD_INVOICES_FAILURE, errors),
  });

export const createInvoice = params => (dispatch, getState) =>
  fetch({
    service: services.invoices.add(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.CREATE_INVOICE_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.CREATE_INVOICE_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.CREATE_INVOICE_FAILURE, errors),
  });

export const updateInvoice = params => (dispatch, getState) =>
  fetch({
    service: services.invoices.update(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.UPDATE_INVOICE_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.UPDATE_INVOICE_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.UPDATE_INVOICE_FAILURE, errors),
  });

export const getInvoices = params => (dispatch, getState) =>
  fetch({
    service: services.invoices.get(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_INVOICES_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_INVOICES_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_INVOICES_FAILURE, errors),
  });

export const getKpi = () => (dispatch, getState) =>
  fetch({
    service: services.invoices.getKpi(),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_KPI_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_KPI_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_KPI_FAILURE, errors),
  });
