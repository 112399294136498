import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components/core/input';

export default function AbandonedOffer({ values, onChange }) {
  const { t } = useTranslation();

  return (
    <Checkbox
      name="abandonedOffer"
      label={t('paf.field.abandonedOffer')}
      value={values.abandonedOffer}
      onChange={onChange}
    />
  );
}
