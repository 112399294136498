import PropTypes from 'prop-types';
import { TableCell, Tooltip, withStyles } from '@material-ui/core';
import S from 'components/Table/styles';

BaseCell.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.object,
  style: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

function BaseCell(props) {
  const { label, tooltip, style, classes } = props;

  const Cell = (
    <TableCell className={classes.tableCell} style={style} align="left">
      {props.children ? props.children : label}
    </TableCell>
  );

  if (tooltip) {
    return (
      <Tooltip placement="top" title={tooltip}>
        {Cell}
      </Tooltip>
    );
  }

  return Cell;
}

export default withStyles(S)(BaseCell);
