import { Formik } from 'formik';
import { PafFormSchema } from 'utils/formSchemas';

import Form from './form';

function Paf({ paf, onSubmit }) {
  const handleOnSubmit = values => {
    const companyValue = values.company;
    let companyId = null;
    let otherCompany = null;
    if (companyValue.value > 0) {
      companyId = companyValue.value;
    } else {
      otherCompany = companyValue.label;
    }

    const offerValue = values.offer;
    let offerId = null;
    let otherOffer = null;
    if (offerValue.value > 0) {
      offerId = offerValue.value;
    } else {
      otherOffer = offerValue.label;
    }

    const candidateValue = values.candidate;
    let candidateId = null;
    let otherCandidate = null;
    if (candidateValue?.value > 0) {
      candidateId = candidateValue.value;
    } else if (candidateValue?.label) {
      otherCandidate = candidateValue.label;
    }

    onSubmit({
      id: values.id,
      adminId: values.admin.value,
      companyId,
      otherCompany,
      offerId,
      otherOffer,
      recruitmentStartDate: values.recruitmentStartDate,
      planId: values.plan.value,
      contractTypeId: values.contractType.value,
      contractDuration: values.contractDuration,
      fixedPrice: values.fixedCost,
      variablePrice: values.variableCost,
      secondVariablePrice: values.variableCostHiring,
      placementDate: values.pafDate,
      candidateStartDate: values.candidateStartDate,
      candidateId,
      otherCandidate,
      finalInternship: values.finalInternship,
      firstVariableInvoiceDate: values.invoiceDate,
      secondVariablePotentialDate: values.secondVariableDate,
      secondVariableInvoiceDate: values.contractChangeDate,
      inDifficulty: values.offerInDifficulty,
      abandoned: values.abandonedOffer,
      completed: values.completedOffer,
      comments: values.comments,
      followUps: values.followUps,
    });
  };

  return (
    <Formik
      initialValues={paf}
      validationSchema={PafFormSchema}
      onSubmit={handleOnSubmit}
    >
      {props => <Form {...props} />}
    </Formik>
  );
}

export default Paf;
