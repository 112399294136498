const auth = {
  processLogin: ({ login, password }) => ({
    url: `/login_check/admin`,
    method: 'post',
    data: { username: login, password: password },
  }),
  processRefreshToken: ({ refresh_token }) => ({
    url: `/token/refresh`,
    method: 'post',
    data: { refresh_token: refresh_token },
  }),
};

export default auth;
