import moment from 'moment-timezone';
import { jobBoardsList } from './constants';
import { ROUTES } from 'utils/routes';

export const getJobBoard = key => {
  return jobBoardsList.find(jobBoard => jobBoard.key === key);
};

const now = moment();
let postingDataFormated = [];

export const postingDataFormat = postings => {
  if (postings === null) {
    return;
  }
  postingDataFormated = [];
  postings.forEach(posting => {
    // Offer part
    let postingFormated = postingDataFormated.find(
      item => item.offer.id === posting.offer.id
    );
    if (typeof postingFormated === 'undefined') {
      const offer = posting.offer;
      const createDuration =
        now.diff(offer.createDate, 'days').toString() + ' jours';

      const offerFormated = {
        ...offer,
        admins: offer.admins?.map(admin => admin.label)?.join(', ') ?? '',
        //company: Object.assign({}, offer.company),
        contractType: Object.assign({}, offer.contractType),
        createDuration: createDuration,
        company: {
          label: offer.company.label,
          url: offer.company.url,
          link: ROUTES.COMPANY_SHOW.replace(':id', offer.company.value),
        },
        label: {
          label: offer.label,
          url: ROUTES.OFFER_SHOW.replace(':id', offer.id),
        },
      };

      postingFormated = {
        offer: offerFormated,
        indicators: {},
        posts: {},
      };

      postingDataFormated.push(postingFormated);
    }

    // Posting part
    const posts = posting.posts;
    if (typeof posting.posts === 'undefined') {
      return;
    }

    //postingFormated.posts = posts;
    jobBoardsList.forEach(jobBoard => {
      postingFormated.posts[jobBoard.key] = [];
      let lastPost = null; // More recent post of the offer on this jobBoard
      let postCount = 0; // How many time the offer has been published on this jobBoard
      let lastPostDuration = 0; // How long time the last post was online on this jobBoard
      let totalPostDuration = 0; // How long time the offer has been published on this jobBoard
      if (typeof posts[jobBoard.key] === 'undefined') {
        return;
      }

      for (let [idx, post] of posts[jobBoard.key].entries()) {
        const postFormated = Object.assign({}, post);
        postingFormated.posts[jobBoard.key].push(postFormated);

        if (lastPost === null || post.id > lastPost.id) {
          lastPost = post;
        }
        postCount += 1;
        totalPostDuration += (post.archiveDate
          ? moment(post.archiveDate)
          : now
        ).diff(moment(post.postDate), 'days');

        if (idx === posts[jobBoard.key].length - 1) {
          lastPostDuration = now.diff(moment(lastPost.postDate), 'days');
          let indicator = moment(lastPost.postDate).format('DD/MM/YYYY');
          indicator += ' (' + lastPostDuration + '/' + totalPostDuration + ')';
          indicator += '  (' + postCount + ') ';
          indicator += lastPost.premium ? 'TP' : '';

          postingFormated.indicators[jobBoard.key] = indicator;

          // Column style
          if (typeof postingFormated.cellStyle === 'undefined') {
            postingFormated.cellStyle = {};
          }
          postingFormated.cellStyle[jobBoard.key] = null;
          if (lastPost.archiveDate) {
            if (moment(lastPost.archiveDate) <= moment(Date.now())) {
              postingFormated.cellStyle[jobBoard.key] = {
                textDecoration: 'line-through',
              };
            }

            if (
              jobBoard.key === 'letudiant' &&
              moment(lastPost.archiveDate)
                .startOf('day')
                .diff(moment(Date.now()).startOf('day'), 'days') <= 0
            ) {
              postingFormated.cellStyle[jobBoard.key] = {
                ...postingFormated.cellStyle[jobBoard.key],
                color: 'red',
                fontWeight: 'bold',
              };
            }
          }
        }
      }

      return;
    });
  });

  return postingDataFormated;
};

export const formatData = offers => {
  if (offers === null) {
    return;
  }
  const rowsFormated = [];
  offers.forEach(offer => {
    const rowFormated = Object.assign({}, offer);
    // Set label as link
    rowFormated.label = {
      label: offer.label,
      url: ROUTES.OFFER_SHOW.replace(':id', offer.id),
    };

    rowFormated.company = {
      label: offer.company.name,
      url: offer.company.url,
      link: ROUTES.COMPANY_SHOW.replace(':id', offer.company.value),
    };

    rowsFormated.push(rowFormated);
    return;
  });

  return rowsFormated;
};
