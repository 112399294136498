import BaseButton from './baseButton';

export default function AppointmentButton(props) {
  const msg = `Souhaites-tu faire une demande de prise de rendez-vous ?
        Un mail sera envoyé au candidat.`;

  return (
    <BaseButton
      title="Prise de rendez-vous"
      label="Rdv"
      message={msg}
      color="primary"
      action={props.action}
      disabled={props.disabled}
    />
  );
}
