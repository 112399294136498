import { useParams } from 'react-router-dom';
import CreateProposal from './createProposal';
import EditProposal from './editProposal';

export default function CommercialProposal() {
  const params = useParams();
  const id = params.id ? parseInt(params.id) : null;

  return id ? <EditProposal id={id} /> : <CreateProposal />;
}
