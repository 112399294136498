//@flow
import type { Dispatch, ThunkAction, GetState } from 'utils/types';
import type { GetPafStatisticsAPIType } from 'utils/api/types';

import { fetch } from 'utils/api';
import services from 'utils/api/Services/services';
import { actionRequest, actionSuccess, actionFailure } from 'utils/functions';
import { pafStatisticsValidator } from 'utils/schemas';

export const ACTIONS_TYPE = Object.freeze({
  GET_PAF_STATISTICS_BEGIN: 'GET_PAF_STATISTICS_BEGIN',
  GET_PAF_STATISTICS_SUCCESS: 'GET_PAF_STATISTICS_SUCCESS',
  GET_PAF_STATISTICS_FAILURE: 'GET_PAF_STATISTICS_FAILURE',
});

export const getPafStatistics = (
  params: GetPafStatisticsAPIType
): ThunkAction => (dispatch: Dispatch, getState: GetState) =>
  fetch({
    service: services.pafStatistics.get(params),
    dispatch,
    getState,
    actionRequest: () => actionRequest(ACTIONS_TYPE.GET_PAF_STATISTICS_BEGIN),
    actionSuccess: result =>
      actionSuccess(ACTIONS_TYPE.GET_PAF_STATISTICS_SUCCESS, result),
    actionFailure: errors =>
      actionFailure(ACTIONS_TYPE.GET_PAF_STATISTICS_FAILURE, errors),
    validator: pafStatisticsValidator,
  });
