import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'utils/routes';
import { cddId, cdiId } from 'utils/constants';
import services from 'utils/api/Services/services';
import {
  useFetchAdmins,
  useFetchContractTypes,
  useFetchPlans,
  useFetchProposalStatuses,
  useFetchProvenances,
  useFetchProposalModels,
} from 'utils/hooks/formOptions';
import { langOptions, getDefaultPrices } from './helper';

import { Grid } from 'components/core/containers';

import TextField from 'components/core/input/TextField';
import SubmitButtons from 'components/core/SubmitButtons';
import Autocomplete from 'components/core/input/Autocomplete';
import AutocompleteAsync from 'components/core/input/AutocompleteAsync';
import Checkbox from 'components/core/input/Checkbox';

import { Subtitle } from 'pages/JobBoardsPosting/Letudiant/Form/subtitle';

function Form({
  values,
  errors,
  touched,
  handleSubmit,
  setFieldValue,
  createMode,
  setValues,
  isSubmitting,
}) {
  const { t } = useTranslation();

  const [fetchAdmins, admins] = useFetchAdmins();
  const [fetchPlans, plans] = useFetchPlans();
  const [fetchProvenances, provenances] = useFetchProvenances();
  const [fetchContractTypes, contractTypes] = useFetchContractTypes();
  const [fetchProposalStatuses, proposalStatuses] = useFetchProposalStatuses();

  useEffect(
    () => {
      fetchAdmins({ onlyActive: 1 });
      fetchPlans();
      fetchProvenances();
      fetchContractTypes();
      fetchProposalStatuses();
    },
    [
      fetchAdmins,
      fetchPlans,
      fetchProvenances,
      fetchContractTypes,
      fetchProposalStatuses,
    ]
  );

  const [fetchModels, models] = useFetchProposalModels();

  // Set default status
  useEffect(
    () => {
      if (createMode && !values.status && proposalStatuses) {
        setValues({
          ...values,
          status: proposalStatuses[0],
        });
      }
    },
    [proposalStatuses, setValues, createMode, values]
  );

  const handleCompanyChange = value => {
    handleChange('company', value);
    if (createMode && value) {
      fetchModels(value.value);
    }
  };

  const handleModelChange = value => {
    const proposal = models.find(model => model.value === value.value).proposal;
    setValues({
      ...proposal,
      company: values.company,
      status: proposalStatuses[0],
      prospect: null,
      isProspect: false,
    });
  };

  const handleChange = useCallback(
    (fieldName, value) => {
      setFieldValue(fieldName, value, false);
    },
    [setFieldValue]
  );

  const handleIsProspectChange = () => {
    if (!values.isProspect) {
      handleChange('company', null);
    } else {
      handleChange('prospect', null);
      handleChange('provenance', null);
    }
    handleChange('isProspect', !values.isProspect);
  };

  const handleContractTypeChange = value => {
    if (createMode && value) {
      setValues({ ...values, ...getDefaultPrices(value.value) });
    }

    handleChange('contractType', value);
  };

  return (
    <form onSubmit={handleSubmit} style={{ padding: 20 }}>
      <Grid container spacing={50}>
        <Grid item md={6} sm={12}>
          <Grid container spacing={25}>
            <Grid item md={12}>
              <Subtitle label="Données propale" />
            </Grid>
            {/* Is Prospect */}
            <Grid item md={6}>
              <Checkbox
                label={t('common.fields.prospect')}
                value={values.isProspect}
                onChange={handleIsProspectChange}
              />
            </Grid>
            {/* Company || Prospect*/}
            <Grid item md={6}>
              {values.isProspect === false ? (
                <AutocompleteAsync
                  defaultValues={[values.company]}
                  service={searchTerm =>
                    services.options.getCompanies({
                      search: searchTerm,
                    })
                  }
                  label={t('offer.fields.company')}
                  error={errors.company}
                  touched={touched.company}
                  onChange={({ value }) => handleCompanyChange(value)}
                />
              ) : (
                <TextField
                  label={t('common.fields.prospect')}
                  touched={touched.prospect}
                  error={errors.prospect}
                  handleChange={({ value }) => {
                    handleChange('prospect', value);
                  }}
                  value={values.prospect}
                />
              )}
            </Grid>
            {/* Model */}
            <Grid item md={6}>
              <Autocomplete
                options={models}
                label={t('common.fields.model')}
                onChange={({ value }) => handleModelChange(value)}
                disabled={!createMode || values.isProspect}
              />
            </Grid>
            {/* Label */}
            <Grid item md={6}>
              <TextField
                label={t('common.fields.label')}
                touched={touched.label}
                error={errors.label}
                handleChange={({ value }) => {
                  handleChange('label', value);
                }}
                value={values.label}
              />
            </Grid>
            {/* Admin */}
            <Grid item md={6}>
              <Autocomplete
                value={values.admin}
                options={admins}
                label={t('common.fields.admin')}
                error={errors.admin}
                touched={touched.admin}
                onChange={({ value }) => {
                  handleChange('admin', value);
                }}
              />
            </Grid>
            {/* Status */}
            <Grid item md={6}>
              <Autocomplete
                value={values.status}
                options={proposalStatuses}
                label={t('common.fields.status')}
                error={errors.status}
                touched={touched.status}
                onChange={({ value }) => {
                  handleChange('status', value);
                }}
              />
            </Grid>
            {/* Plan */}
            <Grid item md={6}>
              <Autocomplete
                value={values.plan}
                options={plans}
                label={t('common.fields.plan')}
                error={errors.plan}
                touched={touched.plan}
                onChange={({ value }) => {
                  handleChange('plan', value);
                }}
              />
            </Grid>
            {/* Contract Type */}
            <Grid item md={6}>
              <Autocomplete
                value={values.contractType}
                options={contractTypes}
                label={t('common.fields.contractType')}
                error={errors.contractType}
                touched={touched.contractType}
                onChange={({ value }) => {
                  handleContractTypeChange(value);
                }}
              />
            </Grid>
            {/* Lang */}
            <Grid item md={6}>
              <Autocomplete
                value={
                  values.lang === 'fr'
                    ? langOptions[0]
                    : values.lang === 'en'
                      ? langOptions[1]
                      : values.lang
                }
                options={langOptions}
                label={t('common.fields.lang')}
                touched={touched.lang}
                error={errors.lang}
                onChange={({ value }) => {
                  handleChange('lang', value);
                }}
              />
            </Grid>

            {/* Fixed Price */}
            <Grid item md={6}>
              <TextField
                label={t('common.fields.fixedPrice')}
                touched={touched.fixedPrice}
                error={errors.fixedPrice}
                handleChange={({ value }) => {
                  handleChange('fixedPrice', value);
                }}
                value={values.fixedPrice}
              />
            </Grid>
            {/* Variable Price */}
            <Grid item md={6}>
              <TextField
                label={t('common.fields.variablePrice')}
                touched={touched.variablePrice}
                error={errors.variablePrice}
                handleChange={({ value }) => {
                  handleChange('variablePrice', value);
                }}
                value={values.variablePrice}
              />
            </Grid>
            {/* Second Variable Price */}
            {values.contractType &&
              [cdiId, cddId].includes(values.contractType.value) === false && (
                <Grid item md={6}>
                  <TextField
                    label={t('common.fields.secondVariablePrice')}
                    touched={touched.secondVariablePrice}
                    error={errors.secondVariablePrice}
                    handleChange={({ value }) => {
                      handleChange('secondVariablePrice', value);
                    }}
                    value={values.secondVariablePrice}
                  />
                </Grid>
              )}
            {/* Specific Terms */}
            <Grid item md={12}>
              <TextField
                label={t('common.fields.specificTerms')}
                touched={touched.specificTerms}
                error={errors.specificTerms}
                handleChange={({ value }) => {
                  handleChange('specificTerms', value);
                }}
                value={values.specificTerms}
                rowMax={10}
                multiline
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sm={12}>
          <Grid container spacing={25}>
            <Grid item md={12}>
              <Subtitle label="Données internes" />
            </Grid>
            {/* Contact */}
            <Grid item md={6}>
              <TextField
                label={t('common.fields.responsible')}
                touched={touched.contact}
                error={errors.contact}
                handleChange={({ value }) => {
                  handleChange('contact', value);
                }}
                value={values.contact}
              />
            </Grid>
            {/* Provenance */}
            <Grid item md={6}>
              {values.isProspect && (
                <Autocomplete
                  value={values.provenance}
                  options={provenances}
                  label={t('common.fields.provenance')}
                  error={errors.provenance}
                  touched={touched.provenance}
                  onChange={({ value }) => {
                    handleChange('provenance', value);
                  }}
                />
              )}
            </Grid>
            {/* Email */}
            <Grid item md={6}>
              <TextField
                label={t('common.fields.email')}
                touched={touched.email}
                error={errors.email}
                handleChange={({ value }) => {
                  handleChange('email', value);
                }}
                value={values.email}
              />
            </Grid>
            {/* Phone */}
            <Grid item md={6}>
              <TextField
                label={t('common.fields.phone')}
                touched={touched.phone}
                error={errors.phone}
                handleChange={({ value }) => {
                  handleChange('phone', value);
                }}
                value={values.phone}
              />
            </Grid>
            {/* Fixed Price */}
            <Grid item md={6}>
              <TextField
                label={t('common.fields.fixedPrice')}
                touched={touched.internalFixedPrice}
                error={errors.internalFixedPrice}
                handleChange={({ value }) => {
                  handleChange('internalFixedPrice', value);
                }}
                value={values.internalFixedPrice}
                type="number"
                endAdornment="€"
              />
            </Grid>
            {/* Variable Price */}
            <Grid item md={6}>
              <TextField
                label={t('common.fields.variablePrice')}
                touched={touched.internalVariablePrice}
                error={errors.internalVariablePrice}
                handleChange={({ value }) => {
                  handleChange('internalVariablePrice', value);
                }}
                value={values.internalVariablePrice}
                type="number"
                endAdornment="€"
              />
            </Grid>
            {/* Second Variable Price */}
            {values.contractType &&
              [cdiId, cddId].includes(values.contractType.value) === false && (
                <>
                  <Grid item md={6}>
                    <TextField
                      label={t('common.fields.secondVariablePrice')}
                      touched={touched.internalSecondVariablePrice}
                      error={errors.internalSecondVariablePrice}
                      handleChange={({ value }) => {
                        handleChange('internalSecondVariablePrice', value);
                      }}
                      value={values.internalSecondVariablePrice}
                      type="number"
                      endAdornment="€"
                    />
                  </Grid>
                  <Grid item md={6} />
                </>
              )}
            {/* Variable Price */}
            <Grid item md={6}>
              <TextField
                label={t('common.fields.positionCount')}
                touched={touched.positionCount}
                error={errors.positionCount}
                handleChange={({ value }) => {
                  handleChange('positionCount', value);
                }}
                value={values.positionCount}
                type="number"
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                label={t('common.fields.comments')}
                handleChange={({ value }) => {
                  handleChange('comment', value);
                }}
                value={values.comment}
                rowMax={10}
                multiline
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SubmitButtons
        cancelRoute={ROUTES.COMMERCIAL_PROPOSAL_LIST}
        isSubmitting={isSubmitting}
      />
    </form>
  );
}

export default Form;
