import { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'utils/hooks/queryState';
import { ROUTES } from 'utils/routes';
import { getOffers, cleanOffers } from 'redux/offers/actions';

import OfferTable from 'components/eiquem/offer/Table';
import { InlineContainer } from 'components/core/containers';
import { SearchFilter } from 'components/core/FilterInput';
import Pagination from 'components/core/Pagination';
import CreateButton from 'components/Table/Actions/CreateButton';
import Filters from './Filters';

OfferTableWithFilter.protoType = {
  companyId: PropTypes.number,
  status: PropTypes.string,
  filterStandBy: PropTypes.bool,
  keepFilters: PropTypes.bool,
};

OfferTableWithFilter.defaultProps = {
  companyId: null,
  status: 'inProgress',
  filterStandBy: true,
};

function OfferTableWithFilter({
  filterStandBy,
  status,
  companyId,
  keepFilters,
}) {
  const {
    handleAllRefresh,
    totalItemCount,
    isPaginated,
  } = useOfferTableWithFilter({
    filterStandBy,
    companyId,
    status,
    keepFilters,
  });

  return (
    <>
      <InlineContainer align="bottom">
        <Filters onChange={handleAllRefresh} keepFilters={keepFilters} />
        <SearchFilter onChange={handleAllRefresh} />
        <CreateButton pathname={ROUTES.OFFER_CREATE} />
      </InlineContainer>
      <OfferTable onChange={handleAllRefresh} />
      {isPaginated && (
        <Pagination itemCount={totalItemCount} onChange={handleAllRefresh} />
      )}
    </>
  );
}

const useOfferTableWithFilter = ({
  filterStandBy,
  status,
  companyId,
  keepFilters,
}) => {
  const dispatch = useDispatch();
  const qs = useQuery();
  const offerState = useSelector(state => state.offers);
  const authenticatedAdmin = useSelector(state => state.auth.userId);

  const handleAllRefresh = useCallback(
    ({
      page = qs.get('page') || 1,
      pageSize = qs.get('page-size') || 20,
      search = qs.get('search'),
      orderBy = null,
      order = null,
      admin = qs.get('admin'),
      adminType = qs.get('adminType'),
      sortField = qs.get('sortField'),
      sortOrder = qs.get('sortOrder'),
      contractTypeIds = qs.getAll('contractTypes'),
      planIds = qs.getAll('plans'),
    }) => {
      dispatch(
        getOffers({
          page: page,
          size: pageSize,
          search: search,
          orderBy: orderBy,
          direction: order,
          status: status === 'standBy' ? 'inProgress' : status,
          admin: admin,
          standBy: filterStandBy ? (status === 'standBy' ? 1 : 0) : null,
          company: companyId,
          adminType: adminType,
          sortField,
          sortOrder,
          contractTypeIds,
          planIds,
        })
      );
    },
    [dispatch, filterStandBy, status, companyId, qs]
  );

  const didMount = useRef(false);
  useEffect(
    () => {
      if (!didMount.current) {
        // Check if we need to fetch with qs admin, authenticated admin or null admin
        const admin =
          qs.get('admin') || (!keepFilters ? authenticatedAdmin : null);
        handleAllRefresh({
          admin,
        });
        didMount.current = true;
      }
    },
    [handleAllRefresh, qs, authenticatedAdmin, keepFilters]
  );

  useEffect(
    () => {
      return () => {
        dispatch(cleanOffers());
      };
    },
    [dispatch]
  );

  const isPaginated = status === 'completed';

  return {
    handleAllRefresh,
    totalItemCount: offerState.totalItemCount,
    isPaginated,
  };
};

export default OfferTableWithFilter;
