import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { setInvoiceAsPaid } from 'redux/invoices/actions';
import ActionButton from 'components/ActionButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { DatePicker } from 'components/core/input';
import { isValid } from 'date-fns';
import { formatDate } from 'utils/functions';
import moment from 'moment-timezone';

function SetAsPaidButton(props) {
  const { invoice } = props;

  const [paymentDate, setPaymentDate] = useState(moment());

  const dispatch = useDispatch();
  const handleConfirm = () => {
    dispatch(
      setInvoiceAsPaid({
        id: invoice.id,
        set: 1,
        paymentDate: paymentDate ? formatDate(paymentDate) : null,
      })
    );
  };

  const dialogContent = (
    <div style={{ maxWidth: 200 }}>
      <DatePicker
        label="Date d'encaissement"
        value={paymentDate}
        onChange={({ value }) => setPaymentDate(value)}
      />
    </div>
  );

  return (
    <ActionButton
      icon={<ArrowDownwardIcon fontSize="small" />}
      dialogContent={dialogContent}
      dialog
      action={handleConfirm}
      label="Marquer comme encaissée"
      title="Marquer comme encaissée"
      disabled={invoice.issuance === null || invoice.paymentDate !== null}
    />
  );
}

export default SetAsPaidButton;
