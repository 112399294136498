import { useIsLoading } from 'utils/hooks/offer';
import { useTranslation } from 'react-i18next';
import Main from 'components/layout/Main';
import { useParamId } from 'utils/hooks';
import CreateOfferForm from './createOfferForm';
import EditOfferForm from './editOfferForm';

function OfferForm() {
  const { t } = useTranslation();

  const id = useParamId();
  const isLoading = useIsLoading();

  return (
    <Main pageTitle={t('menu.recruitmentTitle')} isLoading={isLoading}>
      {id ? <EditOfferForm id={id} /> : <CreateOfferForm />}
    </Main>
  );
}

export default OfferForm;
