import { useTranslation } from 'react-i18next';
import { removeTimeZonePart } from 'utils/functions';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Box,
  TextField,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Subtitle } from './subtitle';

export default function OfferMainFields(props) {
  const {
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    classes,
    getFormOptions,
  } = props;

  const { t } = useTranslation();
  const { offerTypeOptions } = getFormOptions();

  let maxPublishDate = new Date();
  maxPublishDate.setMonth(maxPublishDate.getMonth() + 1);

  return (
    <Box className={classes.row}>
      <Box className={classes.column}>
        <Subtitle label="Infos Offre" />
        {offerTypeOptions && (
          <Box className={classes.row}>
            <Autocomplete
              multiple
              id="offerType"
              className={classes.selectLargeField}
              options={offerTypeOptions}
              getOptionLabel={option => option.label}
              defaultValue={values.offerType}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              renderInput={params => (
                <TextField
                  {...params}
                  id="offerType"
                  variant="standard"
                  label={t('jobboards-posting.field.offerType')}
                  fullWidth
                  error={touched.offerType && Boolean(errors.offerType)}
                  helperText={touched.offerType ? errors.offerType : ''}
                />
              )}
              onChange={(event, value) => {
                setFieldValue('offerType', value);
                setFieldTouched('offerType', true, false);
              }}
            />
          </Box>
        )}
        <Box className={classes.row}>
          <FormControlLabel
            label={t('jobboards-posting.field.isPremium')}
            control={
              <Checkbox
                id="isPremium"
                checked={values.isPremium}
                onChange={event => {
                  setFieldValue('isPremium', event.target.checked);
                  setFieldTouched('isPremium', true, false);
                }}
                color="primary"
              />
            }
            className={classes.checkbox}
            labelPlacement="end"
          />
        </Box>

        <Box className={classes.row}>
          {/* Publication End Date */}
          <KeyboardDatePicker
            className={classes.dateField}
            // maxDate={new Date().setDate(new Date() + 14)}
            maxDate={maxPublishDate}
            disableToolbar
            autoComplete="off"
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label={t('jobboards-posting.field.publicationEndDate')}
            error={
              touched.publicationEndDate && Boolean(errors.publicationEndDate)
            }
            helperText={
              touched.publicationEndDate ? errors.publicationEndDate : ''
            }
            value={removeTimeZonePart(values.publicationEndDate)}
            onChange={value => {
              setFieldValue('publicationEndDate', value);
              setFieldTouched('publicationEndDate', true, false);
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          {/* Label Contact */}
          <TextField
            id="labelContact"
            label={t('jobboards-posting.field.libelleContact')}
            autoComplete="off"
            helperText={touched.labelContact ? errors.labelContact : ''}
            error={touched.labelContact && Boolean(errors.labelContact)}
            className={classes.smallTextField}
            margin="normal"
            onChange={event => {
              const value = event.target.value;
              setFieldValue('labelContact', value);
              setFieldTouched('labelContact', true, false);
            }}
            value={values.labelContact ? values.labelContact : ''}
          />
        </Box>
        <Box className={classes.row}>
          {/* Posutlation Directe Internet */}
          <TextField
            id="offerUrl"
            label={t('jobboards-posting.field.postulationDirecteInternet')}
            autoComplete="off"
            helperText={touched.offerUrl ? errors.offerUrl : ''}
            error={touched.offerUrl && Boolean(errors.offerUrl)}
            className={classes.largeTextField}
            margin="normal"
            onChange={event => {
              const value = event.target.value;
              setFieldValue('offerUrl', value);
              setFieldTouched('offerUrl', true, false);
            }}
            value={values.offerUrl ? values.offerUrl : ''}
          />
        </Box>
      </Box>
      <Box className={classes.row}>
        <Divider className={classes.divider} orientation="vertical" />
      </Box>
      {/* Company + Position */}
      <Box className={classes.column}>
        <Subtitle label="Entreprise" />
        <TextField
          id="company"
          label={t('jobboards-posting.field.company')}
          autoComplete="off"
          helperText={touched.company ? errors.company : ''}
          error={touched.company && Boolean(errors.company)}
          className={classes.textField}
          margin="normal"
          onChange={event => {
            const value = event.target.value;
            setFieldValue('company', value);
            setFieldTouched('company', true, false);
          }}
          value={values.company ? values.company : ''}
        />
        {/* Position */}
        <TextField
          id="position"
          label={t('jobboards-posting.field.position')}
          autoComplete="off"
          helperText={touched.position ? errors.position : ''}
          error={touched.position && Boolean(errors.position)}
          className={classes.largeTextField}
          margin="normal"
          onChange={event => {
            const value = event.target.value;
            setFieldValue('position', value);
            setFieldTouched('position', true, false);
          }}
          value={values.position ? values.position : ''}
        />
      </Box>
    </Box>
  );
}
